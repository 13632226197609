import React, { PropsWithChildren, useMemo } from "react";
import cs from "classnames";
import { groupBy, uniqueId } from "lodash";
import { OptimizationCampaignDataColumnT } from "../../../constants/report";
import {
  ActionStateT,
  BiddingT,
  CampaignStatusT,
  CurrencyT,
  Maybe,
  SystemNameT,
} from "../../../graphql/generated/graphql";
import { formatCurrency, formatPercents } from "../../../i18n/formatNumbers";
import { t } from "../../../i18n/translation";
import { Badge } from "../../../ui/Badge/Badge";
import { ButtonTertiary } from "../../../ui/Button/Button";
import { Col, Row } from "../../../ui/grid/Grid";
import { Icon } from "../../../ui/Icon/Icon";
import { Tooltip } from "../../../ui/Tooltip/Tooltip";
import { UserCampaignActionState } from "../../userCampaignActionState/UserCampaignActionState";
import { getIsEditable } from "../../userSystemAction/ChangeBudgetForm";
import { ChangeFormContextT } from "../../userSystemAction/ChangeStatusForm";
import {
  UserActionTableBiddingChangeT,
  UserActionTableBudgetChangeT,
  UserActionTableStatusChangeT,
  UserSystemActionsTablePropsT,
} from "../../userSystemAction/UserSystemActionTable";
import { CampaignIncludedDataT } from "../TableOptimizationComponent";
import {
  ChangePopup,
  MODAL_CHANGE_BIDDING,
  MODAL_CHANGE_BUDGET,
  MODAL_CHANGE_STATUS,
  PickedCampaignDataT,
  PickedCampaignT,
} from "../TableOptimizationRow";

type CellPropsT = {
  campaignData: PickedCampaignDataT[];
  campaignIncludedData: CampaignIncludedDataT;
};

type CellPropsSingleRowT = {
  campaign: PickedCampaignT;
  campaignIncludedData: CampaignIncludedDataT;
  changeFormContext: ChangeFormContextT;
  onEdit: () => void;
};

type PropsT = Pick<CellPropsT, "campaignIncludedData"> & {
  campaignData?: Maybe<CellPropsT["campaignData"]>;
  changeFormContext: ChangeFormContextT;
  type: string;
  userCampaignActions?: UserSystemActionsTablePropsT["userCampaignActions"];
};

// *** Wrappers
const CountWrappper = ({
  children,
  countElements,
  isOnlyOneType,
}: PropsWithChildren<{ countElements?: { campaign: { name: string } }[]; isOnlyOneType: boolean }>) => {
  const count = countElements?.length || 0;
  const showCount = !!countElements;

  return (
    <Tooltip
      tooltipContent={
        showCount || (!isOnlyOneType && countElements) ? (
          <>
            {countElements.map(({ campaign: { name } }, index) => (
              <span key={uniqueId(name)} className="ml-8 d-inline-flex flex-nowrap">
                {name}
                {index < count - 1 && <span className="ml-8">|</span>}
              </span>
            ))}
          </>
        ) : undefined
      }
    >
      <Row>
        {children}
        {showCount && (
          <Badge className="ml-4" kind="lightGray">
            {count}
          </Badge>
        )}
      </Row>
    </Tooltip>
  );
};

// *** Cells

export const CellWrapper = ({
  children,
  disableEditText,
  onEdit,
  userCampaignAction,
}: {
  children: React.ReactNode;
  disableEditText?: string;
  onEdit?: () => void;
  userCampaignAction?: NonNullable<UserSystemActionsTablePropsT["userCampaignActions"]>[0];
}) => (
  <Row alignItems="center" className="ShowOnHoverWrapper">
    <Col type="grow">{children}</Col>

    {userCampaignAction && (
      <Col className="ml-4 flex-shrink-1">
        <UserCampaignActionState userCampaignAction={userCampaignAction} />
      </Col>
    )}

    {onEdit && (
      <Col alignItems="bottom" className="ml-4" justify="center">
        {!disableEditText && (
          <ButtonTertiary className="ShowOnHoverElement" icon="edit" size="small" onlyIcon onClick={onEdit} />
        )}

        {disableEditText && (
          <Tooltip tooltipContent={disableEditText}>
            <Icon className="ShowOnHoverElement" kind="info" size="14px" />
          </Tooltip>
        )}
      </Col>
    )}
  </Row>
);
// ****** Status
export const CampaignStatus = ({ hideDesc, status }: { hideDesc?: boolean; status: string }) => {
  const iconProps = useMemo(() => {
    if (status === CampaignStatusT.EnabledT) {
      return { color: "#63bc26", kind: "active" };
    }
    if (status === CampaignStatusT.PausedT) {
      return { color: "#859bab", kind: "fill-paused" };
    }
    if (status === CampaignStatusT.RemovedT) {
      return { color: "#ff264a", kind: "close" };
    }
    return null;
  }, [status]);

  return (
    <Row alignItems="center" style={{ color: iconProps?.color }}>
      {!!iconProps && <Icon {...iconProps} className="mr-4" size="14px" style={{ marginBottom: "1px" }} />}
      {!hideDesc && t(status, "campaign.status")}
    </Row>
  );
};

const CampaignStatusCell = ({ campaignData }: CellPropsT) => {
  const statuses = groupBy(campaignData, "campaign.status");

  if (!Object.keys(statuses).length) {
    return null;
  }

  const isOnlyOneType = Object.keys(statuses).length === 1;
  return (
    <CellWrapper>
      <Row alignItems="center" padding="m">
        {Object.keys(statuses).map((status) =>
          !!statuses[status].length ? (
            <Col key={status}>
              <CountWrappper key={status} countElements={statuses[status]} isOnlyOneType={isOnlyOneType}>
                <CampaignStatus hideDesc={Object.keys(statuses).length > 1} status={status} />
              </CountWrappper>
            </Col>
          ) : null
        )}
      </Row>
    </CellWrapper>
  );
};

const CampaignStatusCellSingleRow = ({
  campaign,
  campaignIncludedData,
  changeFormContext,
  onEdit,
  userCampaignAction,
}: CellPropsSingleRowT & { userCampaignAction?: UserActionTableStatusChangeT }) => {
  const isVisibleChangeState = userCampaignAction && userCampaignAction.state !== ActionStateT.FinishedT;

  return (
    <CellWrapper userCampaignAction={userCampaignAction} onEdit={onEdit}>
      <Row className="mb-0">
        <CampaignStatus hideDesc={isVisibleChangeState} status={campaign.status} />
        {isVisibleChangeState && (
          <>
            <Icon className="mr-4" kind="arrow-right" />
            <CampaignStatus status={userCampaignAction.newStatus} hideDesc />
          </>
        )}
      </Row>

      {changeFormContext.popup.action === MODAL_CHANGE_STATUS && !changeFormContext.popup.isFirstCell && (
        <ChangePopup
          campaign={campaign}
          campaignIncludedData={campaignIncludedData}
          changeFormContext={changeFormContext}
          placement="bottom-end"
        />
      )}
    </CellWrapper>
  );
};

// ****** Budget
export const CampaignBudget = ({
  budget,
  budgetKind,
  budgetShared,
  currency,
  textWrap = false,
}: {
  budget?: number | null;
  budgetKind?: Maybe<string>;
  budgetShared?: Maybe<boolean>;
  currency?: Maybe<CurrencyT>;
  textWrap?: boolean;
}) => {
  if (!budget) {
    return <span>-</span>;
  }
  return (
    <span className={cs({ "text-nowrap": !textWrap })}>
      {budgetKind && t(budgetKind, "campaign.budget")}
      {budgetShared ? " (shared)" : ""}
      {budgetKind || budgetShared ? ":" : ""} {formatCurrency(budget, currency)}
    </span>
  );
};

const CampaignBudgetCell = ({ campaignData }: Omit<CellPropsT, "campaignIncludedData">) => {
  const isMultiple = campaignData.length > 1;

  if (isMultiple) {
    return <>Mixed ({campaignData.length})</>;
  }

  return null;
};

const CampaignBudgetCellSingleRow = ({
  campaign,
  campaignIncludedData: { budgetsById },
  campaignIncludedData,
  changeFormContext,
  onEdit,
  userCampaignAction,
}: CellPropsSingleRowT & { userCampaignAction?: UserActionTableBudgetChangeT }) => {
  const isVisibleChangeState = userCampaignAction && userCampaignAction.state !== ActionStateT.FinishedT;
  const budget = budgetsById[campaign.budgetId];
  const isEditable = getIsEditable(budget);
  const disableEditText =
    campaign.status === CampaignStatusT.RemovedT || !isEditable
      ? campaign.status === CampaignStatusT.RemovedT
        ? "Unable to change budget due to removed campaign in Ad system"
        : "Unable to change budget - not supported for ad set"
      : undefined;

  if (!budget) {
    return null;
  }

  return (
    <CellWrapper disableEditText={disableEditText} userCampaignAction={userCampaignAction} onEdit={onEdit}>
      {!isVisibleChangeState ? (
        <CampaignBudget
          budget={budget.amount}
          budgetKind={budget.kind}
          budgetShared={budget.shared}
          currency={campaign.currency}
        />
      ) : (
        <Tooltip
          tooltipContent={
            <>
              <CampaignBudget
                budget={budget.amount}
                budgetKind={budget.kind}
                budgetShared={budget.shared}
                currency={campaign.currency}
              />{" "}
              <Icon className="mr-4" kind="arrow-right" />{" "}
              <CampaignBudget
                budget={userCampaignAction.newBudgetValue}
                budgetKind={budget.kind}
                budgetShared={budget.shared}
                currency={campaign.currency}
              />
            </>
          }
        >
          <span>Updating</span>
        </Tooltip>
      )}
      {changeFormContext.popup.action === MODAL_CHANGE_BUDGET && !changeFormContext.popup.isFirstCell && (
        <ChangePopup
          campaign={campaign}
          campaignIncludedData={campaignIncludedData}
          changeFormContext={changeFormContext}
          placement="bottom-end"
        />
      )}
    </CellWrapper>
  );
};

// ****** TCpa
const CampaignTargetCpa = (props: { currency?: Maybe<CurrencyT>; targetCpa: BiddingT["targetCpa"] }) => {
  return <>{formatCurrency(props?.targetCpa, props?.currency)}</>;
};

// ****** TRoas
const CampaignTargetRoas = ({ targetRoas }: { targetRoas: BiddingT["targetRoas"] }) => (
  <>{formatPercents(targetRoas)}</>
);

export const CampaignBidding = ({
  biddingSchema,
  ...rest
}: {
  biddingSchema?: string | null;
  currency?: Maybe<CurrencyT>;
  targetCpa: BiddingT["targetCpa"];
  targetRoas: BiddingT["targetRoas"];
}) => {
  return biddingSchema ? (
    <span className="text-nowrap">
      {t(biddingSchema, "campaign.bidding")}{" "}
      {(biddingSchema === "TARGET_CPA" || biddingSchema === "MAXIMIZE_CONVERSIONS") && (
        <>
          : <CampaignTargetCpa {...rest} />
        </>
      )}
      {(biddingSchema === "TARGET_ROAS" || biddingSchema === "MAXIMIZE_CONVERSION_VALUE") && (
        <>
          : <CampaignTargetRoas {...rest} />
        </>
      )}
    </span>
  ) : (
    <>-</>
  );
};

// ****** Generic TCpaTRoasCell
export const BiddingCell = ({ campaignData, campaignIncludedData: { biddingsById } }: CellPropsT) => {
  const isOnlyOne = campaignData.length === 1;
  const isMultiple = campaignData.length > 1;

  if (isOnlyOne) {
    const campaign = campaignData[0].campaign;
    const bidding = biddingsById[campaign.biddingId];

    return (
      <CellWrapper>
        <CampaignBidding
          biddingSchema={bidding.schema}
          currency={campaign.currency}
          targetCpa={bidding.targetCpa}
          targetRoas={bidding.targetRoas}
        />
      </CellWrapper>
    );
  }

  if (isMultiple) {
    return <>Mixed ({campaignData.length})</>;
  }

  return null;
};

export const BiddingCellSingleRow = ({
  campaign,
  campaignIncludedData: { biddingsById },
  campaignIncludedData,
  changeFormContext,
  onEdit,
  userCampaignAction,
}: CellPropsSingleRowT & { userCampaignAction?: UserActionTableBiddingChangeT }) => {
  const bidding = biddingsById[campaign.biddingId];
  const isVisibleChangeState = userCampaignAction && userCampaignAction.state !== ActionStateT.FinishedT;
  const disableEditText =
    campaign.status === CampaignStatusT.RemovedT
      ? "Unable to change bidding due to removed campaign in Google Ads"
      : campaign.system !== SystemNameT.AdwordsT
      ? "You can change bidding only for Google Ads campaigns"
      : undefined;

  if (!bidding) {
    return null;
  }

  return (
    <CellWrapper disableEditText={disableEditText} userCampaignAction={userCampaignAction} onEdit={onEdit}>
      {!isVisibleChangeState ? (
        <CampaignBidding
          biddingSchema={bidding.schema}
          currency={campaign.currency}
          targetCpa={bidding.targetCpa}
          targetRoas={bidding.targetRoas}
        />
      ) : (
        <Tooltip
          tooltipContent={
            <>
              <CampaignBidding
                biddingSchema={bidding.schema}
                currency={campaign.currency}
                targetCpa={bidding.targetCpa}
                targetRoas={bidding.targetRoas}
              />{" "}
              <Icon className="mr-4" kind="arrow-right" />{" "}
              <CampaignBidding
                biddingSchema={userCampaignAction.newBiddingSchema}
                currency={campaign.currency}
                targetCpa={userCampaignAction.newBiddingValue}
                targetRoas={userCampaignAction.newBiddingValue}
              />
            </>
          }
        >
          <span>Updating</span>
        </Tooltip>
      )}

      {changeFormContext.popup.action === MODAL_CHANGE_BIDDING && !changeFormContext.popup.isFirstCell && (
        <ChangePopup
          campaign={campaign}
          campaignIncludedData={campaignIncludedData}
          changeFormContext={changeFormContext}
          placement="bottom-end"
        />
      )}
    </CellWrapper>
  );
};

// ****** TableOptimatizationCell
export const TableOptimatizationCell = ({
  campaignData,
  campaignIncludedData,
  changeFormContext,
  type,
  userCampaignActions,
}: PropsT) => {
  if (!campaignData) {
    return null;
  }

  const isSingleRow = campaignData.length === 1;

  const userCampaignAction = userCampaignActions?.find(
    (action) => action?.campaign?.id === campaignData[0].campaign.id
  );

  if (type === OptimizationCampaignDataColumnT.StatusT && isSingleRow) {
    return (
      <CampaignStatusCellSingleRow
        campaign={campaignData[0].campaign}
        campaignIncludedData={campaignIncludedData}
        changeFormContext={changeFormContext}
        userCampaignAction={userCampaignAction?.__typename === "StatusChange" ? userCampaignAction : undefined}
        onEdit={() =>
          changeFormContext.setPopup({ campaignId: campaignData[0].campaign.id, action: MODAL_CHANGE_STATUS })
        }
      />
    );
  }

  if (type === OptimizationCampaignDataColumnT.StatusT) {
    return <CampaignStatusCell campaignData={campaignData} campaignIncludedData={campaignIncludedData} />;
  }

  if (type === OptimizationCampaignDataColumnT.BudgetT && isSingleRow) {
    return (
      <CampaignBudgetCellSingleRow
        campaign={campaignData[0].campaign}
        campaignIncludedData={campaignIncludedData}
        changeFormContext={changeFormContext}
        userCampaignAction={userCampaignAction?.__typename === "BudgetChange" ? userCampaignAction : undefined}
        onEdit={() =>
          changeFormContext.setPopup({ campaignId: campaignData[0].campaign.id, action: MODAL_CHANGE_BUDGET })
        }
      />
    );
  }

  if (type === OptimizationCampaignDataColumnT.BudgetT) {
    return <CampaignBudgetCell campaignData={campaignData} />;
  }

  if (type === OptimizationCampaignDataColumnT.BiddingT && isSingleRow) {
    return (
      <BiddingCellSingleRow
        campaign={campaignData[0].campaign}
        campaignIncludedData={campaignIncludedData}
        changeFormContext={changeFormContext}
        userCampaignAction={userCampaignAction?.__typename === "BiddingChange" ? userCampaignAction : undefined}
        onEdit={() =>
          changeFormContext.setPopup({ campaignId: campaignData[0].campaign.id, action: MODAL_CHANGE_BIDDING })
        }
      />
    );
  }

  if (type === OptimizationCampaignDataColumnT.BiddingT) {
    return <BiddingCell campaignData={campaignData} campaignIncludedData={campaignIncludedData} />;
  }

  return <div>unknown</div>;
};
