/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useCallback, useMemo, useState } from "react";
import { isEqual } from "lodash";
import { WIDGET_SCHEMA } from "../../constants/report";
import {
  filterDuplicatedWidgetDimensions,
  filterDuplicatedWidgetMetrics,
  getWidgetDataFilter,
  getWidgetNamePlaceholder,
} from "../../functions/widgetHelpers";
import {
  CurrencyT,
  DataConsistencyT,
  Maybe,
  WidgetCompareT,
  WidgetDataFragmentT,
  WidgetMetricT,
} from "../../graphql/generated/graphql";
import { useSearch } from "../../hooks/useSearch";
import { ReportFilterT } from "../../types/report";
import { SectionT, WidgetCategoryT, WidgetT } from "../../types/widgets";
import { Col } from "../../ui/grid/Grid";
import { Tile } from "../../ui/Tile/Tile";
import { useReportBoardContext } from "../reportBoard/context/reportBoardContext";
import { WidgetContextT } from "../reportBoard/ReportBoardSection";
import { WidgetPropsT } from "./Widget";
import { WidgetHeader } from "./WidgetHeader";

type PropsT = {
  sectionFilter: ReportFilterT;
  widget: WidgetT;
  widgetContext: WidgetContextT;
} & (
  | {
      isPreview?: never;
      sectionId: SectionT["id"];
    }
  | {
      isPreview: boolean;
      sectionId?: never;
    }
);

export type ChangeWidgetDataT = ({
  dataConsistency,
}: {
  dataConsistency?: Maybe<DataConsistencyT>;
  resolvableCurrency?: Maybe<boolean>;
  resolvedCurrency?: Maybe<CurrencyT>;
}) => void;

const sliceArray = <T extends any[]>(array: T, limit?: number): T[number][] =>
  limit ? [...array].slice(0, limit) : array;

const removeMetricOptions = (array: WidgetMetricT[], remove: boolean) =>
  array.map((item) => ({
    ...item,
    dateRange: remove ? null : item.dateRange,
    compare: remove ? null : item.compare,
  }));

const widgetContainer: (component: FC<WidgetPropsT>) => FC<PropsT> =
  (WrappedComponent) =>
  ({ isPreview, sectionFilter, sectionId, widget, widgetContext }: PropsT) => {
    const { reportFilter, widgetSettingsData } = useReportBoardContext();
    const componentWidgetSchema = WIDGET_SCHEMA[widget.kind];
    const hasRawData = [WidgetCategoryT.ActionableT, WidgetCategoryT.SummaryT, WidgetCategoryT.TimebasedT].includes(
      WIDGET_SCHEMA[widget.kind].category
    );

    const filterRows = useCallback(
      (row: WidgetDataFragmentT["rows"]["nodes"][0]) =>
        row.widgetDimensions.reduce((acc, dimension) => `${acc}${dimension.value}`, ""),
      []
    );

    const { filterData, searchInputProps } = useSearch<WidgetDataFragmentT["rows"]["nodes"]>(filterRows);

    const [isShowRawData, setIsShowRawData] = useState(false);
    const [dataConsistency, setDataConsistency] = useState<DataConsistencyT | undefined>(undefined);
    const [currencyData, setCurrencyData] = useState<
      { isNotResolvable: boolean; resolvedCurrency?: Maybe<CurrencyT> } | undefined
    >(undefined);

    const handleWidgetDataChange: ChangeWidgetDataT = (data) => {
      if (data.dataConsistency && data.dataConsistency !== dataConsistency) {
        setDataConsistency(data.dataConsistency);
      }

      if (
        !isEqual(currencyData, { isNotResolvable: !data.resolvableCurrency, resolvedCurrency: data.resolvedCurrency })
      ) {
        setCurrencyData({ isNotResolvable: !data.resolvableCurrency, resolvedCurrency: data.resolvedCurrency });
      }
    };

    const filterValues = useMemo(
      () => getWidgetDataFilter({ widget, sectionFilter, reportFilter }),
      [widget, sectionFilter, reportFilter]
    );

    const validatedWidget = {
      ...widget,
      maxRows:
        componentWidgetSchema.props.includes("maxRows") && !!widget.widgetDimensions.length ? widget.maxRows : null,
      compare: componentWidgetSchema.hasMetricOptions ? WidgetCompareT.NoneT : widget.compare,
      widgetMetrics: filterDuplicatedWidgetMetrics(
        removeMetricOptions(
          sliceArray(widget.widgetMetrics, componentWidgetSchema.rules.metrics?.max),
          !componentWidgetSchema.hasMetricOptions
        )
      ),
      widgetDimensions: filterDuplicatedWidgetDimensions(
        sliceArray(widget.widgetDimensions, componentWidgetSchema.rules.dimensions?.max)
      ),
    };

    const handleShowRawData = () => {
      setIsShowRawData(true);
    };

    const handleCloseRawData = () => {
      setIsShowRawData(false);
    };

    const namePlaceholder = useMemo(
      () =>
        getWidgetNamePlaceholder({
          widgetMetrics: widget.widgetMetrics,
          widgetDimensions: widget.widgetDimensions,
          metricsById: widgetSettingsData.metricsById,
          dimensionsById: widgetSettingsData.dimensionsById,
        }),
      [widget.widgetMetrics, widget.widgetDimensions, widgetSettingsData]
    );

    return (
      <Col height="100%">
        <Tile
          className="reportWidget--border"
          contentStyle={{ paddingLeft: "24px" }}
          data-test-id={`${widget.name || namePlaceholder}`}
          data-test-widget-type={widget.kind}
          isFullHeight
        >
          <div className="d-flex flex-column h-100">
            <WidgetHeader
              currencyData={currencyData}
              dataConsistency={dataConsistency}
              hasRawData={hasRawData}
              isPreview={isPreview}
              namePlaceholder={namePlaceholder}
              searchInputProps={searchInputProps}
              sectionId={sectionId}
              widget={widget}
              widgetContext={widgetContext}
              onShowRawData={handleShowRawData}
            />
            <div className="flex-grow-1 pos-relative mt-24">
              <div className="widget-contentWrapper">
                <WrappedComponent
                  dataConsistency={dataConsistency}
                  filterData={filterData}
                  filterValues={filterValues}
                  isPreview={isPreview}
                  isShowRawData={isShowRawData}
                  widget={validatedWidget}
                  widgetContext={widgetContext}
                  onCloseRawData={handleCloseRawData}
                  onWidgetDataChange={handleWidgetDataChange}
                />
              </div>
            </div>
          </div>
        </Tile>
      </Col>
    );
  };

export default widgetContainer;
