import { ROUTES } from "../../constants/routes";
import { notifyError } from "../../functions/toast";
import { useReportCreateMutation } from "../../graphql/generated/graphql";
import { OrganizationTypeT, useAppSettings } from "../../providers/appSettingsProvider";
import { Text } from "../../ui/Text/Text";
import { SelectFromCollectionModal } from "../selectFromCollectionModal/SelectFromCollectionModal";

type PropsT = { onModalClose: () => void; reportId: string; reportName: string };

export const DuplicatedToOrganizationModal = ({ onModalClose, reportId, reportName }: PropsT) => {
  const {
    getOrganizationAppUrl,
    organization,
    user: { organizations },
  } = useAppSettings();

  const [duplicateToOtherOrganization, { loading }] = useReportCreateMutation({
    onError: () => notifyError(<>Server error when duplicating report!</>),
  });

  const handleDuplicateReportToOtherOrg = async <T extends OrganizationTypeT>({ id, name }: T) => {
    const result = await duplicateToOtherOrganization({
      variables: {
        organizationExternalId: organization.externalId,
        name: `${reportName} [Copy From "${organizations.current?.name}"]`,
        templateId: reportId,
        targetOrganizationExternalId: id,
      },
    });

    const reportResponse = result.data?.organization?.createReport;
    if (reportResponse?.errors?.length) {
      return notifyError(<>{reportResponse?.errors.join("\n")}</>);
    }

    if (reportResponse?.report?.id) {
      window.location.href = `${getOrganizationAppUrl(id || organization.externalId)}${ROUTES.report(
        reportResponse.report.id
      )}?duplicatedTo=${name}`;
    }
  };

  if (!organizations.available.length) {
    return null;
  }

  return (
    <SelectFromCollectionModal
      collection={organizations.available as NonNullable<typeof organizations.available>}
      confirmText="Duplicate"
      heading="Duplicate Report to Organization"
      isLoading={loading}
      itemIcon="building"
      description={
        <>
          Duplicating this item will create an identical copy of it, but{" "}
          <Text bold>all accounts wil be disconnected</Text> and you have to connect them again in new organization.
        </>
      }
      onClose={onModalClose}
      onSubmit={(org) => handleDuplicateReportToOtherOrg(org)}
    />
  );
};
