import React from "react";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ConfirmationDialogProvider } from "./components/confirmationDialog/confirmationDialogContext";
import { Error404Page } from "./pages/404Page";
import { DimensionDuplicatePage } from "./pages/DimensionDuplicate";
import { DimensionEditPage } from "./pages/DimensionEdit";
import { DimensionNewPage } from "./pages/DimensionNew";
import { HomePage } from "./pages/Home";
import { KpisPage } from "./pages/Kpis";
import { KpisDetailPage } from "./pages/KpisDetail";
import { KpisDuplicatePage } from "./pages/KpisDuplicate";
import { KpisEditPage } from "./pages/KpisEdit";
import { KpisForAllOrganizationsPage } from "./pages/KpisForAllOrganizations";
import { KpisNewPage } from "./pages/KpisNew";
import { MetricDuplicatePage } from "./pages/MetricDuplicate";
import { MetricEditPage } from "./pages/MetricEdit";
import { MetricNewPage } from "./pages/MetricNew";
import { MetricsAndDimensionsPage } from "./pages/MetricsAndDimensions";
import { ReportPage } from "./pages/Report";
import { ReportEditPage } from "./pages/ReportEdit";
import { ReportPreviewPage } from "./pages/ReportPreview";
import { ReportsPage } from "./pages/Reports";
import { ReportTemplatesPage } from "./pages/ReportTemplates";
import { SharedReportPage } from "./pages/SharedReport";
import { UserSystemActionsPage } from "./pages/UserSystemActions";
import { ValueMetricEditPage } from "./pages/ValueMetricEdit";
import { ValueMetricDuplicatePage } from "./pages/ValueMetricsDuplicate";
import { ValueMetricValuesPage } from "./pages/ValueMetricValues";
import { useAppSettings } from "./providers/appSettingsProvider";
import { createClient } from "./providers/graphqlProvider";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const csrfToken = "";
  const { apiToken, apiUri, appPathname, reportToken } = useAppSettings();

  return (
    <ConfirmationDialogProvider>
      <BrowserRouter basename={appPathname}>
        <ApolloProvider client={createClient({ csrfToken, apiUri, apiToken, reportToken })}>
          <Routes>
            {reportToken ? (
              <Route path="/">
                <Route element={<SharedReportPage />} index />
                <Route element={<SharedReportPage />} path="shared_report/:token" />
              </Route>
            ) : (
              <Route path="/">
                <Route element={<HomePage />} index />
                <Route element={<ReportTemplatesPage doNotShowSubHeader isEmptyReports />} path="first-templates" />
                <Route element={<ReportTemplatesPage />} path="templates" />
                <Route element={<UserSystemActionsPage />} path="user-campaign-actions" />
                <Route element={<MetricsAndDimensionsPage />} path="metrics-and-dimensions">
                  <Route path="metrics">
                    <Route element={<MetricNewPage />} path="new" />
                    <Route element={<MetricEditPage />} path=":metricId/edit" />
                    <Route element={<MetricDuplicatePage />} path=":metricId/duplicate" />
                  </Route>
                  <Route path="value-metrics">
                    <Route element={<ValueMetricEditPage />} path=":valueMetricId/edit" />
                    <Route element={<ValueMetricValuesPage />} path=":valueMetricId/values" />
                    <Route element={<ValueMetricDuplicatePage />} path=":valueMetricId/duplicate" />
                  </Route>
                  <Route path="dimensions">
                    <Route element={<DimensionEditPage />} path=":dimensionId/edit" />
                    <Route element={<DimensionDuplicatePage />} path=":dimensionId/duplicate" />
                    <Route element={<DimensionNewPage />} path="new" />
                  </Route>
                </Route>
                <Route path="reports">
                  <Route element={<ReportsPage />} index />
                  <Route path=":reportId">
                    <Route element={<ReportPage />} index />
                    <Route element={<ReportPreviewPage />} path="preview" />
                    <Route element={<ReportEditPage />} path="edit" />
                  </Route>
                </Route>
                <Route path="kpis">
                  <Route element={<KpisPage />} index />
                  <Route element={<KpisForAllOrganizationsPage />} path="organizations" />
                  <Route element={<KpisDetailPage />} path=":kpiSettingId" />
                  <Route element={<KpisNewPage />} path="new" />
                  <Route element={<KpisEditPage />} path=":kpiSettingId/edit" />
                  <Route element={<KpisDuplicatePage />} path=":kpiSettingId/duplicate" />
                </Route>
                <Route element={<Error404Page />} path="*" />
              </Route>
            )}
          </Routes>
          <ToastContainer />
        </ApolloProvider>
      </BrowserRouter>
    </ConfirmationDialogProvider>
  );
}

export default App;
