import React, { useCallback } from "react";
import { withApiStateHandler } from "../components/ErrorLoadingWrapper/withApiStateHandler";
import { OrganizationsKpiList } from "../components/organizationsKpiList/OrganizationsKpiList";
import { notifyError, notifySucces } from "../functions/toast";
import {
  KpisForAllOrganizationsPageQueryT,
  useKpiSettingsDeleteMutation,
  useKpisForAllOrganizationsPageQuery,
} from "../graphql/generated/graphql";
import { Breadcrumb } from "../layout/wrappers/Breadcrumb";
import { BreadcrumbItem } from "../layout/wrappers/BreadcrumbItem";
import { Layout } from "../layout/wrappers/Layout";
import { useAppSettings } from "../providers/appSettingsProvider";
import { HeadingPage } from "../ui/Heading/Heading";
import { KpiListsTabs } from "./Kpis";

type PropsT = {
  data?: KpisForAllOrganizationsPageQueryT;
  onDeleteKpiSettings: (kpiSettingIds: string[]) => void;
};

export const KpisForAllOrganizationsComponent = withApiStateHandler(({ data, onDeleteKpiSettings }: PropsT) => {
  const subOrganizations = data?.organization?.subOrganizations;

  return (
    <Layout>
      <Breadcrumb>
        <BreadcrumbItem>KPIs Dashboard</BreadcrumbItem>
      </Breadcrumb>

      <HeadingPage>KPIs Dashboard</HeadingPage>

      <KpiListsTabs isAllOrganizations />

      {subOrganizations && subOrganizations.length !== 0 ? (
        <OrganizationsKpiList organizations={subOrganizations} onDeleteKpiSettings={onDeleteKpiSettings} />
      ) : (
        <div>There is no KPI</div>
      )}
    </Layout>
  );
});

export const KpisForAllOrganizationsPage = () => {
  const { organization } = useAppSettings();

  const { data, error, loading, refetch } = useKpisForAllOrganizationsPageQuery({
    variables: { organizationExternalId: organization.externalId },
    fetchPolicy: "network-only",
  });

  const [mutation] = useKpiSettingsDeleteMutation({
    onCompleted: (response) => {
      const errors = response?.organization?.deleteKpiSettings?.errors;

      if (!!errors?.length) {
        return notifyError(<>Error when deleting KPI</>);
      }

      const deletedKpis = response?.organization?.deleteKpiSettings?.kpiSettings || 0;

      if (response?.organization?.deleteKpiSettings?.kpiSettings?.length) {
        notifySucces(<>{deletedKpis > 1 ? "KPIs" : "KPI"} was deleted successfully</>);
        refetch();
      }
    },
    onError: () => notifyError(<>Error when deleting KPI</>),
  });

  const handleDeleteKpiSettings = useCallback(
    (kpiSettingIds: string[]) => {
      mutation({
        variables: {
          organizationExternalId: organization.externalId,
          kpiSettingIds,
        },
      });
    },
    [mutation, organization.externalId]
  );

  return (
    <KpisForAllOrganizationsComponent
      data={data}
      error={error}
      loading={loading}
      wrapWithLayout
      onDeleteKpiSettings={handleDeleteKpiSettings}
    />
  );
};
