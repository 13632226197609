import React, { HTMLProps, ReactElement } from "react";
import classNames from "classnames";
import { FieldError } from "react-hook-form";
import { HTMLInputTypeAttributeT } from "../../types/common";
import { Icon } from "../Icon/Icon";
import FormGroup from "./FormGroup";

type InputT = Omit<HTMLProps<HTMLInputElement>, "type" | "id"> & {
  id: string;
  type?: Exclude<HTMLInputTypeAttributeT, "radio">;
};

type PropsT = {
  className?: string;
  disabled?: boolean;
  error?: FieldError;
  input: InputT;
  isFullWidth?: boolean;
  label?: string | number | ReactElement;
  name?: string;
  pausePlay?: boolean;
  testId?: string;
  timeRestriction?: "active" | "restricted" | null;
  visibility?: boolean;
};

const Switch = ({
  className,
  error,
  input,
  isFullWidth = false,
  label = " ",
  pausePlay,
  testId,
  timeRestriction,
  visibility,
}: PropsT) => {
  return (
    <FormGroup
      error={error}
      id={input.id}
      testId={testId}
      className={classNames(className, "form-group", "inline-switch", "switch", {
        "w-100": isFullWidth,
      })}
      isSwitch
    >
      <div>
        {(timeRestriction === "restricted" || timeRestriction === "active") && input.value && (
          <Icon
            className="mr-4 vam"
            color={timeRestriction === "restricted" ? "#F59E0B" : "#63BC26"}
            kind="clock"
            size="16px"
          />
        )}

        {visibility && !input.value && <Icon className="mr-4 vam" color="#D5DDE2" kind="disabled-eye" size="16px" />}

        <input
          {...input}
          type="checkbox"
          className={classNames("form-control", "switch", "switch-input", input.className, {
            "switch-input--icons": pausePlay || visibility,
            "switch-input--iconsNotGeneratingRestricted": timeRestriction === "restricted",
            "switch-input--iconsRestricted": timeRestriction === "active",
            "switch-input--iconsVisibility": visibility,
          })}
        />
        <label className="switch-label switch" data-test-id="switch-input" htmlFor={input.id}>
          <span className="wrapper">
            <span className="moving-box">
              <span className="off" />
              <span className="on" />
            </span>
            <span className="circle" />
          </span>
        </label>

        {!!label && (
          <label className="control-label switch" htmlFor={input.id}>
            {label}
          </label>
        )}
      </div>
    </FormGroup>
  );
};

export default Switch;
