import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { differenceInDays, format, startOfDay } from "date-fns";
import { DateRangePicker } from "react-date-range";
import { DATE_FORMATS } from "../../constants/date";
import { getDateIsValid, getDaysFromDate } from "../../functions/dateHelpers";
import { DateRangeEnumT } from "../../graphql/generated/graphql";
import { formatDateRangeSelection } from "../../i18n/formatDate";
import { WidgetT } from "../../types/widgets";
import { Badge } from "../Badge/Badge";
import { ButtonPrimary, ButtonSecondary } from "../Button/Button";
import { DropdownFloatingBox } from "../Dropdown/DropdownFloatingBox";
import { useDropdown } from "../Dropdown/useDropdown";
import { Row } from "../grid/Grid";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";
import { Tooltip } from "../Tooltip/Tooltip";
import Checkbox from "./Checkbox";
import { DEFAULT_DATE_RANGE } from "./DateRangeController";
import { dateRanges, getEndDay, getRangeKind, getStartDay } from "./dateRangePresets";
import { FakeInput } from "./FakeInput";

export type RangeT = {
  autoFocus?: boolean | undefined;
  color?: string | undefined;
  disabled?: boolean | undefined;
  endDate?: Date | undefined;
  key?: string | undefined;
  kind?: string | null;
  showDateDisplay?: boolean | undefined;
  startDate?: Date | null | undefined;
};

type InputValueT = WidgetT["dateRange"];

type PropsT = {
  id: string;
  isClearDisable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: string;
  onChange: (value: InputValueT) => void;
  value: InputValueT;
};

export const DateRange = ({ id, isClearDisable, isDisabled, isLoading, label, onChange, value }: PropsT) => {
  const { boxProps, reference, triggerElementProps } = useDropdown();
  const [useCustomDays, setCustomDays] = useState<boolean>(value?.range === DateRangeEnumT.RangeCustomDaysT);

  const [ranges, setRanges] = useState<RangeT[]>([
    {
      startDate: getStartDay(value),
      endDate: getEndDay(value),
      key: "selection",
    },
  ]);

  const daysCount =
    ranges[0].endDate && ranges[0].startDate
      ? differenceInDays(new Date(ranges[0].endDate), new Date(ranges[0].startDate)) + 1
      : 0;

  const handleSetInputValue = useCallback(
    (range: RangeT) => {
      onChange({
        from: range.startDate && getDateIsValid(range.startDate) ? format(range.startDate, DATE_FORMATS.dateApi) : null,
        to: range.endDate && getDateIsValid(range.endDate) ? format(range.endDate, DATE_FORMATS.dateApi) : null,
        fromDays:
          useCustomDays && range.startDate && getDateIsValid(range.startDate) ? getDaysFromDate(range.startDate) : null,
        toDays: useCustomDays && range.endDate && getDateIsValid(range.endDate) ? getDaysFromDate(range.endDate) : null,
        range:
          getRangeKind({ startDate: range.startDate, endDate: range.endDate, useCustomDays: useCustomDays }) || null,
        __typename: "DateRange",
      });
    },
    [onChange, useCustomDays]
  );

  const handleSetDefaultValue = () => {
    setRanges([
      {
        startDate: getStartDay(value),
        endDate: getEndDay(value),
        key: "selection",
      },
    ]);
  };

  const handleShowPicker = () => {
    boxProps.onToggle(true);
  };

  const handleClosePicker = useCallback(() => {
    boxProps.onToggle(false);
  }, [boxProps]);

  const handleClear = useCallback(() => {
    setRanges([DEFAULT_DATE_RANGE]);
    handleSetInputValue(DEFAULT_DATE_RANGE);
  }, [handleSetInputValue, setRanges]);

  const handleApply = useCallback(() => {
    handleSetInputValue(ranges[0]);
    handleClosePicker();
  }, [handleSetInputValue, ranges, handleClosePicker]);

  const handleCancel = () => {
    handleSetDefaultValue();
    handleClosePicker();
  };

  const clearedRanges = useMemo(
    () =>
      ranges.map((range) => ({
        ...range,
        startDate: range.startDate === null ? undefined : range.startDate,
      })),
    [ranges]
  );

  return (
    <div className={classNames("DateRange", { "pointer-events-none no-user-select": isLoading })}>
      <span {...triggerElementProps} ref={reference}>
        <FakeInput
          icon="calendar"
          id={id}
          isDisabled={isDisabled}
          isInputContentOpen={boxProps.isOpen}
          label={label}
          hasArrow
          isOverflowHidden
          onClear={isClearDisable ? undefined : handleClear}
          onClick={handleShowPicker}
        >
          {value ? formatDateRangeSelection(value) : ""}
        </FakeInput>
      </span>
      <DropdownFloatingBox {...boxProps} lockScroll={true}>
        <div className="DateRange" style={{ outline: "none" }} tabIndex={0}>
          <div className="DateRange-container">
            <DateRangePicker
              direction="horizontal"
              inputRanges={[]}
              maxDate={startOfDay(new Date())}
              months={2}
              moveRangeOnFirstSelection={false}
              ranges={clearedRanges}
              showDateDisplay={false}
              staticRanges={dateRanges}
              weekdayDisplayFormat="EEEEEE"
              weekStartsOn={1}
              showPreview
              onChange={(item) => {
                setRanges([item.selection]);
              }}
            />
            <div className="DateRange-footer">
              <Row alignItems="center">
                <Text color="lightGray">Range:</Text>
                <Badge className="ml-6" kind="lightGray" size="large">
                  {daysCount} {daysCount === 1 ? "Day" : "Days"}
                </Badge>
              </Row>
              <Row alignItems="center" className="mt-0">
                <Checkbox
                  label="Use floating days"
                  margin={0}
                  input={{
                    id: "use-custom-days",
                    checked: useCustomDays,
                    onChange: ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => setCustomDays(checked),
                    disabled: !ranges[0].startDate || !ranges[0].endDate,
                  }}
                />
                <div
                  className={classNames("ml-12", { "pointer-events-none": !ranges[0].startDate || !ranges[0].endDate })}
                >
                  <Tooltip
                    tooltipContent={`I.e. the date range will always be the last ${daysCount} ${
                      daysCount === 1 ? "day" : "days"
                    } (according to the selected time period)`}
                  >
                    <Icon
                      kind="info-circle-fill"
                      size="16px"
                      className={classNames("negative-ml-8 Icon--lightGray", {
                        "Icon--disabled": !ranges[0].startDate || !ranges[0].endDate,
                      })}
                    />
                  </Tooltip>
                </div>
              </Row>
              <div className="ml-a">
                <ButtonSecondary onClick={handleCancel}>Cancel</ButtonSecondary>
                <ButtonPrimary onClick={handleApply}>Apply</ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </DropdownFloatingBox>
    </div>
  );
};
