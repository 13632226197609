import React, { CSSProperties } from "react";
import classNames from "classnames";
import I18n from "../../i18n/en.json";
import { formatMetricValue } from "../../i18n/formatNumbers";
import { ConditionT } from "../../types/widgets";
import { Text } from "../../ui/Text/Text";
import { MetricsByIdT } from "../reportBoard/context/types";

enum TranslationKeyConversion {
  END_WITH = "EndWithT",
  EQ = "EqT",
  GT = "GtT",
  GTE = "GteT",
  LT = "LtT",
  LTE = "LteT",
  MATCH = "MatchT",
  MATCH_REGEXP = "MatchRegexpT",
  NOT_EQ = "NotEqT",
  NOT_MATCH = "NotMatchT",
  NOT_MATCH_REGEXP = "NotMatchRegexpT",
  START_WITH = "StartWithT",
}

type PropsT = {
  className?: string;
  conditions: ConditionT[];
  isDark?: boolean;
  metricsById: MetricsByIdT;
  style?: CSSProperties;
};

export const ConditionsPreview = ({ className, conditions, isDark, metricsById, style }: PropsT) => {
  if (!conditions.length) {
    return null;
  }

  return (
    <div
      style={{ ...style }}
      className={classNames(className, {
        "pa-8": isDark,
      })}
    >
      {conditions.map((condition, index) => {
        const isGroupStart = condition.groupId != conditions[index - 1]?.groupId;
        const isGroupEnd = condition.groupId != conditions[index + 1]?.groupId;
        const isLast = conditions.length === index + 1;

        const metricOperatorTranslation = condition.metricOperator
          ? I18n.form.metric.condtiton.operator[TranslationKeyConversion[condition.metricOperator]]
          : undefined;

        const dimensionOperatorTranslation = condition.dimensionOperator
          ? I18n.form.dimension.condtiton.operator[TranslationKeyConversion[condition.dimensionOperator]]
          : undefined;

        const formatConditionValues = condition.values.map((value) => {
          if (metricsById?.[condition?.metric?.id || ""]) {
            return formatMetricValue({
              metric: metricsById[condition?.metric?.id || ""],
              value: Number(value),
            });
          }
          return value;
        });

        return (
          <div key={condition.id || `${index}-${condition.groupId}`}>
            <div
              className={classNames("border-left border-right pa-8 ", {
                "border-top border-top-left-radius-4 border-top-right-radius-4": isGroupStart,
                "border-bottom border-bottom-left-radius-4 border-bottom-right-radius-4": isGroupEnd,
                "border-color-soft-gray": !isDark,
                "border-color-dark-gray": isDark,
              })}
            >
              <Text color="darkGreen">WHERE</Text> <span>{condition.metric?.name || condition.dimension?.name}</span>{" "}
              {metricOperatorTranslation || dimensionOperatorTranslation}{" "}
              <span>{formatConditionValues.join(" | ")}</span>{" "}
              {!isGroupEnd && (
                <Text className={classNames({ "d-block mt-8 negative-mb-8": isDark })} color="blue">
                  OR
                </Text>
              )}
            </div>
            {isGroupEnd && !isLast && (
              <div className="mv-8 pv-4 text-center">
                <Text color="softGray">AND</Text>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
