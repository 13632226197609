/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  differenceInDays,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format as formatDateFns,
  isValid,
  parseISO,
} from "date-fns";
import { DATE_FORMATS } from "../constants/date";
import { WidgetDateGroupingT } from "../graphql/generated/graphql";

export const getMinDate = (a: Date, b: Date) => (a < b ? a : b);

export const formatDate = (date: string | Date, format: keyof typeof DATE_FORMATS = "date") => {
  const parsedDate = typeof date === "string" ? parseISO(date) : date;

  if (isValid(parsedDate)) {
    return formatDateFns(parsedDate, DATE_FORMATS[format]);
  }

  return date as string;
};

export const getDateIsValid = (date: any) => !isNaN(Date.parse(date));

export const getDaysFromDate = (date: Date) => differenceInDays(date, new Date()) * -1;

export const isEqualOnlyDate = (date1: Date | string, date2: Date | string) => {
  return formatDate(date1) === formatDate(date2);
};

export const getDateGrouppedDateRange = ({
  dateFrom,
  dateGrouping,
  dateTo: dateToRaw,
}: {
  dateFrom: string;
  dateGrouping?: WidgetDateGroupingT;
  dateTo: string;
}) => {
  const date = new Date(dateFrom);
  const endDate = new Date(dateToRaw);
  let dateTo = undefined;

  if (dateGrouping === WidgetDateGroupingT.WeekT) {
    dateTo = getMinDate(endDate, endOfWeek(date));
  }
  if (dateGrouping === WidgetDateGroupingT.MonthT) {
    dateTo = getMinDate(endDate, endOfMonth(date));
  }
  if (dateGrouping === WidgetDateGroupingT.QuarterT) {
    dateTo = getMinDate(endDate, endOfQuarter(date));
  }
  if (dateGrouping === WidgetDateGroupingT.YearT) {
    dateTo = getMinDate(endDate, endOfYear(date));
  }

  return { dateFrom: date, dateTo };
};
