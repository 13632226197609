import { keyBy } from "lodash";
import { InputMaybe, MappingMatchT } from "../graphql/generated/graphql";

export const createHashMap = <T, K extends keyof T>(arr: T[], key: K) =>
  arr.reduce((acc, curr: T) => {
    if (!curr) {
      return acc;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return { ...acc, [curr[key] as any]: curr };
  }, {} as Record<string, T>);

export const matches = ({
  inputValue,
  matchType,
  originalValue,
}: {
  inputValue?: string;
  matchType?: InputMaybe<MappingMatchT>;
  originalValue: string;
}) => {
  if (!inputValue) {
    return false;
  }

  return !!(
    (matchType === MappingMatchT.IncludesT && originalValue.includes(inputValue.trim())) ||
    (matchType === MappingMatchT.EqualsT && originalValue === inputValue.trim()) ||
    (matchType === MappingMatchT.RegexpT && originalValue.match(new RegExp(inputValue)))
  );
};

export const calculateFieldCounts = ({
  valuesCount = [],
  arrayMapping = [],
  matchType,
}: {
  arrayMapping: Array<{ id: string; to?: string; what?: string }>;
  matchType?: InputMaybe<MappingMatchT>;
  valuesCount: Array<{ count: number; value: string }>;
}) => {
  const hashMapValuesCount = keyBy(valuesCount, ({ value }) => value.toLowerCase());
  const totalCount = valuesCount.length;
  let used = 0;

  const resultFields = arrayMapping.reduce((acc, item) => {
    acc[item.id] = 0;

    Object.keys(hashMapValuesCount).forEach((key) => {
      if (matches({ inputValue: item.what?.toLowerCase(), originalValue: key, matchType })) {
        acc[item.id] += 1;
        used += 1;
        delete hashMapValuesCount[key];
      }
    });

    return acc;
  }, {} as { [key in string]: number });
  return { resultFields, totalCount, unused: totalCount - used };
};
