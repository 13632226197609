import React, { useMemo } from "react";
import { WidgetT } from "../../../types/widgets";
import { DateRange } from "../../../ui/forms/DateRange";
import { OnFormEditWidgetChangeT } from "../FormEditWidget";

type InputValueT = WidgetT["dateRange"];

type PropsT = {
  formKey: string;
  isLoading?: boolean;
  onChange: OnFormEditWidgetChangeT;
  value: InputValueT;
};

export const InputDateRange = ({ formKey, isLoading, onChange, value }: PropsT) => {
  const path = useMemo(() => ["dateRange"], []);
  const pathString = path.join(".");

  const handleChange = (newValue: InputValueT) => {
    onChange((prev) => ({ ...prev, dateRange: newValue }));
  };

  return <DateRange id={`${formKey}.${pathString}`} isLoading={isLoading} value={value} onChange={handleChange} />;
};
