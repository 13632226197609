import React from "react";
import cs from "classnames";
import { ButtonTertiary } from "../Button/Button";
import { useModalContext } from "./modalContext";

const ModalCloseButton = ({
  disabled = false,
  onlyIcon,
  text,
}: {
  disabled?: boolean;
  onlyIcon?: boolean;
  text?: string;
}) => {
  const { close, testId } = useModalContext();

  if (!close) {
    throw new Error("close method is undefined");
  }

  return (
    <ButtonTertiary
      aria-label="Close"
      className={cs({ "modal-close Modal-close": onlyIcon })}
      data-dismiss="modal"
      data-test-id={`${testId}-close`}
      disabled={disabled}
      icon={onlyIcon ? "close" : undefined}
      id="modal-close-button"
      onlyIcon={onlyIcon}
      text={!onlyIcon ? text || "Close" : undefined}
      onClick={close}
    />
  );
};

export default ModalCloseButton;
