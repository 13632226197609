/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { withApiStateHandler } from "../components/ErrorLoadingWrapper/withApiStateHandler";
import { UserSystemActionsTable } from "../components/userSystemAction/UserSystemActionTable";
import { USER_ACTIONS_POLL_INTERVAL } from "../constants/common";
import {
  UserCampaignActionsQueryResultT,
  UserCampaignActionsQueryT,
  useUserCampaignActionsQuery,
} from "../graphql/generated/graphql";
import { Layout } from "../layout/wrappers/Layout";
import { useAppSettings } from "../providers/appSettingsProvider";
import { Heading } from "../ui/Heading/Heading";
import { Pagination, PaginationAdditionalPropsT, useGraphqlPagination } from "../ui/Pagination/Pagination";

type PropsT = {
  data?: UserCampaignActionsQueryT;
  paginationPropsWithCallbacks: PaginationAdditionalPropsT;
  refetch: UserCampaignActionsQueryResultT["refetch"];
};

const UserSystemActionsComponent = withApiStateHandler(({ data, paginationPropsWithCallbacks, refetch }: PropsT) => {
  const campaignActions = data?.organization?.userCampaignActions?.nodes;

  return (
    <Layout>
      <Heading className="mt-16" level="h1">
        User system actions
      </Heading>

      <UserSystemActionsTable userCampaignActions={campaignActions} onRefetch={refetch} />

      <Pagination {...paginationPropsWithCallbacks} className="mt-16" />
    </Layout>
  );
});

export const UserSystemActionsPage = () => {
  const { organization } = useAppSettings();
  const [paginationPropsWithCallbacks, graphqlPaginationVariables, setPageInfo] = useGraphqlPagination();

  const { data, error, loading, refetch, startPolling } = useUserCampaignActionsQuery({
    variables: { ...graphqlPaginationVariables, organizationExternalId: organization.externalId },
  });

  startPolling(USER_ACTIONS_POLL_INTERVAL);

  setPageInfo(data?.organization?.userCampaignActions.pageInfo);

  return (
    <UserSystemActionsComponent
      data={data}
      error={error}
      loading={loading}
      paginationPropsWithCallbacks={paginationPropsWithCallbacks}
      refetch={refetch}
      wrapWithLayout
    />
  );
};
