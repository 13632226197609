/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC } from "react";
import { ReportDimensionT, ReportMetricT } from "../components/reportBoard/context/types";
import { ChangeWidgetDataT } from "../components/widget/widgetContainer";
import { AreaChartComponentPropsT } from "../components/widgetComponents/AreaChartComponent";
import { BarChartComponentPropsT } from "../components/widgetComponents/BarChartComponent";
import { ImageComponentPropsT } from "../components/widgetComponents/ImageComponent";
import { LineChartComponentPropsT } from "../components/widgetComponents/LineChartComponent";
import { NoteComponentPropsT } from "../components/widgetComponents/NoteComponent";
import { PieChartComponentPropsT } from "../components/widgetComponents/PieChartComponent";
import { ScatterChartComponentPropsT } from "../components/widgetComponents/ScatterChartComponent";
import { TableComponentPropsT } from "../components/widgetComponents/TableComponent";
import { TimeBarChartComponentPropsT } from "../components/widgetComponents/TimeBarChartComponent";
import { TimeStackedBarChartComponentPropsT } from "../components/widgetComponents/TimeStackedBarChartComponent";
import { TitleComponentPropsT } from "../components/widgetComponents/TitleComponent";
import {
  DataConsistencyT,
  ConditionT as GeneratedConditionT,
  DimensionConditionT as GeneratedDimensionConditionT,
  MetricConditionT as GeneratedMetricConditionT,
  PageT as GeneratedPageT,
  ReportT as GeneratedReportT,
  SectionT as GeneratedSectionT,
  WidgetT as GeneratedWidgetT,
  PageT,
  SharedReportT,
  SourceSystemT,
  ValueCountT,
  WidgetDataFragmentT,
  WidgetDateGroupingT,
  WidgetKindT,
} from "../graphql/generated/graphql";
import { UseSearchT } from "../hooks/useSearch";
import { TableOrderT } from "./common";
import { ReportFilterT } from "./report";

export enum WidgetComponentT {
  areaChartComponentT = "areaChartComponent",
  barChartComponentT = "barChartComponent",
  imageComponentT = "imageComponent",
  lineChartComponentT = "lineChartComponent",
  noteComponentT = "noteComponent",
  pieChartComponentT = "pieChartComponent",
  scatterChartComponentT = "scatterChartComponent",
  scorecardComponentT = "scorecardComponent",
  tableComponentT = "tableComponent",
  tableOptimizationComponentT = "tableOptimizationComponent",
  timeBarChartComponentT = "timeBarChartComponent",
  timeStackedBarChartComponentT = "timeStackedBarChartComponent",
  titleComponentT = "titleComponent",
}

/* eslint-disable typescript-sort-keys/string-enum */
export enum WidgetCategoryT {
  TimebasedT = "TIMEBASED",
  SummaryT = "SUMMARY",
  ActionableT = "ACTIONABLE",
  StaticT = "STATIC",
  NoneT = "NONE",
}
/* eslint-enable typescript-sort-keys/string-enum */

export type WidgetOptionsT = {
  height?: number;
  width?: number;
};

export type WidgetDefaultOptionsT = {
  [key in WidgetKindT]?: WidgetOptionsT;
};

export type WidgetComponentsT =
  | FC<TitleComponentPropsT>
  | FC<TableComponentPropsT>
  | FC<ImageComponentPropsT>
  | FC<NoteComponentPropsT>
  | FC<LineChartComponentPropsT>
  | FC<AreaChartComponentPropsT>
  | FC<BarChartComponentPropsT>
  | FC<TimeBarChartComponentPropsT>
  | FC<TimeStackedBarChartComponentPropsT>
  | FC<PieChartComponentPropsT>
  | FC<ScatterChartComponentPropsT>;

export type WidgetComponentPropsT = {
  className: string;
  dataConsistency?: DataConsistencyT;
  filterData?: UseSearchT<WidgetDataFragmentT["rows"]["nodes"]>["filterData"];
  filterValues: ReportFilterT;
  isPreview?: boolean;
  isShowRawData: boolean;
  onCloseRawData: () => void;
  onWidgetDataChange: ChangeWidgetDataT | null;
  widget: WidgetT;
  widgetData?: WidgetDataFragmentT;
};

export type WidgetWidthT = 1 | 2 | 3 | 4 | 6;
export type WidgetHeightT = 1 | 2;
export type SectionWidthT = 1 | 2;

export type MetricConditionT = GeneratedConditionT & {
  metric: { id: GeneratedMetricConditionT["id"]; name: string };
  metricOperator: GeneratedMetricConditionT["operator"];
};

export type DimensionConditionT = GeneratedConditionT & {
  dimension: { id: GeneratedDimensionConditionT["id"]; name?: string };
  dimensionOperator: GeneratedDimensionConditionT["operator"];
};

export type ConditionT = GeneratedConditionT &
  Pick<Partial<MetricConditionT>, "metric" | "metricOperator"> &
  Pick<Partial<DimensionConditionT>, "dimension" | "dimensionOperator">;

export type ConditionsT = ConditionT[];

export type WidgetT = Omit<
  GeneratedWidgetT,
  "conditions" | "properties" | "dimensions" | "dimensionIds" | "metrics" | "metricIds" | "kind" | "sourceSystems"
> & {
  conditions: ConditionT[];
  hasDefaultData?: boolean;
  kind: WidgetKindT;
  properties?: {
    content?: string;
    options?: WidgetOptionsT;
    pageSize?: string;
    sortByMetricsAndDimensions?: {
      direction?: TableOrderT;
      sortBy?: string;
    };
    title?: string;
    titleType?: "h1" | "h2" | "h3";
    url?: string;
  } | null;
  sourceSystems: Pick<SourceSystemT, "id" | "name" | "externalId" | "email" | "externalName">[];
};

export type WidgetEditableDataT = Partial<Pick<WidgetT, "name" | "description">>;

export type WidgetsT = WidgetT[];

export type BoardDataT = {
  widgets: WidgetsT;
};

export type ReportT = Omit<
  GeneratedReportT,
  | "pages"
  | "sections"
  | "widget"
  | "dimensions"
  | "metrics"
  | "widgetData"
  | "connectedDimensions"
  | "connectedMetrics"
  | "sourceSystems"
  | "dimensionValueCounts"
  | "sharedReports"
> & {
  dimensionValueCounts?: ValueCountT[];
  dimensions: { nodes: GeneratedReportT["dimensions"]["nodes"] };
  metrics: { nodes: GeneratedReportT["metrics"]["nodes"] };
  pages: {
    nodes: Array<
      Omit<GeneratedPageT, "sections"> & {
        sections: {
          nodes: Array<
            Omit<GeneratedSectionT, "widgets" | "conditions"> & {
              conditions: ConditionT[];
              widgets: {
                nodes: WidgetT[];
              };
            }
          >;
        };
      }
    >;
  };
  sharedReports: {
    nodes: Array<
      Pick<SharedReportT, "id" | "name" | "allPages" | "discarded" | "token"> & {
        pages: { nodes: Pick<PageT, "id" | "name" | "discarded" | "shared">[] };
      }
    >;
  };
  sourceSystems: Pick<SourceSystemT, "id" | "externalId" | "name" | "externalName">[];
};

export type SectionT = ReportT["pages"]["nodes"][0]["sections"]["nodes"][0];

export type SectionsT = SectionT[];

export type EditedWidgetT = WidgetT & { hasDefaultData?: boolean; isNew?: boolean };
export type ReportBoardWidgetsT = EditedWidgetT[];

export type ReportBoardSectionT = Omit<SectionT, "widgets"> & {
  hasDefaultData?: boolean;
  isNew?: boolean;
  name?: string;
  widgets: { nodes: ReportBoardWidgetsT };
};
export type ReportBoardSectionsT = ReportBoardSectionT[];

export type ReportSettingsDataT = {
  dimensions?: ReportDimensionT[];
  metrics?: ReportMetricT[];
};

export type WidgetRuleT = {
  campaignDataColumns?: { max?: number };
  dateGrouping?: WidgetDateGroupingT[];
  dimensions?: { max?: number; min?: number };
  maxRows?: { max?: number; min: number };
  metrics?: { max?: number; min?: number };
};

export type WidgetSchemaT = {
  [key in WidgetKindT]: {
    category: WidgetCategoryT;
    components: WidgetComponentT[];
    defaultProps: Partial<WidgetT>;
    defaultWidgetDimensions?: {
      analytics: string[];
      other: string[];
    };
    defaultWidgetMetrics?: {
      analytics: string[];
      other: string[];
    };
    hasFilter?: boolean;
    hasMetricOptions?: boolean;
    hasQuickSearch?: boolean;
    icon: string;
    name: string;
    props: string[];
    rules: WidgetRuleT;
  };
};
