import React, { ChangeEvent, useState } from "react";
import { Placement } from "@floating-ui/react";
import { notifyError, notifySucces } from "../../functions/toast";
import { BudgetT, CampaignStatusT, useBudgetChangesUserCampaignActionMutation } from "../../graphql/generated/graphql";
import { useAppSettings } from "../../providers/appSettingsProvider";
import Input from "../../ui/forms/Input";
import Label from "../../ui/forms/Label";
import { Col, Row } from "../../ui/grid/Grid";
import { Icon } from "../../ui/Icon/Icon";
import { InfoBox } from "../../ui/InfoBox/InfoBox";
import { Link } from "../../ui/Link/Link";
import { Text } from "../../ui/Text/Text";
import { CampaignBudget } from "../widgetComponents/TableOptimatizationCell/TableOptimatizationCell";
import { CampaignIncludedDataT } from "../widgetComponents/TableOptimizationComponent";
import { PickedCampaignT } from "../widgetComponents/TableOptimizationRow";
import { AffectedCampaignsModal } from "./AffectedCampaignsModal";
import { ChangeFormContextT, ChangePopupWrapper } from "./ChangeStatusForm";

export const useChangeBudgetFormMutation = () =>
  useBudgetChangesUserCampaignActionMutation({
    onCompleted: (responseData) => {
      const errors = responseData.organization?.budgetChangesUserCampaignAction?.errors;

      if (errors?.length) {
        return notifyError(<>{errors.join("\n")}</>);
      }

      notifySucces(<>Change status successful</>);
    },
    onError: () => {
      notifyError(<>Change status failed</>);
    },
  });

type PropsT = ChangeFormContextT & {
  campaign: Omit<PickedCampaignT, "biddingId">;
  campaignIncludedData: CampaignIncludedDataT;
  onClose: () => void;
  placement?: Placement;
};

export const getIsEditable = (budget: Pick<BudgetT, "id" | "kind" | "shared" | "amount">) =>
  !!budget?.amount || budget?.amount === 0;

export const ChangeBudgetForm = ({
  campaign,
  campaignIncludedData,
  mutateBudget,
  mutateBudgetResult,
  onClose,
  placement,
}: PropsT) => {
  const { organization } = useAppSettings();
  const [wantedValues, setWantedValues] = useState<{ [key: string]: number }>({});
  const [isModalOpen, setModalOpen] = useState(false);

  const { budgetsById, getCampaignsWithSameBudget } = campaignIncludedData;
  const budget = budgetsById[campaign.budgetId];
  const campaignsWithSameBudget = getCampaignsWithSameBudget(campaign.budgetId);

  const isEditable = getIsEditable(budget);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      setWantedValues({
        ...wantedValues,
        [event.target.name]: event.target.valueAsNumber,
      });
    }
  };

  if (campaign.status === CampaignStatusT.RemovedT || !isEditable) {
    return (
      <ChangePopupWrapper placement={placement} title="Change budget" onClose={onClose}>
        {campaign.status === CampaignStatusT.RemovedT && "Unable to change budget due to removed campaign in Ad system"}
        {campaign.status !== CampaignStatusT.RemovedT && "Unable to change budget - not supported for ad set"}
      </ChangePopupWrapper>
    );
  }

  const budgetChanges = Object.keys(wantedValues).reduce(
    (acc, key) => (isNaN(wantedValues[key]) ? acc : [...acc, { campaignId: key, newValue: wantedValues[key] }]),
    [] as { campaignId: string; newValue: number }[]
  );

  const handleSend = () => {
    mutateBudget({
      variables: {
        organizationExternalId: organization.externalId,
        budgetChanges,
      },
      onCompleted: onClose,
    });
  };

  return (
    <ChangePopupWrapper
      campaign={campaign}
      colType="budget"
      isFormDisabled={budgetChanges.length === 0 || mutateBudgetResult.loading}
      isFormLoading={mutateBudgetResult.loading}
      title="Change budget"
      onClose={onClose}
      onSave={handleSend}
    >
      <Row alignItems="center">
        <Col type="grow">
          <Label>Current settings:</Label>
        </Col>
        <Col className="text-right" width="147px">
          <Text bold>
            <CampaignBudget
              budget={budget?.amount}
              budgetKind={budget?.kind}
              budgetShared={budget?.shared}
              currency={campaign.currency}
              textWrap
            />
          </Text>
        </Col>
      </Row>
      <Row alignItems="center">
        <Col type="grow">
          <Label>Budget:</Label>
        </Col>
        <Col width="147px">
          <Input
            inputProps={{
              name: campaign.id,
              type: "number",
              onChange: handleChange,
              value: (wantedValues[campaign.id] ? wantedValues[campaign.id] : budget.amount) || "",
              unit: campaign.currency || "",
            }}
          />
        </Col>
      </Row>

      {budget.shared && (
        <InfoBox className="mt-16" variant="warning" withIcon={false}>
          {campaignsWithSameBudget && campaignsWithSameBudget.length > 0 && (
            <div className="negative-mh-8">
              <Row alignItems="center" className="mb-8">
                <Col>
                  <Icon className="Icon--warning" kind="warning" size="16px" />
                </Col>
                <Col type="grow">
                  <Text className="Text--warning" color="error" tag="p" bold>
                    This budget is shared across multiple campaigns.
                  </Text>
                </Col>
              </Row>

              <Text className="mb-8" color="gray" tag="p">
                Change will affect these campaigns as well:
              </Text>

              <Link icon="info" onClick={() => setModalOpen(true)}>
                Affected campaigns
              </Link>

              {isModalOpen && (
                <AffectedCampaignsModal campaignsData={campaignsWithSameBudget} onClose={() => setModalOpen(false)} />
              )}
            </div>
          )}
        </InfoBox>
      )}
    </ChangePopupWrapper>
  );
};
