import React, { useEffect, useState } from "react";
import cs from "classnames";
import { isEqual } from "lodash";
import { useForm } from "react-hook-form";
import { SELECT_OPTIONS_CURRENCY } from "../../constants/form";
import { CurrencyT, DateRangeInputT } from "../../graphql/generated/graphql";
import { usePrevious } from "../../hooks/usePrevious";
import { formatDateRangeSelection } from "../../i18n/formatDate";
import { DateRangeController } from "../../ui/forms/DateRangeController";
import { InputController } from "../../ui/forms/InputController";
import { Col, Row } from "../../ui/grid/Grid";

export type FormReportCompactFilterInputsT = {
  currency?: CurrencyT;
  dateRange: DateRangeInputT;
};

export type PropsT = {
  canEdit?: boolean;
  defaultValues?: Partial<FormReportCompactFilterInputsT>;
  disableClear?: boolean;
  hasCurrency?: boolean;
  hasCurrencyPreview?: CurrencyT | null;
  id: string;
  isInputMinified?: boolean;
  isVissibleOnHover?: boolean;
  onChange: (filterValues: Partial<FormReportCompactFilterInputsT>) => void;
};

export const FormReportCompactFilter = ({
  canEdit,
  defaultValues,
  disableClear,
  hasCurrency,
  hasCurrencyPreview,
  id,
  isInputMinified,
  isVissibleOnHover,
  onChange,
}: PropsT) => {
  const prevDefaultValues = usePrevious(defaultValues);
  const [currentValues, setCurrentValues] = useState(defaultValues);

  const {
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<FormReportCompactFilterInputsT>();

  useEffect(() => {
    const subscription = watch((value) => {
      if (value && value.dateRange) {
        onChange({ ...value, dateRange: value.dateRange as DateRangeInputT });
        setCurrentValues((prev) => ({ ...prev, ...value }));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, onChange]);

  useEffect(() => {
    if (!isEqual(prevDefaultValues, defaultValues) && !isEqual(currentValues, defaultValues)) {
      reset({ ...defaultValues, dateRange: defaultValues?.dateRange || undefined });
    }
  }, [prevDefaultValues, defaultValues, currentValues, reset]);

  const hasDateRange = !!defaultValues?.dateRange && !!formatDateRangeSelection(defaultValues?.dateRange);
  const showDateRange = canEdit || hasDateRange;

  return (
    <form>
      <Row alignItems="center" flexwrap>
        {hasCurrencyPreview && (
          <Col className={cs({ "show-on-hover-element": isVissibleOnHover })}>
            <div data-active-element={!!hasCurrencyPreview}>{hasCurrencyPreview}</div>
          </Col>
        )}
        <Col className={cs("mr-0", { "show-on-hover-element": isVissibleOnHover })} type="shrink">
          {showDateRange && (
            <DateRangeController
              control={control}
              defaultValue={defaultValues?.dateRange || undefined}
              disableClear={disableClear}
              errors={errors}
              input={{ id: `${id}-dateRange`, disabled: !canEdit }}
              isInputMinified={isInputMinified}
              maxDate="today"
              name="dateRange"
              onSetValue={setValue}
            />
          )}
        </Col>
        {hasCurrency && (
          <Col className={cs("mr-0 ml-8", { "show-on-hover-element": isVissibleOnHover })} type="shrink">
            <InputController
              collection={SELECT_OPTIONS_CURRENCY}
              control={control}
              defaultValue={defaultValues?.currency}
              errors={errors}
              id={`${id}-currency`}
              name="currency"
            />
          </Col>
        )}
      </Row>
    </form>
  );
};
