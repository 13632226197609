import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep, set } from "lodash";
import { v4 as uuid } from "uuid";
import { DIMENSION_CAMPAIGN_ORIGINAL_NAME, DIMENSION_CAMPAIGN_STATUS_ORIGINAL_NAME } from "../../constants/common";
import { createHashMap } from "../../functions/calculateFieldCounts";
import { fetchDimensionId, fetchMetricId } from "../../functions/fetchIds";
import {
  BiddingChangeT,
  BiddingT,
  BudgetChangeT,
  BudgetT,
  CampaignStatusT,
  DataConsistencyT,
  DimensionOperatorT,
  StatusChangeT,
  WidgetDataFragmentT,
  WidgetDateGroupingT,
  WidgetDimensionT,
} from "../../graphql/generated/graphql";
import { useGetWidgetComponentData } from "../../hooks/useGetWidgetComponentData";
import { formatDateRangeSelection } from "../../i18n/formatDate";
import { formatMetricValue } from "../../i18n/formatNumbers";
import { WidgetComponentPropsT } from "../../types/widgets";
import { SortableAdditionalPropsT, SortableWrapper, useGraphqlSortable } from "../../ui/DataTable/Sortable";
import { TrendWrapper } from "../../ui/DataTable/TrendWrapper";
import Select from "../../ui/forms/Select";
import { Col, Row } from "../../ui/grid/Grid";
import { Loader } from "../../ui/Loader/Loader";
import { Pagination, UseGraphqlPaginationResultT, useGraphqlPagination } from "../../ui/Pagination/Pagination";
import { TBody, TFoot, THead, Table, Td, Th, TrFooter, TrHeader } from "../../ui/Table/Table";
import { withApiStateHandler } from "../ErrorLoadingWrapper/withApiStateHandler";
import { useReportBoardContext } from "../reportBoard/context/reportBoardContext";
import { WidgetContextT } from "../reportBoard/ReportBoardSection";
import { SearchInput } from "../search/Search";
import { ShowDimensionMetricPill } from "../showDimensionMetricPill/ShowDimensionMetricPill";
import ReloadReportCampaignData from "../userSystemAction/ReloadReportCampaignData";
import { TableOptimizationRow } from "./TableOptimizationRow";
import widgetComponentContainer from "./widgetComponentContainer";
import { widgetDataComponentContainer } from "./widgetDataComponentContainer";

const STATUS_OPTIONS = [
  {
    label: "All states",
    value: "all",
  },
  {
    label: "Active",
    value: "active",
    statuses: [CampaignStatusT.EnabledT],
  },
  {
    label: "Paused",
    value: "paused",
    statuses: [CampaignStatusT.PausedT],
  },
  {
    label: "Active & paused",
    value: "active-paused",
    statuses: [CampaignStatusT.EnabledT, CampaignStatusT.PausedT],
  },
];

export type CampaignIncludedDataT = {
  biddingsById: {
    [key: string]: Pick<BiddingT, "id" | "name" | "targetRoas" | "targetCpa" | "schema" | "strategyType">;
  };
  budgetsById: { [key: string]: Pick<BudgetT, "id" | "kind" | "shared" | "amount"> };
  getCampaignsWithSameBudget: (budgetId: string) => NonNullable<WidgetDataFragmentT["includedData"]>["campaignsData"];
  getCampaignsWithSameStrategy: (
    biddingId: string
  ) => NonNullable<WidgetDataFragmentT["includedData"]>["campaignsData"];
  userCampaignActions: {
    [key: string]:
      | Pick<StatusChangeT, "id" | "newStatus" | "previousStatus" | "state" | "createdAt" | "user">
      | Pick<BiddingChangeT, "id" | "previousValue" | "newValue" | "state" | "createdAt" | "user">
      | Pick<BudgetChangeT, "id" | "previousValue" | "newValue" | "state" | "createdAt" | "user">;
  };
};

export type TableOptimizationComponentPropsT = WidgetComponentPropsT & {
  widgetContext?: WidgetContextT;
};

type WidgetDataRowT = WidgetDataFragmentT["rows"]["nodes"][0];

type FilterPropsT = {
  filterForm: TableOptimizationFilterT;
  setFilterForm: React.Dispatch<React.SetStateAction<TableOptimizationFilterT>>;
};

type TableOptimizationFilterT = {
  search: string;
  status: "all" | "active" | "active-paused";
};

export const TableOptimizationFilter = ({
  filterProps: { filterForm, setFilterForm },
}: {
  filterProps: FilterPropsT;
}) => {
  return (
    <Row className="mb-24">
      <Col>
        <SearchInput
          clearSearch={() => setFilterForm((prev) => ({ ...prev, search: "" }))}
          value={filterForm.search}
          hasMagnifierIcon
          onChangeDebounced={(value) => setFilterForm((prev) => ({ ...prev, search: value }))}
        />
      </Col>
      <Col>
        <Select
          collection={STATUS_OPTIONS}
          selectProps={{
            id: "status",
            onChange: (event) => {
              const value = event.currentTarget.value as TableOptimizationFilterT["status"]; // "const value" - fix chrome currentTarget issue
              setFilterForm((prev) => ({ ...prev, status: value }));
            },
            value: filterForm.status,
          }}
        />
      </Col>
    </Row>
  );
};

const WidgetTableOptimizationComponentContent = withApiStateHandler(
  widgetDataComponentContainer(
    ({
      dataConsistency,
      filterProps,
      hasCampaignNameColumn,
      isPreview,
      loading,
      pagination,
      sortableProps,
      widget,
      widgetData,
    }: Pick<TableOptimizationComponentPropsT, "widget" | "dataConsistency" | "filterValues" | "widgetData"> & {
      dateGrouping: WidgetDateGroupingT;
      filterProps: FilterPropsT;
      hasCampaignNameColumn: boolean;
      isPreview?: boolean;
      loading: boolean;
      pagination: UseGraphqlPaginationResultT;
      sortableProps: SortableAdditionalPropsT;
    }) => {
      const { widgetSettingsData } = useReportBoardContext();
      const { dimensionsById, metricsById } = widgetSettingsData;
      const [paginationPropsWithCallbacks] = pagination;

      const [popup, setPopup] = useState<{ action: string | null; campaignId: string | null; isFirstCell?: boolean }>({
        action: null,
        campaignId: null,
      });

      const isFakeData = dataConsistency === DataConsistencyT.FakeDataT;

      const { widgetDimensions, widgetMetrics } = widget;
      const includedData = widgetData?.includedData;
      const rows = widgetData?.rows.nodes as WidgetDataRowT[] | undefined;
      const summary = widgetData?.summary.nodes;

      const getCampaignData = useCallback(
        (ids: { campaign: { id: string } }[]) =>
          includedData?.campaignsData.filter((data) => ids.some((idRow) => idRow.campaign.id === data.campaign.id)),
        [includedData?.campaignsData]
      );

      const campaignIncludedData = useMemo(
        () => ({
          budgetsById: createHashMap(includedData?.budgets || [], "id"),
          biddingsById: createHashMap(includedData?.biddings || [], "id"),
          userCampaignActions: createHashMap(includedData?.userCampaignActionsLast7Days || [], "id"),
          getCampaignsWithSameBudget: (budgetId: string) =>
            includedData?.campaignsData.filter((campaign) => budgetId === campaign.campaign.budgetId) || [],
          getCampaignsWithSameStrategy: (biddingId: string) =>
            includedData?.campaignsData.filter((campaign) => biddingId === campaign.campaign.biddingId) || [],
        }),
        [includedData]
      );

      const shouldShowDateRange = widget.dateGrouping !== WidgetDateGroupingT.SummaryT;
      const shouldShowInlineTotal = !(widget.widgetDimensions.length === 0 && !shouldShowDateRange);

      return (
        <>
          <div className="d-flex flex-column h-100" data-test-id="optimization-table">
            <div className="delimiter mb-8 negative-ml-24 negative-mr-16" />
            <Row>
              <Col type="grow">
                <TableOptimizationFilter filterProps={filterProps} />
              </Col>
              <Col>
                <ReloadReportCampaignData buttonProps={{ variant: "secondary", icon: "repeat" }} />
              </Col>
            </Row>

            {loading ? (
              <Loader data-test-id="widget-loader" size="big" />
            ) : (
              <>
                <Table
                  doNotStickFirstColumn={!shouldShowInlineTotal}
                  isWithFocus={!!popup.campaignId}
                  style={{ border: "none" }}
                  isExpanded
                  isWithLastRowDelimiter
                  isWithSmallHeader
                >
                  <THead>
                    <TrHeader>
                      {widgetDimensions.map((dim, index) => (
                        <Th
                          key={`${fetchDimensionId(dim)}__${index}`}
                          style={{ width: "320px", maxWidth: "320px", minWidth: "320px" }}
                        >
                          <SortableWrapper {...sortableProps} sortByValue={dim.dimensionId}>
                            <ShowDimensionMetricPill item={dimensionsById[dim.dimensionId]} />
                          </SortableWrapper>
                        </Th>
                      ))}
                      {widgetMetrics.map((met, index) => (
                        <Th key={`${fetchMetricId(met)}__${index}`} justifyRight>
                          <SortableWrapper
                            {...sortableProps}
                            defaultDirection={metricsById[met.metricId]?.positiveTrend ? "asc" : "desc"}
                            sortByValue={met.metricId}
                          >
                            <ShowDimensionMetricPill item={metricsById[met.metricId]} />
                            {met.dateRange ? formatDateRangeSelection(met.dateRange) : null}
                          </SortableWrapper>
                        </Th>
                      ))}
                      {widget.campaignDataColumns.map((met) => (
                        <Th key={met}>{met}</Th>
                      ))}
                      <Th>State fetched at</Th>
                    </TrHeader>
                  </THead>
                  <TBody>
                    {rows?.map((row, index) => {
                      return (
                        <TableOptimizationRow
                          key={`row-${row.id}`}
                          campaignIncludedData={campaignIncludedData}
                          getCampaignData={getCampaignData}
                          hasCampaignNameColumn={hasCampaignNameColumn}
                          isFakeData={isFakeData}
                          isHiglighted={index % 2 !== 0}
                          isPreview={isPreview}
                          popup={popup}
                          row={row}
                          searchValue={filterProps.filterForm.search}
                          setPopup={setPopup}
                          widget={widget}
                          widgetSettingsData={widgetSettingsData}
                        />
                      );
                    })}
                  </TBody>
                  <TFoot>
                    {summary?.map((row) => (
                      <TrFooter key={`row--${row.id}`} className="Sticky-Row">
                        {widgetDimensions.map((dim, dimIndex) => (
                          <Td key={`${fetchDimensionId(dim)}__${dimIndex}`}>{dimIndex === 0 && "Total: "}</Td>
                        ))}

                        {widgetMetrics.map((met, metricIndex) => {
                          const foundMetric = row.widgetMetrics.find((wd) => wd.widgetMetricId == fetchMetricId(met));
                          return (
                            <Td key={`${fetchMetricId(met)}__${metricIndex}`} justifyRight>
                              <TrendWrapper
                                positiveTrend={metricsById[met.metricId].positiveTrend as boolean}
                                trend={foundMetric?.trend}
                                compare={
                                  (widget.dateGrouping === WidgetDateGroupingT.SummaryT ? met.compare : undefined) ||
                                  widget.compare
                                }
                                previousValue={formatMetricValue({
                                  metric: metricsById[met.metricId],
                                  currency: row.currency,
                                  value: foundMetric?.previousValue,
                                })}
                              >
                                {!shouldShowInlineTotal && "Total: "}
                                {formatMetricValue({
                                  metric: metricsById[met.metricId],
                                  currency: row.currency,
                                  value: foundMetric?.value,
                                })}
                              </TrendWrapper>
                            </Td>
                          );
                        })}
                        {widget.campaignDataColumns.map((col) => (
                          <Td key={`campaignDataColumns-${col}`} />
                        ))}
                        <Td />
                      </TrFooter>
                    ))}
                  </TFoot>
                </Table>

                <div className="mt-a">
                  <Pagination className="mt-16" testId={`aa-table-pag`} isCompact {...paginationPropsWithCallbacks} />
                </div>
              </>
            )}
          </div>
        </>
      );
    }
  )
);

const useGetFilters = ({
  campaignNameDim,
  dimensions,
  filterForm,
  hasCampaignNameColumn,
  statusDim,
}: {
  campaignNameDim?: Pick<WidgetDimensionT, "id">;
  dimensions: Pick<WidgetDimensionT, "dimensionId">[];
  filterForm: TableOptimizationFilterT;
  hasCampaignNameColumn: boolean;
  statusDim?: Pick<WidgetDimensionT, "id">;
}) => {
  const filterStatusGroupId = useRef(uuid());
  const filterSearchGroupId = useRef(uuid());

  const statusValues = STATUS_OPTIONS.find((option) => option.value === filterForm.status)?.statuses?.map((status) =>
    status.toLocaleLowerCase()
  );

  const statusFilter = useMemo(
    () =>
      !statusValues?.length
        ? []
        : [
            {
              groupId: filterStatusGroupId.current,
              dimension: { id: statusDim?.id || "", operator: DimensionOperatorT.EqT },
              values: statusValues,
            },
          ],
    [statusDim, statusValues]
  );

  const searchFilter = useMemo(
    () =>
      !filterForm.search
        ? []
        : dimensions.map((dimension) => ({
            groupId: filterSearchGroupId.current,
            dimension: { id: dimension?.dimensionId, operator: DimensionOperatorT.MatchT },
            values: [filterForm.search],
          })),
    [filterForm.search, dimensions]
  );

  const searchFilterCampaignName = useMemo(
    () =>
      hasCampaignNameColumn || !campaignNameDim
        ? []
        : [
            {
              groupId: filterSearchGroupId.current,
              dimension: { id: campaignNameDim.id, operator: DimensionOperatorT.MatchT },
              values: [filterForm.search],
            },
          ],
    [campaignNameDim, hasCampaignNameColumn, filterForm.search]
  );

  return useMemo(
    () => [...statusFilter, ...searchFilter, ...searchFilterCampaignName],
    [statusFilter, searchFilter, searchFilterCampaignName]
  );
};

const getDefaultFilter = (): TableOptimizationFilterT => ({
  status: "active-paused",
  search: "",
});

const WidgetTableOptimizationComponent = ({
  filterValues,
  isPreview,
  onWidgetDataChange,
  widget,
  widgetContext,
  ...rest
}: TableOptimizationComponentPropsT) => {
  const {
    widgetSettingsData: { dimensions },
  } = useReportBoardContext();
  const [isFirstLoadEnded, setIsFirstLoadEnded] = useState(false);
  const [paginationPropsWithCallbacks, graphqlPaginationVariables, setPageInfo] = useGraphqlPagination();
  const { onSetPageIndex } = paginationPropsWithCallbacks;
  const sortableDefault = widget?.properties?.sortByMetricsAndDimensions;
  const sortPropsWithCallbacks = useGraphqlSortable(sortableDefault);
  const [filterForm, setFilterForm] = useState<TableOptimizationFilterT>(getDefaultFilter());

  const statusDimension = useMemo(
    () => dimensions.find((dimension) => dimension.originalName === DIMENSION_CAMPAIGN_STATUS_ORIGINAL_NAME),
    [dimensions]
  );
  const campaignNameDimension = useMemo(
    () => dimensions.find((dimension) => dimension.originalName === DIMENSION_CAMPAIGN_ORIGINAL_NAME),
    [dimensions]
  );
  const hasCampaignNameColumn = useMemo(
    () => widget.widgetDimensions.some((dim) => dim.dimensionId === campaignNameDimension?.id),
    [widget.widgetDimensions, campaignNameDimension?.id]
  );

  const filters = useGetFilters({
    filterForm,
    statusDim: statusDimension,
    dimensions: widget.widgetDimensions,
    campaignNameDim: campaignNameDimension,
    hasCampaignNameColumn,
  });

  const result = useGetWidgetComponentData({
    filterValues,
    widget,
    useCampaignData: true,
    onWidgetDataChange,
    filters,
    pagination: graphqlPaginationVariables,
    order: sortPropsWithCallbacks,
  });

  setPageInfo(result.widgetData?.rows.pageInfo);

  useEffect(() => {
    if (!result.loading) {
      setIsFirstLoadEnded(true);
    }
  }, [result.loading]);

  const { direction, onSort, sortBy } = sortPropsWithCallbacks;

  useEffect(() => {
    if (isPreview && widgetContext?.widgetUpdate) {
      widgetContext.widgetUpdate({
        setWidget: (prev) =>
          set(cloneDeep(prev), ["properties", "sortByMetricsAndDimensions"], {
            direction: direction,
            sortBy: sortBy,
          }),
        widgetId: widget.id,
      });
    }
  }, [isPreview, direction, sortBy, widget.id, widgetContext]);

  useEffect(() => {
    if (sortableDefault && sortableDefault.direction && sortableDefault.sortBy) {
      onSort({
        direction: sortableDefault.direction,
        sortBy: sortableDefault.sortBy,
      });
    }
  }, [sortableDefault, onSort]);

  // clear filters/pagination when widget changed
  useEffect(() => onSetPageIndex(0), [onSetPageIndex, filterForm]);
  useEffect(() => setFilterForm(getDefaultFilter()), [widget.widgetDimensions]);

  return (
    <WidgetTableOptimizationComponentContent
      {...rest}
      {...result}
      dateGrouping={widget.dateGrouping || WidgetDateGroupingT.SummaryT}
      filterProps={{ filterForm, setFilterForm }}
      filterValues={filterValues}
      hasCampaignNameColumn={hasCampaignNameColumn}
      isFirstLoadEnded={isFirstLoadEnded}
      isPreview={isPreview}
      loading={result.loading}
      pagination={[paginationPropsWithCallbacks, graphqlPaginationVariables, setPageInfo]}
      sortableProps={sortPropsWithCallbacks}
      widget={widget}
    />
  );
};

export default widgetComponentContainer(WidgetTableOptimizationComponent);
