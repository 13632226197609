import { Buffer } from "buffer";
import React, { useCallback, useState } from "react";
import { addHours } from "date-fns";
import md5 from "md5";
import image from "../assets/chain_segment_stared.svg";
import { withApiStateHandler } from "../components/ErrorLoadingWrapper/withApiStateHandler";
import { PasswordBox } from "../components/passwordBox/PasswordBox";
import { ReporBoardProvider } from "../components/reportBoard/context/reportBoardContext";
import { ReportBoard } from "../components/reportBoard/ReportBoard";
import { getCookie } from "../functions/cookies";
import { PageSharedReportQueryT, usePageSharedReportQuery } from "../graphql/generated/graphql";
import { usePrevious } from "../hooks/usePrevious";
import { Layout } from "../layout/wrappers/Layout";
import { useAppSettings } from "../providers/appSettingsProvider";
import { EmptyState } from "../ui/Table/EmptyState";

const EXPIRATION_HOURS = 24;

type PropsT = { data?: PageSharedReportQueryT };

const SharedReportComponent = withApiStateHandler(({ data }: PropsT) => {
  return (
    <Layout>
      {data?.sharedReport && (
        <ReporBoardProvider report={data.sharedReport} reportOptions={{ canEdit: false }}>
          <ReportBoard />
        </ReporBoardProvider>
      )}
    </Layout>
  );
});

export const SharedReportPage = () => {
  const { reportToken, setAppSettingsState } = useAppSettings();

  const cookieName = `shared-report-password-${md5(reportToken || "")}`;
  const persistedPassword = getCookie(cookieName);

  const [needPassword, setNeedPassword] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [reportPassword, setReportPassword] = useState<string | undefined>(persistedPassword || undefined);
  const prevReportPassword = usePrevious(reportPassword);

  const setPasswordCookie = useCallback(() => {
    document.cookie = `${cookieName}=${reportPassword}; expires=${addHours(new Date(), EXPIRATION_HOURS).toString()}`;
  }, [reportPassword, cookieName]);

  const { data, error, loading } = usePageSharedReportQuery({
    variables: { reportToken, reportPassword },
    skip: !reportToken,
    onError: (requestError) => {
      if (requestError.message.indexOf("403") !== -1) {
        if (needPassword) {
          setInvalidPassword(true);
        } else {
          setNeedPassword(true);
        }
      }
    },
    onCompleted: () => {
      if (reportPassword && prevReportPassword !== reportPassword) {
        setAppSettingsState((prev) => ({
          ...prev,
          sharedReportPassword: reportPassword,
        }));
        setInvalidPassword(false);
        setNeedPassword(false);
        setPasswordCookie();
      }
    },
  });

  if (needPassword) {
    return (
      <Layout isCentered>
        <PasswordBox
          error={invalidPassword ? "Password is invalid" : undefined}
          loading={loading}
          onSubmit={(password) => {
            const newPassword = Buffer.from(password, "binary").toString("base64");
            setReportPassword(newPassword);
            if (reportPassword === newPassword) {
              setInvalidPassword(true);
            }
          }}
        />
      </Layout>
    );
  }

  if (loading) {
    return (
      <Layout isCentered>
        <div className="Loader Loader--big" />
      </Layout>
    );
  }

  if (!reportToken) {
    return (
      <Layout isCentered>
        <div>No token presented</div>
      </Layout>
    );
  }

  if (!data?.sharedReport) {
    return (
      <Layout isCentered>
        <EmptyState
          description="Oops! We're sorry, but it looks like the link you're trying to access either doesn't exist or has been disabled by the owner of the report. If you believe this is an error, please contact the owner of the report for further information."
          heading="Link Unavailable, Please Contact Owner for Access"
          image={image}
        />
      </Layout>
    );
  }

  return <SharedReportComponent data={data} error={error} loading={loading} />;
};
