import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { VALIDATIONS } from "../../constants/validations";
import { Button, ButtonTertiary } from "../../ui/Button/Button";
import Input from "../../ui/forms/Input";
import { Modal, ModalBody, ModalFooter } from "../../ui/Modal";
import { ConfirmationDialogOptionT } from "./confirmationDialogContext";

type PropsT = {
  isConfirmationPending: boolean;
  onClose: () => void;
  onConfirm: (value: string) => void;
  options: ConfirmationDialogOptionT;
  testId?: string | null;
};

export const ConfirmationDialog = ({ isConfirmationPending, onClose, onConfirm, options, testId }: PropsT) => {
  const [inputValue, setInputValue] = useState(options.inputProps?.defaultValue || "");
  const [shouldShowError, setShouldShowError] = useState(false);

  const inputError = useMemo(
    () =>
      options.inputProps?.isRequired && inputValue === "" ? { message: VALIDATIONS.required, type: "" } : undefined,
    [options.inputProps?.isRequired, inputValue]
  );

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = useCallback(async () => {
    setShouldShowError(true);
    if (!inputError) {
      onConfirm(inputValue);
    }
  }, [onConfirm, inputError, inputValue]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value);
    setShouldShowError(true);
  };

  const isInputVisible = options.hasInput || !!options.inputProps;
  const isInputValueChanged = isInputVisible
    ? inputValue !== options.inputProps?.defaultValue || options.inputProps?.canBeValueNotChanged
    : false;

  return (
    <Modal
      close={handleClose}
      heading={options.title || "Are you sure about that?"}
      portalId="dee-popup-js"
      size="small"
      testId={testId || "confirmation-modal"}
      zIndex="high"
      isOpen
    >
      <ModalBody>
        <div>{options.description}</div>
        {isInputVisible && (
          <Input
            className="mt-16"
            error={shouldShowError ? inputError : undefined}
            label={options.inputProps?.label}
            inputProps={{
              disabled: isConfirmationPending,
              name: "confirmText",
              onChange: handleInputChange,
              value: inputValue,
              autoFocus: true,
            }}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={isInputVisible ? !isInputValueChanged || (shouldShowError ? !!inputError : undefined) : undefined}
          loading={isConfirmationPending}
          variant={options.confirmButtonProps?.variant}
          onClick={handleConfirm}
        >
          {options.confirmButtonProps?.text}
        </Button>
        <ButtonTertiary autoFocus={!isInputVisible} className="ml-16" onClick={onClose}>
          Cancel
        </ButtonTertiary>
      </ModalFooter>
    </Modal>
  );
};
