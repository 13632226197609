import { CSSProperties } from "react";
import cs from "classnames";
import { MATCHING_DIMENSIONS } from "../../constants/report";
import { SystemNameT } from "../../graphql/generated/graphql";
import { Badge, BadgePropsT } from "../../ui/Badge/Badge";
import { Ellipsis } from "../../ui/Ellipsis/Ellipsis";
import { MetricDimensionItemIcon } from "../../ui/forms/MetricDimensionItemIcon";
import { SelectableDimensionT } from "../../ui/forms/MetricDimensionSelectTabs";
import { Tag } from "../../ui/Tag/Tag";
import { Tooltip } from "../../ui/Tooltip/Tooltip";

export type DimensionMetricContentPropsT = {
  isMatchingDimension?: boolean;
  item: ShowDimensionMetricPillItemT & { originalName?: string };
};

export type ShowDimensionMetricPillItemT = Pick<
  SelectableDimensionT,
  "adSystems" | "analyticsSystems" | "name" | "category" | "id"
>;

export type ShowDimensionMetricPillPropsT = {
  cursor?: CSSProperties["cursor"];
  isBadgeHidden?: boolean;
  isDisabled?: boolean;
  item: ShowDimensionMetricPillItemT & { originalName?: string | undefined };
} & Omit<BadgePropsT, "kind">;

const DimensionMetricContent = ({ isMatchingDimension, item }: DimensionMetricContentPropsT) => {
  return (
    <div className="d-flex flex-gap-4 align-items-center mw-100">
      {item.category === "CUSTOM_VALUE" || item.category === "CUSTOM" ? (
        <MetricDimensionItemIcon icon="custom" />
      ) : (
        <>
          {item.adSystems && item.adSystems.length > 0 && (
            <MetricDimensionItemIcon allIconKind="adsystems" icon={item.adSystems[0] as SystemNameT} />
          )}

          {item.analyticsSystems && item.analyticsSystems.length > 0 && !isMatchingDimension && (
            <MetricDimensionItemIcon icon={item.analyticsSystems[0] as SystemNameT} />
          )}
        </>
      )}

      <Ellipsis className="Text--medium">{item.name}</Ellipsis>
    </div>
  );
};

export const ShowDimensionMetricPill = ({
  isBadgeHidden = false,
  isDisabled,
  item,
  onClick,
  ...rest
}: ShowDimensionMetricPillPropsT) => {
  const isMatchingDimension = MATCHING_DIMENSIONS.includes(item?.originalName || item.name);
  const Content = () => <DimensionMetricContent isMatchingDimension={isMatchingDimension} item={item} />;
  const generalProps = {
    size: "extraLarge" as const,
    testId: rest?.testId ? rest.testId : `metDim-option--${item.name}`,
  };

  return (
    <Tooltip tooltipContent={isDisabled ? <>Already selected</> : undefined}>
      <>
        {isBadgeHidden && <DimensionMetricContent isMatchingDimension={isMatchingDimension} item={item} />}

        {!isBadgeHidden && !!onClick && (
          <Tag
            {...generalProps}
            {...rest}
            className={cs(rest.className, { disabled: isDisabled })}
            kind="grape"
            onClick={onClick}
          >
            <Content />
          </Tag>
        )}

        {!isBadgeHidden && !onClick && (
          <Badge {...generalProps} {...rest} className={cs(rest.className)} kind="lightGray">
            <Content />
          </Badge>
        )}
      </>
    </Tooltip>
  );
};
