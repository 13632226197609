import React, { useCallback, useMemo } from "react";
import { AccountSelect } from "../../components/accountSelect/AccountSelect";
import { FormChooseAccountPropsT } from "../../components/formChooseAccount/FormChooseAccount";
import { WidgetSettingsDataT } from "../../components/reportBoard/context/types";
import { getRequiredSystems } from "../../functions/sourceSystemsHelper";
import {
  SystemNameT,
  useNewAccountAccessLinkLazyQueryT,
  useOrganizationSubaccountsQuery,
} from "../../graphql/generated/graphql";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { SourceSystemsT } from "../../types/report";
import { getFiltredSourceSystems } from "./AccountSelectReport";

type PropsT = {
  children?: JSX.Element;
  onSubmit: (sourceSystems: SourceSystemsT) => void;
  selectedMetDims: {
    metrics: string[];
  };
  sourceSystems?: SourceSystemsT;
  widgetSettingsData: {
    metrics: WidgetSettingsDataT["metrics"];
  };
};

export const AccountSelectKpiSettingConnected = ({
  children,
  onSubmit,
  selectedMetDims,
  sourceSystems,
  widgetSettingsData,
}: PropsT) => {
  const { organization } = useAppSettings();

  const { data: dataSubAccounts, refetch: refetchSubAccounts } = useOrganizationSubaccountsQuery({
    variables: { organizationExternalId: organization.externalId },
  });

  const refetchAllAccountsData = useCallback(() => {
    refetchSubAccounts();
  }, [refetchSubAccounts]);

  const [getAccountLink] = useNewAccountAccessLinkLazyQueryT();

  const subAccounts = dataSubAccounts?.organization?.subAccounts;

  const metrics = useMemo(
    () => widgetSettingsData.metrics.filter(({ id }) => selectedMetDims.metrics.includes(id)),
    [selectedMetDims.metrics, widgetSettingsData.metrics]
  );

  const requiredSystems = getRequiredSystems({
    connectedSystems: sourceSystems || [],
    dimensions: [],
    metrics,
  });

  const handleSubmit: FormChooseAccountPropsT["onSubmit"] = ({ systemIds, systemName }) => {
    const savedSystems = getFiltredSourceSystems({ sourceSystems, systemName });
    onSubmit([...(savedSystems || []), ...systemIds]);
  };

  const handleClearSubaccounts = (systemName: SystemNameT) => {
    const clearedSystems = getFiltredSourceSystems({ sourceSystems, systemName });
    onSubmit(clearedSystems || []);
  };

  return (
    <div>
      <AccountSelect
        children={children}
        getAccountLink={getAccountLink}
        refetchSubAccounts={refetchAllAccountsData}
        requiredSystems={requiredSystems}
        sourceSystems={sourceSystems || []}
        subAccounts={subAccounts}
        isCompactView
        onClearSubaccounts={handleClearSubaccounts}
        onSubmit={handleSubmit}
      />
    </div>
  );
};
