import { useEffect } from "react";
import { FieldPath, FieldValues, UseFormSetError } from "react-hook-form";
import { notifyError } from "../functions/toast";

type ServerErrorsT =
  | { serverErros: { message: string; name: string; type: string }[]; type: "object" }
  | { serverErros: string[]; type: "string" };

const parseServerErrors = (errors: string[]): ServerErrorsT => {
  try {
    const parsedErrors = errors.map((error) => {
      const parsedError = JSON.parse(error);
      const [name, message] = Object.entries(parsedError)[0];
      return { name, message: message as string, type: "custom" };
    });
    return { serverErros: parsedErrors, type: "object" };
  } catch {
    return { serverErros: errors, type: "string" };
  }
};

export const useHandleServerErrors = <T extends FieldValues>({
  errors,
  setError,
  shouldNotify,
}: {
  errors?: string[];
  setError: UseFormSetError<T>;
  shouldNotify?: boolean;
}) => {
  useEffect(() => {
    if (!errors || errors.length === 0) {
      return;
    }
    const { serverErros, type } = parseServerErrors(errors);

    if (type === "string") {
      if (shouldNotify) {
        notifyError(
          <>
            {serverErros.map((error) => (
              <div key={error}>{error}</div>
            ))}
          </>
        );
      }
      return;
    }

    if (shouldNotify) {
      notifyError(
        <>
          {serverErros.map((error) => (
            <div key={[error.name, error.message].join("-")}>
              Error that the field <span className="Text--bold">{error.name}</span> {error.message}
            </div>
          ))}
        </>
      );
    }

    if (!!serverErros[0]) {
    }
    serverErros.map((err) =>
      setError(err.name as FieldPath<T>, { message: err.message, type: err.type }, { shouldFocus: true })
    );
  }, [errors, setError, shouldNotify]);

  return { errors };
};
