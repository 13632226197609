import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useReportPageQuery } from "../../graphql/generated/graphql";
import { usePrevious } from "../../hooks/usePrevious";
import { useUpdateReport } from "../../hooks/useUpdateReport";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { RouteParamsT } from "../../types/router";
import ErrorBox from "../../ui/ErrorBox/ErrorBox";
import Checkbox from "../../ui/forms/Checkbox";

export const TemplateVisibilitySwitcher = () => {
  const { reportId } = useParams<RouteParamsT["report"]>();
  const { organization } = useAppSettings();
  const [isChecked, setIsChecked] = useState(false);
  const prevChecked = usePrevious(isChecked);

  const { data, loading } = useReportPageQuery({
    variables: { organizationExternalId: organization.externalId, reportId: reportId as string },
    onCompleted: (returnedData) => {
      setIsChecked(!!returnedData.organization?.report?.visible);
    },
  });

  const {
    errors,
    loading: updateLoading,
    updateReport,
  } = useUpdateReport({
    successMessage: "Visibility changed",
    sucessCallback: (returnedData) => {
      setIsChecked(returnedData.visible);
    },
  });

  const isVisible = data?.organization?.report?.visible;

  useEffect(() => {
    if (prevChecked !== isChecked && isChecked !== isVisible) {
      updateReport({ visible: isChecked });
    }
  }, [isChecked, isVisible, prevChecked, updateReport]);

  if (!!errors?.length) {
    return <ErrorBox>{errors.join("\n")}</ErrorBox>;
  }

  return (
    <Checkbox
      label="visible"
      input={{
        id: "input-template-visibility",
        checked: isChecked,
        disabled: updateLoading || loading,
        onChange: () => setIsChecked((prev) => !prev),
      }}
    />
  );
};
