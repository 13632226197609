import React from "react";
import { SystemIconBasicPropsT } from "./types";

export const BingIcon = ({ active, size = "16px" }: SystemIconBasicPropsT) => (
  <svg
    className={`system-icon bing-icon ${active && "active"}`}
    height={size}
    version="1.1"
    viewBox="0 0 150 150"
    width={size}
    x="0"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    y="0"
  >
    <path d="M0 0h71.4v71.4H0V0z" fill="#F25022" />
    <path d="M78.6 0H150v71.4H78.7c-.1-23.8-.1-47.6-.1-71.4z" fill="#80BA01" />
    <path d="M0 78.6h71.4V150H0V78.6z" fill="#02A4EF" />
    <path d="M78.6 78.6H150V150H78.6V78.6z" fill="#FFB902" />
  </svg>
);
