import React, { useCallback, useMemo } from "react";
import { exportToCsvWidgetData } from "../../functions/csvHelpers";
import { fetchDimensionId, fetchMetricId } from "../../functions/fetchIds";
import { getWidgetNamePlaceholder } from "../../functions/widgetHelpers";
import {
  Maybe,
  WidgetCompareT,
  WidgetDateGroupingT,
  WidgetKindT,
  WidgetMetricT,
  WidgetRowT,
} from "../../graphql/generated/graphql";
import { formatDateRange, formatDateRangeSelection } from "../../i18n/formatDate";
import { formatMetricValue, formatPercents } from "../../i18n/formatNumbers";
import { WidgetT } from "../../types/widgets";
import { ButtonSecondary } from "../../ui/Button/Button";
import { SortableWrapper } from "../../ui/DataTable/Sortable";
import { TrendWrapper } from "../../ui/DataTable/TrendWrapper";
import { Col, Row } from "../../ui/grid/Grid";
import { Pagination } from "../../ui/Pagination/Pagination";
import { TBody, TFoot, THead, Table, Td, Th, TrBody, TrFooter, TrHeader } from "../../ui/Table/Table";
import { Text } from "../../ui/Text/Text";
import { withApiStateHandler } from "../ErrorLoadingWrapper/withApiStateHandler";
import { COMPARE_TITLES } from "../formEditWidget/inputs/InputCompare";
import { useReportBoardContext } from "../reportBoard/context/reportBoardContext";
import { DimensionsByIdT, MetricsByIdT } from "../reportBoard/context/types";
import { WidgetContextT } from "../reportBoard/ReportBoardSection";
import { ShowDimensionMetricPill } from "../showDimensionMetricPill/ShowDimensionMetricPill";
import { TableComponentPropsT } from "./TableComponent";
import { UseBasicTableResultT, useTable } from "./useBasicTable";

const getCompare = ({ metric, widget }: { metric: WidgetMetricT; widget: WidgetT }) =>
  widget.dateGrouping === WidgetDateGroupingT.SummaryT && widget.kind === WidgetKindT.TableSummaryT
    ? metric.compare
    : widget.compare;

const hasCompare = (params: { metric: WidgetMetricT; widget: WidgetT }) => {
  const compare = getCompare(params);
  return compare && compare !== WidgetCompareT.NoneT;
};

export const getRawWidgetDataForCsv = ({
  dimensionsById,
  metricsById,
  rows,
  summary,
  tableData,
  widget,
}: {
  dimensionsById: DimensionsByIdT;
  metricsById: MetricsByIdT;
  rows?: WidgetRowT[];
  summary?: Maybe<Array<Pick<WidgetRowT, "date" | "dateTo" | "currency" | "widgetMetrics">>>;
  tableData: UseBasicTableResultT;
  widget: WidgetT;
}) => {
  const header = [
    ...(tableData.shouldShowDateRange ? ["Date"] : []),
    ...widget.widgetDimensions.map((dim) => dimensionsById[dim.dimensionId].name),
    ...widget.widgetMetrics.flatMap((met) => {
      const compare = getCompare({ metric: met, widget });
      const colName = `${metricsById[met.metricId].name}${
        met.dateRange && formatDateRangeSelection(met.dateRange) ? ` ${formatDateRangeSelection(met.dateRange)}` : ""
      }`;

      return [
        colName,
        ...(hasCompare({ metric: met, widget })
          ? [
              `${colName} - ${COMPARE_TITLES[compare as WidgetCompareT]} value`,
              `${colName} - ${COMPARE_TITLES[compare as WidgetCompareT]} ratio`,
            ]
          : []),
      ];
    }),
  ];

  const body = (rows || []).map((row) => [
    ...(tableData.shouldShowDateRange ? [formatDateRange(row.date, row.dateTo)] : []),
    ...widget.widgetDimensions.map(
      (dim) => row.widgetDimensions.find((wd) => wd.widgetDimensionId == fetchDimensionId(dim))?.value || ""
    ),
    ...widget.widgetMetrics.flatMap((met) => {
      const foundMetric = row.widgetMetrics.find((wm) => wm.widgetMetricId == fetchMetricId(met));
      return [
        formatMetricValue({
          metric: metricsById[met.metricId],
          currency: row.currency,
          value: foundMetric?.value,
        }),
        ...(hasCompare({ metric: met, widget })
          ? [
              formatMetricValue({
                metric: metricsById[met.metricId],
                currency: row.currency,
                value: foundMetric?.previousValue,
              }),
              formatPercents(foundMetric?.trend, 0),
            ]
          : []),
      ];
    }),
  ]);

  const footer =
    (summary || [])?.length === 0
      ? []
      : (summary || [])?.map((row) => [
          ...(tableData.shouldShowDateRange ? [`Total for ${formatDateRange(row.date, row.dateTo)}`] : []),
          ...widget.widgetDimensions.map((dim, dimIndex) =>
            !tableData.shouldShowDateRange && dimIndex === 0 ? "Total: " : ""
          ),
          ...widget.widgetMetrics.flatMap((met) => {
            const foundMetric = row.widgetMetrics.find((wm) => wm.widgetMetricId == fetchMetricId(met));
            return [
              `${!tableData.shouldShowInlineTotal ? "Total: " : ""} ${formatMetricValue({
                metric: metricsById[met.metricId],
                currency: row.currency,
                value: foundMetric?.value,
              })}`,
              ...(hasCompare({ metric: met, widget })
                ? [
                    formatMetricValue({
                      metric: metricsById[met.metricId],
                      currency: row.currency,
                      value: foundMetric?.previousValue,
                    }),
                    formatPercents(foundMetric?.trend, 0),
                  ]
                : []),
            ];
          }),
        ]);

  return [header, ...body, ...footer];
};

export const WidgetTableComponentContent = withApiStateHandler(
  ({
    filterData,
    isFullWidth = true,
    isPreview,
    isRawDataPreview,
    widget,
    widgetContext,
    widgetData,
  }: Pick<TableComponentPropsT, "filterData" | "widgetData" | "filterValues"> & {
    isFullWidth?: boolean;
    isPreview?: boolean;
    isRawDataPreview?: boolean;
    widget: WidgetT;
    widgetContext?: WidgetContextT;
  }) => {
    const rows = widgetData?.rows.nodes as WidgetRowT[] | undefined;
    const summary = widgetData?.summary.nodes;

    const {
      widgetSettingsData: { dimensionsById, metricsById },
    } = useReportBoardContext();

    const widgetPlaceholderName = useMemo(
      () =>
        getWidgetNamePlaceholder({
          widgetMetrics: widget.widgetMetrics,
          widgetDimensions: widget.widgetDimensions,
          metricsById: metricsById,
          dimensionsById: dimensionsById,
        }),
      [widget.widgetMetrics, widget.widgetDimensions, metricsById, dimensionsById]
    );

    const { widgetDimensions, widgetMetrics } = widget;

    const filteredRows = useMemo(() => {
      return filterData ? (filterData(rows || []) as WidgetRowT[]) : rows || [];
    }, [filterData, rows]);

    const tableData = useTable({
      onWidgetUpdate: isPreview ? widgetContext?.widgetUpdate : undefined,
      widget,
      rows: filteredRows,
      type: "table",
    });

    const { paginatedWidgetData, paginationProps, shouldShowDateRange, shouldShowInlineTotal, sortableProps } =
      tableData;

    const handleExportCsv = useCallback(() => {
      exportToCsvWidgetData({
        data: getRawWidgetDataForCsv({ rows, summary, widget, tableData, metricsById, dimensionsById }),
        widgetName: widget.name || widgetPlaceholderName,
      });
    }, [rows, summary, widget, tableData, metricsById, dimensionsById, widgetPlaceholderName]);

    return (
      <>
        {isRawDataPreview && (
          <Row className="mv-8" justify="end">
            <ButtonSecondary
              data-test-id="export-to-csv-button"
              icon="feed-export"
              size="small"
              onClick={handleExportCsv}
            >
              Export lines (CSV)
            </ButtonSecondary>
          </Row>
        )}

        <div className="d-flex flex-column h-100" data-test-id="widget-table-component">
          <Table
            doNotStickFirstColumn={!shouldShowInlineTotal}
            isExpanded={isFullWidth}
            isWithLastRowDelimiter={(summary || [])?.length === 0}
            style={{ ...(isFullWidth ? { border: "none" } : {}) }}
            isWithSmallHeader
          >
            <THead>
              <TrHeader>
                {shouldShowDateRange && (
                  <Th>
                    <SortableWrapper {...sortableProps} sortByValue="date">
                      Date
                    </SortableWrapper>
                  </Th>
                )}
                {widgetDimensions.map((dim, index) => (
                  <Th key={`${fetchDimensionId(dim)}__${index}`}>
                    <SortableWrapper {...sortableProps} sortByValue={dim.dimensionId}>
                      <ShowDimensionMetricPill item={dimensionsById[dim.dimensionId]} />
                    </SortableWrapper>
                  </Th>
                ))}
                {widgetMetrics.map((met, index) => (
                  <Th key={`${fetchMetricId(met)}__${index}`} justifyRight>
                    <SortableWrapper
                      {...sortableProps}
                      defaultDirection={metricsById[met.metricId]?.positiveTrend ? "asc" : "desc"}
                      sortByValue={fetchMetricId(met)}
                    >
                      <Col>
                        <Row>
                          <ShowDimensionMetricPill item={metricsById[met.metricId]} />
                        </Row>
                        {(!!met.dateRange || hasCompare({ metric: met, widget })) && (
                          <Row>
                            <Text color="gray" size="xs">
                              {[
                                ...(met?.dateRange ? [formatDateRangeSelection(met?.dateRange)] : []),
                                ...(hasCompare({ metric: met, widget }) ? [getCompare({ metric: met, widget })] : []),
                              ].join(" | ")}
                            </Text>
                          </Row>
                        )}
                      </Col>
                    </SortableWrapper>
                  </Th>
                ))}
                {widget.campaignDataColumns.map((met) => (
                  <Th key={met}>{met}</Th>
                ))}
              </TrHeader>
            </THead>
            <TBody>
              {paginatedWidgetData.map((row) => (
                <TrBody key={`row--${row.id}`}>
                  {shouldShowDateRange && <Td>{formatDateRange(row.date, row.dateTo)}</Td>}
                  {widgetDimensions.map((dim, dimIndex) => (
                    <Td key={`${fetchDimensionId(dim)}__${dimIndex}`}>
                      {row.widgetDimensions.find((wd) => wd.widgetDimensionId == fetchDimensionId(dim))?.value}
                    </Td>
                  ))}
                  {widgetMetrics.map((met, metIndex) => {
                    const foundMetric = row.widgetMetrics.find((wm) => wm.widgetMetricId == fetchMetricId(met));

                    return (
                      <Td key={`${fetchMetricId(met)}__${metIndex}`} justifyRight>
                        <TrendWrapper
                          compare={getCompare({ metric: met, widget })}
                          positiveTrend={!!metricsById[met.metricId]?.positiveTrend}
                          trend={foundMetric?.trend}
                          previousValue={formatMetricValue({
                            metric: metricsById[met.metricId],
                            currency: row.currency,
                            value: foundMetric?.previousValue,
                          })}
                        >
                          {formatMetricValue({
                            metric: metricsById[met.metricId],
                            currency: row.currency,
                            value: foundMetric?.value,
                          })}
                        </TrendWrapper>
                      </Td>
                    );
                  })}
                </TrBody>
              ))}
            </TBody>
            {(summary || [])?.length > 0 && (
              <TFoot>
                {summary?.map((row, index) => (
                  <TrFooter key={`row--${index}`}>
                    {shouldShowDateRange && <Td>Total for {formatDateRange(row.date, row.dateTo)}</Td>}
                    {widgetDimensions.map((dim, dimIndex) => (
                      <Td key={`${fetchDimensionId(dim)}__${dimIndex}`}>
                        {!shouldShowDateRange && dimIndex === 0 && "Total: "}
                      </Td>
                    ))}

                    {widgetMetrics.map((met, metricIndex) => {
                      const foundMetric = row.widgetMetrics.find((wd) => wd.widgetMetricId == fetchMetricId(met));
                      return (
                        <Td key={`${fetchMetricId(met)}__${metricIndex}`} justifyRight>
                          <TrendWrapper
                            compare={getCompare({ metric: met, widget })}
                            positiveTrend={!!metricsById[met.metricId]?.positiveTrend}
                            trend={foundMetric?.trend}
                            previousValue={formatMetricValue({
                              metric: metricsById[met.metricId],
                              currency: row.currency,
                              value: foundMetric?.previousValue,
                            })}
                          >
                            {!shouldShowInlineTotal && "Total: "}
                            {formatMetricValue({
                              metric: metricsById[met.metricId],
                              currency: row.currency,
                              value: foundMetric?.value,
                            })}
                          </TrendWrapper>
                        </Td>
                      );
                    })}
                  </TrFooter>
                ))}
              </TFoot>
            )}
          </Table>
          <div className="mt-a">
            <Pagination className="mt-16" testId="table-pag" isCompact {...paginationProps} />
          </div>
        </div>
      </>
    );
  }
);
