import React, { ReactElement, ReactNode, cloneElement, useState } from "react";
import {
  FloatingPortal,
  Placement,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import cs from "classnames";

const DELAY = {
  sm: 1200,
};

export type TooltipPropsT = {
  children: ReactElement;
  className?: string;
  enterDelay?: keyof typeof DELAY | number;
  placement?: Placement;
  roleType?: "dialog" | "menu" | "tooltip" | "alertdialog" | "listbox" | "grid" | "tree";
  tooltipContent: ReactNode;
};

export const Tooltip = ({
  children,
  className,
  enterDelay,
  placement = "top",
  roleType = "tooltip",
  tooltipContent,
}: TooltipPropsT) => {
  const [isOpen, setIsOpen] = useState(false);

  const { context, floating, reference, strategy, x, y } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [offset(4), flip(), shift()],
  });

  const hover = useHover(context, {
    move: false,
    handleClose: safePolygon({
      buffer: 1,
    }),
    delay: {
      open: enterDelay
        ? DELAY.hasOwnProperty(enterDelay)
          ? DELAY[enterDelay as keyof typeof DELAY]
          : (enterDelay as number)
        : (enterDelay as undefined),
    },
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: roleType });

  const { getFloatingProps, getReferenceProps } = useInteractions([hover, focus, dismiss, role]);

  if (!tooltipContent) {
    return children;
  }

  return (
    <>
      {cloneElement(children, {
        ...getReferenceProps({
          ref: reference,
        }),
      })}
      <FloatingPortal id="dee-popup-js">
        {isOpen && (
          <div
            ref={floating}
            className={cs(className, { "tooltip-inner mb-16": roleType === "tooltip" })}
            style={{
              position: strategy,
              zIndex: 10010,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
          >
            {tooltipContent}
          </div>
        )}
      </FloatingPortal>
    </>
  );
};
