import { PropsWithChildren, ReactNode, createContext, useCallback, useContext, useState } from "react";
import { merge } from "lodash";
import { ButtonVariantsT } from "../../ui/Button/Button";
import { ConfirmationDialog } from "./ConfirmationDialog";

export type ConfirmationDialogOptionT = {
  confirmButtonProps?: {
    text?: string | null;
    variant?: ButtonVariantsT;
  };
  description?: string | null;
  hasInput?: boolean;
  inputProps?: {
    canBeValueNotChanged?: boolean;
    defaultValue?: string;
    isRequired?: boolean;
    label?: string;
  } | null;
  testId?: string | null;
  title?: ReactNode | null;
};

export type ConfirmationDialogCallbackT<IsWithClose = false> = IsWithClose extends true
  ? (onClose: () => void, param?: string) => void
  : (param?: string) => void;

type ContextReturnStateT = {
  callWithConfirmation: (
    confirmationCallback: ConfirmationDialogCallbackT,
    options?: ConfirmationDialogOptionT
  ) => void;
  callWithConfirmationManualClose: (
    confirmationCallback: ConfirmationDialogCallbackT<true>,
    options?: ConfirmationDialogOptionT
  ) => void;
};

const ConfirmationContext = createContext<ContextReturnStateT | null>(null);

const getDefaultoptions = (): Required<ConfirmationDialogOptionT> => ({
  description: null,
  testId: null,
  title: null,
  confirmButtonProps: {
    text: "Confirm",
    variant: "primary",
  },
  hasInput: false,
  inputProps: null,
});

export const ConfirmationDialogProvider = <T extends boolean>({ children }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false);
  const [manualClose, setManualClose] = useState(false);
  const [isConfirmationPending, setIsConfirmationPending] = useState(false);
  const [callback, setCallback] = useState<ConfirmationDialogCallbackT<T> | null>(null);
  const [options, setOptions] = useState(getDefaultoptions());

  const changeOptions = useCallback(
    (overideOptions?: ConfirmationDialogOptionT) =>
      setOptions((prev) => merge({ ...prev }, { ...(overideOptions || {}) })),
    []
  );

  const callWithConfirmation = useCallback(
    (
      confirmCallback: ConfirmationDialogCallbackT,
      customOptions?: Omit<ConfirmationDialogOptionT, "isConfirmationPending">
    ) => {
      if (isOpen) {
        return;
      }
      setCallback(() => confirmCallback);
      setIsOpen(true);
      changeOptions(customOptions);
    },
    [isOpen, changeOptions]
  );

  const callWithConfirmationManualClose = useCallback(
    (
      confirmCallback: ConfirmationDialogCallbackT<true>,
      customOptions?: Omit<ConfirmationDialogOptionT, "isConfirmationPending">
    ) => {
      if (isOpen) {
        return;
      }
      setManualClose(true);
      setCallback(() => confirmCallback);
      setIsOpen(true);
      changeOptions(customOptions);
    },
    [isOpen, changeOptions]
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setCallback(null);
    setManualClose(false);
    setOptions(getDefaultoptions());
  }, []);

  const handleConfirmWithManualClose = useCallback(
    async (value?: string) => {
      if (callback) {
        const callbackWithManualClose = callback as ConfirmationDialogCallbackT<true>;

        setIsConfirmationPending(true);
        await callbackWithManualClose(handleClose, value);
        setIsConfirmationPending(false);
      }
    },
    [callback, handleClose, setIsConfirmationPending]
  );

  const handleConfirm = useCallback(
    async (value?: string) => {
      if (callback) {
        const callbackWithoutManualClose = callback as ConfirmationDialogCallbackT;

        setIsConfirmationPending(true);
        await callbackWithoutManualClose(value);
        setIsConfirmationPending(false);
      }

      handleClose();
    },
    [callback, handleClose, setIsConfirmationPending]
  );

  const result = {
    callWithConfirmation,
    callWithConfirmationManualClose,
  };

  return (
    <>
      <ConfirmationContext.Provider value={result}>
        {isOpen && (
          <ConfirmationDialog
            isConfirmationPending={isConfirmationPending}
            options={options}
            onClose={handleClose}
            onConfirm={manualClose ? handleConfirmWithManualClose : handleConfirm}
          />
        )}
        {children}
      </ConfirmationContext.Provider>
    </>
  );
};

export const useConfirmationContext = () => {
  const context = useContext(ConfirmationContext);

  if (!context) {
    throw new Error("Confirmation dialog is outside of provider!");
  }

  return context;
};
