import React, { HTMLProps, ReactNode } from "react";

type PropsT = {
  children: ReactNode;
} & HTMLProps<HTMLLabelElement>;

const Label = ({ children, htmlFor, ...rest }: PropsT) => (
  <label htmlFor={htmlFor} {...rest}>
    {children}
  </label>
);

export default Label;
