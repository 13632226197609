import React from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { MetricsAndDimensionsAlias } from "../components/alias/MetricsAndDimensionsAlias";
import { Layout } from "../layout/wrappers/Layout";
import { HeadingPage } from "../ui/Heading/Heading";
import { Tab } from "../ui/Tabs/Tab";
import { TabList } from "../ui/Tabs/TabList";
import { Tabs } from "../ui/Tabs/Tabs";
import { DimensionsPageConnected } from "./Dimensions";
import { MetricsPageConnected } from "./Metrics";
import { ValueMetricsPageConnected } from "./ValueMetrics";

const METRICS = "metrics";
export const DIMENSIONS = "dimensions";
const ALIAS = "alias";

type MetricDimensionTabsPropsT<T = string | null> = { onTabChange: (tab: T) => void; tab: T };
const MetricDimensionTabs = ({ onTabChange, tab }: MetricDimensionTabsPropsT) => (
  <Tabs initialSelectedTab={tab || METRICS} testId="metricsAndDimensions">
    <TabList className="mb-16">
      <Tab id={METRICS} onClick={() => onTabChange(null)}>
        Your Metrics
      </Tab>
      <Tab id={DIMENSIONS} onClick={() => onTabChange(DIMENSIONS)}>
        Your Dimensions
      </Tab>
      <Tab id={ALIAS} onClick={() => onTabChange(ALIAS)}>
        System Metrics & Dimensions
      </Tab>
    </TabList>
  </Tabs>
);

const MetricsAndDimensionsTabsContent = ({ tab }: { tab: string | null }) => {
  if (tab === ALIAS) {
    return <MetricsAndDimensionsAlias />;
  }

  if (tab === DIMENSIONS) {
    return (
      <>
        <DimensionsPageConnected />
      </>
    );
  }

  return (
    <>
      <MetricsPageConnected />
      <ValueMetricsPageConnected />
    </>
  );
};

export const MetricsAndDimensionsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const handleTabChange: MetricDimensionTabsPropsT["onTabChange"] = (newTab) => {
    if (!newTab) {
      searchParams.delete("tab");
    } else {
      searchParams.set("tab", newTab);
    }
    setSearchParams(searchParams);
  };

  return (
    <Layout>
      <HeadingPage>Metrics & Dimensions</HeadingPage>
      <MetricDimensionTabs tab={tab} onTabChange={handleTabChange} />
      <MetricsAndDimensionsTabsContent tab={tab} />
      <Outlet />
    </Layout>
  );
};
