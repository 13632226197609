import React, { ReactElement, useMemo, useState } from "react";
import { groupBy } from "lodash";
import emptyStateImage from "../../assets/chain_segment.png";
import { KpiRoadmapConnected } from "../../componentsConnected/kpiRoadmap/KpiRoadmapConnected";
import { KPI_ACTIVE_STATUSES, ORDERED_KPI_PRIORITIES, ORDERED_KPI_STATUSES } from "../../constants/kpi";
import { ROUTES } from "../../constants/routes";
import { KpiPriorityT, KpiSettingT, KpiStatusT } from "../../graphql/generated/graphql";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { ButtonSecondary } from "../../ui/Button/Button";
import { Col, Row } from "../../ui/grid/Grid";
import { Link } from "../../ui/Link/Link";
import { EmptyState } from "../../ui/Table/EmptyState";
import { Tooltip } from "../../ui/Tooltip/Tooltip";
import { KpiFilter } from "../kpiFilter/KpiFilter";
import { useKpiFilter } from "../kpiFilter/useKpiFilter";
import { KpiListHeading } from "./KpiListHeading";
import { KpiListItem, KpiListItemPropsT } from "./KpiListItem";

type PropsT = {
  children?: ReactElement;
  isCreateBtnHidden?: boolean;
  isDelimiterHidden?: boolean;
  kpiSettings?: KpiListItemPropsT["kpiSetting"][];
  onDeleteKpiSettings: (kpiSettingIds: string[]) => void;
  organizationExternalId?: string | null;
};

const sortByPriority = (a: KpiListItemPropsT["kpiSetting"], b: KpiListItemPropsT["kpiSetting"]) =>
  ORDERED_KPI_PRIORITIES.indexOf(a.priority) - ORDERED_KPI_PRIORITIES.indexOf(b.priority);

const sortByStatus = (a: KpiListItemPropsT["kpiSetting"], b: KpiListItemPropsT["kpiSetting"]) =>
  ORDERED_KPI_STATUSES.indexOf(a.status) - ORDERED_KPI_STATUSES.indexOf(b.status);

export const getActiveKpis = (kpiSettings: KpiListItemPropsT["kpiSetting"][]) =>
  kpiSettings.filter((kpi) => KPI_ACTIVE_STATUSES.includes(kpi.status)).sort(sortByPriority);

export const getNotActiveKpis = (kpiSettings: KpiListItemPropsT["kpiSetting"][]) =>
  kpiSettings
    .filter((kpi) => !KPI_ACTIVE_STATUSES.includes(kpi.status))
    .sort((a, b) => {
      if (ORDERED_KPI_STATUSES.indexOf(a.status) === ORDERED_KPI_STATUSES.indexOf(b.status)) {
        return sortByPriority(a, b);
      }
      return sortByStatus(a, b);
    });

export const KpiList = ({
  children,
  isCreateBtnHidden,
  isDelimiterHidden,
  kpiSettings,
  onDeleteKpiSettings,
  organizationExternalId,
}: PropsT) => {
  const { getOrganizationAppUrl } = useAppSettings();
  const [viewType, setViewType] = useState<"list" | "extendedList" | "roadMap">("list");
  const [hiddenSections, setHiddenSections] = useState<string[]>([]);

  const orgLink = organizationExternalId ? `${getOrganizationAppUrl(organizationExternalId)}` : "";
  const { filterData, isFilterActive, ...filterProps } = useKpiFilter();
  const filteredData = useMemo(() => filterData(kpiSettings || []), [filterData, kpiSettings]);
  const isListEmpty = !filteredData.length;
  const isRoadMapVisible = viewType === "roadMap";
  const activeKpis = useMemo(() => getActiveKpis(filteredData), [filteredData]);
  const notActiveKpis = useMemo(() => getNotActiveKpis(filteredData), [filteredData]);

  const activeGroupedByPriorityKpis = groupBy(activeKpis, "priority") as {
    [K in KpiPriorityT]: KpiSettingT[];
  };
  const notActiveGroupedByStatusKpis = groupBy(notActiveKpis, "status") as {
    [K in KpiStatusT]: KpiSettingT[];
  };
  const groupedBySectionsKpis = { ...activeGroupedByPriorityKpis, ...notActiveGroupedByStatusKpis };

  const handleToggleHiddenSection = (clickedSection: string) => {
    setHiddenSections((prev) => {
      if (prev.includes(clickedSection)) {
        return prev.filter((section) => section !== clickedSection);
      }
      return [clickedSection, ...prev];
    });
  };

  const draftIds = notActiveGroupedByStatusKpis[KpiStatusT.DraftT]?.map((kpiSetting) => kpiSetting.id);

  return (
    <div>
      {(isFilterActive || !isListEmpty) && (
        <>
          <Row>{children}</Row>
          {!isDelimiterHidden && <div className="delimiter mv-16" />}
          <Row className="mt-16 mb-16" padding="xl">
            {!isCreateBtnHidden && (
              <Col>
                <Tooltip enterDelay="sm" tooltipContent="Create new KPI">
                  <ButtonSecondary data-test-id="create-new-kpi" icon="plus" to={ROUTES.kpisNew()} onlyIcon />
                </Tooltip>
              </Col>
            )}
            <Col type="grow">
              <KpiFilter {...filterProps} />
            </Col>

            <Col>
              <div>
                <ButtonSecondary
                  disabled={viewType === "list"}
                  icon="arrange-front"
                  onlyIcon
                  onClick={() => setViewType("list")}
                />
                <ButtonSecondary
                  disabled={viewType === "extendedList"}
                  icon="arrange-backward"
                  onlyIcon
                  onClick={() => setViewType("extendedList")}
                />
                <ButtonSecondary
                  disabled={viewType === "roadMap"}
                  icon="timer"
                  onlyIcon
                  onClick={() => setViewType("roadMap")}
                />
              </div>
            </Col>
          </Row>
        </>
      )}

      {isFilterActive && isListEmpty && (
        <div>None of the items match your search query. Try to modify what you're looking for.</div>
      )}

      {/* TODO: Change/Create image for empty state */}
      {!isFilterActive && isListEmpty && (
        <EmptyState heading="You don’t have any KPIs yet." image={emptyStateImage}>
          <ButtonSecondary data-test-id="create-new-kpi" to={ROUTES.kpisNew()}>
            Create KPI
          </ButtonSecondary>
        </EmptyState>
      )}

      {!isRoadMapVisible && (
        <>
          {(Object.keys(groupedBySectionsKpis) as (keyof typeof groupedBySectionsKpis)[]).map((sectionName) => {
            const isDraft = sectionName === KpiStatusT.DraftT;

            return (
              <div key={sectionName} className="mb-24">
                <Row alignItems="center">
                  <Col type="grow">
                    <KpiListHeading
                      hiddenSections={hiddenSections}
                      sectionName={sectionName}
                      onClick={handleToggleHiddenSection}
                    />
                  </Col>
                  {isDraft && (
                    <Col>
                      <Link color="danger" icon="trash" onClick={() => onDeleteKpiSettings(draftIds)}>
                        Delete all drafts
                      </Link>
                    </Col>
                  )}
                </Row>
                {!hiddenSections.includes(sectionName) &&
                  groupedBySectionsKpis[sectionName].map((kpiSetting) => (
                    <KpiListItem
                      key={kpiSetting.id}
                      isExpandedView={viewType === "extendedList"}
                      kpiSetting={kpiSetting}
                      orgLink={orgLink}
                      onDeleteKpiSettings={onDeleteKpiSettings}
                    />
                  ))}
              </div>
            );
          })}
        </>
      )}

      {isRoadMapVisible && filteredData && (
        <KpiRoadmapConnected
          groupedBySectionsKpis={groupedBySectionsKpis}
          hiddenSections={hiddenSections}
          orgLink={orgLink}
          onToggleHiddenSection={handleToggleHiddenSection}
        />
      )}
    </div>
  );
};
