import { DateRangeEnumT, SystemNameT, WidgetDateGroupingT } from "../graphql/generated/graphql";
import { WidgetCategoryT, WidgetComponentT, WidgetSchemaT } from "../types/widgets";

export const DUPLICATED_PREFIX = "[COPY]";

export enum OptimizationCampaignDataColumnT {
  BiddingT = "bidding",
  BudgetT = "budget",
  StatusT = "status",
}

export const OPTIMIZATION_CAMPAIGN_DATA_COLUMNS = [
  OptimizationCampaignDataColumnT.StatusT,
  OptimizationCampaignDataColumnT.BudgetT,
  OptimizationCampaignDataColumnT.BiddingT,
];

export const METRIC_FORMATS = [
  {
    label: "Label Numeric",
    value: "numeric",
  },
  {
    label: "Label Text",
    value: "text",
  },
];

export const DIMENSION_FORMATS = [
  {
    label: "Label Numeric",
    value: "numeric",
  },
  {
    label: "Label Text",
    value: "text",
  },
];

export const MATCHING_DIMENSIONS = ["Campaign", "Source"];

export const CHART_LINE_COLORS = [
  "#7BBC9A",
  "#478DB8",
  "#E9BA5D",
  "#E46E53",
  "#9C71C6",
  "#6CBDDF",
  "#757595",
  "#9D5373",
  "#B0D364",
  "#AF7C4F",
  "#79AF4F",
  "#4F70AF",
  "#DF7172",
  "#E177C4",
  "#93CB80",
  "#B9A970",
  "#5FC1C8",
  "#615FC8",
  "#C85F78",
  "#805B84",
];

export const CHART_LINE_COLOR_OTHER = "#B8B8B8";

const CHART_GRID_COLOR = "#E5E7E9";

export const CHART_GRID_PROPS = {
  color: CHART_GRID_COLOR,
  strokeDasharray: "3 3",
  strokeWidth: 1,
  vertical: false,
};

export const X_AXIS_STYLES = { tick: { fill: "#98A2B3" }, tickLine: false, stroke: "#CCD0D4" };

export const Y_AXIS_STYLES = { tick: { fill: "#98A2B3" }, tickLine: false, stroke: "#CCD0D4" };

export const REPORT_SECTION_LAYOUT_COLUMNS = { sm: 4 };
export const REPORT_SECTION_LAYOUT_BREAKPOINTS = { sm: 0 };
export const REPORT_BOARD_LAYOUT_COLUMNS = { sm: 1 };
export const REPORT_BOARD_LAYOUT_BREAKPOINTS = { sm: 0 };

export const WIDGET_PROP_BOOLEAN = [false, true];

export const WIDGET_PROP_DATE_RANGE = {
  range: DateRangeEnumT,
  from: "",
  to: "",
};

const dateGroupingTimebased = [
  WidgetDateGroupingT.DayT,
  WidgetDateGroupingT.WeekT,
  WidgetDateGroupingT.MonthT,
  WidgetDateGroupingT.YearT,
  WidgetDateGroupingT.QuarterT,
];

const dateGroupingOnlySummary = [WidgetDateGroupingT.SummaryT];

export const SYSTEMS_ALL_KEY = SystemNameT.AnyT;
export const SYSTEMS_AD = [SystemNameT.AdwordsT, SystemNameT.BingT, SystemNameT.FacebookT, SystemNameT.SklikT];
export const SYSTEMS_ANALYTICS = [SystemNameT.GoogleAnalytics3T, SystemNameT.GoogleAnalytics4T];

// Widget schema
const componentsPropsBase = ["name", "description"];
export const componentPropsData = [
  "widgetDimensions",
  "widgetMetrics",
  "dateRange",
  "dateGrouping",
  "compare",
  "currency",
  "maxRows",
  "showOther",
  "sourceSystems",
];
export const componentPropsTable = ["properties.sortByMetricsAndDimensions", "properties.pageSize"];

const WIDGET_DEFAULT_PROPS = {
  properties: {
    options: {
      width: 2,
      height: 1,
    },
  },
};

const WIDGET_DEFAULT_TABLE_PROPS = {
  properties: {
    options: {
      width: 3,
    },
  },
};

const notUsedWidget = {
  components: [],
  props: [],
  category: WidgetCategoryT.NoneT,
  rules: {},
  defaultProps: WIDGET_DEFAULT_PROPS,
  icon: "headline-widget",
  name: "WIHTOUT NAME",
};

export const WIDGET_SCHEMA: WidgetSchemaT = {
  HEADLINE: {
    name: "Headline",
    components: [WidgetComponentT.titleComponentT],
    props: [...componentsPropsBase, "titleContent", "titleType"],
    // Temporary removed static widgets (category: NoneT)
    category: WidgetCategoryT.NoneT,
    rules: {},
    defaultProps: WIDGET_DEFAULT_PROPS,
    icon: "headline-widget",
  },
  IMAGE: {
    name: "Custom image",
    components: [WidgetComponentT.imageComponentT],
    props: [...componentsPropsBase, "imageUrl"],
    // Temporary removed static widgets (category: NoneT)
    category: WidgetCategoryT.NoneT,
    rules: {},
    defaultProps: WIDGET_DEFAULT_PROPS,
    icon: "image-widget",
  },
  NOTE: {
    name: "Custom note",
    components: [WidgetComponentT.noteComponentT],
    props: [...componentsPropsBase, "noteContent"],
    // Temporary removed static widgets (category: NoneT)
    category: WidgetCategoryT.NoneT,
    rules: {},
    defaultProps: WIDGET_DEFAULT_PROPS,
    icon: "note-widget",
  },
  LINECHART: {
    name: "Line Chart",
    components: [WidgetComponentT.lineChartComponentT],
    props: [...componentsPropsBase, ...componentPropsData],
    category: WidgetCategoryT.TimebasedT,
    rules: {
      dimensions: { min: 0 },
      metrics: { min: 1, max: 10 },
      maxRows: { min: 1, max: 10 },
      campaignDataColumns: { max: 0 },
      dateGrouping: dateGroupingTimebased,
    },
    defaultProps: {
      maxRows: 10,
      showOther: true,
      ...WIDGET_DEFAULT_PROPS,
    },
    icon: "chartline-widget",
    defaultWidgetMetrics: {
      analytics: ["Sessions"],
      other: ["Impressions"],
    },
    hasFilter: true,
  },
  AREACHART: {
    name: "Area Chart",
    components: [WidgetComponentT.areaChartComponentT],
    props: [...componentsPropsBase, ...componentPropsData],
    category: WidgetCategoryT.TimebasedT,
    rules: {
      dimensions: { min: 0 },
      metrics: { min: 1, max: 10 },
      maxRows: { min: 1, max: 10 },
      campaignDataColumns: { max: 0 },
      dateGrouping: dateGroupingTimebased,
    },
    defaultProps: {
      maxRows: 10,
      showOther: true,
      ...WIDGET_DEFAULT_PROPS,
    },
    icon: "chartarea-widget",
    defaultWidgetMetrics: {
      analytics: ["Sessions"],
      other: ["Impressions"],
    },
    hasFilter: true,
  },
  SCATTERCHART: {
    name: "Bubble Chart",
    components: [WidgetComponentT.scatterChartComponentT],
    props: [...componentsPropsBase, ...componentPropsData],
    category: WidgetCategoryT.TimebasedT,
    rules: {
      dimensions: { min: 0 },
      metrics: { min: 1, max: 2 },
      maxRows: { min: 1 },
      campaignDataColumns: { max: 0 },
      dateGrouping: dateGroupingTimebased,
    },
    defaultProps: WIDGET_DEFAULT_PROPS,
    icon: "chartscatter-widget",
    defaultWidgetMetrics: {
      analytics: ["Sessions"],
      other: ["Clicks"],
    },
    hasFilter: true,
  },
  PIECHART: {
    name: "Pie Chart",
    components: [WidgetComponentT.pieChartComponentT],
    props: [...componentsPropsBase, ...componentPropsData],
    category: WidgetCategoryT.SummaryT,
    rules: {
      dimensions: { min: 1, max: 2 },
      metrics: { min: 1, max: 2 },
      maxRows: { min: 1, max: 20 },
      campaignDataColumns: { max: 0 },
      dateGrouping: dateGroupingOnlySummary,
    },
    defaultProps: {
      maxRows: 10,
      showOther: true,
      properties: {
        options: {
          width: 1,
        },
      },
    },
    icon: "chartpie-widget",
    defaultWidgetMetrics: {
      analytics: ["Sessions"],
      other: ["Clicks"],
    },
    defaultWidgetDimensions: {
      analytics: ["Source"],
      other: ["Campaign"],
    },
    hasFilter: true,
  },
  BARCHART: {
    name: "Bar Chart",
    components: [WidgetComponentT.barChartComponentT],
    props: [...componentsPropsBase, ...componentPropsData],
    category: WidgetCategoryT.SummaryT,
    rules: {
      dimensions: { min: 0 },
      metrics: { min: 1, max: 5 },
      maxRows: { min: 1, max: 10 },
      campaignDataColumns: { max: 0 },
      dateGrouping: dateGroupingOnlySummary,
    },
    defaultProps: { maxRows: 10, showOther: true, ...WIDGET_DEFAULT_PROPS },
    icon: "chartbar-widget",
    defaultWidgetMetrics: {
      analytics: ["Sessions"],
      other: ["Impressions"],
    },
    hasFilter: true,
  },
  SCORECARD: {
    name: "Scorecard",
    components: [WidgetComponentT.scorecardComponentT],
    props: [...componentsPropsBase, ...componentPropsData],
    category: WidgetCategoryT.SummaryT,
    rules: {
      dimensions: { min: 0 },
      metrics: { min: 1, max: 5 },
      maxRows: { min: 1, max: 10 },
      campaignDataColumns: { max: 0 },
      dateGrouping: dateGroupingOnlySummary,
    },
    defaultProps: {
      maxRows: 5,
      showOther: true,
      properties: {
        options: {
          width: 1,
        },
      },
    },
    icon: "scorecard-widget",
    defaultWidgetMetrics: {
      analytics: ["Sessions"],
      other: ["Clicks"],
    },
    hasFilter: true,
  },
  TABLE_TIMEBASED: {
    name: "Table",
    components: [WidgetComponentT.tableComponentT],
    props: [...componentsPropsBase, ...componentPropsData, ...componentPropsTable],
    category: WidgetCategoryT.TimebasedT,
    rules: {
      dimensions: { min: 0 },
      metrics: { min: 0 },
      campaignDataColumns: { max: 0 },
      dateGrouping: dateGroupingTimebased,
    },
    defaultProps: {
      dateGrouping: WidgetDateGroupingT.DayT,
      maxRows: undefined,
      ...WIDGET_DEFAULT_TABLE_PROPS,
    },
    icon: "table-widget",
    defaultWidgetMetrics: {
      analytics: ["Sessions"],
      other: ["Clicks"],
    },
    defaultWidgetDimensions: {
      analytics: ["Campaign name"],
      other: ["Campaign name"],
    },
    hasQuickSearch: true,
    hasFilter: true,
  },
  TABLE_SUMMARY: {
    name: "Table",
    components: [WidgetComponentT.tableComponentT],
    props: [...componentsPropsBase, ...componentPropsData, ...componentPropsTable],
    category: WidgetCategoryT.SummaryT,
    rules: {
      dimensions: { min: 0 },
      metrics: { min: 0 },
      campaignDataColumns: { max: 0 },
      dateGrouping: dateGroupingOnlySummary,
    },
    defaultProps: {
      dateGrouping: WidgetDateGroupingT.SummaryT,
      maxRows: undefined,
      ...WIDGET_DEFAULT_TABLE_PROPS,
    },
    icon: "table-widget",
    defaultWidgetMetrics: {
      analytics: ["Sessions"],
      other: ["Clicks"],
    },
    defaultWidgetDimensions: {
      analytics: ["Campaign"],
      other: ["Campaign"],
    },
    hasQuickSearch: true,
    hasFilter: true,
    hasMetricOptions: true,
  },
  TABLE_OPTIMIZATION: {
    name: "Actionable Analytics",
    components: [WidgetComponentT.tableOptimizationComponentT],
    props: [
      ...componentsPropsBase,
      ...componentPropsData.filter((prop) => prop !== "maxRows"),
      ...componentPropsTable,
      "campaignDataColumns",
    ],
    category: WidgetCategoryT.ActionableT,
    rules: {
      dimensions: { min: 1 },
      metrics: { min: 0 },
      dateGrouping: dateGroupingOnlySummary,
    },
    defaultProps: {
      dateGrouping: WidgetDateGroupingT.SummaryT,
      maxRows: undefined,
      ...WIDGET_DEFAULT_TABLE_PROPS,
    },
    icon: "table-optimization-widget",
    defaultWidgetDimensions: {
      analytics: ["Campaign"],
      other: ["Campaign"],
    },
    defaultWidgetMetrics: {
      analytics: ["Sessions"],
      other: ["Clicks"],
    },
    hasFilter: true,
    hasMetricOptions: true,
  },
  FUNNELCHART: notUsedWidget,
  TIMEBARCHART: {
    name: "Timebased Bar Chart",
    components: [WidgetComponentT.timeBarChartComponentT],
    props: [...componentsPropsBase, ...componentPropsData],
    category: WidgetCategoryT.TimebasedT,
    rules: {
      dimensions: { min: 0 },
      metrics: { min: 1, max: 10 },
      maxRows: { min: 1, max: 10 },
      campaignDataColumns: { max: 0 },
      dateGrouping: dateGroupingTimebased,
    },
    defaultProps: { maxRows: 10, showOther: true, ...WIDGET_DEFAULT_PROPS },
    icon: "chartbar-widget",
    defaultWidgetMetrics: {
      analytics: ["Sessions"],
      other: ["Clicks"],
    },
    hasFilter: true,
  },
  TIMESTACKEDBARCHART: {
    name: "Timebased Stacked Bar Chart",
    components: [WidgetComponentT.timeStackedBarChartComponentT],
    props: [...componentsPropsBase, ...componentPropsData],
    category: WidgetCategoryT.TimebasedT,
    rules: {
      dimensions: { min: 0 },
      metrics: { min: 1, max: 10 },
      maxRows: { min: 1, max: 10 },
      campaignDataColumns: { max: 0 },
      dateGrouping: dateGroupingTimebased,
    },
    defaultProps: { maxRows: 10, showOther: true, ...WIDGET_DEFAULT_PROPS },
    // TODO change icon when the one needed for this type is available
    icon: "chartbar-widget",
    defaultWidgetMetrics: {
      analytics: ["Sessions"],
      other: ["Clicks"],
    },
    hasFilter: true,
  },
  STACKEDBARCHART: notUsedWidget,
  RADARCHART: notUsedWidget,
};

export const CategoryTranslations = {
  [WidgetCategoryT.SummaryT]: "All time (summary)",
  [WidgetCategoryT.TimebasedT]: "Over time (time based)",
  [WidgetCategoryT.ActionableT]: "Actionable",
  [WidgetCategoryT.StaticT]: "Custom content",
  [WidgetCategoryT.NoneT]: "-",
};
