import React, { FormEvent } from "react";
import { WidgetT } from "../../../types/widgets";
import Input from "../../../ui/forms/Input";
import { OnFormEditWidgetChangeT } from "../FormEditWidget";

type PropsT = {
  formKey: string;
  onChange: OnFormEditWidgetChangeT;
  value: WidgetT["description"];
};

export const InputDescription = ({ formKey, onChange, value }: PropsT) => {
  const path = ["description"];
  const pathString = path.join(".");

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    onChange((prev) => ({ ...prev, description: newValue }));
  };

  return (
    <Input
      className="mb-16"
      inputProps={{ id: `${formKey}-${pathString}`, onChange: handleChange, value: value || "", name: pathString }}
      label="Widget description"
    />
  );
};
