import React, {
  Children,
  HTMLAttributes,
  MutableRefObject,
  PropsWithChildren,
  cloneElement,
  isValidElement,
} from "react";
import { FloatingContext, FloatingFocusManager, FloatingOverlay, FloatingPortal, Strategy } from "@floating-ui/react";
import cs from "classnames";

type DropdownFloatingBoxContentPropsT = PropsWithChildren<
  {
    context: FloatingContext;
    floating: (node: HTMLElement | null) => void;
    initialFocus?: MutableRefObject<HTMLElement | null> | number;
    onToggle: (isOpen: boolean) => void;
    strategy: Strategy;
    x: number | null;
    y: number | null;
  } & HTMLAttributes<HTMLDivElement>
>;

const DropdownFloatingBoxContent = ({
  children,
  context,
  floating,
  initialFocus,
  onToggle,
  strategy,
  x,
  y,
  ...rest
}: DropdownFloatingBoxContentPropsT) => {
  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { onToggle });
    }
    return child;
  });

  return (
    <FloatingFocusManager context={context} initialFocus={initialFocus}>
      <div
        {...rest}
        ref={floating}
        style={{ position: strategy, overflowX: "unset", right: "unset", top: y ?? 0, left: x ?? 0 }}
      >
        {childrenWithProps}
      </div>
    </FloatingFocusManager>
  );
};

type DropdownFloatingBoxPropsT = DropdownFloatingBoxContentPropsT & {
  isOpen?: boolean;
  lockScroll?: boolean;
};

export const DropdownFloatingBox = ({
  initialFocus,
  isOpen,
  lockScroll = true,
  ...rest
}: DropdownFloatingBoxPropsT) => {
  return (
    <FloatingPortal id="dee-dropdown-js">
      {isOpen && lockScroll && (
        <FloatingOverlay className="zIndex--10000" lockScroll>
          <DropdownFloatingBoxContent initialFocus={initialFocus} {...rest} />
        </FloatingOverlay>
      )}
      {isOpen && !lockScroll && (
        <DropdownFloatingBoxContent
          initialFocus={initialFocus}
          {...rest}
          className={cs(rest.className, "zIndex--10000 elevate-1")}
        />
      )}
    </FloatingPortal>
  );
};
