import React, { Fragment } from "react";
import { Area, AreaChart, ResponsiveContainer } from "recharts";
import { v4 as uuid } from "uuid";
import { WidgetCompareT, WidgetDataFragmentT, WidgetRowT } from "../../graphql/generated/graphql";
import { useGetWidgetComponentData } from "../../hooks/useGetWidgetComponentData";
import { ReportFilterT } from "../../types/report";
import { WidgetComponentPropsT, WidgetT } from "../../types/widgets";
import { withApiStateHandler } from "../ErrorLoadingWrapper/withApiStateHandler";
import { useReportBoardContext } from "../reportBoard/context/reportBoardContext";
import { WidgetDataEmpty } from "../widgetDataAlerts/WidgetDataEmpty";
import { WidgetDataError } from "../widgetDataAlerts/WidgetDataError";
import { useTimebasedChart } from "./useTimebasedChart";
import widgetComponentContainer from "./widgetComponentContainer";
import { widgetDataComponentContainer } from "./widgetDataComponentContainer";

export type AreaChartComponentPropsT = WidgetComponentPropsT;

type PropsT = {
  filterValues: ReportFilterT;
  widget: WidgetT;
  widgetData?: WidgetDataFragmentT;
};

const AreaChartComponentContent = withApiStateHandler(
  widgetDataComponentContainer(({ widget, widgetData }: PropsT) => {
    const {
      widgetSettingsData: { dimensionsById },
    } = useReportBoardContext();
    const { widgetDimensions, widgetMetrics } = widget;
    const rows = widgetData?.rows.nodes;
    const summaryRowsByDimensions = widgetData?.summaryByDimensions.nodes;

    const selectedDimensions = widgetDimensions
      .map((dimension) => dimensionsById[dimension.dimensionId])
      .filter((item) => !!item);

    const { cartesianGrid, legend, rowsByDateAsArray, series, tooltip, xaxis, yaxis } = useTimebasedChart({
      rows: (rows || []) as WidgetRowT[],
      selectedDimensions,
      dateGrouping: widget.dateGrouping,
      widgetMetrics,
      hasCompare: widget.compare !== WidgetCompareT.NoneT,
      summaryRowsByDimensions: summaryRowsByDimensions || [],
    });

    if (!rows || !widgetMetrics || widgetMetrics.length === 0) {
      return <WidgetDataError />;
    }

    if (rows.length === 0) {
      return <WidgetDataEmpty />;
    }

    const uuidChart = uuid();

    return (
      <ResponsiveContainer>
        <AreaChart data={rowsByDateAsArray}>
          {tooltip}

          {cartesianGrid}

          {xaxis}

          {yaxis}

          {series?.map(
            ({ color, dataKey, dimensionsValue, isCompare, isHidden, key, metric, serieId, yAxisId }, index) => (
              <Fragment key={key}>
                <defs>
                  <linearGradient id={`${uuidChart}-area-color-${index}`} x1="0" x2="0" y1="0" y2="1">
                    <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={color} stopOpacity={0} />
                  </linearGradient>
                </defs>

                <Area
                  data-dimension-value={dimensionsValue}
                  data-metric-compare={isCompare}
                  data-metric-id={metric.id}
                  data-serie-id={serieId}
                  dataKey={dataKey}
                  fill={`url(#${uuidChart}-area-color-${index})`}
                  fillOpacity={0.1}
                  hide={isHidden}
                  isAnimationActive={false}
                  stroke={color}
                  strokeDasharray={isCompare ? `3 3` : undefined}
                  type="monotone"
                  yAxisId={yAxisId}
                />
              </Fragment>
            )
          )}

          {legend}
        </AreaChart>
      </ResponsiveContainer>
    );
  })
);

const AreaChartComponent = ({ filterValues, onWidgetDataChange, widget, ...rest }: AreaChartComponentPropsT) => {
  const result = useGetWidgetComponentData({
    filterValues,
    widget,
    useSummaryByDimensions: true,
    onWidgetDataChange,
  });

  return (
    <div className="h-100">
      <AreaChartComponentContent {...rest} {...result} filterValues={filterValues} widget={widget} />
    </div>
  );
};

export default widgetComponentContainer(AreaChartComponent);
