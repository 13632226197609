import React, { useMemo } from "react";
import cs from "classnames";
import { SectionT } from "../../types/widgets";
import { ButtonTertiary } from "../../ui/Button/Button";
import { ClampedText } from "../../ui/ClampedText/ClampedText";
import { Ellipsis } from "../../ui/Ellipsis/Ellipsis";
import { Col, Row } from "../../ui/grid/Grid";
import { HeadingSection } from "../../ui/Heading/Heading";
import { FormReportCompactFilterInputsT } from "../formReportCompactFilter/FormReportCompactFilter";
import { useReportBoardContext } from "./context/reportBoardContext";
import { ReportOptionsT } from "./context/types";
import { PageContextT } from "./ReportBoardPage";
import { ReportBoardSectionControl } from "./ReportBoardSectionControl";
import { ReportFilterCompact } from "./ReportFilterCompact";

type PropsT = {
  isSectionHidden?: boolean;
  onSectionFilterChange: (values: Partial<FormReportCompactFilterInputsT>) => void;
  pageContext: PageContextT;
  reportOptions?: ReportOptionsT;
  section: SectionT;
  setSectionHidden?: (values: boolean) => void;
};

export const ReportBoardSectionHeader = ({
  isSectionHidden,
  onSectionFilterChange,
  pageContext,
  section,
  setSectionHidden,
}: PropsT) => {
  const {
    reportOptions,
    widgetSettingsData: { metricsById },
  } = useReportBoardContext();
  const { sectionFilterOpen } = pageContext;

  // Part of IlineEdit
  // const handleSectionUpdate = (updateObject: Partial<SectionT>) => {
  //   sectionUpdate({ sectionId: section.id, sectionInputData: updateObject });
  // };

  // const { buttonProps, inputProps, isInputVisible } = useInlineEditWithToggleButton({
  //   text: section?.description,
  //   onChange: (value) => handleSectionUpdate({ description: value }),
  // });

  const clampedTextDependencies = useMemo(() => [section.description], [section.description]);

  const filterDefaultValues = {
    ...(section.dateRange && { dateRange: section.dateRange }),
  };

  return (
    <Col className="pv-8">
      <Row alignItems="center">
        <Col className="negative-ml-16" type="grow">
          <Row alignItems="center" className="pos-relative">
            <Col className="show-on-hover-element" width="8px">
              {reportOptions.canEdit && (
                <span className="DragHandle draggable-handle-section" style={{ width: "14px", paddingBottom: "2px" }} />
              )}
            </Col>
            <Col type="grow">
              <Row alignItems="center">
                <Col style={{ maxWidth: "calc(100% - 48px" }}>
                  <HeadingSection className="section-header" data-test-id="section-name" margin={0}>
                    <Ellipsis>{section.name || " Section title placeholder"}</Ellipsis>
                  </HeadingSection>
                  {/*  */}
                  {/* <InlineEdit
                    kind="h1"
                    placeholder="Section title placeholder"
                    testId="section-name-inline-edit"
                    value={section.name}
                    onChange={(value) => {
                      handleSectionUpdate({ name: value });
                    }}
                  /> */}
                </Col>

                {setSectionHidden && (
                  <Col>
                    <ButtonTertiary
                      className={cs("ml-8", { "show-on-hover-element": !isSectionHidden })}
                      icon={isSectionHidden ? "chevron-down" : "chevron-up"}
                      onlyIcon
                      onClick={() => setSectionHidden(!isSectionHidden)}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>

        <Col type="shrink">
          <ReportFilterCompact
            canEdit={reportOptions.canEdit}
            conditions={section.conditions}
            defaultValues={filterDefaultValues}
            id={`section-filter-${section.id}`}
            metricsById={metricsById}
            isInputMinified
            isVissibleOnHover
            onChange={onSectionFilterChange}
            onFilterOpen={() => sectionFilterOpen(section.id)}
          />
        </Col>

        {reportOptions?.canEdit && (
          <Col type="shrink">
            <ReportBoardSectionControl pageContext={pageContext} section={section} />
          </Col>
        )}
      </Row>
      {!isSectionHidden && section.description && section.description.length > 0 && (
        <ClampedText
          className="section-description"
          dataTestId="section-description"
          dependencies={clampedTextDependencies}
        >
          {section.description}
        </ClampedText>
      )}
      {/* <Row className="section-description">
        {isInputVisible ? (
          <InlineEdit
            {...inputProps}
            kind="text"
            testId="section-description-inline-edit"
            value={section.description as string}
            isMultiline
          />
        ) : (
          <Col>
            <ButtonTertiary
              {...buttonProps}
              className={cs("ml-4", { "show-on-hover-element": !isSectionHidden })}
              icon="edit"
              style={{ backgroundColor: "transparent" }}
            >
              Add description
            </ButtonTertiary>
          </Col>
        )}
      </Row> */}
    </Col>
  );
};
