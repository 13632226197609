import React from "react";
import { notifyError, notifySucces } from "../functions/toast";
import {
  CustomMetricsQueryResultT,
  useCustomMetricsQuery,
  useDeleteCustomMetricMutation,
} from "../graphql/generated/graphql";
import { useAppSettings } from "../providers/appSettingsProvider";
import { SortableAdditionalPropsT, SortableWrapper } from "../ui/DataTable/Sortable";
import { TBody, THead, Table, Th, TrHeader } from "../ui/Table/Table";
import { GeneralMetricDimensionTable } from "./GeneralMetricDimensionTable";
import { CustomMetricT, MetricTableRow } from "./MetricTableRow";

type MetricTableRowsT = CustomMetricT[];

export const MetricsTable = ({
  refetch,
  sortableProps,
  tableRows,
}: Pick<CustomMetricsQueryResultT, "refetch"> & {
  sortableProps: SortableAdditionalPropsT;
  tableRows: MetricTableRowsT;
}) => {
  const { organization } = useAppSettings();

  const [deleteCustomMetric] = useDeleteCustomMetricMutation({
    onCompleted: (res) => {
      const errors = res.organization?.deleteCustomMetric?.errors;
      if (errors?.length) {
        return notifyError(<>{errors.join("\n")}</>);
      }
      notifySucces(
        <>
          Custom metric{" "}
          <span className="Text Text--white Text--bold">
            {res.organization?.deleteCustomMetric?.customMetric?.name}
          </span>{" "}
          was deleted successfuly.
        </>
      );
    },
    onError: () => notifyError(<>Server error when deleting report!</>),
    update() {
      refetch();
    },
  });

  return (
    <Table>
      <THead>
        <TrHeader>
          <Th style={{ width: "300px", minWidth: "300px" }}>
            <SortableWrapper {...sortableProps} sortByValue="name">
              Name
            </SortableWrapper>
          </Th>
          <Th>Description</Th>
          <Th>Equation</Th>
          <Th>
            <SortableWrapper {...sortableProps} sortByValue="referencesCount">
              Used
            </SortableWrapper>
          </Th>
          <Th>Created by</Th>
        </TrHeader>
      </THead>
      <TBody>
        {(tableRows || []).map((customMetric) => (
          <MetricTableRow
            key={customMetric.id}
            customMetric={customMetric}
            onDelete={() =>
              deleteCustomMetric({
                variables: { customMetricId: customMetric.id, organizationExternalId: organization.externalId },
              })
            }
          />
        ))}
      </TBody>
    </Table>
  );
};

export const MetricsPageConnected = () => {
  const { organization } = useAppSettings();
  const response = useCustomMetricsQuery({
    fetchPolicy: "network-only",
    variables: {
      organizationExternalId: organization.externalId,
      limit: 1000,
      offset: 0,
    },
  });

  return <GeneralMetricDimensionTable {...response} type="calculatedMetric" />;
};
