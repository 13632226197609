import React, { MouseEvent, ReactElement, ReactNode } from "react";
import classNames from "classnames";
import { FieldError, RefCallBack } from "react-hook-form";
import { Ellipsis } from "../Ellipsis/Ellipsis";
import { Icon } from "../Icon/Icon";
import { Loader } from "../Loader/Loader";
import FormGroup from "./FormGroup";

type PropsT = {
  children?: ReactNode;
  childrenOffset?: string;
  className?: string;
  error?: FieldError;
  hasArrow?: boolean;
  icon?: string;
  id: string;
  inputProps?: {
    ref: RefCallBack;
  };
  isClearable?: boolean;
  isDisabled?: boolean;
  isInputContentOpen?: boolean;
  isLoading?: boolean;
  isOverflowHidden?: boolean;
  label?: string | ReactElement;
  minified?: boolean;
  onClear?: () => void;
  onClick: () => void;
  placeholder?: string;
  testId?: string;
};

export const FakeInput = ({
  children,
  childrenOffset,
  className,
  error,
  hasArrow,
  icon,
  id,
  inputProps,
  isDisabled,
  isInputContentOpen,
  isLoading,
  isOverflowHidden,
  label,
  onClear,
  onClick,
  placeholder,
  testId,
}: PropsT) => {
  const isClearBtnVissible = !isDisabled && onClear;

  const handleClear = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (onClear) {
      onClear();
    }
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (isDisabled) {
      event.stopPropagation();
    } else {
      onClick();
    }
  };

  return (
    <FormGroup
      error={error}
      id={id}
      label={label}
      testId={testId}
      className={classNames(className, "Input", {
        "pointer-events-none no-user-select": icon && isLoading,
        open: isInputContentOpen,
      })}
    >
      <div className="pos-relative" tabIndex={0}>
        {isLoading && <Loader size="small" absolute />}

        <div
          className={classNames("d-flex align-items-center", { "Input-iconWrapper": icon, active: isInputContentOpen })}
        >
          {icon && <Icon data-test-id="input-icon" kind={icon} />}

          <div
            data-test-id={`${testId}-fake-input`}
            id={id}
            className={classNames("Input-fakeInput", {
              disabled: isDisabled,
              "text-overflow": isOverflowHidden,
            })}
            style={{
              marginLeft: 0,
              width: "100%",
              ...((isClearBtnVissible || hasArrow) && { paddingRight: "28px" }),
              ...(isClearBtnVissible && hasArrow && { paddingRight: "48px" }),
              ...(icon && { borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }),
            }}
            onClick={handleClick}
          >
            {/* invisible input - hack for scroll to error  */}
            <input
              ref={inputProps?.ref}
              style={{ opacity: 0, pointerEvents: "none", width: 0, height: 0, border: "none", position: "absolute" }}
            />
            <Ellipsis
              style={
                childrenOffset && children ? { position: "relative", top: childrenOffset } : { lineHeight: "32px" }
              }
            >
              {children || <span style={{ color: "rgba(0, 22, 42, 0.45)" }}>{placeholder}</span>}
            </Ellipsis>
          </div>

          {isClearBtnVissible && (
            <div
              className="Input-reset fc-close"
              data-test-id="clear-input"
              style={{ right: hasArrow ? "30px" : "8px" }}
              onClick={handleClear}
            />
          )}

          {hasArrow && <div className="Input-chevron fc-chevron-down" />}
        </div>
      </div>
    </FormGroup>
  );
};
