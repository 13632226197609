import { useCallback, useEffect, useMemo, useState } from "react";
import { WidgetDimensionT, WidgetMetricT } from "../../graphql/generated/graphql";
import { ReportDimensionT } from "../reportBoard/context/types";

export type UseHideSeriesOnLegendClickT = ({ payload }: { payload: { "data-serie-id": string } }) => void;
export type UseHideSeriesGetIsHiddenT = (serieId: string) => boolean;

export const useHideSeries = ({
  widgetDimensions,
  widgetMetrics,
}: {
  widgetDimensions: Array<ReportDimensionT | WidgetDimensionT>;
  widgetMetrics: WidgetMetricT[];
}) => {
  const [state, setState] = useState<Set<string>>(new Set());

  const inputData = useMemo(
    () =>
      JSON.stringify([
        widgetDimensions.filter((dim) => !dim).map((dimension) => dimension.id),
        widgetMetrics.filter((met) => !met).map((metric) => metric.metricId),
      ]),
    [widgetDimensions, widgetMetrics]
  );

  useEffect(() => {
    setState(new Set());
  }, [inputData]);

  return {
    getIsHidden: useCallback<UseHideSeriesGetIsHiddenT>((serieId) => state.has(serieId), [state]),
    getSerieId: useCallback(
      ({ isCompare, serieId }: { isCompare?: boolean; serieId: string }) => [serieId, isCompare].join("-"),
      []
    ),
    onLegendClick: useCallback<UseHideSeriesOnLegendClickT>(
      ({ payload }) =>
        setState((prevState) => {
          const barId = payload["data-serie-id"] as string;
          const newState = new Set(prevState);

          if (newState.has(barId)) {
            newState.delete(barId);
          } else {
            newState.add(barId);
          }

          return newState;
        }),
      []
    ),
  };
};
