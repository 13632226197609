import React from "react";
import { LegendProps } from "recharts";
import {
  fetchMetricCompareFromRow,
  fetchMetricPreviousValueFromRow,
  fetchMetricTrendFromRow,
  fetchMetricValueFromRow,
} from "../../functions/widgetDataHelpers";
import { CurrencyT, WidgetRowT } from "../../graphql/generated/graphql";
import { Col, Row } from "../../ui/grid/Grid";
import { Tile } from "../../ui/Tile/Tile";
import { MetricsByIdT } from "../reportBoard/context/types";
import { CompareDateT, MetricInformationCard } from "./MetricInformationCard";
import { UseHideSeriesGetIsHiddenT, UseHideSeriesOnLegendClickT } from "./useHideSeries";

type PropsT = Omit<LegendProps, "payload"> & {
  getIsHidden: UseHideSeriesGetIsHiddenT;
  metricsById: MetricsByIdT;
  onClick: UseHideSeriesOnLegendClickT;
  payload?: Array<{
    color: string;
    payload: {
      "data-currency"?: CurrencyT;
      "data-metric-compare"?: CompareDateT;
      "data-metric-id": string;
      "data-serie-id": string;
      "data-sum-row": WidgetRowT;
    };
  }>;
};

export const WidgetChartSummaryLegend = ({ getIsHidden, metricsById, onClick, payload }: PropsT) => {
  return (
    <Row justify="center" style={{ maxHeight: "90px", marginBottom: "-8px" }} flexwrap overflow>
      {payload &&
        payload?.length > 0 &&
        payload.map((entry, index) => {
          const metric = metricsById[entry.payload["data-metric-id"]];
          const sumRow = entry.payload?.["data-sum-row"];
          const isCompare = entry.payload?.["data-metric-compare"];
          const currency = entry.payload?.["data-currency"];
          const compareDate = isCompare
            ? fetchMetricCompareFromRow({ id: "XXX", metricId: metric.id })(sumRow)
            : undefined;
          return (
            <Col
              key={`item-${index}`}
              type="equalSize"
              style={{
                cursor: "pointer",
                opacity: getIsHidden(entry.payload["data-serie-id"]) ? 0.3 : 1,
                maxWidth: "max(160px, calc(8% - 8px))",
              }}
              onClick={() => onClick(entry)}
            >
              <Tile className="h-100 recharts-legend-item-text" isSmallSpaced>
                <div
                  className="w-100 mb-8"
                  style={{ backgroundColor: entry?.color, height: "4px", borderRadius: "2px" }}
                />
                <MetricInformationCard
                  compareDate={compareDate}
                  currency={currency}
                  metric={metric}
                  size="small"
                  trend={!isCompare ? fetchMetricTrendFromRow({ id: "XXX", metricId: metric.id })(sumRow) : undefined}
                  value={
                    ((isCompare
                      ? fetchMetricPreviousValueFromRow({ id: "XXX", metricId: metric.id })(sumRow)
                      : fetchMetricValueFromRow({ id: "XXX", metricId: metric.id })(sumRow)) as number) || undefined
                  }
                />
              </Tile>
            </Col>
          );
        })}
    </Row>
  );
};
