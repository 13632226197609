import React, { useCallback, useEffect, useState } from "react";
import {
  CreateSharedReportInputT,
  SharedReportCreateMutationVariablesT,
  SharedReportQueryResultT,
  SharedReportUpdateMutationVariablesT,
} from "../../graphql/generated/graphql";
import { ButtonPrimary } from "../../ui/Button/Button";
import { Loader } from "../../ui/Loader/Loader";
import { FormSharingLink } from "../formSharingLink/FormSharingLink";
import { ReportBoardPageT, ReportBoardSharedReportT } from "../reportBoard/context/types";
import { ReportSharingDetail } from "./ReportSharingDetail";
import { ReportSharingEmpty } from "./ReportSharingEmpty";
import { ReportSharingLink } from "./ReportSharingLink";

export type ReportSharingPropsT = {
  isLoadingCreate: boolean;
  isLoadingData: boolean;
  isLoadingUpdate: boolean;
  onCreateSharedReport: ({
    onCompleted,
    variables,
  }: {
    onCompleted?: () => void;
    variables: Omit<SharedReportCreateMutationVariablesT, "organizationExternalId" | "reportId">;
  }) => Promise<void>;
  onDeleteSharedReport: (sharedReportId: string) => Promise<void>;
  onRefetchData: SharedReportQueryResultT["refetch"];
  onUpdateSharedReport: ({
    onCompleted,
    variables,
  }: {
    onCompleted?: () => void;
    variables: Omit<SharedReportUpdateMutationVariablesT, "organizationExternalId">;
  }) => Promise<void>;
  pages: Array<
    Pick<ReportBoardPageT, "id" | "name"> & {
      sections: {
        id: string;
        widgets: {
          nodes: {
            id: string;
          }[];
        };
      }[];
    }
  >;
  reportName: string;
  sharedReports: ReportBoardSharedReportT[];
};

export const ReportSharing = ({
  isLoadingCreate,
  isLoadingData,
  isLoadingUpdate,
  onCreateSharedReport,
  onDeleteSharedReport,
  onRefetchData,
  onUpdateSharedReport,
  pages,
  reportName,
  sharedReports,
}: ReportSharingPropsT) => {
  const [section, setSection] = useState<"creating" | null>(null);
  const [editedId, setEditedId] = useState<string | null>(null);
  const [firstLoadDone, setFirstLoadDone] = useState(false);
  const selectedReport = sharedReports.find((shared) => shared.id === editedId);
  const isEmpty = !isLoadingData && !sharedReports.length;

  useEffect(() => {
    if (!isLoadingData || !firstLoadDone) {
      setFirstLoadDone(true);
    }
  }, [isLoadingData, firstLoadDone]);

  const handleShowForm = useCallback(() => setSection("creating"), []);

  const handleSubmitCreate = useCallback(
    (values: Omit<CreateSharedReportInputT, "reportId">) => {
      onCreateSharedReport({
        variables: {
          ...values,
          generateToken: true,
        },
        onCompleted: async () => {
          await onRefetchData();
          setSection(null);
        },
      });
    },
    [onCreateSharedReport, onRefetchData]
  );

  if (isEmpty && !section) {
    return <ReportSharingEmpty onShowForm={handleShowForm} />;
  }

  if (editedId && selectedReport) {
    return (
      <ReportSharingDetail
        isLoadingData={isLoadingData}
        isLoadingUpdate={isLoadingUpdate}
        pages={pages}
        sharedReport={selectedReport}
        isEditation
        onDeleteSharedReport={onDeleteSharedReport}
        onRefetchData={onRefetchData}
        onSetEditedSharedReport={setEditedId}
        onUpdateSharedReport={onUpdateSharedReport}
      />
    );
  }

  if (section === "creating") {
    return (
      <FormSharingLink
        isSubmiting={isLoadingCreate || isLoadingData}
        pages={pages}
        reportName={reportName}
        onCancel={() => setSection(null)}
        onSubmit={handleSubmitCreate}
      />
    );
  }

  return (
    <>
      {!section && (
        <>
          {isLoadingData && (
            <div className="mb-16">
              <Loader size="extraSmall" /> Loading the latest data...
            </div>
          )}
          <div>
            {[...sharedReports]
              .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
              .map((sharedReport) => (
                <ReportSharingLink
                  key={sharedReport.id}
                  className="mb-16"
                  pages={pages}
                  sharedReport={sharedReport}
                  onDeleteSharedReport={onDeleteSharedReport}
                  onRefetchData={onRefetchData}
                  onSetEditedSharedReport={setEditedId}
                  onUpdateSharedReport={onUpdateSharedReport}
                />
              ))}
          </div>

          {firstLoadDone && (
            <ButtonPrimary data-test-id="button-create-new-link" icon="plus" onClick={handleShowForm}>
              Create new link
            </ButtonPrimary>
          )}
        </>
      )}
    </>
  );
};
