import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { withApiStateHandler } from "../components/ErrorLoadingWrapper/withApiStateHandler";
import { FormReportEdit, FormReportEditInputsT } from "../components/formReportEdit/FormReportEdit";
import { ROUTES } from "../constants/routes";
import { formatDate } from "../functions/dateHelpers";
import { ReportEditPageQueryT, useReportEditPageQuery } from "../graphql/generated/graphql";
import { useUpdateReport } from "../hooks/useUpdateReport";
import { Layout } from "../layout/wrappers/Layout";
import { useAppSettings } from "../providers/appSettingsProvider";
import { RouteParamsT } from "../types/router";
import { Col } from "../ui/grid/Grid";
import { HeadingSection } from "../ui/Heading/Heading";
import { Text } from "../ui/Text/Text";
import { Tile } from "../ui/Tile/Tile";

type PropsT = {
  backTo?: string;
  data?: ReportEditPageQueryT;
  onReportSubmit: (values: FormReportEditInputsT) => void;
  submitLoading?: boolean;
};

const ReportEditPageComponent = withApiStateHandler(({ backTo, data, onReportSubmit, ...rest }: PropsT) => (
  <Layout
    backTo={backTo}
    subheaderContent={
      <Text className="Text--caption" bold>
        {data?.organization?.report?.name}
      </Text>
    }
    hideSidebar
  >
    <Tile>
      <HeadingSection>Report Information</HeadingSection>
      <Text color="gray" size="sm">
        Fill in the basic info about your new report.
      </Text>
      <Col className="mt-8" width="672px">
        <FormReportEdit
          {...rest}
          defaultValues={{
            name: data?.organization?.report?.name || "",
            description: data?.organization?.report?.description || "",
          }}
          onSubmit={onReportSubmit}
        />
      </Col>
    </Tile>

    <Tile className="mt-16">
      Created: {formatDate(data?.organization?.report?.createdAt, "dateTime")} {}
    </Tile>
  </Layout>
));

export const ReportEditPage = () => {
  const { reportId } = useParams<RouteParamsT["report"]>();
  const { organization } = useAppSettings();

  const navigate = useNavigate();

  const result = useReportEditPageQuery({
    fetchPolicy: "network-only",
    variables: { organizationExternalId: organization.externalId, reportId: reportId as string },
    skip: !reportId,
  });

  const { loading, updateReport } = useUpdateReport({
    successMessage: "Report was edited",
    sucessCallback: (updatedReport) => navigate(ROUTES.report(updatedReport?.id)),
  });

  const backTo = reportId ? ROUTES.report(reportId) : undefined;

  return <ReportEditPageComponent {...result} backTo={backTo} submitLoading={loading} onReportSubmit={updateReport} />;
};
