/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import cs from "classnames";
import { differenceInDays, format, startOfDay } from "date-fns";
import { isEqual } from "lodash";
import { DateRangePicker } from "react-date-range";
import { FieldPath, FieldPathValue, FieldValues, UseFormSetValue } from "react-hook-form";
import { DATE_FORMATS } from "../../constants/date";
import { getDateIsValid, getDaysFromDate } from "../../functions/dateHelpers";
import { DateRangeEnumT, DateRangeT } from "../../graphql/generated/graphql";
import { usePrevious } from "../../hooks/usePrevious";
import { Badge } from "../Badge/Badge";
import { ButtonPrimary, ButtonSecondary } from "../Button/Button";
import { DropdownFloatingBox } from "../Dropdown/DropdownFloatingBox";
import { useDropdown } from "../Dropdown/useDropdown";
import { Row } from "../grid/Grid";
import { Icon } from "../Icon/Icon";
import { Text } from "../Text/Text";
import { Tooltip } from "../Tooltip/Tooltip";
import Checkbox from "./Checkbox";
import { RangeT } from "./DateRange";
import { dateRanges, getEndDay, getRangeKind, getStartDay } from "./dateRangePresets";
import { InputController, InputControllerPropsT } from "./InputController";

export type PropsT<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<
  InputControllerPropsT<TFieldValues, TName>,
  "defaultValue" | "collection" | "radioOptions" | "select" | "textarea" | "smartSelect" | "autoCompleteOptions" | "id"
> & {
  className?: string;
  defaultValue?: DateRangeT;
  disableClear?: boolean;
  isInputMinified?: boolean;
  maxDate?: "today";
  name: TName;
  onSetValue: UseFormSetValue<TFieldValues>;
};

export const DEFAULT_DATE_RANGE = {
  startDate: undefined,
  endDate: new Date(""),
  key: "selection",
  fromDays: null,
  toDays: null,
};

export const DEFAULT_DATE_VALUE = {
  from: "",
  to: "",
  range: "",
  kind: "",
};

export const DateRangeController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  defaultValue,
  disableClear,
  input,
  isInputMinified,
  isLoading,
  label,
  maxDate,
  name,
  onSetValue,
  ...rest
}: PropsT<TFieldValues, TName>) => {
  const { boxProps, reference, triggerElementProps } = useDropdown();

  const [ranges, setRanges] = useState<RangeT[]>([
    {
      startDate: getStartDay(defaultValue),
      endDate: getEndDay(defaultValue),
      key: "selection",
      kind: defaultValue?.range,
    },
  ]);

  const [useCustomDays, setCustomDays] = useState<boolean>(defaultValue?.range === DateRangeEnumT.RangeCustomDaysT);

  const daysCount =
    ranges[0].endDate && ranges[0].startDate
      ? differenceInDays(new Date(ranges[0].endDate), new Date(ranges[0].startDate)) + 1
      : 0;

  const handleSetInputValue = useCallback(
    (range: RangeT) => {
      onSetValue(name, {
        from: range.startDate && getDateIsValid(range.startDate) ? format(range.startDate, DATE_FORMATS.dateApi) : null,
        to: range.endDate && getDateIsValid(range.endDate) ? format(range.endDate, DATE_FORMATS.dateApi) : null,
        fromDays:
          useCustomDays && range.startDate && getDateIsValid(range.startDate) ? getDaysFromDate(range.startDate) : null,
        toDays: useCustomDays && range.endDate && getDateIsValid(range.endDate) ? getDaysFromDate(range.endDate) : null,
        range: getRangeKind({ startDate: range.startDate, endDate: range.endDate, useCustomDays: useCustomDays }),
        __typename: "DateRange",
      } as FieldPathValue<TFieldValues, TName>);
    },
    [name, onSetValue, useCustomDays]
  );

  const handleSetDefaultValue = () => {
    setRanges([
      {
        startDate: getStartDay(defaultValue),
        endDate: getEndDay(defaultValue),
        key: "selection",
        kind: defaultValue?.range,
      },
    ]);
  };

  const handleShowPicker = () => {
    boxProps.onToggle(true);
  };

  const handleClosePicker = useCallback(() => {
    boxProps.onToggle(false);
  }, [boxProps]);

  const handleClear = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setRanges([DEFAULT_DATE_RANGE]);
      handleSetInputValue(DEFAULT_DATE_RANGE);
    },
    [handleSetInputValue, setRanges]
  );

  const handleApply = useCallback(() => {
    handleSetInputValue(ranges[0]);
    handleClosePicker();
  }, [handleSetInputValue, ranges, handleClosePicker]);

  const handleCancel = () => {
    handleSetDefaultValue();
    handleClosePicker();
  };

  const prevDefaultValue = usePrevious(defaultValue);

  useEffect(() => {
    if (!isEqual(prevDefaultValue, defaultValue)) {
      setRanges([
        {
          startDate: getStartDay(defaultValue),
          endDate: getEndDay(defaultValue),
          key: "selection",
          kind: defaultValue?.range,
        },
      ]);
    }
  }, [prevDefaultValue, defaultValue]);

  const clearedRanges = useMemo(
    () =>
      ranges.map((range) => ({
        ...range,
        startDate: range.startDate === null ? undefined : range.startDate,
      })),
    [ranges]
  );

  return (
    <div
      className={cs("DateRange", {
        "pointer-events-none no-user-select": isLoading,
        ShowOnHoverIsActive: boxProps.isOpen,
      })}
    >
      <span {...(input?.disabled ? {} : triggerElementProps)} ref={reference} data-active-element={boxProps.isOpen}>
        <InputController
          {...rest}
          control={control}
          isLoading={isLoading}
          label={label}
          name={name}
          defaultValue={
            defaultValue
              ? {
                  from: defaultValue.from || "",
                  to: defaultValue.to || "",
                  range: defaultValue.range || "",
                  fromDays: defaultValue.fromDays || defaultValue.fromDays === 0 ? defaultValue.fromDays : "",
                  toDays: defaultValue.toDays || defaultValue.toDays === 0 ? defaultValue.toDays : "",
                }
              : (DEFAULT_DATE_VALUE as any)
          }
          input={{
            ...input,
            tabIndex: 0,
            type: "text",
            readOnly: true,
            hasArrow: true,
            isInputContentOpen: boxProps.isOpen,
            onKeyDownCapture: (event) => {
              if (event.key === "ArrowDown" && !boxProps.isOpen) {
                handleShowPicker();
                event.preventDefault();
              }
            },
            isClearable: !disableClear && !!ranges[0].startDate,
            icon: "calendar",
            minified: isInputMinified,
          }}
          onClear={handleClear}
        />
      </span>
      <DropdownFloatingBox {...boxProps} lockScroll={true}>
        <div className="DateRange" data-test-id="date-range-popup" style={{ outline: "none" }} tabIndex={0}>
          <div className="DateRange-container">
            <DateRangePicker
              direction="horizontal"
              inputRanges={[]}
              maxDate={maxDate === "today" ? startOfDay(new Date()) : undefined}
              months={2}
              moveRangeOnFirstSelection={false}
              ranges={clearedRanges}
              showDateDisplay={false}
              staticRanges={dateRanges}
              weekdayDisplayFormat="EEEEEE"
              weekStartsOn={1}
              showPreview
              onChange={(item) => {
                setRanges([item.selection]);
              }}
            />
            <div className="DateRange-footer">
              <Row alignItems="center">
                <Text color="lightGray">Range:</Text>
                <Badge className="ml-6" kind="lightGray" size="large">
                  {daysCount} {daysCount === 1 ? "Day" : "Days"}
                </Badge>
              </Row>
              <Row alignItems="center" className="mt-0">
                <Checkbox
                  label="Use floating days"
                  margin={0}
                  input={{
                    id: "use-custom-days",
                    checked: useCustomDays,
                    onChange: ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => setCustomDays(checked),
                    disabled: !ranges[0].startDate || !ranges[0].endDate,
                  }}
                />
                <div className={cs("ml-12", { "pointer-events-none": !ranges[0].startDate || !ranges[0].endDate })}>
                  <Tooltip
                    tooltipContent={`I.e. the date range will always be the last ${daysCount} ${
                      daysCount === 1 ? "day" : "days"
                    } (according to the selected time period)`}
                  >
                    <Icon
                      kind="info-circle-fill"
                      size="16px"
                      className={cs("negative-ml-8 Icon--lightGray", {
                        "Icon--disabled": !ranges[0].startDate || !ranges[0].endDate,
                      })}
                    />
                  </Tooltip>
                </div>
              </Row>
              <div className="ml-a">
                <ButtonSecondary onClick={handleCancel}>Cancel</ButtonSecondary>
                <ButtonPrimary data-test-id="apply-button" onClick={handleApply}>
                  Apply
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </DropdownFloatingBox>
    </div>
  );
};
