import React from "react";
import classNames from "classnames";
import { TilePropsT } from "./Tile";
import { TileBadge } from "./TileBadge";
import { TileCheck } from "./TileCheck";

type PropsT = Pick<TilePropsT, "alphaBadge" | "badge" | "children" | "text" | "tooltip" | "isWithCheck">;

export const TileContentVertical = ({ alphaBadge, badge, children, isWithCheck, text, tooltip }: PropsT) => (
  <>
    {(alphaBadge || badge) && <TileBadge alphaBadge={alphaBadge} badge={badge} />}

    {isWithCheck && <TileCheck />}

    {children}
    {text && <div className={classNames({ "pt-16": !!badge || (tooltip && tooltip.length > 0) })}>{text}</div>}
  </>
);
