import React, { HTMLAttributes, useCallback } from "react";
import cs from "classnames";
import { SubAccountT, SystemNameT } from "../../graphql/generated/graphql";
import { ButtonSecondary } from "../../ui/Button/Button";
import { Text } from "../../ui/Text/Text";
import { Tooltip } from "../../ui/Tooltip/Tooltip";
import { GetAccountLinkT } from "./AccountSelect";
import { AccountSelectConnectedSystemsPreview } from "./AccountSelectConnectedSystemsPreview";
import { ButtonConnectNewSystemWithPopover } from "./ButtonConnectNewSystemWithPopover";
import { SYSTEM_NAMES } from "./constants";
import { useGetAccountLink } from "./useGetAccountLink";

type PropsT = {
  connectedSystems: SubAccountT[];
  getAccountLink: GetAccountLinkT;
  isAnyAccountConnected: boolean;
  onClearSubaccounts: (systemName: SystemNameT) => void;
  onEditedSystem: (systemName: SystemNameT) => void;
  refetchSubAccounts: () => void;
  systemName: SystemNameT;
} & HTMLAttributes<HTMLDivElement>;

export const AccountSelectButton = ({
  className,
  connectedSystems,
  getAccountLink,
  isAnyAccountConnected,
  onClearSubaccounts,
  onEditedSystem,
  refetchSubAccounts,
  systemName,
  ...rest
}: PropsT) => {
  const accountAccessLink = useGetAccountLink({ getAccountLink, systemName });

  const connectedSystemsCount = connectedSystems.length || 0;
  const selectAccount = useCallback(() => {
    refetchSubAccounts();
    onEditedSystem(systemName);
  }, [refetchSubAccounts, onEditedSystem, systemName]);

  return (
    <div>
      <div
        {...rest}
        aria-label="Account select button"
        role="group"
        className={cs("btn-group d-flex align-items-center", className, {
          "border border-color-red border-radius-8": isAnyAccountConnected && !connectedSystemsCount,
        })}
      >
        {!isAnyAccountConnected ? (
          <ButtonConnectNewSystemWithPopover
            accountAccessLink={accountAccessLink}
            buttonText={`Add ${SYSTEM_NAMES[systemName]}`}
            buttonVariant="secondary"
            disabled={!accountAccessLink}
            systemName={systemName}
            onSuccess={selectAccount}
          />
        ) : (
          <>
            <Tooltip
              enterDelay={300}
              placement="bottom-end"
              roleType="dialog"
              tooltipContent={
                !!connectedSystems?.length ? (
                  <AccountSelectConnectedSystemsPreview systems={connectedSystems} />
                ) : undefined
              }
            >
              <div className="d-flex align-items-center">
                {/* TODO: Better styles after UI */}
                <ButtonSecondary
                  className="like-segmented-button--first"
                  systemIcon={systemName}
                  type="button"
                  onClick={() => onEditedSystem(systemName)}
                >
                  {SYSTEM_NAMES[systemName]} ({connectedSystemsCount})
                </ButtonSecondary>
                <ButtonSecondary
                  icon="organization-cog"
                  type="button"
                  className={cs("ml-0", {
                    "like-segmented-button--middle": isAnyAccountConnected && !!connectedSystemsCount,
                    "like-segmented-button--last": isAnyAccountConnected && !connectedSystemsCount,
                  })}
                  onlyIcon
                  onClick={() => onEditedSystem(systemName)}
                />
              </div>
            </Tooltip>
            {isAnyAccountConnected && !!connectedSystemsCount && (
              <ButtonSecondary
                className="ml-0 like-segmented-button--last"
                icon="remove"
                type="button"
                onlyIcon
                onClick={() => onClearSubaccounts(systemName)}
              />
            )}
          </>
        )}
      </div>
      {isAnyAccountConnected && !connectedSystemsCount && (
        <div>
          <Text color="error" size="xs">
            Please select views to show data.
          </Text>
        </div>
      )}
    </div>
  );
};
