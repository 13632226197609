/* eslint-disable @typescript-eslint/no-explicit-any */
import { Notifier } from "@airbrake/browser";

const railsEnv = document.getElementById("dee-reporting-root")?.dataset.railsEnv;

export const airbrake = new Notifier({
  projectId: 435714,
  projectKey: "9146bc574c70e704714f54e7a1f19cd3",
  environment: railsEnv,
});

export const airbrakeNotify = (event: any) => {
  if (railsEnv === "production" || railsEnv === "stage") {
    airbrake.notify(event);
  }
};
