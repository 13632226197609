import React from "react";
import { Row } from "../../ui/grid/Grid";
import { HeadingSection } from "../../ui/Heading/Heading";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { KpiScoreValue } from "../organizationsKpiList/OrganizationKpiListItem";

export const KpiTileOverallScore = ({ progress }: { progress: number }) => (
  <Tile isFullHeight>
    <HeadingSection margin={0}>Overall Score</HeadingSection>

    <Row className="mt-24 mb-8" justify="center">
      <KpiScoreValue score={progress} style={{ fontSize: "36px", lineHeight: "44px" }} />
    </Row>

    <Row justify="center">
      <Text color="gray">Total Score of all KPIs in this organization.</Text>
    </Row>
  </Tile>
);
