import React, { HTMLProps, ReactElement } from "react";
import classNames from "classnames";
import { FieldError } from "react-hook-form";
import { HTMLInputTypeAttributeT } from "../../types/common";
import FormGroup from "./FormGroup";
import Label from "./Label";

type InputT = Omit<HTMLProps<HTMLInputElement>, "type" | "id"> & {
  id: string;
  type?: Exclude<HTMLInputTypeAttributeT, "radio">;
};

type PropsT = {
  className?: string;
  error?: FieldError;
  input: InputT;
  isFullWidth?: boolean;
  isLineCheck?: boolean;
  label?: string | number | ReactElement;
  margin?: 0 | 4 | 8 | 12 | 16 | 20 | 24 | 28 | 32;
  testId?: string;
};

const Checkbox = ({
  className,
  error,
  input,
  isFullWidth = false,
  isLineCheck,
  label = " ",
  margin = 8,
  testId,
}: PropsT) => {
  return (
    <FormGroup
      error={error}
      id={input.id}
      testId={testId}
      className={classNames(className, "checkbox Checkboxes-item", `mt-${margin}`, {
        "w-100": isFullWidth,
        "Checkboxes--lineCheck": isLineCheck,
      })}
      isCheckbox
    >
      <div>
        <input
          {...input}
          className={classNames("Checkboxes-input check_boxes hidden", input.className)}
          type="checkbox"
        />

        {label && (
          <Label className={classNames("check_boxes", { "d-block": isFullWidth })} htmlFor={input.id}>
            {label}
          </Label>
        )}
      </div>
    </FormGroup>
  );
};

export default Checkbox;
