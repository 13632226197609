import { useCallback, useLayoutEffect, useRef } from "react";

type StyleObjectT = { overflow: string; position: string };

export const useScrollBlock = (disableOnMount = false) => {
  const scrollBlocked = useRef(false);
  const html = document.documentElement;
  const { body } = document;

  const blockScroll = useCallback(() => {
    if (!body || !body.style || scrollBlocked.current) {
      return;
    }

    html.style.position = "relative";
    html.style.overflow = "hidden";
    body.style.position = "relative";
    body.style.overflow = "hidden";

    scrollBlocked.current = true;
  }, [body, html]);

  const allowScroll = useCallback(
    ({ bodyStyles, htmlStyles }: { bodyStyles: StyleObjectT; htmlStyles: StyleObjectT }) => {
      if (!body || !body.style || !scrollBlocked.current) {
        return;
      }

      html.style.position = htmlStyles.position;
      html.style.overflow = htmlStyles.overflow;
      body.style.position = bodyStyles.position;
      body.style.overflow = bodyStyles.overflow;

      scrollBlocked.current = false;
    },
    [body, html]
  );

  useLayoutEffect((): (() => void) => {
    const bodyStyles = { position: body.style.position, overflow: body.style.overflow };
    const htmlStyles = { position: html.style.position, overflow: html.style.overflow };

    if (!disableOnMount) {
      blockScroll();
    }

    return () => {
      if (!disableOnMount) {
        allowScroll({ bodyStyles, htmlStyles });
      }
    };
  }, [allowScroll, blockScroll, body, disableOnMount, html]);

  return { blockScroll, allowScroll };
};
