import React from "react";
import { DropdownIndicatorProps, GroupBase, components } from "react-select";
import { Icon } from "../Icon/Icon";

export const CustomDropdownIndicator = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>
) => (
  <components.DropdownIndicator {...props}>
    <Icon kind="chevron-down" size="20px" isInheritColor />
  </components.DropdownIndicator>
);
