import React from "react";
import { useGetWidgetComponentData } from "../../hooks/useGetWidgetComponentData";
import { WidgetComponentPropsT } from "../../types/widgets";
import { WidgetContextT } from "../reportBoard/ReportBoardSection";
import { WidgetTableComponentContent } from "./TableComponentContent";
import widgetComponentContainer from "./widgetComponentContainer";
import { widgetDataComponentContainer } from "./widgetDataComponentContainer";

export type TableComponentPropsT = WidgetComponentPropsT & {
  widgetContext: WidgetContextT;
};

const HelperComponent = widgetDataComponentContainer(WidgetTableComponentContent);

const WidgetTableComponent = ({
  filterValues,
  isPreview,
  onWidgetDataChange,
  widget,
  ...rest
}: TableComponentPropsT) => {
  const result = useGetWidgetComponentData({ filterValues, widget, onWidgetDataChange });

  const loading = result.loading;
  const error = result.error;

  return (
    <HelperComponent
      {...rest}
      error={error}
      filterValues={filterValues}
      isPreview={isPreview}
      loading={loading}
      widget={widget}
      widgetData={result.widgetData}
    />
  );
};

export default widgetComponentContainer(WidgetTableComponent);
