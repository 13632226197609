import { ReactElement } from "react";
import ReactDOMServer from "react-dom/server";
import { toast } from "react-toastify";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    NotificationCenter: any;
  }
}

export const notifySucces = (children: ReactElement | string) =>
  typeof window.NotificationCenter !== "undefined"
    ? new window.NotificationCenter().show_success(ReactDOMServer.renderToString(<>{children}</>))
    : toast.success(children);

export const notifyError = (children: ReactElement | string) =>
  typeof window.NotificationCenter !== "undefined"
    ? new window.NotificationCenter().show_error(ReactDOMServer.renderToString(<>{children}</>))
    : toast.error(children);

export const notifyWarning = (children: ReactElement | string) =>
  typeof window.NotificationCenter !== "undefined"
    ? new window.NotificationCenter().show_warning(ReactDOMServer.renderToString(<>{children}</>))
    : toast.warn(children);
