import React, { useCallback } from "react";
import cs from "classnames";
import { SystemNameT } from "../../graphql/generated/graphql";
import { ButtonPrimary } from "../../ui/Button/Button";
import { HeadingSmall } from "../../ui/Heading/Heading";
import { SystemIcon } from "../../ui/SystemIcon/SystemIcon";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { GetAccountLinkT } from "./AccountSelect";
import { ButtonConnectNewSystemWithPopover } from "./ButtonConnectNewSystemWithPopover";
import { SYSTEM_NAMES } from "./constants";
import { useGetAccountLink } from "./useGetAccountLink";

export type ConnectSystemItemPropsT = {
  className?: string;
  getAccountLink: GetAccountLinkT;
  isConnected: boolean;
  onShowSelectSystemModal: (systemName: SystemNameT) => void;
  refetchSubAccounts: () => void;
  systemName: SystemNameT;
};

export const ConnectSystemItem = ({
  className,
  getAccountLink,
  isConnected,
  onShowSelectSystemModal,
  refetchSubAccounts,
  systemName,
}: ConnectSystemItemPropsT) => {
  const accountAccessLink = useGetAccountLink({ getAccountLink, systemName });

  const selectAccount = useCallback(() => {
    refetchSubAccounts();
    onShowSelectSystemModal(systemName);
  }, [onShowSelectSystemModal, systemName, refetchSubAccounts]);

  return (
    <Tile className={className} contentStyle={{ height: "100%" }} style={{ flexBasis: "calc(33% - 8px)" }}>
      <div className="d-flex flex-column h-100">
        <SystemIcon size="20px" system={systemName} />
        <HeadingSmall className="mt-4">{SYSTEM_NAMES[systemName]}</HeadingSmall>
        <Text color="gray">Connect your {SYSTEM_NAMES[systemName]} Account to gather data.</Text>
        <div className={cs("pt-16 mt-a", { "d-flex": isConnected })}>
          {isConnected && <ButtonPrimary onClick={selectAccount}>Use Account</ButtonPrimary>}
          <ButtonConnectNewSystemWithPopover
            accountAccessLink={accountAccessLink}
            buttonText={isConnected ? "Connect new" : "Connect"}
            buttonVariant={isConnected ? "secondary" : "primary"}
            disabled={!accountAccessLink}
            onSuccess={selectAccount}
          />
        </div>
      </div>
    </Tile>
  );
};
