import React, { FC } from "react";
import errorNoDataImage from "../../assets/error_no_data.svg";
import { getWidgetDataErrors } from "../../functions/widgetHelpers";
import { DataConsistencyT, WidgetDataFragmentT } from "../../graphql/generated/graphql";
import { UseSearchT } from "../../hooks/useSearch";
import { ReportFilterT } from "../../types/report";
import { WidgetComponentT, WidgetComponentsT, WidgetT } from "../../types/widgets";
import { HeadingPage } from "../../ui/Heading/Heading";
import { WidgetContextT } from "../reportBoard/ReportBoardSection";
import { ChangeWidgetDataT } from "../widget/widgetContainer";

export type WidgetComponentContainerPropsT = {
  componentType: WidgetComponentT;
  dataConsistency?: DataConsistencyT;
  filterData: UseSearchT<WidgetDataFragmentT["rows"]["nodes"]>["filterData"];
  filterValues: ReportFilterT;
  isPreview?: boolean;
  isShowRawData: boolean;
  onCloseRawData: () => void;
  onWidgetDataChange: ChangeWidgetDataT;
  widget: WidgetT;
  widgetContext: WidgetContextT;
};

type ContainerT = (component: WidgetComponentsT) => FC<WidgetComponentContainerPropsT>;

const widgetComponentContainer: ContainerT =
  (WrappedComponent) =>
  ({
    componentType,
    dataConsistency,
    filterData,
    filterValues,
    isPreview,
    isShowRawData,
    onCloseRawData,
    onWidgetDataChange,
    widget,
    widgetContext,
  }: WidgetComponentContainerPropsT) => {
    if (getWidgetDataErrors(widget).length > 0) {
      return (
        <div className="mt-16 mb-40 text-center">
          <div className="pv-24">
            <img alt="Error no data" src={errorNoDataImage} />
            <div>
              <HeadingPage className="mt-16">No Data To View</HeadingPage>
              {getWidgetDataErrors(widget).map((error) => (
                <div key={error}>{error}</div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    return (
      <WrappedComponent
        className={`widget-component pos-relative widget-component--${componentType}`}
        dataConsistency={dataConsistency}
        filterData={filterData}
        filterValues={filterValues}
        isPreview={isPreview}
        isShowRawData={isShowRawData}
        widget={widget}
        widgetContext={widgetContext}
        onCloseRawData={onCloseRawData}
        onWidgetDataChange={onWidgetDataChange}
      />
    );
  };

export default widgetComponentContainer;
