import React from "react";
import { Maybe, ProgressSummaryT } from "../../graphql/generated/graphql";
import { Col, Row } from "../../ui/grid/Grid";
import { KpiListItemPropsT } from "../kpiList/KpiListItem";
import { KpiTileActiveKpis } from "./KpiTileActiveKpis";
import { KpiTileOverallBestWorst } from "./KpiTileOverallBestWorst";
import { KpiTileOverallScore } from "./KpiTileOverallScore";

type PropsT = {
  kpiSettings?: KpiListItemPropsT["kpiSetting"][];
  kpiSummary?: Maybe<ProgressSummaryT>;
};

export const KpiSummaryOverview = ({ kpiSettings, kpiSummary }: PropsT) => {
  return (
    <Row type="grow">
      {typeof kpiSummary?.estimatedProgress === "number" && (
        <Col type="grow">
          <KpiTileOverallScore progress={kpiSummary?.estimatedProgress} />
        </Col>
      )}
      {!!kpiSettings?.length && (
        <>
          <Col type="grow">
            <KpiTileActiveKpis kpiSettings={kpiSettings} />
          </Col>
          <Col type="grow">
            <KpiTileOverallBestWorst kpiSettings={kpiSettings} />
          </Col>
        </>
      )}
    </Row>
  );
};
