import React, { useState } from "react";
import imageNotFound from "../../assets/image_not_found.svg";
import { DUPLICATED_PREFIX } from "../../constants/report";
import { ROUTES } from "../../constants/routes";
import { useCreateReport } from "../../hooks/useCreateReport";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { ReportListItemT } from "../../types/report";
import { ReportT } from "../../types/widgets";
import { Badge } from "../../ui/Badge/Badge";
import { ButtonPrimary } from "../../ui/Button/Button";
import { DropdownMenu } from "../../ui/Dropdown/DropdownMenu";
import { Col, Row } from "../../ui/grid/Grid";
import { HeadingSmall } from "../../ui/Heading/Heading";
import { Icon } from "../../ui/Icon/Icon";
import { SystemIcon } from "../../ui/SystemIcon/SystemIcon";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { SYSTEM_NAMES } from "../accountSelect/constants";
import { useConfirmationContext } from "../confirmationDialog/confirmationDialogContext";
import { withApiStateHandler } from "../ErrorLoadingWrapper/withApiStateHandler";
import { DuplicatedToOrganizationModal } from "./DuplicatedToOrganizationModal";

type PropsT = {
  canEdit: boolean;
  onDeleteReport: (id: ReportT["id"]) => void;
  report: ReportListItemT;
};

export const ReportItem = withApiStateHandler(({ canEdit, onDeleteReport, report }: PropsT) => {
  const {
    organization,
    user: { organizations },
  } = useAppSettings();

  const { callWithConfirmation } = useConfirmationContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { createReport, loading } = useCreateReport({
    successMessage: `Report ${report.name} was succesfully duplicated`,
    namePrefix: DUPLICATED_PREFIX,
    shouldGoToReport: false,
  });

  const uniqueSourceSystems = report.sourceSystems
    .map((sourceSystem) => sourceSystem.name)
    .filter((value, index, self) => self.indexOf(value) === index);

  return (
    <Col width="280px">
      <Tile isFullHeight>
        <Col height="100%">
          <div aria-label="Report image" className="pos-relative w-100 mb-16" role="img" style={{ height: "144px" }}>
            {organization.isTemplate && report.visible && (
              <Badge kind="green" style={{ position: "absolute", top: "-5px", left: "-5px" }}>
                <Icon className="mr-4" color="#fff" kind="active" size="1em" />
                visible
              </Badge>
            )}

            {report.shared && (
              <Badge kind="blue" size="medium" style={{ position: "absolute", top: "8px", right: "8px", zIndex: 10 }}>
                Shared
              </Badge>
            )}

            <img
              alt="Report preview"
              className="pos-absolute mw-100"
              src={report.thumbnailUrl ? report.thumbnailUrl : imageNotFound}
              style={{ inset: "0px", margin: "auto", maxHeight: "100%" }}
            />
          </div>

          <div className="delimiter negative-ml-16 negative-mr-16 mb-16" />
          <div className="line-clamp line-clamp-2">
            <HeadingSmall margin={4}>{report.name}</HeadingSmall>
          </div>

          <div className="mb-8 line-clamp line-clamp-5">
            <Text color="gray">{report.description}</Text>
          </div>

          <div className="mt-a pt-8">
            <Text bold>Platforms:</Text>
            <Row className="mt-4">
              {uniqueSourceSystems.length ? (
                uniqueSourceSystems.map((system) => (
                  <Col key={system}>
                    <SystemIcon system={system} tooltipContent={SYSTEM_NAMES[system]} />
                  </Col>
                ))
              ) : (
                <span>&nbsp;</span>
              )}
            </Row>
          </div>

          <Row className="mt-16">
            <Col type="grow">
              <ButtonPrimary data-test-id="open-report" to={ROUTES.report(report.id)} hasFullWidth>
                Open Report
              </ButtonPrimary>
            </Col>
            {canEdit && (
              <Col>
                <DropdownMenu
                  buttonProps={{ variant: "tertiary" }}
                  isLoading={loading}
                  testId={`Dropdown--${report.name}`}
                  collection={[
                    {
                      value: "Duplicate report",
                      icon: "duplicate",
                      onClick: () => createReport({ name: report.name, templateId: report.id }),
                    },
                    ...(!organizations.available.length
                      ? []
                      : [
                          {
                            value: "Duplicate to Organization",
                            icon: "duplicate",
                            onClick: () => setIsModalOpen(true),
                          },
                        ]),
                    {
                      value: "Delete report",
                      icon: "trash",
                      color: "danger",
                      onClick: () => {
                        callWithConfirmation(() => onDeleteReport(report.id), {
                          description: `Are you sure you want to delete this report: "${report.name}"?`,
                          title: "Delete report",
                          testId: `delete-report-${report.id}`,
                          confirmButtonProps: {
                            variant: "red",
                            text: "Delete",
                          },
                        });
                      },
                    },
                  ]}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Tile>
      {isModalOpen && (
        <DuplicatedToOrganizationModal
          reportId={report.id}
          reportName={report.name}
          onModalClose={() => setIsModalOpen(false)}
        />
      )}
    </Col>
  );
});
