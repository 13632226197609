import React, { useState } from "react";
import { BaseMutationOptions } from "@apollo/client";
import { useParams } from "react-router-dom";
import { mergeApiErrors } from "../functions/mergeApiErrors";
import { notifyError, notifySucces } from "../functions/toast";
import {
  ReportUpdateMutationT,
  ReportUpdateMutationVariablesT,
  useReportUpdateMutation,
} from "../graphql/generated/graphql";
import { useAppSettings } from "../providers/appSettingsProvider";
import { RouteParamsT } from "../types/router";

type ParamsT = {
  dontLeave?: boolean;
  successMessage?: string;
  sucessCallback?: (
    updatedReport: NonNullable<
      NonNullable<NonNullable<ReportUpdateMutationT["organization"]>["updateReport"]>["report"]
    >
  ) => void;
} & Pick<
  BaseMutationOptions<ReportUpdateMutationT, ReportUpdateMutationVariablesT>,
  "refetchQueries" | "awaitRefetchQueries"
>;

export const useUpdateReport = ({ successMessage, sucessCallback, ...rest }: ParamsT) => {
  const { organization } = useAppSettings();
  const { reportId } = useParams<RouteParamsT["report"]>();
  const [mutationErrors, setMutationErrors] = useState<string[] | null | undefined>([]);

  const [mutation, mutateState] = useReportUpdateMutation({
    ...rest,
    onCompleted: (mutateData) => {
      const updatedReport = mutateData.organization?.updateReport;
      const errors = updatedReport?.errors;

      if (!!errors?.length) {
        setMutationErrors(errors);
        notifyError(<>Error when saving report</>);
      }
      if (updatedReport?.report && successMessage) {
        notifySucces(<div data-test-id="notify-success-message">{successMessage}</div>);
        if (typeof sucessCallback === "function") {
          sucessCallback(updatedReport?.report);
        }
      }
    },
    onError: () => notifyError(<>Server error when updating report!</>),
  });

  const handleUpdateReport = (values: Partial<ReportUpdateMutationVariablesT>) => {
    mutation({
      variables: {
        ...values,
        reportId: reportId as string,
        organizationExternalId: organization.externalId,
        conditions: values.conditions || [],
      },
    });
  };

  return {
    loading: mutateState.loading,
    data: mutateState.data,
    updateReport: handleUpdateReport,
    errors: mergeApiErrors(mutationErrors, mutateState.error),
  };
};
