import React, { useMemo } from "react";
import { SYSTEMS_AD, SYSTEMS_ANALYTICS } from "../../constants/report";
import { SystemNameT } from "../../graphql/generated/graphql";
import ModalBody from "../../ui/Modal/ModalBody";
import { Tab } from "../../ui/Tabs/Tab";
import { TabList } from "../../ui/Tabs/TabList";
import { TabPanel } from "../../ui/Tabs/TabPanel";
import { Tabs } from "../../ui/Tabs/Tabs";
import { AccountSelectModalPropsT } from "./AccountSelectModal";
import { ConnectSystemList } from "./ConnectSystemList";

const ALL_SYSTEMS = [...SYSTEMS_AD, ...SYSTEMS_ANALYTICS];

export const SystemsModalContent = ({
  requiredSystems,
  subAccountsKeys,
  ...rest
}: Pick<
  AccountSelectModalPropsT,
  "requiredSystems" | "getAccountLink" | "onShowSelectSystemModal" | "refetchSubAccounts"
> & {
  subAccountsKeys: string[];
}) => {
  const filtredSystems = useMemo(
    () => ALL_SYSTEMS.filter((value) => !requiredSystems.includes(value)),
    [requiredSystems]
  );

  const isAuthorized = subAccountsKeys.length > 0;

  return (
    <ModalBody>
      {requiredSystems.length > 0 && (
        <ConnectSystemList
          {...rest}
          className="mb-24"
          heading="Required For This Report"
          isConnectedCallback={(system) => subAccountsKeys.includes(system)}
          systems={requiredSystems}
        />
      )}

      {!isAuthorized && (
        <ConnectSystemList
          {...rest}
          heading={requiredSystems.length > 0 ? "Other" : ""}
          isConnectedCallback={() => false}
          systems={filtredSystems}
        />
      )}

      {isAuthorized && (
        <Tabs initialSelectedTab="authorized" testId="accounts">
          <TabList className="mb-16">
            <Tab id="authorized">Authorized ({subAccountsKeys.length})</Tab>
            <Tab id="any">All accounts ({ALL_SYSTEMS.length})</Tab>
          </TabList>

          <TabPanel tabId="authorized">
            <ConnectSystemList {...rest} isConnectedCallback={() => true} systems={subAccountsKeys as SystemNameT[]} />
          </TabPanel>

          <TabPanel tabId="any">
            <ConnectSystemList
              {...rest}
              isConnectedCallback={(system) => subAccountsKeys.includes(system)}
              systems={ALL_SYSTEMS}
            />
          </TabPanel>
        </Tabs>
      )}
    </ModalBody>
  );
};
