import { ReactElement, useCallback, useEffect, useState } from "react";
import type { Transition } from "history";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "../i18n/translation";
import { useAppSettings } from "../providers/appSettingsProvider";
import { ConfirmationModal } from "../ui/Modal/ConfirmationModal";
import { useNavigationBlocker } from "./useNavigationBlocker";

export type UnsavedChangesAlertPropsT = {
  confirmationCallback?: (confirmationResult: "continue" | "cancel") => void;
  description: string;
  isForceShow?: boolean;
};
type UseCallbackPromptT = (when: boolean) => {
  UnsavedChangesAlert: (props: UnsavedChangesAlertPropsT) => ReactElement<Element> | null;
  showModal: boolean;
};

export const useUnsavedAlertOnRedirect: UseCallbackPromptT = (when) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [lastLocation, setLastLocation] = useState<{ location: { pathname: string; search?: string } } | null>(null);
  const { appPathname } = useAppSettings();
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowModal(false);
  }, []);

  const confirmNavigation = useCallback(() => {
    setShowModal(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      const newBaseURL =
        appPathname === "/" ? lastLocation?.location?.pathname : lastLocation?.location?.pathname.split(appPathname)[1];
      const newURL = newBaseURL + lastLocation?.location?.search;

      navigate(newURL?.startsWith("/") ? newURL : `/${newURL}`);
    }
  }, [confirmedNavigation, appPathname, lastLocation, navigate]);

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback(
    (tx: Transition) => {
      const escapedPathname = tx.location.pathname.replace(appPathname, "/").replace(/\/{2,}/g, "/");
      // in if condition we are checking next location and current location are equals or not
      if (!confirmedNavigation && escapedPathname !== location.pathname) {
        setShowModal(true);
        setLastLocation(tx);
        return false;
      }

      tx.retry();
    },
    [confirmedNavigation, location.pathname, appPathname]
  );

  useNavigationBlocker(handleBlockedNavigation, when);

  const UnsavedChangesAlert = ({ confirmationCallback, description, isForceShow }: UnsavedChangesAlertPropsT) =>
    showModal || !!isForceShow ? (
      <ConfirmationModal
        confirmButtonText={t("confirmationModal.unsavedChanges.confirmButtonText")}
        description={description}
        heading={t("confirmationModal.unsavedChanges.heading")}
        isOpen={showModal || !!isForceShow}
        type="confirm"
        close={() => {
          cancelNavigation();
          if (confirmationCallback) {
            confirmationCallback("cancel");
          }
        }}
        onConfirm={() => {
          confirmNavigation();
          if (confirmationCallback) {
            confirmationCallback("continue");
          }
        }}
      />
    ) : null;

  return { UnsavedChangesAlert, showModal };
};
