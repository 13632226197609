import React from "react";
import { ConditionT } from "../../types/widgets";
import { ButtonSecondary } from "../../ui/Button/Button";
import { Tooltip } from "../../ui/Tooltip/Tooltip";
import { ConditionsPreview } from "../conditionsPreview/ConditionsPreview";
import { MetricsByIdT } from "../reportBoard/context/types";

type PropsT = {
  canEdit?: boolean;
  className?: string;
  conditions: ConditionT[];
  metricsById: MetricsByIdT;
  onFilterOpen: () => void;
};

export const FilterButtonWithPreview = ({ canEdit, className, conditions, metricsById, onFilterOpen }: PropsT) => {
  if (!conditions?.length && canEdit) {
    return (
      <div>
        <ButtonSecondary className={className} icon="filter" onlyIcon onClick={onFilterOpen} />
      </div>
    );
  }

  if (!!conditions?.length) {
    return (
      <div>
        <Tooltip
          placement="bottom-end"
          roleType="tooltip"
          tooltipContent={<ConditionsPreview conditions={conditions} metricsById={metricsById} isDark />}
        >
          <div className="pos-relative" style={{ zIndex: 2 }}>
            <ButtonSecondary disabled={!canEdit} icon="filter" onClick={canEdit ? onFilterOpen : undefined}>
              Active filter
            </ButtonSecondary>
          </div>
        </Tooltip>
      </div>
    );
  }

  return null;
};
