import React, { ForwardedRef } from "react";
import { CusotmIconBasicPropsT } from "./IconCustom";

export const HourglassHexIcon = React.forwardRef(
  ({ className, size, ...rest }: CusotmIconBasicPropsT, ref: ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1.8577 6.14434L12 0.288675L22.1423 6.14434V17.8557L12 23.7113L1.8577 17.8557V6.14434Z"
        fill="#D9D9D9"
        stroke="url(#paint0_linear_5559_109878)"
        strokeWidth="0.5"
      />
      <g clipPath="url(#clip0_5559_109878)">
        <g filter="url(#filter0_ddddd_5559_109878)">
          <path
            d="M15.6636 9.25444C16.2024 8.6115 16.5 7.80487 16.5 6.98419V5.75C16.5 5.47386 16.2761 5.25 16 5.25H8C7.72386 5.25 7.5 5.47386 7.5 5.75V6.98419C7.5 7.80487 7.797 8.61094 8.33644 9.25387L10.0723 11.3239C10.3912 11.7047 10.3935 12.2379 10.0774 12.621L8.31675 14.7551C7.79025 15.3941 7.5 16.1923 7.5 17.0023V18.25C7.5 18.5261 7.72386 18.75 8 18.75H16C16.2761 18.75 16.5 18.5261 16.5 18.25V17.0023C16.5 16.1923 16.2092 15.3947 15.6827 14.7557L13.9221 12.6216C13.6059 12.2385 13.6082 11.7052 13.9271 11.3244L15.6636 9.25444Z"
            fill="black"
          />
        </g>
        <path
          d="M14.8147 15.4707L13.0541 13.3366C12.3982 12.5423 12.4027 11.3914 13.0648 10.6011L14.8012 8.53114C15.1708 8.09014 15.375 7.55632 15.375 6.93701H8.625C8.625 7.55464 8.8275 8.08957 9.19875 8.53114L10.9346 10.6017C11.5972 11.392 11.6018 12.5423 10.9453 13.3377L9.18525 15.4713C8.81794 15.9156 8.625 16.4416 8.625 17.0626H15.375C15.375 16.4416 15.1815 15.9162 14.8147 15.4707Z"
          fill="#D9D9D9"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="17.5"
          id="filter0_ddddd_5559_109878"
          width="11"
          x="6.5"
          y="4.25"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_5559_109878" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="effect1_dropShadow_5559_109878" mode="normal" result="effect2_dropShadow_5559_109878" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
          <feBlend in2="effect2_dropShadow_5559_109878" mode="normal" result="effect3_dropShadow_5559_109878" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend in2="effect3_dropShadow_5559_109878" mode="normal" result="effect4_dropShadow_5559_109878" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
          <feBlend in2="effect4_dropShadow_5559_109878" mode="normal" result="effect5_dropShadow_5559_109878" />
          <feBlend in="SourceGraphic" in2="effect5_dropShadow_5559_109878" mode="normal" result="shape" />
        </filter>
        <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_5559_109878" x1="12" x2="12" y1="0" y2="24">
          <stop stopColor="#F9F9F9" />
          <stop offset="1" stopColor="#B1B1B1" />
        </linearGradient>
        <clipPath id="clip0_5559_109878">
          <rect fill="white" height="13.5" transform="translate(5.25 5.25)" width="13.5" />
        </clipPath>
      </defs>
    </svg>
  )
);
