import React, {
  CSSProperties,
  HTMLAttributes,
  MouseEvent,
  PropsWithChildren,
  forwardRef,
  useCallback,
  useState,
} from "react";
import cs from "classnames";
import { Tooltip } from "../Tooltip/Tooltip";

export const Ellipsis = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ isInline?: boolean; style?: CSSProperties; tag?: "span" | "p" | "div"; twoLine?: boolean }> &
    HTMLAttributes<HTMLElement>
>(({ children, className, isInline = true, style, tag = "span", twoLine = false, ...rest }, ref) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (event.target && event.currentTarget.offsetWidth !== event.currentTarget.scrollWidth && !showTooltip) {
        setShowTooltip(true);
      } else if (event.currentTarget.offsetWidth === event.currentTarget.scrollWidth && showTooltip) {
        setShowTooltip(false);
      }
    },
    [showTooltip, setShowTooltip]
  );

  const Tag = tag;

  return (
    <Tooltip tooltipContent={showTooltip ? children : null}>
      <Tag
        {...rest}
        ref={ref}
        style={style}
        className={cs(className, {
          "text-overflow": !isInline,
          "text-inline-block-overflow": isInline,
          "text-overflow-twolines": twoLine,
        })}
        onMouseEnter={handleMouseEnter}
      >
        {children}
      </Tag>
    </Tooltip>
  );
});
