import React, { ReactNode, useContext, useState } from "react";
import { API_PATH, TEMPLATE_ORGANIZATION_EXTERNAL_ID } from "../constants/common";

export type OrganizationTypeT = { id: string; name: string };

type StateT = {
  apiToken?: string;
  apiUri: string;
  appPathname: string;
  getOrganizationAppUrl: (organizationExternalId: string) => string;
  host: string;
  layoutSelectors: {
    breadcrumbContainer: string;
    mainMenuContainer: string;
    sidebarContainer: string;
    sidebarMainNavigation: string;
    sidebarMenu: string;
    sidebarWrapper: string;
    subheaderContainer: string;
    subheaderWrapper: string;
  };
  organization: {
    externalId: string;
    isTemplate: boolean;
  };
  reportToken?: string;
  sharedReportPassword?: string | null;
  user: {
    canEdit: boolean;
    organizations: { available: OrganizationTypeT[]; current: OrganizationTypeT | undefined };
  };
};

type AppSettiongsContextT =
  | null
  | (StateT & {
      setAppSettingsState: React.Dispatch<React.SetStateAction<StateT>>;
    });

const rootElement = document.getElementById("dee-reporting-root") as HTMLElement;

const getUserOrganizations = () => {
  const allOrganizations = JSON.parse(rootElement.dataset.managedOrganizations || "[]").map(
    (org: { id: number; name: string }) => ({
      id: `${org.id}`,
      name: org.name,
    })
  ) as { id: string; name: string }[];

  const current = allOrganizations.find((org) => org.id === rootElement.dataset.organizationExternalId);
  const available = allOrganizations.filter(
    (org) => org.id !== rootElement.dataset.organizationExternalId && org.id !== TEMPLATE_ORGANIZATION_EXTERNAL_ID
  );

  return { current, available };
};

const host = rootElement.dataset.host || "/";
const appUrlWithOrgPlaceholder = rootElement.dataset.appUrlWithOrganizationPlaceholder;
const orgPlaceholder = rootElement.dataset.organizationPlaceholder;
const accessType = rootElement.dataset.accessType as string;

const defaultState = {
  host,
  apiToken: rootElement.dataset.apiToken as string,
  reportToken: rootElement.dataset.reportToken as string,
  sharedReportPassword: null,
  apiUri: `${host}${API_PATH}`,
  appPathname: rootElement.dataset.appUrl as string,
  organization: {
    externalId: rootElement.dataset.organizationExternalId as string,
    isTemplate: rootElement.dataset.organizationExternalId === TEMPLATE_ORGANIZATION_EXTERNAL_ID,
  },
  user: {
    organizations: getUserOrganizations(),
    canEdit: accessType !== "read",
  },
  getOrganizationAppUrl: (organizationId: string) =>
    appUrlWithOrgPlaceholder?.replace(orgPlaceholder || "", organizationId) || "",
  layoutSelectors: {
    breadcrumbContainer: rootElement.dataset.selectorBreadcrumbContainer as string,
    mainMenuContainer: rootElement.dataset.selectorMainMenuContainer as string,
    sidebarWrapper: rootElement.dataset.selectorSidebarWrapper as string,
    sidebarMainNavigation: rootElement.dataset.selectorSidebarMainNavigation as string,
    sidebarContainer: rootElement.dataset.selectorSidebarContainer as string,
    sidebarMenu: rootElement.dataset.selectorSidebarMenu as string,
    subheaderWrapper: rootElement.dataset.selectorSubheaderWrapper as string,
    subheaderContainer: rootElement.dataset.selectorSubheaderContainer as string,
  },
};

const AppSettingsContext = React.createContext<AppSettiongsContextT>(null);

export const AppSettingsProvider = (props: { children: ReactNode }) => {
  const [state, setState] = useState<StateT>(defaultState);

  return (
    <AppSettingsContext.Provider value={{ ...state, setAppSettingsState: setState }}>
      {props.children}
    </AppSettingsContext.Provider>
  );
};

export const useAppSettings = () => {
  const context = useContext(AppSettingsContext);
  if (!context) {
    throw new Error("Component is outside appSettings provider");
  }
  return context;
};
