import React, { CSSProperties, ForwardedRef, MouseEvent, PropsWithChildren, forwardRef } from "react";
import cs from "classnames";
import { BADGE_KIND, BADGE_SIZE } from "./constants";

export type BadgeKindT = typeof BADGE_KIND[number];
type SizeT = typeof BADGE_SIZE[number];

export type BadgePropsT = {
  className?: string;
  isUppercase?: boolean;
  isWithBorder?: boolean;
  kind?: BadgeKindT;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  size?: SizeT;
  style?: CSSProperties;
  testId?: string;
};

export const Badge = forwardRef(
  (
    {
      children,
      className,
      isUppercase,
      isWithBorder,
      kind = "blue",
      onClick,
      size = "medium",
      style,
      testId,
    }: PropsWithChildren<BadgePropsT>,
    ref: ForwardedRef<HTMLElement>
  ) => {
    const handleClick = (event: MouseEvent<HTMLElement>) => {
      if (typeof onClick === "function") {
        event.preventDefault();
        onClick(event);
        return false;
      }
      return true;
    };

    return (
      <span
        ref={ref}
        data-test-id={testId}
        style={style}
        className={cs("Badge", className, {
          "Badge--uppercase": isUppercase,
          "Badge--withBorder": isWithBorder,
          [`Badge--${kind}`]: kind,
          [`Badge--${size}`]: size,
          "Badge--empty": !children && children !== 0,
        })}
        onClick={handleClick}
      >
        {children}
      </span>
    );
  }
);
