import React from "react";
import { SectionT } from "../../types/widgets";
import { DropdownMenu } from "../../ui/Dropdown/DropdownMenu";
import { useModal } from "../../ui/Modal";
import { useConfirmationContext } from "../confirmationDialog/confirmationDialogContext";
import { FormSectionInputsT } from "../formSection/FormSection";
import { PageContextT } from "./ReportBoardPage";
import { SectionModal } from "./ReportBoardSectionModal";

type PropsT = {
  pageContext: PageContextT;
  section: SectionT;
};

export const ReportBoardSectionControl = ({ pageContext, section }: PropsT) => {
  const editModalOptions = useModal();
  const { callWithConfirmation } = useConfirmationContext();
  const { sectionDelete, sectionDuplicate, sectionUpdate } = pageContext;

  const handleSectionDelete = () => {
    sectionDelete(section.id);
  };

  const handleSectionUpdate = (sectionInputData: FormSectionInputsT) => {
    sectionUpdate({ sectionId: section.id, sectionInputData: (prev) => ({ ...prev, ...sectionInputData }) });
    editModalOptions.close();
  };

  return (
    <div>
      <DropdownMenu
        buttonProps={{ variant: "secondary" }}
        className="show-on-hover-element section-helper-class"
        testId={`Dropdown--section-${section.name}`}
        collection={[
          {
            value: "Edit section",
            icon: "edit",
            onClick: () => editModalOptions.open(),
          },
          {
            value: "Duplicate section",
            icon: "duplicate",
            onClick: () => sectionDuplicate(section),
          },
          {
            value: "Delete section",
            icon: "trash",
            color: "danger",
            onClick: () => {
              callWithConfirmation(handleSectionDelete, {
                description: `Do you really want to delete this section: "${section.name}" and all its widgets?`,
                title: "Delete section",
                confirmButtonProps: { text: "Delete section", variant: "red" },
              });
            },
          },
        ]}
      />

      <SectionModal
        defaultValues={{ name: section.name, description: section.description || "" }}
        modalOptions={{ ...editModalOptions, testId: "section-edit-modal", heading: "Edit section" }}
        onSubmit={handleSectionUpdate}
      />
    </div>
  );
};
