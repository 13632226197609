import React, { useMemo, useState } from "react";
import { createHashMap } from "../../../functions/calculateFieldCounts";
import { DimensionT, WidgetDimensionT, useReportDimensionsLazyQueryT } from "../../../graphql/generated/graphql";
import { useRecentMetDims } from "../../../hooks/useRecentMetDims";
import { DimensionNewModal } from "../../../pages/DimensionNew";
import { useAppSettings } from "../../../providers/appSettingsProvider";
import { WidgetT } from "../../../types/widgets";
import { ButtonSecondary } from "../../../ui/Button/Button";
import { DropdownFloatingBox } from "../../../ui/Dropdown/DropdownFloatingBox";
import { useDropdown } from "../../../ui/Dropdown/useDropdown";
import { FakeInput } from "../../../ui/forms/FakeInput";
import { MetricDimensionSelectedCategoryT } from "../../../ui/forms/MetricDimensionController";
import { MetricDimensionSelect } from "../../../ui/forms/MetricDimensionSelect";
import { Tile } from "../../../ui/Tile/Tile";
import { useReportBoardContext } from "../../reportBoard/context/reportBoardContext";
import { ReportDimensionT } from "../../reportBoard/context/types";
import { ShowDimensionMetricPill } from "../../showDimensionMetricPill/ShowDimensionMetricPill";

type InputValueT = WidgetT["widgetDimensions"][number];

type PropsT = {
  formKey: string;
  index: number;
  isDefaultOpen: boolean;
  isDisabled: boolean;
  onChange: ({ index, newDimension }: { index: number; newDimension: InputValueT }) => void;
  selectedIds: string[];
  value: WidgetDimensionT;
};

type DimensionSelectPropsT = {
  dimensions: ReportDimensionT[];
  hasArrow?: boolean;
  id: string;
  isDefaultOpen?: boolean;
  isDisabled?: boolean;
  onChange: (dimensionId: DimensionT["id"] | null) => void;
  onCreate?: () => Promise<void>;
  placeholder?: string;
  selectedIds?: string[];
  value: DimensionT["id"];
};

export const DimensionSelect = ({
  dimensions,
  hasArrow,
  id,
  isDefaultOpen,
  isDisabled,
  onChange,
  onCreate,
  placeholder,
  selectedIds,
  value,
}: DimensionSelectPropsT) => {
  const [selectedCategory, setSelectedCategory] = useState<MetricDimensionSelectedCategoryT | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { addUsedDimension, recentDimensions } = useRecentMetDims();

  const dimensionsById = useMemo(() => createHashMap(dimensions, "id"), [dimensions]);

  const { boxProps, reference, triggerElementProps } = useDropdown({
    hasParentWidth: true,
    isDefaultOpen: isDefaultOpen,
  });

  const handleChange = (newValue: DimensionT["id"] | null) => {
    boxProps.onToggle(false);
    onChange(newValue || null);
    addUsedDimension(newValue);
  };

  const handleCreate = async (newDimensionId: string) => {
    if (onCreate) {
      await onCreate();
      handleChange(newDimensionId);
      setIsModalOpen(false);
    }
  };

  return (
    <div className="position-relative w-100" data-test-id="metricDimensionSelect">
      <div>
        <div {...triggerElementProps} ref={reference}>
          <FakeInput
            childrenOffset="3px"
            hasArrow={hasArrow}
            id={id}
            isDisabled={isDisabled}
            placeholder={placeholder}
            testId={id}
            onClick={() => {
              boxProps.onToggle((prev) => !prev);
            }}
          >
            {dimensionsById[value] && <ShowDimensionMetricPill item={dimensionsById[value]} />}
          </FakeInput>
        </div>
        <DropdownFloatingBox {...boxProps} className="d-flex flex-column noWrap" lockScroll={false}>
          <Tile
            className="w-100 overflow-auto"
            contentStyle={{ paddingTop: 0, paddingBottom: 0 }}
            style={{ minWidth: "300px" }}
          >
            <MetricDimensionSelect
              dimensions={dimensions}
              disabledIds={selectedIds}
              disabledTab="metric"
              recentDimensions={recentDimensions}
              selectedCategory={selectedCategory}
              onSetSelectedCategory={setSelectedCategory}
              onSetSelectedItem={(selectedDimension) => handleChange(selectedDimension.value || null)}
            />
            {onCreate && (
              <ButtonSecondary
                className="mb-8"
                icon="plus"
                block
                onClick={() => {
                  setIsModalOpen(true);
                  boxProps.onToggle(false);
                }}
              >
                Create New Custom Dimension
              </ButtonSecondary>
            )}
          </Tile>
        </DropdownFloatingBox>

        {isModalOpen && onCreate && (
          <DimensionNewModal
            onCreate={handleCreate}
            onClose={() => {
              setIsModalOpen(false);
              boxProps.onToggle(true);
            }}
          />
        )}
      </div>
    </div>
  );
};

export const InputWidgetDimensionsItem = ({
  formKey,
  index,
  isDefaultOpen,
  isDisabled,
  onChange,
  selectedIds,
  value,
}: PropsT) => {
  const pathString = ["widgetDimensions", index].join("-");

  const {
    dimensionsMetricsHydrateFromApi,
    report,
    widgetSettingsData: { dimensions },
  } = useReportBoardContext();

  const { organization } = useAppSettings();

  const [getDimensions] = useReportDimensionsLazyQueryT({
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      dimensionsMetricsHydrateFromApi({ dimensions: response.organization?.report?.dimensions.nodes });
    },
    variables: { reportId: report.id, organizationExternalId: organization.externalId },
  });

  const handleCreate = async () => {
    await getDimensions();
  };

  return (
    <DimensionSelect
      dimensions={dimensions}
      id={`${formKey}-${pathString}`}
      isDefaultOpen={isDefaultOpen}
      isDisabled={isDisabled}
      selectedIds={selectedIds}
      value={value?.dimensionId}
      onChange={(id) => onChange({ index, newDimension: { ...value, dimensionId: id || "" } })}
      onCreate={handleCreate}
    />
  );
};
