import React from "react";
import { WidgetComponentPropsT } from "../../types/widgets";
import widgetComponentContainer from "./widgetComponentContainer";

export type NoteComponentPropsT = WidgetComponentPropsT;

const WidgetNoteComponent = ({ widget }: NoteComponentPropsT) => (
  <div>{widget.properties?.content || "Text placeholder"}</div>
);

export default widgetComponentContainer(WidgetNoteComponent);
