import React from "react";
import classNames from "classnames";
import color from "color";
import { KpiSemaphoreT } from "../../graphql/generated/graphql";
import { Col, Row } from "../../ui/grid/Grid";
import { IconCustom } from "../../ui/IconCustom/IconCustom";
import { KPI_SEMAPHORE_VARIANTS } from "./KpiSemaphore";

type PropsT = {
  className?: string;
  isWithIcon?: boolean;
  semaphore?: KpiSemaphoreT | null;
  size?: "lg";
};

export const KpiSemaphoreBadge = ({ className, isWithIcon, semaphore, size }: PropsT) => {
  const isLarge = size === "lg";

  if (!semaphore) {
    return null;
  }

  const semaphoreVariant = KPI_SEMAPHORE_VARIANTS[semaphore];

  return (
    <Row
      alignItems="center"
      className={className}
      justify="center"
      padding="m"
      style={{
        padding: isWithIcon ? "0.3em 0.5em" : "0.4em 0.8em",
        fontSize: isLarge ? "18px" : "12px",
        fontWeight: "bold",
        borderRadius: "4px",
        background: !isWithIcon ? color(semaphoreVariant.color).alpha(0.2).toString() : undefined,
        color: semaphoreVariant.color,
        border: isWithIcon ? "1px solid" : undefined,
        whiteSpace: "nowrap",
      }}
    >
      {isWithIcon && (
        <Col type="shrink">
          <IconCustom name={semaphoreVariant.icon} size={20} />
        </Col>
      )}

      <Col className={classNames({ "pr-8": isWithIcon })} type="shrink">
        {semaphoreVariant.label}
      </Col>
    </Row>
  );
};
