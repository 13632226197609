import React from "react";
import { groupBy } from "lodash";
import { KpiStatusT } from "../../graphql/generated/graphql";
import { Col, Row } from "../../ui/grid/Grid";
import { HeadingSection } from "../../ui/Heading/Heading";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { KpiListItemPropsT } from "../kpiList/KpiListItem";
import { KpiSemaphore } from "../kpiSemaphore/KpiSemaphore";

export const KpiTileActiveKpis = ({ kpiSettings }: { kpiSettings: KpiListItemPropsT["kpiSetting"][] }) => {
  const activeKpis = kpiSettings.filter((kpiSetting) => kpiSetting.status === KpiStatusT.ActiveT);
  const groupedBySemaphore = groupBy(activeKpis, (setting) => setting.lastResult?.semaphore);

  return (
    <Tile isFullHeight>
      <HeadingSection margin={0}>Active KPI's</HeadingSection>

      <Row className="mt-24 mb-16" justify="center">
        <Text color="softGray" style={{ fontSize: "20px" }}>
          <span className="Text--gray Text--bold">{activeKpis.length}</span> KPIs
        </Text>
      </Row>

      <Row justify="center" padding="s" flexwrap>
        {Object.keys(groupedBySemaphore).map((key) =>
          groupedBySemaphore[key].map((kpiSetting) => (
            <Col key={kpiSetting.id}>
              <KpiSemaphore onlyIcon {...kpiSetting} result={kpiSetting.lastResult} />
            </Col>
          ))
        )}
      </Row>
    </Tile>
  );
};
