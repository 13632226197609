import React, { ReactNode, useCallback, useMemo, useState } from "react";
import cs from "classnames";
import { snakeCase } from "lodash";
import { useSearch } from "../../hooks/useSearch";
import { ButtonPrimary } from "../../ui/Button/Button";
import { Col, GridOverflow, Row } from "../../ui/grid/Grid";
import { Icon } from "../../ui/Icon/Icon";
import { Modal, ModalBody, ModalFooter } from "../../ui/Modal";
import ModalCloseButton from "../../ui/Modal/ModalCloseButton";
import { EmptyState } from "../../ui/Table/EmptyState";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { SearchInput } from "../search/Search";

type PropsT<T> = {
  collection: T[];
  confirmText: string;
  description?: ReactNode;
  disableSearch?: boolean;
  heading: string;
  isLoading?: boolean;
  itemIcon?: string;
  onClose: () => void;
  onSubmit: (item: T) => void;
};

export const SelectFromCollectionModal = <T extends { id: string; name?: string | null }>({
  collection,
  confirmText,
  description,
  disableSearch = false,
  heading,
  isLoading,
  itemIcon,
  onClose,
  onSubmit,
}: PropsT<T>) => {
  const [selected, setSelected] = useState<T | null>(null);
  const filterCollection = useCallback((item: T) => item?.name || item.id, []);
  const { filterData, searchInputProps } = useSearch<T[]>(filterCollection);
  const filtredData = useMemo(() => filterData(collection), [filterData, collection]);

  const handleSubmit = () => {
    if (!selected) {
      return;
    }
    onSubmit(selected);
  };

  const testId = `${snakeCase(heading)}-modal`;

  return (
    <Modal close={onClose} heading={heading} size="small" testId={testId} isOpen>
      <ModalBody>
        <Text className="mb-16" tag="p">
          {description}
        </Text>
        <Tile contentStyle={{ padding: "8px 0" }}>
          {!disableSearch && <SearchInput {...searchInputProps} className="pa-8 pt-0" />}

          <GridOverflow className="pa-0" style={{ position: "relative" }}>
            <Col className="Navigation" style={{ maxHeight: "192px" }} type="grow">
              {filtredData.length === 0 && (
                <EmptyState
                  description={`Out of ${collection.length} records, none of them match your search query. Try to modify what you're looking for.`}
                />
              )}

              {filtredData.map((item) => (
                <Row
                  key={item.id}
                  className={cs("Navigation-item", { active: item.id === selected?.id })}
                  onClick={() => setSelected(item)}
                >
                  {itemIcon && <Icon className="mr-8" kind={itemIcon} size="14px" />}
                  {item?.name}
                </Row>
              ))}
            </Col>
          </GridOverflow>
        </Tile>
      </ModalBody>
      <ModalFooter>
        <ButtonPrimary
          data-test-id={`${testId}--confirm`}
          disabled={!selected}
          loading={isLoading}
          onClick={handleSubmit}
        >
          {confirmText}
        </ButtonPrimary>
        <ModalCloseButton />
      </ModalFooter>
    </Modal>
  );
};
