/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from "react";
import { SearchInputPropsT } from "../components/search/Search";

export type UseSearchT<T extends any[] | []> = {
  filterData: (data: T) => T;
  searchInputProps: SearchInputPropsT;
};

export type UseSearchParamsT<T extends any[] | []> = (row: T[number]) => string;

export const useSearch = <T extends any[] | []>(filterByRowCallback: UseSearchParamsT<T>): UseSearchT<T> => {
  const [value, setValue] = useState("");

  const handleChange = useCallback((newValue: FormEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    setValue(newValue.currentTarget.value);
  }, []);

  const clearSearch = useCallback(() => {
    setValue("");
  }, []);

  const filterData = useCallback(
    (data: T): T => {
      if (value === "") {
        return data;
      }

      return data.filter((row) => filterByRowCallback(row).toLowerCase().includes(value.toLowerCase())) as T;
    },
    [filterByRowCallback, value]
  );

  const result = useMemo(
    () => ({
      searchInputProps: {
        onChange: handleChange,
        value,
        clearSearch,
      },
      filterData,
    }),
    [handleChange, value, clearSearch, filterData]
  );

  return result;
};
