import { useAliasDimensionsQuery } from "../../graphql/generated/graphql";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { AliasTable } from "./AliasTable";

export const AliasDimensionsConnected = () => {
  const {
    organization: { externalId },
  } = useAppSettings();
  const result = useAliasDimensionsQuery({ variables: { organizationExternalId: externalId } });

  return <AliasTable {...result} heading="Ad Systems Dimensions" headingLevel="h2" type="dimensions" />;
};
