import { useCallback, useEffect, useRef } from "react";

export const useSynchronizedScroll = ({ scrollRightPixels }: { scrollRightPixels?: number }) => {
  const headerRow = useRef<HTMLDivElement>(null);
  const bodyRow = useRef<HTMLDivElement>(null);
  const scrollingUser = useRef(0);
  const scrollingUserTimeout = useRef<NodeJS.Timeout | null>(null);

  const resetToToday = useCallback(() => {
    const head = headerRow?.current;
    const body = bodyRow?.current;
    if (head && body && scrollRightPixels) {
      const loadScrollPosition = scrollRightPixels - body.offsetWidth;

      if (loadScrollPosition > 0) {
        head.scrollLeft = loadScrollPosition;
        body.scrollLeft = loadScrollPosition;
      }
    }
  }, [headerRow, bodyRow, scrollRightPixels]);

  useEffect(() => {
    function updateScrollPositionBody() {
      if (
        headerRow &&
        headerRow.current &&
        bodyRow &&
        bodyRow.current &&
        (scrollingUser.current === 1 || scrollingUser.current === 0)
      ) {
        scrollingUser.current = 1;
        bodyRow.current.scrollLeft = headerRow.current.scrollLeft;
        if (scrollingUserTimeout.current) {
          clearTimeout(scrollingUserTimeout.current);
          scrollingUserTimeout.current = null;
        }
        scrollingUserTimeout.current = setTimeout(() => {
          scrollingUser.current = 0;
          scrollingUserTimeout.current = null;
        }, 100);
      }
    }
    function updateScrollPositionHeader() {
      if (
        headerRow &&
        headerRow.current &&
        bodyRow &&
        bodyRow.current &&
        (scrollingUser.current === 2 || scrollingUser.current === 0)
      ) {
        headerRow.current.scrollLeft = bodyRow.current.scrollLeft;
        scrollingUser.current = 2;
        if (scrollingUserTimeout.current) {
          clearTimeout(scrollingUserTimeout.current);
          scrollingUserTimeout.current = null;
        }
        scrollingUserTimeout.current = setTimeout(() => {
          scrollingUser.current = 0;
          scrollingUserTimeout.current = null;
        }, 100);
      }
    }

    const head = headerRow?.current;
    const body = bodyRow?.current;

    resetToToday();

    if (head) {
      head.addEventListener("scroll", updateScrollPositionBody, false);
    }
    if (body) {
      body.addEventListener("scroll", updateScrollPositionHeader, false);
    }

    return function cleanup() {
      if (head) {
        head.removeEventListener("scroll", updateScrollPositionBody, false);
      }
      if (body) {
        body.removeEventListener("scroll", updateScrollPositionHeader, false);
      }
    };
  }, [resetToToday]);

  return {
    headerRow,
    bodyRow,
    resetToToday: scrollRightPixels && scrollRightPixels > 0 ? resetToToday : undefined,
  };
};
