import React, { ForwardedRef } from "react";
import { CusotmIconBasicPropsT } from "./IconCustom";

export const WarningIcon = React.forwardRef(
  ({ className, size, ...rest }: CusotmIconBasicPropsT, ref: ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.3416 2.72919C11.6258 2.20804 12.3742 2.20804 12.6584 2.72919L22.9739 21.6409C23.2465 22.1406 22.8848 22.75 22.3155 22.75H1.68454C1.11525 22.75 0.753512 22.1406 1.02612 21.6409L11.3416 2.72919Z"
        fill="#F59E0B"
        stroke="url(#paint0_linear_5390_127888)"
        strokeWidth="0.5"
      />
      <g filter="url(#filter0_ddddd_5390_127888)">
        <path d="M13 8.47241H11V15.4724H13V8.47241Z" fill="#241C00" />
        <path
          d="M10.75 18.4724C10.75 19.1624 11.31 19.7224 12 19.7224C12.69 19.7224 13.25 19.1624 13.25 18.4724C13.25 17.7824 12.69 17.2224 12 17.2224C11.31 17.2224 10.75 17.7824 10.75 18.4724Z"
          fill="#241C00"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="15.25"
          id="filter0_ddddd_5390_127888"
          width="4.5"
          x="9.75"
          y="7.47241"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_5390_127888" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="effect1_dropShadow_5390_127888" mode="normal" result="effect2_dropShadow_5390_127888" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
          <feBlend in2="effect2_dropShadow_5390_127888" mode="normal" result="effect3_dropShadow_5390_127888" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend in2="effect3_dropShadow_5390_127888" mode="normal" result="effect4_dropShadow_5390_127888" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
          <feBlend in2="effect4_dropShadow_5390_127888" mode="normal" result="effect5_dropShadow_5390_127888" />
          <feBlend in="SourceGraphic" in2="effect5_dropShadow_5390_127888" mode="normal" result="shape" />
        </filter>
        <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_5390_127888" x1="12" x2="12" y1="1" y2="23">
          <stop stopColor="#FFDD64" />
          <stop offset="1" stopColor="#C69B03" />
        </linearGradient>
      </defs>
    </svg>
  )
);
