import React, { PropsWithChildren } from "react";
import { CustomMetricOperationT, MetricDataT, MetricT } from "../../graphql/generated/graphql";
import { Badge } from "../../ui/Badge/Badge";
import { Col, Row } from "../../ui/grid/Grid";
import { Icon } from "../../ui/Icon/Icon";
import { Text } from "../../ui/Text/Text";
import { ShowDimensionMetricPill } from "../showDimensionMetricPill/ShowDimensionMetricPill";

type PickedMetricT = Pick<MetricT, "dataType" | "id" | "name">;

type ExampleOperationRowT = PropsWithChildren<{
  isResultInPercent?: boolean;
  leftMetric?: PickedMetricT;
  newMetricName?: string;
  operation?: string;
  operationSymbol?: string;
  result?: string;
  rightMetric?: PickedMetricT;
}>;

type ExampleOperationMetricBadgeT = {
  metric?: PickedMetricT;
  number: string;
  placeholder: string;
};

const ExampleOperationMetricBadge = ({ metric, number, placeholder }: ExampleOperationMetricBadgeT) => (
  <Badge kind="lightGray" size="large">
    {metric ? (
      <Row alignItems="center" className="mw-100 line-height-0">
        <Col className="flex-shrink-1" type="equalSize">
          <Text size="sm" style={{ height: "16px" }} semibold>
            <ShowDimensionMetricPill item={metric} isBadgeHidden />
          </Text>
        </Col>
        <Col>= {number}</Col>
      </Row>
    ) : (
      <Text color="gray">
        {placeholder} = {number}
      </Text>
    )}
  </Badge>
);

const ExampleOperationRow = ({
  children,
  isResultInPercent,
  leftMetric,
  newMetricName,
  operation,
  operationSymbol,
  result,
  rightMetric,
}: ExampleOperationRowT) => {
  const leftMoney = leftMetric?.dataType === MetricDataT.MoneyT;
  const rightMoney = rightMetric?.dataType === MetricDataT.MoneyT;
  const leftNumber = leftMoney ? "100$" : "100";
  const rightNumber = rightMoney ? "50$" : "50";
  const outputCurrency = leftMoney || rightMoney ? "$" : "";

  return (
    <>
      <Row alignItems="center" className="mw-100">
        <Col className="flex-shrink-4" style={{ minWidth: "164px" }}>
          <ExampleOperationMetricBadge metric={leftMetric} number={leftNumber} placeholder="First metric" />
        </Col>
        <Col>
          <Text color="gray">{operation}</Text>
        </Col>
        <Col className="flex-shrink-4" style={{ minWidth: "164px" }}>
          <ExampleOperationMetricBadge metric={rightMetric} number={rightNumber} placeholder="Second metric" />
        </Col>
        <Col>
          <Text color="gray">=</Text>
        </Col>
        <Col className="flex-shrink-1" style={{ minWidth: "164px" }}>
          <Badge kind="lightGray" size="large">
            {newMetricName && (
              <Row alignItems="center" className="mw-100" padding="s">
                <Col>
                  <Icon kind="user" />
                </Col>
                <Col className="flex-shrink-1" type="equalSize">
                  <Text className="text-overflow" semibold>
                    {newMetricName}
                  </Text>
                </Col>
                <Col>
                  <Text className="text-overflow" color="gray" bold>
                    = {result}
                    {isResultInPercent ? "%" : outputCurrency}
                  </Text>
                </Col>
              </Row>
            )}
            {!newMetricName && (
              <Text className="text-overflow" color="gray" bold>
                {!newMetricName && "New Metric"}&nbsp;= {result}
                {isResultInPercent ? "%" : outputCurrency}
              </Text>
            )}
          </Badge>
        </Col>
        <Col>
          <Text color="gray">
            ({leftNumber} {operationSymbol} {rightNumber} = {result}
            {isResultInPercent ? "%" : outputCurrency})
          </Text>
        </Col>
      </Row>
      {children && <Row>{children}</Row>}
    </>
  );
};

export const ExampleOperation = ({
  leftMetric,
  newMetricName,
  operation,
  rightMetric,
}: {
  leftMetric?: PickedMetricT;
  newMetricName?: string;
  operation?: CustomMetricOperationT;
  rightMetric?: PickedMetricT;
}) => {
  const leftMoney = leftMetric?.dataType === MetricDataT.MoneyT;
  const rightMoney = rightMetric?.dataType === MetricDataT.MoneyT;

  const generalProps = {
    leftMetric,
    rightMetric,
    newMetricName,
  };

  if (operation === CustomMetricOperationT.DivT) {
    return <ExampleOperationRow {...generalProps} operation="Divide (/)" operationSymbol="/" result="2" />;
  }

  if (operation === CustomMetricOperationT.RatioT) {
    return (
      <ExampleOperationRow
        {...generalProps}
        operation="Ratio (/ in %)"
        operationSymbol="/"
        result="200"
        isResultInPercent
      >
        {leftMoney !== rightMoney && (
          <Text color="error">Cannot combine number and money metric when you want to get ratio</Text>
        )}
      </ExampleOperationRow>
    );
  }

  if (operation === CustomMetricOperationT.AddT) {
    return <ExampleOperationRow {...generalProps} operation="Add (+)" operationSymbol="+" result="150" />;
  }

  if (operation === CustomMetricOperationT.MulT) {
    return <ExampleOperationRow {...generalProps} operation="Multiply (*)" operationSymbol="*" result="5000" />;
  }

  if (operation === CustomMetricOperationT.SubT) {
    return <ExampleOperationRow {...generalProps} operation="Subtract (-)" operationSymbol="-" result="50" />;
  }

  return null;
};
