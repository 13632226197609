import React from "react";
import { ButtonPrimary, ButtonPrimaryRed, ButtonTertiary } from "../Button/Button";
import Modal, { ModalPropsT } from "./Modal";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";

type PropsT = {
  confirmButtonText?: string;
  description: string;
  heading: string;
  onConfirm: () => void;
  type?: "confirm" | "delete";
} & Omit<ModalPropsT, "testId"> &
  Partial<Pick<ModalPropsT, "testId">>;

const componentsMap = {
  confirm: ButtonPrimary,
  delete: ButtonPrimaryRed,
};

export const ConfirmationModal = ({
  confirmButtonText,
  description,
  onConfirm,
  testId,
  type = "delete",
  ...rest
}: PropsT) => {
  const Button = componentsMap[type];

  const handleConfirm = () => {
    onConfirm();
    rest.close();
  };

  return (
    <Modal {...rest} portalId="dee-popup-js" size="small" testId={testId ? testId : "confirmation-modal"} zIndex="high">
      <ModalBody>{description}</ModalBody>
      <ModalFooter>
        <Button onClick={handleConfirm}>{confirmButtonText || (type === "confirm" ? "Confirm" : "Delete")}</Button>
        <ButtonTertiary className="ml-16" autoFocus onClick={rest.close}>
          Cancel
        </ButtonTertiary>
      </ModalFooter>
    </Modal>
  );
};
