import React, { Fragment, useCallback, useMemo, useState } from "react";
import { groupBy } from "lodash";
import { SourceSystemT, SystemNameT } from "../../../graphql/generated/graphql";
import { useSearch } from "../../../hooks/useSearch";
import { WidgetT } from "../../../types/widgets";
import { Badge } from "../../../ui/Badge/Badge";
import { Button, ButtonSecondary, ButtonTertiary } from "../../../ui/Button/Button";
import { DropdownFloatingBox } from "../../../ui/Dropdown/DropdownFloatingBox";
import { useDropdown } from "../../../ui/Dropdown/useDropdown";
import Checkbox from "../../../ui/forms/Checkbox";
import { Col, Row } from "../../../ui/grid/Grid";
import { HeadingSmall } from "../../../ui/Heading/Heading";
import { Icon } from "../../../ui/Icon/Icon";
import { InfoBox } from "../../../ui/InfoBox/InfoBox";
import { SystemIcon } from "../../../ui/SystemIcon/SystemIcon";
import { Text } from "../../../ui/Text/Text";
import { Tile } from "../../../ui/Tile/Tile";
import { Tooltip } from "../../../ui/Tooltip/Tooltip";
import {
  AccountSelectConnectedSystemsPreviewInTable,
  AccountSelectConnectedSystemsPreviewItem,
} from "../../accountSelect/AccountSelectConnectedSystemsPreview";
import { SYSTEM_NAMES } from "../../accountSelect/constants";
import { SearchInput } from "../../search/Search";
import { OnFormEditWidgetChangeT } from "../FormEditWidget";

type FormPropsT = Pick<PropsT, "value" | "connectedSystems" | "formKey"> & {
  onClose: () => void;
  onSubmit: (selectedSystems: string[]) => void;
};

const Form = ({ connectedSystems, formKey, onClose, onSubmit, value }: FormPropsT) => {
  const path = "sourceSystems";
  const [selectedSystems, setSelectedSystems] = useState(
    (!value.length ? connectedSystems : value).map((system) => system.id)
  );

  const filterConnectedSystemsBy = useCallback(
    (system: PropsT["connectedSystems"][0]) => `${system.externalName}${system.email}${system.externalId}`,
    []
  );
  const { filterData: filterConnectedSystems, searchInputProps } = useSearch(filterConnectedSystemsBy);

  const isPartialSelected = selectedSystems.length > 0 && selectedSystems.length < connectedSystems.length;

  const handleChange = (systemId: string) => {
    const index = selectedSystems.findIndex((selectedSystemId) => selectedSystemId === systemId);

    if (index > -1) {
      setSelectedSystems((prev) => prev.filter((selectedSystemId) => selectedSystemId !== systemId));
    } else {
      setSelectedSystems((prev) => [...prev, systemId]);
    }
  };

  const handleSubmit = () => {
    onSubmit(connectedSystems.length === selectedSystems.length ? [] : selectedSystems);
    onClose();
  };

  const handleToggleAllChange = () => {
    if (!!selectedSystems.length) {
      return setSelectedSystems([]);
    }
    setSelectedSystems(connectedSystems.map((system) => system.id));
  };

  const connectedSystemsGrouped = useMemo(
    () =>
      groupBy(filterConnectedSystems(connectedSystems), (system) => system.name) as {
        [K in SystemNameT]: PropsT["connectedSystems"];
      },
    [filterConnectedSystems, connectedSystems]
  );

  return (
    <div>
      <SearchInput className="mb-16" hasMagnifierIcon {...searchInputProps} />

      {searchInputProps.value && !Object.keys(connectedSystemsGrouped).length && <div>No search results</div>}

      <div className="area--scrollable" style={{ maxHeight: "530px" }}>
        {!searchInputProps.value && (
          <Checkbox
            input={{ id: `${formKey}-${path}-all`, checked: !!selectedSystems.length, onChange: handleToggleAllChange }}
            isLineCheck={isPartialSelected}
            label="All Accounts"
          />
        )}

        {(Object.keys(connectedSystemsGrouped) as Array<keyof typeof connectedSystemsGrouped>).map((key) => (
          <div key={key}>
            <Text className="mt-16" tag="p" bold>
              <SystemIcon className="mr-8" size="1.2em" system={key} />
              <span className="vam d-inline-block" style={{ paddingBottom: "7px" }}>
                {SYSTEM_NAMES[key]}
              </span>
            </Text>
            {connectedSystemsGrouped[key].map((system) => (
              <Fragment key={system.id}>
                <Checkbox
                  className="ma-0"
                  input={{
                    id: `${formKey}-${path}-${system.id}`,
                    onChange: () => handleChange(system.id),
                    checked: selectedSystems.some((selectedSystemId) => selectedSystemId === system.id),
                  }}
                  label={
                    <AccountSelectConnectedSystemsPreviewItem
                      {...system}
                      email={system.email || "-"}
                      name={system.externalName || "-"}
                      secondColStyle={{ textAlign: "right" }}
                      system={system.name}
                    />
                  }
                  isFullWidth
                />
                <div className="delimiter mv-4" />
              </Fragment>
            ))}
          </div>
        ))}
      </div>

      <div className="mt-16 background-white">
        {!selectedSystems.length && (
          <InfoBox variant="warning">You have to choose at least 1 account to show data on the widget.</InfoBox>
        )}

        <Row className="pt-8">
          <ButtonSecondary disabled={!selectedSystems.length} onClick={handleSubmit}>
            Save
          </ButtonSecondary>
          <ButtonTertiary onClick={onClose}>Cancel</ButtonTertiary>
        </Row>
      </div>
    </div>
  );
};

type PropsT = {
  connectedSystems: Pick<SourceSystemT, "externalId" | "name" | "id" | "externalName" | "email">[];
  formKey: string;
  onChange: OnFormEditWidgetChangeT;
  value: WidgetT["sourceSystems"];
};

export const InputSourceSystems = ({ connectedSystems, formKey, onChange, value }: PropsT) => {
  const { boxProps, reference, triggerElementProps } = useDropdown({
    placement: "left-start",
    offset: 25,
  });

  const handleChange = (selectedSystemIds: string[]) => {
    onChange((prev) => ({
      ...prev,
      sourceSystems: connectedSystems.filter((system) => selectedSystemIds.includes(system.id)),
    }));
  };

  return (
    <Row ref={reference} alignItems="center">
      <Col type="grow">
        <HeadingSmall margin={0}>
          Filter linked accounts
          <Tooltip
            tooltipContent={
              <div style={{ maxWidth: "280px" }}>
                Filters allow you to select specific accounts as a data source for the widget. Thanks to that, you will
                see only the selected data in the widget.
              </div>
            }
          >
            <Icon className="ml-8 Icon--gray" kind="info" />
          </Tooltip>
        </HeadingSmall>
      </Col>

      <Col>
        {!!value.length && (
          <Tooltip
            placement="bottom"
            roleType="dialog"
            tooltipContent={
              <AccountSelectConnectedSystemsPreviewInTable
                systems={value.map((system) => ({
                  ...system,
                  system: system.name,
                  name: system.externalName || "-",
                  email: system.email || "",
                }))}
              />
            }
          >
            <Badge kind="orange">
              <Icon className="mr-4" kind="filter" /> {value.length} of {connectedSystems.length}
            </Badge>
          </Tooltip>
        )}
      </Col>

      <Col style={{ position: "relative" }}>
        <Button {...triggerElementProps} icon="admin" variant={boxProps.isOpen ? "secondary" : "tertiary"} onlyIcon />
      </Col>

      <DropdownFloatingBox {...{ ...boxProps }}>
        <Tile className="elevate-3" style={{ width: "660px" }}>
          <HeadingSmall>Filter data sources used in widget</HeadingSmall>
          <Form
            connectedSystems={connectedSystems}
            formKey={formKey}
            value={value}
            onClose={() => boxProps.onToggle(false)}
            onSubmit={handleChange}
          />
        </Tile>
      </DropdownFloatingBox>
    </Row>
  );
};
