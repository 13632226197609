import React from "react";
import { ShowDimensionMetricPill } from "../../components/showDimensionMetricPill/ShowDimensionMetricPill";
import { ValueCategoryT } from "../../graphql/generated/graphql";
import { Icon } from "../Icon/Icon";
import { Tooltip } from "../Tooltip/Tooltip";
import { SelectableDimensionT } from "./MetricDimensionSelectTabs";

type MetricDimensionPillPropsT = {
  isDisabled: boolean;
  isDisabledNotConnectedWarning?: boolean;
  item: SelectableDimensionT;
  onClick: () => void;
};

export const MetricDimensionPill = ({
  isDisabled,
  isDisabledNotConnectedWarning,
  item,
  onClick,
}: MetricDimensionPillPropsT) => {
  const isWarningIconVisible =
    item.category !== ValueCategoryT.CustomValueT && !item.isConnected && !isDisabledNotConnectedWarning;
  return (
    <div className="d-flex align-items-center mv-8 mr-8 mw-100">
      <ShowDimensionMetricPill
        isDisabled={isDisabled}
        item={item}
        style={{ maxWidth: isWarningIconVisible ? "calc(100% - 20px)" : "100%" }}
        onClick={onClick}
      />
      {isWarningIconVisible && (
        <Tooltip tooltipContent={<span>Will need to connect account.</span>}>
          <Icon className="Icon--orange mh-4" kind="warning" size="16px" />
        </Tooltip>
      )}
    </div>
  );
};
