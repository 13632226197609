import React, { useCallback } from "react";
import { SubAccountT, SystemNameT } from "../../graphql/generated/graphql";
import { ButtonPrimary, ButtonSecondary } from "../../ui/Button/Button";
import ModalBody from "../../ui/Modal/ModalBody";
import ModalFooter from "../../ui/Modal/ModalFooter";
import { FormChooseAccount } from "../formChooseAccount/FormChooseAccount";
import { AccountSelectModalPropsT } from "./AccountSelectModal";
import { ButtonConnectNewSystemWithPopover } from "./ButtonConnectNewSystemWithPopover";
import { useGetAccountLink } from "./useGetAccountLink";

export const AccountsModalContent = ({
  accounts,
  editedSystem,
  getAccountLink,
  onClose,
  onSubmit,
  refetchSubAccounts,
  selectedAccounts,
}: Pick<
  AccountSelectModalPropsT,
  "getAccountLink" | "onSubmit" | "selectedAccounts" | "refetchSubAccounts" | "onClose"
> & {
  accounts: SubAccountT[];
  editedSystem: SystemNameT;
}) => {
  const onSuccess = useCallback(() => {
    refetchSubAccounts();
  }, [refetchSubAccounts]);

  const accountAccessLink = useGetAccountLink({ getAccountLink, systemName: editedSystem });

  return (
    <>
      <ModalBody>
        <FormChooseAccount
          accounts={accounts}
          formId="selected_system_modal"
          selectedAccounts={selectedAccounts}
          systemName={editedSystem}
          onSubmit={onSubmit}
        />
      </ModalBody>
      <ModalFooter>
        <div className="d-flex justify-content-between">
          <ButtonSecondary onClick={onClose}>Close</ButtonSecondary>
          <div>
            <ButtonConnectNewSystemWithPopover
              accountAccessLink={accountAccessLink}
              buttonText="Connect new"
              buttonVariant="secondary"
              disabled={false}
              onSuccess={onSuccess}
            />
            <ButtonPrimary form="selected_system_modal" type="submit">
              Save changes
            </ButtonPrimary>
          </div>
        </div>
      </ModalFooter>
    </>
  );
};
