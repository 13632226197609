import React, { useEffect, useState } from "react";
import { formatDistance } from "date-fns";
import { formatTime } from "../../i18n/formatDate";
import { Tooltip } from "../Tooltip/Tooltip";

type PropsT = {
  hideTooltip?: boolean;
  time?: string | number;
};

export const TimeAgo = ({ hideTooltip, time }: PropsT) => {
  const [now, setNow] = useState<Date>(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (!time) {
    return null;
  }

  if (hideTooltip) {
    return <span>{formatDistance(now, new Date(time))}</span>;
  }

  return (
    <Tooltip className="hide-in-percy" tooltipContent={formatTime(new Date(time))}>
      <span>{formatDistance(now, new Date(time))}</span>
    </Tooltip>
  );
};

export default TimeAgo;
