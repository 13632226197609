import React from "react";
import { SystemIconBasicPropsT } from "./types";

export const FacebookIcon = ({ active, size = "16px" }: SystemIconBasicPropsT) => (
  <svg
    className={`system-icon facebook-icon ${active && "active"}`}
    height={size}
    version="1.1"
    viewBox="0 0 24 24"
    width={size}
    x="0"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    y="0"
  >
    <path
      clipRule="evenodd"
      d="M24 12.1C24 5.4 18.6 0 12 0S0 5.4 0 12.1c0 6 4.4 11 10.1 11.9v-8.4h-3v-3.5h3V9.4c0-3 1.8-4.7 4.5-4.7 1.4 0 2.7.3 2.7.3v3h-1.5c-1.5 0-2 .9-2 1.9v2.3h3.3l-.5 3.5h-2.8V24c5.8-.9 10.2-5.9 10.2-11.9"
      fill="#1877F2"
      fillRule="evenodd"
    />
  </svg>
);
