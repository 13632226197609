import { useEffect, useMemo } from "react";
import { isEqual } from "lodash";
import { normalizeDateRange } from "../../functions/normalize";
import {
  DataConsistencyT,
  DateRangeInputT,
  DateRangeT,
  Maybe,
  ReportSourceSystemsQueryT,
  useReportSourceSystemsQuery,
} from "../../graphql/generated/graphql";
import { useReportBoardContext } from "./context/reportBoardContext";
import { ReportBoardPageT } from "./context/types";

const cleanDateRanges = <T extends { dateRange?: Maybe<DateRangeT> }>(item: T) =>
  item?.dateRange?.range ? normalizeDateRange(item.dateRange) : null;

type GetDateRangeLevelT = (pagesData: ReportBoardPageT[], reportDateRange?: Maybe<DateRangeT>) => DateRangeInputT[];

const getDateRangeLevel: GetDateRangeLevelT = (pagesData: ReportBoardPageT[], reportDateRange?: Maybe<DateRangeT>) => {
  const widgetDateRanges = pagesData.flatMap((page) =>
    page.sections.flatMap((section) =>
      section.widgets.nodes.filter((widget) => widget.dateRange?.range).flatMap(cleanDateRanges)
    )
  );
  if (widgetDateRanges.length > 0) {
    return widgetDateRanges as DateRangeInputT[];
  }

  const sectionDateRanges = pagesData.flatMap((page) =>
    page.sections.filter((widget) => widget.dateRange?.range).map(cleanDateRanges)
  );
  if (sectionDateRanges.length > 0) {
    return sectionDateRanges as DateRangeInputT[];
  }

  return reportDateRange ? [normalizeDateRange(reportDateRange)] : [];
};

const getFetchingNewConnectedSystemsData = (data: ReportSourceSystemsQueryT | undefined) =>
  !!data?.organization?.report?.sourceSystems &&
  data?.organization?.report?.sourceSystems?.length > 0 &&
  data?.organization?.report?.sourceSystems?.some(
    (system) =>
      system.dataConsistency === DataConsistencyT.InProgressT || system.dataConsistency === DataConsistencyT.IncompleteT
  );

export const useRefetchWidgetData = ({
  canEdit,
  organizationExternalId,
}: {
  canEdit?: boolean;
  organizationExternalId: string;
}) => {
  const {
    fetchingNewConnectedSystemsData,
    pagesData,
    report,
    reportFilter,
    setFetchingNewConnectedSystemsData,
    setLastFetchedConnectedSystemsData,
  } = useReportBoardContext();

  const dateRanges = useMemo(
    () => getDateRangeLevel(pagesData, reportFilter.dateRange),
    [pagesData, reportFilter.dateRange]
  );

  const { data, previousData, refetch } = useReportSourceSystemsQuery({
    fetchPolicy: "network-only",
    variables: {
      organizationExternalId,
      reportId: report.id,
      dateRanges,
    },
    skip: report.sourceSystems.length === 0 || !canEdit,
  });

  useEffect(() => {
    setFetchingNewConnectedSystemsData(getFetchingNewConnectedSystemsData(data));
  }, [data, setFetchingNewConnectedSystemsData]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (fetchingNewConnectedSystemsData) {
        refetch();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [fetchingNewConnectedSystemsData, refetch]);

  useEffect(() => {
    const previousLastImport = previousData?.organization?.report?.sourceSystems?.map((ss) => ss.dataConsistency) || [];
    const currentLastImport = data?.organization?.report?.sourceSystems?.map((ss) => ss.dataConsistency) || [];

    if (currentLastImport.length > 0 && !isEqual(previousLastImport, currentLastImport)) {
      setLastFetchedConnectedSystemsData(new Date());
      setFetchingNewConnectedSystemsData(getFetchingNewConnectedSystemsData(data));
    }
  }, [
    previousData,
    data,
    report.sourceSystems,
    setLastFetchedConnectedSystemsData,
    setFetchingNewConnectedSystemsData,
  ]);
};
