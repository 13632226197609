import React, { ForwardedRef } from "react";
import { CusotmIconBasicPropsT } from "./IconCustom";

export const HourglassHexSmIcon = React.forwardRef(
  ({ className, size, ...rest }: CusotmIconBasicPropsT, ref: ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M8 0L14.9282 4V12L8 16L1.0718 12V4L8 0Z" fill="#D9D9D9" />
      <g clipPath="url(#clip0_5559_109854)">
        <path
          d="M10.4424 6.16963C10.8016 5.741 11 5.20325 11 4.65612V3.5H5V4.65612C5 5.20325 5.198 5.74062 5.55762 6.16925L6.71487 7.54925C6.9275 7.80312 6.929 8.15863 6.71825 8.414L5.5445 9.83675C5.1935 10.2628 5 10.7949 5 11.3349V12.5H11V11.3349C11 10.7949 10.8061 10.2631 10.4551 9.83712L9.28137 8.41437C9.07062 8.159 9.07212 7.8035 9.28475 7.54962L10.4424 6.16963Z"
          fill="black"
        />
        <path
          d="M9.8765 10.3136L8.70275 8.89089C8.2655 8.36139 8.2685 7.59414 8.70987 7.06726L9.8675 5.68726C10.1139 5.39326 10.25 5.03739 10.25 4.62451H5.75C5.75 5.03626 5.885 5.39289 6.1325 5.68726L7.28975 7.06764C7.7315 7.59451 7.7345 8.36139 7.29688 8.89164L6.1235 10.314C5.87862 10.6103 5.75 10.9609 5.75 11.3749H10.25C10.25 10.9609 10.121 10.6106 9.8765 10.3136Z"
          fill="#D9D9D9"
        />
      </g>
      <defs>
        <clipPath id="clip0_5559_109854">
          <rect fill="white" height="9" transform="translate(3.5 3.5)" width="9" />
        </clipPath>
      </defs>
    </svg>
  )
);
