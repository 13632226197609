import React, { ChangeEvent } from "react";
import { ORDERED_KPI_PRIORITIES } from "../../constants/kpi";
import Checkbox from "../../ui/forms/Checkbox";
import Input from "../../ui/forms/Input";
import Select from "../../ui/forms/Select";
import { Col, Row } from "../../ui/grid/Grid";
import { KPI_SEMAPHORE_VARIANTS, ORDERED_KPI_SEMAPHORE_VARIANTS } from "../kpiSemaphore/KpiSemaphore";
import { SearchInputPropsT } from "../search/Search";

const PRIORITY_OPTIONS = [
  {
    label: "Priority",
    value: "",
  },
  ...ORDERED_KPI_PRIORITIES.map((priority) => ({ label: priority, value: priority })),
];

const STATUS_OPTIONS = [
  {
    label: "Status",
    value: "",
  },
  ...ORDERED_KPI_SEMAPHORE_VARIANTS.map((status) => ({ label: KPI_SEMAPHORE_VARIANTS[status].label, value: status })),
];

type PropsT = {
  activeInputProps: {
    checked: boolean;
    onChange: () => void;
  };
  isDisabled?: boolean;
  priorityInputProps: {
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
    value: string;
  };
  searchInputProps: SearchInputPropsT;

  statusInputProps: {
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
    value: string;
  };
};

export const KpiFilter = ({
  activeInputProps,
  isDisabled,
  priorityInputProps,
  searchInputProps,

  statusInputProps,
}: PropsT) => {
  return (
    <Row>
      <Col>
        <Input
          inputProps={{
            value: searchInputProps.value,
            onChange: searchInputProps.onChange,
            disabled: isDisabled,
            placeholder: "Search",
            isClearable: true,
          }}
          onClear={searchInputProps.clearSearch}
        />
      </Col>

      <Col>
        <Select
          collection={STATUS_OPTIONS}
          selectProps={{ id: "kpi-filter-status", ...statusInputProps, disabled: isDisabled }}
        />
      </Col>
      <Col>
        <Select
          collection={PRIORITY_OPTIONS}
          selectProps={{ id: "kpi-filter-priority", ...priorityInputProps, disabled: isDisabled }}
        />
      </Col>
      <Col>
        <Checkbox
          input={{ id: "kpi-filter-hasActive", ...activeInputProps, disabled: isDisabled }}
          label="Active only"
        />
      </Col>
    </Row>
  );
};
