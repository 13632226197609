import { useLocalStorage } from "./useLocalStorage";

export const useRecentMetDims = () => {
  const [recentMetrics, setRecentMetrics] = useLocalStorage("recentMetrics", []);
  const [recentDimensions, setRecentDimensions] = useLocalStorage("recentDimensions", []);

  const getNewRecentArray = (newItem: string, array: string[]) => {
    const newItemIndex = array.indexOf(newItem);
    const myArray = [...array];

    if (newItemIndex !== -1) {
      myArray.sort((a, b) => (a === newItem ? -1 : b === newItem ? 1 : 0));
    }

    if (newItemIndex === -1) {
      myArray.unshift(newItem);
    }
    return myArray.slice(0, 5);
  };

  const addUsedMetric = (id?: string | null) => {
    if (id) {
      const test = getNewRecentArray(id, recentMetrics);
      setRecentMetrics(test);
    }
  };

  const addUsedDimension = (id?: string | null) => {
    if (id) {
      setRecentDimensions(getNewRecentArray(id, recentDimensions));
    }
  };

  return {
    addUsedMetric,
    addUsedDimension,
    recentMetrics,
    recentDimensions,
  };
};
