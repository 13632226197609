import React, { Fragment, KeyboardEvent, PropsWithChildren, TransitionEvent, useMemo } from "react";
import ReactDOM from "react-dom";
import ModalComponent, { ModalComponentPropsT } from "./ModalComponent";
import { ModalContext } from "./modalContext";
import { ModalBasicPropsT } from "./types";

export type ModalPropsT = ModalBasicPropsT &
  Omit<ModalComponentPropsT, "onKeyDown" | "onTransitionEnd"> & {
    close: () => void;
    portalId?: string;
  };

const Modal = ({
  children,
  className,
  close,
  forceClose,
  hasHeaderBorder,
  heading,
  icon,
  isClosing,
  isOpen,
  portalId = "dee-modal-js",
  size = "medium",
  systemIcon,
  testId,
  zIndex,
}: PropsWithChildren<ModalPropsT>) => {
  const handleCloseOnTransitionEnd = (event: TransitionEvent) => {
    return isClosing && event.propertyName === "opacity" && forceClose && forceClose();
  };
  const handleKeyDown = (event: KeyboardEvent) => event.key === "Escape" && isOpen && !size.includes("full") && close();

  const portalDiv = useMemo(() => document.getElementById(portalId), [portalId]);
  const value = { isOpen, isClosing, close, testId };

  if (!portalDiv) {
    // eslint-disable-next-line no-console
    console.warn("No element with id='dee-modal-js' was found");
    return null;
  }

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <Fragment>
      <ModalContext.Provider value={value}>
        <ModalComponent
          className={className}
          hasHeaderBorder={hasHeaderBorder}
          heading={heading}
          icon={icon}
          size={size}
          systemIcon={systemIcon}
          testId={testId}
          zIndex={zIndex}
          onKeyDown={handleKeyDown}
          onTransitionEnd={handleCloseOnTransitionEnd}
        >
          {children}
        </ModalComponent>
      </ModalContext.Provider>
    </Fragment>,
    portalDiv as HTMLElement
  );
};

export default Modal;
