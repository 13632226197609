import React, { PropsWithChildren } from "react";
import cs from "classnames";
import { Badge } from "../Badge/Badge";
import { Icon } from "../Icon/Icon";
import { Tooltip } from "../Tooltip/Tooltip";
import { useSegmentedButtonContext } from "./segmentedButtonContext";

type PropsT = {
  badge?: string;
  className?: string;
  icon?: string;
  isIconOnly?: boolean;
  isRequired?: boolean;
  text?: string;
  tooltipContent?: string;
  value: string;
};

export const SegmentedItem = ({
  badge,
  children,
  className,
  icon,
  isRequired,
  text,
  tooltipContent,
  value,
}: PropsWithChildren<PropsT>) => {
  const { id, name, onChange, selected, testId } = useSegmentedButtonContext();

  const optionId = `${id}_${value}`;

  return (
    <Tooltip tooltipContent={tooltipContent}>
      <span className="radio" data-test-id={`${testId}-${value}-wrapper`}>
        <input
          checked={selected === value}
          className={cs("Segmented-input", "radio_buttons", { required: isRequired })}
          data-test-id={`${testId}-${value}-item`}
          id={optionId}
          name={name}
          type="radio"
          value={value}
          onChange={onChange}
        />
        <label
          aria-labelledby={optionId}
          data-test-id={`${testId}-${value}-label`}
          htmlFor={optionId}
          className={cs(className, "collection_radio_buttons nowrap", {
            "Segmented-label--onlyIcons": !children && !text,
          })}
        >
          {icon && (
            <Icon className={cs("Segmented-labelIcon", { "mr-4": children || text })} kind={icon} isInheritColor />
          )}
          {text || children}
          {badge && (
            <Badge className="CustomSwiper-badge ml-4" kind="blue" size="medium" testId={`${testId}-${value}-badge`}>
              {badge}
            </Badge>
          )}
        </label>
      </span>
    </Tooltip>
  );
};
