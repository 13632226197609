import React, { FormEvent } from "react";
import { WidgetCompareT } from "../../../graphql/generated/graphql";
import { WidgetT } from "../../../types/widgets";
import Select from "../../../ui/forms/Select";
import { OnFormEditWidgetChangeT } from "../FormEditWidget";

type PropsT = {
  formKey: string;
  onChange: OnFormEditWidgetChangeT;
  value: WidgetT["compare"];
};

export const COMPARE_OPTIONS = [
  {
    label: "-",
    value: WidgetCompareT.NoneT,
  },
  {
    label: "Previous period",
    value: WidgetCompareT.PeriodT,
  },
  {
    label: "Previous month",
    value: WidgetCompareT.MonthT,
  },
  {
    label: "Previous year",
    value: WidgetCompareT.YearT,
  },
];

export const COMPARE_TITLES = COMPARE_OPTIONS.reduce(
  (acc, option) => ({
    ...acc,
    [option.value]: option.label,
  }),
  {} as { [K in WidgetCompareT]: string }
);

export const InputCompare = ({ formKey, onChange, value }: PropsT) => {
  const path = ["compare"];
  const pathString = path.join(".");

  const handleChange = (event: FormEvent<HTMLSelectElement>) => {
    const newValue = event.currentTarget.value;
    onChange((prev) => ({ ...prev, compare: newValue as WidgetCompareT }));
  };

  return (
    <Select
      className="mb-16"
      collection={COMPARE_OPTIONS}
      label="Compare with"
      selectProps={{ id: `${formKey}.${pathString}`, onChange: handleChange, value: value || "", name: pathString }}
    />
  );
};
