import React, { useCallback, useMemo } from "react";
import { AccountSelect } from "../../components/accountSelect/AccountSelect";
import { FormChooseAccountPropsT } from "../../components/formChooseAccount/FormChooseAccount";
import { WidgetSettingsDataT } from "../../components/reportBoard/context/types";
import { getRequiredSystems } from "../../functions/sourceSystemsHelper";
import {
  SystemNameT,
  useAccountSelectReportQuery,
  useNewAccountAccessLinkLazyQueryT,
} from "../../graphql/generated/graphql";
import { useUpdateReport } from "../../hooks/useUpdateReport";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { SourceSystemsT } from "../../types/report";
import ErrorBox from "../../ui/ErrorBox/ErrorBox";

export const getFiltredSourceSystems = ({
  sourceSystems,
  systemName,
}: {
  sourceSystems?: SourceSystemsT;
  systemName: SystemNameT;
}) =>
  sourceSystems
    ?.filter((system) => system?.name !== systemName)
    .map((system) => ({
      name: system?.name,
      externalId: system?.externalId,
      id: system?.id,
      externalName: system?.externalName,
      email: system?.email,
    }));

type PropsT = {
  children?: JSX.Element;
  isFakeData?: boolean;
  reportId: string;
  selectedMetDims: {
    dimensions: string[];
    metrics: string[];
  };
  widgetSettingsData: {
    dimensions: WidgetSettingsDataT["dimensions"];
    metrics: WidgetSettingsDataT["metrics"];
  };
};

export const AccountSelectReportConnected = ({ children, reportId, selectedMetDims, widgetSettingsData }: PropsT) => {
  const { organization } = useAppSettings();

  const { data, refetch } = useAccountSelectReportQuery({
    variables: { organizationExternalId: organization.externalId, reportId: reportId },
  });

  const refetchSubAccounts = useCallback(() => {
    refetch();
  }, [refetch]);

  const [getAccountLink] = useNewAccountAccessLinkLazyQueryT();

  const { errors, loading, updateReport } = useUpdateReport({
    successMessage: "Accounts changed!",
    sucessCallback: () => refetchSubAccounts(),
    refetchQueries: "active",
    awaitRefetchQueries: true,
  });

  const subAccounts = data?.organization?.subAccounts;
  const sourceSystems = data?.organization?.report?.sourceSystems;

  const dimensions = useMemo(
    () => widgetSettingsData.dimensions?.filter(({ id }) => selectedMetDims.dimensions?.includes(id)),
    [selectedMetDims.dimensions, widgetSettingsData.dimensions]
  );

  const metrics = useMemo(
    () => widgetSettingsData.metrics.filter(({ id }) => selectedMetDims.metrics.includes(id)),
    [selectedMetDims.metrics, widgetSettingsData.metrics]
  );

  const requiredSystems = getRequiredSystems({
    connectedSystems: sourceSystems || [],
    dimensions: dimensions || [],
    metrics,
  });

  const handleSubmit: FormChooseAccountPropsT["onSubmit"] = ({ systemIds, systemName }) => {
    const savedSystems = getFiltredSourceSystems({ sourceSystems, systemName });
    updateReport({
      sourceSystems: [...(savedSystems || []), ...systemIds].map((sourceSystem) => ({
        externalId: sourceSystem.externalId,
        name: sourceSystem.name,
        externalName: sourceSystem.externalName,
        email: sourceSystem.email,
      })),
    });
  };

  const handleClearSubaccounts = (systemName: SystemNameT) => {
    const clearedSystems = getFiltredSourceSystems({ sourceSystems, systemName });
    updateReport({
      sourceSystems: clearedSystems?.map((system) => ({
        externalId: system.externalId,
        name: system.name,
        externalName: system.externalName,
        email: system.email,
      })),
    });
  };

  if (!!errors?.length) {
    return <ErrorBox>{errors.join("\n")}</ErrorBox>;
  }

  return (
    <AccountSelect
      children={children}
      getAccountLink={getAccountLink}
      refetchSubAccounts={refetchSubAccounts}
      requiredSystems={requiredSystems}
      sourceSystems={sourceSystems || []}
      subAccounts={subAccounts}
      submitLoading={loading}
      onClearSubaccounts={handleClearSubaccounts}
      onSubmit={handleSubmit}
    />
  );
};
