import React from "react";
import { notifySucces } from "../../functions/toast";
import { ButtonSecondary } from "../../ui/Button/Button";
import { Ellipsis } from "../../ui/Ellipsis/Ellipsis";
import { Col, Row } from "../../ui/grid/Grid";
import { HeadingSection } from "../../ui/Heading/Heading";
import { Icon } from "../../ui/Icon/Icon";
import { Link } from "../../ui/Link/Link";
import { Modal, ModalBody } from "../../ui/Modal";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { ReportBoardSharedReportT } from "../reportBoard/context/types";

type PropsT = {
  link: string;
  onClose: () => void;
  sharedReport: ReportBoardSharedReportT;
};

export const RegenerateLinkConfirmationModal = ({ link, onClose, sharedReport }: PropsT) => {
  return (
    <Modal
      close={onClose}
      heading={"Link Has Been Regenerated"}
      portalId="dee-popup-js"
      size="small"
      testId={"regenerate-link-confirmation-modal"}
      zIndex="high"
      isOpen
    >
      <ModalBody>
        <p>Your link has been regenerated and below you can find a new URL that you can copy and share.</p>

        <Tile className="overflow-hidden" isSmallSpaced>
          <Row>
            <Col justify="center">
              <div className="pos-relative">
                <Icon color="#7140df" kind="anchor" size="2em" />
                {sharedReport.hasPassword && (
                  <Icon
                    className="pos-absolute background-white"
                    kind="locked"
                    size="0.9em"
                    style={{
                      padding: "0px 1px",
                      borderRadius: "50%",
                      bottom: "0",
                      right: "0",
                      transform: "translate(3px, 3px)",
                    }}
                  />
                )}
              </div>
            </Col>
            <Col>
              <HeadingSection margin={0} style={{ maxWidth: "240px" }}>
                <Ellipsis>{sharedReport.name}</Ellipsis>
              </HeadingSection>
              <Link className="ellipsis" href={link} style={{ maxWidth: "240px" }} target="_blank">
                <Text color="gray">{link}</Text>
              </Link>
            </Col>
            <Col alignItems="center" direction="row" justify="end" type="grow">
              <ButtonSecondary
                className="mr-8"
                icon="duplicate"
                onClick={() => {
                  navigator.clipboard.writeText(link);
                  notifySucces("Link has been copied to clipboard");
                }}
              >
                Copy
              </ButtonSecondary>
            </Col>
          </Row>
        </Tile>
      </ModalBody>
    </Modal>
  );
};
