import React, { ForwardedRef } from "react";
import { CusotmIconBasicPropsT } from "./IconCustom";

export const ErrorHexSmIcon = React.forwardRef(
  ({ className, size, ...rest }: CusotmIconBasicPropsT, ref: ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M8 0L14.9282 4V12L8 16L1.0718 12V4L8 0Z" fill="#D9D9D9" />
      <path
        d="M7.31147 9.1502C7.31147 9.53115 7.62065 9.84033 8.0016 9.84033C8.38254 9.84033 8.69172 9.53115 8.69172 9.1502V4.08929C8.69172 3.70835 8.38254 3.39917 8.0016 3.39917C7.62065 3.39917 7.31147 3.70835 7.31147 4.08929V9.1502Z"
        fill="#EF292A"
      />
      <path
        d="M7.07959 11.6807C7.07959 12.1886 7.49182 12.6008 7.99975 12.6008C8.50769 12.6008 8.91992 12.1886 8.91992 11.6807C8.91992 11.1727 8.50769 10.7605 7.99975 10.7605C7.49182 10.7605 7.07959 11.1727 7.07959 11.6807Z"
        fill="#EF292A"
      />
    </svg>
  )
);
