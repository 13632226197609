import React, { DetailedHTMLProps, ReactElement, ReactNode, SelectHTMLAttributes } from "react";
import classNames from "classnames";
import { FieldError } from "react-hook-form";
import { FormSelectOptionsT } from "../../types/common";
import { Loader } from "../Loader/Loader";
import FormGroup from "./FormGroup";

type PropsT = {
  className?: string;
  error?: FieldError;
  isLoading?: boolean;
  label?: string | ReactElement;
  selectProps: DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;
  testId?: string;
} & (
  | { children?: never; collection: FormSelectOptionsT }
  | {
      children: ReactNode;
      collection?: never;
    }
);

const Select = ({ children, className, collection, error, isLoading, label, selectProps, testId }: PropsT) => {
  return (
    <FormGroup
      error={error}
      id={selectProps.id}
      label={label}
      testId={testId}
      className={classNames(className, "Dropdown", {
        "pointer-events-none no-user-select": isLoading,
      })}
    >
      <div className="Dropdown-wrapper" data-test-id={`${label}`}>
        {isLoading && <Loader size="small" absolute />}
        <select
          {...selectProps}
          className="select"
          onChange={(event) => {
            if (selectProps.onChange) {
              selectProps.onChange(event);
            }
          }}
        >
          {children ||
            collection?.map((option, index) => (
              <option key={`${option.value}-${option.label}--${index}`} disabled={option.disabled} value={option.value}>
                {option.label}
              </option>
            ))}
        </select>
      </div>
    </FormGroup>
  );
};

export default Select;
