import React from "react";
import cs from "classnames";
import { SystemNameT } from "../../graphql/generated/graphql";
import { Icon } from "../Icon/Icon";
import { SystemIcon } from "../SystemIcon/SystemIcon";
import ModalCloseButton from "./ModalCloseButton";
import { useModalContext } from "./modalContext";

type PropsT = {
  disabledCloseButton?: boolean;
  hasBorder?: boolean;
  heading?: string | React.ReactNode;
  icon?: string;
  systemIcon?: SystemNameT | null;
};

function ModalHeader({ disabledCloseButton, hasBorder, heading, icon, systemIcon }: PropsT) {
  const { testId } = useModalContext();

  return (
    <div className={cs("Modal-header", { "Modal-header--withBorder": hasBorder })} data-test-id={`${testId}-header`}>
      <div className="Grid--row Grid--paddingM Grid--center">
        <div className="Grid--col Grid--paddingM Grid--grow">
          <div className="d-flex align-items-center flex-gap-8">
            {systemIcon && <SystemIcon size="18px" system={systemIcon} />}
            {icon && <Icon kind={icon} size="18px" />}
            {heading && (
              <h2 className="Heading Heading--xl mb-0" data-test-id={`${testId}-heading`}>
                {heading}
              </h2>
            )}
          </div>
        </div>
        <div className="Grid--col Grid--paddingM Grid--shrink">
          <ModalCloseButton disabled={disabledCloseButton} onlyIcon />
        </div>
      </div>
    </div>
  );
}

export default ModalHeader;
