import React, { ForwardedRef } from "react";
import { CusotmIconBasicPropsT } from "./IconCustom";

export const HandCircleSmIcon = React.forwardRef(
  ({ className, size, ...rest }: CusotmIconBasicPropsT, ref: ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8 0C3.582 0 0 3.582 0 8C0 12.418 3.582 16 8 16C12.418 16 16 12.418 16 8C16 3.582 12.418 0 8 0Z"
        fill="#EF292A"
      />
      <path
        d="M10.0448 8.61267C10.1215 8.46867 10.6215 7.55 10.6262 7.54333C10.9068 7.00867 11.7575 7.22 11.7575 7.76C11.7575 7.842 11.7382 7.93133 11.6942 8.02733C11.6115 8.224 10.5415 10.3327 10.2775 10.8713C9.99949 11.436 9.65015 12 8.65215 12H6.69549C5.64549 12 5.09149 11.4333 5.09082 10.442L5.09215 6.11733C5.09215 5.54333 5.96149 5.57267 5.96149 6.09067V7.98733C5.96149 8.14333 6.27615 8.14267 6.27615 7.98733V4.94C6.27615 4.35 7.26015 4.366 7.26015 4.94V7.81667C7.26015 7.97533 7.55149 7.96867 7.55149 7.81333V4.43733C7.55149 4.148 7.79549 4 8.03749 4C8.27615 4 8.51349 4.14333 8.51349 4.43733L8.51482 7.852C8.51482 8.00467 8.81682 8.00867 8.81682 7.852V4.984C8.81682 4.44133 9.71549 4.42467 9.71549 4.984V8.534C9.71549 8.73267 9.96215 8.76467 10.0448 8.61267Z"
        fill="white"
      />
    </svg>
  )
);
