import React, { useMemo } from "react";
import { uniq } from "lodash";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis, YAxisProps } from "recharts";
import { CHART_GRID_PROPS, CHART_LINE_COLORS, X_AXIS_STYLES, Y_AXIS_STYLES } from "../../constants/report";
import { createHashMap } from "../../functions/calculateFieldCounts";
import { fetchMetricId } from "../../functions/fetchIds";
import { getYAxisDataTypes, normalizeMetricMoneyAxis } from "../../functions/widgetChartHelpers";
import {
  fetchColor,
  fetchDimensionValueFromRowAsText,
  fetchMetricPreviousValueFromRow,
  fetchMetricValueFromRow,
} from "../../functions/widgetDataHelpers";
import { normalizeMetricDataType } from "../../functions/widgetHelpers";
import {
  MetricDataT,
  WidgetCompareT,
  WidgetDataFragmentT,
  WidgetMetricT,
  WidgetRowT,
} from "../../graphql/generated/graphql";
import { useGetWidgetComponentData } from "../../hooks/useGetWidgetComponentData";
import { formatByDataType } from "../../i18n/formatNumbers";
import { ReportFilterT } from "../../types/report";
import { WidgetComponentPropsT, WidgetT } from "../../types/widgets";
import { withApiStateHandler } from "../ErrorLoadingWrapper/withApiStateHandler";
import { useReportBoardContext } from "../reportBoard/context/reportBoardContext";
import { MetricsByIdT } from "../reportBoard/context/types";
import { WidgetDataEmpty } from "../widgetDataAlerts/WidgetDataEmpty";
import { WidgetDataError } from "../widgetDataAlerts/WidgetDataError";
import { useHideSeries } from "./useHideSeries";
import { WidgetChartSummaryLegend } from "./WidgetChartSummaryLegend";
import { WidgetChartTooltipSummary } from "./WidgetChartTooltipSummary";
import widgetComponentContainer from "./widgetComponentContainer";
import { widgetDataComponentContainer } from "./widgetDataComponentContainer";

export type BarChartComponentPropsT = WidgetComponentPropsT;
type PropsT = { filterValues: ReportFilterT; widget: WidgetT; widgetData?: WidgetDataFragmentT };

const getYAxisWidgetMetrics = ({
  metricsById,
  rows,
  widgetMetrics,
}: {
  metricsById: MetricsByIdT;
  rows?: WidgetRowT[];
  widgetMetrics: WidgetMetricT[];
}) => {
  const currencies = uniq(rows?.map((row) => row.currency));

  return widgetMetrics.flatMap((metric) =>
    metricsById[metric.metricId].dataType === MetricDataT.MoneyT
      ? currencies.map((currency) => ({
          widgetMetric: metric,
          currency,
          yAxisId: normalizeMetricMoneyAxis(currency),
        }))
      : { widgetMetric: metric, currency: "", yAxisId: normalizeMetricDataType(metricsById[metric.metricId]) }
  );
};

const BarChartComponentContent = withApiStateHandler(
  widgetDataComponentContainer(({ widget: { compare, widgetDimensions, widgetMetrics }, widgetData }: PropsT) => {
    const {
      widgetSettingsData: { dimensionsById, metricsById },
    } = useReportBoardContext();

    const selectedDimensions = widgetDimensions.map((wd) => dimensionsById[wd.dimensionId]);

    const rows = widgetData?.rows.nodes as WidgetRowT[] | undefined;
    const rowsById = createHashMap(rows || [], "id");

    const formatRowDimensionsTexts = fetchDimensionValueFromRowAsText(selectedDimensions);
    const yAxisWidgetMetrics = useMemo(
      () => getYAxisWidgetMetrics({ widgetMetrics, rows, metricsById }),
      [widgetMetrics, metricsById, rows]
    );

    const { getIsHidden, getSerieId, onLegendClick } = useHideSeries({ widgetMetrics, widgetDimensions });

    if (!rows || !widgetMetrics || widgetMetrics.length === 0) {
      return <WidgetDataError />;
    }

    if (rows.length === 0) {
      return <WidgetDataEmpty />;
    }

    return (
      <div className="h-100">
        <ResponsiveContainer>
          <BarChart data={rows}>
            <CartesianGrid {...CHART_GRID_PROPS} />
            <Tooltip
              content={<WidgetChartTooltipSummary metricsById={metricsById} selectedDimensions={selectedDimensions} />}
              cursor={{ fill: "rgba(0,22,42,.05)" }}
            />

            <XAxis
              {...X_AXIS_STYLES}
              dataKey="id"
              interval="preserveStartEnd"
              tickFormatter={(record) => formatRowDimensionsTexts(rowsById[record]) as string}
            />

            {getYAxisDataTypes({ widgetMetrics, rows, metricsById }).map(
              ({ currency, dataType, orientation, yAxisId }) => {
                return (
                  <YAxis
                    {...Y_AXIS_STYLES}
                    key={yAxisId}
                    domain={[0, "dataMax"]}
                    orientation={orientation as YAxisProps["orientation"]}
                    padding={{ top: 10 }}
                    tickFormatter={(record) => formatByDataType(dataType, currency, record, 0)}
                    width={yAxisId.includes("MONEY") ? 70 : undefined}
                    yAxisId={yAxisId}
                  />
                );
              }
            )}

            {yAxisWidgetMetrics.map(({ currency, widgetMetric, yAxisId }, index) => {
              const barIdCompare = getSerieId({ serieId: widgetMetric.metricId, isCompare: true });
              const barId = getSerieId({ serieId: widgetMetric.metricId });
              return (
                <React.Fragment key={`${fetchMetricId(widgetMetric)}__${yAxisId}`}>
                  {(widgetMetric.compare && widgetMetric.compare !== WidgetCompareT.NoneT) ||
                    (compare && compare !== WidgetCompareT.NoneT && (
                      <Bar
                        data-currency={currency}
                        data-metric-compare={true}
                        data-metric-id={widgetMetric.metricId}
                        data-serie-id={barIdCompare}
                        data-sum-row={rows?.[0]}
                        dataKey={fetchMetricPreviousValueFromRow(widgetMetric)}
                        fill={fetchColor({ color: CHART_LINE_COLORS[index], isCompare: true })}
                        hide={getIsHidden(barIdCompare)}
                        isAnimationActive={false}
                        yAxisId={yAxisId}
                      />
                    ))}

                  <Bar
                    data-currency={currency}
                    data-metric-id={widgetMetric.metricId}
                    data-serie-id={barId}
                    data-sum-row={rows?.[0]}
                    dataKey={fetchMetricValueFromRow(widgetMetric)}
                    fill={fetchColor({ color: CHART_LINE_COLORS[index], isCompare: false })}
                    hide={getIsHidden(barId)}
                    isAnimationActive={false}
                    yAxisId={yAxisId}
                  />
                </React.Fragment>
              );
            })}

            {widgetMetrics.length > 1 && (
              <Legend
                content={
                  <WidgetChartSummaryLegend
                    getIsHidden={getIsHidden}
                    metricsById={metricsById}
                    onClick={(event) => onLegendClick(event)}
                  />
                }
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  })
);

const BarChartComponent = ({ filterValues, onWidgetDataChange, widget, ...rest }: BarChartComponentPropsT) => {
  const result = useGetWidgetComponentData({
    filterValues,
    widget,
    useSummaryByDimensions: true,
    onWidgetDataChange,
  });

  return (
    <div className="h-100">
      <BarChartComponentContent {...rest} {...result} filterValues={filterValues} widget={widget} />
    </div>
  );
};

export default widgetComponentContainer(BarChartComponent);
