import React from "react";
import { ResponsiveContainer, Scatter, ScatterChart } from "recharts";
import { WidgetCompareT, WidgetDataFragmentT, WidgetRowT } from "../../graphql/generated/graphql";
import { useGetWidgetComponentData } from "../../hooks/useGetWidgetComponentData";
import { ReportFilterT } from "../../types/report";
import { WidgetComponentPropsT, WidgetT } from "../../types/widgets";
import { withApiStateHandler } from "../ErrorLoadingWrapper/withApiStateHandler";
import { useReportBoardContext } from "../reportBoard/context/reportBoardContext";
import { WidgetDataEmpty } from "../widgetDataAlerts/WidgetDataEmpty";
import { WidgetDataError } from "../widgetDataAlerts/WidgetDataError";
import { useTimebasedChart } from "./useTimebasedChart";
import widgetComponentContainer from "./widgetComponentContainer";
import { widgetDataComponentContainer } from "./widgetDataComponentContainer";

export type ScatterChartComponentPropsT = WidgetComponentPropsT;

type PropsT = {
  filterValues: ReportFilterT;
  widget: WidgetT;
  widgetData?: WidgetDataFragmentT;
};

const ScatterChartComponentContent = withApiStateHandler(
  widgetDataComponentContainer(({ widget, widgetData }: PropsT) => {
    const {
      widgetSettingsData: { dimensionsById },
    } = useReportBoardContext();
    const { widgetDimensions, widgetMetrics } = widget;
    const rows = widgetData?.rows.nodes;
    const summaryRowsByDimensions = widgetData?.summaryByDimensions;

    const selectedDimensions = widgetDimensions
      .map((dimension) => dimensionsById[dimension.dimensionId])
      .filter((item) => !!item);

    const { cartesianGrid, legend, rowsByDateAsArray, series, tooltip, xaxis, yaxis } = useTimebasedChart({
      rows: (rows || []) as WidgetRowT[],
      dateGrouping: widget.dateGrouping,
      selectedDimensions,
      widgetMetrics,
      hasCompare: widget.compare !== WidgetCompareT.NoneT,
      summaryRowsByDimensions: summaryRowsByDimensions?.nodes || [],
    });

    if (!rows || !widgetMetrics || widgetMetrics.length === 0) {
      return <WidgetDataError />;
    }

    if (rows.length === 0) {
      return <WidgetDataEmpty />;
    }

    return (
      <ResponsiveContainer>
        <ScatterChart data={rowsByDateAsArray}>
          {tooltip}

          {cartesianGrid}

          {xaxis}

          {yaxis}

          {series?.map(({ color, dataKey, dimensionsValue, isCompare, isHidden, key, metric, serieId, yAxisId }) => (
            <Scatter
              key={key}
              data-dimension-value={dimensionsValue}
              data-metric-compare={isCompare}
              data-metric-id={metric.id}
              data-serie-id={serieId}
              dataKey={dataKey}
              fill={color}
              hide={isHidden}
              isAnimationActive={false}
              type="monotone"
              yAxisId={yAxisId}
            />
          ))}

          {legend}
        </ScatterChart>
      </ResponsiveContainer>
    );
  })
);

const ScatterChartComponent = ({ filterValues, onWidgetDataChange, widget, ...rest }: ScatterChartComponentPropsT) => {
  const result = useGetWidgetComponentData({
    filterValues,
    widget,
    useSummaryByDimensions: true,
    onWidgetDataChange,
  });

  return (
    <div className="h-100">
      <ScatterChartComponentContent {...rest} {...result} filterValues={filterValues} widget={widget} />
    </div>
  );
};

export default widgetComponentContainer(ScatterChartComponent);
