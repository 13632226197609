import React, { ForwardedRef } from "react";
import { CusotmIconBasicPropsT } from "./IconCustom";

export const HandCircleIcon = React.forwardRef(
  ({ className, size, ...rest }: CusotmIconBasicPropsT, ref: ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 25 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0.75 12C0.75 5.51107 6.01107 0.25 12.5 0.25C18.9889 0.25 24.25 5.51107 24.25 12C24.25 18.4889 18.9889 23.75 12.5 23.75C6.01107 23.75 0.75 18.4889 0.75 12Z"
        fill="#EF292A"
        stroke="url(#paint0_linear_5390_127885)"
        strokeWidth="0.5"
      />
      <g filter="url(#filter0_ddddd_5390_127885)">
        <path
          d="M15.5672 12.919C15.6822 12.703 16.4322 11.325 16.4392 11.315C16.8602 10.513 18.1362 10.83 18.1362 11.64C18.1362 11.763 18.1072 11.897 18.0412 12.041C17.9172 12.336 16.3122 15.499 15.9162 16.307C15.4992 17.154 14.9752 18 13.4782 18H10.5432C8.96823 18 8.13723 17.15 8.13623 15.663L8.13823 9.176C8.13823 8.315 9.44223 8.359 9.44223 9.136V11.981C9.44223 12.215 9.91423 12.214 9.91423 11.981V7.41C9.91423 6.525 11.3902 6.549 11.3902 7.41V11.725C11.3902 11.963 11.8272 11.953 11.8272 11.72V6.656C11.8272 6.222 12.1932 6 12.5562 6C12.9142 6 13.2702 6.215 13.2702 6.656L13.2722 11.778C13.2722 12.007 13.7252 12.013 13.7252 11.778V7.476C13.7252 6.662 15.0732 6.637 15.0732 7.476V12.801C15.0732 13.099 15.4432 13.147 15.5672 12.919Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="16"
          id="filter0_ddddd_5390_127885"
          width="12"
          x="7.13623"
          y="5"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_5390_127885" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="effect1_dropShadow_5390_127885" mode="normal" result="effect2_dropShadow_5390_127885" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
          <feBlend in2="effect2_dropShadow_5390_127885" mode="normal" result="effect3_dropShadow_5390_127885" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend in2="effect3_dropShadow_5390_127885" mode="normal" result="effect4_dropShadow_5390_127885" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
          <feBlend in2="effect4_dropShadow_5390_127885" mode="normal" result="effect5_dropShadow_5390_127885" />
          <feBlend in="SourceGraphic" in2="effect5_dropShadow_5390_127885" mode="normal" result="shape" />
        </filter>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_5390_127885"
          x1="12.5"
          x2="12.5"
          y1="0"
          y2="24"
        >
          <stop stopColor="#FD8484" />
          <stop offset="1" stopColor="#BD1E1F" />
        </linearGradient>
      </defs>{" "}
    </svg>
  )
);
