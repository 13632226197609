import React, { MouseEvent } from "react";
import cs from "classnames";

export enum HeadingVariants {
  h1 = "Heading--3xl",
  h2 = "Heading--xl",
  h3 = "Heading--lg",
  h4 = "Heading--md",
  h5 = "Heading--sm",
  h6 = "Heading--xs",
}

type PropsT = {
  className?: string;
  color?:
    | "gray"
    | "error"
    | "softGray"
    | "blue"
    | "accented"
    | "green"
    | "white"
    | "default"
    | "black-light"
    | "inherit";
  level: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  margin?: 0 | 4 | 8 | 12 | 16 | 20 | 24 | 28 | 32 | 40;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  spaced?: boolean;
  sticky?: boolean;
  uppercase?: boolean;
} & React.HTMLAttributes<HTMLElement>;

export const Heading = ({ className, color, level, margin = 8, spaced, sticky, style, uppercase, ...rest }: PropsT) => {
  const Component = level;
  const variant = HeadingVariants[level];

  return (
    <Component
      {...rest}
      className={cs("Heading", variant, `mb-${margin}`, className, {
        "Heading--sticky": sticky,
        "Heading--uppercase": uppercase,
        "Heading--spaced": spaced,
        [`Text--${color}`]: color,
      })}
      style={{
        ...(color === "inherit" && { color: "inherit" }),
        ...style,
      }}
    />
  );
};

type OmitedPropsT = Omit<PropsT, "level" | "dangerouslySetInnerHTML">;

export const HeadingPage = (props: OmitedPropsT) => <Heading margin={16} {...props} level="h1" />;
export const HeadingSection = (props: OmitedPropsT) => <Heading {...props} level="h2" />;
export const HeadingSmall = (props: OmitedPropsT) => <Heading {...props} level="h3" />;
