import React, { ChangeEvent, Dispatch, ReactNode, SetStateAction, useState } from "react";
import { Placement } from "@floating-ui/react";
import classNames from "classnames";
import { notifyError, notifySucces } from "../../functions/toast";
import {
  BiddingChangesUserCampaignActionMutationFnT,
  BiddingChangesUserCampaignActionMutationResultT,
  BudgetChangesUserCampaignActionMutationFnT,
  BudgetChangesUserCampaignActionMutationResultT,
  CampaignStatusT,
  CampaignT,
  StatusChangesUserCampaignActionMutationFnT,
  StatusChangesUserCampaignActionMutationResultT,
  useStatusChangesUserCampaignActionMutation,
} from "../../graphql/generated/graphql";
import { t } from "../../i18n/translation";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { ButtonPrimary, ButtonSecondary, ButtonTertiary } from "../../ui/Button/Button";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Label from "../../ui/forms/Label";
import Select from "../../ui/forms/Select";
import { Col, Row } from "../../ui/grid/Grid";
import { HeadingSmall } from "../../ui/Heading/Heading";
import { Icon } from "../../ui/Icon/Icon";
import { InfoBox } from "../../ui/InfoBox/InfoBox";
import { Link } from "../../ui/Link/Link";
import { SystemIcon } from "../../ui/SystemIcon/SystemIcon";
import { Text } from "../../ui/Text/Text";
import { SYSTEM_NAMES } from "../accountSelect/constants";
import { CampaignStatus } from "../widgetComponents/TableOptimatizationCell/TableOptimatizationCell";
import { PickedCampaignT } from "../widgetComponents/TableOptimizationRow";

export const useChangeStatusFormMutation = () =>
  useStatusChangesUserCampaignActionMutation({
    onCompleted: (responseData) => {
      const errors = responseData.organization?.statusChangesUserCampaignAction?.errors;

      if (errors?.length) {
        return notifyError(<>{errors.join("\n")}</>);
      }

      notifySucces(<>Change status successful</>);
    },
    onError: () => {
      notifyError(<>Change status failed</>);
    },
  });

export type ChangeFormContextT = {
  mutateBidding: BiddingChangesUserCampaignActionMutationFnT;
  mutateBiddingResult: BiddingChangesUserCampaignActionMutationResultT;
  mutateBudget: BudgetChangesUserCampaignActionMutationFnT;
  mutateBudgetResult: BudgetChangesUserCampaignActionMutationResultT;
  mutateStatus: StatusChangesUserCampaignActionMutationFnT;
  mutateStatusResult: StatusChangesUserCampaignActionMutationResultT;
  popup: { action: string | null; campaignId: string | null; isFirstCell?: boolean };
  resetPopup: () => void;
  setPopup: Dispatch<SetStateAction<{ action: string | null; campaignId: string | null; isFirstCell?: boolean }>>;
};

export const ChangePopupWrapper = ({
  campaign,
  children,
  colType,
  isFormDisabled,
  isFormLoading,
  onClose,
  onSave,
  placement = "bottom-start",
  title,
}: {
  campaign?: Pick<CampaignT, "beeManagedBidding" | "beeManagedBudget" | "beeSystemSettingsLink" | "system">;
  children: ReactNode;
  colType?: "budget" | "bidding";
  isFormDisabled?: boolean;
  isFormLoading?: boolean;
  onClose: () => void;
  onSave?: () => void;
  placement?: Placement;
  title: string;
}) => (
  <Dropdown
    className={classNames(" ", {})}
    placement={placement}
    testId={"change-pop-wrapper"}
    triggerItem={({ reference, ...props }) => {
      return (
        <div
          {...props}
          ref={reference}
          style={{
            ...(placement === "bottom-end"
              ? { position: "absolute", bottom: 0, right: 0, height: "2px", width: "10px" }
              : {}),
          }}
        />
      );
    }}
    isDefaultOpen
    onClose={onClose}
  >
    <div className="background-white elevate-2" style={{ width: "270px" }}>
      <Row alignItems="center" className="border-bottom border-color-gray pt-4 pr-4 pb-4 pl-8">
        <Col type="grow">
          <HeadingSmall className="ma-0 pa-0">{title}</HeadingSmall>
        </Col>
        <Col>
          <ButtonTertiary icon="close" onlyIcon onClick={onClose} />
        </Col>
      </Row>
      <div className="pv-16 ph-8">{children}</div>

      {((colType === "bidding" && campaign?.beeManagedBidding) ||
        (colType === "budget" && campaign?.beeManagedBudget)) && (
        <InfoBox className="mh-8 mb-16" variant="warning" withIcon={false}>
          <div className="negative-mh-8">
            <Row alignItems="center" className="mb-8">
              <Col>
                <Icon className="Icon--warning" kind="warning" size="16px" />
              </Col>
              <Col type="grow">
                <Text className="Text--warning" color="error" tag="p" bold>
                  Warning! Campaign is managed by Dotidot. So this change will be overwritten within next Dotidot
                  synchronization. Update your campaign settings first.
                </Text>
              </Col>
            </Row>

            <Text color="gray">
              {colType === "bidding"
                ? "For bidding optimization through Actionable Analytics, access your campaign settings and pause bidding synchronization from Dotidot app."
                : "For budget optimization through Actionable Analytics, access your campaign settings and pause budget synchronization from Dotidot app."}
            </Text>
            <div className="mt-16">
              {campaign.beeSystemSettingsLink && (
                <Link href={campaign.beeSystemSettingsLink} isExternal>
                  <SystemIcon className="mr-8" size="1em" system={campaign.system} />
                  {SYSTEM_NAMES[campaign.system]} campaign settings
                </Link>
              )}
            </div>
          </div>
        </InfoBox>
      )}

      {onSave && (
        <Row className="pa-8 border-top border-color-gray">
          <ButtonPrimary disabled={isFormDisabled} loading={isFormLoading} onClick={onSave}>
            Save
          </ButtonPrimary>
          <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
        </Row>
      )}
    </div>
  </Dropdown>
);

type PropsT = ChangeFormContextT & {
  campaign: Omit<PickedCampaignT, "budgetId">;
  onClose: () => void;
  placement?: Placement;
};

export const ChangeStatusForm = ({ campaign, mutateStatus, mutateStatusResult, onClose, placement }: PropsT) => {
  const { organization } = useAppSettings();
  const [wantedValues, setWantedValues] = useState<{ [key: string]: string }>({});

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (event.target) {
      setWantedValues({ ...wantedValues, [event.target.name]: event.target.value });
    }
  };

  const statusChanges = Object.keys(wantedValues).reduce(
    (acc, key) =>
      wantedValues[key] === "" ? acc : [...acc, { campaignId: key, newStatus: wantedValues[key] as CampaignStatusT }],
    [] as { campaignId: string; newStatus: CampaignStatusT }[]
  );

  const handleSend = () => {
    mutateStatus({
      variables: {
        organizationExternalId: organization.externalId,
        statusChanges,
      },
      onCompleted: () => {
        onClose();
      },
    });
  };

  return (
    <ChangePopupWrapper
      campaign={campaign}
      isFormDisabled={statusChanges.length === 0 || mutateStatusResult.loading}
      isFormLoading={mutateStatusResult.loading}
      placement={placement}
      title="Change status"
      onClose={onClose}
      onSave={handleSend}
    >
      <Row alignItems="center" className="mb-16">
        <Col type="grow">
          <Label>Current status:</Label>
        </Col>
        <Col className="text-right" width="147px">
          <CampaignStatus status={campaign.status} />
        </Col>
      </Row>

      <Row alignItems="center">
        <Col type="grow">
          <Label>New status:</Label>
        </Col>
        <Col width="147px">
          <Select selectProps={{ name: campaign.id, onChange: handleChange, disabled: mutateStatusResult.loading }}>
            <option value="">No change</option>
            {campaign.status !== CampaignStatusT.EnabledT && (
              <option value="ENABLED">{t("campaign.status.ENABLED")}</option>
            )}
            {campaign.status !== CampaignStatusT.PausedT && (
              <option value="PAUSED">{t("campaign.status.PAUSED")}</option>
            )}
            {campaign.status !== CampaignStatusT.RemovedT && (
              <option value="REMOVED">{t("campaign.status.REMOVED")}</option>
            )}
          </Select>
        </Col>
      </Row>
    </ChangePopupWrapper>
  );
};
