import React from "react";
import cs from "classnames";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useToggleClassOnMount } from "../../hooks/useToggleClassOnMount";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { Icon } from "../../ui/Icon/Icon";
import { Link } from "../../ui/Link/Link";

export const RootSidebarMenuItems = () => {
  const { pathname } = useLocation();
  const {
    layoutSelectors: { sidebarMenu },
  } = useAppSettings();

  const container = document.querySelector(sidebarMenu);
  const newSidebarElement = document.querySelector(".NewLayout-sidebarIcons");

  useToggleClassOnMount(`${sidebarMenu} a.Navigation-item`, "active", { shouldRemoveOnMount: true });

  if (!container) {
    return null;
  }

  // Temporary added new and old nav for easier release
  return ReactDOM.createPortal(
    <>
      {!newSidebarElement ? (
        <ul className="Navigation-subMenu mt-8">
          <li className="mv-4">
            <Link
              data-test-id="reporting-reports"
              to={ROUTES.homepage()}
              className={cs("Navigation-item", {
                active:
                  pathname === ROUTES.homepage() ||
                  pathname === ROUTES.reports() ||
                  pathname === ROUTES.firstTemplates() ||
                  pathname === ROUTES.templates(),
              })}
            >
              <span className="d-flex align-items-center text-overflow">
                <span className="text-overflow js-text-overflow">Reports</span>
              </span>
            </Link>
          </li>
          {/* TODO - Remove this condition when kpis are ready for production */}
          {process.env.NODE_ENV === "development" && (
            <li className="mv-4">
              <Link
                data-test-id="reporting-kpis"
                to={ROUTES.kpis()}
                className={cs("Navigation-item", {
                  active: pathname === ROUTES.kpis() || pathname === ROUTES.kpisForAllOrganizations(),
                })}
              >
                <span className="d-flex align-items-center text-overflow">
                  <span className="text-overflow js-text-overflow">KPI Dashboard</span>
                </span>
              </Link>
            </li>
          )}
          <li className="mv-4">
            <Link
              className={cs("Navigation-item", { active: pathname === ROUTES.metricsAndDimensions() })}
              data-test-id="reporting-metrics"
              to={ROUTES.metricsAndDimensions()}
            >
              <span className="d-flex align-items-center text-overflow">
                <span className="text-overflow js-text-overflow">Metrics & Dimension</span>
              </span>
            </Link>
          </li>
          <li className="mv4">
            <Link
              className={cs("Navigation-item", { active: pathname === ROUTES.userSystemActions() })}
              data-test-id="reporting-user-system-actions"
              to={ROUTES.userSystemActions()}
            >
              <span className="d-flex align-items-center text-overflow">
                <span className="text-overflow js-text-overflow">System Changes</span>
              </span>
            </Link>
          </li>
        </ul>
      ) : (
        <ul className="Navigation Navigation--main  Navigation--secondLevel">
          <li>
            <Link
              data-test-id="reporting-reports"
              to={ROUTES.homepage()}
              className={cs("Navigation-item", {
                "active-react":
                  pathname === ROUTES.homepage() ||
                  pathname === ROUTES.reports() ||
                  pathname === ROUTES.firstTemplates() ||
                  pathname === ROUTES.templates(),
              })}
            >
              <span className="d-flex align-items-center text-overflow">
                <Icon className="Navigation-icon" kind="reports" />
                <span className="text-overflow js-text-overflow">Reports</span>
              </span>
            </Link>
          </li>
          {/* TODO - Remove this condition when kpis are ready for production */}
          {process.env.NODE_ENV === "development" && (
            <li>
              <Link
                data-test-id="reporting-kpis"
                to={ROUTES.kpis()}
                className={cs("Navigation-item", {
                  "active-react": pathname === ROUTES.kpis() || pathname === ROUTES.kpisForAllOrganizations(),
                })}
              >
                <span className="d-flex align-items-center text-overflow">
                  <span className="text-overflow js-text-overflow">KPI Dashboard</span>
                </span>
              </Link>
            </li>
          )}
          <li>
            <Link
              className={cs("Navigation-item", { "active-react": pathname === ROUTES.metricsAndDimensions() })}
              data-test-id="reporting-metrics"
              to={ROUTES.metricsAndDimensions()}
            >
              <span className="d-flex align-items-center text-overflow">
                <Icon className="Navigation-icon" kind="metric-dimensions" />
                <span className="text-overflow js-text-overflow">Metrics & Dimension</span>
              </span>
            </Link>
          </li>
          <li>
            <Link
              className={cs("Navigation-item", { "active-react": pathname === ROUTES.userSystemActions() })}
              data-test-id="reporting-user-system-actions"
              to={ROUTES.userSystemActions()}
            >
              <span className="d-flex align-items-center text-overflow">
                <Icon className="Navigation-icon" kind="system-changes" />
                <span className="text-overflow js-text-overflow">System Changes</span>
              </span>
            </Link>
          </li>
        </ul>
      )}
    </>,
    container
  );
};
