import React, { ForwardedRef, MouseEvent, PropsWithChildren, forwardRef } from "react";
import cs from "classnames";
import { TAG_KIND, TAG_SIZE } from "./constants";

export type TagKindT = typeof TAG_KIND[number];
type SizeT = typeof TAG_SIZE[number];

export type TagPropsT = {
  className?: string;
  isDisabled?: boolean;
  kind?: TagKindT;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  size?: SizeT;
  testId?: string;
};

export const Tag = forwardRef(
  (
    { children, className, isDisabled, kind = "grape", size = "medium", testId, ...rest }: PropsWithChildren<TagPropsT>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        data-test-id={testId}
        className={cs("Tag", className, {
          [`Tag--${kind}`]: kind,
          [`Tag--${size}`]: size,
          disabled: isDisabled,
        })}
        {...rest}
      >
        {children}
      </div>
    );
  }
);
