import React, { CSSProperties } from "react";
import { SubAccountT } from "../../graphql/generated/graphql";
import { Ellipsis } from "../../ui/Ellipsis/Ellipsis";
import { Col, Row } from "../../ui/grid/Grid";
import { TBody, Table, Td, TrBody } from "../../ui/Table/Table";
import { Text } from "../../ui/Text/Text";

type PropsT = {
  systems: Omit<SubAccountT, "id">[];
};

export const AccountSelectConnectedSystemsPreviewItem = ({
  email,
  externalId,
  name,
  secondColStyle,
}: Omit<SubAccountT, "id"> & { secondColStyle?: CSSProperties }) => {
  return (
    <Row alignItems="center" padding="l">
      <Col type="grow">{name}</Col>
      <Col style={secondColStyle}>
        <Text color="gray">{email}</Text>
        <div className="mt-4">{externalId}</div>
      </Col>
    </Row>
  );
};

export const AccountSelectConnectedSystemsPreview = ({ systems }: PropsT) => (
  <div className="ph-8 pv-4 background-white border-radius-8 elevate-3" style={{ minWidth: "400px" }}>
    {systems.map((system) => (
      <div key={system.externalId} className="item-delimiter mt-8 pb-8">
        <AccountSelectConnectedSystemsPreviewItem {...system} />
      </div>
    ))}
  </div>
);

export const AccountSelectConnectedSystemsPreviewInTable = ({ systems }: PropsT) => (
  <Table className="text-regular elevate-3" style={{ fontSize: "10px", minWidth: "400px" }}>
    <TBody>
      {systems.map((system) => (
        <TrBody key={system.externalId}>
          <Td>
            <Ellipsis>{system.name}</Ellipsis>
          </Td>
          <Td>
            <div>{system.email}</div>
            <div className="mt-4">{system.externalId}</div>
          </Td>
        </TrBody>
      ))}
    </TBody>
  </Table>
);
