export const DEFAULT_LINE_WIDTH = 6;
export const DEFAULT_LINE_SPACING = 2;
export const DEFAULT_SIZE = 50;

export const COLORS = {
  used_products: "#ff9b00",
  unused_products: "#eee",
  used_adSets: "#3ed95e",
  unused_adSets: "#eee",
};

export const REMAINING_COLORS = {
  used_products: "#8C969F",
  unused_products: "#eee",
  used_adSets: "#596774",
  unused_adSets: "#eee",
};
export const GREEN_COLORS = {
  used_products: "#3ed95e",
  unused_products: "#eee",
  used_adSets: "#3ed95e",
  unused_adSets: "#eee",
};
export const COLOR_KIND = {
  color: COLORS,
  remaining: REMAINING_COLORS,
  green: GREEN_COLORS,
};
