import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import cs from "classnames";
import { isEqual } from "lodash";
import { PageInfoT } from "../../graphql/generated/graphql";
import { usePaginationFloatingWindow } from "../../hooks/usePaginationFloatingWindow";
import { usePrevious } from "../../hooks/usePrevious";
import { Button } from "../Button/Button";
import { Col, Row } from "../grid/Grid";
import { Tooltip } from "../Tooltip/Tooltip";
import { PAGE_SIZES } from "./constants";

export type OverridePaginationStateT = {
  currentPageIndex?: number;
  pageCount?: number;
  pageSize?: number;
  pageSizes?: number[];
};

export type PaginationStateT = {
  canNextPage: boolean;
  canPreviousPage: boolean;
  currentPageIndex: number;
  pageCount: number;
  pageSize: number;
  pageSizes: number[];
  totalSize: number;
};
export type PaginationAdditionalPropsT = PaginationStateT & {
  onSetPageIndex: (index: number) => void;
  onSetPageSize: (size: number) => void;
};

const countPages = (rowsCount: number, pageSize: number) => Math.ceil(rowsCount / (pageSize * 1.0));
const DEFAULT_OVERRIDE_STATE = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function usePagination<Type extends any[] | []>(
  tableRows: Type,
  overrideDefaultState: OverridePaginationStateT = DEFAULT_OVERRIDE_STATE,
  onPaginationChange?: (args: PaginationStateT) => void
): [paginationPropsWithCallbacks: PaginationAdditionalPropsT, paginatedTableRows: Type] {
  const defaultPageSize = overrideDefaultState?.pageSizes?.[0] || PAGE_SIZES[0];
  const [paginationProps, setPaginationState] = useState<PaginationStateT>({
    canNextPage: true,
    canPreviousPage: true,
    currentPageIndex: 0,
    pageCount: countPages(tableRows.length, defaultPageSize),
    pageSize: defaultPageSize,
    pageSizes: PAGE_SIZES,
    totalSize: tableRows.length,
    ...overrideDefaultState,
  });

  const prevPaginationProps = usePrevious(paginationProps);

  useEffect(() => {
    if (
      typeof onPaginationChange === "function" &&
      !!prevPaginationProps &&
      !isEqual(paginationProps, prevPaginationProps)
    ) {
      onPaginationChange({ ...paginationProps });
    }
  }, [onPaginationChange, paginationProps, prevPaginationProps]);

  const prevOverrideDefaultState = usePrevious(overrideDefaultState);
  useEffect(() => {
    if (!isEqual(prevOverrideDefaultState, overrideDefaultState)) {
      setPaginationState((state) => ({
        ...state,
        ...overrideDefaultState,
        pageCount: countPages(state.totalSize, overrideDefaultState.pageSize || state.pageSize),
      }));
    }
  }, [overrideDefaultState, prevOverrideDefaultState]);

  useEffect(() => {
    setPaginationState((state) => ({
      ...state,
      currentPageIndex: 0,
      totalSize: tableRows.length,
      pageCount: countPages(tableRows.length, state.pageSize),
    }));
  }, [tableRows]);

  const onSetPageIndex = (currentPageIndex: number) => setPaginationState({ ...paginationProps, currentPageIndex });
  const onSetPageSize = (pageSize: number) => {
    setPaginationState((state) => ({
      ...state,
      pageSize,
      currentPageIndex: 0,
      pageCount: countPages(state.totalSize, pageSize),
    }));
  };

  const paginationPropsWithCallbacks = {
    ...paginationProps,
    onSetPageIndex,
    onSetPageSize,
  } as PaginationAdditionalPropsT;

  let paginatedData = tableRows;
  if (tableRows.length > 0) {
    paginatedData = tableRows.slice(
      paginationProps.currentPageIndex * paginationProps.pageSize,
      (paginationProps.currentPageIndex + 1) * paginationProps.pageSize
    ) as Type;
  }

  return [paginationPropsWithCallbacks, paginatedData];
}

export type GraphqlPaginationVariablesT = {
  limit: number;
  offset: number;
};

export type UseGraphqlPaginationResultT = [
  PaginationAdditionalPropsT,
  GraphqlPaginationVariablesT,
  (pageInfo?: PageInfoT) => void
];

export function useGraphqlPagination(
  overrideDefaultState: OverridePaginationStateT = DEFAULT_OVERRIDE_STATE,
  onPaginationChange?: (args: PaginationStateT) => void
): [
  paginationPropsWithCallbacks: PaginationAdditionalPropsT,
  graphqlPaginationVariables: GraphqlPaginationVariablesT,
  setPageInfo: (pageInfo?: PageInfoT) => void
] {
  const defaultPageSize = overrideDefaultState?.pageSizes?.[0] || PAGE_SIZES[0];
  const [paginationProps, setPaginationState] = useState<PaginationStateT>({
    canNextPage: true,
    canPreviousPage: true,
    currentPageIndex: 0,
    pageCount: 0,
    pageSize: defaultPageSize,
    pageSizes: PAGE_SIZES,
    totalSize: 0,
    ...overrideDefaultState,
  });

  useEffect(() => {
    if (typeof onPaginationChange === "function") {
      onPaginationChange({ ...paginationProps });
    }
  }, [onPaginationChange, paginationProps]);

  const prevOverrideDefaultState = usePrevious(overrideDefaultState);
  useEffect(() => {
    if (!isEqual(prevOverrideDefaultState, overrideDefaultState)) {
      setPaginationState((state) => ({
        ...state,
        ...overrideDefaultState,
        pageCount: countPages(state.totalSize, overrideDefaultState.pageSize || state.pageSize),
      }));
    }
  }, [overrideDefaultState, prevOverrideDefaultState]);

  const onSetPageIndex = useCallback(
    (currentPageIndex: number) => setPaginationState((prev) => ({ ...prev, currentPageIndex })),
    [setPaginationState]
  );
  const onSetPageSize = useCallback(
    (pageSize: number) =>
      setPaginationState((state) => ({
        ...state,
        pageSize,
        currentPageIndex: 0,
        pageCount: countPages(state.totalSize, pageSize),
      })),
    []
  );

  const paginationPropsWithCallbacks = useMemo(
    () =>
      ({
        ...paginationProps,
        onSetPageIndex,
        onSetPageSize,
      } as PaginationAdditionalPropsT),
    [paginationProps, onSetPageIndex, onSetPageSize]
  );

  const setPageInfo = useCallback(
    (pageInfo?: PageInfoT) => {
      const totalSize = pageInfo?.total || 0;
      const pageCount = countPages(totalSize, paginationProps.pageSize);
      if (paginationProps.totalSize !== totalSize || paginationProps.pageCount !== pageCount) {
        setPaginationState((state) => ({
          ...state,
          totalSize,
          pageCount,
        }));
      }
    },
    [paginationProps.pageSize, paginationProps.totalSize, paginationProps.pageCount]
  );

  const graphqlPaginationVariables = useMemo(
    () => ({
      limit: paginationProps.pageSize,
      offset: paginationProps.currentPageIndex * paginationProps.pageSize,
    }),
    [paginationProps]
  );

  return [paginationPropsWithCallbacks, graphqlPaginationVariables, setPageInfo];
}

export type PaginationPropsT = {
  canNextPage?: boolean;
  canPreviousPage?: boolean;
  className?: string;
  currentPageIndex: number;
  disablePageSizeChange?: boolean;
  isCompact?: boolean;
  onSetPageIndex: (index: number) => void;
  onSetPageSize?: (size: number) => void;
  pageCount: number;
  pageSize: number;
  pageSizes?: number[];
  testId?: string;
  totalSize?: number;
};

export const Pagination = ({
  canNextPage,
  canPreviousPage,
  className,
  currentPageIndex,
  disablePageSizeChange,
  isCompact,
  onSetPageIndex,
  onSetPageSize,
  pageCount,
  pageSize,
  pageSizes = PAGE_SIZES,
  testId = "pagination",
  totalSize,
}: PaginationPropsT) => {
  const isDisablePrevButton = !canPreviousPage || currentPageIndex - 1 < 0;
  const isDisableNextButton = !canNextPage || currentPageIndex + 1 >= pageCount;
  const { endMore, pages, startMore, startingCounter } = usePaginationFloatingWindow(pageCount, currentPageIndex);
  const buttonSize = isCompact ? "small" : "medium";

  const handleSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (onSetPageSize) {
      onSetPageSize(Number(event.target.value));
    }
  };

  return (
    <div
      data-test-id={testId}
      className={cs("Pagination", className, {
        "Pagination--compact": isCompact,
      })}
    >
      {onSetPageSize && (totalSize || pageCount * pageSize) > pageSizes[0] && (
        <div className="Pagination-items">
          <Row alignItems="center" justify="center" inline>
            <Col>Show</Col>
            <Col>
              <div className="Dropdown select">
                <div className="Dropdown-wrapper">
                  <select
                    data-test-id={`${testId}-select`}
                    disabled={disablePageSizeChange}
                    value={pageSize}
                    onChange={handleSizeChange}
                  >
                    {pageSizes.map((size) => (
                      <option key={size} value={size}>
                        {size} items
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </Col>
            <Col data-test-id="pag-info-total-items" noWrap>
              out of {totalSize || pageCount * pageSize}
            </Col>
          </Row>
        </div>
      )}
      {pages?.length !== 0 && pageCount !== 1 && (
        <div className="Pagination-buttons">
          <Tooltip tooltipContent="First page">
            <Button
              className="mr-12"
              data-test-id={`${testId}-btn-start`}
              disabled={isDisablePrevButton}
              icon="chevron-first"
              size={buttonSize}
              variant="secondaryGray"
              onlyIcon
              onClick={() => onSetPageIndex(0)}
            />
          </Tooltip>

          <Tooltip tooltipContent={isCompact ? "Previous page" : ""}>
            <Button
              className={cs("ml-0", { " mr-16": isCompact, "mr-24": !isCompact })}
              data-test-id={`${testId}-btn-prev`}
              disabled={isDisablePrevButton}
              icon="chevron-left"
              onlyIcon={isCompact}
              size={buttonSize}
              variant={isCompact ? "secondaryGray" : "secondary"}
              onClick={() => onSetPageIndex(currentPageIndex - 1)}
            >
              {!isCompact && "Prev"}
            </Button>
          </Tooltip>

          {!!startMore && (
            <React.Fragment>
              <Button
                key="1"
                className="Pagination-button"
                data-test-id="pag-page-1"
                size={buttonSize}
                withoutVariant
                onClick={() => onSetPageIndex(0)}
              >
                1
              </Button>
              <Button
                className="Pagination-button notActive"
                data-test-id="pag-start-more"
                size={buttonSize}
                disabled
                withoutVariant
              >
                …
              </Button>
            </React.Fragment>
          )}

          {pages.map((_, index) => {
            const isActive = currentPageIndex === startingCounter + index;
            return (
              <Button
                key={startingCounter + index + 1}
                className={cs("Pagination-button", { active: isActive })}
                data-test-id={isActive ? "pag-page-current" : `pag-page-${startingCounter + index + 1}`}
                size={buttonSize}
                withoutVariant
                onClick={() => onSetPageIndex(startingCounter + index)}
              >
                {startingCounter + index + 1}
              </Button>
            );
          })}

          {!!endMore && (
            <React.Fragment>
              <Button
                className="Pagination-button notActive"
                data-test-id="pag-end-more"
                size={buttonSize}
                disabled
                withoutVariant
              >
                …
              </Button>
              <Button
                key={pageCount}
                className="Pagination-button"
                data-test-id={`pag-page-${pageCount}`}
                size={buttonSize}
                withoutVariant
                onClick={() => onSetPageIndex(pageCount - 1)}
              >
                {pageCount}
              </Button>
            </React.Fragment>
          )}

          <Tooltip tooltipContent={isCompact ? "Next page" : ""}>
            <Button
              className={isCompact ? "ml-12" : "ml-20"}
              data-test-id={`${testId}-btn-next`}
              disabled={isDisableNextButton}
              hasIconAfterText={!isCompact}
              icon="chevron-right"
              onlyIcon={isCompact}
              size={buttonSize}
              variant={isCompact ? "secondaryGray" : "secondary"}
              onClick={() => onSetPageIndex(currentPageIndex + 1)}
            >
              {!isCompact && "Next"}
            </Button>
          </Tooltip>

          <Tooltip tooltipContent="Last page">
            <Button
              className="ml-12"
              data-test-id={`${testId}-btn-end`}
              disabled={isDisableNextButton}
              icon="chevron-last"
              size={buttonSize}
              variant="secondaryGray"
              onlyIcon
              onClick={() => onSetPageIndex(pageCount - 1)}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};
