import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormDimension, FormDimensionPropsT } from "../components/formDimension/FormDimension";
import { ROUTES } from "../constants/routes";
import { notifyError, notifySucces } from "../functions/toast";
import {
  CustomDimensionQueryT,
  CustomDimensionsDocumentT,
  useCustomDimensionQuery,
  useUpdateCustomDimensionMutation,
} from "../graphql/generated/graphql";
import { useAppSettings } from "../providers/appSettingsProvider";
import { RouteParamsT } from "../types/router";
import ErrorBox from "../ui/ErrorBox/ErrorBox";
import { Loader } from "../ui/Loader/Loader";
import { Modal, ModalBody } from "../ui/Modal";
import ModalCloseButton from "../ui/Modal/ModalCloseButton";
import { normalizeApiFormDimensionValues } from "./DimensionNew";
import { DIMENSIONS } from "./MetricsAndDimensions";

const DimensionEditModalContent = ({
  data,
  isModalLoading,
  ...rest
}: FormDimensionPropsT & { data?: CustomDimensionQueryT; isModalLoading: boolean }) => {
  if (isModalLoading) {
    return <Loader />;
  }

  if (data?.organization?.customDimension?.discarded) {
    return (
      <>
        <ModalBody>
          <ErrorBox withIcon>Custom dimension was deleted</ErrorBox>
        </ModalBody>
        <ModalCloseButton />
      </>
    );
  }

  return <FormDimension defaultValues={data?.organization?.customDimension} {...rest} />;
};

export const DimensionEditModal = ({ dimensionId, onClose }: { dimensionId?: string; onClose: () => void }) => {
  const [serverErrors, setServerErrors] = useState<string[] | undefined>(undefined);
  const {
    organization: { externalId },
  } = useAppSettings();

  const { data, loading } = useCustomDimensionQuery({
    fetchPolicy: "network-only",
    variables: {
      organizationExternalId: externalId,
      customDimensionId: dimensionId || "",
    },
    skip: !dimensionId,
  });

  const [mutate, { loading: mutationLoading }] = useUpdateCustomDimensionMutation({
    refetchQueries: [CustomDimensionsDocumentT],
    onCompleted: (res) => {
      const errors = res.organization?.updateCustomDimension?.errors;

      if (errors?.length) {
        return setServerErrors(errors);
      }
      notifySucces(<>{`Custom dimension updated!`}</>);
      onClose();
    },
    onError: () => notifyError(<>{`Server error when updating custom dimension!`}</>),
  });

  return (
    <Modal close={onClose} heading="Edit custom dimension" testId="edit-custom-dimension-modal" isOpen>
      <DimensionEditModalContent
        data={data}
        isModalLoading={loading}
        isMutationLoading={mutationLoading}
        serverErrors={serverErrors}
        onSubmit={(values) =>
          mutate({
            variables: {
              ...normalizeApiFormDimensionValues(values),
              id: dimensionId || "",
              organizationExternalId: externalId,
            },
          })
        }
      />
    </Modal>
  );
};

export const DimensionEditPage = () => {
  const { dimensionId } = useParams<RouteParamsT["dimension"]>();
  const navigate = useNavigate();

  return (
    <DimensionEditModal
      dimensionId={dimensionId}
      onClose={() => navigate(ROUTES.metricsAndDimensionsTabs(DIMENSIONS))}
    />
  );
};
