import React, { FormEvent } from "react";
import { WidgetT } from "../../../types/widgets";
import Input from "../../../ui/forms/Input";
import { OnFormEditWidgetChangeT } from "../FormEditWidget";

type PropsT = {
  formKey: string;
  onChange: OnFormEditWidgetChangeT;
  placeholder?: string;
  value: WidgetT["name"];
};

export const InputName = ({ formKey, onChange, placeholder, value }: PropsT) => {
  const path = ["name"];
  const pathString = path.join(".");

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    const newValue = event?.currentTarget?.value;
    onChange((prev) => {
      return { ...prev, name: newValue };
    });
  };

  return (
    <Input
      className="mb-16"
      label="Widget name"
      inputProps={{
        id: `${formKey}-${pathString}`,
        onChange: handleChange,
        value: value || "",
        name: pathString,
        placeholder,
      }}
    />
  );
};
