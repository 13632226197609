import React, { HTMLProps, PropsWithChildren } from "react";
import cs from "classnames";
import { useTabsContext } from "./TabsContext";

type PropsT = PropsWithChildren & HTMLProps<HTMLUListElement>;

export const TabList = ({ children, className, ...rest }: PropsT) => {
  const { testId } = useTabsContext();

  return (
    <ul
      {...rest}
      className={cs("TabNavigation TabNavigation--noSpacing", className)}
      data-test-id={`${testId}-TabList`}
      role="tablist"
    >
      {children}
    </ul>
  );
};
