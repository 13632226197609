import React, { useMemo } from "react";
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { CHART_GRID_PROPS, X_AXIS_STYLES, Y_AXIS_STYLES } from "../../constants/report";
import { KpiSettingT, MetricT, ResultT } from "../../graphql/generated/graphql";
import { formatDateRange } from "../../i18n/formatDate";
import { formatPercents } from "../../i18n/formatNumbers";
import { KPI_SEMAPHORE_VARIANTS } from "../kpiSemaphore/KpiSemaphore";
import { KpiSemaphoreTooltipContent } from "../kpiSemaphore/KpiSemaphoreTooltipContent";

type PeriodT = Pick<ResultT, "id" | "startOn" | "endOn" | "currentValue" | "semaphore" | "estimatedProgress">;

type PropsT = Pick<KpiSettingT, "name"> & {
  height?: number;
  metric?: Pick<MetricT, "id" | "dataType">;
  periodResults?: PeriodT[] | null;
};

export const KpiPeriodComparsionChart = ({ height, metric, name, periodResults }: PropsT) => {
  const sortedResults = useMemo(
    () => periodResults?.sort((a, b) => new Date(a.startOn).getTime() - new Date(b.startOn).getTime()),
    [periodResults]
  );

  if (!periodResults || !periodResults.length || !metric) {
    return <div>"This KPI has no periods data"</div>;
  }

  return (
    <div>
      <ResponsiveContainer height={height || 300}>
        <BarChart barCategoryGap={10} data={sortedResults}>
          <Tooltip
            cursor={{ fill: "rgba(0,22,42,.05)" }}
            position={{ y: -100 }}
            wrapperStyle={{ zIndex: 2 }}
            allowEscapeViewBox={{
              y: true,
            }}
            content={(props) =>
              props.payload?.[0]?.payload ? (
                <div className="tooltip-inner text-center" style={{ borderRadius: "8px" }}>
                  <KpiSemaphoreTooltipContent
                    metric={metric}
                    name={name}
                    result={props.payload[0].payload as PeriodT}
                  />
                </div>
              ) : null
            }
          />

          <CartesianGrid {...CHART_GRID_PROPS} />

          <XAxis
            {...X_AXIS_STYLES}
            allowDataOverflow={false}
            dataKey={(period) => period.startOn}
            tickFormatter={(record, index) => formatDateRange(periodResults[index].startOn, periodResults[index].endOn)}
          />

          <YAxis {...Y_AXIS_STYLES} tickFormatter={(record) => formatPercents(record)} />

          <Bar dataKey={(period: PeriodT) => period.estimatedProgress} isAnimationActive={false} type="monotone">
            {periodResults.map((period) => (
              <Cell key={period.id} fill={period.semaphore ? KPI_SEMAPHORE_VARIANTS[period.semaphore].color : "gray"} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
