import React from "react";
import { useNavigate } from "react-router-dom";
import { withApiStateHandler } from "../components/ErrorLoadingWrapper/withApiStateHandler";
import { FormKpi } from "../components/formKpi/FormKpi";
import { ROUTES } from "../constants/routes";
import { notifyError, notifySucces } from "../functions/toast";
import {
  KpiSettingCreateMutationVariablesT,
  KpisNewPageQueryT,
  useKpiSettingCreateMutation,
  useKpisNewPageQuery,
} from "../graphql/generated/graphql";
import { Layout } from "../layout/wrappers/Layout";
import { useAppSettings } from "../providers/appSettingsProvider";

type PropsT = {
  data?: KpisNewPageQueryT;
  loading: boolean;
};

export const KpisNewPageComponent = withApiStateHandler(({ data, loading: isDataLoading }: PropsT) => {
  const { organization } = useAppSettings();
  const navigate = useNavigate();

  const [mutation, mutateState] = useKpiSettingCreateMutation({
    onCompleted: (response) => {
      const errors = response?.organization?.createKpiSetting?.errors;

      if (!!errors?.length) {
        return notifyError(<>Error when creating KPI</>);
      }
      if (response?.organization?.createKpiSetting?.kpiSetting?.id) {
        notifySucces(<>KPI was created successfully</>);
        navigate(ROUTES.kpis());
      }
    },
    onError: () => notifyError(<>Server error when updating KPI!</>),
  });

  const handleSubmit = (newKpi: KpiSettingCreateMutationVariablesT) => {
    mutation({
      variables: {
        organizationExternalId: organization.externalId,
        ...newKpi,
        sourceSystems: Array.isArray(newKpi.sourceSystems)
          ? newKpi.sourceSystems?.map((sourceSystem) => ({
              externalId: sourceSystem.externalId,
              name: sourceSystem.name,
              externalName: sourceSystem.externalName,
            }))
          : null,
      },
    });
  };

  return (
    <Layout backTo={ROUTES.kpis()} kind="formLayout" title="New KPI" hideSidebar>
      <FormKpi
        dimensions={data?.organization?.dimensions.nodes}
        isDataLoading={isDataLoading}
        isSubmitLoading={mutateState.loading}
        metrics={data?.organization?.metrics.nodes}
        onSubmit={handleSubmit}
      />
    </Layout>
  );
});

export const KpisNewPage = () => {
  const { organization } = useAppSettings();

  const { data, error, loading } = useKpisNewPageQuery({
    variables: { organizationExternalId: organization.externalId as string },
  });

  return <KpisNewPageComponent data={data} error={error} loading={loading} />;
};
