import React from "react";
import { ReferenceType } from "@floating-ui/react";
import cs from "classnames";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { DropdownMenuPropsT } from "./DropdownMenu";

type PropsT = Pick<DropdownMenuPropsT, "icon" | "isLoading" | "label"> &
  DropdownMenuPropsT["buttonProps"] & {
    onClick: () => void;
    reference?: (node: ReferenceType | null) => void;
  };

export const DropdownMenuButton = ({ icon, isLoading, label, reference, variant, ...props }: PropsT) => {
  return (
    <Button
      {...props}
      ref={reference}
      className={cs({ "DropdownMenu-button": variant === "tertiary" })}
      icon={!label && icon ? icon : undefined}
      loading={isLoading}
      onlyIcon={!label}
      variant={variant}
    >
      {label}
      {label && <Icon className="DropdownMenu-chevron ml-8 negative-mr-4" kind="chevron-down" size="20px" />}
    </Button>
  );
};
