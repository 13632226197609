import React, { useCallback, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { CreateSharedReportInputT } from "../../graphql/generated/graphql";
import { useSearch } from "../../hooks/useSearch";
import { ActionBar } from "../../ui/ActionBar/ActionBar";
import { ButtonPrimary, ButtonTertiary } from "../../ui/Button/Button";
import Checkbox from "../../ui/forms/Checkbox";
import ErrorMessage from "../../ui/forms/ErrorMessage";
import { InputController } from "../../ui/forms/InputController";
import { HeadingSmall } from "../../ui/Heading/Heading";
import { Tile } from "../../ui/Tile/Tile";
import { ReportBoardPageT, ReportBoardSharedReportT } from "../reportBoard/context/types";
import { SearchInput } from "../search/Search";
import { formSharingLinkSchema } from "./validations";

export type FormSharingLinkInputsT = {
  allPages: boolean;
  hasPassword: boolean;
  name: string;
  pageIds: {
    [key: string]: boolean;
  };
  password: string;
};

export type FormSharingLinkPropsT = {
  defaultValues?: Partial<ReportBoardSharedReportT>;
  isEditation?: boolean;
  isSubmiting?: boolean;
  onCancel: () => void;
  onSubmit: (values: Omit<CreateSharedReportInputT, "reportId">) => void;
  pages: Pick<ReportBoardPageT, "id" | "name">[];
  reportName: string;
};

export const FormSharingLink = ({
  defaultValues,
  isEditation,
  isSubmiting,
  onCancel,
  onSubmit,
  pages,
  reportName,
}: FormSharingLinkPropsT) => {
  const filterPageBy = useCallback((page: Pick<ReportBoardPageT, "id" | "name">) => page.name || "", []);
  const { filterData: filterPages, searchInputProps } = useSearch(filterPageBy);
  const filteredPages = useMemo(() => filterPages(pages), [pages, filterPages]);
  const [togglePagesInput, setTogglePagesInput] = useState<"all" | "none" | "partial">(
    pages.every((page) => defaultValues?.pages?.nodes.some((defaultPage) => defaultPage.id === page.id))
      ? "all"
      : "partial"
  );

  const {
    control,
    formState: { dirtyFields, errors, isDirty },
    handleSubmit: handleSubmitUseForm,
    setValue,
    watch,
  } = useForm<FormSharingLinkInputsT>({
    resolver: yupResolver(formSharingLinkSchema),
    defaultValues: {
      allPages: !!defaultValues?.allPages,
      name: defaultValues?.name || "",
      pageIds: pages.reduce(
        (acc, page) => ({
          ...acc,
          [page.id]: !!defaultValues?.pages?.nodes.some((defaultPage) => defaultPage.id === page.id),
        }),
        {}
      ),
      password: "",
      hasPassword: !!defaultValues?.hasPassword,
    },
  });

  const [hasPassword, allPages] = watch(["hasPassword", "allPages"]);

  useEffect(() => {
    if (!hasPassword) {
      setValue("password", "");
    }
  }, [hasPassword, setValue]);

  useEffect(() => {
    if (togglePagesInput !== "partial") {
      setValue(
        "pageIds",
        pages.reduce((acc, page) => ({ ...acc, [page.id]: togglePagesInput === "all" }), {}),
        { shouldDirty: true }
      );
    }
  }, [togglePagesInput, pages, setValue]);

  useEffect(() => {
    const subscription = watch((values) => {
      const allChecked = Object.values(values.pageIds || {}).every((value) => !!value);
      const noneChecked = Object.values(values.pageIds || {}).every((value) => !value);
      if (allChecked) {
        setTogglePagesInput("all");
      }
      if (noneChecked) {
        setTogglePagesInput("none");
      }
      if (!allChecked && !noneChecked) {
        setTogglePagesInput("partial");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleSubmit = useCallback(
    (values: FormSharingLinkInputsT) => {
      onSubmit({
        ...values,
        pageIds: Object.keys(values.pageIds).filter((key) => values.pageIds[key]),
        ...(dirtyFields.password || !hasPassword ? { password: values.password === "" ? null : values.password } : {}),
      });
    },
    [onSubmit, dirtyFields, hasPassword]
  );

  return (
    <div>
      <form data-test-id="form-sharing-link" id="form-sharing-link" onSubmit={handleSubmitUseForm(handleSubmit)}>
        {!isEditation && (
          <>
            <HeadingSmall>Basic informations</HeadingSmall>
            <p>
              Set the unique and descriptive name for your link to easily recognize link purpose eg. “Link for you
              client”.
            </p>
          </>
        )}

        {!isEditation && (
          <>
            <InputController
              control={control}
              errors={errors}
              id="name"
              label="Link name"
              name="name"
              input={{
                style: { maxWidth: "333px" },
              }}
            />

            <hr />
          </>
        )}

        <HeadingSmall>Password protection</HeadingSmall>
        <p>Set the password for the link - user will have to enter the password in case show the report.</p>

        <InputController
          control={control}
          errors={errors}
          id="hasPassword"
          label="Enable Password Protection"
          name="hasPassword"
          testId="password-protection-switch"
          input={{
            type: "switch",
            checked: true,
          }}
        />

        {hasPassword && (
          <InputController
            className="mt-16"
            control={control}
            errors={errors}
            id="password"
            label={defaultValues?.hasPassword ? "Update password" : "Password"}
            name="password"
            input={{
              style: { maxWidth: "333px" },
            }}
            hasCopyBtn
          />
        )}

        <hr />

        <HeadingSmall>Share settings</HeadingSmall>
        <p>Set the preferences for sharing. Do you want to share whole report or specific page?</p>

        <InputController
          control={control}
          errors={errors}
          id="allPages"
          label="Share Complete Report (All Pages)"
          name="allPages"
          testId="share-complete-report-switch"
          input={{
            type: "switch",
          }}
        />

        {!allPages && (
          <>
            <Tile className="mt-24" style={{ ...(errors.pageIds ? { borderColor: "red" } : {}) }}>
              <SearchInput className="mb-16" hasMagnifierIcon {...searchInputProps} />

              <Checkbox
                className="Text--bold"
                isLineCheck={togglePagesInput === "partial"}
                label={reportName}
                input={{
                  id: "toggle-pages-input",
                  name: "toggle-pages",
                  checked: togglePagesInput !== "none",
                  onChange: () => setTogglePagesInput((prev) => (prev !== "none" ? "none" : "all")),
                }}
              />

              <div className="ml-16">
                {filteredPages.map((page) => (
                  <InputController
                    key={page.id}
                    control={control}
                    errors={errors}
                    id={`page-${page.id}`}
                    label={page.name || ""}
                    name={`pageIds.${page.id}`}
                    testId={`check-page-${page.name}`}
                    input={{
                      type: "checkbox",
                    }}
                  />
                ))}
              </div>
            </Tile>
            <ErrorMessage error={errors.pageIds} />
          </>
        )}

        <ActionBar hasAbsolutePosition>
          <ButtonPrimary disabled={isSubmiting || !isDirty} loading={isSubmiting}>
            {isEditation ? "Update" : "Create"} link
          </ButtonPrimary>
          <ButtonTertiary disabled={isSubmiting} type="button" onClick={onCancel}>
            Cancel
          </ButtonTertiary>
        </ActionBar>
      </form>
    </div>
  );
};
