import { Link, useNavigate } from "react-router-dom";
import { useConfirmationContext } from "../components/confirmationDialog/confirmationDialogContext";
import { OPERATORS } from "../components/formCustomMetric/FormCustomMetric";
import { ShowDimensionMetricPill } from "../components/showDimensionMetricPill/ShowDimensionMetricPill";
import { ROUTES } from "../constants/routes";
import { CustomMetricOperationT, CustomMetricsQueryT } from "../graphql/generated/graphql";
import { DropdownMenu } from "../ui/Dropdown/DropdownMenu";
import { Col, Row } from "../ui/grid/Grid";
import { Icon } from "../ui/Icon/Icon";
import { Td, TrBody } from "../ui/Table/Table";

export type CustomMetricT = NonNullable<CustomMetricsQueryT["organization"]>["customMetrics"]["nodes"][number];
type PropsT = {
  customMetric: CustomMetricT;
  onDelete: () => void;
};

export const MetricTableRow = ({ customMetric, onDelete }: PropsT) => {
  const { callWithConfirmation } = useConfirmationContext();

  const navigate = useNavigate();

  return (
    <TrBody key={customMetric.id}>
      <Td>
        <Row alignItems="center">
          <Col type="grow">
            <Link to={ROUTES.metricEdit(customMetric.id)}>{customMetric.name}</Link>
          </Col>
          <Col type="shrink">
            <DropdownMenu
              buttonProps={{ variant: "tertiary" }}
              testId={`Dropdown--${customMetric.name}`}
              collection={[
                {
                  value: "Edit custom metric",
                  icon: "edit",
                  onClick: () => {
                    navigate(ROUTES.metricEdit(customMetric.id));
                  },
                },
                {
                  value: "Duplicate custom metric",
                  icon: "duplicate",
                  onClick: () => {
                    navigate(ROUTES.metricDuplicate(customMetric.id));
                  },
                },
                {
                  color: "danger",
                  icon: "trash",
                  isDisabled: !!customMetric?.referencesCount,
                  onClick: () => {
                    callWithConfirmation(onDelete, {
                      description: `Are you sure you want to delete this custom metric: "${customMetric.name}"?`,
                      title: "Delete custom metric",
                      testId: `delete-custom-metric-${customMetric.id}`,
                    });
                  },
                  tooltipContent: !!customMetric?.referencesCount
                    ? "This metric cannot be deleted because it is used somewhere else."
                    : undefined,
                  value: "Delete custom metric",
                },
              ]}
            />
          </Col>
        </Row>
      </Td>
      <Td>{customMetric.description}</Td>
      <Td>
        {customMetric.operation && customMetric.rightMetric && customMetric.leftMetric && (
          <>
            <ShowDimensionMetricPill item={customMetric.leftMetric} />
            <span style={{ fontSize: "16px", margin: "0 10px" }}>{OPERATORS[customMetric.operation]}</span>
            <ShowDimensionMetricPill item={customMetric.rightMetric} />{" "}
            {customMetric.operation === CustomMetricOperationT.RatioT ? (
              <span style={{ fontSize: "16px", marginLeft: "5px" }}>%</span>
            ) : (
              <span />
            )}
          </>
        )}
      </Td>
      <Td justifyRight>{customMetric.referencesCount}</Td>
      <Td>
        <Row alignItems="center" type="shrink">
          <Col>
            <Icon color="#8C969F" kind="user" size="20px" />
          </Col>
          <Col>{customMetric.user?.fullname}</Col>
        </Row>
      </Td>
    </TrBody>
  );
};
