import React, { ChangeEvent, FormEvent, Fragment, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { normalizeSearchText } from "../../functions/normalize";
import { SubAccountT, SystemNameT } from "../../graphql/generated/graphql";
import { SourceSystemsT } from "../../types/report";
import { ButtonSecondary } from "../../ui/Button/Button";
import Input from "../../ui/forms/Input";
import { InputController } from "../../ui/forms/InputController";
import { Col, Row } from "../../ui/grid/Grid";
import { Text } from "../../ui/Text/Text";

export type ChooseAccountsT = SubAccountT[];

export type FormReportAccountInputsT = {
  [K: string]: boolean;
};

export type FormChooseAccountPropsT = {
  accounts: ChooseAccountsT;
  formId: string;
  onSubmit: ({ systemIds, systemName }: { systemIds: SourceSystemsT; systemName: SystemNameT }) => void;
  selectedAccounts?: SourceSystemsT;
  systemName: SystemNameT;
};

export const FormChooseAccount = ({
  onSubmit,
  accounts = [],
  selectedAccounts = [],
  formId,
  systemName,
}: FormChooseAccountPropsT) => {
  const defaultValues = useMemo(
    () =>
      accounts.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.externalId]: selectedAccounts?.some(({ externalId }) => externalId === cur.externalId),
        }),
        {}
      ),
    [accounts, selectedAccounts]
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormReportAccountInputsT>({
    defaultValues: defaultValues,
  });

  const [selected, setSelected] = useState(selectedAccounts.map(({ externalId }) => externalId));
  const [search, setSearch] = useState("");
  const isSearchActive = search !== "";

  const enhancedAccounts = useMemo(
    () =>
      accounts
        .filter(
          ({ externalId, name }) =>
            !isSearchActive ||
            normalizeSearchText(name).includes(normalizeSearchText(search)) ||
            normalizeSearchText(externalId)
              .replaceAll("-", "")
              .includes(normalizeSearchText(search).replaceAll("-", ""))
        )
        .map((account) => ({
          ...account,
          isSelected: selected.indexOf(account.externalId) !== -1,
        }))
        .sort((a, b) => (a.isSelected && !b.isSelected ? -1 : 1)),
    [accounts, isSearchActive, search, selected]
  );

  useEffect(() => {
    const subscription = watch((values) => {
      setSelected(Object.keys(values).filter((key) => values[key]));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleRemoveAll = () => {
    selected.forEach((id) => setValue(id, false));
  };

  const handleSubmitWithPreventPropagation = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    return handleSubmit(async (partialResult) => {
      const systemIds = Object.keys(partialResult)
        .filter((id) => partialResult[id])
        .map((id) => {
          const selectedAccount = accounts.find((account) => account.externalId === id);
          return {
            id: selectedAccount?.sourceSystemId || "",
            externalId: selectedAccount?.externalId || "",
            name: systemName,
            externalName: selectedAccount?.name,
            email: selectedAccount?.email,
          };
        });

      return onSubmit({ systemIds, systemName });
    })(event);
  };

  return (
    <div>
      <Input
        className="mb-16 mt-8"
        inputProps={{
          id: "search",
          value: search,
          placeholder: "Search...",
          isWithSearch: true,
          onChange: (event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value),
        }}
      />
      <form id={formId} onSubmit={handleSubmitWithPreventPropagation}>
        {!isSearchActive && !!selected.length && (
          <fieldset className="d-flex align-items-center ">
            {selected.length !== accounts.length && <label className="Text Text--bold">Selected</label>}
            <ButtonSecondary className="ml-a" icon="remove" size="small" onClick={handleRemoveAll}>
              Remove all
            </ButtonSecondary>
          </fieldset>
        )}

        {!enhancedAccounts.length &&
          isSearchActive &&
          "None of the items match your search query. Try to modify what you’re looking for."}

        {!enhancedAccounts.length && !isSearchActive && "No account available"}

        {enhancedAccounts.map(({ email, externalId, id, isSelected, name }, index) => {
          const hasNotSelectedLabel =
            !isSearchActive && index !== 0 && !isSelected && enhancedAccounts[index - 1].isSelected;

          const isLastOneSelected = !isSearchActive && isSelected && !enhancedAccounts[index + 1]?.isSelected;

          return (
            <Fragment key={id}>
              {hasNotSelectedLabel && <label className="mt-24 Text Text--bold">Available</label>}
              <div key={id} className={classNames({ "item-delimiter pb-8": !isLastOneSelected })}>
                <InputController
                  control={control}
                  errors={errors}
                  id={`${id}`}
                  input={{ type: "checkbox" }}
                  name={externalId}
                  label={
                    <Row alignItems="center">
                      <Col type="grow">{name}</Col>
                      <Col>
                        <Text color="gray">{email}</Text>
                        <div className="mt-4">{externalId}</div>
                      </Col>
                    </Row>
                  }
                  isFullWidth
                />
              </div>
            </Fragment>
          );
        })}
      </form>
    </div>
  );
};
