import { SystemNameT } from "../../graphql/generated/graphql";

export const SYSTEM_NAMES = {
  [SystemNameT.AdwordsT]: "Google Ads",
  [SystemNameT.BingT]: "Microsoft Advertising",
  [SystemNameT.FacebookT]: "Facebook",
  [SystemNameT.SklikT]: "Sklik",
  [SystemNameT.GoogleAnalytics3T]: "Google Analytics 3",
  [SystemNameT.GoogleAnalytics4T]: "Google Analytics 4",
  [SystemNameT.AnyT]: "Any system",
};
