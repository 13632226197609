import { Reducer, useCallback, useMemo, useReducer } from "react";

type ActionT = { type: "TOGGLE" | "ON" | "OFF" | "ON_CLOSING" };
type StateT = { isClosing: boolean; isOpen: boolean };

export type ModalReducerT = Reducer<StateT, ActionT>;

export const modalReducer: ModalReducerT = (state, action) => {
  switch (action.type) {
    case "TOGGLE": {
      return { isOpen: !state.isOpen, isClosing: false };
    }
    case "ON": {
      return { isOpen: true, isClosing: false };
    }
    case "ON_CLOSING": {
      return { isOpen: true, isClosing: true };
    }
    case "OFF": {
      return { isOpen: false, isClosing: false };
    }
    default: {
      throw new Error(`Unhandled type: ${action.type}`);
    }
  }
};

export type UseModalReturnT = {
  close: () => void;
  forceClose: () => void;
  isClosing: boolean;
  isOpen: boolean;
  open: () => void;
  toggle: () => void;
};

export const useModal = ({ isInitialOpen = false, reducer = modalReducer } = {}): UseModalReturnT => {
  const [{ isClosing, isOpen }, dispatch] = useReducer(reducer, { isOpen: isInitialOpen, isClosing: false });

  const toggle = useCallback(() => dispatch({ type: "TOGGLE" }), []);
  const open = useCallback(() => {
    dispatch({ type: "ON" });
  }, []);
  const close = useCallback(() => {
    dispatch({ type: "ON_CLOSING" });
  }, []);
  const forceClose = useCallback(() => dispatch({ type: "OFF" }), []);

  const value = useMemo(
    () => ({ isOpen, toggle, open, close, forceClose, isClosing }),
    [close, forceClose, isClosing, isOpen, open, toggle]
  );

  return value;
};
