import React, { ElementType, PropsWithChildren } from "react";
import { Placement } from "@floating-ui/react";
import cs from "classnames";
import { DropdownFloatingBox } from "./DropdownFloatingBox";
import { useDropdown } from "./useDropdown";

type PropsT = {
  className?: string;
  isDefaultOpen?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  placement?: Placement;
  testId: string;
  triggerItem: ElementType;
};

export const Dropdown = ({
  children,
  className,
  isDefaultOpen,
  isOpen,
  onClose,
  onOpen,
  placement = "bottom-end",
  testId,
  triggerItem: TriggerItem,
}: PropsWithChildren<PropsT>) => {
  const { boxProps, reference, triggerElementProps } = useDropdown({ placement, onOpen, onClose, isDefaultOpen });

  if (!children) {
    return null;
  }

  return (
    <div
      className={cs({ ShowOnHoverIsActive: triggerElementProps?.["aria-expanded"] === "true" })}
      data-active-element={triggerElementProps?.["aria-expanded"]}
      data-test-id={testId}
      style={{ flexGrow: 1 }}
    >
      {<TriggerItem reference={reference} {...triggerElementProps} />}

      <DropdownFloatingBox {...{ ...boxProps, ...(isOpen !== undefined ? { isOpen } : {}) }} className={className}>
        {children}
      </DropdownFloatingBox>
    </div>
  );
};
