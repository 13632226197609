import React, { PropsWithChildren, ReactNode } from "react";
import ReactDOM from "react-dom";
import { useToggleClassOnMount } from "../../hooks/useToggleClassOnMount";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { InlineEditOnChangeT } from "../../ui/forms/InlineEdit";
import { SubheaderComponent } from "./SubheaderComponent";

type PropsT = { backTo?: string | -1; onTitleChange?: InlineEditOnChangeT; title?: ReactNode };

export type SubheaderPropsT = PropsWithChildren<PropsT>;

export const Subheader = (props: SubheaderPropsT) => {
  const {
    layoutSelectors: { subheaderContainer, subheaderWrapper },
  } = useAppSettings();

  const container = document.querySelector(subheaderContainer);
  useToggleClassOnMount(subheaderWrapper, "d-none", { shouldRemoveOnMount: true });

  if (!container) {
    return null;
  }

  return ReactDOM.createPortal(<SubheaderComponent {...props} />, container);
};
