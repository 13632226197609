/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from "react";
import { WIDGET_SCHEMA } from "../../constants/report";
import { getValidatedWidgetData, getWidgetNamePlaceholder } from "../../functions/widgetHelpers";
import { EditedWidgetT, WidgetT } from "../../types/widgets";
import { Heading } from "../../ui/Heading/Heading";
import { WidgetSettingsDataT } from "../reportBoard/context/types";
import { OnSetEditedWidgetT } from "../reportBoard/ReportBoardEditWidget";
import { InputCampaignDataColumns } from "./inputs/InputCampaignDataColumns";
import { InputCompare } from "./inputs/InputCompare";
import { InputCurrency } from "./inputs/InputCurrency";
import { InputDateGrouping } from "./inputs/InputDateGrouping";
import { InputDateRange } from "./inputs/InputDateRange";
import { InputDescription } from "./inputs/InputDescription";
import { InputMaxRows } from "./inputs/InputMaxRows";
import { InputName } from "./inputs/InputName";
import { InputSourceSystems } from "./inputs/InputSourceSystems";
import { InputWidgetDimensions } from "./inputs/InputWidgetDimensions";
import { InputWidgetMetrics } from "./inputs/InputWidgetMetrics";

export type FormEditWidgetInputsT = { [key: string]: string | object | string[] };

type PropsT = {
  editedWidget: WidgetT;
  onSetEditedWidget: OnSetEditedWidgetT;
  tabPanelType: "setting" | "information";
  widgetSettingsData: WidgetSettingsDataT;
};

export type OnFormEditWidgetChangeT = (onChange: (widget: EditedWidgetT) => EditedWidgetT) => void;

export const FormEditWidget = ({ editedWidget, onSetEditedWidget, tabPanelType, widgetSettingsData }: PropsT) => {
  const widgetProps = WIDGET_SCHEMA[editedWidget.kind].props;
  const widgetRules = WIDGET_SCHEMA[editedWidget.kind].rules;
  const hasMetricOptions = WIDGET_SCHEMA[editedWidget.kind].hasMetricOptions;
  const placeholderName = useMemo(
    () =>
      getWidgetNamePlaceholder({
        widgetMetrics: editedWidget.widgetMetrics,
        widgetDimensions: editedWidget.widgetDimensions,
        metricsById: widgetSettingsData.metricsById,
        dimensionsById: widgetSettingsData.dimensionsById,
      }),
    [editedWidget.widgetMetrics, editedWidget.widgetDimensions]
  );
  const clearedDimension = editedWidget.widgetDimensions.filter((dimension) => dimension.dimensionId);
  const hasDimensions = !!clearedDimension.length;

  const handleChange = useCallback(
    (onChange: (widget: EditedWidgetT) => EditedWidgetT) => {
      onSetEditedWidget((prev) => {
        const updatedWidget = onChange(prev);
        return getValidatedWidgetData({
          ...widgetSettingsData,
          widget: { ...updatedWidget, ...(updatedWidget.hasDefaultData ? { hasDefaultData: false } : {}) },
        });
      });
    },
    [onSetEditedWidget]
  );

  const inputProps = {
    formKey: "edit-widget",
    onChange: handleChange,
  };

  return (
    <div>
      {tabPanelType === "information" && (
        <>
          {!!(widgetProps.includes("name") || widgetProps.includes("description")) && (
            <Heading level="h3" margin={16}>
              Widget Info
            </Heading>
          )}

          {widgetProps.includes("name") && (
            <InputName {...inputProps} placeholder={placeholderName} value={editedWidget.name} />
          )}

          {widgetProps.includes("description") && <InputDescription {...inputProps} value={editedWidget.description} />}
        </>
      )}

      {tabPanelType === "setting" && (
        <>
          {widgetProps.includes("sourceSystems") && !!widgetSettingsData.connectedSystems.length && (
            <>
              <InputSourceSystems
                {...inputProps}
                connectedSystems={widgetSettingsData.connectedSystems}
                value={editedWidget.sourceSystems}
              />
              <div className="delimiter mv-16"></div>
            </>
          )}

          {!!widgetProps.includes("widgetMetrics") && (
            <div>
              <InputWidgetMetrics
                {...inputProps}
                addButtonTitle="Add metric"
                dateGrouping={editedWidget.dateGrouping}
                hasDefaultData={editedWidget.hasDefaultData}
                hasMetricOptions={hasMetricOptions}
                label="Metrics"
                rules={widgetRules}
                value={editedWidget.widgetMetrics}
              />
            </div>
          )}
          {!!widgetProps.includes("widgetDimensions") && (
            <div>
              <div className="delimiter mv-16"></div>
              <InputWidgetDimensions
                {...inputProps}
                addButtonTitle="Add dimension"
                hasDefaultData={editedWidget.hasDefaultData}
                label="Dimensions"
                rules={widgetRules}
                value={editedWidget.widgetDimensions}
              />

              {hasDimensions && widgetProps.includes("maxRows") && (
                <InputMaxRows
                  {...inputProps}
                  rules={widgetRules}
                  value={{ maxRows: editedWidget.maxRows, showOther: editedWidget.showOther }}
                />
              )}
            </div>
          )}

          <div className="delimiter mv-16"></div>

          <h3 className="Heading Heading--lg mb-8">Widget options</h3>

          {widgetProps.includes("dateGrouping") && (
            <InputDateGrouping {...inputProps} rules={widgetRules} value={editedWidget.dateGrouping} />
          )}

          {widgetProps.includes("compare") && !hasMetricOptions && (
            <InputCompare {...inputProps} value={editedWidget.compare} />
          )}

          {widgetProps.includes("currency") && <InputCurrency {...inputProps} value={editedWidget.currency} />}

          {widgetProps.includes("campaignDataColumns") && (
            <InputCampaignDataColumns {...inputProps} rules={widgetRules} value={editedWidget.campaignDataColumns} />
          )}

          {!!widgetProps.includes("dateRange") && (
            <div>
              <div className="delimiter mv-16"></div>
              <h3 className="Heading Heading--lg mb-8">Custom Date Range</h3>
              <InputDateRange {...inputProps} value={editedWidget.dateRange} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
