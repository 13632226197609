import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { withApiStateHandler } from "../components/ErrorLoadingWrapper/withApiStateHandler";
import { KpiList } from "../components/kpiList/KpiList";
import { KpiSummaryOverview } from "../components/kpiSummaryOverview/KpiSummaryOverview";
import { ROUTES } from "../constants/routes";
import { notifyError, notifySucces } from "../functions/toast";
import { KpisPageQueryT, useKpiSettingsDeleteMutation, useKpisPageQuery } from "../graphql/generated/graphql";
import { Breadcrumb } from "../layout/wrappers/Breadcrumb";
import { BreadcrumbItem } from "../layout/wrappers/BreadcrumbItem";
import { Layout } from "../layout/wrappers/Layout";
import { useAppSettings } from "../providers/appSettingsProvider";
import { HeadingPage } from "../ui/Heading/Heading";
import { Tab } from "../ui/Tabs/Tab";
import { TabList } from "../ui/Tabs/TabList";
import { Tabs } from "../ui/Tabs/Tabs";

type PropsT = {
  data?: KpisPageQueryT;
  onDeleteKpiSettings: (kpiSettingIds: string[]) => void;
};

export const KpiListsTabs = ({ isAllOrganizations }: { isAllOrganizations?: boolean }) => {
  const navigate = useNavigate();

  return (
    <Tabs initialSelectedTab={isAllOrganizations ? "suborganizations" : "my-organization"} testId="kpi-tabs">
      <TabList className="mb-16">
        <Tab id="my-organization" onClick={() => navigate(ROUTES.kpis())}>
          My organization KPIs
        </Tab>
        <Tab id="suborganizations" onClick={() => navigate(ROUTES.kpisForAllOrganizations())}>
          Suborganizations KPIs
        </Tab>
      </TabList>
    </Tabs>
  );
};

const KpisPageComponent = withApiStateHandler(({ data, onDeleteKpiSettings }: PropsT) => {
  const kpiSettings = data?.organization?.kpiSettings.nodes;
  const kpiSummary = data?.organization?.kpiProgressSummary;
  const hasSuborganizations = !!data?.organization?.subOrganizations?.length;

  return (
    <Layout title="KPIs">
      <Breadcrumb>
        <BreadcrumbItem>KPIs Dashboard</BreadcrumbItem>
      </Breadcrumb>

      <HeadingPage>KPIs Dashboard</HeadingPage>

      {hasSuborganizations && <KpiListsTabs />}

      <KpiList kpiSettings={kpiSettings} onDeleteKpiSettings={onDeleteKpiSettings}>
        <KpiSummaryOverview kpiSettings={kpiSettings} kpiSummary={kpiSummary} />
      </KpiList>
    </Layout>
  );
});

export const KpisPage = () => {
  const { organization } = useAppSettings();

  const { data, error, loading, refetch } = useKpisPageQuery({
    variables: { organizationExternalId: organization.externalId },
    fetchPolicy: "network-only",
  });

  const [mutation] = useKpiSettingsDeleteMutation({
    onCompleted: (response) => {
      const errors = response?.organization?.deleteKpiSettings?.errors;

      if (!!errors?.length) {
        return notifyError(<>Error when deleting KPI</>);
      }

      const deletedKpis = response?.organization?.deleteKpiSettings?.kpiSettings || 0;

      if (response?.organization?.deleteKpiSettings?.kpiSettings?.length) {
        notifySucces(<>{deletedKpis > 1 ? "KPIs" : "KPI"} was deleted successfully</>);
        refetch();
      }
    },
    onError: () => notifyError(<>Error when deleting KPI</>),
  });

  const handleDeleteKpiSettings = useCallback(
    (kpiSettingIds: string[]) => {
      mutation({
        variables: {
          organizationExternalId: organization.externalId,
          kpiSettingIds,
        },
      });
    },
    [mutation, organization.externalId]
  );

  return (
    <KpisPageComponent data={data} error={error} loading={loading} onDeleteKpiSettings={handleDeleteKpiSettings} />
  );
};
