import React, { CSSProperties, Dispatch, SetStateAction, useEffect } from "react";
import { isEmpty, keys, map, pickBy } from "lodash";
import {
  Control,
  DeepRequired,
  FieldArrayWithId,
  FieldErrorsImpl,
  Path,
  UseFieldArrayAppend,
  UseFieldArrayInsert,
  UseFieldArrayRemove,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { v4 as uuid } from "uuid";
import { ConditionT } from "../../types/widgets";
import { ButtonSecondary } from "../../ui/Button/Button";
import { MetricOrDimensionT } from "../../ui/forms/MetricDimensionController";
import { SelectableDimensionT, SelectableMetricT } from "../../ui/forms/MetricDimensionSelectTabs";
import { ConditionsFormT, ConditionsT, DimensionsValueCountsT } from "./FormConditions";
import FormConditionsItem from "./FormConditionsItem";

type PropsT<T extends ConditionsFormT> = {
  append: UseFieldArrayAppend<ConditionsFormT>;
  conditions: T["conditions"];
  control: Control<T>;
  dimensions?: SelectableDimensionT[];
  dimensionsValueCounts?: DimensionsValueCountsT;
  errors: FieldErrorsImpl<DeepRequired<ConditionsFormT>>;
  fields: FieldArrayWithId<ConditionsFormT>[];
  insert: UseFieldArrayInsert<ConditionsFormT>;
  metrics?: SelectableMetricT[];
  onChange?: (values: ConditionsFormT) => void;
  onGetValueCounts?: (dimensionId: string) => void;
  onSetIsConditionEmpty?: Dispatch<SetStateAction<boolean>>;
  remove: UseFieldArrayRemove;
  reportId?: string;
  setValue: UseFormSetValue<T>;
  style?: CSSProperties;
  trigger: UseFormTrigger<T>;
  watch: UseFormWatch<T>;
};

export const getDefaultValuesNewCondition = () => ({
  id: "",
  value: [],
  groupId: uuid(),
  operator: "",
  unit: {},
  type: "" as const,
});

export const getAreConditionsEmpty = (formValues?: {
  conditions?: ((Partial<Omit<ConditionsT[0], "value">> & { value?: (string | undefined)[] }) | undefined)[];
}) =>
  formValues?.conditions?.length === 1 &&
  formValues?.conditions?.[0]?.value?.length === 0 &&
  formValues?.conditions?.[0].operator === "" &&
  isEmpty(formValues?.conditions?.[0].unit);

export const getDefaultConditions = ({
  conditions,
  selectOptionsUnit,
}: {
  conditions: ConditionT[];
  selectOptionsUnit: { label: string; type: MetricOrDimensionT; value: string }[];
}) =>
  conditions.length
    ? conditions.map((condition) => {
        return {
          id: condition.id || "",
          value: condition.values || [],
          groupId: condition.groupId || "",
          operator: condition.metricOperator || condition.dimensionOperator || "",
          unit:
            selectOptionsUnit.find(
              (option) => option.value === condition.dimension?.id || option.value === condition.metric?.id
            ) || {},
          type: condition.metric
            ? ("metric" as MetricOrDimensionT)
            : condition.dimension
            ? ("dimension" as MetricOrDimensionT)
            : ("" as MetricOrDimensionT),
        };
      })
    : [getDefaultValuesNewCondition()];

export const FormConditionsList = <T extends ConditionsFormT>({
  append,
  conditions,
  control,
  dimensions,
  dimensionsValueCounts,
  errors,
  fields,
  insert,
  metrics,
  onChange,
  onGetValueCounts,
  onSetIsConditionEmpty,
  remove,
  setValue,
  style,
  trigger,
  watch,
}: PropsT<T>) => {
  const handleAddCondition = (groupId?: string, index?: number) => {
    if (groupId && index) {
      insert(index, { ...getDefaultValuesNewCondition(), groupId });
    } else {
      append(getDefaultValuesNewCondition());
    }
  };

  const handleRemoveGroup = (groupId: string) => {
    const indexes = map(keys(pickBy(fields, { groupId })), Number);
    remove(indexes);
  };

  useEffect(() => {
    const subscription = watch(async (values, { name }) => {
      if (name !== "conditions" && name?.startsWith("conditions")) {
        await trigger(name?.match(/conditions\.[0-9]/) as unknown as Path<T>);
      }

      if (onChange) {
        const clearedConditions = (values.conditions || []).filter(
          (condition, index) =>
            !errors?.conditions?.[index] &&
            !(
              condition?.operator === "" &&
              (condition.value?.length === 0 || (condition.value?.length === 1 && condition.value[0] === "")) &&
              JSON.stringify(condition.unit) === "{}"
            )
        );
        onChange({
          conditions:
            clearedConditions.length && !getAreConditionsEmpty({ conditions: clearedConditions })
              ? clearedConditions
              : [],
        } as ConditionsFormT);
      }

      if (values.conditions?.length === 0) {
        append(getDefaultValuesNewCondition());
      }

      if (onSetIsConditionEmpty) {
        onSetIsConditionEmpty(getAreConditionsEmpty(values));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, append, trigger, onChange, errors, onSetIsConditionEmpty]);

  return (
    <div style={style}>
      {[...fields].map((field, index) => {
        const isGroupStart = field.groupId != fields[index - 1]?.groupId;
        const isGroupEnd = field.groupId != fields[index + 1]?.groupId;
        const isLast = fields.length === index + 1;
        const isAlone = fields.filter((fieldsItem) => fieldsItem.groupId === field.groupId).length < 2;

        return (
          <FormConditionsItem
            key={field.id}
            control={control}
            dimensions={dimensions}
            dimensionsValueCounts={dimensionsValueCounts}
            error={errors?.conditions?.[index]}
            field={field}
            index={index}
            isAlone={isAlone}
            isFormEmpty={getAreConditionsEmpty({ conditions })}
            isGroupEnd={isGroupEnd}
            isGroupStart={isGroupStart}
            isLast={isLast}
            metrics={metrics}
            watch={watch}
            onAddCondition={handleAddCondition}
            onGetValueCounts={onGetValueCounts}
            onRemoveCondition={remove}
            onRemoveGroup={handleRemoveGroup}
            onSetValue={setValue}
          />
        );
      })}

      <div className="mt-16 d-flex justify-content-center">
        <ButtonSecondary onClick={() => handleAddCondition()}>AND</ButtonSecondary>
      </div>
    </div>
  );
};
