import React, { ForwardedRef } from "react";
import { CusotmIconBasicPropsT } from "./IconCustom";

export const CheckCircleSmIcon = React.forwardRef(
  ({ className, size, ...rest }: CusotmIconBasicPropsT, ref: ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.5559 7.97343C15.0806 7.48943 14.9746 6.7541 15.2939 6.15543C15.5219 5.72876 15.5379 5.22143 15.3366 4.78076C15.1359 4.34076 14.7412 4.01943 14.2692 3.91276C13.6086 3.76276 13.1212 3.20143 13.0672 2.52543C13.0279 2.04343 12.7666 1.60676 12.3592 1.34543C11.9532 1.08343 11.4479 1.02676 10.9926 1.19276C10.3552 1.4241 9.6419 1.2141 9.23123 0.67543C8.9379 0.29143 8.4819 0.0654297 7.9979 0.0654297C7.51457 0.0654297 7.05857 0.29143 6.76523 0.67543C6.35657 1.21476 5.64323 1.42476 5.0059 1.19343C4.55123 1.0281 4.0459 1.08476 3.63923 1.3461C3.23257 1.60743 2.97057 2.0441 2.9319 2.5261C2.87723 3.2021 2.39057 3.7641 1.7299 3.91343C1.2579 4.0201 0.863232 4.34143 0.662565 4.78143C0.461899 5.22143 0.477899 5.72943 0.705899 6.15543C1.02523 6.75343 0.919232 7.48943 0.444565 7.97343C0.105232 8.3181 -0.0534346 8.80143 0.0152321 9.2801C0.0838987 9.75876 0.372565 10.1781 0.795232 10.4134C1.38723 10.7434 1.69657 11.4201 1.5579 12.0834C1.4599 12.5568 1.58723 13.0488 1.9039 13.4141C2.22123 13.7801 2.69057 13.9768 3.1719 13.9461C3.84923 13.9041 4.4739 14.3061 4.71657 14.9388C4.88923 15.3908 5.26323 15.7354 5.7279 15.8714C6.19123 16.0074 6.69257 15.9194 7.0819 15.6334C7.6279 15.2314 8.37257 15.2314 8.9179 15.6334C9.30723 15.9201 9.80857 16.0081 10.2719 15.8714C10.7366 15.7354 11.1106 15.3901 11.2839 14.9388C11.5259 14.3061 12.1512 13.9034 12.8279 13.9461C13.3106 13.9768 13.7792 13.7801 14.0959 13.4141C14.4126 13.0488 14.5406 12.5568 14.4419 12.0834C14.3039 11.4201 14.6126 10.7434 15.2052 10.4134C15.6272 10.1774 15.9152 9.75876 15.9846 9.2801C16.0539 8.80143 15.8946 8.3181 15.5559 7.97343Z"
        fill="#63BC26"
      />
      <path
        d="M4.14579 8.39846L6.85728 11.028C6.93624 11.1046 7.06221 11.103 7.13926 11.0245L11.8612 6.21315C11.9381 6.13484 11.9375 6.00923 11.8599 5.93164L10.9052 4.97692C10.8264 4.89813 10.6984 4.89892 10.6206 4.97867L7.13769 8.54861C7.06133 8.62688 6.93627 8.62931 6.85692 8.55408L5.37932 7.15307C5.30067 7.0785 5.17693 7.08014 5.10029 7.15678L4.14361 8.11347C4.06465 8.19242 4.06564 8.32073 4.14579 8.39846Z"
        fill="white"
      />
    </svg>
  )
);
