import { FakeDataStateT } from "../../../functions/sourceSystemsHelper";
import {
  CurrencyT,
  DateRangeT,
  Maybe,
  PageT,
  ReportPageQueryT,
  SharedReportT,
  SourceSystemT,
} from "../../../graphql/generated/graphql";
import { ConditionsT, ReportBoardSectionT, ReportBoardSectionsT, ReportT } from "../../../types/widgets";

export type PageUpdateParamsT = {
  pageId: PageT["id"];
  pageInputData: Partial<ReportBoardPageT> | ((page: ReportBoardPageT) => ReportBoardPageT);
};

export type ReportFilterUpdateT = (filterValues: Partial<ReportBoardReducerStateT["reportFilter"]>) => void;
export type PageAddT = (
  name: string,
  options: { callback: (params: { id: string; name: string; uuid: string }) => void }
) => void;
export type PageDuplicateT = (
  params: { newName: string; pageId: string },
  options: { callback: (params: { id: string; name: string; uuid: string }) => void }
) => void;
export type PageUpdateT = ({ pageId, pageInputData }: PageUpdateParamsT) => void;
export type PageDeleteT = (pageId: string) => void;
export type ReportResetT = () => void;
export type ReportApiUpdateT = () => void;
export type ReportFilterCloseT = () => void;
export type ReportFilterOpenT = () => void;
export type DimensionsMetricsHydrateFromApiT = ({
  dimensions,
  metrics,
}:
  | { dimensions?: WidgetSettingsDataT["dimensions"]; metrics?: never }
  | { dimensions?: never; metrics?: WidgetSettingsDataT["metrics"] }) => void;

export type ReportContextActionsT = {
  dimensionsMetricsHydrateFromApi: DimensionsMetricsHydrateFromApiT;
  pageAdd: PageAddT;
  pageDelete: PageDeleteT;
  pageDuplicate: PageDuplicateT;
  pageUpdate: PageUpdateT;
  reportApiUpdate: ReportApiUpdateT;
  reportFilterClose: ReportFilterCloseT;
  reportFilterOpen: ReportFilterOpenT;
  reportFilterUpdate: ReportFilterUpdateT;
  reportReset: ReportResetT;
  setFetchingNewConnectedSystemsData: (value: boolean) => void;
  setLastFetchedConnectedSystemsData: (value: Date) => void;
};

export enum ReportBoardActionsT {
  PAGE_ADD = "PAGE_ADD",
  PAGE_DELETE = "PAGE_DELETE",
  PAGE_UPDATE = "PAGE_UPDATE",
  REPORT_FILTER_UPDATE = "REPORT_FILTER_UPDATE",
  SOURCE_SYSTEMS_UPDATE = "SOURCE_SYSTEMS_UPDATE",
  UPDATE = "UPDATE",
}

export type QueryReportT = NonNullable<NonNullable<ReportPageQueryT["organization"]>["report"]>;
export type ReportDimensionT = QueryReportT["dimensions"]["nodes"][number];
export type ReportMetricT = QueryReportT["metrics"]["nodes"][number];

export type MetricsByIdT = { [key: string]: ReportMetricT };
export type DimensionsByIdT = { [key: string]: ReportDimensionT };

export type WidgetSettingsDataT = {
  connectedSystems: Pick<SourceSystemT, "externalId" | "name" | "id">[];
  dimensions: ReportDimensionT[];
  dimensionsById: DimensionsByIdT;
  metrics: ReportMetricT[];
  metricsById: MetricsByIdT;
};

export type ReportOptionsT = {
  canEdit: boolean;
  fakeDataStatus?: FakeDataStateT;
};

export type ReportBoardPageT = Pick<PageT, "id" | "name" | "uuid" | "shared"> & {
  isNew?: boolean;
  sections: ReportBoardSectionsT;
};

export type ReportBoardSharedReportT = Pick<
  SharedReportT,
  "id" | "allPages" | "discarded" | "name" | "token" | "createdAt" | "hasPassword"
> & {
  pages: { nodes: Pick<PageT, "id" | "name" | "discarded" | "shared">[] };
};

export type StateReportT = Pick<
  ReportT,
  "conditions" | "currency" | "dateRange" | "id" | "name" | "sourceSystems" | "pages"
> & {
  dimensions: {
    nodes: ReportDimensionT[];
  };
  metrics: {
    nodes: ReportMetricT[];
  };
};

export type ReportBoardReducerStateT = {
  fetchingNewConnectedSystemsData: boolean;
  hasReportUnsavedChanges: boolean;
  isReportFilterOpen: boolean;
  lastFetchedConnectedSystemsData: null | Date;
  pagesData: ReportBoardPageT[];
  report: StateReportT;
  reportFilter: {
    conditions: ConditionsT;
    currency: CurrencyT;
    dateRange?: Maybe<DateRangeT>;
  };
  selectedMetDims: {
    dimensions: string[];
    metrics: string[];
  };
  wantedSectionPosition: number;
  widgetSettingsData: WidgetSettingsDataT;
};

export type ReportReducerActionT =
  | ({ type: typeof ReportBoardActionsT.PAGE_ADD } & {
      newPage: {
        id: PageT["id"];
        name: string;
        sections: ReportBoardSectionT[];
        uuid: string;
      };
    })
  | ({ type: typeof ReportBoardActionsT.PAGE_UPDATE } & PageUpdateParamsT)
  | ({ type: typeof ReportBoardActionsT.PAGE_DELETE } & { pageId: string })
  | ({ type: typeof ReportBoardActionsT.REPORT_FILTER_UPDATE } & {
      data: { reportFilter: Partial<ReportBoardReducerStateT["reportFilter"]> };
    })
  | ({ type: typeof ReportBoardActionsT.SOURCE_SYSTEMS_UPDATE } & {
      sourceSystems: Pick<SourceSystemT, "id" | "externalId" | "name" | "externalName">[];
    })
  | ({ type: typeof ReportBoardActionsT.UPDATE } & { data: Partial<ReportBoardReducerStateT> });
