import { createContext, useContext } from "react";
import { ModalPropsT } from "./Modal";

const ModalContext = createContext<null | Partial<ModalPropsT>>(null);

const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(`Modal compound components cannot be rendered outside the Modal component`);
  }
  return context;
};

export { useModalContext, ModalContext };
