import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { notifyError, notifySucces } from "../functions/toast";
import {
  CustomMetricsDocumentT,
  useCustomMetricQuery,
  useUpdateCustomMetricMutation,
} from "../graphql/generated/graphql";
import { useAppSettings } from "../providers/appSettingsProvider";
import { RouteParamsT } from "../types/router";
import { Modal } from "../ui/Modal";
import MetricEditModalContent from "./MetricEditModalContent";

type PropsT = { metricId?: string; onClose: () => void };

export const MetricEditModal = ({ metricId, onClose }: PropsT) => {
  const [serverErrors, setServerErrors] = useState<string[] | undefined>(undefined);

  const {
    organization: { externalId },
  } = useAppSettings();

  const { data, loading } = useCustomMetricQuery({
    fetchPolicy: "network-only",
    variables: {
      organizationExternalId: externalId,
      customMetricId: metricId || "",
    },
    skip: !metricId,
  });

  const [mutate, { loading: mutationLoading }] = useUpdateCustomMetricMutation({
    refetchQueries: [CustomMetricsDocumentT],
    onCompleted: (res) => {
      const errors = res.organization?.updateCustomMetric?.errors;

      if (errors?.length) {
        return setServerErrors(errors);
      }
      notifySucces(<>{`Custom metric updated!`}</>);
      onClose();
    },
    onError: () => notifyError(<>{`Server error when updating custom metric!`}</>),
  });
  return (
    <Modal close={onClose} heading="Edit custom metric" testId="edit-custom-metric-modal" isOpen>
      <MetricEditModalContent
        data={data}
        isModalLoading={loading}
        isMutationLoading={mutationLoading}
        serverErrors={serverErrors}
        onSubmit={(values) =>
          mutate({
            variables: {
              ...values,
              id: metricId || "",
              organizationExternalId: externalId,
            },
          })
        }
      />
    </Modal>
  );
};

export const MetricEditPage = () => {
  const { metricId } = useParams<RouteParamsT["metric"]>();
  const navigate = useNavigate();

  return <MetricEditModal metricId={metricId} onClose={() => navigate(ROUTES.metricsAndDimensions())} />;
};
