import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { KpiStatusT } from "../../graphql/generated/graphql";
import { useSearch } from "../../hooks/useSearch";
import { KpiListItemPropsT } from "../kpiList/KpiListItem";

export const useKpiFilter = () => {
  const [hasOnlyActive, setHasOnlyActive] = useState(false);
  const [status, setStatus] = useState("");
  const [priority, setPriority] = useState("");

  const filterSearchRows = useCallback((kpiSetting: KpiListItemPropsT["kpiSetting"]) => kpiSetting.name, []);
  const { filterData: filterSearchData, searchInputProps } =
    useSearch<KpiListItemPropsT["kpiSetting"][]>(filterSearchRows);

  const isFilterActive = hasOnlyActive || !!status || !!priority || !!searchInputProps.value;

  const filterData = useCallback(
    (kpiSettings: KpiListItemPropsT["kpiSetting"][]) => {
      return filterSearchData(kpiSettings).filter(
        (kpiSetting) =>
          (!hasOnlyActive || kpiSetting.status === KpiStatusT.ActiveT) &&
          (!status || status === kpiSetting.lastResult?.semaphore) &&
          (!priority || kpiSetting.priority === priority)
      );
    },
    [filterSearchData, hasOnlyActive, status, priority]
  );

  const activeInputProps = useMemo(
    () => ({
      onChange: () => setHasOnlyActive((prev) => !prev),
      checked: hasOnlyActive,
    }),
    [hasOnlyActive]
  );

  const priorityInputProps = useMemo(
    () => ({
      onChange: (event: ChangeEvent<HTMLSelectElement>) => setPriority(event.currentTarget.value),
      value: priority,
    }),
    [priority]
  );

  const statusInputProps = useMemo(
    () => ({
      onChange: (event: ChangeEvent<HTMLSelectElement>) => setStatus(event.currentTarget.value),
      value: status,
    }),
    [status]
  );

  return useMemo(
    () => ({
      filterData,
      isFilterActive,
      activeInputProps,
      priorityInputProps,
      searchInputProps,
      statusInputProps,
    }),
    [filterData, searchInputProps, activeInputProps, priorityInputProps, isFilterActive, statusInputProps]
  );
};
