/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ButtonHTMLAttributes, CSSProperties, ForwardedRef, MouseEvent, ReactNode, forwardRef } from "react";
import cs from "classnames";
import { Link } from "react-router-dom";
import { SystemNameT } from "../../graphql/generated/graphql";
import { Loader } from "../Loader/Loader";
import { SystemIcon } from "../SystemIcon/SystemIcon";

export const BUTTON_VARIANTS = [
  "grayTable",
  "greenTable",
  "primary",
  "red",
  "primaryTable",
  "redTable",
  "secondary",
  "secondaryRed",
  "secondaryGray",
  "tertiary",
] as const;

export const BUTTON_SIZES = ["small", "medium", "large"] as const;

const SYSTEM_ICON_SIZE = { small: "12px", medium: "14px", large: "16px" };

type ButtonSizesT = typeof BUTTON_SIZES[number];
export type ButtonVariantsT = typeof BUTTON_VARIANTS[number];

type ButtonPropsBaseT = {
  block?: boolean;
  className?: string;
  disabled?: boolean;
  hasFullWidth?: boolean;
  isExternal?: boolean;
  isExternalLink?: boolean;
  isRealHref?: boolean;
  link?: string;
  loading?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  size?: ButtonSizesT;
  style?: CSSProperties;
  to?: string | -1;
} & {
  children?: ReactNode;
  hasIconAfterText?: boolean;
  onlyIcon?: boolean;
  text?: string;
  withoutVariant?: boolean;
} & ({ icon?: string; systemIcon?: never } | { icon?: never; systemIcon?: SystemNameT }) &
  ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonPropsT = ButtonPropsBaseT & { variant?: ButtonVariantsT };

type Ref = ForwardedRef<HTMLButtonElement>;

type ComponentPropsT<T extends keyof JSX.IntrinsicElements> = { tag: T } & JSX.IntrinsicElements[T] & {
    to?: string | -1;
  };

declare function ButtonFn<Tag extends "a" | "button">(props: ComponentPropsT<Tag>): JSX.Element;

export const Component = forwardRef<HTMLElement, ComponentPropsT<any>>(
  ({ isExternalLink, isRealHref, tag: Tag, ...props }, ref) => {
    if (isRealHref) {
      const { href, to, ...linkProps } = props;
      return <a ref={ref} {...linkProps} href={to || href} {...(isExternalLink ? { target: "_blank" } : {})} />;
    }
    if (props.to) {
      return <Link ref={ref} {...props} {...(isExternalLink ? { target: "_blank" } : {})} />;
    }
    return <Tag ref={ref} {...props} />;
  }
) as typeof ButtonFn;

export const Button = forwardRef(
  (
    {
      block,
      children,
      className,
      disabled,
      hasFullWidth,
      hasIconAfterText,
      icon,
      isExternal = false,
      link,
      loading,
      onClick,
      onlyIcon,
      size = "medium",
      style,
      systemIcon,
      text,
      to,
      variant = "secondary",
      withoutVariant,
      ...rest
    }: ButtonPropsT,
    ref: Ref
  ) => {
    const buttonVariant = `Button--${variant}`;
    const buttonSize = `Button--${size}`;
    const Tag = link || to ? "a" : "button";

    const handleClick = (event: MouseEvent<HTMLElement>) => {
      if (typeof onClick === "function") {
        event.preventDefault();
        onClick(event);
        return false;
      }
      return true;
    };

    return (
      <Component
        {...rest}
        ref={ref}
        disabled={disabled}
        href={link}
        role={Tag === "a" ? "link" : Tag}
        style={{ ...style }}
        tag={link ? "a" : "button"}
        target={isExternal ? "_blank" : undefined}
        to={to}
        className={cs("Button", buttonSize, className, {
          [`${buttonVariant}`]: !withoutVariant,
          [`fc-${icon}`]: icon,
          "Button--onlyIcon": onlyIcon,
          "Button--block w-100": block,
          "pos-relative btn-no-action": loading,
          "Button--full": hasFullWidth,
          "Button--iconPositionRight": hasIconAfterText,
        })}
        onClick={handleClick}
      >
        {loading && <Loader size="small" absolute />}

        {onlyIcon ? null : (
          <span className="d-inline-block">
            {systemIcon && (
              <SystemIcon className="mr-8 vam line-height-1" size={SYSTEM_ICON_SIZE[size]} system={systemIcon} />
            )}
            {text}
            {children}
          </span>
        )}
      </Component>
    );
  }
);

export const ButtonGrayTable = forwardRef((props: ButtonPropsBaseT, ref: Ref) => (
  <Button {...props} ref={ref} variant="grayTable" />
));
export const ButtonGreenTable = forwardRef((props: ButtonPropsBaseT, ref: Ref) => (
  <Button {...props} ref={ref} variant="greenTable" />
));
export const ButtonPrimary = forwardRef((props: ButtonPropsBaseT, ref: Ref) => (
  <Button {...props} ref={ref} variant="primary" />
));
export const ButtonPrimaryTable = forwardRef((props: ButtonPropsBaseT, ref: Ref) => (
  <Button {...props} ref={ref} variant="primaryTable" />
));
export const ButtonRedTable = forwardRef((props: ButtonPropsBaseT, ref: Ref) => (
  <Button {...props} ref={ref} variant="redTable" />
));
export const ButtonSecondary = forwardRef((props: ButtonPropsBaseT, ref: Ref) => (
  <Button {...props} ref={ref} variant="secondary" />
));
export const ButtonPrimaryRed = forwardRef((props: ButtonPropsBaseT, ref: Ref) => (
  <Button {...props} ref={ref} variant="red" />
));
export const ButtonSecondaryRed = forwardRef((props: ButtonPropsBaseT, ref: Ref) => (
  <Button {...props} ref={ref} variant="secondaryRed" />
));
export const ButtonTertiary = forwardRef((props: ButtonPropsBaseT, ref: Ref) => (
  <Button {...props} ref={ref} variant="tertiary" />
));
