import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Responsive, ResponsiveProps, WidthProvider } from "react-grid-layout";
import { REPORT_BOARD_LAYOUT_BREAKPOINTS, REPORT_BOARD_LAYOUT_COLUMNS } from "../../constants/report";
import { getGridLayout, getGridLayoutPropertiesOfItems } from "../../functions/gridLayoutHelpers";
import { ReportBoardSectionsT, SectionT } from "../../types/widgets";
import { useReportBoardContext } from "./context/reportBoardContext";
import { PageContextT } from "./ReportBoardPage";
import { ReportBoardSection } from "./ReportBoardSection";

const ResponsiveGridLayout = WidthProvider(Responsive);

type PropsT = {
  pageContext: PageContextT;
  sections: ReportBoardSectionsT;
};

const ReportBoardSections = ({ pageContext, sections }: PropsT) => {
  const [heights, setHeights] = useState<{ [key: string]: number }>({});
  const { reportOptions } = useReportBoardContext();
  const [forceRenderCounter, setForceRenderCounter] = useState(0);
  const { sectionUpdate } = pageContext;

  const sectionsLayoutChange = useCallback<NonNullable<ResponsiveProps["onLayoutChange"]>>(
    (currentLayout) => {
      const orders = getGridLayoutPropertiesOfItems(currentLayout);
      orders.forEach((order) =>
        sectionUpdate({ sectionId: order?.itemId, sectionInputData: (prev) => ({ ...prev, position: order.order }) })
      );
    },
    [sectionUpdate]
  );

  const gridColumns = REPORT_BOARD_LAYOUT_COLUMNS;
  const gridBreakpoints = REPORT_BOARD_LAYOUT_BREAKPOINTS;
  const gridLayout = useMemo(
    () =>
      getGridLayout({
        items: sections
          ?.sort((a, b) => a.position - b.position)
          .map((section) => ({
            id: section.id,
            width: 1,
            height: heights[section.id] || 1,
          })),
        columns: gridColumns,
        breakpoints: gridBreakpoints,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sections?.length, heights]
  );

  const handleHeightChange = (sectionId: SectionT["id"], height: number) => {
    setHeights((prev) => ({ ...prev, [sectionId]: height }));
  };

  const handleWidthChange = useCallback(() => {
    setForceRenderCounter((prev) => prev + 1);
  }, []);

  useEffect(() => {
    window.addEventListener("onSidebarToggle", handleWidthChange);

    return () => {
      window.removeEventListener("onSidebarToggle", handleWidthChange);
    };
  });

  return (
    <ResponsiveGridLayout
      key={forceRenderCounter}
      breakpoints={gridBreakpoints}
      className="layout"
      cols={gridColumns}
      draggableHandle=".draggable-handle-section"
      isDraggable={reportOptions?.canEdit}
      isResizable={false}
      layouts={gridLayout}
      margin={[0, 0]}
      rowHeight={1}
      autoSize
      onLayoutChange={sectionsLayoutChange}
    >
      {sections.map((section, index) => (
        <div key={section.id}>
          <ReportBoardSection
            isLast={sections.length - 1 === index}
            pageContext={pageContext}
            section={section}
            onHeightChange={handleHeightChange}
          />
        </div>
      ))}
    </ResponsiveGridLayout>
  );
};

export default ReportBoardSections;
