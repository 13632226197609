import React from "react";
import { FieldError } from "react-hook-form";

type PropsT = {
  error?: Partial<FieldError>;
};

const ErrorMessage = ({ error }: PropsT) => {
  if (!error?.message) {
    return null;
  }

  return (
    <span className="Input-errorLabel" data-test-id="field-error-message" role="alert">
      {error?.message}
    </span>
  );
};

export default ErrorMessage;
