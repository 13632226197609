import { Link, useNavigate } from "react-router-dom";
import { useConfirmationContext } from "../components/confirmationDialog/confirmationDialogContext";
import { ROUTES } from "../constants/routes";
import { CustomValueMetricsQueryT, MetricDataT } from "../graphql/generated/graphql";
import { DropdownMenu } from "../ui/Dropdown/DropdownMenu";
import { Ellipsis } from "../ui/Ellipsis/Ellipsis";
import { Col, Row } from "../ui/grid/Grid";
import { Icon } from "../ui/Icon/Icon";
import { Td, TrBody } from "../ui/Table/Table";

export type CustomValueMetricT = NonNullable<
  CustomValueMetricsQueryT["organization"]
>["customValueMetrics"]["nodes"][number];
type PropsT = {
  customValueMetric: CustomValueMetricT;
  onDelete: () => void;
};

export const ValueMetricTableRow = ({ customValueMetric, onDelete }: PropsT) => {
  const { callWithConfirmation } = useConfirmationContext();

  const navigate = useNavigate();

  return (
    <TrBody key={customValueMetric.id}>
      <Td>
        <Row alignItems="center">
          <Col type="grow">
            <Link to={ROUTES.valueMetricEdit(customValueMetric.id)}>{customValueMetric.name}</Link>
          </Col>
          <Col type="shrink">
            <DropdownMenu
              buttonProps={{ variant: "tertiary" }}
              testId={`Dropdown--${customValueMetric.name}`}
              collection={[
                {
                  value: "Entered Values",
                  icon: "all-analytics",
                  onClick: () => navigate(ROUTES.valueMetricValues(customValueMetric.id)),
                },
                {
                  value: "Edit custom value metric",
                  icon: "edit",
                  onClick: () => navigate(ROUTES.valueMetricEdit(customValueMetric.id)),
                },
                {
                  value: "Duplicate custom value metric",
                  icon: "duplicate",
                  onClick: () => navigate(ROUTES.valueMetricDuplicate(customValueMetric.id)),
                },
                {
                  color: "danger",
                  icon: "trash",
                  isDisabled: !!customValueMetric?.referencesCount,
                  onClick: () => {
                    callWithConfirmation(onDelete, {
                      description: `Are you sure you want to delete this custom metric: "${customValueMetric.name}"?`,
                      title: "Delete custom metric",
                      testId: `delete-custom-metric-${customValueMetric.id}`,
                    });
                  },
                  tooltipContent: !!customValueMetric?.referencesCount
                    ? "This metric cannot be deleted because it is used somewhere else."
                    : undefined,
                  value: "Delete custom value metric",
                },
              ]}
            />
          </Col>
        </Row>
      </Td>
      <Td>
        <Ellipsis>{customValueMetric.description}</Ellipsis>
      </Td>
      <Td>{customValueMetric.aggregationFunction}</Td>
      <Td>{customValueMetric.dataType}</Td>
      <Td>{customValueMetric.dataType === MetricDataT.MoneyT ? customValueMetric.defaultCurrency : ""}</Td>
      <Td>{customValueMetric.referencesCount}</Td>
      <Td>
        <Row alignItems="center" type="shrink">
          <Col>
            <Icon color="#8C969F" kind="user" size="20px" />
          </Col>
          <Col>{customValueMetric.user?.fullname}</Col>
        </Row>
      </Td>
    </TrBody>
  );
};
