import { KpiPriorityT, KpiStatusT } from "../graphql/generated/graphql";

export const ORDERED_KPI_PRIORITIES = [
  KpiPriorityT.ContractedT,
  KpiPriorityT.HighT,
  KpiPriorityT.MediumT,
  KpiPriorityT.LowT,
  KpiPriorityT.InternalT,
];

export const KPI_ACTIVE_STATUSES = [KpiStatusT.ActiveT, KpiStatusT.ScheduledT];

export const ORDERED_KPI_STATUSES = [KpiStatusT.DraftT, KpiStatusT.FinishedT];

export const KPI_PRIORITY_PROPS = {
  [KpiPriorityT.HighT]: {
    color: "#FF264A",
    background: "#FFEFEE",
    border: "#F0C9C6",
  },
  [KpiPriorityT.MediumT]: {
    color: "#F59E0B",
    background: "#FFF8DD",
    border: "#E4D493",
  },
  [KpiPriorityT.LowT]: {
    color: "#63BC26",
    background: "#E7F9E4",
    border: "#BEE1B8",
  },
  [KpiPriorityT.ContractedT]: {
    color: "#0085FF",
    background: "#E7F3FF",
    border: "#B7DBFF",
  },
  [KpiPriorityT.InternalT]: {
    color: "#A7A7A7",
    background: "#F8F8F8",
    border: "#DADADA",
  },
};
