import React, { ForwardedRef } from "react";
import { CusotmIconBasicPropsT } from "./IconCustom";

export const ErrorHexIcon = React.forwardRef(
  ({ className, size, ...rest }: CusotmIconBasicPropsT, ref: ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 25 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2.3577 6.14434L12.5 0.288675L22.6423 6.14434V17.8557L12.5 23.7113L2.3577 17.8557V6.14434Z"
        fill="#D9D9D9"
        stroke="url(#paint0_linear_5559_109508)"
        strokeWidth="0.5"
      />
      <g filter="url(#filter0_ddddd_5559_109508)">
        <path
          d="M11.467 13.7252C11.467 14.2966 11.9307 14.7604 12.5021 14.7604C13.0736 14.7604 13.5373 14.2966 13.5373 13.7252V6.13382C13.5373 5.5624 13.0736 5.09863 12.5021 5.09863C11.9307 5.09863 11.467 5.5624 11.467 6.13382V13.7252Z"
          fill="#EF292A"
        />
        <path
          d="M11.1191 17.5209C11.1191 18.2828 11.7375 18.9011 12.4994 18.9011C13.2613 18.9011 13.8796 18.2828 13.8796 17.5209C13.8796 16.759 13.2613 16.1406 12.4994 16.1406C11.7375 16.1406 11.1191 16.759 11.1191 17.5209Z"
          fill="#EF292A"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="17.8025"
          id="filter0_ddddd_5559_109508"
          width="4.76025"
          x="10.1191"
          y="4.09863"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_5559_109508" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="effect1_dropShadow_5559_109508" mode="normal" result="effect2_dropShadow_5559_109508" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
          <feBlend in2="effect2_dropShadow_5559_109508" mode="normal" result="effect3_dropShadow_5559_109508" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
          <feBlend in2="effect3_dropShadow_5559_109508" mode="normal" result="effect4_dropShadow_5559_109508" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
          <feBlend in2="effect4_dropShadow_5559_109508" mode="normal" result="effect5_dropShadow_5559_109508" />
          <feBlend in="SourceGraphic" in2="effect5_dropShadow_5559_109508" mode="normal" result="shape" />
        </filter>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_5559_109508"
          x1="12.5"
          x2="12.5"
          y1="0"
          y2="24"
        >
          <stop stopColor="#F9F9F9" />
          <stop offset="1" stopColor="#B1B1B1" />
        </linearGradient>
      </defs>
    </svg>
  )
);
