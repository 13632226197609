import { object, string } from "yup";
import { VALIDATIONS } from "../../constants/validations";

export const formSharingLinkSchema = object({
  name: string().required(VALIDATIONS.required),
  password: string().when("showPassword", {
    is: true,
    then: string().required(VALIDATIONS.required),
  }),
  pageIds: object().test("required", "At least one page should be selected", (value, other) => {
    const isSomeChecked = Object.keys(value).some((key) => value[key]);

    return other.parent.allPages || isSomeChecked;
  }),
});
