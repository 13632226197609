import React, { FormEvent } from "react";
import { WidgetDateGroupingT } from "../../../graphql/generated/graphql";
import { WidgetRuleT, WidgetT } from "../../../types/widgets";
import Select from "../../../ui/forms/Select";
import { OnFormEditWidgetChangeT } from "../FormEditWidget";

type PropsT = {
  formKey: string;
  onChange: OnFormEditWidgetChangeT;
  rules?: WidgetRuleT;
  value: WidgetT["dateGrouping"];
};

const DATE_GROUPING_OPTIONS = [
  {
    label: "day",
    value: WidgetDateGroupingT.DayT,
  },
  {
    label: "week",
    value: WidgetDateGroupingT.WeekT,
  },
  {
    label: "month",
    value: WidgetDateGroupingT.MonthT,
  },
  {
    label: "quarter",
    value: WidgetDateGroupingT.QuarterT,
  },
  {
    label: "year",
    value: WidgetDateGroupingT.YearT,
  },
  {
    label: "all time",
    value: WidgetDateGroupingT.SummaryT,
  },
];

export const InputDateGrouping = ({ formKey, onChange, rules, value }: PropsT) => {
  const path = ["dateGrouping"];
  const pathString = path.join(".");

  const handleChange = (event: FormEvent<HTMLSelectElement>) => {
    const newValue = event.currentTarget.value;
    onChange((prev) => ({ ...prev, dateGrouping: newValue as WidgetDateGroupingT }));
  };

  const selectOptions = rules?.dateGrouping
    ? DATE_GROUPING_OPTIONS.filter((option) => rules?.dateGrouping?.includes(option.value as WidgetDateGroupingT))
    : DATE_GROUPING_OPTIONS;

  if (selectOptions.length <= 1) {
    return null;
  }

  return (
    <Select
      className="mb-16"
      collection={selectOptions}
      label="Group time by "
      selectProps={{ id: `${formKey}.${pathString}`, onChange: handleChange, value: value || "", name: pathString }}
    />
  );
};
