import { isEqual, subDays } from "date-fns";
import { DateRangeEnumT, DateRangeT } from "../graphql/generated/graphql";
import { dateRangesOptionsByKind } from "../ui/forms/dateRangePresets";

export const formatTime = (
  date: Date,
  options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }
) => {
  if (date) {
    const date2 = new Date(date);
    return date2.toLocaleDateString("cs-CZ", options);
  }
  return "-";
};

export const formatDate = (
  date: Date,
  options: Intl.DateTimeFormatOptions = { year: "numeric", month: "numeric", day: "numeric" }
) => {
  if (date) {
    const date2 = new Date(date);
    return date2.toLocaleDateString("en-UK", options);
  }
  return "-";
};

export const formatDateRange = (dateFrom: Date, dateTo?: Date) => {
  if (dateFrom && dateTo) {
    const dFrom = new Date(dateFrom);
    const dTo = new Date(dateTo);

    if (isEqual(dFrom, dTo)) {
      return formatDate(dateFrom);
    }

    if (dFrom.getFullYear() == dTo.getFullYear()) {
      if (dFrom.getMonth() == dTo.getMonth()) {
        if (dFrom.getDate() === 1 && dTo.getDate() === new Date(dTo.getFullYear(), dTo.getMonth() + 1, 0).getDate()) {
          return formatDate(dateFrom, { month: "long", year: "numeric" });
        }
        return `${formatDate(dateFrom, { day: "numeric" })} - ${formatDate(dateTo)}`;
      }
      return `${formatDate(dateFrom, { month: "numeric", day: "numeric" })} - ${formatDate(dateTo)}`;
    }
    return `${formatDate(dateFrom)} - ${formatDate(dateTo)}`;
  }

  return formatDate(dateFrom);
};

export const formatDateDaysRange = (fromDays: number, toDays: number) => {
  return `${fromDays} - ${toDays} days ago (${formatDateRange(
    subDays(new Date(), fromDays),
    subDays(new Date(), toDays)
  )})`;
};

export const formatDateRangeSelection = ({ from, fromDays, range, to, toDays }: DateRangeT) => {
  if (range === DateRangeEnumT.RangeCustomDaysT && typeof fromDays === "number" && typeof toDays === "number") {
    return formatDateDaysRange(fromDays, toDays);
  }

  if (range === DateRangeEnumT.RangeCustomT) {
    return formatDateRange(from, to);
  }

  return range ? dateRangesOptionsByKind[range]?.label : range;
};
