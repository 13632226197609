import React from "react";
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { CHART_LINE_COLORS, CHART_LINE_COLOR_OTHER } from "../../constants/report";
import {
  fetchColor,
  fetchDimensionValueFromRowAsText,
  fetchMetricCompareFromRow,
} from "../../functions/widgetDataHelpers";
import { WidgetRowMetricT } from "../../graphql/generated/graphql";
import { MetricsByIdT, ReportDimensionT } from "../reportBoard/context/types";
import { MetricInformationCard } from "./MetricInformationCard";

export const WidgetChartTooltipSummary = ({
  active,
  metricsById,
  payload,
  selectedDimensions,
}: TooltipProps<ValueType, NameType> & {
  metricsById: MetricsByIdT;
  selectedDimensions: ReportDimensionT[];
}) => {
  const data = payload?.[0];
  if (!data || !active) {
    return null;
  }

  return (
    <div className="Popover custom-tooltip " style={{ borderColor: `${CHART_LINE_COLOR_OTHER}` }}>
      {(payload?.[0].payload?.widgetMetrics || []).map((widgetRowMetric: WidgetRowMetricT, index: number) => {
        if (!widgetRowMetric?.metricId) {
          return null;
        }
        return (
          <MetricInformationCard
            key={`${widgetRowMetric.metricId}`}
            color={fetchColor({ color: CHART_LINE_COLORS[index], isCompare: false })}
            currency={payload?.[0].payload?.currency}
            dimensionValue={fetchDimensionValueFromRowAsText(selectedDimensions)(payload?.[0].payload)}
            metric={metricsById[widgetRowMetric?.metricId || ""]}
            previousValue={widgetRowMetric.previousValue}
            size="small"
            trend={widgetRowMetric?.trend}
            value={widgetRowMetric?.value as number}
            compareDate={fetchMetricCompareFromRow({ id: "XXX", metricId: widgetRowMetric.metricId })(
              payload?.[0].payload
            )}
          />
        );
      })}
    </div>
  );
};
