import React, { CSSProperties } from "react";
import img from "../../assets/dot_small.png";

type PropsT = {
  className?: string;
  size?: "sm";
  style?: CSSProperties;
};

const sizes = {
  sm: 13,
};

export const Dot = React.forwardRef<HTMLImageElement, PropsT>(({ className, size = "sm", style }: PropsT, ref) => (
  <img
    ref={ref}
    alt=""
    className={className}
    src={img}
    style={{ pointerEvents: "initial", width: `${sizes[size]}px`, height: `${sizes[size]}px`, ...style }}
  />
));
