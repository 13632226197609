import React, { useCallback } from "react";
import { notifyError, notifySucces, notifyWarning } from "../../functions/toast";
import { SystemNameT } from "../../graphql/generated/graphql";
import { Button, ButtonVariantsT } from "../../ui/Button/Button";

type ButtonConnectNewSystemWithPopoverPropsT = {
  accountAccessLink?: string;
  buttonText: string;
  buttonVariant: ButtonVariantsT;
  disabled: boolean;
  onSuccess: () => void;
  systemName?: SystemNameT;
};

const SECURITY_KEY = "QjCv5NDqoZ";

type StartPopverT = (args: {
  accountAccessLink?: string;
  onClose: () => void;
  onError: (errorMessage: string) => void;
  onSuccess: () => void;
}) => () => void;
const startOauthPopover: StartPopverT =
  ({ accountAccessLink, onClose, onError, onSuccess }) =>
  () => {
    const width = 600;
    const height = 900;
    const left = screen.width / 2 - width / 2;
    const top = screen.height / 2 - height / 2;
    let timer: NodeJS.Timer | null = null;
    const popup = window.open(
      `${accountAccessLink}&popup=true`,
      "oauth",
      `popup=1, toolbar=yes, scrollbars=yes, resizable=yes, width=${width}, height=${height}, top=${top}, left=${left}`
    );
    popup?.focus();

    function receiveMessageFromPopup(event: MessageEvent) {
      if (typeof event.data !== "string" || !event.data.startsWith(SECURITY_KEY)) {
        return;
      }
      const text = event.data.replace(SECURITY_KEY, "");

      if (text.startsWith("SUCCESS")) {
        if (typeof onSuccess === "function") {
          onSuccess();
        }
      }

      if (text.startsWith("ERROR: ")) {
        if (typeof onError === "function") {
          onError(text.replace("ERROR: ", ""));
        }
      }
      if (timer) {
        clearInterval(timer);
      }
      removeEventListener("message", receiveMessageFromPopup, false);
      popup?.close();
    }

    timer = setInterval(function () {
      if (popup?.closed) {
        removeEventListener("message", receiveMessageFromPopup, false);
        if (timer) {
          clearInterval(timer);
        }
        if (typeof onClose === "function") {
          onClose();
        }
      }
    }, 200);

    addEventListener("message", receiveMessageFromPopup, false);
  };

export const ButtonConnectNewSystemWithPopover = ({
  accountAccessLink,
  buttonText,
  buttonVariant,
  disabled,
  onSuccess,
  systemName,
}: ButtonConnectNewSystemWithPopoverPropsT) => {
  const onSuccessCallback = useCallback(() => {
    notifySucces(<>Linked new account</>);
    onSuccess();
  }, [onSuccess]);
  const onError = useCallback(
    (errorMessage?: string) => notifyError(<>{errorMessage || "Unable to connect account"}</>),
    []
  );
  const onClose = useCallback(() => notifyWarning(<>By closing OAUTH modal you have cancled linking process.</>), []);
  return (
    <Button
      disabled={disabled}
      systemIcon={systemName}
      type="button"
      variant={buttonVariant}
      onClick={startOauthPopover({ accountAccessLink, onSuccess: onSuccessCallback, onError, onClose })}
    >
      {buttonText}
    </Button>
  );
};
