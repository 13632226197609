import { formatPercents, numberWithThousandDelimiter } from "../../i18n/formatNumbers";
import { COLORS } from "./constants";
import { UsedByGraphPropsT } from "./UsedByGraph";

export const getData = ({
  name,
  total,
  value,
}: {
  name: "products" | "adSets";
  total: number;
  value: number;
}): Array<{ name: keyof typeof COLORS; value: number }> => {
  if (total === 0) {
    return [{ name: `unused_${name}`, value: 0 }];
  }

  if (value <= 0) {
    return [{ name: `unused_${name}`, value: total }];
  }

  if (value > total) {
    return [{ name: `used_${name}`, value: total }];
  }
  return [
    { name: `used_${name}`, value },
    { name: `unused_${name}`, value: total - value },
  ];
};

export const getTranslationText = ({
  adSets,
  adSetsTotal,
  products,
  productsTotal,
}: Pick<UsedByGraphPropsT, "adSets" | "adSetsTotal" | "products" | "productsTotal">) => {
  const adSetsPercent =
    typeof adSetsTotal === "number" && typeof adSets === "number"
      ? formatPercents(adSetsTotal > 0 ? adSets / adSetsTotal : 0)
      : "";
  const productsPercent =
    typeof productsTotal === "number" && typeof products === "number"
      ? formatPercents(productsTotal > 0 ? products / productsTotal : 0)
      : "";
  const formatedAdSets = typeof adSets === "number" ? numberWithThousandDelimiter(adSets) : "";
  const formatedAdSetsTotal = typeof adSetsTotal === "number" ? numberWithThousandDelimiter(adSetsTotal) : "";
  const formatedProducts = typeof products === "number" ? numberWithThousandDelimiter(products) : "";
  const formatedProductsTotal = typeof productsTotal === "number" ? numberWithThousandDelimiter(productsTotal) : "";

  const isAdSets = adSetsTotal && adSetsTotal >= 0;
  const isProducts = productsTotal && productsTotal >= 0;

  if (isAdSets && isProducts) {
    return `Ad Sets ${formatedAdSets} from ${formatedAdSetsTotal} (${adSetsPercent}) Products ${formatedProducts} from ${formatedProductsTotal} (${productsPercent})`;
  }

  if (isAdSets) {
    return `Ad Sets ${formatedAdSets} from ${formatedAdSetsTotal} (${adSetsPercent})`;
  }

  if (isProducts) {
    return `Matches ${formatedProducts} from ${formatedProductsTotal} (${productsPercent})`;
  }

  return null;
};
