import React, { useState } from "react";
import { notifySucces } from "../../functions/toast";
import { ReportBoardSectionT } from "../../types/widgets";
import { ButtonPrimary, ButtonSecondary } from "../../ui/Button/Button";
import { Modal, ModalBody, ModalFooter } from "../../ui/Modal";
import { UseModalReturnT } from "../../ui/Modal/useModal";
import { SelectableList } from "../../ui/SelectableList/SelectableList";

type PropsT = UseModalReturnT & {
  onMove: (wantedSectionId: string) => void;
  sectionId: string;
  sections: Pick<ReportBoardSectionT, "id" | "name">[];
};

export const MoveToOtherSectionModal = ({ onMove, sectionId, sections: sectionsData, ...modalProps }: PropsT) => {
  const [selected, setSelected] = useState<PropsT["sections"][0] | null>(null);

  const handleSubmit = () => {
    if (!selected) {
      return;
    }
    onMove(selected.id);
    notifySucces(<>Widget moved to section: {selected.name}</>);
    modalProps.close();
  };

  const sectionItems = sectionsData.filter((section) => section.id !== sectionId);

  return (
    <Modal {...modalProps} heading="Move Widget to Section" size="small" testId="move-widget-to-section-modal">
      <ModalBody>
        <SelectableList items={sectionItems} selectedItemId={selected?.id} hasBorder onSelect={setSelected} />
      </ModalBody>
      <ModalFooter>
        <ButtonPrimary disabled={!selected} onClick={handleSubmit}>
          Move
        </ButtonPrimary>
        <ButtonSecondary onClick={modalProps.close}>Close</ButtonSecondary>
      </ModalFooter>
    </Modal>
  );
};
