import React from "react";
import { KpiRoadmap, KpiRoadmapPropsT } from "../../components/kpiRoadmap/KpiRoadmap";
import { useKpisRoadmapQuery } from "../../graphql/generated/graphql";
import { useAppSettings } from "../../providers/appSettingsProvider";

export const KpiRoadmapConnected = (rest: KpiRoadmapPropsT) => {
  const { organization } = useAppSettings();
  const { data } = useKpisRoadmapQuery({
    variables: {
      organizationExternalId: organization.externalId,
    },
  });

  return <KpiRoadmap {...rest} kpiSettingsWithPeriodResults={data?.organization?.kpiSettings?.nodes} />;
};
