import { Col, Row } from "../../ui/grid/Grid";
import { AliasDimensionsConnected } from "./AliasDimensionsConnected";
import { AliasMetricsConnected } from "./AliasMetricsConnected";

export const MetricsAndDimensionsAlias = () => {
  return (
    <div>
      <Row>
        <Col type="grow">
          <AliasDimensionsConnected />
        </Col>
      </Row>
      <Row>
        <Col type="grow">
          <AliasMetricsConnected />
        </Col>
      </Row>
    </div>
  );
};
