import React, { PropsWithChildren } from "react";
import { Ellipsis } from "../../ui/Ellipsis/Ellipsis";
import { Icon } from "../../ui/Icon/Icon";
import { Text } from "../../ui/Text/Text";

type PropsT = PropsWithChildren<{ icon?: string }>;

export const BreadcrumbItem = ({ children, icon }: PropsT) => {
  return (
    <div className="d-flex align-items-baseline">
      <div className="Navbar-slash">/</div>
      <div className="Navbar-item ph-0 ml-16" style={{ maxWidth: "344px" }}>
        {icon && <Icon className="mr-4" kind={icon} />}
        <Text bold isCaption>
          <Ellipsis isInline={false}>{children}</Ellipsis>
        </Text>
      </div>
    </div>
  );
};
