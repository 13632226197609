import { get, has, isEqual, omit } from "lodash";
import { v4 as uuid } from "uuid";
import {
  DimensionsByIdT,
  MetricsByIdT,
  ReportBoardReducerStateT,
  ReportDimensionT,
  ReportMetricT,
} from "../components/reportBoard/context/types";
import { DEFAULT_WIDGET_DIMENSION, DEFAULT_WIDGET_METRIC } from "../constants/form";
import { WIDGET_SCHEMA } from "../constants/report";
import {
  DateRangeEnumT,
  WidgetKindT as GeneratedWidgetKindT,
  MetricDataT,
  SystemNameT,
  WidgetCompareT,
  WidgetDateGroupingT,
  WidgetDimensionT,
  WidgetKindT,
  WidgetMetricT,
  WidgetRowT,
} from "../graphql/generated/graphql";
import { ReportFilterT, SourceSystemsT } from "../types/report";
import {
  EditedWidgetT,
  ReportBoardSectionT,
  ReportBoardSectionsT,
  ReportSettingsDataT,
  SectionT,
  WidgetT,
} from "../types/widgets";
import { getNameByIdFromArray } from "./getNameByIdFromArray";

// TODO - add test
export const getWidgetPropertyFormToApiInput = ({
  path,
  propertyValue,
}: {
  path?: string;
  propertyValue: string | object | (string | object | undefined)[] | undefined;
}) => {
  const getValue = (object: object) => {
    return get(object, "value");
  };

  if ((path?.includes("widgetDimensions") || path?.includes("widgetMetrics")) && typeof propertyValue === "object") {
    if (Array.isArray(propertyValue)) {
      return propertyValue.map((row) => {
        if (path?.endsWith("widgetMetrics") || path?.endsWith("widgetDimensions")) {
          const isMetric = path?.includes("widgetMetrics");
          const key = isMetric ? "metricId" : "dimensionId";
          return { ...(typeof row === "object" ? row : {}), [key]: has(row, key) ? get(row, key).value : "" };
        }
        return typeof row === "object"
          ? Object.keys(row).reduce((acc, item) =>
              typeof item === "object" && has(item, "value") ? getValue(item) : item
            )
          : propertyValue;
      });
    }

    if (has(propertyValue, "value")) {
      return getValue(propertyValue);
    }
  }
  return propertyValue;
};

export const updateSectionInSections = (
  sections: ReportBoardSectionsT,
  sectionId: SectionT["id"],
  callback: (section: ReportBoardSectionT) => ReportBoardSectionT
): ReportBoardSectionsT => {
  const other = sections.filter((section) => section.id !== sectionId);
  const edited = sections.find((section) => section.id === sectionId);
  const result = edited ? [...other, callback(edited)] : [...other];

  return result.sort((a, b) => (a.position || 0) - (b.position || 0));
};

type GetMetricsObjectsT = (
  metrics: WidgetRowT["widgetMetrics"],
  widgetSettingsMetrics: ReportSettingsDataT["metrics"]
) => { [key: string]: string | number | null };

export const getMetricsObjects: GetMetricsObjectsT = (metrics, widgetSettingsMetrics) => {
  if (!metrics) {
    return {};
  }
  return metrics.reduce(
    (acc, cur) => ({ ...acc, [getNameByIdFromArray(widgetSettingsMetrics)(cur.metricId)]: cur.value }),
    {}
  );
};

export const getUsedMetDims = ({
  sectionsData,
}: {
  sectionsData: ReportBoardSectionsT;
}): ReportBoardReducerStateT["selectedMetDims"] => {
  const metrics = new Set() as Set<string>;
  const dimensions = new Set() as Set<string>;

  sectionsData.forEach((section) => {
    section.widgets.nodes.forEach((widget) => {
      widget.widgetMetrics.forEach((metric) => metrics.add(metric.metricId));
      widget.widgetDimensions.forEach((dimension) => dimensions.add(dimension.dimensionId));
    });
  });

  return {
    metrics: Array.from(metrics),
    dimensions: Array.from(dimensions),
  };
};

export const getValidatedWidgetData = ({
  connectedSystems,
  dimensions,
  metrics,
  widget,
}: {
  connectedSystems: SourceSystemsT;
  dimensions: ReportDimensionT[];
  metrics: ReportMetricT[];
  widget: EditedWidgetT;
}) => {
  let updatedValues = {} as Partial<WidgetT>;
  const defaultProps = WIDGET_SCHEMA[widget.kind].defaultProps;
  const widgetRules = WIDGET_SCHEMA[widget.kind].rules;
  const hasAnalyticSystem = connectedSystems.some((system) => system.name === SystemNameT.GoogleAnalytics3T);
  const hasDefaultData = widget.hasDefaultData;
  const defaultWidgetMetricConnected = metrics
    .filter((metric) => metric.isConnected)
    .find((metric) =>
      WIDGET_SCHEMA[widget.kind].defaultWidgetMetrics?.[hasAnalyticSystem ? "analytics" : "other"].includes(metric.name)
    );
  const defaultWidgetMetric = metrics.find((metric) =>
    WIDGET_SCHEMA[widget.kind].defaultWidgetMetrics?.[hasAnalyticSystem ? "analytics" : "other"].includes(metric.name)
  );
  const defaultWidgetDimensionConnected = dimensions
    .filter((dimension) => dimension.isConnected)
    .find((dimension) =>
      WIDGET_SCHEMA[widget.kind].defaultWidgetDimensions?.[hasAnalyticSystem ? "analytics" : "other"].includes(
        dimension.name
      )
    );
  const defaultWidgetDimension = dimensions.find((dimension) =>
    WIDGET_SCHEMA[widget.kind].defaultWidgetDimensions?.[hasAnalyticSystem ? "analytics" : "other"].includes(
      dimension.name
    )
  );

  // Max rows
  const maxRows = widget?.maxRows;
  const maxRowsRules = widgetRules?.maxRows;

  if (maxRowsRules) {
    if ((maxRowsRules.min && !maxRows) || (maxRowsRules.min && maxRows && maxRows < maxRowsRules.min)) {
      updatedValues = { ...updatedValues, maxRows: maxRowsRules.min };
    }

    if (maxRowsRules.max && (!maxRows || maxRows > maxRowsRules.max)) {
      updatedValues = { ...updatedValues, maxRows: maxRowsRules.max };
    }
  }

  // Date grouping
  const dateGroupingRules = widgetRules?.dateGrouping;

  if (dateGroupingRules) {
    const dateGrouping = widget?.dateGrouping;

    if (!dateGroupingRules?.includes(dateGrouping)) {
      updatedValues = {
        ...updatedValues,
        dateGrouping: defaultProps?.dateGrouping || dateGroupingRules[0] || WidgetDateGroupingT.DayT,
      };
    }
  }

  // Campaign data columns
  const campaignDataColumns = widget.campaignDataColumns;
  const campaignDataColumnsRules = widgetRules?.campaignDataColumns;

  if (typeof campaignDataColumnsRules?.max === "number") {
    if (campaignDataColumns?.length > campaignDataColumnsRules.max) {
      updatedValues = {
        ...updatedValues,
        campaignDataColumns: campaignDataColumns.slice(0, campaignDataColumnsRules.max),
      };
    }
  }

  // WidgetMetrics
  if (hasDefaultData && (defaultWidgetMetricConnected?.id || defaultWidgetMetric?.id)) {
    updatedValues = {
      ...updatedValues,
      widgetMetrics: [
        {
          ...DEFAULT_WIDGET_METRIC,
          id: uuid(),
          metricId: defaultWidgetMetricConnected?.id || defaultWidgetMetric?.id || "",
        },
      ],
    };
  }

  // WidgetDimensions
  if (hasDefaultData && (defaultWidgetDimensionConnected?.id || defaultWidgetDimension?.id)) {
    updatedValues = {
      ...updatedValues,
      widgetDimensions: [
        {
          ...DEFAULT_WIDGET_DIMENSION,
          id: uuid(),
          dimensionId: defaultWidgetDimensionConnected?.id || defaultWidgetDimension?.id || "",
        },
      ],
    };
  }

  return {
    ...widget,
    ...(hasDefaultData ? defaultProps : {}),
    ...updatedValues,
  };
};

type GetWidgetDefaultDataParamsT = {
  connectedSystems: SourceSystemsT;
  dimensions: ReportDimensionT[];
  id: WidgetT["id"];
  kind?: WidgetKindT;
  metrics: ReportMetricT[];
  position: number;
};

export const getWidgetDefaultData = ({
  id,
  kind = GeneratedWidgetKindT.LinechartT,
  position,
  metrics,
  dimensions,
  connectedSystems,
}: GetWidgetDefaultDataParamsT): EditedWidgetT => {
  const defaultProps = WIDGET_SCHEMA[kind].defaultProps;

  const widgetData = {
    isNew: true,
    hasDefaultData: true,
    id,
    name: "",
    position,
    campaignDataColumns: [],
    compare: WidgetCompareT.NoneT,
    dateGrouping: defaultProps.dateGrouping || WidgetDateGroupingT.DayT,
    widgetDimensions: [],
    widgetMetrics: [],
    maxRows: null,
    showOther: true,
    kind: kind as WidgetKindT,
    properties: {
      ...defaultProps.properties,
      options: {
        width: 1,
        height: 1,
        ...defaultProps.properties?.options,
      },
    },
    conditions: [],
    sourceSystems: [],
  };

  return getValidatedWidgetData({ widget: widgetData, metrics, dimensions, connectedSystems });
};

export const filterDuplicatedWidgetMetrics = (widgetMetrics: WidgetMetricT[]) =>
  widgetMetrics
    .filter((metric) => metric.metricId)
    .filter(
      (metric, index, arr) => arr.findIndex((metric2) => isEqual(omit(metric2, ["id"]), omit(metric, ["id"]))) === index
    );

export const filterDuplicatedWidgetDimensions = (widgetDimensions: WidgetDimensionT[]) =>
  widgetDimensions.filter((dimension) => dimension.dimensionId);

export const getWidgetNamePlaceholder = ({
  dimensionsById,
  metricsById,
  widgetDimensions,
  widgetMetrics,
}: {
  dimensionsById?: DimensionsByIdT;
  metricsById?: MetricsByIdT;
  widgetDimensions?: WidgetDimensionT[];
  widgetMetrics?: WidgetMetricT[];
}) => {
  const metricsName = widgetMetrics?.reduce((acc, widgetMetric, index) => {
    const metName = metricsById?.[widgetMetric.metricId]?.name;
    return metName ? `${acc}${index === 0 ? "" : ", "}${metricsById[widgetMetric.metricId]?.name}` : acc;
  }, "");

  const dimensionsName = widgetDimensions?.reduce((acc, widgetDimension, index) => {
    const dimName = dimensionsById?.[widgetDimension.dimensionId]?.name;

    return dimName ? `${acc}${index === 0 ? "" : ", "}${dimensionsById[widgetDimension.dimensionId]?.name}` : acc;
  }, "");

  return `${metricsName}${dimensionsName ? ` by ${dimensionsName}` : ""}`;
};

export const normalizeMetricDataType = (metric: ReportMetricT) =>
  metric.dataType === MetricDataT.IntegerT ? MetricDataT.FloatT : metric.dataType;

export const getWidgetDataErrors = (widget: EditedWidgetT) => {
  const validDimesions = widget.widgetDimensions.filter((dimension) => dimension.dimensionId);
  const validMetrics = widget.widgetMetrics.filter((metric) => metric.metricId);
  const setupErrors = [];
  const rules = WIDGET_SCHEMA[widget.kind].rules;

  if (typeof rules.metrics?.min === "number" && validMetrics.length < rules.metrics.min) {
    setupErrors.push(`Widget needs at least ${rules.metrics.min} metrics.`);
  }
  if (typeof rules.dimensions?.min === "number" && validDimesions.length < rules.dimensions.min) {
    setupErrors.push(`Widget needs at least ${rules.dimensions.min} dimensions.`);
  }
  if (validDimesions.length === 0 && validMetrics.length === 0) {
    setupErrors.push(`Widget needs at least one metric or dimension.`);
  }
  return setupErrors;
};

type GetWidgetDataFilterT = (params: {
  reportFilter?: ReportBoardReducerStateT["reportFilter"];
  sectionFilter: ReportFilterT;
  widget: Pick<WidgetT, "dateRange" | "conditions">;
}) => ReportFilterT;

export const getWidgetDataFilter: GetWidgetDataFilterT = ({ reportFilter, sectionFilter, widget }) => ({
  dateRange:
    (widget.dateRange?.range ? widget.dateRange : null) ||
    (sectionFilter?.dateRange?.range ? sectionFilter.dateRange : null) ||
    (reportFilter?.dateRange?.range
      ? reportFilter.dateRange
      : { range: DateRangeEnumT.RangeLastMonthT, from: null, to: null }),
  conditions: [...(widget?.conditions || []), ...sectionFilter.conditions, ...(reportFilter?.conditions || [])],
  currency: reportFilter?.currency,
});
