import React, { CSSProperties, useState } from "react";
import cs from "classnames";
import { KPI_PRIORITY_PROPS, ORDERED_KPI_PRIORITIES } from "../../constants/kpi";
import { ROUTES } from "../../constants/routes";
import { KpiSemaphoreT, OrganizationT } from "../../graphql/generated/graphql";
import { formatPercents } from "../../i18n/formatNumbers";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { ButtonTertiary } from "../../ui/Button/Button";
import { Icon } from "../../ui/Icon/Icon";
import { Link } from "../../ui/Link/Link";
import { Td, TrBody } from "../../ui/Table/Table";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { KpiList } from "../kpiList/KpiList";
import { KpiListItemPropsT } from "../kpiList/KpiListItem";
import { KPI_SEMAPHORE_VARIANTS, KpiSemaphore } from "../kpiSemaphore/KpiSemaphore";

export type OrganizationKpiListItemPropsT = {
  onDeleteKpiSettings: (kpiSettingIds: string[]) => void;
  organization: Pick<OrganizationT, "id" | "name" | "kpiProgressSummary" | "externalId"> & {
    kpiSettings: {
      nodes: KpiListItemPropsT["kpiSetting"][];
    };
  };
};

export const KpiScoreValue = ({
  hasIndicator,
  score,
  style,
}: {
  hasIndicator?: boolean;
  score?: number | null;
  style?: CSSProperties;
}) => {
  if (typeof score !== "number") {
    return null;
  }

  const decimals = score < 0.01 ? 1 : 0;

  let color = KPI_SEMAPHORE_VARIANTS[KpiSemaphoreT.GreenT].color;
  if (score < 0.8) {
    color = KPI_SEMAPHORE_VARIANTS[KpiSemaphoreT.YellowT].color;
  }
  if (score < 0.6) {
    color = KPI_SEMAPHORE_VARIANTS[KpiSemaphoreT.RedT].color;
  }

  let icon = "chevron-up";
  if (score === 0) {
    icon = "line";
  }
  if (score <= 0) {
    icon = "chevron-down";
  }

  return (
    <Text style={{ ...style, color }} bold>
      {formatPercents(score, decimals)} {hasIndicator && <Icon kind={icon} size="11px" />}
    </Text>
  );
};

export const OrganizationKpiListItem = ({ onDeleteKpiSettings, organization }: OrganizationKpiListItemPropsT) => {
  const { getOrganizationAppUrl, organization: currentOrganization } = useAppSettings();
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const cellClassNames = isDetailVisible ? "border-none" : "";
  const overallScore = organization.kpiProgressSummary?.estimatedProgress;
  const orgLink = organization?.externalId ? `${getOrganizationAppUrl(organization?.externalId)}` : "";

  return (
    <>
      <TrBody hasHover={!isDetailVisible}>
        <Td className={cs("text-center", cellClassNames)}>
          <KpiScoreValue score={overallScore} />
        </Td>
        <Td className={cellClassNames}>
          {orgLink && currentOrganization.externalId !== organization.externalId ? (
            <Link isRealHref={!!orgLink} to={`${orgLink}${ROUTES.kpisForAllOrganizations()}`}>
              {organization.name}
            </Link>
          ) : (
            organization.name
          )}
        </Td>
        {ORDERED_KPI_PRIORITIES.map((priority) => (
          <Td key={priority} className={cellClassNames}>
            <div className="d-flex flex-gap-4 flex-wrap">
              {organization.kpiSettings.nodes
                .filter((kpiSetting) => kpiSetting.priority === priority)
                .map((kpiSetting) => {
                  return (
                    <div key={kpiSetting.id}>
                      <KpiSemaphore {...kpiSetting} result={kpiSetting.lastResult} size="sm" />
                    </div>
                  );
                })}
            </div>
          </Td>
        ))}

        <Td className={cellClassNames} width="50px">
          {currentOrganization.externalId === organization.externalId ? (
            <ButtonTertiary icon="plus" to={ROUTES.kpisNew()} onlyIcon />
          ) : (
            <ButtonTertiary icon="plus" link={`${orgLink}${ROUTES.kpisNew()}`} isExternalLink onlyIcon />
          )}
        </Td>
        <Td className={cellClassNames} width="50px">
          {!!organization.kpiSettings.nodes.length && (
            <ButtonTertiary
              icon={isDetailVisible ? "chevron-up" : "chevron-down"}
              onlyIcon
              onClick={() => setIsDetailVisible((prev) => !prev)}
            />
          )}
        </Td>
      </TrBody>
      {isDetailVisible && (
        <TrBody hasHover={false}>
          <Td colSpan={4 + Object.keys(KPI_PRIORITY_PROPS).length}>
            <Tile
              className="mt-4 mb-8"
              contentStyle={{ paddingTop: 0 }}
              style={{ background: "#fafafa", border: "none" }}
            >
              <KpiList
                kpiSettings={organization.kpiSettings.nodes}
                organizationExternalId={organization.externalId}
                isCreateBtnHidden
                isDelimiterHidden
                onDeleteKpiSettings={onDeleteKpiSettings}
              />
            </Tile>
          </Td>
        </TrBody>
      )}
    </>
  );
};
