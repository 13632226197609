import React, { useEffect, useMemo, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { addDays, startOfDay } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { KPI_PRIORITY_PROPS } from "../../constants/kpi";
import { getConditionsApiInputFormat } from "../../functions/conditions";
import { formatDate } from "../../functions/dateHelpers";
import { getKpiTargetEndOn } from "../../functions/kpisHelper";
import {
  CurrencyT,
  DataConsistencyT,
  KpiPeriodT,
  KpiPriorityT,
  KpiSettingCreateMutationVariablesT,
  KpiSettingT,
  MetricDataT,
  SourceSystemT,
  TargetInputT,
  ValueCategoryT,
  useKpiMetricsLazyQueryT,
  useOrganizationWidgetDataQuery,
} from "../../graphql/generated/graphql";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { ConditionT } from "../../types/widgets";
import { ActionBar } from "../../ui/ActionBar/ActionBar";
import { ButtonPrimary } from "../../ui/Button/Button";
import { DEFAULT_DATE, DateController } from "../../ui/forms/DateController";
import { InputController } from "../../ui/forms/InputController";
import { MetricOrDimensionT } from "../../ui/forms/MetricDimensionController";
import { SelectableDimensionT, SelectableMetricT } from "../../ui/forms/MetricDimensionSelectTabs";
import { Col, Row } from "../../ui/grid/Grid";
import { HeadingSection, HeadingSmall } from "../../ui/Heading/Heading";
import { InfoBox } from "../../ui/InfoBox/InfoBox";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { ConditionsT } from "../formConditions/FormConditions";
import { getAreConditionsEmpty, getDefaultConditions } from "../formConditions/FormConditionsList";
import { CURRENCY_OPTIONS } from "../formEditWidget/inputs/InputCurrency";
import { MetricSelect } from "../formEditWidget/inputs/InputWidgetMetricsItem";
import { parseFormConditions } from "../reportBoard/ReportFilter";
import { MetricInformationCard } from "../widgetComponents/MetricInformationCard";
import { FormKpiConnectedAccounts } from "./FormKpiConnectedAccounts";
import { FormKpiPeriods } from "./FormKpiPeriods";
import { KpiConditions } from "./KpiConditions";
import { formKpiSchema } from "./validations";

const PRIORITY_OPTIONS = [
  {
    label: "Low",
    value: KpiPriorityT.LowT,
    icon: "flag",
    iconColor: KPI_PRIORITY_PROPS[KpiPriorityT.LowT].color,
    description: "Widget description. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    label: "Medium",
    value: KpiPriorityT.MediumT,
    icon: "flag",
    iconColor: KPI_PRIORITY_PROPS[KpiPriorityT.MediumT].color,
    description: "Medium importance or relevance to an organization's overall goals and objectives.",
  },
  {
    label: "High",
    value: KpiPriorityT.HighT,
    icon: "flag",
    iconColor: KPI_PRIORITY_PROPS[KpiPriorityT.HighT].color,
    description: "High importance or relevance to an organization's overall goals and objectives.",
  },
  {
    label: "Internal",
    value: KpiPriorityT.InternalT,
    icon: "flag",
    iconColor: KPI_PRIORITY_PROPS[KpiPriorityT.InternalT].color,
    description: "Priority for internal team purposes.",
  },
  {
    label: "Contracted",
    value: KpiPriorityT.ContractedT,
    icon: "flag",
    iconColor: KPI_PRIORITY_PROPS[KpiPriorityT.ContractedT].color,
    description: "KPI priority based on contracted values.",
  },
];

const PERIOD_OPTIONS = [
  { label: "Week", value: KpiPeriodT.WeekT },
  { label: "Month", value: KpiPeriodT.MonthT },
  { label: "Quarter", value: KpiPeriodT.QuarterT },
  { label: "Year", value: KpiPeriodT.YearT },
  { label: "One Time", value: KpiPeriodT.NoneT },
];

const DATE_RANGE = { from: startOfDay(addDays(new Date(), -30)), to: new Date() };

export type FormKpiInputsT = {
  conditions: ConditionsT;
  currency: CurrencyT;
  description: string;
  endOn?: string;
  metricId: string;
  name: string;
  period: KpiPeriodT;
  priority: KpiPriorityT;
  startOn: string;
};

type PropsT = {
  defaultValues?:
    | (Omit<FormKpiInputsT, "description" | "currency" | "conditions"> & {
        conditions?: Omit<ConditionT, "conditionable">[] | null;
        currency?: FormKpiInputsT["currency"] | null;
        description?: FormKpiInputsT["description"] | null;
        sourceSystems?: Pick<SourceSystemT, "externalId" | "name" | "id" | "externalName">[];
        targets?: Pick<NonNullable<KpiSettingT["targets"]>[number], "currency" | "id" | "startOn" | "value">[] | null;
      })
    | null;
  dimensions?: SelectableDimensionT[];
  isDataLoading?: boolean;
  isEditing?: boolean;
  isSubmitLoading?: boolean;
  metrics?: SelectableMetricT[];
  onSubmit: (values: Omit<KpiSettingCreateMutationVariablesT, "targets"> & { targets: TargetInputT[] }) => void;
  submitLabel?: string;
};

export type UserFilledTargetFormStateT = {
  hasErros?: boolean;
  hasFirstInputTouched?: boolean;
  values: {
    currency?: CurrencyT | null;
    id?: string;
    startOn: Date;
    value: string;
  }[];
};

const getMinEndOnDate = ({
  endOn,
  isEditing,
  period,
  startOn,
}: {
  endOn?: string;
  isEditing?: boolean;
  period: KpiPeriodT;
  startOn: string;
}) => {
  if (startOn && !isEditing) {
    return addDays(new Date(startOn), +1);
  }

  if (isEditing) {
    return getKpiTargetEndOn({
      startOn: formatDate(new Date(), "dateApi"),
      kpiSetting: { period, endOn },
    });
  }

  return undefined;
};
const getconditionsForApi = (conditions: ConditionsT) =>
  getAreConditionsEmpty({ conditions }) ? [] : getConditionsApiInputFormat(parseFormConditions({ conditions }) || []);

export const FormKpi = ({
  defaultValues,
  dimensions,
  isEditing,
  isSubmitLoading,
  metrics,
  onSubmit,
  submitLabel,
}: PropsT) => {
  const inputRefs = useRef<(HTMLDivElement | null)[]>([]);
  const { organization } = useAppSettings();
  const [userFilledTargetsFormState, setUserFilledTargetsFormState] = useState<UserFilledTargetFormStateT>({
    values: (defaultValues?.targets || []).map((target) => ({
      ...target,
      value: target.value.toString(),
      startOn: startOfDay(new Date(target.startOn.split("T")[0])),
    })),
    hasErros: false,
    hasFirstInputTouched: false,
  });
  const [sourceSystems, setSourceSystems] = useState(
    defaultValues?.sourceSystems ? [...defaultValues?.sourceSystems] : []
  );

  const selectOptionsUnit = useMemo(
    () => [
      ...(dimensions || []).map((dimension) => ({
        label: dimension.name,
        value: dimension.id,
        type: "dimension" as MetricOrDimensionT,
      })),
    ],
    [dimensions]
  );

  const {
    control,
    formState: { errors },
    handleSubmit: formSubmithandler,
    setValue,
    trigger,
    watch,
  } = useForm<FormKpiInputsT>({
    defaultValues: {
      metricId: defaultValues?.metricId || "",
      name: defaultValues?.name || "",
      description: defaultValues?.description || "",
      priority: defaultValues?.priority || KpiPriorityT.LowT,
      period: defaultValues?.period || KpiPeriodT.MonthT,
      startOn: defaultValues?.startOn || "",
      endOn: defaultValues?.endOn || "",
      conditions: getDefaultConditions({
        conditions: defaultValues?.conditions ? defaultValues.conditions : [],
        selectOptionsUnit,
      }),
      currency: defaultValues?.currency || CurrencyT.CzkT,
    },
    resolver: yupResolver(formKpiSchema),
  });

  const [startOn, endOn, period, metricId, currency, conditions] = watch([
    "startOn",
    "endOn",
    "period",
    "metricId",
    "currency",
    "conditions",
  ]);

  const selectedMetric = metrics?.find((item) => item.id === metricId);
  const isCustomValueMetric = selectedMetric?.category === ValueCategoryT.CustomValueT;
  const isOneTimePeriod = period === KpiPeriodT.NoneT;
  const minEndOnDate = getMinEndOnDate({ startOn, isEditing, period });
  const isLastPeriod =
    isEditing &&
    !!endOn &&
    new Date(endOn).getTime() <=
      getKpiTargetEndOn({ startOn: formatDate(new Date(), "dateApi"), kpiSetting: { period, endOn } }).getTime();

  const handleChangeAccounts = (
    newSourceSystems: Pick<SourceSystemT, "externalId" | "name" | "id" | "externalName">[]
  ) => {
    setSourceSystems([...newSourceSystems]);
  };

  const handleSubmit = (values: FormKpiInputsT) => {
    setUserFilledTargetsFormState((prev) => ({ ...prev, hasFirstInputTouched: true }));
    if (userFilledTargetsFormState.hasErros) {
      inputRefs.current[0]?.focus();
      return;
    }

    onSubmit({
      ...values,
      conditions: getAreConditionsEmpty(values) ? [] : getConditionsApiInputFormat(parseFormConditions(values) || []),
      startOn: formatDate(new Date(values.startOn).toISOString(), "dateApi"),
      endOn: values.endOn ? formatDate(new Date(values.endOn).toISOString(), "dateApi") : undefined,
      targets: userFilledTargetsFormState.values.map((filledTarget) => ({
        ...filledTarget,
        currency: (filledTarget.currency || values.currency) as CurrencyT,
        startOn: formatDate(filledTarget.startOn, "dateApi"),
        value: Number(filledTarget.value),
      })),
      sourceSystems: isCustomValueMetric
        ? []
        : sourceSystems.map((system) => ({ ...system, externalName: system.externalName })),
    });
  };

  const [getMetrics] = useKpiMetricsLazyQueryT({
    fetchPolicy: "network-only",
    variables: { organizationExternalId: organization.externalId },
  });

  const handleCreate = async () => {
    await getMetrics();
  };

  const { data: widgetData, error: widgetDataError } = useOrganizationWidgetDataQuery({
    variables: {
      metricIds: [metricId],
      organizationExternalId: organization.externalId,
      conditions: getconditionsForApi(conditions),
      currency,
      sourceSystemIds: isCustomValueMetric
        ? []
        : (sourceSystems || []).filter((system) => !!system.id).map((system) => system.id),
      ...DATE_RANGE,
    },
    skip: !metricId || (!isCustomValueMetric && !sourceSystems?.length),
  });

  const widgetDataMetric = widgetData?.organization?.widgetData.summary?.nodes[0];
  const widgetDataConsistency = widgetData?.organization?.widgetData.dataConsistency;
  const selectedmetric = metrics?.find((metric) => metric.id === metricId);

  useEffect(() => {
    trigger("endOn");
  }, [period, trigger]);

  return (
    <form id="kpi-form" onSubmit={formSubmithandler(handleSubmit)}>
      <Tile className="mb-16">
        <HeadingSection className="mb-0">Monitored metric</HeadingSection>
        <Text className="mb-16 d-block" color="gray">
          Choose the metric you want to monitor and connect accounts.
        </Text>
        <Row>
          <Col type="grow">
            <Controller
              control={control}
              name="metricId"
              render={({ field, fieldState }) => (
                <MetricSelect
                  error={fieldState.error}
                  id="metricId"
                  inputProps={field}
                  label="Metric"
                  metrics={metrics || []}
                  placeholder="Choose metric"
                  value={field.value}
                  hasArrow
                  isDisabledNotConnectedWarning
                  onChange={(newMetricId) => setValue("metricId", newMetricId || "", { shouldValidate: true })}
                  onCreate={handleCreate}
                />
              )}
            />
          </Col>

          {selectedMetric?.dataType === MetricDataT.MoneyT && (
            <Col width="144px">
              <InputController
                collection={CURRENCY_OPTIONS.filter((item) => item.value !== CurrencyT.OriginalT && item.value !== "")}
                control={control}
                id="currency"
                label="Currency"
                name="currency"
              />
            </Col>
          )}
        </Row>

        <div className="mt-16">
          <FormKpiConnectedAccounts
            isCustomValueMetric={isCustomValueMetric}
            metricId={metricId}
            metrics={metrics}
            sourceSystems={sourceSystems}
            onChangeAccounts={handleChangeAccounts}
          />
        </div>
      </Tile>

      {!isCustomValueMetric && (
        <KpiConditions
          conditions={conditions}
          control={control}
          dimensions={dimensions}
          errors={errors}
          setValue={setValue}
          trigger={trigger}
          watch={watch}
        />
      )}

      <Tile className="mb-16">
        <HeadingSmall className="mb-0">Metric value preview</HeadingSmall>
        <Text className="mb-16 d-block" color="gray">
          Preview the value of the selected metric for the last 30 days.
        </Text>
        <Row className="ma-0 pa-16 border border-color-soft-gray border-radius-6" justify="center">
          {!!selectedmetric && !!widgetDataMetric && widgetDataConsistency === DataConsistencyT.OkT ? (
            <MetricInformationCard
              currency={widgetDataMetric?.currency}
              dateRange={DATE_RANGE}
              metric={selectedmetric}
              value={widgetDataMetric?.widgetMetrics.find((metric) => metric.metricId === metricId)?.value}
            />
          ) : (
            <Text color="gray">
              {widgetDataError || widgetDataConsistency !== DataConsistencyT.InProgressT
                ? "No preview available"
                : "Metric value preview will be shown here"}
            </Text>
          )}
        </Row>
      </Tile>

      <Tile className="mb-16">
        <HeadingSection margin={0}>Priority</HeadingSection>
        <Text className="mb-16 d-block" color="gray">
          Choose the priority of created KPI
        </Text>

        <InputController
          className="mb-16"
          control={control}
          id="priority"
          input={{ type: "radio", kind: "tile" }}
          name="priority"
          radioOptions={PRIORITY_OPTIONS}
        />
      </Tile>

      <Tile>
        <HeadingSection margin={0}>KPI Settings</HeadingSection>
        <Text className="mb-16 d-block" color="gray">
          Fill in the basic info about KPI.
        </Text>

        <InputController
          className="mb-16"
          control={control}
          errors={errors}
          id="name"
          input={{ disabled: isSubmitLoading, placeholder: selectedMetric?.name || "Insert name" }}
          label="Name"
          name="name"
        />

        <InputController
          className="mb-16"
          control={control}
          errors={errors}
          id="description"
          label="Description"
          name="description"
          textarea={{
            rows: 3,
            disabled: isSubmitLoading,
            placeholder: "Add description to your KPI...",
          }}
        />

        <div className="delimiter mv-16" />

        <InputController
          className="mb-16"
          collection={PERIOD_OPTIONS}
          control={control}
          errors={errors}
          id="period"
          label="Period"
          name="period"
          select={{ disabled: isSubmitLoading || isEditing }}
        />

        <Row>
          <Col type="grow">
            <DateController
              className="mb-16"
              control={control}
              defaultValue={defaultValues?.startOn || DEFAULT_DATE}
              errors={errors}
              label="Start date"
              maxDate={endOn ? new Date(endOn) : undefined}
              name="startOn"
              input={{
                disabled: isEditing,
                placeholder: "Select date",
                hasArrow: true,
              }}
              disableClear
              onSetValue={setValue}
            />
          </Col>
          <Col type="grow">
            <DateController
              className="mb-16"
              control={control}
              defaultValue={defaultValues?.endOn || DEFAULT_DATE}
              disableClear={period === KpiPeriodT.NoneT}
              errors={errors}
              label="End date (Optional)"
              minDate={minEndOnDate}
              name="endOn"
              input={{
                disabled: isLastPeriod,
                placeholder: "Select date",
                hasArrow: true,
              }}
              onSetValue={setValue}
            />
          </Col>
        </Row>
      </Tile>

      <Tile className="mt-16" id="targets">
        <HeadingSection>KPI Targets</HeadingSection>

        {!!(isOneTimePeriod && startOn && endOn) || !!(!isOneTimePeriod && startOn) ? (
          <FormKpiPeriods
            endOn={endOn}
            isEditing={isEditing}
            period={period}
            selectedCurrency={selectedMetric?.dataType === MetricDataT.MoneyT ? (currency as CurrencyT) : undefined}
            selectedMetric={selectedMetric}
            setInputRef={(element, index) => (inputRefs.current[index] = element)}
            setUserFilledTargetsFormState={setUserFilledTargetsFormState}
            startOn={startOn}
            userFilledTargetsFormState={userFilledTargetsFormState}
          />
        ) : (
          <div className="text-center">
            {<InfoBox isCompact>Please setup start {isOneTimePeriod && "and end"} date first.</InfoBox>}
          </div>
        )}
      </Tile>

      <ActionBar>
        <ButtonPrimary disabled={isSubmitLoading} form="kpi-form" loading={isSubmitLoading} type="submit">
          {submitLabel || "Create KPI"}
        </ButtonPrimary>
      </ActionBar>
    </form>
  );
};
