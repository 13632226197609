import { endOfMonth, endOfQuarter, endOfWeek, endOfYear, isBefore, isDate } from "date-fns";
import { sortBy } from "lodash";
import { KpiListItemPropsT } from "../components/kpiList/KpiListItem";
import { KpiPeriodT, KpiSemaphoreT, KpiSettingT, KpiStatusT } from "../graphql/generated/graphql";

type ContainsPeriodDateT = ({
  date,
  period,
}: {
  date?: string;
  period: { beginsAt: string | null; endsAt: string | null };
}) => boolean;

export const containsPeriodDate: ContainsPeriodDateT = ({ date: incomingDate, period }) => {
  const date = incomingDate ? new Date(incomingDate) : new Date();

  return !!period.beginsAt && !!period.endsAt && new Date(period.beginsAt) <= date && date <= new Date(period.endsAt);
};

export const getBestWorstKpis = (kpiSettings: KpiListItemPropsT["kpiSetting"][]) => {
  const activeKpiSettings = kpiSettings.filter(
    (kpiSetting) =>
      kpiSetting.status === KpiStatusT.ActiveT &&
      typeof kpiSetting.lastResult?.estimatedProgress === "number" &&
      kpiSetting.lastResult?.semaphore !== KpiSemaphoreT.LoadingT
  );
  const sortedFromLowest = sortBy(activeKpiSettings, (kpiSetting) => kpiSetting.lastResult?.estimatedProgress);
  const sortedFromBiggest = [...sortedFromLowest].reverse();

  const worst =
    sortedFromBiggest.find((kpiSetting) => kpiSetting.lastResult?.semaphore === KpiSemaphoreT.ErrorT) ||
    sortedFromLowest[0];
  const best = sortedFromBiggest[0];

  return { worst, best };
};

type GetKpiTargetEndOnParamsT = {
  kpiSetting: Pick<KpiSettingT, "period" | "endOn">;
  startOn: string;
};

export const getKpiTargetEndOn = ({ kpiSetting: { endOn: endGiven, period }, startOn }: GetKpiTargetEndOnParamsT) => {
  const getEndOn = (getEndFn: (date: Date | number) => Date) => {
    const endCalculated = getEndFn(new Date(startOn));
    if (endGiven) {
      return isBefore(endCalculated, new Date(endGiven)) ? endCalculated : new Date(endGiven);
    }
    return endCalculated;
  };

  if (period === KpiPeriodT.NoneT) {
    return isDate(endGiven) ? endGiven : new Date(endGiven);
  }
  if (period === KpiPeriodT.WeekT) {
    return getEndOn(endOfWeek);
  }
  if (period === KpiPeriodT.MonthT) {
    return getEndOn(endOfMonth);
  }
  if (period === KpiPeriodT.QuarterT) {
    return getEndOn(endOfQuarter);
  }
  if (period === KpiPeriodT.YearT) {
    return getEndOn(endOfYear);
  }
  return {};
};
