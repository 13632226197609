import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { withApiStateHandler } from "../components/ErrorLoadingWrapper/withApiStateHandler";
import { FormDimension } from "../components/formDimension/FormDimension";
import { DUPLICATED_PREFIX } from "../constants/report";
import { ROUTES } from "../constants/routes";
import { notifyError, notifySucces } from "../functions/toast";
import {
  CustomDimensionQueryT,
  CustomDimensionsDocumentT,
  useCreateCustomDimensionMutation,
  useCustomDimensionQuery,
} from "../graphql/generated/graphql";
import { useAppSettings } from "../providers/appSettingsProvider";
import { RouteParamsT } from "../types/router";
import { Modal } from "../ui/Modal";
import { normalizeApiFormDimensionValues } from "./DimensionNew";
import { DIMENSIONS } from "./MetricsAndDimensions";

type PropsT = {
  data?: CustomDimensionQueryT;
  onClose: () => void;
  organizationExternalId: string;
};

const DimensionsDuplicatePageComponent = withApiStateHandler(({ data, onClose, organizationExternalId }: PropsT) => {
  const [serverErrors, setServerErrors] = useState<string[] | undefined>(undefined);

  const [mutate, { loading }] = useCreateCustomDimensionMutation({
    refetchQueries: [CustomDimensionsDocumentT],
    onCompleted: (res) => {
      const errors = res.organization?.createCustomDimension?.errors;

      if (errors?.length) {
        return setServerErrors(errors);
      }
      notifySucces(<>{`Custom dimension duplicated!`}</>);
      onClose();
    },
    onError: () => notifyError(<>{`Server error when creating custom dimension!`}</>),
  });

  const customDimension = data?.organization?.customDimension;

  return (
    <FormDimension
      isMutationLoading={loading}
      serverErrors={serverErrors}
      defaultValues={
        customDimension ? { ...customDimension, name: `${DUPLICATED_PREFIX} ${customDimension?.name}` } : undefined
      }
      onSubmit={(values) =>
        mutate({
          variables: {
            ...normalizeApiFormDimensionValues(values),
            organizationExternalId,
          },
        })
      }
    />
  );
});

export const DimensionDuplicateModal = ({ dimensionId, onClose }: { dimensionId?: string; onClose: () => void }) => {
  const { organization } = useAppSettings();

  const response = useCustomDimensionQuery({
    fetchPolicy: "network-only",
    variables: {
      organizationExternalId: organization.externalId,
      customDimensionId: dimensionId || "",
    },
    skip: !dimensionId,
  });
  return (
    <Modal close={onClose} heading="Duplicate custom dimension" testId="duplicate-custom-dimension-modal" isOpen>
      <DimensionsDuplicatePageComponent
        {...response}
        organizationExternalId={organization.externalId}
        onClose={onClose}
      />
    </Modal>
  );
};

export const DimensionDuplicatePage = () => {
  const { dimensionId } = useParams<RouteParamsT["dimension"]>();
  const navigate = useNavigate();

  return (
    <DimensionDuplicateModal
      dimensionId={dimensionId}
      onClose={() => navigate(ROUTES.metricsAndDimensionsTabs(DIMENSIONS))}
    />
  );
};
