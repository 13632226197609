import React from "react";
import classNames from "classnames";
import { Badge } from "../Badge/Badge";
import { TilePropsT } from "./Tile";

type PropsT = Pick<TilePropsT, "alphaBadge" | "badge"> & {
  leftSpacing?: boolean;
};

export const TileBadge = ({ alphaBadge, badge, leftSpacing }: PropsT) => (
  <div className={classNames("Tile-badge")} style={{ zIndex: 2 }}>
    {badge && badge.length > 0 && (
      <Badge className={classNames({ "mr-4": !leftSpacing })} kind="blue" size="medium" isUppercase>
        {badge}
      </Badge>
    )}
    {alphaBadge && alphaBadge.length > 0 && (
      <Badge
        className={classNames({ "mr-4": !leftSpacing, "ml-4": leftSpacing })}
        kind="lightBlue"
        size="medium"
        isUppercase
      >
        {alphaBadge}
      </Badge>
    )}
  </div>
);
