import React from "react";
import { ButtonSecondary } from "../../../ui/Button/Button";
import { Col, Row } from "../../../ui/grid/Grid";
import { Text } from "../../../ui/Text/Text";

type PropsT = {
  addButtonTitle: string;
  filledLength: number;
  handleAddRow: () => void;
  limits?: {
    max?: number | undefined;
    min?: number | undefined;
  };
  rawLength: number;
  testId: string;
};

export const LimitState = ({ addButtonTitle, filledLength, handleAddRow, limits, rawLength, testId }: PropsT) => {
  const isOnLimit = !!(limits && limits.max && limits.max <= rawLength);
  const isUnderLimit = !!(limits && limits.min && limits.min > filledLength);

  return (
    <Row alignItems="center" className="mt-8" justify="between">
      {!isOnLimit && (
        <Col>
          <ButtonSecondary data-test-id={testId} size="small" onClick={() => handleAddRow()}>
            {addButtonTitle}
          </ButtonSecondary>
        </Col>
      )}

      {!!limits && (
        <Col className="ml-a">
          {!!limits.max && !isUnderLimit && (
            <Text color="gray">
              {filledLength} out of {limits.max}
            </Text>
          )}
          {isUnderLimit && limits.min === 1 && (
            <Text color="error" bold>
              Required
            </Text>
          )}
          {isUnderLimit && limits.min !== 1 && (
            <Text color="error" bold>
              {filledLength} of {limits.min} required
            </Text>
          )}
        </Col>
      )}
    </Row>
  );
};
