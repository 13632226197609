import React, { useCallback, useMemo } from "react";
import { snakeCase } from "lodash";
import emptyMetricsImage from "../assets/empty_metrics.svg";
import { withApiStateHandler } from "../components/ErrorLoadingWrapper/withApiStateHandler";
import { ROUTES } from "../constants/routes";
import {
  CustomDimensionsQueryResultT,
  CustomMetricsQueryResultT,
  CustomValueMetricsQueryResultT,
} from "../graphql/generated/graphql";
import { useSearch } from "../hooks/useSearch";
import { Unpacked } from "../types/common";
import { ButtonPrimary } from "../ui/Button/Button";
import { useSortable } from "../ui/DataTable/Sortable";
import { Pagination, usePagination } from "../ui/Pagination/Pagination";
import { EmptyState } from "../ui/Table/EmptyState";
import { TableHeader } from "../ui/Table/TableHeader";
import { Tile } from "../ui/Tile/Tile";
import { DimensionsTable } from "./Dimensions";
import { CustomDimensionT } from "./DimensionTableRow";
import { MetricsTable } from "./Metrics";
import { CustomMetricT } from "./MetricTableRow";
import { ValueMetricsTable } from "./ValueMetrics";
import { CustomValueMetricT } from "./ValueMetricTableRow";

const CONSTANTS = {
  calculatedMetric: {
    heading: "Calculated Metrics",
    emptyStateHeading: "You have no calculated metrics yet.",
    createNewLink: ROUTES.metricNew(),
    createNewText: "Create New Calculated Metric",
    noDataDescription: "Start with create new metric",
  },
  customValue: {
    heading: "Custom Value Metrics",
    emptyStateHeading: "You have no custom value metrics yet.",
    createNewLink: ROUTES.valueMetricNew(),
    createNewText: "Create New Custom Value Metric",
    noDataDescription: "Start with create new metric",
  },
  dimension: {
    heading: "Custom Dimensions",
    createNewLink: `${ROUTES.dimensionNew()}?tab=dimensions`,
    emptyStateHeading: "You have no custom dimensions yet.",
    createNewText: "Create New Custom Dimension",
    noDataDescription: "Start with create new dimension",
  },
};

type PropsT =
  | (Pick<CustomMetricsQueryResultT, "data" | "refetch"> & { type: "calculatedMetric" })
  | (Pick<CustomValueMetricsQueryResultT, "data" | "refetch"> & { type: "customValue" })
  | (Pick<CustomDimensionsQueryResultT, "data" | "refetch"> & { type: "dimension" });

export const GeneralMetricDimensionTable = withApiStateHandler(({ data, refetch, type }: PropsT) => {
  const rows = useMemo(() => {
    if (type === "calculatedMetric") {
      return data?.organization?.customMetrics.nodes || [];
    }
    if (type === "customValue") {
      return data?.organization?.customValueMetrics.nodes || [];
    }
    if (type === "dimension") {
      return data?.organization?.customDimensions.nodes || [];
    }
    return [];
  }, [data, type]);
  const filterSearchRows = useCallback((item: Unpacked<typeof rows>) => item?.name, []);
  const { filterData, searchInputProps } = useSearch(filterSearchRows);
  const filtredData: typeof rows = useMemo(() => filterData(rows || []), [filterData, rows]);

  const [sortableProps, sortedTableRows] = useSortable(
    filtredData,
    useCallback(
      (sortBy) => (row) => {
        const rowValue = row?.[sortBy as "name" | "referencesCount"];
        return rowValue ?? null;
      },
      []
    ),
    { sortBy: "name" }
  );

  const [paginationProps, paginatedTableRows] = usePagination<typeof sortedTableRows>(sortedTableRows);

  const resetFilters = () => {
    searchInputProps.clearSearch();
  };

  if (!rows?.length) {
    return (
      <>
        <TableHeader heading={CONSTANTS[type].heading} headingLevel="h2" />
        <Tile className="mb-16 mt-8">
          <EmptyState
            description={CONSTANTS[type].noDataDescription}
            heading={CONSTANTS[type].emptyStateHeading}
            image={emptyMetricsImage}
          >
            <div className="mt-16">
              <ButtonPrimary icon="plus" to={CONSTANTS[type].createNewLink}>
                {CONSTANTS[type].createNewText}
              </ButtonPrimary>
            </div>
          </EmptyState>
        </Tile>
      </>
    );
  }

  return (
    <>
      <TableHeader
        createButtonProps={{ to: CONSTANTS[type].createNewLink }}
        heading={CONSTANTS[type].heading}
        headingLevel="h2"
        searchInputProps={searchInputProps}
      />

      {paginatedTableRows.length === 0 ? (
        <Tile>
          <EmptyState
            description={`Out of ${rows.length} records, none of them match your search query. Try to modify what you're looking for.`}
            heading={CONSTANTS[type].emptyStateHeading}
            image={emptyMetricsImage}
          >
            <div className="mt-16">
              <ButtonPrimary icon="remove" onClick={resetFilters}>
                Reset filters
              </ButtonPrimary>
            </div>
          </EmptyState>
        </Tile>
      ) : (
        <>
          {type == "calculatedMetric" && (
            <MetricsTable
              refetch={refetch}
              sortableProps={sortableProps}
              tableRows={paginatedTableRows as CustomMetricT[]}
            />
          )}
          {type == "customValue" && (
            <ValueMetricsTable
              refetch={refetch}
              sortableProps={sortableProps}
              tableRows={paginatedTableRows as CustomValueMetricT[]}
            />
          )}
          {type === "dimension" && (
            <DimensionsTable
              refetch={refetch}
              sortableProps={sortableProps}
              tableRows={paginatedTableRows as CustomDimensionT[]}
            />
          )}
        </>
      )}

      <Pagination
        className="mb-40 mt-16"
        testId={`${snakeCase(CONSTANTS[type].heading)}--pagination`}
        isCompact
        {...paginationProps}
      />
    </>
  );
});
