import React, { ComponentPropsWithRef, MouseEvent, PropsWithChildren, ReactNode } from "react";
import cs from "classnames";
import { Component } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { Tooltip, TooltipPropsT } from "../Tooltip/Tooltip";

export type LinkPropsT = {
  className?: string;
  color?: "danger";
  icon?: string;
  isDisabled?: boolean;
  isExternal?: boolean;
  isRealHref?: boolean;
  noUnderline?: true;
  tooltipContent?: ReactNode;
} & ComponentPropsWithRef<"a"> &
  Partial<Pick<TooltipPropsT, "tooltipContent">> &
  (
    | { href?: string; onClick?: never; to?: never }
    | { href?: never; onClick?: (e: MouseEvent<HTMLElement>) => void; to?: never }
    | { href?: never; onClick?: never; to?: string | -1 }
  );

export const Link = ({
  children,
  className,
  color,
  href,
  icon,
  isDisabled,
  isExternal,
  noUnderline,
  onClick,
  to,
  tooltipContent,
  ...rest
}: PropsWithChildren<LinkPropsT>) => {
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (typeof onClick === "function" && !isDisabled) {
      event.preventDefault();
      onClick(event);
      return;
    }

    if (href === "#") {
      event.preventDefault();
    }
  };

  const cls = cs("Link", className, {
    "Link--disabled": isDisabled,
    "Link--noUnderline": noUnderline,
    "Link--withIcon": icon || isExternal,
    [`fc-${icon}`]: icon,
    [`Link--${color}`]: color,
  });

  const onClickHref = typeof onClick === "function" ? "#" : undefined;

  return (
    <Tooltip tooltipContent={tooltipContent}>
      <Component
        {...rest}
        className={cls}
        href={isDisabled ? "" : href || onClickHref}
        style={tooltipContent ? { ...rest.style, pointerEvents: "auto" } : rest.style}
        tag="a"
        target={isExternal ? "_blank" : rest.target}
        to={isDisabled ? "" : to}
        onClick={handleClick}
      >
        {children}
        {isExternal && <Icon className="Link-icon" kind="external" isInheritColor />}
      </Component>
    </Tooltip>
  );
};
