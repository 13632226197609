import React, { PropsWithChildren } from "react";
import ReactDOM from "react-dom";
import { useAppSettings } from "../../providers/appSettingsProvider";

export const Breadcrumb = ({ children }: PropsWithChildren) => {
  const {
    layoutSelectors: { breadcrumbContainer },
  } = useAppSettings();

  const container = document.querySelector(breadcrumbContainer);

  if (!container) {
    return null;
  }

  return ReactDOM.createPortal(<>{children}</>, container);
};
