import { useCallback, useMemo } from "react";
import { cloneDeep, set } from "lodash";
import { fetchMetricId } from "../../functions/fetchIds";
import { WidgetDataFragmentT, WidgetDateGroupingT } from "../../graphql/generated/graphql";
import { TableOrderT } from "../../types/common";
import { WidgetT } from "../../types/widgets";
import { SortableAdditionalPropsT, SortableStateT, useSortable } from "../../ui/DataTable/Sortable";
import { PAGE_SIZES } from "../../ui/Pagination/constants";
import { PaginationAdditionalPropsT, PaginationStateT, usePagination } from "../../ui/Pagination/Pagination";
import { useReportBoardContext } from "../reportBoard/context/reportBoardContext";
import { WidgetContextT } from "../reportBoard/ReportBoardSection";
import { TableComponentPropsT } from "./TableComponent";

export type BasicTablePropsT = Pick<TableComponentPropsT, "widget"> & {
  onWidgetUpdate?: WidgetContextT["widgetUpdate"];
  rows: WidgetDataFragmentT["rows"]["nodes"];
  type: "table" | "tableOptimization";
};

export type UseBasicTableResultT = {
  paginatedWidgetData: WidgetDataFragmentT["rows"]["nodes"];
  paginationProps: PaginationAdditionalPropsT;
  shouldShowDateRange: boolean;
  shouldShowInlineTotal: boolean;
  sortableProps: SortableAdditionalPropsT;
};

const getIsSortValid = (widget: WidgetT) => {
  const sort = widget.properties?.sortByMetricsAndDimensions;
  if (!sort || (sort.sortBy === "date" && widget.dateGrouping === WidgetDateGroupingT.SummaryT)) {
    return false;
  }

  if (sort.sortBy?.includes("MetricId:")) {
    return widget.widgetMetrics.map((wm) => fetchMetricId(wm)).includes(sort.sortBy);
  }

  if (sort.sortBy !== "date") {
    return widget.widgetDimensions.map((wd) => wd.dimensionId).includes(sort?.sortBy || "");
  }

  return true;
};

const getValidSort = (widget: WidgetT) => {
  if (getIsSortValid(widget)) {
    return widget.properties?.sortByMetricsAndDimensions;
  }

  return {
    sortBy:
      widget.dateGrouping !== WidgetDateGroupingT.SummaryT
        ? "date"
        : widget?.widgetMetrics?.length > 0
        ? fetchMetricId(widget.widgetMetrics[0])
        : widget.widgetDimensions[0]?.dimensionId,
    direction: TableOrderT.asc,
  };
};

export const useTable = ({ onWidgetUpdate, rows, widget }: BasicTablePropsT): UseBasicTableResultT => {
  const {
    widgetSettingsData: { dimensionsById, metricsById },
  } = useReportBoardContext();

  const rowsNotOther = useMemo(() => rows.filter((row) => !row.isOther), [rows]);
  const rowsOther = useMemo(() => rows.filter((row) => row.isOther), [rows]);

  const sortableGetRowValue = useCallback(
    (sortBy: string) => (row: typeof rows[0]) => {
      if (sortBy === "date") {
        return row.date;
      }

      const dimension = dimensionsById[sortBy];
      const metric = metricsById[sortBy.split(":")[1]];

      if (dimension) {
        return row.widgetDimensions.find((dim) => dim.dimensionId === sortBy)?.value || null;
      }

      if (metric) {
        return row.widgetMetrics.find((met) => met.widgetMetricId === sortBy)?.value || null;
      }

      return null;
    },
    [dimensionsById, metricsById]
  );

  const sortableHandleChange = useCallback(
    (args: SortableStateT) => {
      if (onWidgetUpdate) {
        onWidgetUpdate({
          setWidget: (prev) =>
            set(cloneDeep(prev), ["properties", "sortByMetricsAndDimensions"], {
              ...args,
            }),
          widgetId: widget.id,
        });
      }
    },
    [onWidgetUpdate, widget.id]
  );

  const [sortableProps, sortedTableRows] = useSortable<typeof rowsNotOther>(
    rowsNotOther,
    sortableGetRowValue,
    getValidSort(widget),
    sortableHandleChange
  );

  const [, sortedTableRowsOther] = useSortable<typeof rowsOther>(
    rowsOther,
    sortableGetRowValue,
    getValidSort(widget),
    sortableHandleChange,
    sortableProps
  );

  const sortedRows = useMemo(
    () =>
      sortableProps.sortBy === "date"
        ? [...sortedTableRows, ...sortedTableRowsOther].sort(
            (rowA, rowB) =>
              (new Date(rowA.date).getTime() - new Date(rowB.date).getTime()) *
              (sortableProps.direction === "asc" ? 1 : -1)
          )
        : [...sortedTableRows, ...sortedTableRowsOther],
    [sortedTableRows, sortedTableRowsOther, sortableProps]
  );

  const [paginationProps, paginatedWidgetData] = usePagination<typeof sortedTableRows>(
    sortedRows,
    {
      pageSize:
        typeof widget.properties?.pageSize === "string"
          ? parseInt(widget.properties?.pageSize, 10)
          : widget.properties?.pageSize || PAGE_SIZES[0],
    },
    useCallback(
      (args: PaginationStateT) => {
        if (onWidgetUpdate) {
          onWidgetUpdate({
            setWidget: (prev) => set(cloneDeep(prev), ["properties", "pageSize"], args.pageSize),
            widgetId: widget.id,
          });
        }
      },
      [onWidgetUpdate, widget.id]
    )
  );

  const shouldShowDateRange = widget.dateGrouping !== WidgetDateGroupingT.SummaryT;
  const shouldShowInlineTotal = !(widget.widgetDimensions.length === 0 && !shouldShowDateRange);

  return {
    paginationProps,
    shouldShowInlineTotal,
    paginatedWidgetData,
    shouldShowDateRange,
    sortableProps,
  };
};
