import React from "react";
import { ReportListConnected } from "../componentsConnected/reportList/ReportList";
import { ROUTES } from "../constants/routes";
import { useCreateReport } from "../hooks/useCreateReport";
import { Layout } from "../layout/wrappers/Layout";
import { useAppSettings } from "../providers/appSettingsProvider";
import { DropdownMenu } from "../ui/Dropdown/DropdownMenu";
import { Col, Row } from "../ui/grid/Grid";
import { HeadingPage } from "../ui/Heading/Heading";
import { Tooltip } from "../ui/Tooltip/Tooltip";

export const ReportsPage = () => {
  const { createReport, loading } = useCreateReport({
    successMessage: "New empty report was created.",
  });
  const {
    user: { canEdit },
  } = useAppSettings();

  return (
    <Layout>
      <HeadingPage>
        <Row>
          <Col type="grow">Your Reports</Col>
        </Row>
      </HeadingPage>
      {canEdit && (
        <Tooltip tooltipContent="Create new report">
          <div className="d-inline-block">
            <DropdownMenu
              buttonProps={{ variant: "primary" }}
              className="mb-24"
              icon="plus"
              isLoading={loading}
              testId="Dropdown--add-new-report"
              collection={[
                {
                  value: "New empty report",
                  onClick: () => createReport({ name: "New report", description: "Description for the new report." }),
                },
                { value: "New report from template", to: ROUTES.templates() },
              ]}
            />
          </div>
        </Tooltip>
      )}
      <ReportListConnected canEdit={canEdit} />
    </Layout>
  );
};
