import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { useReportListQuery } from "../graphql/generated/graphql";
import { Layout } from "../layout/wrappers/Layout";
import { useAppSettings } from "../providers/appSettingsProvider";
import ErrorBox from "../ui/ErrorBox/ErrorBox";
import { Loader } from "../ui/Loader/Loader";

export const HomePage = () => {
  const { organization } = useAppSettings();
  const { data, error, loading } = useReportListQuery({
    variables: { organizationExternalId: organization.externalId, limit: 0, offset: 0 },
  });

  const total = data?.organization?.reports?.pageInfo?.total;

  const navigate = useNavigate();

  useEffect(() => {
    if (typeof total === "number") {
      if (total > 0) {
        navigate(ROUTES.reports());
        return;
      }
      navigate(ROUTES.firstTemplates());
    }
  }, [total, navigate]);

  return (
    <Layout>
      {loading && <Loader size="big" absolute />}
      {error && <ErrorBox className="mt-16">Error has occured when downloading data</ErrorBox>}
    </Layout>
  );
};
