import { useState } from "react";
import { useUnsavedAlertOnRedirect } from "./useUnsavedAlertOnRedirect";

type PropsT = { callback: () => void; hasUnsavedChanges: boolean };

export const useUnsavedAlertOnRedirectAndExit = ({ callback, hasUnsavedChanges }: PropsT) => {
  const { UnsavedChangesAlert } = useUnsavedAlertOnRedirect(hasUnsavedChanges);
  const [isCancelBlocked, setIsCancelBlocked] = useState(false);

  const handleClose = () => {
    if (hasUnsavedChanges) {
      setIsCancelBlocked(true);
    } else {
      callback();
    }
  };

  const UnsavedChangesAlertComponent = () => {
    return (
      <UnsavedChangesAlert
        description="You have made some changes in this form. Are you sure you want to discard all changes?"
        isForceShow={isCancelBlocked}
        confirmationCallback={(confirmationResult) => {
          if (confirmationResult === "cancel") {
            setIsCancelBlocked(false);
          }

          if (confirmationResult === "continue") {
            setIsCancelBlocked(false);
            callback();
          }
        }}
      />
    );
  };

  return { UnsavedChangesAlert: UnsavedChangesAlertComponent, onClose: handleClose };
};
