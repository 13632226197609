import React, { useCallback, useMemo, useState } from "react";
import { cloneDeep, isEqual, omit, omitBy } from "lodash";
import { getIsConditionsEqual } from "../../functions/conditions";
import { useUnsavedAlertOnRedirectAndExit } from "../../hooks/useUnsavedAlertOnRedirectAndExit";
import { ReportFilterT } from "../../types/report";
import { WidgetT } from "../../types/widgets";
import { ActionBar } from "../../ui/ActionBar/ActionBar";
import { ButtonPrimary, ButtonTertiary } from "../../ui/Button/Button";
import { Col, Row } from "../../ui/grid/Grid";
import { Modal, ModalBody, useModal } from "../../ui/Modal";
import Widget from "../widget/Widget";
import { ReportBoardReducerStateT, WidgetSettingsDataT } from "./context/types";
import { ReportBoardEditWidgetSidebar } from "./ReportBoardEditWidgetSidebar";
import { WidgetContextT } from "./ReportBoardSection";

export type OnSetEditedWidgetT = React.Dispatch<React.SetStateAction<WidgetT>>;

type PropsT = {
  editedWidget: WidgetT;
  isCreating?: boolean;
  isFilterOpenDefault?: boolean;
  onClose: () => void;
  onSaveWidget: (widget: WidgetT) => void;
  reportFilter: ReportBoardReducerStateT["reportFilter"];
  sectionFilter: ReportFilterT;
  widgetContext: WidgetContextT;
  widgetSettingsData: WidgetSettingsDataT;
};

export const ReportBoardEditWidget = ({
  editedWidget: defaultWidget,
  isCreating,
  isFilterOpenDefault,
  onClose,
  onSaveWidget,
  reportFilter,
  sectionFilter,
  widgetContext,
  widgetSettingsData,
}: PropsT) => {
  const modalOptions = useModal({ isInitialOpen: true });
  const [editedWidget, setEditedWidget] = useState<WidgetT>(cloneDeep(defaultWidget));
  const [isFilterOpen, setIsFilterOpen] = useState(!!isFilterOpenDefault);

  const hasUnsavedChanges = useMemo(() => {
    return (
      !isEqual(
        omitBy(omit(defaultWidget, ["hasDefaultData", "conditions"]), (value) => !value),
        omitBy(omit(editedWidget, ["hasDefaultData", "conditions"]), (value) => !value || value === "")
      ) || !getIsConditionsEqual(defaultWidget.conditions, editedWidget.conditions)
    );
  }, [editedWidget, defaultWidget]);

  const handleSaveAndClose = useCallback(() => {
    onSaveWidget(editedWidget);
    onClose();
  }, [onSaveWidget, onClose, editedWidget]);

  const handleClouseWithoutSave = useCallback(() => {
    onClose();
  }, [onClose]);

  const { UnsavedChangesAlert, onClose: onModalClose } = useUnsavedAlertOnRedirectAndExit({
    hasUnsavedChanges,
    callback: handleClouseWithoutSave,
  });

  const modalTitle = (
    <Row padding="xl">
      <Col type="shrink">"Widget settings"</Col>
    </Row>
  );

  const editWidgetUpdate = useCallback(({ setWidget }: { setWidget: WidgetT | ((widget: WidgetT) => WidgetT) }) => {
    if (typeof setWidget === "function") {
      return setEditedWidget((prev) => setWidget(prev));
    }
    setEditedWidget(setWidget);
  }, []);

  const editWidgetContext = useMemo(
    () => ({
      ...widgetContext,
      widgetUpdate: editWidgetUpdate,
      widgetEditationOpenConditions: () => setIsFilterOpen(true),
    }),
    [widgetContext, editWidgetUpdate]
  );

  return (
    <>
      <Modal
        heading={modalTitle}
        size="fullwithGrid"
        {...{ ...modalOptions, isOpen: true, close: onModalClose }}
        testId="edit-widget-modal"
      >
        <ModalBody>
          <Row height="100%">
            <>
              <Col alignItems="center" className="pos-relative" justify="center" type="grow">
                {editedWidget && (
                  <div className="ph-40 pv-40 w-100" style={{ maxWidth: "1024px", minHeight: "500px" }}>
                    <Widget
                      sectionFilter={sectionFilter}
                      widget={editedWidget}
                      widgetContext={editWidgetContext}
                      isPreview
                    />
                  </div>
                )}

                <ActionBar hasAbsolutePosition>
                  <ButtonPrimary
                    data-test-id="close-widget-edit-save"
                    disabled={!hasUnsavedChanges && !isCreating}
                    onClick={handleSaveAndClose}
                  >
                    Save
                  </ButtonPrimary>
                  <ButtonTertiary onClick={handleClouseWithoutSave}>
                    {hasUnsavedChanges ? "Cancel" : "Close"}
                  </ButtonTertiary>
                </ActionBar>
              </Col>
              <Col className="pos-relative negative-mr-16" overflow>
                <ReportBoardEditWidgetSidebar
                  editedWidget={editedWidget}
                  isCreating={isCreating}
                  isFilterOpen={isFilterOpen}
                  reportFilter={reportFilter}
                  sectionFilter={sectionFilter}
                  widgetSettingsData={widgetSettingsData}
                  onSetEditedWidget={setEditedWidget}
                  onSetIsFilterOpen={setIsFilterOpen}
                />
              </Col>
            </>
          </Row>
        </ModalBody>
      </Modal>
      <UnsavedChangesAlert />
    </>
  );
};
