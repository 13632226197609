import React from "react";
import { Badge } from "../../ui/Badge/Badge";
import { HeadingSmall } from "../../ui/Heading/Heading";
import { Tile } from "../../ui/Tile/Tile";
import { Tooltip } from "../../ui/Tooltip/Tooltip";
import { UserSystemActionsTable, UserSystemActionsTablePropsT } from "./UserSystemActionTable";

type PropsT = {
  className?: string;
  userCampaignActions?: UserSystemActionsTablePropsT["userCampaignActions"];
};

export const BadgeChangesNumber = ({ className, userCampaignActions }: PropsT) => {
  if (!userCampaignActions || userCampaignActions.length === 0) {
    return null;
  }

  return (
    <Tooltip
      roleType="listbox"
      tooltipContent={
        <Tile className="elevate-3">
          <HeadingSmall>Changes in the last 7 days</HeadingSmall>
          <UserSystemActionsTable userCampaignActions={userCampaignActions} isCompact />
        </Tile>
      }
    >
      <Badge className={className} kind="lightGray">
        {userCampaignActions?.length}
      </Badge>
    </Tooltip>
  );
};
