import React from "react";
import { ReportTemplateListT } from "../../types/report";
import { Row } from "../../ui/grid/Grid";
import { TemplateCard } from "./TemplateCard";

export type TemplatePreviewListPropsT = {
  templates: ReportTemplateListT;
};

export const TemplatePreviewList = ({ templates }: TemplatePreviewListPropsT) => {
  return (
    <Row flexwrap>
      {templates?.map((template) => (
        <TemplateCard {...template} key={template.id} />
      ))}
    </Row>
  );
};
