import React from "react";
import cs from "classnames";
import { useLocation } from "react-router-dom";
import { Link } from "../../ui/Link/Link";

export type SidebarMenuCollectionT = { icon: string; label: string; link: string }[];
export type SidebarMenuComponentPropsT = { collection: SidebarMenuCollectionT };

export const SidebarMenuComponent = ({ collection }: SidebarMenuComponentPropsT) => {
  const { pathname } = useLocation();

  return (
    <ul className="Navigation ">
      {collection.map(({ icon, label, link }) => {
        const uniqName = `nav-organization-${label.replace(" ", "-").toLowerCase()}`;

        return (
          <li key={uniqName} className="mv-4">
            <Link className={cs("Navigation-item", { active: pathname === link })} data-test-id={uniqName} to={link}>
              <span className="d-flex align-items-center text-overflow">
                <span className={`Navigation-icon fc-${icon}`} />
                <span className="text-overflow js-text-overflow ml-16">{label}</span>
              </span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
