import React, { Dispatch, FormEvent, SetStateAction, useEffect } from "react";
import {
  add,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  isAfter,
  isEqual,
  startOfDay,
} from "date-fns";
import { sortBy } from "lodash";
import { formatDate, isEqualOnlyDate } from "../../functions/dateHelpers";
import { CurrencyT, KpiPeriodT, MetricDataT, MetricT } from "../../graphql/generated/graphql";
import { usePrevious } from "../../hooks/usePrevious";
import { formatDateRange } from "../../i18n/formatDate";
import { formatMetricValue } from "../../i18n/formatNumbers";
import Input from "../../ui/forms/Input";
import Select from "../../ui/forms/Select";
import { Col, Row } from "../../ui/grid/Grid";
import { Text } from "../../ui/Text/Text";
import { CURRENCY_OPTIONS as DEFAULT_CURRENCY_OPTIONS } from "../formEditWidget/inputs/InputCurrency";
import { KpiSemaphore, KpiSemaphorePropsT, KpiSemaphoreResultT } from "../kpiSemaphore/KpiSemaphore";
import { UserFilledTargetFormStateT } from "./FormKpi";

const CURRENCY_OPTIONS = [...DEFAULT_CURRENCY_OPTIONS];
CURRENCY_OPTIONS.shift();

type GetRangesT = (args: {
  array: Date[];
  end: Date;
  endGiven: boolean;
  periodEndFn: (date: Date) => Date;
  start: Date;
}) => { dateFrom: Date; dateTo: Date }[];

const getRanges: GetRangesT = ({ array, end: endInput, endGiven, periodEndFn, start }) => {
  const end = endGiven ? endInput : periodEndFn(endInput);

  return array.map((periodStart, index) => {
    if (array.length === 1) {
      return { dateFrom: start, dateTo: end };
    } else if (index === 0) {
      return { dateFrom: start, dateTo: periodEndFn(periodStart) };
    } else if (index === array.length - 1) {
      return { dateFrom: periodStart, dateTo: end };
    }
    return { dateFrom: periodStart, dateTo: periodEndFn(periodStart) };
  });
};

export type ResolvePeriodsParamsT = {
  endOn?: string;
  period: KpiPeriodT;
  startOn?: string;
};

const resolvePeriods = ({ endOn: endOnInput, period, startOn: startOnInput }: ResolvePeriodsParamsT) => {
  if (!startOnInput) {
    return [];
  }

  if (period === KpiPeriodT.NoneT && !endOnInput) {
    return [];
  }

  const start = new Date(startOnInput);
  const end = endOnInput ? new Date(endOnInput) : add(new Date(), { years: 1 });

  if (period === KpiPeriodT.NoneT) {
    return [{ dateFrom: start, dateTo: end }];
  }
  if (period === KpiPeriodT.WeekT) {
    const array = eachWeekOfInterval({ start, end }, { weekStartsOn: 1 });
    return getRanges({
      start,
      end,
      endGiven: !!endOnInput,
      array,
      periodEndFn: (date: Date) => endOfWeek(date, { weekStartsOn: 1 }),
    });
  }
  if (period === KpiPeriodT.MonthT) {
    const array = eachMonthOfInterval({ start, end });
    return getRanges({
      start,
      end,
      endGiven: !!endOnInput,
      array,
      periodEndFn: endOfMonth,
    });
  }
  if (period === KpiPeriodT.QuarterT) {
    const array = eachQuarterOfInterval({ start, end });
    return getRanges({
      start,
      end,
      endGiven: !!endOnInput,
      array,
      periodEndFn: endOfQuarter,
    });
  }
  if (period === KpiPeriodT.YearT) {
    const array = eachYearOfInterval({ start, end });
    return getRanges({
      start,
      end,
      endGiven: !!endOnInput,
      array,
      periodEndFn: endOfYear,
    });
  }
  return [];
};

const findTarget = ({
  startOn,
  userFilledTargets,
}: {
  startOn: Date;
  userFilledTargets: {
    currency?: CurrencyT | null;
    id?: string;
    startOn: Date;
    value: number | string;
  }[];
}): [
  boolean,
  { currency?: CurrencyT | null; id?: string | undefined; startOn: Date; value: number | string } | undefined
] => {
  const foundTarget = sortBy([...userFilledTargets], "startOn").findLast(
    (target) => startOfDay(target.startOn) <= startOfDay(startOn)
  );

  if (foundTarget) {
    return [formatDate(new Date(foundTarget.startOn)) === formatDate(startOn), foundTarget];
  }

  return [false, undefined];
};

type PropsT = ResolvePeriodsParamsT & {
  isEditing?: boolean;
  selectedCurrency?: CurrencyT;
  selectedMetric?: Pick<MetricT, "dataType">;
  setInputRef?: (element: HTMLDivElement | null, index: number) => void;
  setUserFilledTargetsFormState?: Dispatch<SetStateAction<UserFilledTargetFormStateT>>;
  userFilledTargetsFormState?: UserFilledTargetFormStateT;
} & (
    | {
        hasPreview: true;
        metric: KpiSemaphorePropsT["metric"];
        name: KpiSemaphorePropsT["name"];
        periodResults?: KpiSemaphoreResultT[] | null;
      }
    | {
        hasPreview?: never;
        metric?: never;
        name?: never;
        periodResults?: never;
      }
  );

export const FormKpiPeriods = ({
  endOn,
  hasPreview,
  isEditing,
  metric,
  name: kpiName,
  period,
  periodResults,
  selectedCurrency,
  selectedMetric,
  setInputRef,
  setUserFilledTargetsFormState,
  startOn,
  userFilledTargetsFormState,
}: PropsT) => {
  const isCurrencyVisible = selectedMetric?.dataType === MetricDataT.MoneyT;
  const userFilledTargets = userFilledTargetsFormState?.values;
  const isErrorVisible = userFilledTargetsFormState?.hasFirstInputTouched && userFilledTargetsFormState.hasErros;
  const firstTarget = sortBy([...(userFilledTargets || [])], "startOn")[0];
  const prevStartOn = usePrevious(startOn);
  const prevPeriod = usePrevious(period);

  useEffect(() => {
    if (
      setUserFilledTargetsFormState &&
      ((prevStartOn && startOn !== prevStartOn) || (prevPeriod && prevPeriod !== period))
    ) {
      setUserFilledTargetsFormState((prev) => ({
        ...prev,
        values: [],
      }));
    }
  }, [startOn, setUserFilledTargetsFormState, prevStartOn, prevPeriod, period]);

  const handleChangeTarget = (
    name: "currency" | "value",
    value: string | null,
    target: {
      currency?: CurrencyT | null;
      id?: string;
      startOn: Date;
      value: string;
    }
  ) => {
    if (setUserFilledTargetsFormState) {
      setUserFilledTargetsFormState((prevUserFilledTargetsForm) => {
        const { values: prevUserFilledTargets } = prevUserFilledTargetsForm;

        const filledTargetIndex = prevUserFilledTargets.findIndex((filledTarget) => {
          return (target.id && filledTarget.id === target.id) || isEqual(filledTarget.startOn, target.startOn);
        });
        const hasFilledTarget = filledTargetIndex !== -1;

        if (!hasFilledTarget) {
          return {
            ...prevUserFilledTargetsForm,
            values: [...prevUserFilledTargets, { ...target, [name]: value }],
          };
        }
        if (name === "value" && value === "") {
          const newValues = [...prevUserFilledTargets];
          newValues.splice(filledTargetIndex, 1);
          return {
            ...prevUserFilledTargetsForm,
            values: newValues,
          };
        }
        return {
          ...prevUserFilledTargetsForm,
          values: prevUserFilledTargets.map((prevTarget, index) =>
            index === filledTargetIndex ? { ...target, [name]: value } : prevTarget
          ),
        };
      });
    }
  };

  useEffect(() => {
    if (setUserFilledTargetsFormState) {
      setUserFilledTargetsFormState((prev) => ({
        ...prev,
        hasErros:
          !firstTarget?.value ||
          !!(
            startOn &&
            firstTarget?.startOn &&
            !isEqual(startOfDay(firstTarget.startOn), startOfDay(new Date(startOn)))
          ),
      }));
    }
  }, [firstTarget, startOn, setUserFilledTargetsFormState]);

  return (
    <div>
      <Row alignItems="center">
        <Col type="grow">
          <Text bold>Period</Text>
        </Col>
        <Col type="grow">
          <Text bold>Target</Text>
        </Col>
        <Col width={"120px"} />
        {hasPreview && (
          <>
            <Col width="100px">Result</Col>
            <Col width="200px">Result (%)</Col>
          </>
        )}
      </Row>

      {resolvePeriods({ startOn, endOn, period }).map((periodRange, index) => {
        const [userFilled, target] = findTarget({
          userFilledTargets: userFilledTargets || [],
          startOn: periodRange.dateFrom,
        });
        const isFuturePeriod = isAfter(periodRange.dateFrom, endOfDay(new Date()));
        const hasDisabledInputs = (!isFuturePeriod && isEditing) || hasPreview;
        const periodResult = periodResults?.find((result) => {
          return isEqualOnlyDate(new Date(result?.startOn), periodRange.dateFrom);
        });

        return (
          <Row
            key={`${periodRange.dateFrom}-${periodRange.dateTo}`}
            alignItems="center"
            className="border-color-gray border-top pt-8"
          >
            <Col type="grow">{formatDateRange(periodRange.dateFrom, periodRange.dateTo)}</Col>
            <Col type="grow">
              {
                <Input
                  error={index === 0 && isErrorVisible ? { type: "", message: "This target is required" } : undefined}
                  inputProps={{
                    value: userFilled ? target?.value : "",
                    placeholder: !userFilled && target?.value ? `${target?.value}` : "",
                    type: "number",
                    disabled: hasDisabledInputs,
                    ref: setInputRef ? (element) => setInputRef(element, index) : undefined,
                    onChange: (event: FormEvent<HTMLInputElement>) =>
                      handleChangeTarget("value", event.currentTarget.value, {
                        id: userFilled ? target?.id : undefined,
                        currency: target?.currency,
                        startOn: periodRange.dateFrom,
                        value: userFilled ? `${target?.value}` : "",
                      }),
                    onBlur:
                      index === 0 && setUserFilledTargetsFormState
                        ? () => setUserFilledTargetsFormState((prev) => ({ ...prev, hasFirstInputTouched: true }))
                        : undefined,
                  }}
                />
              }
            </Col>
            <Col width={"120px"}>
              {userFilled && target?.value && isCurrencyVisible && (
                <Select
                  collection={CURRENCY_OPTIONS}
                  selectProps={{
                    value: userFilled ? `${target?.currency || selectedCurrency}` : "",
                    placeholder: !userFilled && target?.value ? `${target?.value}` : "",
                    disabled: hasDisabledInputs,
                    onChange: (event: FormEvent<HTMLSelectElement>) =>
                      handleChangeTarget(
                        "currency",
                        (event.currentTarget.value ? event.currentTarget.value : null) as CurrencyT | null,
                        {
                          id: userFilled ? target?.id : undefined,
                          currency: target?.currency,
                          startOn: periodRange.dateFrom,
                          value: userFilled ? `${target?.value}` : "",
                        }
                      ),
                  }}
                />
              )}
            </Col>
            {hasPreview && (
              <>
                <Col width="100px">
                  {formatMetricValue({ metric, value: periodResult?.currentValue, currency: periodResult?.currency })}
                </Col>
                <Col width="200px">
                  <KpiSemaphore metric={metric} name={kpiName} result={periodResult} />
                </Col>
              </>
            )}
          </Row>
        );
      })}
    </div>
  );
};
