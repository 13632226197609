import React, { ForwardedRef, forwardRef } from "react";
import classNames from "classnames";
import Select, { GroupBase, MultiValue, OnChangeValue, PropsValue, SingleValue, StylesConfig } from "react-select";
import CreatableSelect, { CreatableProps } from "react-select/creatable";
import SelectT from "react-select/dist/declarations/src/Select";
import { Loader } from "../Loader/Loader";
import { CustomClearIndicator } from "./CustomClearIndicator";
import { CustomDropdownIndicator } from "./CustomDropdownIndicator";
import { CustomMultiValueRemove } from "./CustomMultiValueRemove";
import { smartSelectStyles } from "./styles/SmartSelectStyles";

export type SmartSelectPropsT = {
  className?: string;
  isCreatable?: boolean;
  isLoading?: boolean;
  onChange?: (selected: unknown[] | unknown) => void;
};

const getDefaultValue = <Option,>({ value }: { value: PropsValue<Option> }) => {
  if (!value) {
    return undefined;
  }

  if (Array.isArray(value)) {
    return (value || []).map((opt) => ({ value: opt, label: opt })) as unknown as MultiValue<Option>;
  }
  return { value, label: value } as unknown as SingleValue<Option>;
};

export const SmartSelect = forwardRef(
  <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    {
      className,
      defaultValue,
      isLoading,
      isMulti,
      onBlur,
      onChange,
      onFocus,
      options = [],
      value,
      isCreatable = true,
      ...props
    }: CreatableProps<Option, IsMulti, Group> & SmartSelectPropsT,
    ref: ForwardedRef<SelectT<Option, IsMulti, Group>>
  ) => {
    const handleChange = (selected: OnChangeValue<Option, IsMulti>) => {
      if (!onChange) {
        return undefined;
      }

      if (Array.isArray(selected)) {
        return onChange(selected.map((item) => item.value));
      }
      return onChange((selected as SingleValue<{ value: unknown }>)?.value);
    };

    const sharedProps = {
      ...props,
      classNamePrefix: "SmartSelect",
      createOptionPosition: "first" as const,
      defaultValue: getDefaultValue({ value: defaultValue || value || [] }),
      hideSelectedOptions: false,
      isLoading: isLoading,
      isMulti: isMulti,
      options: options.map((opt) => ({ value: opt, label: opt })) as unknown as readonly (Option | Group)[],
      styles: smartSelectStyles as StylesConfig<Option, IsMulti, Group>,
      className: classNames("SmartSelect", className, {
        "SmartSelect--loading pointer-events-none no-user-select": isLoading,
      }),
      components: {
        DropdownIndicator: CustomDropdownIndicator,
        ClearIndicator: CustomClearIndicator,
        MultiValueRemove: CustomMultiValueRemove,
        LoadingIndicator: () => <Loader size="small" absolute />,
      },
      isSearchable: true,
      onblur,
      onChange: handleChange,
      onFocus,
    };

    if (isCreatable) {
      return <CreatableSelect ref={ref} {...sharedProps} />;
    }

    return <Select ref={ref} {...sharedProps} />;
  }
);
