import React, { useCallback } from "react";
import { ReportSharing, ReportSharingPropsT } from "../../components/reportSharing/ReportSharing";
import { notifyError, notifySucces } from "../../functions/toast";
import {
  ReportPageQueryResultT,
  SharedReportCreateMutationVariablesT,
  SharedReportUpdateMutationVariablesT,
  useSharedReportCreateMutation,
  useSharedReportDeleteMutation,
  useSharedReportQuery,
  useSharedReportUpdateMutation,
} from "../../graphql/generated/graphql";
import { useAppSettings } from "../../providers/appSettingsProvider";

type PropsT = Pick<ReportSharingPropsT, "pages" | "reportName"> & {
  reportId: string;
  reportRefetch: ReportPageQueryResultT["refetch"];
};

export const ReportSharingConnected = ({
  reportId,
  reportRefetch,
  ...props
}: Pick<PropsT, "pages" | "reportName" | "reportRefetch"> & {
  reportId: string;
}) => {
  const { organization } = useAppSettings();

  const [mutateDelete] = useSharedReportDeleteMutation({
    onCompleted: (response) => {
      const errors = response.organization?.deleteSharedReport?.errors;
      if (errors?.length) {
        return notifyError(<>{errors.join("\n")}</>);
      }
      reportRefetch();
    },
    onError: () => notifyError(<>Delete link failed</>),
  });

  const [mutateUpdate, { loading: isLoadingUpdate }] = useSharedReportUpdateMutation({
    onCompleted: (response) => {
      const errors = response.organization?.updateSharedReport?.errors;
      if (errors?.length) {
        return notifyError(<>{errors.join("\n")}</>);
      }
      notifySucces(<>Report was succesfully updated!</>);
      reportRefetch();
    },
    onError: () => notifyError(<>Update link name failed</>),
  });

  const queryResult = useSharedReportQuery({
    variables: {
      organizationExternalId: organization.externalId,
      reportId: reportId,
    },
  });

  const [mutateCreate, { loading: isLoadingCreate }] = useSharedReportCreateMutation({
    onCompleted: async (response) => {
      const errors = response.organization?.createSharedReport?.errors;
      if (errors?.length) {
        return notifyError(<>{errors.join("\n")}</>);
      }
      notifySucces(
        <>
          Link <strong>{response.organization?.createSharedReport?.sharedReport?.name}</strong> was created
        </>
      );
      reportRefetch();
    },
    onError: () => notifyError("Error when creating link"),
  });

  const handleCreateSharedReport = useCallback(
    async ({
      onCompleted,
      variables,
    }: {
      onCompleted?: () => void;
      variables: Omit<SharedReportCreateMutationVariablesT, "organizationExternalId" | "reportId">;
    }) => {
      await mutateCreate({
        variables: {
          organizationExternalId: organization.externalId,
          reportId: reportId,
          ...variables,
        },
        onCompleted,
      });
    },
    [mutateCreate, organization, reportId]
  );

  const handleUpdateSharedReport = useCallback(
    async ({
      onCompleted,
      variables,
    }: {
      onCompleted?: () => void;
      variables: Omit<SharedReportUpdateMutationVariablesT, "organizationExternalId">;
    }) => {
      await mutateUpdate({
        variables: {
          organizationExternalId: organization.externalId,
          generateToken: false,
          ...variables,
        },
        onCompleted,
      });
    },
    [mutateUpdate, organization]
  );

  const handleDeleteSharedReport = useCallback(
    async (sharedReportId: string) => {
      await mutateDelete({
        variables: {
          organizationExternalId: organization.externalId,
          sharedReportId,
        },
      });
    },
    [mutateDelete, organization]
  );

  return (
    <ReportSharing
      {...props}
      isLoadingCreate={isLoadingCreate}
      isLoadingData={queryResult.loading}
      isLoadingUpdate={isLoadingUpdate}
      sharedReports={queryResult.data?.organization?.report?.sharedReports.nodes || []}
      onCreateSharedReport={handleCreateSharedReport}
      onDeleteSharedReport={handleDeleteSharedReport}
      onRefetchData={queryResult.refetch}
      onUpdateSharedReport={handleUpdateSharedReport}
    />
  );
};
