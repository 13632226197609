import React, { ChangeEvent, ReactNode, useState } from "react";
import cs from "classnames";
import { SegmentedButtonContext } from "./segmentedButtonContext";

export const SEGMENTED_BUTTON_SIZES = ["small", "medium", "large"] as const;

type SegmentedButtonSizesT = typeof SEGMENTED_BUTTON_SIZES[number];
type PropsT = {
  children: ReactNode;
  className?: string;
  doNotUseInternalState?: boolean;
  id: string;
  isFullWidth?: boolean;
  name: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onlyIcons?: boolean;
  size?: SegmentedButtonSizesT;
  testId: string;
  value: string | number;
};

export const SegmentedButton = ({
  children,
  className,
  doNotUseInternalState,
  id,
  isFullWidth,
  name,
  onChange,
  onlyIcons,
  size,
  testId,
  value,
}: PropsT) => {
  const [buttonState, setButtonState] = useState({ value });
  const selected = doNotUseInternalState ? value : buttonState.value;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value;
    setButtonState((prevState) => ({ ...prevState, value: targetValue }));
    if (typeof onChange === "function") {
      onChange(event);
    }
  };

  const contextValue = { selected, name, id, onChange: handleChange, testId };

  return (
    <div
      className={cs(className, "Segmented", `Segmented--${size}`, "radio_buttons", {
        "Segmented--onlyIcons": onlyIcons,
        "Segmented--fullWidth": isFullWidth,
      })}
    >
      <div className="Segmented-wrapper" data-test-id={`${testId}-wrapper`}>
        <input id={id} name={name} type="hidden" value="" />
        <SegmentedButtonContext.Provider value={contextValue}>{children}</SegmentedButtonContext.Provider>
      </div>
    </div>
  );
};
