import React from "react";
import { useParams } from "react-router-dom";
import { withApiStateHandler } from "../components/ErrorLoadingWrapper/withApiStateHandler";
import { ReporBoardProvider } from "../components/reportBoard/context/reportBoardContext";
import { ReportBoard } from "../components/reportBoard/ReportBoard";
import { ReportPageQueryT, useReportPageQuery } from "../graphql/generated/graphql";
import { Layout } from "../layout/wrappers/Layout";
import { useAppSettings } from "../providers/appSettingsProvider";
import { RouteParamsT } from "../types/router";

type PropsT = { data?: ReportPageQueryT };

const ReportPreviewComponent = withApiStateHandler(({ data }: PropsT) => {
  return (
    <Layout>
      {data?.organization?.report ? (
        <ReporBoardProvider report={data.organization.report} reportOptions={{ canEdit: false }}>
          <ReportBoard />
        </ReporBoardProvider>
      ) : (
        <div>Loading...</div>
      )}
    </Layout>
  );
});

export const ReportPreviewPage = () => {
  const { reportId } = useParams<RouteParamsT["report"]>();
  const { organization } = useAppSettings();

  const { data, error, loading } = useReportPageQuery({
    variables: { organizationExternalId: organization.externalId, reportId: reportId as string },
    skip: !reportId,
  });

  return <ReportPreviewComponent data={data} error={error} loading={loading} />;
};
