import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { mergeApiErrors } from "../functions/mergeApiErrors";
import { notifyError, notifySucces } from "../functions/toast";
import {
  ReportCreateMutationVariablesT,
  useOrganizationIdQuery,
  useReportCreateMutation,
} from "../graphql/generated/graphql";
import { useAppSettings } from "../providers/appSettingsProvider";

export const useCreateReport = ({
  namePrefix,
  shouldGoToReport = true,
  successMessage,
}: {
  namePrefix?: string;
  shouldGoToReport?: boolean;
  successMessage: string;
}) => {
  const { organization } = useAppSettings();
  const navigate = useNavigate();
  const [mutationErrors, setMutationErrors] = useState<string[] | null | undefined>([]);

  const { data } = useOrganizationIdQuery({
    variables: { organizationExternalId: organization.externalId },
  });

  const [mutate, mutateState] = useReportCreateMutation({
    onCompleted: (mutateData) => {
      const newReport = mutateData.organization?.createReport;
      const errors = newReport?.errors;
      const newReportId = newReport?.report?.id;

      if (!!errors?.length) {
        setMutationErrors(errors);
        notifyError(<>Error when saving report</>);
      }
      if (newReportId) {
        notifySucces(<>{successMessage}</>);
        if (shouldGoToReport) {
          navigate(ROUTES.report(newReportId));
        }
      }
    },
    onError: () => notifyError(<>Server error when creating report!</>),
    update: (cache) => {
      if (data?.organization?.id) {
        const org = cache.identify({ __typename: "Organization", id: data.organization.id });
        cache.evict({ id: org, fieldName: "reports" });
        cache.gc();
      }
    },
  });

  const handleCreateReport = (values: Partial<ReportCreateMutationVariablesT>) => {
    mutate({
      variables: {
        ...values,
        organizationExternalId: organization.externalId,
        name: namePrefix ? [namePrefix, values.name].join(" ") : values.name || "",
      },
    });
  };

  return {
    loading: mutateState.loading,
    data: mutateState.data,
    createReport: handleCreateReport,
    errors: mergeApiErrors(mutationErrors, mutateState.error),
  };
};
