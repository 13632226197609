/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable no-console */
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import possibleTypes from "../graphql/generated/possibleTypes.json";

let counter = 0;

document.addEventListener("DOMContentLoaded", () => {
  if (document.body) {
    document.body.dataset.graphqlActive = "false";
  }
});

type OptionalHeadersT = {
  Authorization?: string;
  "X-CSRF-Token": string;
};

export const cache = new InMemoryCache({
  possibleTypes,
});

export const createClient = ({
  apiToken,
  apiUri,
  csrfToken,
  reportToken,
}: {
  apiToken?: string;
  apiUri: string;
  csrfToken: string;
  reportToken?: string;
}) => {
  const headers = (
    reportToken
      ? {
          "X-CSRF-Token": csrfToken,
        }
      : {
          "X-CSRF-Token": csrfToken,
          Authorization: `ApiToken ${apiToken}`,
        }
  ) as OptionalHeadersT;

  if (!apiToken) {
    delete headers.Authorization;
  }

  const httpLink = new HttpLink({
    credentials: "same-origin",
    headers,
    uri: reportToken ? apiUri.replace("graphql", "public_graphql") : apiUri,
  });

  const watcherMiddleware = new ApolloLink((operation, forward) => {
    counter += 1;
    let isSubtracted = false;

    document.body.dataset.deeGraphqlActive = `${counter > 0}`;

    setTimeout(() => {
      if (!isSubtracted) {
        counter -= 1;
        isSubtracted = true;
        document.body.dataset.deeGraphqlActive = `${counter > 0}`;
      }
    }, 3000);

    return forward(operation).map((data) => {
      if (!isSubtracted) {
        counter -= 1;
        isSubtracted = true;
        setTimeout(() => {
          document.body.dataset.deeGraphqlActive = `${counter > 0}`;
        }, 100);
      }
      return data;
    });
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ locations, message, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  return new ApolloClient({
    link: from([errorLink, watcherMiddleware, httpLink]),
    cache,
    connectToDevTools: process.env.NODE_ENV === "development",
  });
};
