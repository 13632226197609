import React from "react";
import { SystemIconBasicPropsT } from "./types";

export const SklikIcon = ({ active, size = "16px" }: SystemIconBasicPropsT) => (
  <svg
    className={`system-icon sklik-icon ${active && "active"}`}
    height={size}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 49.9 36.5"
    width={size}
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
    // style={{ enableBackground: "new 0 0 49.9 36.5" }}
  >
    <g>
      <path
        fill="#d51217"
        d="M41.4,0.5C42.2-1,43,1.2,43.7,3.9c0.7,2.7,2.5,5.6-2.2,6c-1.8,0-3.5-0.2-7,0
        c-3.2,0.2-3.9,0.4-6.1,1.1c-1.1,0.4-1.1,1.6-0.1,2.2c0.7,0.4,4.1,1.7,7.1,2.9c3.8,1.5,9.4,3.4,11.7,5.2c3.5,2.7,3.3,6.7,1.9,9.1
        c-1.5,2.6-6.1,4.2-12.3,5.1c-8.3,1.2-24.3,1.1-30,0.6C1,35.5-0.1,35.2,0,34.5c0.1-0.7,4-0.5,7.4-0.7c4.4-0.3,23.2-1.9,27.4-5
        c2.6-1.9,1.1-3.5,0.7-3.8c-1.1-1.3-5.3-3.1-8.9-5c-5.3-2.7-9.3-5.1-10.3-7.3c-0.8-1.7-0.7-3.9,1.3-5.9c2.3-2.3,5-3.5,8.6-4.4
        c3.9-1,6.2-1,9.3-1.1C38.1,1.3,40.9,1.4,41.4,0.5"
      />
    </g>
  </svg>
);
