import React from "react";
import { AccountSelectKpiSettingConnected } from "../../componentsConnected/accountSelect/AccountSelectKpiSetting";
import { SourceSystemsT } from "../../types/report";
import { SelectableMetricT } from "../../ui/forms/MetricDimensionSelectTabs";
import { InfoBox } from "../../ui/InfoBox/InfoBox";
import { Text } from "../../ui/Text/Text";

type PropsT = {
  isCustomValueMetric: boolean;
  metricId: string;
  metrics?: SelectableMetricT[];
  onChangeAccounts: (newSourceSystems: SourceSystemsT) => void;
  sourceSystems?: SourceSystemsT;
};

export const FormKpiConnectedAccounts = ({
  isCustomValueMetric,
  metricId,
  metrics,
  onChangeAccounts,
  sourceSystems,
}: PropsT) => {
  if (isCustomValueMetric) {
    return null;
  }

  return (
    <>
      <Text className="mb-8" tag="p" bold>
        Connect Accounts
      </Text>
      {metricId === "" ? (
        <div>
          <InfoBox isCompact>Please select metric first to connect account</InfoBox>
        </div>
      ) : (
        <AccountSelectKpiSettingConnected
          selectedMetDims={{ metrics: [metricId] }}
          sourceSystems={sourceSystems}
          widgetSettingsData={{ metrics: metrics || [] }}
          onSubmit={onChangeAccounts}
        />
      )}
    </>
  );
};
