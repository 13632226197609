import { createContext, useContext } from "react";

type TabsContextT = {
  initialSelectedTab: string | null;
  selectTab: (tab: string) => void;
  selectedTab: string | null;
  testId?: string;
};

const TabsContext = createContext<TabsContextT>({ selectedTab: null, selectTab: () => {}, initialSelectedTab: null });

const useTabsContext = () => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error(`Tabs compound components cannot be rendered outside the Tabs component`);
  }
  return context;
};

export { useTabsContext, TabsContext };
