import { HTMLAttributes, PropsWithChildren } from "react";
import { Row } from "../grid/Grid";
import { Text } from "../Text/Text";

type PropsT = PropsWithChildren<
  { description?: string; heading?: string; image?: string } & HTMLAttributes<HTMLDivElement>
>;

export const EmptyState = ({ children, description, heading, image, style, ...rest }: PropsT) => {
  return (
    <Row justify="center">
      <div className="EmptyContainer" style={{ maxWidth: "500px", ...style }} {...rest}>
        <div className="EmptyContainer-content">
          {image && <img alt="Empty state icon" src={image} />}
          {heading && heading.length > 0 && <div className="Heading Heading--3xl mt-16 mb-16">{heading}</div>}
          {description && (
            <div>
              <Text className="mt-16">{description}</Text>
            </div>
          )}
          {children}
        </div>
      </div>
    </Row>
  );
};
