import React from "react";
import { useParams } from "react-router-dom";
import { withApiStateHandler } from "../components/ErrorLoadingWrapper/withApiStateHandler";
import { FormKpiPeriods } from "../components/formKpi/FormKpiPeriods";
import { KpiPeriodComparsionChart } from "../components/kpiPeriodComparsionChart/KpiPeriodComparsionChart";
import { KpiProgressChart } from "../components/kpiProgressChart/KpiProgressChart";
import { KpiSemaphore } from "../components/kpiSemaphore/KpiSemaphore";
import { ROUTES } from "../constants/routes";
import { formatDate, getDaysFromDate } from "../functions/dateHelpers";
import { getKpiTargetEndOn } from "../functions/kpisHelper";
import { KpiStatusT, KpisDetailPageQueryT, useKpisDetailPageQuery } from "../graphql/generated/graphql";
import { formatDateRange } from "../i18n/formatDate";
import { formatMetricValue } from "../i18n/formatNumbers";
import { Breadcrumb } from "../layout/wrappers/Breadcrumb";
import { BreadcrumbItem } from "../layout/wrappers/BreadcrumbItem";
import { Layout } from "../layout/wrappers/Layout";
import { useAppSettings } from "../providers/appSettingsProvider";
import { RouteParamsT } from "../types/router";
import { Badge } from "../ui/Badge/Badge";
import { Col, Row } from "../ui/grid/Grid";
import { Heading, HeadingSection, HeadingSmall } from "../ui/Heading/Heading";
import { Icon } from "../ui/Icon/Icon";
import { InfoBox } from "../ui/InfoBox/InfoBox";
import { Text } from "../ui/Text/Text";
import { Tile } from "../ui/Tile/Tile";

type PropsT = {
  data?: KpisDetailPageQueryT;
};

const KpisDetailPageComponent = withApiStateHandler(({ data }: PropsT) => {
  const kpiSetting = data?.organization?.kpiSetting;
  const isFinished = kpiSetting?.status === KpiStatusT.FinishedT;
  const isScheduled = kpiSetting?.status === KpiStatusT.ScheduledT;
  const isDraft = kpiSetting?.status === KpiStatusT.DraftT;

  return (
    <Layout backTo={ROUTES.kpis()} title={kpiSetting?.name}>
      <Breadcrumb>
        <BreadcrumbItem>KPIs Dashboard</BreadcrumbItem>
      </Breadcrumb>

      {isDraft && (
        <InfoBox className="mb-24" variant="warning">
          This KPI has status "Draft" until required account will be connected.
        </InfoBox>
      )}

      <Row padding="xl">
        <Col width="calc(40% - 16px)">
          <Tile isFullHeight>
            <HeadingSection>Description</HeadingSection>
            <Text color="gray">{kpiSetting?.description}</Text>
          </Tile>
        </Col>
        <Col width="calc(30% - 16px)">
          <Tile isFullHeight>
            <HeadingSection>Period</HeadingSection>
            <Row justify="center">
              <Badge kind="gray" size="extraLarge">
                {kpiSetting?.period}
              </Badge>
            </Row>
            <Row className="mt-24" gap={24} justify="center">
              <Text color="gray">Start: {formatDate(kpiSetting?.startOn)}</Text>
              <Text color="gray">End: {formatDate(kpiSetting?.endOn) || "-"}</Text>
            </Row>
          </Tile>
        </Col>
        <Col width="calc(30% - 16px)">
          <Tile isFullHeight>
            <Row direction="col" height="100%">
              <Col justify="center">
                <HeadingSection>Priority</HeadingSection>
              </Col>
              <Col alignItems="center" justify="center" type="grow">
                <Badge kind="gray" size="extraLarge">
                  {kpiSetting?.priority}
                </Badge>
              </Col>
            </Row>
          </Tile>
        </Col>
      </Row>

      <Row className="mt-24" padding="xl">
        <Col width="calc(40% - 16px)">
          {!isScheduled && (
            <Tile isFullHeight>
              <HeadingSection>{isFinished ? "Final score" : "KPI Status"}</HeadingSection>
              <Text color="gray">
                KPI status indicates the overall score of your KPI. Here you can monitor whether your KPI is On track or
                Behind. Thanks to this, you know how much to focus on it.
              </Text>
              <Tile className="mt-24">
                {kpiSetting && (
                  <Row justify="center">
                    <KpiSemaphore {...kpiSetting} hideBadge={isFinished} result={kpiSetting?.lastResult} size="lg" />
                  </Row>
                )}

                {kpiSetting && (
                  <>
                    <hr className="mv-24" />
                    <Row justify="center">
                      <KpiProgressChart
                        currency={kpiSetting.currency}
                        currentValue={kpiSetting.lastResult?.currentValue}
                        dataType={kpiSetting.metric.dataType}
                        estimatedPeriodValue={kpiSetting.lastResult?.estimatedPeriodValue}
                        positiveTrend={kpiSetting.metric.positiveTrend}
                        predictionKind={kpiSetting.metric.predictionKind}
                        progressThresholdLow={kpiSetting.progressThresholdLow}
                        progressThresholdOk={kpiSetting.progressThresholdOk}
                        targetValue={kpiSetting.lastResult?.targetValue}
                      />
                    </Row>
                  </>
                )}
              </Tile>

              <Row className="mt-24 mb-16">
                <Col alignItems="center" className="border-right border-color-soft-gray" type="grow">
                  <Text color="softGray">Current Value</Text>
                  <HeadingSmall margin={0}>
                    {kpiSetting &&
                      formatMetricValue({
                        metric: kpiSetting.metric,
                        value: kpiSetting.lastResult?.currentValue,
                        currency: kpiSetting.lastResult?.currency,
                      })}
                  </HeadingSmall>
                </Col>
                <Col alignItems="center" className="border-right border-color-soft-gray" type="grow">
                  <Text color="softGray">Target</Text>
                  <HeadingSmall margin={0}>
                    {kpiSetting &&
                      formatMetricValue({
                        metric: kpiSetting.metric,
                        value: kpiSetting.lastResult?.targetValue,
                        currency: kpiSetting.lastResult?.currency,
                      })}
                  </HeadingSmall>
                </Col>
                <Col alignItems="center" type="grow">
                  <Text color="softGray">Estimated End Value</Text>
                  <HeadingSmall margin={0}>
                    {kpiSetting && !isFinished
                      ? formatMetricValue({
                          metric: kpiSetting.metric,
                          value: kpiSetting.lastResult?.estimatedPeriodValue,
                          currency: kpiSetting.lastResult?.currency,
                        })
                      : "-"}
                  </HeadingSmall>
                </Col>
              </Row>
            </Tile>
          )}

          {isScheduled && (
            <Tile>
              <HeadingSection>KPI Status</HeadingSection>
              <Text color="gray">
                KPI status indicates the overall score of your KPI. Here you can monitor whether your KPI is On track or
                Behind. Thanks to this, you know how much to focus on it.
              </Text>

              <Tile className="mt-24">
                <Col alignItems="center">
                  <Icon color="#8c969f" kind="calendar" size="3em" />
                  <Heading
                    className="mt-16"
                    color="softGray"
                    level="h1"
                    margin={0}
                    style={{ fontSize: "36px", lineHeight: "44px" }}
                  >
                    Scheduled
                  </Heading>
                </Col>
                <hr />
                <Text className="text-center" style={{ fontSize: "18px" }} tag="p">
                  {/* TODO - conditional plular inflection */}
                  Monitoring start:{" "}
                  <b>
                    {formatDate(kpiSetting.startOn)} ({getDaysFromDate(new Date(kpiSetting.startOn)) * -1 + 1} days
                    left)
                  </b>
                </Text>
              </Tile>

              <Row className="mt-24 mb-16">
                <Col alignItems="center" className="border-right border-color-soft-gray" type="grow">
                  <Text color="softGray">First period</Text>
                  <HeadingSmall>
                    {kpiSetting &&
                      formatDateRange(
                        kpiSetting.targets?.[0].startOn,
                        getKpiTargetEndOn({ startOn: kpiSetting.targets?.[0].startOn, kpiSetting })
                      )}
                  </HeadingSmall>
                </Col>
                <Col alignItems="center" type="grow">
                  <Text color="softGray">First Period Target</Text>
                  <HeadingSmall>
                    {kpiSetting && !isFinished
                      ? formatMetricValue({
                          metric: kpiSetting.metric,
                          value: kpiSetting.targets?.[0]?.value,
                          currency: kpiSetting.targets?.[0]?.currency,
                        })
                      : "-"}
                  </HeadingSmall>
                </Col>
              </Row>
            </Tile>
          )}
        </Col>
        <Col width="calc(60% - 16px)">
          <Tile isFullHeight>
            <Col height="100%" padding="n" type="grow">
              <Row>
                <HeadingSection>Period comparsion</HeadingSection>
              </Row>
              <Row>
                <Text color="gray" tag="p">
                  The following chart compares how the KPI fared in each period. It allows you to track the fulfillment
                  of the goal over time.
                </Text>
              </Row>
              {kpiSetting && !isScheduled && (
                <Row className="mt-24">
                  <KpiPeriodComparsionChart {...kpiSetting} height={289} />
                </Row>
              )}
              {isScheduled && (
                <Row className="mt-a mb-a" justify="center">
                  <InfoBox isCompact>KPI is scheduled, will start on {formatDate(kpiSetting.startOn)}</InfoBox>
                </Row>
              )}
            </Col>
          </Tile>
        </Col>
      </Row>

      <Tile className="mt-24">
        <HeadingSection>KPI Targets</HeadingSection>
        <Text className="mb-24" color="gray" tag="p">
          KPI targets show an overview of KPI fulfillment in each period. You can see both the target value and the
          actual achieved value.
        </Text>
        {kpiSetting && (
          <FormKpiPeriods
            {...kpiSetting}
            userFilledTargetsFormState={{
              values:
                kpiSetting.targets?.map((target) => ({
                  ...target,
                  value: `${target.value}`,
                  startOn: new Date(target.startOn),
                })) || [],
            }}
            hasPreview
          />
        )}
      </Tile>
    </Layout>
  );
});

export const KpisDetailPage = () => {
  const { kpiSettingId } = useParams<RouteParamsT["kpisDetail"]>();
  const { organization } = useAppSettings();

  const { data, error, loading } = useKpisDetailPageQuery({
    variables: { organizationExternalId: organization.externalId, kpiSettingId: kpiSettingId as string },
    fetchPolicy: "network-only",
    skip: !kpiSettingId,
  });

  return <KpisDetailPageComponent data={data} error={error} loading={loading} />;
};
