import React, { useState } from "react";
import cs from "classnames";
import keyImage from "../../assets/key.svg";
import { ButtonSecondary } from "../../ui/Button/Button";
import Input from "../../ui/forms/Input";
import { HeadingPage } from "../../ui/Heading/Heading";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";

type PropsT = {
  error?: string;
  loading?: boolean;
  onSubmit: (password: string) => void;
};

export const PasswordBox = ({ error, loading, onSubmit }: PropsT) => {
  const [password, setPassword] = useState("");

  return (
    <Tile className="text-center" style={{ maxWidth: "374px" }}>
      <img alt="Data source" src={keyImage} />
      <HeadingPage className="mt-16">Report is Locked</HeadingPage>
      <p>
        You need a password to access <Text bold>Monthly Unique Visitors</Text>
      </p>

      <form
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit(password);
        }}
      >
        <Input
          className={cs({ "pb-16": !error })}
          error={{ message: error, type: "" }}
          inputProps={{
            onChange: (event) => setPassword(event.currentTarget.value),
            type: "password",
          }}
        />

        <ButtonSecondary className="mt-16" disabled={!password} loading={loading} block>
          Continue
        </ButtonSecondary>
      </form>
    </Tile>
  );
};
