import React from "react";
import { notifyError, notifySucces } from "../functions/toast";
import {
  CustomDimensionsQueryResultT,
  useCustomDimensionsQuery,
  useDeleteCustomDimensionMutation,
} from "../graphql/generated/graphql";
import { useAppSettings } from "../providers/appSettingsProvider";
import { SortableAdditionalPropsT, SortableWrapper } from "../ui/DataTable/Sortable";
import { TBody, THead, Table, Th, TrHeader } from "../ui/Table/Table";
import { CustomDimensionT, DimensionTableRow } from "./DimensionTableRow";
import { GeneralMetricDimensionTable } from "./GeneralMetricDimensionTable";

type TableRowsT = CustomDimensionT[];

export const DimensionsTable = ({
  refetch,
  sortableProps,
  tableRows,
}: Pick<CustomDimensionsQueryResultT, "refetch"> & {
  sortableProps: SortableAdditionalPropsT;
  tableRows: TableRowsT;
}) => {
  const { organization } = useAppSettings();

  const [deleteCustomDimension] = useDeleteCustomDimensionMutation({
    onCompleted: (res) => {
      const errors = res.organization?.deleteCustomDimension?.errors;
      if (errors?.length) {
        return notifyError(<>{errors.join("\n")}</>);
      }
      notifySucces(
        <>
          Custom dimension{" "}
          <span className="Text Text--white Text--bold">
            {res.organization?.deleteCustomDimension?.customDimension?.name}
          </span>{" "}
          deleted successfuly.
        </>
      );
    },
    onError: () => notifyError(<>Server error when deleting report!</>),
    update() {
      refetch();
    },
  });

  return (
    <Table>
      <THead>
        <TrHeader>
          <Th>
            <SortableWrapper {...sortableProps} sortByValue="name">
              Name
            </SortableWrapper>
          </Th>
          <Th>Description</Th>
          <Th>Values from</Th>
          <Th>
            <SortableWrapper {...sortableProps} sortByValue="referencesCount">
              Used
            </SortableWrapper>
          </Th>
          <Th>Created by</Th>
        </TrHeader>
      </THead>
      <TBody>
        {tableRows.map((customDimension) => (
          <DimensionTableRow
            key={customDimension.id}
            customDimension={customDimension}
            onDelete={() =>
              deleteCustomDimension({
                variables: { customDimensionId: customDimension.id, organizationExternalId: organization.externalId },
              })
            }
          />
        ))}
      </TBody>
    </Table>
  );
};

export const DimensionsPageConnected = () => {
  const { organization } = useAppSettings();
  const response = useCustomDimensionsQuery({
    fetchPolicy: "network-only",
    variables: {
      organizationExternalId: organization.externalId,
      limit: 1000,
      offset: 0,
    },
  });

  return <GeneralMetricDimensionTable {...response} type="dimension" />;
};
