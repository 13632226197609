/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from "react";
import { notifyError, notifySucces } from "../../functions/toast";
import {
  AcknowledgeUserCampaignActionMutationT,
  ActionStateT,
  CampaignT,
  DeleteUserCampaignActionMutationT,
  RetryUserCampaignActionMutationT,
  UserCampaignActionT,
  useAcknowledgeUserCampaignActionMutation,
  useDeleteUserCampaignActionMutation,
  useRetryUserCampaignActionMutation,
} from "../../graphql/generated/graphql";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { DropdownMenu } from "../../ui/Dropdown/DropdownMenu";
import { Icon } from "../../ui/Icon/Icon";
import { Tooltip } from "../../ui/Tooltip/Tooltip";

export type PropsT = {
  onRefetch?: () => void;
  userCampaignAction: Pick<NonNullable<UserCampaignActionT>, "state" | "id" | "error"> & {
    campaign?: Pick<CampaignT, "name"> | null;
  };
};

const iconsForState = {
  [ActionStateT.QueuedT]: {
    icon: "wait",
    className: "grey-dark",
    hasWaitingStatus: false,
  },
  [ActionStateT.FinishedT]: {
    icon: "check",
    className: "text-success",
    hasWaitingStatus: false,
  },
  [ActionStateT.FailedT]: {
    icon: "danger",
    className: "red-dark",
    hasWaitingStatus: false,
  },
  [ActionStateT.FailedUserAcknowledgedT]: {
    icon: "danger",
    className: "red-dark",
    hasWaitingStatus: false,
  },
  [ActionStateT.RunningT]: {
    icon: "syncing",
    className: "blue",
    hasWaitingStatus: true,
  },
};

export const UserCampaignActionState = ({ onRefetch, userCampaignAction }: PropsT) => {
  const { organization } = useAppSettings();
  const [mutationState, setMutationState] = useState<null | "deleted" | "acknowledged">(null);
  const getSuccessCallback = (actionName: "retry" | "delete" | "acknowledge") => ({
    onCompleted: (
      res: RetryUserCampaignActionMutationT | AcknowledgeUserCampaignActionMutationT | DeleteUserCampaignActionMutationT
    ) => {
      const errors = (res.organization as { [key: string]: any } | undefined)?.[`${actionName}UserCampaignAction`]
        ?.errors as Array<string> | undefined;

      if (errors?.length) {
        return notifyError(<>{errors.join("\n")}</>);
      }
      notifySucces(<>{`Campaign action was succesfully ${actionName}!`}</>);
      if (actionName === "delete") {
        setMutationState("deleted");
      }
      if (actionName === "acknowledge") {
        setMutationState("acknowledged");
      }
      if (onRefetch) {
        onRefetch();
      }
    },
    onError: () => notifyError(<>{`Server error when ${actionName} campaign action!`}</>),
  });

  const [mutateRetry, { loading: isLoadingRetry }] = useRetryUserCampaignActionMutation(getSuccessCallback("retry"));
  const [mutateDelete, { loading: isLoadingDelete }] = useDeleteUserCampaignActionMutation(
    getSuccessCallback("delete")
  );
  const [mutateAcknowledge, { loading: isLoadingAcknowledge }] = useAcknowledgeUserCampaignActionMutation(
    getSuccessCallback("acknowledge")
  );

  const getDropdownMenu = useCallback(
    (campaignActionId?: string) => {
      const params = campaignActionId
        ? { variables: { organizationExternalId: organization.externalId, campaignActionId } }
        : null;

      return params
        ? [
            {
              value: "Retry",
              onClick: () => {
                mutateRetry(params);
              },
            },
            {
              value: "Delete",
              onClick: () => {
                mutateDelete(params);
              },
            },
            {
              value: "Acknowledge",
              onClick: () => {
                mutateAcknowledge(params);
              },
            },
          ]
        : [];
    },
    [mutateRetry, mutateDelete, mutateAcknowledge, organization]
  );

  if (!userCampaignAction?.state) {
    return null;
  }

  if (mutationState === "deleted") {
    return <span>Action Deleted</span>;
  }

  if (mutationState === "acknowledged") {
    return <span>Manually Resolved</span>;
  }

  return (
    <div className="d-flex align-items-center">
      <Tooltip tooltipContent={userCampaignAction.error}>
        <Icon
          className={`${iconsForState[userCampaignAction?.state].className} d-block`}
          isRotate={iconsForState[userCampaignAction?.state].hasWaitingStatus}
          kind={iconsForState[userCampaignAction?.state].icon}
        />
      </Tooltip>

      {userCampaignAction.state === ActionStateT.FailedT && (
        <div>
          <DropdownMenu
            buttonProps={{ disabled: isLoadingRetry || isLoadingDelete || isLoadingAcknowledge, size: "small" }}
            className="ml-8"
            collection={getDropdownMenu(userCampaignAction.id)}
            testId={`campaign-actions-menu${userCampaignAction.campaign?.name}`}
          />
        </div>
      )}
    </div>
  );
};
