import React, { useState } from "react";
import { ReportList } from "../../components/reportList/ReportList";
import { notifyError, notifySucces } from "../../functions/toast";
import { useReportDeleteMutation, useReportListQuery } from "../../graphql/generated/graphql";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { ReportT } from "../../types/widgets";
import Radio from "../../ui/forms/Radio";
import { useGraphqlPagination } from "../../ui/Pagination/Pagination";

export const ReportListConnected = ({ canEdit }: { canEdit: boolean }) => {
  const [visibilityFilterValue, setVisibilityFilterValue] = useState<"all" | "visible" | "invisible">("all");
  const { organization } = useAppSettings();

  const [paginationPropsWithCallbacks, graphqlPaginationVariables, setPageInfo] = useGraphqlPagination();

  const { data, refetch, ...rest } = useReportListQuery({
    variables: {
      organizationExternalId: organization.externalId,
      ...graphqlPaginationVariables,
      visible: visibilityFilterValue === "all" ? undefined : visibilityFilterValue === "visible",
    },
  });

  const reports = data?.organization?.reports.nodes.filter(({ discarded }) => !discarded);
  const organizationId = data?.organization?.id;
  setPageInfo(data?.organization?.reports.pageInfo);

  const [mutate] = useReportDeleteMutation({
    onCompleted: (res) => {
      const errors = res.organization?.deleteReport?.errors;
      if (errors?.length) {
        return notifyError(<>{errors.join("\n")}</>);
      }
      notifySucces(<>Report was succesfully deleted!</>);

      if ((reports || []).length - 1 === 0 && paginationPropsWithCallbacks.currentPageIndex > 0) {
        paginationPropsWithCallbacks.onSetPageIndex(paginationPropsWithCallbacks.currentPageIndex - 1);
      }
    },
    onError: () => notifyError(<>Server error when deleting report!</>),
  });

  const handleDeleteReport = (reportId: ReportT["id"]) => {
    mutate({
      variables: {
        organizationExternalId: organization.externalId,
        reportId,
      },
      update(cache) {
        if (organizationId) {
          const cachedOrganization = cache.identify({ __typename: "Organization", id: organizationId });
          cache.evict({ id: cachedOrganization, fieldName: "reports" });
          cache.gc();
        }
      },
    });
  };

  return (
    <div>
      {organization.isTemplate && (
        <Radio
          className="mb-24"
          label="Select visibility filter"
          input={{
            name: "input-template-visibility",
            id: "input-template-visibility",
            disabled: rest.loading,
            value: visibilityFilterValue,
            onChange: (event) => {
              setVisibilityFilterValue((event.target as HTMLInputElement).value as "all" | "visible" | "invisible");
            },
          }}
          radioOptions={[
            {
              label: "All",
              value: "all",
            },
            {
              label: "Visible",
              value: "visible",
            },
            {
              label: "Invisible",
              value: "invisible",
            },
          ]}
          isInline
        />
      )}

      <ReportList
        {...rest}
        canEdit={canEdit}
        pagination={paginationPropsWithCallbacks}
        reports={reports}
        onDeleteReport={handleDeleteReport}
      />
    </div>
  );
};
