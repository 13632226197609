import { Reducer } from "react";
import { ReportBoardActionsT, ReportBoardReducerStateT, ReportReducerActionT } from "./types";

type ReducerT = Reducer<ReportBoardReducerStateT, ReportReducerActionT>;

export const reportReducer: ReducerT = (state, action) => {
  switch (action.type) {
    case ReportBoardActionsT.REPORT_FILTER_UPDATE: {
      const {
        reportFilter: { conditions, ...rest },
      } = action.data;

      return {
        ...state,
        reportFilter: {
          ...state.reportFilter,
          ...rest,
          conditions: conditions || state.reportFilter.conditions,
        },
      };
    }

    case ReportBoardActionsT.UPDATE: {
      const { data } = action;
      return { ...state, ...data };
    }

    case ReportBoardActionsT.PAGE_ADD: {
      const { newPage: newPageInput } = action;
      const newPage = {
        ...newPageInput,
        shared: false,
        isNew: true,
      };

      return { ...state, pagesData: [...state.pagesData, newPage] };
    }

    case ReportBoardActionsT.PAGE_DELETE: {
      const { pageId } = action;
      const newPages = state.pagesData.filter((page) => page.id !== pageId);

      return { ...state, pagesData: newPages };
    }

    case ReportBoardActionsT.PAGE_UPDATE: {
      const { pageId, pageInputData } = action;
      const index = state.pagesData.findIndex((page) => page.id === pageId);
      const editedPage = state.pagesData.find((page) => page.id === pageId);

      let callbackData = undefined;

      if (typeof pageInputData === "function" && editedPage) {
        callbackData = pageInputData(editedPage);
      }

      const pages = [...state.pagesData];
      if (index > -1) {
        pages[index] = { ...pages[index], ...(callbackData || pageInputData) };
      }

      return { ...state, pagesData: pages };
    }

    case ReportBoardActionsT.SOURCE_SYSTEMS_UPDATE: {
      const { sourceSystems } = action;
      return { ...state, report: { ...state.report, sourceSystems } };
    }

    default: {
      throw new Error(`Unhandled action type. ${JSON.stringify(action)}`);
    }
  }
};
