import React, { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AccountSelectReportConnected } from "../../componentsConnected/accountSelect/AccountSelectReport";
import { FakeDataStateT } from "../../functions/sourceSystemsHelper";
import { ReportPageQueryResultT } from "../../graphql/generated/graphql";
import { useUnsavedAlertOnRedirect } from "../../hooks/useUnsavedAlertOnRedirect";
import { t } from "../../i18n/translation";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { ActionBar } from "../../ui/ActionBar/ActionBar";
import { ButtonPrimary } from "../../ui/Button/Button";
import { Row } from "../../ui/grid/Grid";
import { InfoBox } from "../../ui/InfoBox/InfoBox";
import { Loader } from "../../ui/Loader/Loader";
import { useModal } from "../../ui/Modal";
import { SYSTEM_NAMES } from "../accountSelect/constants";
import { useConfirmationContext } from "../confirmationDialog/confirmationDialogContext";
import { useReportBoardContext } from "./context/reportBoardContext";
import { ReportBoardHeader, ReportBoardHeaderPropsT } from "./ReportBoardHeader";
import { ReportBoardPage } from "./ReportBoardPage";
import { SharingModal } from "./ReportBoardSharingnModal";
import { ReportFilterCompact } from "./ReportFilterCompact";
import { ReportPagesMenu } from "./ReportPagesMenu";
import { useRefetchWidgetData } from "./useRefetchWidgetData";

type PropsT = Pick<
  ReportBoardHeaderPropsT,
  | "isOnDeleteReportLoading"
  | "isOnDuplicationReportLoading"
  | "onDeleteReport"
  | "onDuplicateReport"
  | "onSharingModalOpen"
  | "isTemplate"
> & {
  reportRefetch?: ReportPageQueryResultT["refetch"];
};

export const ReportBoard = (props: PropsT) => {
  const { organization } = useAppSettings();
  const [searchParams] = useSearchParams();

  const {
    fetchingNewConnectedSystemsData,
    hasReportUnsavedChanges,
    loading,
    pageAdd,
    pageDelete,
    pageDuplicate,
    pageUpdate,
    pagesData,
    report,
    reportApiUpdate,
    reportFilter,
    reportFilterOpen,
    reportFilterUpdate,
    reportOptions,
    reportReset,
    selectedMetDims,
    widgetSettingsData,
  } = useReportBoardContext();

  const activePageId = pagesData.find((page) => page.uuid === searchParams.get("page"))
    ? searchParams.get("page")
    : pagesData?.[0]?.uuid;

  const { canEdit } = reportOptions;
  useRefetchWidgetData({ organizationExternalId: organization.externalId, canEdit });
  const { callWithConfirmation } = useConfirmationContext();

  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const sharingModalOptions = useModal();

  const handleDeleteReport = (reportId: string) => {
    setDeleteInProgress(true);
    callWithConfirmation(() => (props.onDeleteReport ? props.onDeleteReport(reportId) : undefined), {
      description: `Are you sure you want to delete this report: "${report.name}"?`,
      title: "Delete report",
      testId: `delete-report-${report.name}`,
      confirmButtonProps: { text: "Delete", variant: "red" },
    });
  };
  const { UnsavedChangesAlert } = useUnsavedAlertOnRedirect(hasReportUnsavedChanges && !deleteInProgress && canEdit);

  const isEmptyBoard = !pagesData.flatMap((page) => page.sections).length;
  const isSampleData = reportOptions.fakeDataStatus === FakeDataStateT.ALL_SAMPLE;
  const isIncompleteData = reportOptions.fakeDataStatus === FakeDataStateT.INCOMPLETE;

  const uniqueSourceSystems = useMemo(
    () => new Set(report.sourceSystems.map(({ name }) => name)),
    [report.sourceSystems]
  );

  return (
    <div data-test-id="report-board">
      {!organization.isTemplate && canEdit && (
        <>
          {(isSampleData || isIncompleteData) && (
            <InfoBox className="mb-8" variant="warning">
              <div>
                {isSampleData && (
                  <>
                    This report contains <span className="Text Text--bold">sample data</span>, please add your accounts
                    first to view your data.
                  </>
                )}
                {isIncompleteData && (
                  <>
                    This report shows{" "}
                    <span className="Text Text--bold">
                      data only from {uniqueSourceSystems.size} of the required accounts (
                      {Array.from(uniqueSourceSystems.values())
                        .map((name) => SYSTEM_NAMES[name])
                        .join(", ")}
                      )
                    </span>
                    , please add all required accounts to view all data in report.
                  </>
                )}
              </div>
            </InfoBox>
          )}
          <AccountSelectReportConnected
            isFakeData={isSampleData}
            reportId={report.id}
            selectedMetDims={selectedMetDims}
            widgetSettingsData={widgetSettingsData}
          >
            {fetchingNewConnectedSystemsData ? (
              <div className="mt-16">
                <Loader className="mr-16" size="small" />
                Fetching data for newly connected accounts
              </div>
            ) : undefined}
          </AccountSelectReportConnected>
        </>
      )}

      <div>
        <ReportBoardHeader
          {...props}
          onDeleteReport={handleDeleteReport}
          onSharingModalOpen={() => sharingModalOptions.open()}
        />
        <UnsavedChangesAlert description={t("confirmationModal.unsavedChanges.description")} />

        <Row alignItems="top" gap={8} justify="between">
          <ReportPagesMenu
            activePageId={activePageId}
            canEdit={canEdit}
            hasReportUnsavedChanges={hasReportUnsavedChanges}
            pages={pagesData}
            reportId={report.id}
            onPageAdd={pageAdd}
            onPageDelete={pageDelete}
            onPageDuplicate={pageDuplicate}
            onPageUpdate={pageUpdate}
            onReportReset={reportReset}
          />
          {!isEmptyBoard && (
            <ReportFilterCompact
              canEditConditions={canEdit}
              conditions={reportFilter.conditions}
              defaultValues={{ currency: reportFilter.currency, dateRange: reportFilter.dateRange || {} }}
              id="global-filter"
              metricsById={widgetSettingsData.metricsById}
              canEdit
              disableClear
              hasBottomSpacing
              hasCurrency
              onChange={reportFilterUpdate}
              onFilterOpen={reportFilterOpen}
            />
          )}
        </Row>

        <ReportBoardPage activePageId={activePageId} />
      </div>

      {canEdit && (
        <ActionBar>
          <ButtonPrimary
            data-test-id="save-report"
            disabled={!hasReportUnsavedChanges || loading}
            icon="save"
            loading={loading}
            onClick={reportApiUpdate}
          >
            Save changes
          </ButtonPrimary>
        </ActionBar>
      )}

      {props.reportRefetch && (
        <SharingModal
          modalOptions={{ ...sharingModalOptions, testId: "sharing-modal" }}
          pages={pagesData}
          reportId={report.id}
          reportName={report.name}
          reportRefetch={props.reportRefetch}
        />
      )}
    </div>
  );
};
