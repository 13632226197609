import React from "react";
import { intervalToDuration } from "date-fns";
import { formatDateRange } from "../../i18n/formatDate";
import { formatMetricValue, formatPercents } from "../../i18n/formatNumbers";
import { Col, Row } from "../../ui/grid/Grid";
import { Heading, HeadingPage, HeadingSmall } from "../../ui/Heading/Heading";
import { IconCustom } from "../../ui/IconCustom/IconCustom";
import { Text } from "../../ui/Text/Text";
import { KPI_SEMAPHORE_VARIANTS, KpiSemaphorePropsT } from "./KpiSemaphore";
import { KpiSemaphoreBadge } from "./KpiSemaphoreBadge";

type PropsT = Pick<KpiSemaphorePropsT, "name" | "metric" | "result"> & {
  theme?: "light";
};

export const KpiSemaphoreTooltipContent = ({ metric, name, result }: PropsT) => {
  const isOver = result?.endOn ? new Date(result.endOn) < new Date() : false;
  const semaphoreVariant = result?.semaphore ? KPI_SEMAPHORE_VARIANTS[result?.semaphore] : undefined;

  if (!semaphoreVariant) {
    return null;
  }

  return (
    <div className="pv-4">
      <HeadingSmall color="inherit" margin={0}>
        {name}
      </HeadingSmall>
      <Text color="black-light" size="xs" tag="p">
        Period: {formatDateRange(result?.startOn, result?.endOn)}
      </Text>

      {semaphoreVariant.error && (
        <>
          <Row className="mt-8" justify="center">
            <IconCustom name={semaphoreVariant.icon} size={36} />
          </Row>
          <Row width="150px">
            <Text color="black-light">{semaphoreVariant.error}</Text>
          </Row>
        </>
      )}

      {!semaphoreVariant.error && (
        <>
          <Row alignItems="center" className="mt-8" justify="center">
            <Col type="shrink">
              <HeadingPage margin={0} style={{ color: semaphoreVariant?.color }}>
                {typeof result?.estimatedProgress === "number" && formatPercents(result.estimatedProgress, 0)}
              </HeadingPage>
            </Col>
            <Col type="shrink">
              <KpiSemaphoreBadge semaphore={result?.semaphore} isWithIcon />
            </Col>
          </Row>
          <div className="delimiter mv-8" style={{ borderColor: "#CCD0D4" }} />
          <div>
            <Text className="d-block" color="black-light">
              {isOver ? "Final value" : "Current Value"}
            </Text>
            <Heading color="white" level="h4" margin={0}>
              {formatMetricValue({ metric, value: result?.currentValue, currency: result?.currency })}
            </Heading>
          </div>

          {!isOver && (
            <div className="mt-8">
              <Text className="d-block" color="black-light">
                Estimated Value
              </Text>
              <Heading color="white" level="h4" margin={0}>
                {formatMetricValue({ metric, value: result?.estimatedPeriodValue, currency: result?.currency })}
              </Heading>
            </div>
          )}

          <div className="mt-8">
            <Text className="d-block" color="black-light">
              Target value
            </Text>
            <Heading color="white" level="h4" margin={0}>
              {formatMetricValue({ metric, value: result?.targetValue, currency: result?.currency })}
            </Heading>
            {!isOver && (
              <>
                <Text className="d-block" color="black-light" size="xs">
                  (still {intervalToDuration({ start: new Date(), end: new Date(result?.endOn) }).days} days to go)
                </Text>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
