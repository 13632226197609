import React, { MouseEvent } from "react";
import { KpiPriorityT, KpiStatusT } from "../../graphql/generated/graphql";
import { Row } from "../../ui/grid/Grid";
import { HeadingSmall } from "../../ui/Heading/Heading";
import { Icon } from "../../ui/Icon/Icon";
import { KpiPriority } from "../KpiPriority/KpiPriority";

type PropsT = {
  className?: string;
  hiddenSections: string[];
  onClick: (kpiPriority: string) => void;
  sectionName: KpiPriorityT | KpiStatusT;
};

export const KpiListHeading = ({ className, hiddenSections, onClick, sectionName }: PropsT) => {
  const isHidden = hiddenSections.includes(sectionName);
  const isPriority = Object.values(KpiPriorityT).includes(sectionName as KpiPriorityT);
  const isStatus = Object.values(KpiStatusT).includes(sectionName as KpiStatusT);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onClick(sectionName);
  };

  return (
    <HeadingSmall className={className} color="softGray" spaced uppercase onClick={handleClick}>
      <Row alignItems="center" className="cursor-pointer" gap={8}>
        <div>
          {isPriority && <KpiPriority priority={sectionName as KpiPriorityT} />}
          {isStatus && (
            <>
              {sectionName === KpiStatusT.DraftT && <Icon className="mr-4" kind="circle" />}
              {sectionName}
            </>
          )}
        </div>
        <Icon color="#aaa" kind={isHidden ? "chevron-down" : "chevron-up"} />
      </Row>
    </HeadingSmall>
  );
};
