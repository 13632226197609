import React, { ChangeEvent, useState } from "react";
import { Placement } from "@floating-ui/react";
import { notifyError, notifySucces } from "../../functions/toast";
import {
  BiddingSchemaT,
  BiddingStrategyT,
  CampaignStatusT,
  SystemNameT,
  useBiddingChangesUserCampaignActionMutation,
} from "../../graphql/generated/graphql";
import { getDecimalsCount } from "../../i18n/formatNumbers";
import { t } from "../../i18n/translation";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { Ellipsis } from "../../ui/Ellipsis/Ellipsis";
import Input from "../../ui/forms/Input";
import Select from "../../ui/forms/Select";
import { Col, Row } from "../../ui/grid/Grid";
import { Text } from "../../ui/Text/Text";
import { CampaignBidding } from "../widgetComponents/TableOptimatizationCell/TableOptimatizationCell";
import { CampaignIncludedDataT } from "../widgetComponents/TableOptimizationComponent";
import { PickedCampaignT } from "../widgetComponents/TableOptimizationRow";
import { ChangeFormContextT, ChangePopupWrapper } from "./ChangeStatusForm";

const TITLE = "Change bidding";

export const useChangeBiddingFormMutation = () =>
  useBiddingChangesUserCampaignActionMutation({
    onCompleted: (responseData) => {
      const errors = responseData.organization?.biddingChangesUserCampaignAction?.errors;

      if (errors?.length) {
        return notifyError(<>{errors.join("\n")}</>);
      }

      notifySucces(<>Change status successful</>);
    },
    onError: () => {
      notifyError(<>Change status failed</>);
    },
  });

type PropsT = ChangeFormContextT & {
  campaign: PickedCampaignT;
  campaignIncludedData: CampaignIncludedDataT;
  onClose: () => void;
  placement?: Placement;
};

export const ChangeBiddingForm = ({
  campaign,
  campaignIncludedData,
  mutateBidding,
  mutateBiddingResult,
  onClose,
  placement,
}: PropsT) => {
  const { biddingsById } = campaignIncludedData;
  const { organization } = useAppSettings();

  const [wantedValues, setWantedValues] = useState<{
    [key: string]: { inputValue: number | null; newBiddingSchema: BiddingSchemaT | null; newValue: number | null };
  }>({});

  const bidding = biddingsById[campaign.biddingId];

  const newBiddingSchema = wantedValues[campaign.id]?.newBiddingSchema || bidding.schema;

  const biddingChanges = Object.keys(wantedValues).reduce(
    (acc, key) =>
      wantedValues[key].newBiddingSchema === null
        ? acc
        : [
            ...acc,
            {
              campaignId: key,
              newBiddingSchema: wantedValues[key].newBiddingSchema as BiddingSchemaT,
              newValue: wantedValues[key].newValue ? wantedValues[key].newValue : null,
            },
          ],
    [] as { campaignId: string; newBiddingSchema: BiddingSchemaT; newValue: number | null }[]
  );

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>, isPercent?: boolean) => {
    if (event.target) {
      setWantedValues({
        ...wantedValues,
        [event.target.name]: {
          ...wantedValues[event.target.name],
          newBiddingSchema: (wantedValues[event.target.name]?.newBiddingSchema ||
            biddingsById[campaign.biddingId]?.schema) as BiddingSchemaT,
          newValue: isPercent ? event.target.valueAsNumber / 100 : event.target.valueAsNumber,
          inputValue: event.target.valueAsNumber,
        },
      });
    }
  };

  const handlSchemaChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (event.target) {
      setWantedValues({
        ...wantedValues,
        [event.target.name]: {
          newBiddingSchema: event.target.value as BiddingSchemaT,
          newValue: null,
          inputValue: null,
        },
      });
    }
  };

  const handleSend = () => {
    mutateBidding({
      variables: {
        organizationExternalId: organization.externalId,
        biddingChanges,
      },
      onCompleted: onClose,
    });
  };

  if (campaign.system !== SystemNameT.AdwordsT) {
    return (
      <ChangePopupWrapper title={TITLE} onClose={onClose}>
        You can change bidding only for Google Ads campaigns
      </ChangePopupWrapper>
    );
  }

  if (campaign.status === CampaignStatusT.RemovedT) {
    return (
      <ChangePopupWrapper title={TITLE} onClose={onClose}>
        Unable to change bidding due to removed campaign in Google Ads
      </ChangePopupWrapper>
    );
  }

  return (
    <ChangePopupWrapper
      campaign={campaign}
      colType="bidding"
      isFormDisabled={biddingChanges.length === 0 || mutateBiddingResult.loading}
      isFormLoading={mutateBiddingResult.loading}
      placement={placement}
      title={TITLE}
      onClose={onClose}
      onSave={handleSend}
    >
      <Row>
        <Col type="grow">Current settings</Col>
        <Col className="text-right" type="grow" width="147px">
          <Text bold>
            <Ellipsis>
              <CampaignBidding
                biddingSchema={bidding.schema}
                currency={campaign.currency}
                targetCpa={bidding.targetCpa}
                targetRoas={bidding.targetRoas}
              />
            </Ellipsis>
          </Text>
        </Col>
      </Row>
      <Row alignItems="center">
        <Col type="grow">Strategy</Col>
        <Col width="147px">
          <Select
            selectProps={{
              name: campaign.id,
              onChange: handlSchemaChange,
              value: newBiddingSchema || "",
              disabled: mutateBiddingResult.loading || bidding.strategyType === BiddingStrategyT.PortfolioT,
            }}
          >
            <option value="">No change</option>
            {campaign?.availableBiddingSchemes?.map((scheme) => {
              return (
                <option key={scheme} value={scheme}>
                  {t(`campaign.bidding.${scheme}`)}
                </option>
              );
            })}
          </Select>
        </Col>
      </Row>
      <Row alignItems="center">
        <Col type="grow">Value</Col>
        <Col width="147px">
          {(newBiddingSchema === BiddingSchemaT.MaximizeConversionValueT ||
            newBiddingSchema === BiddingSchemaT.TargetRoasT) && (
            <Input
              error={
                wantedValues[campaign.id] && getDecimalsCount(wantedValues[campaign.id]?.inputValue) > 2
                  ? {
                      type: "pattern",
                      message: "Only two decimal places are allowed, otherwise the number will be truncated.",
                    }
                  : undefined
              }
              inputProps={{
                unit: "%",
                type: "number",
                name: campaign.id,
                onChange: (event: ChangeEvent<HTMLInputElement>) => handleValueChange(event, true),
                value:
                  (wantedValues[campaign.id]
                    ? wantedValues[campaign.id]?.inputValue
                    : (bidding.targetRoas || 0) * 100) || "",
              }}
            />
          )}
          {(newBiddingSchema === BiddingSchemaT.MaximizeConversionsT ||
            newBiddingSchema === BiddingSchemaT.TargetCpaT) && (
            <Input
              inputProps={{
                unit: campaign.currency || "",
                name: campaign.id,
                type: "number",
                onChange: handleValueChange,
                value: (wantedValues[campaign.id] ? wantedValues[campaign.id].newValue : bidding.targetCpa) || "",
              }}
            />
          )}
        </Col>
      </Row>
    </ChangePopupWrapper>
  );
};
