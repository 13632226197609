import { ApolloError } from "@apollo/client";

export const mergeApiErrors = (mutationErrors?: string[] | null, stateErrors?: ApolloError) => {
  let errors: string[] = [];
  if (stateErrors) {
    errors = [stateErrors.message];
  }
  if (mutationErrors?.length) {
    errors = [...errors, ...mutationErrors];
  }

  return errors;
};
