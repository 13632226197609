import React, { PropsWithChildren } from "react";
import cs from "classnames";
import { useModalContext } from "./modalContext";

type PropsT = { className?: string; padding?: 0 | 4 | 8 | 16 | 24 | 32 | 48 };

function ModalBody({ children, className, padding }: PropsWithChildren<PropsT>) {
  const { testId } = useModalContext();

  return (
    <div className={cs("Modal-body", className, { [`pr-${padding}`]: padding })} data-test-id={`${testId}-body`}>
      {children}
    </div>
  );
}

export default ModalBody;
