import React, { CSSProperties, ForwardedRef, HTMLAttributes, PropsWithChildren, forwardRef } from "react";
import cs from "classnames";
import { capitalize } from "lodash";
import { GRID_ALIGN_ITEMS, GRID_GAP, GRID_JUSTIFY, GRID_PADDING } from "./constants";

type AlignItemsT = typeof GRID_ALIGN_ITEMS[number];
type SpacingT = typeof GRID_PADDING[number];
type GapT = typeof GRID_GAP[number];
type JustifyT = typeof GRID_JUSTIFY[number];

type PropsT = {
  alignItems?: AlignItemsT;
  className?: string;
  direction?: "col" | "row";
  flexwrap?: boolean;
  gap?: GapT;
  height?: string;
  inline?: boolean;
  justify?: JustifyT;
  noWrap?: boolean;
  overflow?: boolean;
  padding?: SpacingT;
  style?: CSSProperties;
  type?: "shrink" | "grow" | "equalSize";
  width?: string;
  wrap?: boolean;
} & HTMLAttributes<HTMLElement>;

type Ref = ForwardedRef<HTMLDivElement>;

export const getEnhancedStyles = ({ height, width }: Pick<PropsT, "width" | "height">) =>
  height || width
    ? {
        flexGrow: (width || height) && 0,
        flexBasis: width || "100%",
        flexShrink: (width || height) && 0,
        ...(width ? { width } : {}),
        ...(height ? { height } : {}),
      }
    : {};

export const getCellProps = ({
  alignItems,
  className,
  direction = "row",
  flexwrap,
  height,
  inline,
  justify,
  noWrap,
  overflow,
  padding = "m",
  style = {},
  type = "shrink",
  width,
  wrap,
  gap,
  ...rest
}: PropsT) => {
  const newStyle = { ...style, ...getEnhancedStyles({ width, height }) };
  const newClassName = cs(className, `Grid--${direction}`, {
    [`Grid--${alignItems}`]: alignItems,
    "Grid--flexwrap": flexwrap,
    "Grid--inline": inline,
    [`Grid--padding${(padding || "").toUpperCase()}`]: padding !== "n",
    [`Grid--justify${capitalize(justify)}`]: justify,
    [`flex-gap-${gap}`]: gap,
    "Grid--overflow": overflow,
    "Grid--grow": type === "grow",
    "Grid--shrink": type === "shrink",
    "Grid--shrinkWrap": wrap,
    "Grid--textNowrap": noWrap,
  });

  return { ...rest, className: newClassName, style: newStyle };
};

const GridComponent = forwardRef(({ children, ...rest }: PropsWithChildren<PropsT>, ref: Ref) => {
  return (
    <div {...getCellProps(rest)} ref={ref}>
      {children}
    </div>
  );
});

export const Row = GridComponent;

export const Col = forwardRef(({ children, ...rest }: PropsWithChildren<PropsT>, ref: Ref) => {
  return (
    <GridComponent direction="col" {...rest} ref={ref}>
      {children}
    </GridComponent>
  );
});

export const GridOverflow = forwardRef(({ children, className, ...rest }: PropsWithChildren<PropsT>, ref: Ref) => {
  return (
    <GridComponent {...rest} ref={ref} className={cs("Grid--overflowWrapper", className)}>
      {children}
    </GridComponent>
  );
});
