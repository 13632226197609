import React, { FormEvent } from "react";
import { CurrencyT } from "../../../graphql/generated/graphql";
import { WidgetT } from "../../../types/widgets";
import Select from "../../../ui/forms/Select";
import { OnFormEditWidgetChangeT } from "../FormEditWidget";

type PropsT = {
  formKey: string;
  onChange: OnFormEditWidgetChangeT;
  value: WidgetT["currency"];
};

export const CURRENCY_OPTIONS = [
  {
    label: "not selected",
    value: "",
  },
  ...(Object.keys(CurrencyT) as (keyof typeof CurrencyT)[]).map((key) => ({
    label: CurrencyT[key],
    value: CurrencyT[key],
  })),
];

export const InputCurrency = ({ formKey, onChange, value }: PropsT) => {
  const path = ["currency"];
  const pathString = path.join(".");

  const handleChange = (event: FormEvent<HTMLSelectElement>) => {
    const newValue = event.currentTarget.value;
    onChange((prev) => ({ ...prev, currency: (newValue || null) as CurrencyT | null }));
  };

  return (
    <Select
      className="mb-16"
      collection={CURRENCY_OPTIONS}
      label="Currency"
      selectProps={{ id: `${formKey}.${pathString}`, onChange: handleChange, value: value || "", name: pathString }}
    />
  );
};
