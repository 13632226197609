/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import colorFc from "color";
import { omit } from "lodash";
import { ReportDimensionT } from "../components/reportBoard/context/types";
import { ShowDimensionMetricPill } from "../components/showDimensionMetricPill/ShowDimensionMetricPill";
import { CHART_LINE_COLOR_OTHER } from "../constants/report";
import { WidgetMetricT, WidgetRowT } from "../graphql/generated/graphql";
import { fetchMetricId } from "./fetchIds";

export const fetchIsDimensionOther = (dimension: Pick<WidgetRowT["widgetDimensions"][number], "value">): boolean => {
  return dimension.value === "other";
};

export const fetchDimensionValueFromRowAsText =
  (selectedDimensions: Pick<ReportDimensionT, "id">[]) => (row: Pick<WidgetRowT, "widgetDimensions">) => {
    if (selectedDimensions.length === 0 || !row) {
      return "-";
    }
    if (selectedDimensions.length === 1) {
      return (
        row.widgetDimensions.find((widgetDimension) => widgetDimension.dimensionId === selectedDimensions[0].id)
          ?.value || "-"
      );
    }

    if (row.widgetDimensions.every(fetchIsDimensionOther)) {
      return row.widgetDimensions[0]?.value;
    }

    return selectedDimensions
      .map(
        (dimension) =>
          row.widgetDimensions.find((widgetDimension) => widgetDimension.dimensionId === dimension?.id)?.value || "-"
      )
      .join(" | ");
  };

export const fetchRowHasOtherData = (row?: Pick<WidgetRowT, "widgetDimensions">) => {
  return row?.widgetDimensions?.some(fetchIsDimensionOther);
};

export const fetchMetricPreviousValueFromRow = (metric: WidgetMetricT) => (row?: Pick<WidgetRowT, "widgetMetrics">) => {
  const foundMetric = row?.widgetMetrics.find((widgetMetric) => widgetMetric.widgetMetricId === fetchMetricId(metric));
  return foundMetric?.previousValue;
};

export const fetchMetricTrendFromRow = (metric: WidgetMetricT) => (row?: Pick<WidgetRowT, "widgetMetrics">) => {
  const foundMetric = row?.widgetMetrics.find((widgetMetric) => widgetMetric.widgetMetricId === fetchMetricId(metric));
  return foundMetric?.trend;
};

export const fetchMetricValueFromRow = (metric: WidgetMetricT) => (row?: Pick<WidgetRowT, "widgetMetrics">) => {
  const foundMetric = row?.widgetMetrics.find((widgetMetric) => widgetMetric.widgetMetricId === fetchMetricId(metric));
  return (foundMetric?.value || "") as string | number;
};

export const fetchMetricCompareFromRow = (metric: WidgetMetricT) => (row?: Pick<WidgetRowT, "widgetMetrics">) => {
  const foundMetric = row?.widgetMetrics.find((widgetMetric) => widgetMetric.widgetMetricId === fetchMetricId(metric));
  if (!foundMetric?.previousDate || !foundMetric?.previousDateTo) {
    return undefined;
  }
  return { previousDate: foundMetric.previousDate, previousDateTo: foundMetric.previousDateTo };
};

export const fetchMetricValueFromGroupedRowByDate =
  (metric: WidgetMetricT, dimensionsValue: string, selectedDimensions: ReportDimensionT[], isPrevious?: boolean) =>
  (groupedRow: { date: string; dateTo: string; rowsData: WidgetRowT[] }) => {
    const row = groupedRow.rowsData.find(
      (item) => fetchDimensionValueFromRowAsText(selectedDimensions)(item) === dimensionsValue
    );
    const foundMetric = row?.widgetMetrics?.find(
      (widgetMetric) => widgetMetric.widgetMetricId === fetchMetricId(metric)
    );
    return ((isPrevious ? foundMetric?.previousValue : foundMetric?.value) || "") as string | number;
  };

export const fetchSummaryMetricFromRow = (metric: WidgetMetricT) => (row?: Pick<WidgetRowT, "widgetMetrics">) => {
  return row?.widgetMetrics.find((widgetMetric) => widgetMetric.widgetMetricId === fetchMetricId(metric));
};

export const fetchDimensionValueFromRow =
  (selectedDimensions: ReportDimensionT[]) => (row?: Pick<WidgetRowT, "widgetDimensions">) => {
    if (selectedDimensions.length === 0) {
      return "-";
    }
    if (selectedDimensions.length === 1) {
      return (
        row?.widgetDimensions.find((widgetDimension) => widgetDimension.dimensionId === selectedDimensions[0].id)
          ?.value || "-"
      );
    }

    if (row?.widgetDimensions.every(fetchIsDimensionOther)) {
      return row?.widgetDimensions[0].value;
    }

    return (
      <React.Fragment>
        {selectedDimensions.map((dimension, index) => (
          <span key={dimension.id}>
            {index !== 0 && ", "}
            <ShowDimensionMetricPill item={dimension} /> :{" "}
            {row?.widgetDimensions.find((widgetDimension) => widgetDimension.dimensionId === dimension?.id)?.value ||
              "-"}
          </span>
        ))}
      </React.Fragment>
    );
  };

export const fetchColor = ({
  color,
  isCompare,
  isOther,
}: {
  color: string;
  isCompare?: boolean;
  isOther?: boolean;
}) => {
  const computedColor = isOther ? CHART_LINE_COLOR_OTHER : color;

  if (isCompare) {
    return colorFc(computedColor).lighten(0.3).string();
  }

  return computedColor;
};

export const fetchColorForRow = ({
  color,
  isCompare,
  row,
}: {
  color: string;
  isCompare?: boolean;
  isOther?: boolean;
  row?: WidgetRowT;
}) => {
  const isOther = fetchRowHasOtherData(row);
  return fetchColor({
    color,
    isCompare,
    isOther,
  });
};

export const removeTypenameOnArrayFields = <T extends Array<any>>(arr?: T): Array<Omit<T[number], "__typename">> => {
  return arr?.map((item) => omit(item, ["__typename"])) as Array<Omit<T[number], "__typename">>;
};
