import React from "react";
import { FormCustomMetric, FormCustomMetricPropsT } from "../components/formCustomMetric/FormCustomMetric";
import { CustomMetricQueryT } from "../graphql/generated/graphql";
import ErrorBox from "../ui/ErrorBox/ErrorBox";
import { Loader } from "../ui/Loader/Loader";
import { ModalBody } from "../ui/Modal";
import ModalCloseButton from "../ui/Modal/ModalCloseButton";

export default function MetricEditModalContent({
  data,
  isModalLoading,
  ...rest
}: FormCustomMetricPropsT & { data?: CustomMetricQueryT; isModalLoading: boolean }) {
  if (isModalLoading) {
    return <Loader />;
  }

  if (data?.organization?.customMetric?.discarded) {
    return (
      <>
        <ModalBody>
          <ErrorBox withIcon>Custom metric was deleted</ErrorBox>
        </ModalBody>
        <ModalCloseButton />
      </>
    );
  }

  return <FormCustomMetric defaultValues={data?.organization?.customMetric} {...rest} />;
}
