import { useEffect, useMemo } from "react";
import { isNull, omit } from "lodash";
import { useReportBoardContext } from "../components/reportBoard/context/reportBoardContext";
import { getConditionsApiInputFormat } from "../functions/conditions";
import { normalizeDateRange } from "../functions/normalize";
import {
  ConditionInputT,
  DateRangeEnumT,
  OrderDirectionT,
  WidgetCompareT,
  WidgetDateGroupingT,
  WidgetDimensionInputT,
  WidgetMetricInputT,
  useSharedWidgetDataQuery,
  useWidgetDataQuery,
} from "../graphql/generated/graphql";
import { useAppSettings } from "../providers/appSettingsProvider";
import { WidgetComponentPropsT } from "../types/widgets";
import { SortableStateT } from "../ui/DataTable/Sortable";
import { GraphqlPaginationVariablesT } from "../ui/Pagination/Pagination";

export type PropsT = Omit<WidgetComponentPropsT, "className" | "isShowRawData" | "onCloseRawData"> & {
  filters?: ConditionInputT[];
  limit?: number;
  order?: SortableStateT;
  pagination?: GraphqlPaginationVariablesT;
  skip?: boolean;
  useCampaignData?: boolean;
  useSummary?: boolean;
  useSummaryByDimensions?: boolean;
};

const convertBoolean = (value: boolean | string | null): null | boolean => {
  if (isNull(value)) {
    return null;
  }
  if (typeof value === "boolean") {
    return value;
  }
  if (value === "1" || value === "true" || value === "TRUE" || value === "t") {
    return true;
  }
  return false;
};

const removeTypename =
  (fields: string[] = []) =>
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  (object: any) => {
    const newObject = omit(object, ["__typename"]);

    return fields.reduce((subObject, field) => {
      if (subObject[field]) {
        subObject[field] = removeTypename()(subObject[field]);
      }
      return subObject;
    }, newObject);
  };

export const useGetWidgetComponentData = ({
  filterValues,
  filters,
  limit,
  onWidgetDataChange,
  order,
  pagination,
  skip,
  useCampaignData = false,
  useSummary = true,
  useSummaryByDimensions = false,
  widget,
}: PropsT) => {
  const { lastFetchedConnectedSystemsData, report } = useReportBoardContext();
  const { sourceSystems } = report;
  const { widgetDimensions, widgetMetrics } = widget;
  const dateRange = filterValues.dateRange;
  const { organization, reportToken, sharedReportPassword } = useAppSettings();
  const orderGqlFormated = useMemo(
    () =>
      order?.sortBy && order.direction
        ? { orderAttributeId: order.sortBy, orderDirection: order.direction as OrderDirectionT }
        : {},
    [order]
  );

  const normalRequest = useWidgetDataQuery({
    variables: {
      conditions: getConditionsApiInputFormat(filterValues.conditions),
      filters,
      useCampaignData,
      useSummary,
      useSummaryByDimensions,
      organizationExternalId: organization.externalId,
      reportId: report.id,
      currency: widget.currency || filterValues.currency,
      ...(widget.maxRows && { maxRows: Number(widget.maxRows), showOther: convertBoolean(widget.showOther) }),
      dateRange: normalizeDateRange(dateRange),
      compare: widget.compare || WidgetCompareT.NoneT,
      dateGrouping: widget.dateGrouping || WidgetDateGroupingT.SummaryT,
      widgetMetrics: widgetMetrics
        .filter((metric) => metric.metricId)
        .map(removeTypename(["dateRange"])) as WidgetMetricInputT[],
      widgetDimensions: widgetDimensions
        .filter((dimension) => dimension.dimensionId)
        .map(removeTypename()) as WidgetDimensionInputT[],
      sourceSystemIds: widget.sourceSystems.map((system) => system.id),
      limit,
      ...pagination,
      ...orderGqlFormated,
    },
    skip:
      !!reportToken ||
      !(
        (dateRange?.range && dateRange.range !== DateRangeEnumT.RangeCustomT) ||
        (dateRange?.range === DateRangeEnumT.RangeCustomT && dateRange?.from && dateRange?.to)
      ) ||
      skip,
  });

  const publicRequest = useSharedWidgetDataQuery({
    variables: {
      reportToken: reportToken as string,
      reportPassword: sharedReportPassword,
      useCampaignData,
      useSummary,
      useSummaryByDimensions,
      currency: widget.currency || filterValues.currency,
      dateRange: normalizeDateRange(dateRange),
      widgetId: widget.id,
      ...pagination,
      ...orderGqlFormated,
    },
    onError: (requestError) => {
      if (requestError.message.indexOf("403") !== -1) {
        window.location.reload();
      }
    },
    skip:
      !reportToken ||
      !(
        (dateRange?.range && dateRange.range !== DateRangeEnumT.RangeCustomT) ||
        (dateRange?.range === DateRangeEnumT.RangeCustomT && dateRange?.from && dateRange?.to)
      ) ||
      skip,
    fetchPolicy: "no-cache",
  });

  const widgetData = reportToken
    ? publicRequest?.data?.sharedReport?.widgetData
    : normalRequest?.data?.organization?.report?.widgetData;

  const error = reportToken ? publicRequest.error : normalRequest.error;
  const loading = reportToken ? publicRequest.loading : normalRequest.loading;
  const refetch = reportToken ? publicRequest.refetch : normalRequest.refetch;

  useEffect(() => {
    if (widgetData && onWidgetDataChange) {
      onWidgetDataChange(widgetData);
    }
  }, [widgetData, onWidgetDataChange]);

  useEffect(() => {
    refetch();
  }, [refetch, sourceSystems, lastFetchedConnectedSystemsData]);

  return { widgetData, error, loading };
};
