import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";
import { usePrevious } from "../../hooks/usePrevious";
import { Icon } from "../Icon/Icon";
import { Tooltip } from "../Tooltip/Tooltip";

export type SortDirectionT = "asc" | "desc";

export type SortingIconElementPropsT = {
  sort?: string;
};

export const SortingIconElement = ({ sort = "asc" }: SortingIconElementPropsT) => {
  return (
    <Tooltip tooltipContent={sort === "asc" ? "Order ascending" : "Order descending"}>
      <Icon
        className="pa-8 pl-4 clickable line-height-1 ml-0"
        color="#26394a"
        kind={sort === "asc" ? "arrow-up" : "arrow-down"}
        size="10px"
      />
    </Tooltip>
  );
};

export type OverrideSortableStateT = {
  direction?: SortDirectionT;
  sortBy?: string;
};

export type SortableStateT = {
  direction?: SortDirectionT;
  sortBy?: string;
};

export type OnSortArgsT = {
  direction: SortDirectionT;
  sortBy: string;
};

export type OnSortT = (args: OnSortArgsT) => void;
export type SortableAdditionalPropsT = SortableStateT & {
  onSort: OnSortT;
};

type Unarray<T> = T extends Array<infer U> ? U : T;

function sortWithNullLast<Type>(direction: SortDirectionT, getValue: (row: Unarray<Type>) => string | number | null) {
  return (rowA: Unarray<Type>, rowB: Unarray<Type>): number => {
    const valueA = getValue(rowA);
    const valueB = getValue(rowB);
    const valA = typeof valueA === "string" ? valueA.toLowerCase() : valueA;
    const valB = typeof valueB === "string" ? valueB.toLowerCase() : valueB;

    // equal items sort equally
    if (valA === valB) {
      return 0;
    }

    // nulls sort after anything else
    if (valA === null) {
      return 1;
    }
    if (valB === null) {
      return -1;
    }

    // otherwise, if we're ascending, lowest sorts first
    if (direction === "asc") {
      return valA < valB ? -1 : 1;
    }

    // if descending, highest sorts first
    return valA < valB ? 1 : -1;
  };
}

const DEFAULT_OVERRIDE_STATE = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useSortable<Type extends any[] | []>(
  tableRows: Type,
  getValue: (sortBy: string) => (row: Unarray<Type>) => number | string | null,
  overrideDefaultState: OverrideSortableStateT = DEFAULT_OVERRIDE_STATE,
  onSortChange?: (args: SortableStateT) => void,
  customSortState?: OverrideSortableStateT
): [sortablePropsWithCallbacks: SortableAdditionalPropsT, sortedTableRows: Type] {
  const [sortableState, setSortableState] = useState<SortableStateT>({
    direction: "asc",
    sortBy: undefined,
    ...overrideDefaultState,
  });

  const sortableProps = customSortState || sortableState;

  const prevOverrideDefaultState = usePrevious(overrideDefaultState);
  useEffect(() => {
    if (!isEqual(prevOverrideDefaultState, overrideDefaultState)) {
      setSortableState((state) => ({
        ...state,
        ...overrideDefaultState,
      }));
    }
  }, [overrideDefaultState, prevOverrideDefaultState]);

  const onSort: OnSortT = useCallback(
    ({ direction, sortBy }) => {
      setSortableState({ direction, sortBy });
      if (typeof onSortChange === "function") {
        onSortChange({ direction, sortBy });
      }
    },
    [setSortableState, onSortChange]
  );

  const sortPropsWithCallbacks = useMemo(
    () =>
      ({
        ...sortableProps,
        onSort,
      } as SortableAdditionalPropsT),
    [sortableProps, onSort]
  );

  const sortedData = useMemo(() => {
    if (sortableProps.sortBy) {
      const getRowValue = getValue(sortableProps.sortBy);
      return [...tableRows].sort(sortWithNullLast(sortableProps.direction || "asc", getRowValue)) as Type;
    } else {
      return tableRows;
    }
  }, [tableRows, sortableProps.direction, sortableProps.sortBy, getValue]);

  return [sortPropsWithCallbacks, sortedData];
}

export const useGraphqlSortable = (overrideDefaultState: OverrideSortableStateT = DEFAULT_OVERRIDE_STATE) => {
  const [sortableState, setSortableState] = useState<SortableStateT>({
    direction: "asc",
    sortBy: undefined,
    ...overrideDefaultState,
  });

  const prevOverrideDefaultState = usePrevious(overrideDefaultState);

  useEffect(() => {
    if (!isEqual(prevOverrideDefaultState, overrideDefaultState)) {
      setSortableState((state) => ({
        ...state,
        ...overrideDefaultState,
      }));
    }
  }, [overrideDefaultState, prevOverrideDefaultState]);

  const onSort: OnSortT = useCallback(
    ({ direction, sortBy }) => {
      setSortableState({ direction, sortBy });
    },
    [setSortableState]
  );

  return useMemo(
    () =>
      ({
        ...sortableState,
        onSort,
      } as SortableAdditionalPropsT),
    [sortableState, onSort]
  );
};

interface SortElementPropsT extends SortableStateT {
  sortByValue: string;
}
export const SortElement = ({ direction, sortBy, sortByValue }: SortElementPropsT) => {
  if (sortBy === sortByValue) {
    if (direction === "asc") {
      return <SortingIconElement />;
    }
    return <SortingIconElement sort="desc" />;
  }
  return (
    <div className="ShowOnHoverElement d-inline-block">
      <SortingIconElement />
    </div>
  );
};

interface SortableWrapperPropsT extends SortableAdditionalPropsT {
  defaultDirection?: SortDirectionT;
  sortByValue: string;
}

export const SortableWrapper = ({
  children,
  defaultDirection,
  direction,
  onSort,
  sortBy,
  sortByValue,
}: PropsWithChildren<SortableWrapperPropsT>) => {
  let nextDirection = defaultDirection || "asc";
  if (sortBy === sortByValue) {
    nextDirection = direction === "asc" ? "desc" : "asc";
  }

  return (
    <div
      className={"ShowOnHoverWrapper cursor-pointer select-none d-inline-flex align-items-center"}
      style={{
        marginTop: "-4px",
        paddingTop: "4px",
        marginBottom: "-4px",
        paddingBottom: "4px",
      }}
      onClick={() => onSort({ sortBy: sortByValue, direction: nextDirection })}
    >
      {children}
      <SortElement direction={direction} sortBy={sortBy} sortByValue={sortByValue} />
    </div>
  );
};
