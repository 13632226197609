import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Placement } from "@floating-ui/react";
import classNames from "classnames";
import { OptimizationCampaignDataColumnT } from "../../constants/report";
import { fetchDimensionId, fetchMetricId } from "../../functions/fetchIds";
import {
  ActionStateT,
  CampaignDataT,
  CampaignStatusT,
  CampaignT,
  SystemNameT,
  WidgetDataFragmentT,
  WidgetDateGroupingT,
  useUserCampaignActionsLazyQueryT,
} from "../../graphql/generated/graphql";
import { formatMetricValue } from "../../i18n/formatNumbers";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { WidgetT } from "../../types/widgets";
import { ButtonSecondary } from "../../ui/Button/Button";
import { TrendWrapper } from "../../ui/DataTable/TrendWrapper";
import { DropdownMenu } from "../../ui/Dropdown/DropdownMenu";
import { MiddleEllipsis } from "../../ui/Ellipsis/MiddleEllipsis";
import { Col, Row } from "../../ui/grid/Grid";
import { Td, TrBody } from "../../ui/Table/Table";
import { Text } from "../../ui/Text/Text";
import TimeAgo from "../../ui/TimeAgo/TimeAgo";
import { Tooltip } from "../../ui/Tooltip/Tooltip";
import { Dot } from "../dot/Dot";
import { WidgetSettingsDataT } from "../reportBoard/context/types";
import { BadgeChangesNumber } from "../userSystemAction/BadgeChangesNumber";
import { ChangeBiddingForm, useChangeBiddingFormMutation } from "../userSystemAction/ChangeBiddingForm";
import { ChangeBudgetForm, getIsEditable, useChangeBudgetFormMutation } from "../userSystemAction/ChangeBudgetForm";
import {
  ChangeFormContextT,
  ChangeStatusForm,
  useChangeStatusFormMutation,
} from "../userSystemAction/ChangeStatusForm";
import { useUserCampaignActionsHistory } from "../userSystemAction/useUserCampaignHistory";
import { TableOptimatizationCell } from "./TableOptimatizationCell/TableOptimatizationCell";
import { CampaignIncludedDataT } from "./TableOptimizationComponent";

export const MODAL_CHANGE_STATUS = "MODAL_CHANGE_STATUS";
export const MODAL_CHANGE_BIDDING = "MODAL_CHANGE_BIDDING";
export const MODAL_CHANGE_BUDGET = "MODAL_CHANGE_BUDGET";

type ChangePopupPropsT = Pick<PropsT, "campaignIncludedData"> & {
  campaign: PickedCampaignT;
  changeFormContext: ChangeFormContextT;
  placement?: Placement;
};

export const ChangePopup = ({ campaign, changeFormContext, ...rest }: ChangePopupPropsT) => {
  if (changeFormContext.popup.action === MODAL_CHANGE_STATUS && changeFormContext.popup.campaignId === campaign.id) {
    return (
      <ChangeStatusForm {...rest} {...changeFormContext} campaign={campaign} onClose={changeFormContext.resetPopup} />
    );
  }

  if (changeFormContext.popup.action === MODAL_CHANGE_BIDDING && changeFormContext.popup.campaignId === campaign.id) {
    return (
      <ChangeBiddingForm {...rest} {...changeFormContext} campaign={campaign} onClose={changeFormContext.resetPopup} />
    );
  }

  if (changeFormContext.popup.action === MODAL_CHANGE_BUDGET && changeFormContext.popup.campaignId === campaign.id) {
    return (
      <ChangeBudgetForm {...rest} {...changeFormContext} campaign={campaign} onClose={changeFormContext.resetPopup} />
    );
  }

  return null;
};

export type PickedCampaignT = Pick<
  CampaignT,
  | "id"
  | "name"
  | "system"
  | "updatedAt"
  | "status"
  | "currency"
  | "biddingId"
  | "budgetId"
  | "beeCampaignId"
  | "beeSystemSettingsLink"
  | "beeManagedBidding"
  | "beeManagedBudget"
  | "availableBiddingSchemes"
>;

export type PickedCampaignDataT = {
  campaign: PickedCampaignT;
  id: CampaignDataT["id"];
};

type SingleRowMenuT = Pick<PropsT, "isFakeData" | "isPreview" | "row"> & {
  campaign: PickedCampaignT;
  campaignIncludedData: CampaignIncludedDataT;
  changeFormContext: ChangeFormContextT;
};

const SingleRowMenu = ({
  campaign,
  campaignIncludedData: { budgetsById },
  campaignIncludedData,
  changeFormContext,
  isFakeData,
  isPreview,
  row,
}: SingleRowMenuT) => {
  const budget = budgetsById[campaign.budgetId];
  const isEditable = getIsEditable(budget);

  const getSingleRowMenu = useCallback(
    () =>
      isPreview
        ? []
        : [
            {
              value: "Change status",
              kind: OptimizationCampaignDataColumnT.StatusT,
              onClick: () =>
                changeFormContext.setPopup({
                  action: MODAL_CHANGE_STATUS,
                  campaignId: campaign.id,
                  isFirstCell: true,
                }),
            },
            ...(campaign.system === SystemNameT.AdwordsT && campaign.status !== CampaignStatusT.RemovedT
              ? [
                  {
                    value: "Change bidding",
                    kind: OptimizationCampaignDataColumnT.BiddingT,
                    onClick: () =>
                      changeFormContext.setPopup({
                        action: MODAL_CHANGE_BIDDING,
                        campaignId: campaign.id,
                        isFirstCell: true,
                      }),
                  },
                ]
              : []),
            ...(campaign.status !== CampaignStatusT.RemovedT && isEditable
              ? [
                  {
                    value: "Change budget",
                    kind: OptimizationCampaignDataColumnT.BudgetT,
                    onClick: () =>
                      changeFormContext.setPopup({
                        action: MODAL_CHANGE_BUDGET,
                        campaignId: campaign.id,
                        isFirstCell: true,
                      }),
                  },
                ]
              : []),
          ],
    [isPreview, changeFormContext, campaign, isEditable]
  );

  return (
    <>
      <DropdownMenu
        buttonProps={{ disabled: isPreview || isFakeData, size: "small", icon: "edit" }}
        className="mr-8"
        collection={getSingleRowMenu()}
        testId={`row-menu-${row.id}`}
      />

      {changeFormContext.popup.isFirstCell && (
        <ChangePopup
          campaign={campaign}
          campaignIncludedData={campaignIncludedData}
          changeFormContext={changeFormContext}
        />
      )}
    </>
  );
};

type PropsT = {
  campaignIncludedData: CampaignIncludedDataT;
  getCampaignData: (
    ids: { campaign: { id: string } }[]
  ) => NonNullable<WidgetDataFragmentT["includedData"]>["campaignsData"] | undefined;
  hasCampaignNameColumn: boolean;
  isExpanded?: boolean;
  isFakeData: boolean;
  isFocused?: boolean;
  isHiglighted?: boolean;
  isLastSubRow?: boolean;
  isPreview?: boolean;
  isSubrow?: boolean;
  onToggleExpand?: () => void;
  popup: ChangeFormContextT["popup"];
  row: WidgetDataFragmentT["rows"]["nodes"][0];
  searchValue?: string;
  setPopup: ChangeFormContextT["setPopup"];
  widget: WidgetT;
  widgetSettingsData: WidgetSettingsDataT;
};

export const TableOptimizationRowComponent = ({
  campaignIncludedData,
  getCampaignData,
  hasCampaignNameColumn,
  isExpanded,
  isFakeData,
  isFocused,
  isHiglighted,
  isLastSubRow,
  isPreview,
  isSubrow,
  onToggleExpand,
  popup,
  row,
  setPopup,
  widget,
  widgetSettingsData,
}: PropsT) => {
  const { widgetDimensions, widgetMetrics } = widget;
  const { metricsById } = widgetSettingsData;
  const { organization } = useAppSettings();

  // User actions handling
  const [mutateStatus, mutateStatusResult] = useChangeStatusFormMutation();
  const [mutateBidding, mutateBiddingResult] = useChangeBiddingFormMutation();
  const [mutateBudget, mutateBudgetResult] = useChangeBudgetFormMutation();

  const allMutateResults = useMemo(
    () => [
      ...(mutateStatusResult.data?.organization?.statusChangesUserCampaignAction?.userCampaignActions || []),
      ...(mutateBiddingResult.data?.organization?.biddingChangesUserCampaignAction?.userCampaignActions || []),
      ...(mutateBudgetResult.data?.organization?.budgetChangesUserCampaignAction?.userCampaignActions || []),
    ],
    [mutateStatusResult, mutateBiddingResult, mutateBudgetResult]
  );

  const userActionIds = allMutateResults.map(({ id }) => id);

  const [updateStateOfUserActions, { data: dataUserCampaignActions }] = useUserCampaignActionsLazyQueryT({
    variables: {
      limit: userActionIds?.length || 0,
      offset: 0,
      organizationExternalId: organization.externalId,
      ids: userActionIds,
    },
    fetchPolicy: "cache-and-network",
  });

  const timer = useRef<undefined | NodeJS.Timeout>(undefined);

  if (
    !timer.current &&
    userActionIds &&
    userActionIds.length > 0 &&
    (!dataUserCampaignActions ||
      dataUserCampaignActions?.organization?.userCampaignActions?.nodes.find(
        ({ state }) => state === ActionStateT.QueuedT || state === ActionStateT.RunningT
      ))
  ) {
    timer.current = setTimeout(() => {
      updateStateOfUserActions();
      clearInterval(timer.current);
      timer.current = undefined;
    }, 2000);
  }

  const userCampaignActions = dataUserCampaignActions?.organization?.userCampaignActions?.nodes || allMutateResults;

  const changeFormContext = useMemo(
    () => ({
      mutateStatus,
      mutateStatusResult,
      mutateBudget,
      mutateBudgetResult,
      mutateBidding,
      mutateBiddingResult,
      resetPopup: () => setPopup({ campaignId: null, action: null, isFirstCell: false }),
      setPopup,
      popup,
    }),
    [
      mutateStatus,
      mutateStatusResult,
      mutateBudget,
      mutateBudgetResult,
      mutateBidding,
      mutateBiddingResult,
      setPopup,
      popup,
    ]
  );

  // other row logic
  const campaignData = useMemo(() => getCampaignData(row?.campaignData || []) || [], [getCampaignData, row]);
  const isSingleRow = (campaignData.length === 1 && hasCampaignNameColumn) || isSubrow;
  const isMultipleRow = (campaignData.length > 1 || !hasCampaignNameColumn) && !isSubrow;
  const numberOfSubRow = campaignData.length;

  const lastUpdate = Math.min(...(campaignData.map((campaign) => Date.parse(campaign.campaign.updatedAt)) || []));

  const userCampaignAction = useMemo(
    () => userCampaignActions?.find((action) => action?.campaign?.id === campaignData[0].campaign.id),
    [userCampaignActions, campaignData]
  );

  const { dataUserCampaignActionsHistory, refetch } = useUserCampaignActionsHistory({
    campaignsData: campaignData,
    skip:
      !changeFormContext.mutateBiddingResult.called &&
      !changeFormContext.mutateBudgetResult.called &&
      !changeFormContext.mutateStatusResult.called,
  });

  const userCampaignActionsHistory = useMemo(
    () =>
      dataUserCampaignActionsHistory?.organization?.userCampaignActions.nodes ||
      (campaignData[0]?.userCampaignActionIds || []).map((id) => campaignIncludedData.userCampaignActions[id]),
    [campaignData, campaignIncludedData.userCampaignActions, dataUserCampaignActionsHistory]
  );

  useEffect(() => {
    if (userCampaignAction) {
      refetch();
    }
  }, [userCampaignAction, refetch]);

  return (
    <TrBody
      className={classNames({
        "Sticky-Row--dashed": isExpanded,
        "Sticky-Row--subRow": isSubrow,
        "Sticky-Row--lastSubRow": isSubrow && isLastSubRow,
        "Sticky-Row--highlighted": isHiglighted,
        "Sticky-Row--focused": isFocused && !isExpanded,
      })}
    >
      {widgetDimensions.map((dim, dimIndex) => (
        <Td
          key={`${fetchDimensionId(dim)}__${dimIndex}`}
          style={{ paddingRight: "40px", width: "320px", maxWidth: "320px" }}
        >
          <Row justify="between">
            <Col type="equalSize">
              <MiddleEllipsis className="mw-100">
                {isSubrow
                  ? campaignData[0].campaign.name
                  : row.widgetDimensions.find((wd) => wd.widgetDimensionId == fetchDimensionId(dim))?.value}
              </MiddleEllipsis>
            </Col>
            {dimIndex === 0 && isSingleRow && campaignData[0].campaign.beeCampaignId && (
              <Tooltip enterDelay={300} tooltipContent="Managed by Dotidot">
                <Dot style={{ marginTop: "1px" }} />
              </Tooltip>
            )}
            {dimIndex === 0 && isSingleRow && !!userCampaignActionsHistory.length && (
              <BadgeChangesNumber className="ml-8" userCampaignActions={userCampaignActionsHistory} />
            )}
            {isMultipleRow && dimIndex === 0 && (
              <Text className="ml-8" color="softGray" size="xs" nowrap>
                {numberOfSubRow} grouped
              </Text>
            )}
          </Row>

          <div className="pos-absolute align-vertical" style={{ right: 0 }}>
            {isSingleRow && dimIndex === 0 && (
              <SingleRowMenu
                campaign={campaignData[0].campaign}
                campaignIncludedData={campaignIncludedData}
                changeFormContext={changeFormContext}
                isFakeData={isFakeData}
                isPreview={isPreview}
                row={row}
              />
            )}

            {dimIndex === 0 && isMultipleRow && (
              <ButtonSecondary
                className="mr-8"
                icon={isExpanded ? "chevron-up" : "chevron-down"}
                size="small"
                onlyIcon
                onClick={onToggleExpand}
              />
            )}
          </div>
        </Td>
      ))}

      {widgetMetrics.map((met, metIndex) => {
        const foundMetric = row.widgetMetrics.find((wd) => wd.widgetMetricId == fetchMetricId(met));
        return (
          <Td key={`${fetchMetricId(met)}__${metIndex}`} justifyRight>
            <span>
              <TrendWrapper
                positiveTrend={metricsById[met.metricId].positiveTrend as boolean}
                trend={foundMetric?.trend}
                compare={
                  (widget.dateGrouping === WidgetDateGroupingT.SummaryT ? met.compare : undefined) || widget.compare
                }
                previousValue={formatMetricValue({
                  metric: metricsById[met.metricId],
                  currency: row.currency,
                  value: foundMetric?.previousValue,
                })}
              >
                {formatMetricValue({
                  metric: metricsById[met.metricId],
                  currency: row.currency,
                  value: foundMetric?.value,
                })}
              </TrendWrapper>
            </span>
          </Td>
        );
      })}

      {widget.campaignDataColumns.map((col) => (
        <Td key={`campaignDataColumns-${col}`}>
          <TableOptimatizationCell
            campaignData={campaignData}
            campaignIncludedData={campaignIncludedData}
            changeFormContext={changeFormContext}
            type={col}
            userCampaignActions={userCampaignActions}
          />
        </Td>
      ))}
      <Td>{lastUpdate && isFinite(lastUpdate) && <TimeAgo time={lastUpdate} />}</Td>
    </TrBody>
  );
};

export const TableOptimizationRow = (props: PropsT) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const isFocused = props.row.campaignData.some((campaign) => campaign.campaign.id === props.popup.campaignId);

  useEffect(() => {
    setIsExpanded(!!props.searchValue);
  }, [props.searchValue]);

  return (
    <>
      <TableOptimizationRowComponent
        {...props}
        isExpanded={isExpanded}
        isFocused={isFocused}
        onToggleExpand={() => setIsExpanded((prev) => !prev)}
      />

      {isExpanded &&
        (props.row.campaignData.length > 1 || !props.hasCampaignNameColumn) &&
        props.row.campaignData.map((campaign, index, arr) => {
          const isSubRowFocused = campaign.campaign.id === props.popup.campaignId;
          return (
            <TableOptimizationRowComponent
              {...props}
              key={campaign.campaign.id}
              isFocused={isSubRowFocused}
              isLastSubRow={index + 1 === arr.length}
              row={{ ...props.row, campaignData: [campaign] }}
              isSubrow
            />
          );
        })}
    </>
  );
};
