import React from "react";
import classNames from "classnames";
import { ButtonTertiary } from "../../ui/Button/Button";
import { Ellipsis } from "../../ui/Ellipsis/Ellipsis";
import { Col, Row } from "../../ui/grid/Grid";
import { Heading } from "../../ui/Heading/Heading";
import { SubheaderPropsT } from "./Subheader";

export const SubheaderComponent = ({ backTo, children, title }: SubheaderPropsT) => {
  return (
    <>
      {backTo && (
        <>
          <Col className="mr-8" type="shrink">
            <ButtonTertiary icon="arrow-left" to={backTo} onlyIcon />
          </Col>
          <Col className="mr-24" type="shrink">
            <div className="delimiter-vertical"></div>
          </Col>
        </>
      )}
      <Col className={classNames({ "negative-ml-16": !!backTo, "ml-8": !backTo })} type="grow">
        <Row alignItems="center" justify="between">
          {title && (
            <Col type="grow">
              <Heading className="pt-8" data-test-id="subheader-title" level="h1" margin={0}>
                {/*
                Todo: Uncomment this when we have a fixed inlineEdit
                {onTitleChange ? (
                  <InlineEdit kind="h3" value={title as string} onChange={onTitleChange} />
                ) : ( */}
                <Ellipsis>{title}</Ellipsis>
                {/* )} */}
              </Heading>
            </Col>
          )}
          {children}
        </Row>
      </Col>
    </>
  );
};
