import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { get } from "lodash";
import { Control, Controller, FieldArrayWithId, Path, PathValue, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { DIMENSION_CAMPAIGN_STATUS_ORIGINAL_NAME } from "../../constants/common";
import {
  SELECT_OPTIONS_DIMENSION_CAMPAIGN_STATUS_OPERATOR,
  SELECT_OPTIONS_DIMENSION_OPERATOR,
  SELECT_OPTIONS_METRIC_OPERATOR,
} from "../../constants/form";
import { DimensionOperatorT, MetricDataT, MetricOperatorT, ValueCountT } from "../../graphql/generated/graphql";
import { usePrevious } from "../../hooks/usePrevious";
import { ButtonSecondary, ButtonTertiary } from "../../ui/Button/Button";
import Input from "../../ui/forms/Input";
import { InputController } from "../../ui/forms/InputController";
import { MetricDimensionController, MetricOrDimensionT } from "../../ui/forms/MetricDimensionController";
import { SelectableDimensionT, SelectableMetricT } from "../../ui/forms/MetricDimensionSelectTabs";
import { Col, Row } from "../../ui/grid/Grid";
import { Heading } from "../../ui/Heading/Heading";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { FormKpiInputsT } from "../formKpi/FormKpi";
import { DimensionsValueCountsT } from "./FormConditions";

const DEFAULT_METRIC_OPERATOR = MetricOperatorT.GtT;
const DEFAULT_DIMENSION_OPERATOR = DimensionOperatorT.MatchT;
const DIM_OPERATORS_WITH_DIM_VALUES = [DimensionOperatorT.EqT, DimensionOperatorT.NotEqT];

type PropsT<T extends Pick<FormKpiInputsT, "conditions">> = {
  control: Control<T>;
  dimensions?: SelectableDimensionT[];
  dimensionsValueCounts?: DimensionsValueCountsT;
  error?: { message?: string };
  field: FieldArrayWithId<FormKpiInputsT, "conditions", "id">;
  index: number;
  isAlone: boolean;
  isFormEmpty: boolean;
  isGroupEnd: boolean;
  isGroupStart: boolean;
  isLast: boolean;
  loading?: boolean;
  metrics?: SelectableMetricT[];
  onAddCondition: (groupId: string, index: number) => void;
  onGetValueCounts?: (dimensionId: string) => void;
  onRemoveCondition: (index: number) => void;
  onRemoveGroup: (groupId: string) => void;
  onSetValue: UseFormSetValue<T>;
  valueCounts?: ValueCountT[];
  watch: UseFormWatch<T>;
};

const FormConditionsItem = <T extends Pick<FormKpiInputsT, "conditions">>({
  control,
  dimensions,
  dimensionsValueCounts,
  error,
  field,
  index,
  isAlone,
  isFormEmpty,
  isGroupEnd,
  isGroupStart,
  isLast,
  metrics,
  onAddCondition,
  onGetValueCounts,
  onRemoveCondition,
  onRemoveGroup,
  onSetValue,
  watch,
}: PropsT<T>) => {
  const [unitType, setUnitType] = useState<"dimension" | "metric" | null>(field.unit.type || null);
  const [value, setValue] = useState(field.unit.value || "");
  const [typeRestriction, setTypeRestriction] = useState<MetricOrDimensionT | null>(null);
  const conditionFormPath = `conditions.${index}` as const;

  const campaignStatusId = dimensions?.find((dim) => dim.name === DIMENSION_CAMPAIGN_STATUS_ORIGINAL_NAME)?.id;
  const isDimensionCampaignStatus = value === campaignStatusId;

  let selectOptionsOperator = [
    {
      label: "Select condition",
      value: "",
    },
  ];

  if (unitType === "metric") {
    selectOptionsOperator = [...selectOptionsOperator, ...SELECT_OPTIONS_METRIC_OPERATOR];
  }

  if (unitType === "dimension" && !isDimensionCampaignStatus) {
    selectOptionsOperator = [...selectOptionsOperator, ...SELECT_OPTIONS_DIMENSION_OPERATOR];
  }

  if (isDimensionCampaignStatus) {
    selectOptionsOperator = [...selectOptionsOperator, ...SELECT_OPTIONS_DIMENSION_CAMPAIGN_STATUS_OPERATOR];
  }

  const dataType = useMemo(() => {
    if (unitType === "metric") {
      return metrics?.find((met) => met.id === value)?.dataType;
    }

    return "";
  }, [metrics, unitType, value]);

  useEffect(() => {
    const subscription = watch((values) => {
      const conditionValues = get(values, conditionFormPath);

      setUnitType((prev) => (prev !== conditionValues.unit.type ? conditionValues.unit.type : prev));
      setValue((prev) => (prev !== conditionValues.unit.value ? conditionValues.unit.value : prev));

      const groupConditions =
        values.conditions?.filter((val) => val?.groupId === conditionValues?.groupId && val?.unit?.type) || [];
      const filledConditionsCount = groupConditions.length;
      const restrictedType = groupConditions[0]?.unit?.type || null;

      setTypeRestriction(
        filledConditionsCount > 1 || (filledConditionsCount === 1 && !conditionValues?.unit.type)
          ? restrictedType
          : null
      );

      if (
        onGetValueCounts &&
        !dimensionsValueCounts?.[conditionValues?.unit?.value] &&
        conditionValues?.unit?.type === "dimension" &&
        conditionValues?.unit?.value &&
        conditionValues.unit.value !== "" &&
        DIM_OPERATORS_WITH_DIM_VALUES.includes(conditionValues.operator)
      ) {
        onGetValueCounts(conditionValues.unit.value);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, index, setUnitType, conditionFormPath, onGetValueCounts, dimensionsValueCounts]);

  const [inputValue, setInputValue] = useState<string | null>(
    dataType === MetricDataT.PercentT ? `${Number(field.value[0]) * 100}` : field.value[0] || null
  );

  const itemValue = watch().conditions[index];
  const prevItemValue = usePrevious({ ...itemValue });

  // set correct values when switch metric/dimension
  useEffect(() => {
    if (itemValue.unit.type && prevItemValue?.unit.type && itemValue.unit.type !== prevItemValue.unit.type) {
      onSetValue(
        `${conditionFormPath}.operator` as Path<T>,
        itemValue.unit.type === "metric"
          ? (DEFAULT_METRIC_OPERATOR as PathValue<T, Path<T>>)
          : (DEFAULT_DIMENSION_OPERATOR as PathValue<T, Path<T>>)
      );
      onSetValue(`${conditionFormPath}.value` as Path<T>, [] as PathValue<T, Path<T>>);
      setInputValue("");
    }
  }, [itemValue, prevItemValue, onSetValue, conditionFormPath]);

  // clear value for Campaign status
  useEffect(() => {
    if (prevItemValue?.unit.value !== itemValue.unit.value && isDimensionCampaignStatus) {
      onSetValue(`${conditionFormPath}.operator` as Path<T>, "" as PathValue<T, Path<T>>);
      onSetValue(`${conditionFormPath}.value` as Path<T>, [] as PathValue<T, Path<T>>);
    }
  }, [prevItemValue, itemValue, isDimensionCampaignStatus, onSetValue, conditionFormPath]);

  return (
    <>
      <div
        className={classNames("border-left border-right border-color-soft-gray ph-16", {
          "border-top border-top-left-radius-8 border-top-right-radius-8 pt-16": isGroupStart,
          "border-bottom border-bottom-left-radius-8 border-bottom-right-radius-8 pb-16": isGroupEnd,
        })}
      >
        {isGroupStart && (
          <div className="d-flex align-items-center justify-content-between mb-8">
            <Heading level="h3" margin={0}>
              Condition
            </Heading>

            {!isFormEmpty && !isAlone && (
              <ButtonTertiary icon="trash" size="small" onlyIcon onClick={() => onRemoveGroup(field.groupId)} />
            )}
          </div>
        )}
        <Tile className="border-radius-4" isSmallSpaced>
          <Row alignItems="center">
            <Col alignItems="center" width="164px">
              WHERE
            </Col>
            <Col type="grow">
              <MetricDimensionController
                control={control}
                dimensions={dimensions}
                disabledTab={typeRestriction ? (typeRestriction === "metric" ? "dimension" : "metric") : undefined}
                id={`${conditionFormPath}.unit`}
                metrics={metrics}
                name={`${conditionFormPath}.unit` as Path<T>}
                placeholder="Choose variable"
                onSetValue={onSetValue}
              />
            </Col>
          </Row>
          <Row>
            <Col width="164px">
              <InputController
                collection={selectOptionsOperator}
                control={control}
                id={`${conditionFormPath}.operator`}
                name={`${conditionFormPath}.operator` as Path<T>}
                select={{ disabled: selectOptionsOperator.length <= 1 }}
              />
            </Col>
            <Col type="grow">
              {unitType === "dimension" &&
              DIM_OPERATORS_WITH_DIM_VALUES.includes(itemValue.operator as DimensionOperatorT) ? (
                <InputController
                  control={control}
                  id={`${conditionFormPath}.value`}
                  name={`${conditionFormPath}.value` as Path<T>}
                  placeholder="Insert Values"
                  smartSelect={{
                    options: (dimensionsValueCounts?.[value]?.valuesCount || []).map((item) => item.value),
                    isMulti: true,
                    isLoading: dimensionsValueCounts?.[value]?.isLoading,
                    isDisabled: !unitType,
                    isCreatable: !isDimensionCampaignStatus,
                  }}
                />
              ) : (
                <Controller
                  control={control}
                  name={`${conditionFormPath}.value` as Path<T>}
                  render={(opts) => (
                    <Input
                      inputProps={{
                        ...opts.field,
                        type: unitType === "dimension" ? "text" : "number",
                        unit: dataType === MetricDataT.PercentT ? "%" : undefined,
                        id: `${conditionFormPath}.value`,
                        value: new Array(1).fill(inputValue),
                        onChange: (event) => {
                          setInputValue(event.currentTarget.value);
                          if (event.currentTarget.value === "") {
                            return opts.field.onChange([]);
                          }

                          opts.field.onChange([
                            `${
                              dataType === MetricDataT.PercentT
                                ? !isNaN(event.currentTarget.valueAsNumber)
                                  ? event.currentTarget.valueAsNumber / 100
                                  : event.currentTarget.value
                                : event.currentTarget.value
                            }`,
                          ]);
                        },
                      }}
                    />
                  )}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col type="grow">
              <div className="d-flex align-items-center">
                {error && (
                  <Text className="flex-1" color="error">
                    {error.message}
                  </Text>
                )}
                {isFormEmpty && !error && (
                  <Text className="flex-1 position-absolute" color="softGray">
                    Setup the Condition First.
                  </Text>
                )}
                {!isFormEmpty && (
                  <ButtonTertiary
                    className="ml-a"
                    icon="trash"
                    size="small"
                    onlyIcon
                    onClick={() => onRemoveCondition(index)}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Tile>
        {!isGroupEnd && (
          <div className="mt-16 pb-16 d-flex justify-content-center">
            <Text color="softGray" style={{ fontSize: "14px" }}>
              OR
            </Text>
          </div>
        )}
        {(isGroupEnd || isLast) && (
          <div className="mt-8 d-flex justify-content-center">
            <ButtonSecondary onClick={() => onAddCondition(field.groupId, index + 1)}>OR</ButtonSecondary>
          </div>
        )}
      </div>
      {isGroupEnd && !isLast && (
        <div className="mv-16 d-flex justify-content-center">
          <Text color="softGray" style={{ fontSize: "14px" }}>
            AND
          </Text>
        </div>
      )}
    </>
  );
};

export default FormConditionsItem;
