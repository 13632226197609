import React, { useEffect, useMemo } from "react";
import { groupBy } from "lodash";
import { normalizeSearchText } from "../../functions/normalize";
import { MetricDataT, ValueCategoryT } from "../../graphql/generated/graphql";
import { Col, Row } from "../grid/Grid";
import { Icon } from "../Icon/Icon";
import { InfoBox } from "../InfoBox/InfoBox";
import { Link } from "../Link/Link";
import { Text } from "../Text/Text";
import { MetricOrDimensionT } from "./MetricDimensionController";
import { MetricDimensionPill } from "./MetricDimensionItemPill";
import { MetricDimensionSelectPropsT } from "./MetricDimensionSelect";
import { SelectableDimensionT } from "./MetricDimensionSelectTabs";

type MetricDimensionCategoriesT = Omit<
  MetricDimensionSelectPropsT,
  "dimensions" | "metrics" | "typeRestriction" | "onResetValue"
> & {
  onSetValuesCount?: (length: number) => void;
  type: MetricOrDimensionT;
  values: Array<SelectableDimensionT & { dataType?: MetricDataT }>;
  withTabs?: boolean;
};

export const CATEGORY_PROPS = {
  [ValueCategoryT.AdSystemsT]: { label: "Ad systems", icon: "" },
  [ValueCategoryT.CustomT]: { label: "Custom", icon: "user" },
  [ValueCategoryT.CustomValueT]: { label: "Custom value", icon: "user" },
  [ValueCategoryT.GoogleAdsT]: { label: "Google Ads", icon: "adwords-icon" },
  [ValueCategoryT.MetaT]: { label: "Meta", icon: "facebook" },
  [ValueCategoryT.MicrosoftAdvertisingT]: { label: "Microsoft Advertising", icon: "bing-icon" },
  [ValueCategoryT.SklikT]: { label: "Sklik", icon: "sklik-icon" },
  [ValueCategoryT.AnalyticsT]: { label: "Google Analytics", icon: "google-analytics" },
  [ValueCategoryT.GoogleAnalytics3T]: { label: "Google Analytics 3", icon: "google-analytics" },
  [ValueCategoryT.GoogleAnalytics4T]: { label: "Google Analytics 4", icon: "google-analytics" },
  [ValueCategoryT.CombinedT]: { label: "Combined", icon: "" },
};

export const MetricDimensionItemSelect = ({
  disabledIds,
  isDisabledNotConnectedWarning,
  onSetSelectedCategory,
  onSetSelectedItem,
  onSetValuesCount,
  recentDimensions: recentDimensionIds,
  recentMetrics: recentMetricIds,
  searchValue,
  selectedCategory,
  type,
  values,
  withTabs,
}: MetricDimensionCategoriesT) => {
  const recentIds = type === "metric" ? recentMetricIds : recentDimensionIds;
  const recentItems = recentIds
    ?.map((recentId) => values.find((value) => value.id === recentId))
    .filter((item) => item !== undefined) as SelectableDimensionT[];

  const groupedValues = useMemo(
    () => groupBy(values, "category") as { [key in ValueCategoryT]: SelectableDimensionT[] },
    [values]
  );

  const filteredItems = useMemo(
    () =>
      searchValue
        ? values.filter((value) => normalizeSearchText(value.name).includes(normalizeSearchText(searchValue)))
        : selectedCategory?.name
        ? groupedValues[selectedCategory.name]
        : null,
    [groupedValues, searchValue, selectedCategory?.name, values]
  );

  useEffect(() => {
    if (filteredItems && searchValue && onSetValuesCount) {
      onSetValuesCount(filteredItems.length);
    }
  }, [filteredItems, searchValue, onSetValuesCount]);

  if (!filteredItems?.length && searchValue) {
    return <InfoBox className="mv-16">You have filtred too much</InfoBox>;
  }

  if (!groupedValues || !values.length) {
    return <div>no data</div>;
  }

  if ((!selectedCategory?.name || !groupedValues[selectedCategory.name]) && !searchValue) {
    const favoriteItems = values.filter((item) => item.favorite);
    return (
      <div className="mt-8">
        {(!!favoriteItems.length || !!recentItems.length) && (
          <Text color="gray" size="xs" spaced uppercase>
            {!!recentItems.length ? "Recently Used" : "Favorite items"}
          </Text>
        )}
        <Row style={{ maxWidth: "288px" }} flexwrap>
          {(!!recentItems.length ? recentItems : favoriteItems).map((item) => (
            <MetricDimensionPill
              key={item.id}
              isDisabled={(disabledIds || []).includes(item.id)}
              isDisabledNotConnectedWarning={isDisabledNotConnectedWarning}
              item={item}
              onClick={() => onSetSelectedItem({ type, label: item.name, value: item.id })}
            />
          ))}
        </Row>
        <div
          className="Preview Preview--delimeter Preview--smallSpaced mv-8 negative-mh-16"
          style={favoriteItems.length === 0 ? { borderTop: "none" } : {}}
        >
          {(Object.keys(groupedValues) as (keyof typeof groupedValues)[]).map((group) => {
            return (
              <div
                key={group}
                className="Preview-item Preview-item--topBorder Preview-item--bold Preview-item--link ph-8"
                data-test-id={`metDim-category--${group}`}
                onClick={() => onSetSelectedCategory({ name: group, type })}
              >
                <Row className="ph-8">
                  {CATEGORY_PROPS[group].icon && (
                    <Col>
                      <Icon color="#8C969F" kind={CATEGORY_PROPS[group].icon} size="14px" />
                    </Col>
                  )}
                  <Col type="grow">
                    <Text color="gray" bold>
                      {CATEGORY_PROPS[group].label}
                    </Text>
                  </Col>
                  <Col>
                    <Icon kind="chevron-right" />
                  </Col>
                </Row>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return (
    <>
      {!searchValue && (
        <Link
          className="d-flex align-items-center cursor-pointer delimiter pv-16 ph-16 mb-16 negative-mh-16 background-white border-top-left-radius-8 border-top-right-radius-8 z-index-1"
          style={{ position: "sticky", top: withTabs ? "49px" : 0 }}
          noUnderline
          onClick={() => onSetSelectedCategory(null)}
        >
          <Icon kind="chevron-left" />
          <span className="ml-16">{selectedCategory?.name ? CATEGORY_PROPS[selectedCategory.name].label : ""}</span>
        </Link>
      )}
      {filteredItems?.map((item) => (
        <MetricDimensionPill
          key={item.id}
          isDisabled={(disabledIds || []).includes(item.id)}
          isDisabledNotConnectedWarning={isDisabledNotConnectedWarning}
          item={item}
          onClick={() => onSetSelectedItem({ type, label: item.name, value: item.id })}
        />
      ))}
    </>
  );
};
