import React, { KeyboardEvent, useCallback, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm, useFormState } from "react-hook-form";
import { normalizeDateRange } from "../../functions/normalize";
import {
  ConditionT,
  DateRangeT,
  ValueCountT,
  useReportDimensionValueCountsLazyQueryT,
} from "../../graphql/generated/graphql";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { ActionBar } from "../../ui/ActionBar/ActionBar";
import { ButtonPrimary, ButtonTertiary } from "../../ui/Button/Button";
import { MetricOrDimensionT } from "../../ui/forms/MetricDimensionController";
import { GridOverflow, Row } from "../../ui/grid/Grid";
import { useConfirmationContext } from "../confirmationDialog/confirmationDialogContext";
import { useReportBoardContext } from "../reportBoard/context/reportBoardContext";
import { WidgetSettingsDataT } from "../reportBoard/context/types";
import { FormConditionsList, getAreConditionsEmpty, getDefaultConditions } from "./FormConditionsList";
import { formConditionsSchema } from "./validations";

export type ConditionsT = {
  groupId: string;
  id: string;
  operator: string;
  type: "metric" | "dimension" | "";
  unit: { label?: string; type?: MetricOrDimensionT; value?: string };
  value: string[];
}[];

export type ConditionsFormT = {
  conditions: ConditionsT;
};

type PropsT = {
  conditions: Omit<ConditionT, "conditionable">[];
  dateRange: DateRangeT | undefined | null;
  onChange?: (values: ConditionsFormT) => void;
  onClose?: () => void;
  onSubmit: (values: ConditionsFormT) => void;
  widgetSettingsData: WidgetSettingsDataT;
};

export type DimensionsValueCountsT = { [key in string]: { isLoading: boolean; valuesCount?: ValueCountT[] } };

export const FormConditions = ({ conditions, dateRange, onChange, onClose, onSubmit, widgetSettingsData }: PropsT) => {
  const { report } = useReportBoardContext();
  const { organization } = useAppSettings();
  const [getDimensionValueCounts] = useReportDimensionValueCountsLazyQueryT();
  const dimensionsValueCounts: DimensionsValueCountsT = useMemo(() => ({}), []);
  const { callWithConfirmation } = useConfirmationContext();

  const handleGetValueCounts = useCallback(
    (dimensionId: string) => {
      dimensionsValueCounts[dimensionId] = { isLoading: !dimensionsValueCounts[dimensionId] };
      {
        getDimensionValueCounts({
          fetchPolicy: "cache-and-network",
          onCompleted(result) {
            dimensionsValueCounts[dimensionId] = {
              isLoading: false,
              valuesCount: result.organization?.report?.dimensionValueCounts,
            };
          },
          variables: {
            dimensionId,
            reportId: report.id,
            externalId: organization.externalId,
            dateRange: dateRange ? normalizeDateRange(dateRange) : null,
          },
        });
      }
    },
    [dimensionsValueCounts, getDimensionValueCounts, organization.externalId, report.id, dateRange]
  );

  const selectOptionsUnit = [
    ...widgetSettingsData.metrics.map((metric) => ({
      label: metric.name,
      value: metric.id,
      type: "metric" as MetricOrDimensionT,
    })),
    ...widgetSettingsData.dimensions.map((dimension) => ({
      label: dimension.name,
      value: dimension.id,
      type: "dimension" as MetricOrDimensionT,
    })),
  ];

  const defaultValues = {
    conditions: getDefaultConditions({ conditions, selectOptionsUnit }),
  };

  const [isFormEmpty, setIsFormEmpty] = useState(getAreConditionsEmpty(defaultValues));

  const {
    control,
    formState: { isDirty },
    handleSubmit: useFormHandleSubmit,
    reset,
    setValue,
    trigger,
    watch,
  } = useForm<ConditionsFormT>({
    defaultValues,
    resolver: yupResolver(formConditionsSchema),
  });

  const { errors } = useFormState({ control });

  const fields = useFieldArray({
    control,
    name: "conditions",
  });

  const handleSubmit = useCallback(
    (values: ConditionsFormT) => {
      if (isFormEmpty) {
        onSubmit({ ...values, conditions: [] });
      } else {
        onSubmit(values);
      }
    },
    [isFormEmpty, onSubmit]
  );

  const checkKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const resetWithConfirmation = () => {
    callWithConfirmation(
      () => {
        reset();
        if (onClose) {
          onClose();
        }
      },
      {
        description: "This action returns you to the state before editing",
        title: "Reset changes",
        testId: "reset-filters",
        confirmButtonProps: { text: "Reset", variant: "red" },
      }
    );
  };

  return (
    <>
      <Row className="pos-relative negative-ml-16 negative-mr-16" type="grow">
        <GridOverflow>
          <form
            className="w-100 mt-16 ph-8"
            id="form-conditions"
            onKeyDown={checkKeyDown}
            onSubmit={useFormHandleSubmit(handleSubmit)}
          >
            <FormConditionsList
              {...fields}
              conditions={watch("conditions")}
              control={control}
              dimensions={widgetSettingsData.dimensions}
              dimensionsValueCounts={dimensionsValueCounts}
              errors={errors}
              metrics={widgetSettingsData.metrics}
              setValue={setValue}
              style={{ paddingBottom: "80px" }}
              trigger={trigger}
              watch={watch}
              onChange={onChange}
              onGetValueCounts={handleGetValueCounts}
              onSetIsConditionEmpty={setIsFormEmpty}
            />
          </form>
        </GridOverflow>
      </Row>

      <ActionBar hasAbsolutePosition hasMoreBottomPadding>
        <ButtonPrimary data-test-id="btn-use-filter" disabled={!isDirty} onClick={useFormHandleSubmit(handleSubmit)}>
          Use filter
        </ButtonPrimary>
        <ButtonTertiary onClick={isDirty ? resetWithConfirmation : onClose}>Cancel</ButtonTertiary>
      </ActionBar>
    </>
  );
};
