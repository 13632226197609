import React, { CSSProperties, PropsWithChildren, ReactNode } from "react";
import { RootSidebarMenuItems } from "./RootSidebarMenuItems";
import { SidebarHidden } from "./SidebarHidden";
import { SidebarMenu } from "./SidebarMenu";
import { SidebarMenuCollectionT } from "./SidebarMenuComponent";
import { Subheader } from "./Subheader";

export type LayoutPropsT = PropsWithChildren<{
  backTo?: string | -1;
  hideSidebar?: boolean;
  isCentered?: boolean;
  kind?: "formLayout";
  sidebarCollection?: SidebarMenuCollectionT;
  subheaderContent?: ReactNode;
  title?: ReactNode;
}>;

/* To show something in top bar menu use: 
  <MainMenu>
    <Link to={ROUTES.userSystemActions()}>
      <ButtonTertiary className="Navbar-toolbox" icon="history" onlyIcon />
    </Link>
  </MainMenu> */

export const Layout = ({
  backTo,
  children,
  hideSidebar,
  isCentered,
  kind,
  sidebarCollection,
  subheaderContent,
  title,
}: LayoutPropsT) => {
  const centeredStyles = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "calc(100vh - 170px)",
  } as CSSProperties;
  const formLayoutStyles = { maxWidth: "800px", minWidth: "600px" };

  return (
    <>
      <RootSidebarMenuItems />
      {hideSidebar && <SidebarHidden />}
      {sidebarCollection?.length && <SidebarMenu collection={sidebarCollection} />}
      {(backTo || subheaderContent || title) && (
        <Subheader backTo={backTo} title={title}>
          {subheaderContent}
        </Subheader>
      )}

      <div
        style={{
          ...(!!isCentered ? centeredStyles : {}),
          ...(kind === "formLayout" ? formLayoutStyles : {}),
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div>{children}</div>
      </div>
    </>
  );
};
