import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { InputController } from "../../ui/forms/InputController";
import { formSectionSchema } from "./validations";

export type FormSectionInputsT = {
  description?: string;
  name?: string;
};

export type FormSectionPropsT = {
  defaultValues?: Partial<FormSectionInputsT>;
  formId: string;
  onSubmit: (values: FormSectionInputsT) => void;
};

export const FormSection = ({ defaultValues, formId, onSubmit }: FormSectionPropsT) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormSectionInputsT>({
    resolver: yupResolver(formSectionSchema),
  });

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <InputController
        control={control}
        defaultValue={defaultValues?.name || ""}
        errors={errors}
        id="title"
        label="Title"
        name="name"
        input={{
          autoFocus: true,
        }}
      />

      <InputController
        control={control}
        defaultValue={defaultValues?.description || ""}
        errors={errors}
        id="description"
        label="Description"
        name="description"
        textarea={{}}
      />
    </form>
  );
};
