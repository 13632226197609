import { cloneDeep } from "lodash";
import { v4 as uuid } from "uuid";
import { DUPLICATED_PREFIX } from "../../../constants/report";
import { ReportBoardSectionsT, SectionT, WidgetT } from "../../../types/widgets";

export const shouldUseCopyPrefix = (text?: string) => {
  const isAlreadyDuplication = text?.startsWith(DUPLICATED_PREFIX);
  const isUsingDynamicName = text === "";

  return !isAlreadyDuplication && !isUsingDynamicName;
};

export const compact = (obj: Record<string, unknown>) => {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
};

export const sortByPosition = <T extends { position: number }>(a: T, b: T) => (a.position || 0) - (b.position || 0);

export const clearItemId = <T extends { id: string }>(item: T) => ({ ...cloneDeep(item), id: uuid() });

export const cleanWidget = (widget: WidgetT) => ({
  ...widget,
  id: uuid(),
  conditions: widget.conditions.map(clearItemId),
  widgetDimensions: widget.widgetDimensions.map(clearItemId),
  widgetMetrics: widget.widgetMetrics.map(clearItemId),
});

export const sortSections = (sections: SectionT[]): SectionT[] =>
  sections.length
    ? [...sections].sort(sortByPosition).map((section) => ({
        ...section,
        widgets: { ...section.widgets, nodes: [...(section.widgets.nodes || [])].sort(sortByPosition) },
      }))
    : [];

export const countSectionsAndWidgets = (sections: ReportBoardSectionsT) =>
  sections.reduce(
    (acc, section) => {
      acc.sectionCount += 1;
      acc.widgetCount += section.widgets.nodes.length;
      return acc;
    },
    { sectionCount: 0, widgetCount: 0 }
  );
