import React from "react";
import classNames from "classnames";
import {
  BiddingChangeT,
  BudgetChangeT,
  CampaignT,
  StatusChangeT,
  UserCampaignActionsQueryResultT,
} from "../../graphql/generated/graphql";
import { SystemIcon } from "../../ui/SystemIcon/SystemIcon";
import { TBody, THead, Table, Td, Th, TrBody, TrHeader } from "../../ui/Table/Table";
import TimeAgo from "../../ui/TimeAgo/TimeAgo";
import { UserCampaignActionState } from "../userCampaignActionState/UserCampaignActionState";
import {
  CampaignBidding,
  CampaignBudget,
  CampaignStatus,
} from "../widgetComponents/TableOptimatizationCell/TableOptimatizationCell";

type CustomCampaignT = Pick<CampaignT, "id" | "budget" | "system" | "name" | "currency"> | null;

export type UserActionTableBiddingChangeT = Pick<
  BiddingChangeT,
  "id" | "createdAt" | "__typename" | "user" | "state"
> & {
  campaign?: CustomCampaignT;
  newBiddingSchema?: string | null;
  newBiddingValue?: number | null;
  previousBiddingSchema?: string | null;
  previousBiddingValue?: number | null;
};

export type UserActionTableBudgetChangeT = Pick<BudgetChangeT, "id" | "createdAt" | "__typename" | "user" | "state"> & {
  campaign?: CustomCampaignT;
  newBudgetValue?: number | null;
  previousBudgetValue?: number | null;
  previousValue?: number | null;
};

export type UserActionTableStatusChangeT = Pick<
  StatusChangeT,
  "id" | "createdAt" | "__typename" | "previousStatus" | "user" | "newStatus" | "state"
> & {
  campaign?: CustomCampaignT;
};

export type UserSystemActionsTablePropsT = {
  isCompact?: boolean;
  isElevate?: boolean;
  onRefetch?: UserCampaignActionsQueryResultT["refetch"];
  userCampaignActions?: Array<
    UserActionTableBiddingChangeT | UserActionTableBudgetChangeT | UserActionTableStatusChangeT
  >;
};

export const UserSystemActionsTable = ({
  isCompact,
  isElevate,
  onRefetch,
  userCampaignActions,
}: UserSystemActionsTablePropsT) => {
  return (
    <Table
      className={classNames("text-regular", { "elevate-3": isElevate })}
      style={{ ...(isCompact && { fontSize: "10px", maxHeight: "300px" }) }}
      isWithSmallHeader
    >
      <THead>
        <TrHeader>
          <Th>date</Th>
          <Th>system</Th>
          {!isCompact && <Th>campaign</Th>}
          <Th>previous state</Th>
          <Th>wanted state</Th>
          {!isCompact && <Th>actions</Th>}
          <Th>user</Th>
        </TrHeader>
      </THead>
      <TBody>
        {userCampaignActions?.map((campaignAction) => (
          <TrBody key={campaignAction.id}>
            <Td>
              <TimeAgo time={campaignAction.createdAt} />
            </Td>
            <Td className="text-center">
              {campaignAction.campaign?.system ? <SystemIcon system={campaignAction.campaign.system} /> : ""}
            </Td>

            {!isCompact && <Td>{campaignAction.campaign?.name}</Td>}

            <Td>
              {campaignAction.__typename === "StatusChange" && campaignAction.previousStatus && (
                <CampaignStatus status={campaignAction.previousStatus} />
              )}
              {campaignAction.__typename === "BiddingChange" && (
                <CampaignBidding
                  biddingSchema={campaignAction.previousBiddingSchema}
                  currency={campaignAction.campaign?.currency}
                  targetCpa={campaignAction.previousBiddingValue}
                  targetRoas={campaignAction.previousBiddingValue}
                />
              )}
              {campaignAction.__typename === "BudgetChange" && (
                <CampaignBudget
                  budget={campaignAction.previousBudgetValue}
                  budgetKind={campaignAction.campaign?.budget?.kind}
                  budgetShared={campaignAction.campaign?.budget?.shared}
                  currency={campaignAction.campaign?.currency}
                />
              )}
            </Td>
            <Td>
              {campaignAction.__typename === "StatusChange" && <CampaignStatus status={campaignAction.newStatus} />}
              {campaignAction.__typename === "BiddingChange" && (
                <CampaignBidding
                  biddingSchema={campaignAction.newBiddingSchema}
                  currency={campaignAction.campaign?.currency}
                  targetCpa={campaignAction.newBiddingValue}
                  targetRoas={campaignAction.newBiddingValue}
                />
              )}
              {campaignAction.__typename === "BudgetChange" && (
                <CampaignBudget
                  budget={campaignAction.newBudgetValue}
                  budgetKind={campaignAction.campaign?.budget?.kind}
                  budgetShared={campaignAction.campaign?.budget?.shared}
                  currency={campaignAction.campaign?.currency}
                />
              )}
            </Td>
            {!isCompact && (
              <Td>
                {campaignAction && (
                  <UserCampaignActionState userCampaignAction={campaignAction} onRefetch={onRefetch} />
                )}
              </Td>
            )}
            <Td>
              <div>{campaignAction.user?.fullname}</div>
              <div>{campaignAction.user?.email}</div>
            </Td>
          </TrBody>
        ))}
      </TBody>
    </Table>
  );
};
