import React from "react";
import { HashLink } from "react-router-hash-link";
import { ROUTES } from "../../constants/routes";
import { formatDate } from "../../functions/dateHelpers";
import { KpiSettingT, KpiStatusT, MetricT, ResultT, TargetT } from "../../graphql/generated/graphql";
import { formatDateRange } from "../../i18n/formatDate";
import { formatMetricValue } from "../../i18n/formatNumbers";
import { ButtonPrimary, ButtonSecondary } from "../../ui/Button/Button";
import { DropdownMenu } from "../../ui/Dropdown/DropdownMenu";
import { Ellipsis } from "../../ui/Ellipsis/Ellipsis";
import { Col, Row } from "../../ui/grid/Grid";
import { HeadingSection } from "../../ui/Heading/Heading";
import { Icon } from "../../ui/Icon/Icon";
import { Link } from "../../ui/Link/Link";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { KpiPriority } from "../KpiPriority/KpiPriority";
import { KpiProgressChart } from "../kpiProgressChart/KpiProgressChart";
import { KpiSemaphore } from "../kpiSemaphore/KpiSemaphore";
import { KpiScoreValue } from "../organizationsKpiList/OrganizationKpiListItem";

export type KpiResultT = Pick<
  ResultT,
  | "semaphore"
  | "currentValue"
  | "estimatedProgress"
  | "targetValue"
  | "currency"
  | "startOn"
  | "endOn"
  | "estimatedPeriodValue"
  | "estimatedProgressChange"
  | "daysRemaining"
>;

export type KpiListItemPropsT = {
  isExpandedView?: boolean;
  kpiSetting: Pick<
    KpiSettingT,
    | "id"
    | "name"
    | "priority"
    | "currency"
    | "progressThresholdLow"
    | "progressThresholdOk"
    | "startOn"
    | "endOn"
    | "previousResult"
    | "status"
  > & {
    lastResult?: KpiResultT | null;
    metric: Pick<MetricT, "id" | "dataType" | "predictionKind" | "predictionKind" | "positiveTrend">;
    nextTarget?: Pick<TargetT, "currency" | "value"> | null;
    targets?: Array<Pick<TargetT, "currency" | "value">> | null;
  };
  onDeleteKpiSettings: (kpiSettingIds: string[]) => void;
  orgLink: string;
};

export type ItemMenuPropsT = Pick<KpiListItemPropsT, "onDeleteKpiSettings"> &
  Pick<KpiSettingT, "name" | "id"> & { orgLink?: string };

const ItemMenu = ({ id, name, onDeleteKpiSettings, orgLink }: ItemMenuPropsT) => {
  return (
    <DropdownMenu
      testId={`kpi-item-menu-${name}`}
      collection={[
        {
          value: "Edit",
          icon: "edit-value",
          to: `${orgLink}${ROUTES.kpiEdit(id)}`,
          isRealHref: !!orgLink,
        },
        {
          value: "Duplicate",
          icon: "duplicate",
          to: `${orgLink}${ROUTES.kpiDuplicate(id)}`,
          isRealHref: !!orgLink,
        },
        {
          value: "Delete",
          icon: "trash",
          onClick: () => onDeleteKpiSettings([id]),
        },
      ]}
    />
  );
};

export const KpiListItem = ({ isExpandedView, kpiSetting, onDeleteKpiSettings, orgLink }: KpiListItemPropsT) => {
  const {
    endOn,
    id,
    lastResult,
    metric,
    name,
    nextTarget,
    previousResult,
    priority,
    progressThresholdLow,
    progressThresholdOk,
    startOn,
    status,
    targets,
  } = kpiSetting;
  const isDraft = status === KpiStatusT.DraftT;
  const isFinished = status === KpiStatusT.FinishedT;
  const isScheduled = status === KpiStatusT.ScheduledT;

  const rederPeriod = () => {
    if (isDraft) {
      return "Draft";
    }
    if (isFinished) {
      return `Ended: ${formatDate(endOn)}`;
    }
    if (isScheduled) {
      return `Starting: ${formatDate(startOn)}`;
    }
    return `Period: ${formatDateRange(lastResult?.startOn, lastResult?.endOn)}`;
  };

  return (
    <Tile className="mb-8">
      <Row alignItems="center" padding="n" style={{ gap: "5%" }}>
        <Col type="grow">
          <div>
            <Link
              icon={isDraft ? "edit-value" : undefined}
              isRealHref={!!orgLink}
              to={`${orgLink}${ROUTES.kpiDetail(kpiSetting.id)}`}
            >
              <HeadingSection className="Heading--inheritColor d-inline">
                <Ellipsis>{name}</Ellipsis>
              </HeadingSection>
            </Link>
          </div>
          <Text color="gray">{rederPeriod()}</Text>
        </Col>
        {!isDraft && !isFinished && !isScheduled && (
          <>
            <Col width="15em">
              <KpiSemaphore {...kpiSetting} result={kpiSetting.lastResult} />
            </Col>
            <Col width="7em">
              <Text color="softGray">Current Value</Text>
              <Text className="mt-4" bold>
                {formatMetricValue({ metric, value: lastResult?.currentValue, currency: lastResult?.currency })}
              </Text>
            </Col>
            <Col width="13em">
              <Text color="softGray">Target {lastResult && <small>({lastResult.daysRemaining} days left)</small>}</Text>
              <Text className="mt-4" bold>
                {formatMetricValue({ metric, value: lastResult?.targetValue, currency: lastResult?.currency })}{" "}
                {!!orgLink ? (
                  <Link href={`${orgLink}${ROUTES.kpiEdit(kpiSetting.id)}#targets`}>
                    <Icon className="ml-8" kind="edit-value" />
                  </Link>
                ) : (
                  <HashLink to={{ pathname: ROUTES.kpiEdit(kpiSetting.id), hash: "targets" }}>
                    <Icon className="ml-8" kind="edit-value" />
                  </HashLink>
                )}
              </Text>
            </Col>
            <Col width="11em">
              <Text color="softGray">Estimated End Value</Text>
              <Text className="mt-4" bold>
                {formatMetricValue({
                  metric,
                  value: lastResult?.estimatedPeriodValue,
                  currency: lastResult?.currency,
                })}
              </Text>
            </Col>
            <Col>
              <ItemMenu id={id} name={name} orgLink={orgLink} onDeleteKpiSettings={onDeleteKpiSettings} />
            </Col>
          </>
        )}

        {isScheduled && (
          <>
            <Col width="19em">
              <Text color="gray">Estimated First Period Target</Text>
              <Text className="mt-4" bold>
                {formatMetricValue({
                  metric,
                  value: targets?.[0]?.value,
                  currency: targets?.[0]?.currency,
                })}
              </Text>
            </Col>
            <Col>
              <ItemMenu id={id} name={name} orgLink={orgLink} onDeleteKpiSettings={onDeleteKpiSettings} />
            </Col>
          </>
        )}

        {isDraft && (
          <Col>
            <div>
              <ButtonPrimary to={ROUTES.kpiEdit(id)}>Edit draft</ButtonPrimary>
              <ButtonSecondary icon="trash" onlyIcon onClick={() => onDeleteKpiSettings([id])} />
            </div>
          </Col>
        )}

        {isFinished && (
          <>
            <Col width="160px">
              <Text>
                <KpiPriority priority={priority} />
              </Text>
            </Col>
            <Col width="130px">
              <Text color="softGray">Last period</Text>
              <Text className="mt-4" bold>
                {formatDateRange(lastResult?.startOn, lastResult?.endOn)}
              </Text>
            </Col>
            <Col width="170px">
              <Text color="softGray">Last period result</Text>
              <Row alignItems="center" className="mt-4">
                <KpiSemaphore {...kpiSetting} className="mr-8" result={lastResult} size="md" hideBadge />
                <Text bold>
                  {formatMetricValue({
                    metric,
                    value: lastResult?.currentValue,
                    currency: lastResult?.currency,
                  })}{" "}
                  of{" "}
                  {formatMetricValue({
                    metric,
                    value: lastResult?.targetValue,
                    currency: lastResult?.currency,
                  })}
                </Text>
              </Row>
            </Col>
            <Col width="80px">
              <Text color="softGray">Final score</Text>
              <KpiScoreValue score={kpiSetting.lastResult?.estimatedProgress} />
            </Col>
            <Col>
              <ItemMenu id={id} name={name} orgLink={orgLink} onDeleteKpiSettings={onDeleteKpiSettings} />
            </Col>
          </>
        )}
      </Row>

      {isExpandedView && (
        <Row className="mt-16 pt-16 border-top border-color-soft-gray">
          <Col>
            <KpiProgressChart
              currency={kpiSetting.currency}
              currentValue={lastResult?.currentValue}
              dataType={metric.dataType}
              estimatedPeriodValue={lastResult?.estimatedPeriodValue}
              positiveTrend={metric.positiveTrend}
              predictionKind={metric.predictionKind}
              progressThresholdLow={progressThresholdLow}
              progressThresholdOk={progressThresholdOk}
              targetValue={lastResult?.targetValue}
            />
          </Col>
          <Col type="grow" />
          <Col width="22em">
            {!isFinished && (
              <>
                <Text color="softGray">
                  Last Period ({formatDateRange(previousResult?.startOn, previousResult?.endOn)})
                </Text>
                <Row alignItems="center" className="flex-gap-8 mt-4">
                  <KpiSemaphore {...kpiSetting} result={previousResult} size="md" />
                  <Text bold>
                    {formatMetricValue({
                      metric,
                      value: previousResult?.currentValue,
                      currency: previousResult?.currency,
                    })}
                  </Text>
                  of
                  <Text bold>
                    {formatMetricValue({
                      metric,
                      value: previousResult?.targetValue,
                      currency: previousResult?.currency,
                    })}
                  </Text>
                </Row>
              </>
            )}
          </Col>
          <Col width="13em">
            {!isFinished && (
              <>
                <Text color="softGray">Period Compare</Text>
                <Text className="mt-4" bold>
                  <KpiScoreValue score={lastResult?.estimatedProgressChange} hasIndicator />
                </Text>
              </>
            )}
          </Col>
          <Col width="13em">
            {!isFinished && (
              <>
                <Text color="softGray">Next Period Target</Text>
                <Text className="mt-4" bold>
                  {formatMetricValue({ metric, value: nextTarget?.value, currency: nextTarget?.currency })}
                </Text>
              </>
            )}
          </Col>
        </Row>
      )}
    </Tile>
  );
};
