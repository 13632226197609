import React from "react";
import { SystemNameT } from "../../graphql/generated/graphql";
import { Icon } from "../Icon/Icon";
import { SystemIcon } from "../SystemIcon/SystemIcon";

type PropsT = {
  allIconKind?: "adsystems" | "analytics";
  icon: SystemNameT | "all" | "custom";
};

export const MetricDimensionItemIcon = ({ allIconKind = "analytics", icon }: PropsT) => {
  if (icon === "custom") {
    return <Icon kind="user" />;
  }

  if (icon === SystemNameT.AnyT || icon === "all") {
    return <Icon kind={`all-${allIconKind}`} />;
  }

  return <SystemIcon className="Icon pl-0" system={icon} />;
};
