import React, { memo } from "react";
import { Cell, Pie, PieChart } from "recharts";
import { Loader } from "../Loader/Loader";
import { Tooltip } from "../Tooltip/Tooltip";
import { COLOR_KIND, DEFAULT_LINE_SPACING, DEFAULT_LINE_WIDTH, DEFAULT_SIZE } from "./constants";
import { getData, getTranslationText } from "./functions";

export type UsedByGraphPropsT = {
  adSets?: number;
  adSetsTotal?: number;
  disableTooltip?: boolean;
  doNotShowPercent?: boolean;
  kind?: keyof typeof COLOR_KIND;
  loading?: boolean;
  products?: number;
  productsTotal?: number;
  size?: number;
};

export const UsedByGraph = memo(
  ({
    adSets,
    adSetsTotal,
    disableTooltip,
    kind = "color",
    loading,
    products,
    productsTotal,
    size = DEFAULT_SIZE,
  }: UsedByGraphPropsT) => {
    const productsData = getData({ name: "products", value: products || 0, total: productsTotal || 0 });
    const adSetsData = getData({ name: "adSets", value: adSets || 0, total: adSetsTotal || 0 });
    const lineWidth = (size / DEFAULT_SIZE) * DEFAULT_LINE_WIDTH;
    const lineSpacing = (size / DEFAULT_SIZE) * DEFAULT_LINE_SPACING;

    const tooltipText = disableTooltip ? null : getTranslationText({ adSets, adSetsTotal, products, productsTotal });
    return (
      <Tooltip data-test-id="graph-tooltip" tooltipContent={tooltipText}>
        <div>
          {loading ? (
            <Loader className="pl-4" size="small" />
          ) : (
            <PieChart height={size} width={size}>
              {(!productsTotal || !adSetsTotal) && (
                <Pie
                  data={[{ value: 100, fill: "#eee" }]}
                  dataKey="value"
                  endAngle={-270}
                  innerRadius={size / 2 - lineWidth * 2 - lineSpacing}
                  isAnimationActive={false}
                  outerRadius={size / 2 - lineWidth - lineSpacing}
                  paddingAngle={0}
                  startAngle={90}
                  stroke="none"
                />
              )}

              {productsTotal && productsTotal >= 0 && (
                <Pie
                  data={productsData}
                  dataKey="value"
                  endAngle={-270}
                  fill="#82ca9d"
                  innerRadius={size / 2 - lineWidth * 2 - lineSpacing}
                  isAnimationActive={false}
                  outerRadius={size / 2 - lineWidth - lineSpacing}
                  paddingAngle={0}
                  startAngle={90}
                  stroke="none"
                >
                  {productsData.map((entry) => (
                    <Cell key={`cell-${entry.name}`} fill={COLOR_KIND[kind][entry.name]} />
                  ))}
                </Pie>
              )}
              {adSetsTotal && adSetsTotal >= 0 && (
                <Pie
                  data={adSetsData}
                  dataKey="value"
                  endAngle={-270}
                  fill="#82ca9d"
                  innerRadius={size / 2 - lineWidth}
                  isAnimationActive={false}
                  outerRadius={size / 2}
                  paddingAngle={0}
                  startAngle={90}
                  stroke="none"
                >
                  {adSetsData.map((entry) => (
                    <Cell key={`cell-${entry.name}`} fill={COLOR_KIND[kind][entry.name]} />
                  ))}
                </Pie>
              )}
            </PieChart>
          )}
        </div>
      </Tooltip>
    );
  }
);
