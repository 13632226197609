import React, { PropsWithChildren } from "react";
import { ErrorBoundary as EB, ErrorBoundaryPropsWithComponent, withErrorBoundary as wEB } from "react-error-boundary";
import { airbrakeNotify } from "../../airbrake";
import { ErrorFallback, ErrorFallbackWithoutTryAgain } from "./ErrorFallback";

export const ErrorBoundary = ({
  children,
  ...rest
}: PropsWithChildren<Omit<ErrorBoundaryPropsWithComponent, "FallbackComponent">>) => {
  return (
    <EB
      {...rest}
      FallbackComponent={typeof rest?.onReset === "function" ? ErrorFallback : ErrorFallbackWithoutTryAgain}
      onError={airbrakeNotify}
      onReset={rest.onReset}
    >
      {children}
    </EB>
  );
};

export const withErrorBoundary = (Comp: React.ComponentType) =>
  wEB(Comp, {
    FallbackComponent: ErrorFallback,
  });
