import React, { HTMLProps, ReactElement } from "react";
import classNames from "classnames";
import { FieldError } from "react-hook-form";
import FormGroup from "./FormGroup";

type InputT = HTMLProps<HTMLTextAreaElement>;

type PropsT = {
  className?: string;
  error?: FieldError;
  label?: string | number | ReactElement;
  testId?: string;
  textarea: InputT;
};

const Textarea = ({ className, error, label, testId, textarea }: PropsT) => {
  return (
    <FormGroup className={classNames(className, "Input")} error={error} id={textarea.id} label={label} testId={testId}>
      <textarea {...textarea} />
    </FormGroup>
  );
};

export default Textarea;
