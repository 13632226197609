import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { cloneDeep, isEqual, omit } from "lodash";
import { v4 as uuid } from "uuid";
import { createHashMap } from "../../../functions/calculateFieldCounts";
import { getConditionsApiInputFormat, getIsConditionsEqual } from "../../../functions/conditions";
import { normalizeDateRange } from "../../../functions/normalize";
import { buildNewSection } from "../../../functions/sectionHelper";
import { getIsRequiredAnySystem } from "../../../functions/sourceSystemsHelper";
import { getUsedMetDims } from "../../../functions/widgetHelpers";
import { CurrencyT, ReportUpdateMutationVariablesT } from "../../../graphql/generated/graphql";
import { usePrevious } from "../../../hooks/usePrevious";
import { useUpdateReport } from "../../../hooks/useUpdateReport";
import { cleanWidget, clearItemId, sortSections } from "./functions";
import { reportReducer } from "./reportReducer";
import {
  DimensionsMetricsHydrateFromApiT,
  PageAddT,
  PageDeleteT,
  PageDuplicateT,
  PageUpdateT,
  ReportApiUpdateT,
  ReportBoardActionsT,
  ReportBoardPageT,
  ReportBoardReducerStateT,
  ReportContextActionsT,
  ReportFilterUpdateT,
  ReportOptionsT,
  ReportResetT,
  StateReportT,
} from "./types";

type AdditionalContextPropsT = {
  loading?: boolean;
  reportOptions: ReportOptionsT;
};

type ContextReturnStateT = ReportBoardReducerStateT & AdditionalContextPropsT & ReportContextActionsT;

const ReportBoardContext = createContext<null | ContextReturnStateT>(null);

type PropsT = PropsWithChildren<
  Pick<ReportBoardReducerStateT, "report"> & Pick<AdditionalContextPropsT, "reportOptions">
>;

const removeTypename =
  (fields: string[] = []) =>
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  (object: any) => {
    const newObject = omit(object, ["__typename"]);

    return fields.reduce((subObject, field) => {
      if (subObject[field]) {
        subObject[field] = removeTypename()(subObject[field]);
      }
      return subObject;
    }, newObject);
  };

const getPagesData = (pages: StateReportT["pages"]) =>
  pages.nodes.map((page) => ({
    ...page,
    sections: sortSections(page.sections.nodes),
  }));

export const ReporBoardProvider = ({ children, report: initialReport, reportOptions: initialOptions }: PropsT) => {
  const [state, dispatch] = useReducer(reportReducer, {
    report: initialReport,
    reportFilter: {
      conditions: initialReport.conditions,
      dateRange: initialReport.dateRange,
      currency: initialReport.currency || CurrencyT.OriginalT,
    },
    isReportFilterOpen: false,
    hasReportUnsavedChanges: false,
    fetchingNewConnectedSystemsData: false,
    lastFetchedConnectedSystemsData: null,
    pagesData: getPagesData(initialReport.pages),
    wantedSectionPosition: 0,
    widgetSettingsData: {
      dimensions: initialReport.dimensions.nodes,
      dimensionsById: createHashMap(initialReport.dimensions.nodes, "id"),
      metrics: initialReport.metrics.nodes,
      metricsById: createHashMap(initialReport.metrics.nodes, "id"),
      connectedSystems: initialReport.sourceSystems,
    },
    selectedMetDims: { metrics: [], dimensions: [] },
  });

  const prevInitialReport = usePrevious(initialReport);

  // Keep fresh partial report data after refetch
  useEffect(() => {
    if (!isEqual(prevInitialReport, initialReport)) {
      dispatch({ type: ReportBoardActionsT.SOURCE_SYSTEMS_UPDATE, sourceSystems: [...initialReport.sourceSystems] });
      dispatch({
        type: ReportBoardActionsT.UPDATE,
        data: {
          widgetSettingsData: {
            dimensions: initialReport.dimensions.nodes,
            dimensionsById: createHashMap(initialReport.dimensions.nodes, "id"),
            metrics: initialReport.metrics.nodes,
            metricsById: createHashMap(initialReport.metrics.nodes, "id"),
            connectedSystems: initialReport.sourceSystems,
          },
          ...(!state.hasReportUnsavedChanges ? { pagesData: getPagesData(initialReport.pages) } : {}),
        },
      });
    }
  }, [initialReport, prevInitialReport, state.hasReportUnsavedChanges]);

  const handleReportUnsavedChanges = (hasReportUnsavedChanges: boolean) => {
    dispatch({ type: ReportBoardActionsT.UPDATE, data: { hasReportUnsavedChanges } });
  };

  const pagesHydrateFromApi = (pagesData: ReportBoardPageT[]) => {
    dispatch({ type: ReportBoardActionsT.UPDATE, data: { pagesData } });
  };

  const dimensionsMetricsHydrateFromApi: DimensionsMetricsHydrateFromApiT = ({ dimensions, metrics }) => {
    if (dimensions) {
      const dimensionsById = createHashMap(dimensions, "id");
      dispatch({
        type: ReportBoardActionsT.UPDATE,
        data: { widgetSettingsData: { ...state.widgetSettingsData, dimensions, dimensionsById } },
      });
      return;
    }

    if (metrics) {
      const metricsById = createHashMap(metrics, "id");
      dispatch({
        type: ReportBoardActionsT.UPDATE,
        data: { widgetSettingsData: { ...state.widgetSettingsData, metrics, metricsById } },
      });
    }
  };

  const { loading, updateReport: updateApiReport } = useUpdateReport({
    successMessage: "Report has been saved",
    sucessCallback(updatedReport) {
      pagesHydrateFromApi(getPagesData(updatedReport.pages));
    },
  });

  // Keep updated used metrics and dimensions
  // TODO - test it
  useEffect(() => {
    const usedMetDims = getUsedMetDims({ sectionsData: state.pagesData.flatMap((page) => page.sections) });

    if (!isEqual(usedMetDims, state.selectedMetDims)) {
      dispatch({
        type: ReportBoardActionsT.UPDATE,
        data: { selectedMetDims: usedMetDims },
      });
    }
  }, [state.pagesData, state.selectedMetDims]);

  const reportOptions = useMemo(
    () => ({
      ...initialOptions,
      fakeDataStatus: getIsRequiredAnySystem({
        metrics: initialReport.metrics.nodes.filter((metric) => state.selectedMetDims.metrics.includes(metric.id)),
        dimensions: initialReport.dimensions.nodes.filter((dimension) =>
          state.selectedMetDims.dimensions.includes(dimension.id)
        ),
        connectedSystems: initialReport.sourceSystems,
      }),
    }),
    [
      initialOptions,
      initialReport.metrics,
      initialReport.dimensions,
      initialReport.sourceSystems,
      state.selectedMetDims,
    ]
  );

  useEffect(() => {
    handleReportUnsavedChanges(
      !isEqual(
        state.pagesData.map((page) => ({ ...page, sections: sortSections(page.sections), shared: null })),
        initialReport.pages.nodes.map((page) => ({
          ...page,
          sections: sortSections(page.sections.nodes),
          shared: null,
        }))
      ) ||
        !isEqual(
          normalizeDateRange(state?.reportFilter?.dateRange || {}),
          normalizeDateRange(initialReport?.dateRange || {})
        ) ||
        !getIsConditionsEqual(initialReport.conditions, state.reportFilter.conditions) ||
        !isEqual(initialReport.currency, state.reportFilter.currency)
    );
  }, [
    initialReport.pages.nodes,
    state.pagesData,
    state.reportFilter,
    initialReport.conditions,
    initialReport.dateRange,
    initialReport.currency,
  ]);

  const reportFilterUpdate: ReportFilterUpdateT = (reportFilterValues) => {
    dispatch({ type: ReportBoardActionsT.REPORT_FILTER_UPDATE, data: { reportFilter: reportFilterValues } });
  };

  const reportFilterOpen = () => {
    dispatch({ type: ReportBoardActionsT.UPDATE, data: { isReportFilterOpen: true } });
  };

  const reportFilterClose = () => {
    dispatch({ type: ReportBoardActionsT.UPDATE, data: { isReportFilterOpen: false } });
  };

  const setFetchingNewConnectedSystemsData = useCallback(
    (value: boolean) => {
      dispatch({ type: ReportBoardActionsT.UPDATE, data: { fetchingNewConnectedSystemsData: value } });
    },
    [dispatch]
  );

  const setLastFetchedConnectedSystemsData = useCallback(
    (value: Date) => {
      dispatch({ type: ReportBoardActionsT.UPDATE, data: { lastFetchedConnectedSystemsData: value } });
    },
    [dispatch]
  );

  const reportApiUpdate: ReportApiUpdateT = () => {
    const { pagesData, report, reportFilter } = state;
    const reportForUpdate = {
      dateRange: normalizeDateRange(reportFilter.dateRange || {}),
      conditions: getConditionsApiInputFormat(reportFilter.conditions || []),
      reportId: report.id,
      currency: reportFilter.currency,
      pages: pagesData.map((page) => ({
        ...omit(page, ["isNew", "sections", "__typename", "shared"]),
        id: page.isNew ? null : page.id,
      })),
      sections: pagesData.flatMap((page) =>
        page.sections.map((section) => ({
          id: section.isNew ? null : section.id,
          pageUuid: section.pageUuid,
          name: section.name,
          dateRange: section?.dateRange ? normalizeDateRange(section.dateRange) : undefined,
          description: section.description,
          position: section.position,
          conditions: getConditionsApiInputFormat(section.conditions),
          widgets: section.widgets.nodes.map((widget) => {
            return {
              ...omit(widget, ["isNew", "hasDefaultData", "sourceSystems", "__typename"]),
              widgetDimensions: widget.widgetDimensions
                .filter((dimension) => dimension.dimensionId)
                .map(removeTypename()),
              widgetMetrics: widget.widgetMetrics
                .filter((metric) => metric.metricId)
                .map(removeTypename(["dateRange"])),
              id: widget.isNew ? null : widget.id,
              dateRange: widget?.dateRange ? normalizeDateRange(widget.dateRange) : undefined,
              position: widget.position || 0,
              properties: widget.properties,
              conditions: getConditionsApiInputFormat(widget.conditions),
              dateGrouping: widget.dateGrouping,
              compare: widget.compare,
              currency: widget.currency || null,
              maxRows: Number(widget.maxRows) || null,
              sourceSystems: widget.sourceSystems.map((system) => ({
                name: system.name,
                externalId: system.externalId,
              })),
            };
          }),
        }))
      ),
    } as ReportUpdateMutationVariablesT;

    updateApiReport(reportForUpdate);
  };

  const pageAdd: PageAddT = useCallback((name, { callback }) => {
    const pageUuid = uuid();
    const newPage = {
      name,
      id: uuid(),
      uuid: pageUuid,
      sections: [buildNewSection({ id: uuid(), pageUuid, name: "New section" })],
    };
    dispatch({ type: ReportBoardActionsT.PAGE_ADD, newPage });
    if (callback) {
      callback(newPage);
    }
  }, []);

  const pageDuplicate: PageDuplicateT = useCallback(
    ({ newName, pageId }, { callback }) => {
      const originalPage = cloneDeep(state.pagesData.find((page) => page.id === pageId));
      const pageUuid = uuid();

      if (originalPage) {
        const newPage = {
          ...originalPage,
          name: newName,
          id: uuid(),
          uuid: pageUuid,
          sections: originalPage.sections.map((section) => ({
            ...cloneDeep(section),
            id: uuid(),
            pageUuid,
            isNew: true,
            conditions: section.conditions.map(clearItemId),
            widgets: {
              nodes: section.widgets.nodes.map((widget) => ({
                ...cloneDeep(cleanWidget(widget)),
                isNew: true,
                id: uuid(),
              })),
            },
          })),
        };

        dispatch({ type: ReportBoardActionsT.PAGE_ADD, newPage });
        if (callback) {
          callback(newPage);
        }
      }
    },
    [state.pagesData]
  );

  const pageDelete: PageDeleteT = useCallback((pageId) => {
    dispatch({ type: ReportBoardActionsT.PAGE_DELETE, pageId });
  }, []);

  const pageUpdate: PageUpdateT = useCallback(({ pageId, pageInputData }) => {
    dispatch({ type: ReportBoardActionsT.PAGE_UPDATE, pageId, pageInputData });
  }, []);

  const reportReset: ReportResetT = useCallback(() => {
    dispatch({
      type: ReportBoardActionsT.UPDATE,
      data: {
        pagesData: getPagesData(initialReport.pages),
        reportFilter: {
          conditions: initialReport.conditions,
          dateRange: initialReport.dateRange,
          currency: initialReport.currency || CurrencyT.OriginalT,
        },
      },
    });
  }, [initialReport]);

  const actions = {
    dimensionsMetricsHydrateFromApi,
    pageAdd,
    pageDelete,
    pageDuplicate,
    pageUpdate,
    reportApiUpdate,
    reportFilterClose,
    reportFilterOpen,
    reportFilterUpdate,
    setFetchingNewConnectedSystemsData,
    setLastFetchedConnectedSystemsData,
    reportReset,
  };

  return (
    <ReportBoardContext.Provider
      value={{
        ...state,
        ...actions,
        loading,
        reportOptions,
      }}
    >
      {children}
    </ReportBoardContext.Provider>
  );
};

export const useReportBoardContext = () => {
  const context = useContext(ReportBoardContext);
  if (!context) {
    throw new Error("ReportBoard compound components cannot be rendered outside the main ReportBoard component");
  }
  return context;
};
