import { useToggleClassOnMount } from "../../hooks/useToggleClassOnMount";
import { useAppSettings } from "../../providers/appSettingsProvider";

export const SidebarHidden = () => {
  const {
    layoutSelectors: { sidebarWrapper },
  } = useAppSettings();

  useToggleClassOnMount(sidebarWrapper, "d-none");

  return null;
};
