/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { MouseEvent, useCallback, useState } from "react";
import cs from "classnames";
import { startOfDay } from "date-fns";
import { Calendar } from "react-date-range";
import { FieldPath, FieldPathValue, FieldValues, UseFormSetValue } from "react-hook-form";
import { DropdownFloatingBox } from "../Dropdown/DropdownFloatingBox";
import { useDropdown } from "../Dropdown/useDropdown";
import { InputController, InputControllerPropsT } from "./InputController";

export type PropsT<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<
  InputControllerPropsT<TFieldValues, TName>,
  "defaultValue" | "collection" | "radioOptions" | "select" | "textarea" | "smartSelect" | "autoCompleteOptions" | "id"
> & {
  className?: string;
  defaultValue?: string;
  disableClear?: boolean;
  isInputMinified?: boolean;
  maxDate?: Date | "today";
  minDate?: Date | "today";
  name: TName;
  onSetValue: UseFormSetValue<TFieldValues>;
};

export const DEFAULT_DATE = undefined;

export const DateController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  defaultValue,
  disableClear,
  input,
  isInputMinified,
  isLoading,
  label,
  maxDate,
  minDate,
  name,
  onSetValue,
  ...rest
}: PropsT<TFieldValues, TName>) => {
  const { boxProps, reference, triggerElementProps } = useDropdown();

  const [date, setDate] = useState<Date | undefined>(defaultValue ? new Date(defaultValue) : undefined);

  const handleSetInputValue = useCallback(
    (newDate: string) => {
      onSetValue(name, (newDate || "") as FieldPathValue<TFieldValues, TName>, { shouldValidate: true });
    },
    [name, onSetValue]
  );

  const handleShowPicker = () => {
    boxProps.onToggle(true);
  };

  const handleClosePicker = useCallback(() => {
    boxProps.onToggle(false);
  }, [boxProps]);

  const handleClear = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setDate(undefined);
      handleSetInputValue("");
    },
    [handleSetInputValue, setDate]
  );

  const handleChange = useCallback(
    (newDate: Date) => {
      handleSetInputValue(newDate.toISOString());
      setDate(newDate);
      handleClosePicker();
    },
    [handleSetInputValue, handleClosePicker]
  );

  return (
    <div className={cs("DateRange", { "pointer-events-none no-user-select": isLoading })}>
      <span {...(!input?.disabled ? triggerElementProps : {})} ref={reference}>
        <InputController
          {...rest}
          control={control}
          defaultValue={(defaultValue || "") as FieldPathValue<TFieldValues, TName>}
          isLoading={isLoading}
          label={label}
          name={name}
          input={{
            ...input,
            tabIndex: 0,
            type: "text",
            readOnly: true,
            isInputContentOpen: boxProps.isOpen,
            onKeyDownCapture: (event) => {
              if (event.key === "ArrowDown" && !boxProps.isOpen) {
                handleShowPicker();
                event.preventDefault();
              }
            },
            isClearable: !disableClear && !!date,
            icon: "calendar",
            minified: isInputMinified,
          }}
          isDate
          onClear={handleClear}
        />
      </span>
      <DropdownFloatingBox {...boxProps} lockScroll={true}>
        <div className="DateRange" style={{ outline: "none" }} tabIndex={0}>
          <div className="DateRange-container">
            <Calendar
              date={date}
              direction="horizontal"
              maxDate={maxDate === "today" ? startOfDay(new Date()) : maxDate || undefined}
              minDate={minDate === "today" ? startOfDay(new Date()) : minDate || undefined}
              showDateDisplay={false}
              weekStartsOn={1}
              showPreview
              onChange={(item) => {
                handleChange(item);
              }}
            />
          </div>
        </div>
      </DropdownFloatingBox>
    </div>
  );
};
