import React, { useCallback, useMemo, useState } from "react";
import { eachDayOfInterval, format, sub } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import { CURRENCY_OPTIONS } from "../components/formEditWidget/inputs/InputCurrency";
import { ROUTES } from "../constants/routes";
import { notifyError, notifySucces, notifyWarning } from "../functions/toast";
import {
  CurrencyT,
  MetricDataT,
  useCustomValueMetricWithValuesQuery,
  useUpdateMetricValuesMutation,
} from "../graphql/generated/graphql";
import { useAppSettings } from "../providers/appSettingsProvider";
import { RouteParamsT } from "../types/router";
import { ButtonPrimary } from "../ui/Button/Button";
import ErrorMessage from "../ui/forms/ErrorMessage";
import Input from "../ui/forms/Input";
import Select from "../ui/forms/Select";
import { Col, Row } from "../ui/grid/Grid";
import { Loader } from "../ui/Loader/Loader";
import { Modal, ModalBody, ModalFooter } from "../ui/Modal";
import ModalCloseButton from "../ui/Modal/ModalCloseButton";

type StateT = {
  [key: string]: {
    currency?: CurrencyT | string;
    date?: Date | string;
    id?: string;
    value: string;
  };
};

const isInvalidValue = (value?: string | number): boolean => {
  if (value === "") {
    return false;
  }
  if (value) {
    return isNaN(parseFloat(value.toString())) || !!value.toString().match(/[^\d,\.\s]/);
  }
  return true;
};

const InputError = () => <ErrorMessage error={{ message: "number is required" }} />;

export const ValueMetricValuesModal = ({ onClose, valueMetricId }: { onClose: () => void; valueMetricId?: string }) => {
  const {
    organization: { externalId },
  } = useAppSettings();

  const dateFrom = sub(new Date(), { days: 62 });
  const dateTo = new Date();
  const [state, setState] = useState<StateT>({});
  const [ranges] = useState(eachDayOfInterval({ start: dateFrom, end: dateTo }).reverse());

  const { data, refetch } = useCustomValueMetricWithValuesQuery({
    fetchPolicy: "network-only",
    variables: {
      organizationExternalId: externalId,
      customValueMetricId: valueMetricId || "",
      dateFrom: format(dateFrom, "yyyy-MM-dd"),
      dateTo: format(dateTo, "yyyy-MM-dd"),
    },
    skip: !valueMetricId,
  });

  const [mutate, { loading: mutationLoading }] = useUpdateMetricValuesMutation({
    onCompleted: (res) => {
      const errors = res.organization?.updateMetricValues?.errors;

      if (errors?.length) {
        return notifyError(<>{errors.join("\n")}</>);
      }

      const stateWithErrors = Object.keys(state).reduce(
        (acc, date) => (isInvalidValue(state[date].value) ? { ...acc, [date]: state[date] } : acc),
        {}
      );
      setState(stateWithErrors); // clear state
      if (Object.keys(stateWithErrors).length === 0) {
        notifySucces(<>{`Values updated!`}</>);
      } else {
        notifyWarning(<>{`Some values could not been updated due to error!`}</>);
      }
      refetch();
      onClose();
    },
    onError: () => notifyError(<>{`Server error when updating metric values!`}</>),
  });

  const existingData = useMemo(
    () =>
      data?.organization?.customValueMetric?.metricValues?.reduce((acc, item) => ({ ...acc, [item.date]: item }), {}) ||
      {},
    [data?.organization?.customValueMetric?.metricValues]
  ) as StateT;

  const handleSave = useCallback(() => {
    if (valueMetricId) {
      const newValues = Object.values(state)
        .map((item) => ({
          ...item,
          value: item.value === "" ? null : parseFloat(item.value.toString().replace(/\s+/, "")),
        }))
        .filter(
          (item) =>
            (item.date && item.value === null && !!existingData[item.date as string]) ||
            (item.value && !isInvalidValue(item.value))
        );

      if (newValues.length > 0) {
        mutate({
          variables: {
            organizationExternalId: externalId,
            metricId: valueMetricId,
            metricValueChanges: newValues,
          },
        });
      } else {
        return notifyError(<>{"All values to update has errors"}</>);
      }
    }
  }, [mutate, externalId, valueMetricId, state, existingData]);

  const isFormDirty = useMemo(
    () =>
      Object.keys(state).reduce((acc, key) => {
        const item = state[key];

        if (existingData?.[key]) {
          return (
            acc ||
            `${item.value}` !== `${existingData[key].value}` ||
            (!!item.currency && item.currency !== existingData[key].currency)
          );
        }
        return acc || item.value !== "";
      }, false),
    [existingData, state]
  );

  if (!data) {
    return <Loader />;
  }

  return (
    <Modal
      close={onClose}
      heading={`Values for metric ${data.organization?.customValueMetric?.name}`}
      testId="values-for-metric-modal"
      isOpen
    >
      <ModalBody>
        {ranges.map((range) => {
          const date = format(range, "yyyy-MM-dd") as string;
          const hasError = !!state[date] && isInvalidValue(state[date].value);

          return (
            <Row key={date} alignItems="top" justify="center">
              <Col className="text-right pt-8" width="130px">
                {range.toDateString()}
              </Col>
              <Col width="200px">
                <Input
                  inputProps={{
                    value:
                      state[date]?.value || state[date]?.value === ""
                        ? state[date]?.value
                        : existingData[date]?.value || "",
                    onChange: ({ currentTarget: { value } }) =>
                      setState((prev) => {
                        return {
                          ...prev,
                          [date]: { ...(prev[date] || {}), id: existingData[date]?.id, value, date },
                        };
                      }),
                  }}
                />
                {hasError && <InputError />}
              </Col>
              {data.organization?.customValueMetric?.dataType === MetricDataT.MoneyT && (
                <Col type="shrink">
                  <Select
                    collection={CURRENCY_OPTIONS.filter(
                      (item) => item.value !== CurrencyT.OriginalT && item.value !== ""
                    )}
                    selectProps={{
                      disabled:
                        (!state[date] && !existingData[date]) || (!state[date]?.value && !existingData[date]?.value),
                      value:
                        state[date]?.currency ||
                        existingData[date]?.currency ||
                        data.organization?.customValueMetric?.defaultCurrency ||
                        "",
                      onChange: ({ currentTarget: { value } }) =>
                        setState((prev) => ({
                          ...prev,
                          [date]: {
                            ...(prev[date] || {}),
                            value: prev[date]?.value || existingData[date]?.value || "",
                            id: existingData[date]?.id,
                            currency: value,
                          },
                        })),
                    }}
                  />
                </Col>
              )}
            </Row>
          );
        })}
      </ModalBody>
      <ModalFooter>
        <ButtonPrimary
          disabled={mutationLoading || Object.keys(state).length === 0 || !isFormDirty}
          loading={mutationLoading}
          onClick={handleSave}
        >
          Save
        </ButtonPrimary>
        <ModalCloseButton />
      </ModalFooter>
    </Modal>
  );
};

export const ValueMetricValuesPage = () => {
  const { valueMetricId } = useParams<RouteParamsT["valueMetric"]>();
  const navigate = useNavigate();

  return (
    <ValueMetricValuesModal valueMetricId={valueMetricId} onClose={() => navigate(ROUTES.metricsAndDimensions())} />
  );
};
