import React, { Fragment, KeyboardEvent, PropsWithChildren, TransitionEvent, useEffect, useRef, useState } from "react";
import cs from "classnames";
import FocusLock from "react-focus-lock";
import { SystemNameT } from "../../graphql/generated/graphql";
import { useScrollBlock } from "../../hooks/useScrollBlock";
import ModalContent from "./ModalContent";
import ModalContentFullWithGrid from "./ModalContentFullWithGrid";
import { useModalContext } from "./modalContext";

type SizesT = "small" | "medium" | "full" | "fullwithGrid" | "rightSide";
export type ModalComponentPropsT = {
  className?: string;
  hasHeaderBorder?: boolean;
  heading?: string | React.ReactNode;
  icon?: string;
  onKeyDown: (e: KeyboardEvent) => void;
  onTransitionEnd: (e: TransitionEvent) => void;
  size?: SizesT;
  systemIcon?: SystemNameT | null;
  testId: string;
  zIndex?: "normal" | "high" | "extra";
};

const ModalComponent = ({
  children,
  className,
  hasHeaderBorder = false,
  heading,
  icon,
  onKeyDown,
  onTransitionEnd,
  size = "medium",
  systemIcon,
  testId,
  zIndex = "normal",
}: PropsWithChildren<ModalComponentPropsT>) => {
  const modalSize = `Modal--${size}`;
  const [isTransitionPossible, setIsTransitionPossible] = useState(false);

  const { close, isClosing, isOpen } = useModalContext();
  useScrollBlock();

  useEffect(() => {
    const timeoutId = setTimeout(() => setIsTransitionPossible(true));
    return () => clearTimeout(timeoutId);
  }, []);

  const modalContentRef = useRef<HTMLDivElement>(null);

  const handleModalAppearance = () => {
    const modalElem = modalContentRef.current;
    const modalFooterElem = modalContentRef.current?.querySelector(".Modal-footer") as HTMLElement | null;
    const modalHeaderElem = modalContentRef.current?.querySelector(".Modal-header");
    const modalBodyElem = modalContentRef.current?.querySelector(".Modal-body") as HTMLElement | null;
    const modalContentElem = modalContentRef.current?.querySelector(".Modal-content") as HTMLElement | null;

    if (modalHeaderElem) {
      if (modalHeaderElem.getBoundingClientRect().y === 0) {
        modalHeaderElem.classList.add("Modal-header--sticky");
      } else {
        modalHeaderElem.classList.remove("Modal-header--sticky");
      }
    }

    if (modalElem && modalFooterElem && modalContentElem && modalBodyElem) {
      if (
        modalElem.offsetHeight - modalBodyElem.offsetHeight - modalFooterElem.offsetHeight ===
        modalBodyElem.getBoundingClientRect().y
      ) {
        return modalFooterElem.classList.remove("Modal-footer--sticky");
      }

      if (modalContentElem.offsetHeight > modalElem.offsetHeight) {
        modalFooterElem.classList.add("Modal-footer--sticky");
      }
    }
  };

  useEffect(() => {
    const currentModalContentElem = modalContentRef.current;
    handleModalAppearance();
    currentModalContentElem?.addEventListener("scroll", handleModalAppearance);
    return () => {
      currentModalContentElem?.removeEventListener("scroll", handleModalAppearance);
    };
  }, []);

  const isClassicModal = size !== "fullwithGrid" && size !== "rightSide";

  return (
    <Fragment>
      <div
        className={cs("modal-backdrop fade", {
          in: !isClosing && isOpen && isTransitionPossible,
          "modal-backdrop--zIndex-high": zIndex === "high",
          "modal-backdrop--zIndex-extra": zIndex === "extra",
        })}
      />
      <FocusLock>
        <div
          ref={modalContentRef}
          data-test-id={testId}
          role="dialog"
          style={{ display: "block", overflowX: "hidden", overflowY: "auto" }}
          tabIndex={1}
          className={cs("Modal fade h-100", modalSize, className, {
            in: !isClosing && isOpen && isTransitionPossible,
            "Modal--zIndex-high": zIndex === "high",
            "Modal--zIndex-extra": zIndex === "extra",
          })}
          aria-modal
          onClick={close}
          onKeyDown={onKeyDown}
          onTransitionEnd={onTransitionEnd}
        >
          <div
            className={cs("Modal-dialog", { "pb-32": isClassicModal })}
            role="document"
            onClick={(event) => event.stopPropagation()}
          >
            {isClassicModal && (
              <ModalContent
                children={children}
                hasHeaderBorder={hasHeaderBorder}
                heading={heading}
                icon={icon}
                systemIcon={systemIcon}
              />
            )}
            {!isClassicModal && (
              <ModalContentFullWithGrid
                children={children}
                hasHeaderBorder={hasHeaderBorder}
                heading={heading}
                icon={icon}
                systemIcon={systemIcon}
              />
            )}
          </div>
        </div>
      </FocusLock>
    </Fragment>
  );
};

export default ModalComponent;
