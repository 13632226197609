import React, { FocusEvent, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { CustomMetricOperationT, MetricT, useOrganizationMetricsQuery } from "../../graphql/generated/graphql";
import { useHandleServerErrors } from "../../hooks/useHandleServerErrors";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { ButtonPrimary } from "../../ui/Button/Button";
import FormGroup from "../../ui/forms/FormGroup";
import { InputController } from "../../ui/forms/InputController";
import { Col, Row } from "../../ui/grid/Grid";
import { HeadingSmall } from "../../ui/Heading/Heading";
import { Loader } from "../../ui/Loader/Loader";
import { ModalBody, ModalFooter } from "../../ui/Modal";
import ModalCloseButton from "../../ui/Modal/ModalCloseButton";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { MetricSelect } from "../formEditWidget/inputs/InputWidgetMetricsItem";
import { ExampleOperation } from "./ExampleOperation";
import { formCustomMetricSchema } from "./validations";

export const OPERATORS = {
  [CustomMetricOperationT.DivT]: "/",
  [CustomMetricOperationT.RatioT]: "/",
  [CustomMetricOperationT.AddT]: "+",
  [CustomMetricOperationT.MulT]: "*",
  [CustomMetricOperationT.SubT]: "-",
};
const SELECT_OPERATIONS = [
  { label: "Divide (/)", value: CustomMetricOperationT.DivT },
  { label: "Ratio (/ in %)", value: CustomMetricOperationT.RatioT },
  { label: "Add (+)", value: CustomMetricOperationT.AddT },
  { label: "Multiply (*)", value: CustomMetricOperationT.MulT },
  { label: "Subtract (-)", value: CustomMetricOperationT.SubT },
];
export type FormCustomMetricInputsT = {
  description: string;
  leftMetricId: string;
  name: string;
  operation: CustomMetricOperationT;
  rightMetricId: string;
};

export type FormCustomMetricPropsT = {
  defaultValues?: Pick<MetricT, "name" | "description" | "operation" | "leftMetricId" | "rightMetricId"> | null;
  isMutationLoading?: boolean;
  onSubmit: (values: FormCustomMetricInputsT) => void;
  serverErrors?: string[];
};

export const FormCustomMetric = ({
  defaultValues,
  isMutationLoading,
  onSubmit,
  serverErrors,
}: FormCustomMetricPropsT) => {
  const {
    organization: { externalId },
  } = useAppSettings();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
    watch,
  } = useForm<FormCustomMetricInputsT>({
    resolver: yupResolver(formCustomMetricSchema),
    defaultValues: {
      name: defaultValues?.name || "",
      description: defaultValues?.description || "",
      operation: defaultValues?.operation || CustomMetricOperationT.DivT,
      leftMetricId: defaultValues?.leftMetricId || "",
      rightMetricId: defaultValues?.rightMetricId || "",
    },
  });

  const [metricName, setMetricName] = useState("");

  const { data, loading: metricsLoading } = useOrganizationMetricsQuery({
    variables: {
      organizationExternalId: externalId,
      limit: 100000,
    },
  });

  const metricsById = (data?.organization?.metrics?.nodes || []).reduce(
    (acc, dim) => ({ ...acc, [dim.id]: dim }),
    {}
  ) as {
    [id: MetricT["id"]]: MetricT;
  };
  const [operation, leftMetricId, rightMetricId] = watch(["operation", "leftMetricId", "rightMetricId"]);

  const handleBlur = (event: FocusEvent<HTMLInputElement, Element>) => setMetricName(event.currentTarget.value);

  useHandleServerErrors({ errors: serverErrors, setError, shouldNotify: true });

  return (
    <form
      id="new-metric"
      onSubmit={(event) => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
    >
      <ModalBody>
        <Tile className="mt-8">
          <div className="w-50 mb-8">
            <InputController
              control={control}
              defaultValue={defaultValues?.name || ""}
              errors={errors}
              id="name"
              input={{ type: "text", placeholder: "Insert name", autoFocus: true, onBlur: handleBlur }}
              label="Metric name"
              name="name"
            />
          </div>
          <InputController
            className="mb-8"
            control={control}
            errors={errors}
            id="description"
            label="Description"
            name="description"
            textarea={{ type: "text", placeholder: "Insert Description (Optional)" }}
          />

          <div className="delimiter mv-16" />
          <HeadingSmall margin={0}>Equation</HeadingSmall>
          <Text className="mb-16" color="gray" tag="p">
            Below, select the metrics and the mathematical function between them to calculate the new metric value.
          </Text>

          {metricsLoading ? (
            <Loader />
          ) : (
            <Row wrap>
              <Col type="grow">
                <Controller
                  control={control}
                  name="leftMetricId"
                  render={({ field, fieldState }) => (
                    <FormGroup error={fieldState.error} label="First Metric">
                      <MetricSelect
                        id="leftMetricId"
                        metrics={data?.organization?.metrics?.nodes || []}
                        placeholder="Select first metric"
                        value={field.value}
                        hasArrow
                        isDisabledNotConnectedWarning
                        onChange={(metricId) => setValue("leftMetricId", metricId || "")}
                      />
                    </FormGroup>
                  )}
                />
              </Col>
              <Col style={{ minWidth: "130px" }} type="shrink">
                <InputController
                  collection={SELECT_OPERATIONS}
                  control={control}
                  id="operation"
                  label="Operator"
                  name="operation"
                />
              </Col>
              <Col type="grow">
                <Controller
                  control={control}
                  name="rightMetricId"
                  render={({ field, fieldState }) => (
                    <FormGroup error={fieldState.error} label="Second Metric">
                      <MetricSelect
                        id="rightMetricId"
                        metrics={data?.organization?.metrics?.nodes || []}
                        placeholder="Select second metric"
                        value={field.value}
                        hasArrow
                        isDisabledNotConnectedWarning
                        onChange={(metricId) => setValue("rightMetricId", metricId || "")}
                      />
                    </FormGroup>
                  )}
                />
              </Col>
            </Row>
          )}
          <Tile className="mt-16" isSmallSpaced>
            <Text className="mb-8" tag="p" bold>
              Example
            </Text>
            <ExampleOperation
              leftMetric={metricsById[leftMetricId]}
              newMetricName={metricName}
              operation={operation}
              rightMetric={metricsById[rightMetricId]}
            />
          </Tile>
        </Tile>
      </ModalBody>
      <ModalFooter>
        <ButtonPrimary disabled={isMutationLoading} form="new-metric" loading={isMutationLoading} type="submit">
          Save
        </ButtonPrimary>
        <ModalCloseButton />
      </ModalFooter>
    </form>
  );
};
