import { useAliasMetricsQuery } from "../../graphql/generated/graphql";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { AliasTable } from "./AliasTable";

export const AliasMetricsConnected = () => {
  const { organization } = useAppSettings();
  const result = useAliasMetricsQuery({ variables: { organizationExternalId: organization.externalId } });

  return <AliasTable {...result} heading="Ad Systems Metrics" headingLevel="h2" type="metrics" />;
};
