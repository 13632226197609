import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ButtonPrimary } from "../../ui/Button/Button";
import { InputController } from "../../ui/forms/InputController";
import { formReportEditSchema } from "./validations";

export type FormReportEditInputsT = {
  description?: string;
  name: string;
};

export type PropsT = {
  defaultValues?: Partial<FormReportEditInputsT>;
  onSubmit: (values: FormReportEditInputsT) => void;
  submitLabel?: string;
  submitLoading?: boolean;
};

export const FormReportEdit = ({ defaultValues, onSubmit, submitLabel, submitLoading }: PropsT) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormReportEditInputsT>({
    resolver: yupResolver(formReportEditSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputController
        control={control}
        defaultValue={defaultValues?.name || ""}
        errors={errors}
        id="title"
        input={{ disabled: submitLoading }}
        label="Title"
        name="name"
      />

      <InputController
        control={control}
        defaultValue={defaultValues?.description || ""}
        errors={errors}
        id="desc"
        label="Description"
        name="description"
        textarea={{ disabled: submitLoading }}
      />

      <div className="mt-16">
        <ButtonPrimary disabled={submitLoading} loading={submitLoading}>
          {submitLabel || "Save changes"}
        </ButtonPrimary>
      </div>
    </form>
  );
};
