import React, { createContext, useContext, useState } from "react";
import {
  add,
  differenceInDays,
  eachMonthOfInterval,
  endOfDay,
  endOfMonth,
  getDaysInMonth,
  getMonth,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { KPI_PRIORITY_PROPS } from "../../constants/kpi";
import { ROUTES } from "../../constants/routes";
import { KpiPriorityT, KpiSettingT, KpiStatusT, MetricT } from "../../graphql/generated/graphql";
import { formatDate, formatDateRange } from "../../i18n/formatDate";
import { formatMetricValue } from "../../i18n/formatNumbers";
import { Button } from "../../ui/Button/Button";
import { Ellipsis } from "../../ui/Ellipsis/Ellipsis";
import Select from "../../ui/forms/Select";
import { Col, Row } from "../../ui/grid/Grid";
import { Link } from "../../ui/Link/Link";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { KpiListHeading } from "../kpiList/KpiListHeading";
import { KpiResultT } from "../kpiList/KpiListItem";
import { KpiSemaphore, KpiSemaphoreResultT } from "../kpiSemaphore/KpiSemaphore";
import { useSynchronizedScroll } from "./useSynchronizedScroll";

const diffInDays = (dateA: Date, dateB: Date) => differenceInDays(dateA, dateB) + 1;

const ROW_HEIGHT = "36px";
const HEADER_ROW_HEIGHT = "40px";
const PRIORITY_ROW_HEIGHT = "26px";
const ONE_DAY_WIDTH = 4;

export type KpiRoadmapContent = {
  oneDayWidth: number;
};
export const KpiRoadmapContext = createContext<KpiRoadmapContent>({
  oneDayWidth: ONE_DAY_WIDTH,
});
export const useKpiRoadmapContext = () => useContext(KpiRoadmapContext);

type KpiNamePropsT = Pick<KpiSettingT, "id" | "name" | "startOn" | "endOn"> & {
  lastResult?: KpiResultT | null;
  metric: Pick<MetricT, "id" | "dataType">;
  orgLink: string;
};
const KpiName = ({ endOn, id, lastResult, metric, name, orgLink, startOn }: KpiNamePropsT) => {
  return (
    <Row alignItems="center" className="pl-16" style={{ height: ROW_HEIGHT }}>
      <Col padding="n" type="grow">
        <Row>
          <Link className="mw-100" isRealHref={!!orgLink} to={`${orgLink}${ROUTES.kpiDetail(id)}`}>
            <Ellipsis>{name}</Ellipsis>
          </Link>
        </Row>
        <Row>
          {startOn && endOn && (
            <Text color="gray" size="xs">
              {formatDateRange(startOn, endOn)}
            </Text>
          )}
          {startOn && !endOn && (
            <Text color="gray" size="xs">
              Started from: {formatDateRange(startOn, startOn)}
            </Text>
          )}
        </Row>
      </Col>
      <Col className="text-right" width="80px">
        {typeof lastResult?.currentValue === "number"
          ? formatMetricValue({ metric, value: lastResult.currentValue, currency: lastResult?.currency })
          : ""}
      </Col>
      <Col className="text-right pr-8" width="80px">
        {typeof lastResult?.targetValue === "number"
          ? formatMetricValue({ metric, value: lastResult.targetValue, currency: lastResult?.currency })
          : ""}
      </Col>
    </Row>
  );
};

type KpiTodayResultPropsT = Pick<KpiSettingT, "priority" | "name"> & {
  lastResult?: KpiResultT | null;
  metric: Pick<MetricT, "id" | "dataType">;
};

const KpiTodayResult = ({ lastResult, ...rest }: KpiTodayResultPropsT) => {
  return (
    <Row style={{ height: ROW_HEIGHT }}>
      <Col alignItems="center" className="ph-8" justify="center">
        <Row>
          <KpiSemaphore {...rest} result={lastResult} />
        </Row>
      </Col>
    </Row>
  );
};

type KpiPriorityPropsT = {
  children: React.ReactNode;
  hiddenSections: string[];
  onToggleHiddenSection: (kpiPriority: string) => void;
  sectionName: KpiPriorityT | KpiStatusT;
  showName?: boolean;
};
const KpiPriority = ({ children, hiddenSections, onToggleHiddenSection, sectionName, showName }: KpiPriorityPropsT) => {
  const isHidden = hiddenSections.indexOf(sectionName) !== -1;

  return (
    <>
      {showName ? (
        <Row className="pl-8" style={{ height: PRIORITY_ROW_HEIGHT }}>
          <KpiListHeading hiddenSections={hiddenSections} sectionName={sectionName} onClick={onToggleHiddenSection} />
        </Row>
      ) : (
        <Row style={{ height: PRIORITY_ROW_HEIGHT }} />
      )}

      {!isHidden && children}
    </>
  );
};

const KpiHeaderMonth = ({ isFirst, month }: { isFirst?: boolean; month: Date }) => {
  const { oneDayWidth } = useKpiRoadmapContext();
  const isYearSplitter = getMonth(month) === 0 || isFirst;
  return (
    <Col
      className="pl-16"
      style={{ borderLeft: `${isYearSplitter ? 2 : 1}px solid #e5e7e9`, color: "#A1A1A1" }}
      width={`${getDaysInMonth(month) * oneDayWidth}px`}
    >
      <Row alignItems="bottom" className="pb-8" style={{ height: "100%" }}>
        <Text color="softGray">
          {formatDate(month, { month: "long", year: isYearSplitter ? "numeric" : undefined })}
        </Text>
      </Row>
    </Col>
  );
};

const KpiBodyMonthLines = ({ isFirst, month }: { isFirst?: boolean; month: Date }) => {
  const { oneDayWidth } = useKpiRoadmapContext();
  const isYearSplitter = getMonth(month) === 0 || isFirst;
  return (
    <Col
      className="pl-16"
      style={{ borderLeft: `${isYearSplitter ? 2 : 1}px solid #e5e7e9`, height: "100%" }}
      width={`${getDaysInMonth(month) * oneDayWidth}px`}
    ></Col>
  );
};

const KpiStartingFiller = ({ roadmapStartDate, startOn }: { roadmapStartDate: Date; startOn: string }) => {
  const { oneDayWidth } = useKpiRoadmapContext();
  const diffDays = diffInDays(endOfDay(new Date(startOn)), roadmapStartDate) - 1;
  if (diffDays <= 0) {
    return null;
  }
  return <Col width={`${diffDays * oneDayWidth}px`} />;
};

type KpiRoadmapResultPropsT = Pick<KpiSettingT, "priority" | "name"> & {
  isFirst?: boolean;
  isLast?: boolean;
  metric: Pick<MetricT, "id" | "dataType">;
  result: KpiSemaphoreResultT;
  roadmapEndDate: Date;
};

const KpiRoadmapResult = ({ isFirst, isLast, priority, result, roadmapEndDate, ...rest }: KpiRoadmapResultPropsT) => {
  const { endOn, startOn } = result;
  const { oneDayWidth } = useKpiRoadmapContext();
  const endDate = !!endOn ? new Date(endOn) : new Date();
  const isGoingOverRoadmap = endDate > roadmapEndDate;
  const boxEndDate = isGoingOverRoadmap ? roadmapEndDate : endDate;
  const diffDays = diffInDays(endOfDay(new Date(boxEndDate)), startOfDay(new Date(startOn)));

  const priorityProp = KPI_PRIORITY_PROPS[priority];
  const background = priorityProp.background;
  const borderLeft = isFirst ? "none" : `1px dashed ${priorityProp.border}`;

  return (
    <Col
      alignItems="center"
      justify="center"
      width={`${diffDays * oneDayWidth}px`}
      style={{
        background,
        borderLeft,
        height: "100%",
        ...(isFirst ? { borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", zIndex: 10 } : {}),
        ...(isLast ? { borderTopRightRadius: "20px", borderBottomRightRadius: "20px" } : {}),
      }}
    >
      <KpiSemaphore size="sm" {...rest} result={result} />
    </Col>
  );
};

type KpiRoadmapLinePropsT = Pick<KpiSettingT, "priority" | "name" | "startOn"> & {
  metric: Pick<MetricT, "id" | "dataType">;
  periodResults: KpiResultT[];
  roadmapEndDate: Date;
  roadmapStartDate: Date;
};

const KpiRoadmapLine = ({
  periodResults,
  roadmapEndDate,
  roadmapStartDate,
  startOn,
  ...rest
}: KpiRoadmapLinePropsT) => {
  const visiblePeriodResults = (
    (periodResults || []).filter((periodResult) => !!periodResult && new Date(periodResult.startOn) < roadmapEndDate) ||
    []
  ).sort((a, b) => new Date(a.startOn).getTime() - new Date(b.startOn).getTime());
  return (
    <Row padding="n" style={{ height: ROW_HEIGHT, zIndex: 1 }}>
      <KpiStartingFiller roadmapStartDate={roadmapStartDate} startOn={visiblePeriodResults[0]?.startOn || startOn} />
      {visiblePeriodResults.map((periodResult, index) => (
        <KpiRoadmapResult
          {...periodResult}
          {...rest}
          key={periodResult?.startOn}
          isFirst={index === 0}
          isLast={visiblePeriodResults.length - 1 === index}
          result={periodResult}
          roadmapEndDate={roadmapEndDate}
        />
      ))}
    </Row>
  );
};

const KpiToday = ({ roadmapStartDate }: { roadmapStartDate: Date }) => {
  const { oneDayWidth } = useKpiRoadmapContext();
  const daysForToday = diffInDays(endOfDay(new Date()), roadmapStartDate);
  const width = 4;
  return (
    <div
      className="pos-absolute"
      style={{
        left: `${daysForToday * oneDayWidth - width / 2}px`,
        top: "0px",
        bottom: "0px",
        width: `${width}px`,
        background: "#7140DF",
        zIndex: 11,
      }}
    />
  );
};

export type KpiRoadmapPropsT = {
  groupedBySectionsKpis: { [K in KpiPriorityT | KpiStatusT]: KpiSettingT[] };
  hiddenSections: string[];
  kpiSettingsWithPeriodResults?: Pick<KpiSettingT, "id" | "periodResults">[];
  onToggleHiddenSection: (kpiPriority: string) => void;
  orgLink: string;
};

export const KpiRoadmap = ({
  groupedBySectionsKpis,
  hiddenSections,
  kpiSettingsWithPeriodResults,
  onToggleHiddenSection,
  orgLink,
}: KpiRoadmapPropsT) => {
  const [oneDayWidth, setOneDayWidth] = useState<number>(ONE_DAY_WIDTH);

  const usedSections = Object.keys(groupedBySectionsKpis) as (keyof typeof groupedBySectionsKpis)[];

  const firstPeriodResulsStartDays = (kpiSettingsWithPeriodResults || [])
    .map((kpiSetting) => kpiSetting.periodResults?.[0]?.startOn)
    .filter((startOn) => !!startOn)
    .map((startOn) => new Date(startOn))
    .sort();

  const defaultStartDate = startOfDay(startOfMonth(add(new Date(), { days: -365 })));
  const startDate =
    firstPeriodResulsStartDays[0] && firstPeriodResulsStartDays[0] < defaultStartDate
      ? firstPeriodResulsStartDays[0]
      : defaultStartDate;
  const endDate = endOfMonth(add(new Date(), { days: 365 }));
  const daysToToday = diffInDays(endOfMonth(new Date()), startDate);

  const months = eachMonthOfInterval({
    start: startOfMonth(startDate),
    end: endOfMonth(endDate),
  });

  const { bodyRow, headerRow, resetToToday } = useSynchronizedScroll({ scrollRightPixels: daysToToday * oneDayWidth });

  return (
    <KpiRoadmapContext.Provider value={{ oneDayWidth }}>
      <Tile contentStyle={{ padding: 0 }}>
        <Row className="pos-relative" padding="n">
          <Col padding="n" width="349px">
            <Row
              alignItems="bottom"
              className="KpiRoadmap-rightShadow border-bottom border-gray border-color-soft-gray border-top-left-radius-8 background-white  pl-16 pb-8"
              type="shrink"
              style={{
                height: HEADER_ROW_HEIGHT,
                position: "sticky",
                top: "0px",
                zIndex: 12,
              }}
            >
              <Col type="grow">
                <Text color="softGray">Name</Text>
              </Col>
              <Col className="text-right" width="80px">
                <Text color="softGray">Current value</Text>
              </Col>
              <Col className="text-right pr-8" width="80px">
                <Text color="softGray">Target value</Text>
              </Col>
            </Row>
            <Row className="KpiRoadmap-rightShadow pt-8" type="shrink">
              <Col className="pb-32" type="grow">
                {usedSections.map((sectionName) => (
                  <KpiPriority
                    key={sectionName}
                    hiddenSections={hiddenSections}
                    sectionName={sectionName}
                    showName
                    onToggleHiddenSection={onToggleHiddenSection}
                  >
                    {groupedBySectionsKpis[sectionName].map((kpiSetting) => (
                      <KpiName key={kpiSetting.id} {...kpiSetting} orgLink={orgLink} />
                    ))}
                  </KpiPriority>
                ))}
              </Col>
            </Row>
          </Col>
          <Col padding="n" type="grow">
            <Row
              ref={headerRow}
              className="hide-scrollbar background-white"
              padding="n"
              type="shrink"
              style={{
                height: HEADER_ROW_HEIGHT,
                position: "sticky",
                top: "0px",
                overflowX: "auto",
                zIndex: 11,
              }}
            >
              {" "}
              <KpiToday roadmapStartDate={startDate} />
              {months.map((month, index) => (
                <KpiHeaderMonth key={month.toISOString()} isFirst={index === 0} month={month} />
              ))}
            </Row>
            <Row
              ref={bodyRow}
              className="pos-relative pb-16 ppcbee-scrollbar pt-8"
              style={{ overflowX: "auto" }}
              type="shrink"
            >
              <KpiToday roadmapStartDate={startDate} />
              <div
                className="h-100 pos-absolute"
                style={{
                  top: "0px",
                  left: "0px",
                  right: "0px",
                  bottom: "0px",
                  zIndex: 0,
                }}
              >
                <Row style={{ height: "100%" }} type="grow">
                  {months.map((month, index) => (
                    <KpiBodyMonthLines key={month.toISOString()} isFirst={index === 0} month={month} />
                  ))}
                </Row>
              </div>
              <Col type="grow">
                {usedSections.map((sectionName) => (
                  <KpiPriority
                    key={sectionName}
                    hiddenSections={hiddenSections}
                    sectionName={sectionName}
                    onToggleHiddenSection={onToggleHiddenSection}
                  >
                    {groupedBySectionsKpis[sectionName].map((kpiSetting) => (
                      <KpiRoadmapLine
                        key={kpiSetting.id}
                        {...kpiSetting}
                        roadmapEndDate={endDate}
                        roadmapStartDate={startDate}
                        periodResults={
                          (kpiSettingsWithPeriodResults || []).find((kswpr) => kswpr.id === kpiSetting.id)
                            ?.periodResults || []
                        }
                      />
                    ))}
                  </KpiPriority>
                ))}
              </Col>
            </Row>
          </Col>
          <Col padding="n" width="162">
            <Row
              alignItems="center"
              className="ph-8 background-white border-top-right-radius-8"
              type="shrink"
              style={{
                height: HEADER_ROW_HEIGHT,
                position: "sticky",
                top: "0px",
                zIndex: 1,
              }}
            >
              {resetToToday && (
                <Col type="shrink">
                  <Button size="medium" variant="secondary" onClick={resetToToday}>
                    Today
                  </Button>
                </Col>
              )}

              <Col type="shrink">
                <Select
                  selectProps={{
                    value: oneDayWidth,
                    onChange: ({ target: { value } }) => {
                      if (value) {
                        const newValue = parseInt(value);
                        setOneDayWidth(newValue);
                        if (headerRow?.current && bodyRow?.current) {
                          const positionLeft = headerRow.current.scrollLeft;
                          const spaceWidth = headerRow.current.offsetWidth;
                          setTimeout(() => {
                            if (headerRow?.current && bodyRow?.current) {
                              headerRow.current.scrollLeft =
                                (positionLeft + spaceWidth) * (newValue / oneDayWidth) - spaceWidth;
                              bodyRow.current.scrollLeft =
                                (positionLeft + spaceWidth) * (newValue / oneDayWidth) - spaceWidth;
                            }
                          }, 10);
                        }
                      }
                    },
                  }}
                >
                  <option value="1">25%</option>
                  <option value="2">50%</option>
                  <option value="3">75%</option>
                  <option value="4">100%</option>
                  <option value="8">200%</option>
                </Select>
              </Col>
            </Row>
            <Row className="pb-16 pt-8" type="shrink">
              <Col className="pb-16" type="grow">
                {usedSections.map((sectionName) => (
                  <KpiPriority
                    key={sectionName}
                    hiddenSections={hiddenSections}
                    sectionName={sectionName}
                    onToggleHiddenSection={onToggleHiddenSection}
                  >
                    {groupedBySectionsKpis[sectionName].map((kpiSetting) => (
                      <KpiTodayResult key={kpiSetting.id} {...kpiSetting} />
                    ))}
                  </KpiPriority>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Tile>
    </KpiRoadmapContext.Provider>
  );
};
