import React from "react";
import { Bar, BarChart, ResponsiveContainer } from "recharts";
import { WidgetCompareT, WidgetDataFragmentT, WidgetRowT } from "../../graphql/generated/graphql";
import { useGetWidgetComponentData } from "../../hooks/useGetWidgetComponentData";
import { ReportFilterT } from "../../types/report";
import { WidgetComponentPropsT, WidgetT } from "../../types/widgets";
import { withApiStateHandler } from "../ErrorLoadingWrapper/withApiStateHandler";
import { useReportBoardContext } from "../reportBoard/context/reportBoardContext";
import { WidgetDataEmpty } from "../widgetDataAlerts/WidgetDataEmpty";
import { WidgetDataError } from "../widgetDataAlerts/WidgetDataError";
import { useTimebasedChart } from "./useTimebasedChart";
import widgetComponentContainer from "./widgetComponentContainer";
import { widgetDataComponentContainer } from "./widgetDataComponentContainer";

export type TimeStackedBarChartComponentPropsT = WidgetComponentPropsT;

type PropsT = {
  filterValues: ReportFilterT;
  widget: WidgetT;
  widgetData?: WidgetDataFragmentT;
};

const TimeStackedBarChartComponentContent = withApiStateHandler(
  widgetDataComponentContainer(({ widget, widgetData }: PropsT) => {
    const {
      widgetSettingsData: { dimensionsById },
    } = useReportBoardContext();
    const { widgetDimensions, widgetMetrics } = widget;
    const rows = widgetData?.rows.nodes;
    const summaryRowsByDimensions = widgetData?.summaryByDimensions.nodes;

    const selectedDimensions = widgetDimensions
      .map((dimension) => dimensionsById[dimension.dimensionId])
      .filter((item) => !!item);

    const { cartesianGrid, legend, rowsByDateAsArray, series, tooltip, xaxis, yaxis } = useTimebasedChart({
      rows: (rows || []) as WidgetRowT[],
      categoricalDate: true,
      dateGrouping: widget.dateGrouping,
      selectedDimensions,
      summaryRowsByDimensions: summaryRowsByDimensions || [],
      widgetMetrics,
      hasCompare: widget.compare !== WidgetCompareT.NoneT,
    });

    if (!rows || !widgetMetrics || widgetMetrics.length === 0) {
      return <WidgetDataError />;
    }

    if (rows.length === 0) {
      return <WidgetDataEmpty />;
    }

    return (
      <ResponsiveContainer>
        <BarChart barCategoryGap={10} data={rowsByDateAsArray}>
          {tooltip}

          {cartesianGrid}

          {xaxis}

          {yaxis}

          {series?.map(({ color, dataKey, dimensionsValue, isCompare, isHidden, key, metric, serieId, yAxisId }) => {
            return (
              <Bar
                key={key}
                barSize={100}
                data-dimension-value={dimensionsValue}
                data-metric-compare={isCompare}
                data-metric-id={metric.id}
                data-serie-id={serieId}
                dataKey={dataKey}
                fill={color}
                hide={isHidden}
                isAnimationActive={false}
                stackId={isCompare ? `${metric.id}--compare` : metric.id}
                strokeDasharray={isCompare ? `3 3` : undefined}
                type="monotone"
                yAxisId={yAxisId}
              />
            );
          })}

          {legend}
        </BarChart>
      </ResponsiveContainer>
    );
  })
);

const TimeStackedBarChartComponent = ({
  filterValues,
  onWidgetDataChange,
  widget,
  ...rest
}: TimeStackedBarChartComponentPropsT) => {
  const result = useGetWidgetComponentData({
    filterValues,
    widget,
    useSummaryByDimensions: true,
    onWidgetDataChange,
  });

  return (
    <div className="h-100">
      <TimeStackedBarChartComponentContent {...rest} {...result} filterValues={filterValues} widget={widget} />
    </div>
  );
};

export default widgetComponentContainer(TimeStackedBarChartComponent);
