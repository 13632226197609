import React from "react";
import { fetchMetricId } from "../../functions/fetchIds";
import { fetchDimensionValueFromRow, fetchMetricCompareFromRow } from "../../functions/widgetDataHelpers";
import { WidgetDataFragmentT, WidgetDateGroupingT } from "../../graphql/generated/graphql";
import { useGetWidgetComponentData } from "../../hooks/useGetWidgetComponentData";
import { ReportFilterT } from "../../types/report";
import { WidgetComponentPropsT, WidgetT } from "../../types/widgets";
import { Col, Row } from "../../ui/grid/Grid";
import { withApiStateHandler } from "../ErrorLoadingWrapper/withApiStateHandler";
import { useReportBoardContext } from "../reportBoard/context/reportBoardContext";
import { MetricInformationCard } from "./MetricInformationCard";
import widgetComponentContainer from "./widgetComponentContainer";
import { widgetDataComponentContainer } from "./widgetDataComponentContainer";

export type ScorecardComponentPropsT = WidgetComponentPropsT;
type PropsT = { filterValues: ReportFilterT; widget: WidgetT; widgetData?: WidgetDataFragmentT };

const ScorecardComponentContent = withApiStateHandler(
  widgetDataComponentContainer(
    ({ widget: { compare, dateGrouping, widgetDimensions, widgetMetrics }, widgetData }: PropsT) => {
      const {
        widgetSettingsData: { dimensionsById, metricsById },
      } = useReportBoardContext();

      const rows = widgetData?.rows;

      const selectedDimensions = widgetDimensions.map((dimension) => dimensionsById[dimension.dimensionId]);

      if (!rows?.nodes.length) {
        return <div>No data to show.</div>;
      }

      return (
        <Row className="flex-gap-24" flexwrap>
          {rows?.nodes.map((row) =>
            widgetMetrics.map((met) => {
              const foundMetric = row.widgetMetrics.find((wd) => wd.widgetMetricId == fetchMetricId(met));
              const metricCompare =
                (dateGrouping === WidgetDateGroupingT.SummaryT ? met.compare : undefined) || compare;

              return (
                <Col key={`${fetchMetricId(met)}__${row.id}`}>
                  <MetricInformationCard
                    compare={metricCompare}
                    compareDate={fetchMetricCompareFromRow(met)(row)}
                    currency={row.currency}
                    dateRange={met.dateRange}
                    dimensionValue={fetchDimensionValueFromRow(selectedDimensions)(row)}
                    metric={metricsById[met.metricId]}
                    previousValue={foundMetric?.previousValue}
                    trend={foundMetric?.trend}
                    value={foundMetric?.value}
                  />
                </Col>
              );
            })
          )}
        </Row>
      );
    }
  )
);

const ScorecardComponent = ({ filterValues, onWidgetDataChange, widget, ...rest }: ScorecardComponentPropsT) => {
  const result = useGetWidgetComponentData({
    filterValues,
    widget,
    onWidgetDataChange,
  });

  return (
    <div className="overflow-auto h-100">
      <ScorecardComponentContent {...rest} {...result} filterValues={filterValues} widget={widget} />
    </div>
  );
};

export default widgetComponentContainer(ScorecardComponent);
