import React from "react";
import imageNotFound from "../../assets/image_not_found.svg";
import { DUPLICATED_PREFIX } from "../../constants/report";
import { useCreateReport } from "../../hooks/useCreateReport";
import { ReportTemplateListItemT } from "../../types/report";
import { ButtonPrimary, ButtonSecondary } from "../../ui/Button/Button";
import { Col, Row } from "../../ui/grid/Grid";
import { HeadingSmall } from "../../ui/Heading/Heading";
import { useModal } from "../../ui/Modal";
import { SystemIcon } from "../../ui/SystemIcon/SystemIcon";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { SYSTEM_NAMES } from "../accountSelect/constants";
import { TemplatePreviewModalConnected } from "./TemplatePreviewModal";

export const TemplateCard = ({ description, id, name, sourceSystems, thumbnailUrl }: ReportTemplateListItemT) => {
  const modalProps = useModal();

  const { createReport, loading } = useCreateReport({
    namePrefix: DUPLICATED_PREFIX,
    successMessage: `New report from template ${name} was created.`,
  });

  const handleUseTemplate = (templateId: string, templateName: string) => {
    createReport({ name: templateName, templateId });
  };

  return (
    <Col width="280px">
      {/* TODO - BADGE: get category from API badge="Mocked category" */}
      <Tile isFullHeight>
        <Col height="100%">
          <div
            aria-label="Template image with category badge"
            className="pos-relative w-100 mb-16 d-flex justify-content-center align-items-center"
            role="img"
            style={{ height: "144px" }}
          >
            <img
              alt="Template preview"
              className="pos-absolute mw-100"
              src={thumbnailUrl ? thumbnailUrl : imageNotFound}
              style={{ inset: "0px", margin: "auto", maxHeight: "100%" }}
            />
          </div>
          <div className="delimiter negative-ml-16 negative-mr-16 mb-16" />
          <HeadingSmall margin={4}>{name}</HeadingSmall>

          {description && description.length > 0 && (
            <div className="mb-8 line-clamp line-clamp-5">
              <Text color="gray">{description}</Text>
            </div>
          )}

          <div className="mt-a pt-8">
            <Text bold>Platforms:</Text>
            <Row className="mt-4">
              {sourceSystems.map(({ externalId, name: system }) => (
                <Col key={`${system}-${externalId}`}>
                  <SystemIcon system={system} tooltipContent={SYSTEM_NAMES[system]} />
                </Col>
              ))}
            </Row>
          </div>

          <Row className="mt-16">
            <Col type="grow">
              <ButtonSecondary hasFullWidth onClick={modalProps.open}>
                Preview
              </ButtonSecondary>
            </Col>
            <Col type="grow">
              <ButtonPrimary
                data-test-id="use-template"
                loading={loading}
                hasFullWidth
                onClick={() => handleUseTemplate(id, name)}
              >
                Use Template
              </ButtonPrimary>
            </Col>
            <TemplatePreviewModalConnected
              modalOptions={{ ...modalProps, testId: "preview-modal" }}
              name={name}
              reportId={id}
              useTemplateLoading={loading}
              onUseTemplate={handleUseTemplate}
            />
          </Row>
        </Col>
      </Tile>
    </Col>
  );
};
