import React, { memo, useCallback } from "react";
import { isEqual } from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import { v4 as uuid } from "uuid";
import { DEFAULT_WIDGET_METRIC } from "../../../constants/form";
import { WidgetDateGroupingT, WidgetMetricT } from "../../../graphql/generated/graphql";
import { usePrevious } from "../../../hooks/usePrevious";
import { WidgetRuleT, WidgetT } from "../../../types/widgets";
import { Text } from "../../../ui/Text/Text";
import { OnFormEditWidgetChangeT } from "../FormEditWidget";
import { InputWidgetMetricsItemRow } from "./InputWidgetMetricsItemRow";
import { LimitState } from "./LimitState";
import { useMetricDimensionDraggableList } from "./useMetricDimensionDraggableList";

const ResponsiveGridLayout = WidthProvider(Responsive);

type PropsT = {
  addButtonTitle: string;
  dateGrouping: WidgetT["dateGrouping"];
  formKey: string;
  hasDefaultData?: boolean;
  hasMetricOptions?: boolean;
  label?: React.ReactNode;
  onChange: OnFormEditWidgetChangeT;
  rules?: WidgetRuleT;
  value: WidgetT["widgetMetrics"];
};

const InputWidgetMetricsMemo = ({ addButtonTitle, hasDefaultData, label, onChange, rules, value, ...rest }: PropsT) => {
  const limits = rules?.metrics;
  const filledLength = value.filter((met) => met?.metricId !== "").length;
  const prevValue = usePrevious(value);
  const hasNewItem = !!prevValue && value.length > prevValue.length;

  const handleLayoutChange = <T extends WidgetMetricT>(sortedValues: T[]) => {
    onChange((prev) => ({ ...prev, widgetMetrics: sortedValues }));
  };

  const { defaultGridProps, onRowHeightChange } = useMetricDimensionDraggableList({
    value,
    onChange: handleLayoutChange,
  });

  const handleChange = useCallback(
    ({ index, newMetric }: { index: number; newMetric: WidgetMetricT }) => {
      onChange((prev) => ({
        ...prev,
        widgetMetrics: [...value.slice(0, index), newMetric, ...value.slice(index + 1)],
      }));
    },
    [value, onChange]
  );

  const handleAddRow = useCallback(() => {
    onChange((prev) => ({ ...prev, widgetMetrics: [...value, { ...DEFAULT_WIDGET_METRIC, id: uuid() }] }));
  }, [onChange, value]);

  const handleRemoveRow = useCallback(
    (index: number) => {
      onChange((prev) => ({
        ...prev,
        widgetMetrics: [...value.slice(0, index), ...value.slice(index + 1)],
      }));
    },
    [value, onChange]
  );

  return (
    <div className="mb-16">
      {<h3 className="Heading Heading--lg mb-0">{label}</h3> || (
        <Text className="mb-0" bold>
          Metrics
        </Text>
      )}

      <ResponsiveGridLayout
        {...defaultGridProps}
        className="mt-16"
        draggableHandle=".draggable-handle-metric"
        margin={[0, 0]}
      >
        {value.map((widgetMetric, index) => (
          <div key={widgetMetric.id}>
            <InputWidgetMetricsItemRow
              isDefaultOpen={hasNewItem && !hasDefaultData}
              {...rest}
              canShowAdditionalInfo={!!(rest.hasMetricOptions && rest.dateGrouping === WidgetDateGroupingT.SummaryT)}
              handleRemoveRow={handleRemoveRow}
              index={index}
              isRowDisabled={!!(limits && limits.max && index >= limits.max)}
              value={widgetMetric}
              onChange={handleChange}
              onRowHeightChange={onRowHeightChange}
            />
          </div>
        ))}
      </ResponsiveGridLayout>

      <LimitState
        addButtonTitle={addButtonTitle}
        filledLength={filledLength}
        handleAddRow={handleAddRow}
        limits={limits}
        rawLength={value.length}
        testId="widgetMetrics-add-button"
      />
    </div>
  );
};

export const InputWidgetMetrics = memo(InputWidgetMetricsMemo, isEqual);
