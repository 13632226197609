import React, { useEffect } from "react";
import { omit } from "lodash";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { withApiStateHandler } from "../components/ErrorLoadingWrapper/withApiStateHandler";
import { ReporBoardProvider } from "../components/reportBoard/context/reportBoardContext";
import { ReportBoard } from "../components/reportBoard/ReportBoard";
import { TemplateVisibilitySwitcher } from "../componentsConnected/templateVisibilitySwitcher/TemplateVisibilitySwitcher";
import { DUPLICATED_PREFIX } from "../constants/report";
import { ROUTES } from "../constants/routes";
import { notifyError, notifySucces } from "../functions/toast";
import {
  DateRangeEnumT,
  DateRangeT,
  ReportPageQueryResultT,
  ReportPageQueryT,
  useReportDeleteMutation,
  useReportPageQuery,
} from "../graphql/generated/graphql";
import { useCreateReport } from "../hooks/useCreateReport";
import { Breadcrumb } from "../layout/wrappers/Breadcrumb";
import { BreadcrumbItem } from "../layout/wrappers/BreadcrumbItem";
import { Layout } from "../layout/wrappers/Layout";
import { SidebarMenuCollectionT } from "../layout/wrappers/SidebarMenuComponent";
import { useAppSettings } from "../providers/appSettingsProvider";
import { cache } from "../providers/graphqlProvider";
import { RouteParamsT } from "../types/router";
import ErrorBox from "../ui/ErrorBox/ErrorBox";

const SIDEBAR_COLLECTION: SidebarMenuCollectionT = [{ icon: "menu-dashboard", link: "#", label: "Setup" }];

const DEFAULT_DATE_RANGE = { from: null, to: null, range: DateRangeEnumT.RangeLast_30DaysT };

type PropsT = ReportPageQueryResultT & { canEdit: boolean; data?: ReportPageQueryT };

const getDefaultDateRange = (dateRange?: DateRangeT | null | undefined) =>
  dateRange?.from || dateRange?.to || dateRange?.range ? omit(dateRange, "__typename") : DEFAULT_DATE_RANGE;

const ReportComponent = withApiStateHandler(({ canEdit, data, refetch }: PropsT) => {
  const { organization } = useAppSettings();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const duplicatedTo = searchParams.get("duplicatedTo");
  const reportName = data?.organization?.report?.name;

  const parsedName = (reportName || "").match(/(.*)\s\[Copy From\s(.*?)\]$/);

  useEffect(() => {
    if (duplicatedTo) {
      notifySucces(
        <>
          Duplication of the report {parsedName ? parsedName[1] : reportName} to organization {duplicatedTo} was{" "}
          succesfull
        </>
      );
      setSearchParams({});
    }
  }, [duplicatedTo, parsedName, reportName, setSearchParams]);

  const { createReport, loading: isOnDuplicationReportLoading } = useCreateReport({
    successMessage: `Report ${data?.organization?.report?.name || ""} was succesfully duplicated`,
    namePrefix: DUPLICATED_PREFIX,
  });

  const [deleteReport, { loading: isOnDeleteReportLoading }] = useReportDeleteMutation({
    onCompleted: (res) => {
      const errors = res.organization?.deleteReport?.errors;
      if (errors?.length) {
        return notifyError(<>{errors.join("\n")}</>);
      }
      notifySucces(<>Report was succesfully deleted!</>);
      navigate(ROUTES.reports());
    },
    onError: () => notifyError(<>Server error when deleting report!</>),
  });

  const handleDelete = (reportId: string) => {
    if (reportId) {
      deleteReport({
        variables: {
          organizationExternalId: organization.externalId,
          reportId,
        },
      });
    }
  };

  const handleDuplicate = ({ name, reportId }: { name: string; reportId: string }) => {
    if (data?.organization?.report) {
      createReport({ name, templateId: reportId });
    }
  };

  if (data?.organization?.report?.discarded) {
    return (
      <Layout sidebarCollection={SIDEBAR_COLLECTION} hideSidebar>
        <ErrorBox withIcon>Report was deleted</ErrorBox>
      </Layout>
    );
  }

  return (
    <Layout sidebarCollection={SIDEBAR_COLLECTION} hideSidebar>
      {data?.organization?.report ? (
        <>
          {organization.isTemplate && <TemplateVisibilitySwitcher />}
          {data.organization.report.name && (
            <Breadcrumb>
              <BreadcrumbItem>{data.organization.report.name}</BreadcrumbItem>
            </Breadcrumb>
          )}
          <ReporBoardProvider
            reportOptions={{ canEdit }}
            report={{
              ...data.organization.report,
              dateRange: getDefaultDateRange(data.organization.report.dateRange),
            }}
          >
            <ReportBoard
              isOnDeleteReportLoading={isOnDeleteReportLoading}
              isOnDuplicationReportLoading={isOnDuplicationReportLoading}
              reportRefetch={refetch}
              onDeleteReport={handleDelete}
              onDuplicateReport={handleDuplicate}
            />
          </ReporBoardProvider>
        </>
      ) : (
        <ErrorBox withIcon>
          It seems that the report you want to view either doesn`t exist or you don`t have rights to view it.
        </ErrorBox>
      )}
    </Layout>
  );
});

export const ReportPage = () => {
  const { reportId } = useParams<RouteParamsT["report"]>();
  const {
    organization,
    user: { canEdit },
  } = useAppSettings();

  const response = useReportPageQuery({
    variables: { organizationExternalId: organization.externalId, reportId: reportId as string },
    skip: !reportId,
  });

  useEffect(() => {
    return () => {
      cache.evict({ id: `Report:${reportId}`, fieldName: "metrics" });
      cache.evict({ id: `Report:${reportId}`, fieldName: "dimensions" });
      cache.gc();
    };
  }, [reportId]);

  return <ReportComponent {...response} canEdit={canEdit} />;
};
