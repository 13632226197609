import React from "react";
import { SystemIconBasicPropsT } from "./types";

export const AdWordsIcon = ({ active, size = "16px" }: SystemIconBasicPropsT) => (
  <svg
    className={`system-icon adwords-icon ${active && "active"}`}
    height={size}
    viewBox="0 0 711.53 647.49"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <rect
          fill="#fbbd04"
          height="708.99"
          rx="119.07"
          ry="119.07"
          transform="matrix(0.87, 0.5, -0.5, 0.87, 194.22, -75.59)"
          width="238.14"
          x="119.09"
          y="-29.87"
        />
        <rect
          fill="#438edc"
          height="708.99"
          rx="119.07"
          ry="119.07"
          transform="translate(-97.81 280.68) rotate(-30)"
          width="238.14"
          x="355.78"
          y="-31.63"
        />
        <circle cx="120.72" cy="526.76" fill="#34a952" r="120.72" />
      </g>
    </g>
  </svg>
);
