/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Object {
    hasOwnProperty<K extends PropertyKey>(key: K): this is Record<K, unknown>;
  }

  interface Array<T> {
    findLast(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: any): T;
  }
}

export type Unpacked<T> = T extends (infer U)[] ? U : T;

export type FormSelectOptionsT = {
  disabled?: boolean;
  label: string | number;
  value?: string | number;
}[];

export type FormRadioOptionsT = {
  description?: string;
  disabled?: boolean;
  icon?: string;
  iconColor?: string;
  label: string | number;
  value: string | number;
}[];

export type HTMLInputTypeAttributeT =
  | "button"
  | "checkbox"
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "file"
  | "hidden"
  | "image"
  | "month"
  | "number"
  | "password"
  | "radio"
  | "range"
  | "reset"
  | "search"
  | "submit"
  | "switch"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week";

export enum TableOrderT {
  asc = "asc",
  desc = "desc",
}
export type SortT = { descs: TableOrderT[]; ids: string[] };
