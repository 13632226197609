import React, { PropsWithChildren, useCallback, useEffect, useMemo } from "react";
import { TabsContext } from "./TabsContext";

type PropsT = PropsWithChildren<{
  onSelected?: (tab: string) => void;
  testId: string;
}> &
  ({ initialSelectedTab?: never; selectedTab: string } | { initialSelectedTab: string | null; selectedTab?: never });

export const Tabs = ({
  children,
  initialSelectedTab = null,
  onSelected,
  selectedTab: forceSelectedTab,
  testId,
}: PropsT) => {
  const [selectedTab, setSelected] = React.useState<string | null>(initialSelectedTab);
  const selectTab = useCallback(
    (tab: string) => {
      if (onSelected) {
        onSelected(tab);
      }
      setSelected(tab);
    },
    [onSelected]
  );

  useEffect(() => {
    if (forceSelectedTab) {
      selectTab(forceSelectedTab);
    }
  }, [selectTab, forceSelectedTab]);

  const value = useMemo(
    () => ({ selectedTab, testId, selectTab, initialSelectedTab }),
    [initialSelectedTab, selectedTab, testId, selectTab]
  );

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};
