import React, { ComponentType } from "react";
import { ApolloError } from "@apollo/client";
import { Layout } from "../../layout/wrappers/Layout";
import ErrorBox from "../../ui/ErrorBox/ErrorBox";
import { Loader } from "../../ui/Loader/Loader";

type PropsT = {
  called?: boolean;
  error?: ApolloError;
  isFirstLoadEnded?: boolean;
  loading?: boolean;
  wrapWithLayout?: boolean;
};

export const withApiStateHandler = <T extends object>(Component: ComponentType<T>) => {
  const withApiStateHandlerComponent = (props: T & PropsT) => {
    if (props.loading && !props.isFirstLoadEnded) {
      if (props.wrapWithLayout) {
        return (
          <Layout>
            <div className="d-flex justify-content-center myl">
              <Loader data-test-id="widget-loader" size="big" />
            </div>
          </Layout>
        );
      }
      return (
        <div className="d-flex justify-content-center myl">
          <Loader data-test-id="widget-loader" size="big" />
        </div>
      );
    }

    if (props.error) {
      return <ErrorBox withIcon>An unexpected error has occured</ErrorBox>;
    }

    return <Component {...props} />;
  };

  return withApiStateHandlerComponent;
};
