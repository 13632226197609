import React from "react";
import { useNavigate } from "react-router-dom";
import { useConfirmationContext } from "../components/confirmationDialog/confirmationDialogContext";
import { ROUTES } from "../constants/routes";
import { CustomDimensionsQueryT } from "../graphql/generated/graphql";
import { DropdownMenu } from "../ui/Dropdown/DropdownMenu";
import { Col, Row } from "../ui/grid/Grid";
import { Icon } from "../ui/Icon/Icon";
import { Link } from "../ui/Link/Link";
import { Td, TrBody } from "../ui/Table/Table";

export type CustomDimensionT = NonNullable<CustomDimensionsQueryT["organization"]>["customDimensions"]["nodes"][number];
type PropsT = {
  customDimension: CustomDimensionT;
  onDelete: () => void;
};
export const DimensionTableRow = ({ customDimension, onDelete }: PropsT) => {
  const { callWithConfirmation } = useConfirmationContext();

  const navigate = useNavigate();

  return (
    <TrBody key={customDimension.id}>
      <Td>
        <Row alignItems="center">
          <Col type="grow">
            <Link to={`${ROUTES.dimensionEdit(customDimension.id)}?tab=dimensions`}>{customDimension.name}</Link>
          </Col>
          <Col type="shrink">
            <DropdownMenu
              buttonProps={{ variant: "tertiary" }}
              testId={`Dropdown--${customDimension.name}`}
              collection={[
                {
                  value: "Edit custom dimension",
                  icon: "edit",
                  onClick: () => navigate(`${ROUTES.dimensionEdit(customDimension.id)}?tab=dimensions`),
                },
                {
                  value: "Duplicate custom dimension",
                  icon: "duplicate",
                  onClick: () => navigate(`${ROUTES.dimensionDuplicate(customDimension.id)}?tab=dimensions`),
                },
                {
                  color: "danger",
                  icon: "trash",
                  isDisabled: !!customDimension?.referencesCount,
                  onClick: () => {
                    callWithConfirmation(onDelete, {
                      description: `Are you sure you want to delete this custom dimension: "${customDimension.name}"?`,
                      title: "Delete custom dimension",
                      testId: `delete-custom-dimension-${customDimension.id}`,
                    });
                  },
                  tooltipContent: !!customDimension?.referencesCount
                    ? "This dimension cannot be deleted because it is used somewhere else."
                    : undefined,
                  value: "Delete custom dimension",
                },
              ]}
            />
          </Col>
        </Row>
      </Td>
      <Td>{customDimension.description}</Td>
      <Td>{customDimension.referencedDimension.name}</Td>
      <Td justifyRight>{customDimension.referencesCount}</Td>
      <Td>
        <Row alignItems="center" type="shrink">
          <Col>
            <Icon color="#8C969F" kind="user" size="20px" />
          </Col>
          <Col>{customDimension.user?.fullname}</Col>
        </Row>
      </Td>
    </TrBody>
  );
};
