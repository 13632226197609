import { add, endOfDay, startOfDay } from "date-fns";
import { groupBy, uniq } from "lodash";
import { CartesianGrid, Legend, Tooltip, XAxis, YAxis, YAxisProps } from "recharts";
import { CHART_LINE_COLORS, X_AXIS_STYLES, Y_AXIS_STYLES } from "../../constants/report";
import { getDateGrouppedDateRange } from "../../functions/dateHelpers";
import { getYAxisDataTypes, normalizeMetricMoneyAxis } from "../../functions/widgetChartHelpers";
import {
  fetchColor,
  fetchDimensionValueFromRowAsText,
  fetchMetricValueFromGroupedRowByDate,
} from "../../functions/widgetDataHelpers";
import { normalizeMetricDataType } from "../../functions/widgetHelpers";
import { MetricDataT, WidgetDateGroupingT, WidgetMetricT, WidgetRowT } from "../../graphql/generated/graphql";
import { formatDateRange } from "../../i18n/formatDate";
import { formatByDataType } from "../../i18n/formatNumbers";
import { useReportBoardContext } from "../reportBoard/context/reportBoardContext";
import { ReportDimensionT, ReportMetricT } from "../reportBoard/context/types";
import { useHideSeries } from "./useHideSeries";
import { WidgetChartTimebasedLegend } from "./WidgetChartTimebasedLegend";
import { WidgetChartTooltipTimebased } from "./WidgetChartTooltipTimebased";

export type SerieT = {
  color: string;
  dataKey: (groupedRow: { date: string; dateTo: string; rowsData: WidgetRowT[] }) => string | number;
  dimensionsValue: string;
  isCompare: boolean;
  key: string;
  metric: ReportMetricT;
  widgetMetric: WidgetMetricT;
  yAxisId: string;
};

const getAxisDomainExpansion = ({
  dateGrouping,
  expandXAxis,
}: {
  dateGrouping?: WidgetDateGroupingT;
  expandXAxis?: boolean;
}) => {
  if (expandXAxis && dateGrouping === WidgetDateGroupingT.DayT) {
    return [{}, { hours: 2 }];
  }

  return [{}, {}];
};

export const useTimebasedChart = ({
  categoricalDate,
  dateGrouping,
  hasCompare,
  rows,
  selectedDimensions,
  summaryRowsByDimensions,
  widgetMetrics,
}: {
  categoricalDate?: boolean;
  dateGrouping?: WidgetDateGroupingT;
  hasCompare?: boolean;
  rows: WidgetRowT[];
  selectedDimensions: ReportDimensionT[];
  summaryRowsByDimensions: Pick<WidgetRowT, "widgetMetrics">[];
  widgetMetrics: WidgetMetricT[];
}) => {
  const {
    widgetSettingsData: { metricsById },
  } = useReportBoardContext();

  const { getIsHidden, getSerieId, onLegendClick } = useHideSeries({
    widgetMetrics,
    widgetDimensions: selectedDimensions,
  });

  const isCategorical = categoricalDate && dateGrouping && dateGrouping !== WidgetDateGroupingT.DayT;
  const [minExpansion, maxExpansion] = getAxisDomainExpansion({ dateGrouping, expandXAxis: categoricalDate });
  const endDate = new Date(rows[rows.length - 1]?.dateTo);

  const domain = categoricalDate
    ? [add(startOfDay(new Date(rows[0]?.date)), minExpansion).getTime(), add(endOfDay(endDate), maxExpansion).getTime()]
    : [new Date(rows[0]?.date).getTime(), new Date(rows[rows.length - 1]?.date).getTime()];

  const xaxis = (
    <XAxis
      {...X_AXIS_STYLES}
      allowDataOverflow={false}
      dataKey={(record) => add(new Date(record.date), { hours: categoricalDate ? 12 : 0 }).getTime()}
      domain={isCategorical ? ["auto", "auto"] : domain}
      interval="preserveStartEnd"
      scale={isCategorical ? "auto" : "linear"}
      type={isCategorical ? "category" : "number"}
      tickFormatter={(record) => {
        const { dateFrom, dateTo } = getDateGrouppedDateRange({
          dateFrom: record,
          dateTo: rows[rows.length - 1]?.dateTo,
          dateGrouping,
        });

        return formatDateRange(dateFrom, dateTo);
      }}
    />
  );

  const yaxis = getYAxisDataTypes({ widgetMetrics, rows, metricsById }).map(
    ({ currency, dataType, orientation, yAxisId }) => {
      return (
        <YAxis
          {...Y_AXIS_STYLES}
          key={yAxisId}
          domain={[0, "dataMax"]}
          orientation={orientation as YAxisProps["orientation"]}
          padding={{ top: 10 }}
          width={yAxisId.includes("MONEY") ? 70 : undefined}
          yAxisId={yAxisId}
          tickFormatter={(record) =>
            formatByDataType(dataType, currency, record, dataType === MetricDataT.PercentT ? 2 : 0)
          }
        />
      );
    }
  );

  const legend = (
    <Legend
      content={
        <WidgetChartTimebasedLegend
          getIsHidden={getIsHidden}
          metricsById={metricsById}
          selectedDimensions={selectedDimensions}
          summaryRowsByDimensions={summaryRowsByDimensions}
          widgetMetrics={widgetMetrics}
          onClick={(event) => onLegendClick(event)}
        />
      }
    />
  );

  const dimensionRows = groupBy(rows, fetchDimensionValueFromRowAsText(selectedDimensions));
  const dimensioValues = Object.keys(dimensionRows);

  let counter = -1;
  let isOtherUsed = false;
  const series = widgetMetrics?.flatMap((widgetMetric) => {
    const metric = metricsById[widgetMetric.metricId];

    return dimensioValues.flatMap((dimensionsValue) => {
      return uniq(dimensionRows[dimensionsValue]?.map((row) => row.currency)).flatMap((currency) => {
        const yAxisId =
          metric.dataType === MetricDataT.MoneyT ? normalizeMetricMoneyAxis(currency) : normalizeMetricDataType(metric);
        const isOther = !isOtherUsed && dimensionsValue === "other";
        if (isOther) {
          isOtherUsed = true;
        }
        counter += 1;
        const key = [metric.id, dimensionsValue, currency].join("-");
        const serieId = getSerieId({ serieId: `${key}`, isCompare: false });
        const basic = {
          key: key,
          dataKey: fetchMetricValueFromGroupedRowByDate(widgetMetric, dimensionsValue, selectedDimensions),
          widgetMetric,
          color: fetchColor({ color: CHART_LINE_COLORS[counter], isOther }),
          serieId,
          isCompare: false,
          isHidden: getIsHidden(serieId),
          metric,
          dimensionsValue,
          yAxisId,
        };
        if (hasCompare) {
          const compareKey = [key, "compare"].join("-");
          const compareSerieId = getSerieId({ serieId: `${compareKey}`, isCompare: true });
          return [
            {
              ...basic,
              key: compareKey,
              color: fetchColor({ color: CHART_LINE_COLORS[counter], isOther, isCompare: true }),
              serieId: compareSerieId,
              isHidden: getIsHidden(compareSerieId),
              isCompare: true,
              dataKey: fetchMetricValueFromGroupedRowByDate(widgetMetric, dimensionsValue, selectedDimensions, true),
            },
            basic,
          ];
        }
        return [basic];
      });
    });
  });

  const tooltip = (
    <Tooltip
      content={<WidgetChartTooltipTimebased series={series} widgetMetrics={widgetMetrics} />}
      cursor={{ fill: "rgba(0,22,42,.05)" }}
      position={{ y: -100 }}
      wrapperStyle={{ zIndex: 2 }}
      allowEscapeViewBox={{
        y: true,
      }}
    />
  );

  const cartesianGrid = <CartesianGrid strokeDasharray="3 3" vertical={false} />;

  const rowsByDate = groupBy(rows, "date");
  const rowsByDateAsArray = Object.keys(rowsByDate).map((date) => {
    const rowsData = rowsByDate[date];
    return {
      date,
      dateTo: rowsData[0].dateTo,
      rowsData,
      dateGrouping,
    };
  });

  return {
    cartesianGrid,
    yaxis,
    xaxis,
    rowsByDateAsArray,
    series,
    legend,
    tooltip,
  };
};
