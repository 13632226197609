type IdT = string | number;

export const ROUTES = {
  homepage: () => "/",
  dimensionEdit: (dimensionId: IdT) => `/metrics-and-dimensions/dimensions/${dimensionId}/edit`,
  dimensionDuplicate: (dimensionId: IdT) => `/metrics-and-dimensions/dimensions/${dimensionId}/duplicate`,
  metricDuplicate: (metricId: IdT) => `/metrics-and-dimensions/metrics/${metricId}/duplicate`,
  valueMetricDuplicate: (valueMetricId: IdT) => `/metrics-and-dimensions/value-metrics/${valueMetricId}/duplicate`,
  dimensionNew: () => `/metrics-and-dimensions/dimensions/new`,
  firstTemplates: () => "/first-templates",
  kpiDetail: (kpiSettingId: IdT) => `/kpis/${kpiSettingId}`,
  kpiDuplicate: (kpiSettingId: IdT) => `/kpis/${kpiSettingId}/duplicate`,
  kpiEdit: (kpiSettingId: IdT) => `/kpis/${kpiSettingId}/edit`,
  kpis: () => "/kpis",
  kpisNew: () => `/kpis/new`,
  kpisForAllOrganizations: () => `/kpis/organizations/`,
  metricEdit: (metricId: IdT) => `/metrics-and-dimensions/metrics/${metricId}/edit`,
  metricNew: () => `/metrics-and-dimensions/metrics/new?type=metric`,
  metricsAndDimensions: () => `/metrics-and-dimensions`,
  metricsAndDimensionsTabs: (tab: string) => `/metrics-and-dimensions?tab=${tab}`,
  report: (id: IdT) => `/reports/${id}`,
  reportPage: (reportId: IdT, pageId: IdT) => `/reports/${reportId}?page=${pageId}`,
  reportCreateFromTemplate: (templateId: IdT) => `/report-create?templateId=${templateId}`,
  reportEdit: (id: IdT) => `/reports/${id}/edit`,
  reports: () => "/reports",
  reportsPreview: (reportId: IdT) => `reports/${reportId}/preview`,
  reportTemplateSelect: () => "/",
  templates: () => "/templates",
  userSystemActions: () => "/user-campaign-actions",
  valueMetricEdit: (metricId: IdT) => `/metrics-and-dimensions/value-metrics/${metricId}/edit`,
  valueMetricNew: () => `/metrics-and-dimensions/metrics/new?type=value-metric`,
  valueMetricValues: (metricId: IdT) => `/metrics-and-dimensions/value-metrics/${metricId}/values`,
};
