import React from "react";
import { FallbackProps } from "react-error-boundary";
import { ButtonSecondary } from "../../ui/Button/Button";
import ErrorBox from "../../ui/ErrorBox/ErrorBox";
import { Row } from "../../ui/grid/Grid";
import { Heading } from "../../ui/Heading/Heading";
import { Text } from "../../ui/Text/Text";

type PropsT = { withTryAgainButton: boolean } & Partial<FallbackProps>;

const ErrorFallbackComponent = ({ error, resetErrorBoundary, withTryAgainButton }: PropsT) => {
  return (
    <ErrorBox role="alert">
      <Heading level="h2">Something went wrong</Heading>
      <Row>
        <Text>{error?.message}</Text>
      </Row>
      <Row>
        {withTryAgainButton && (
          <ButtonSecondary icon="refresh" onClick={resetErrorBoundary}>
            Try again
          </ButtonSecondary>
        )}
      </Row>
    </ErrorBox>
  );
};

export const ErrorFallback = ({ ...props }: Partial<FallbackProps>) => (
  <ErrorFallbackComponent {...props} withTryAgainButton />
);

export const ErrorFallbackWithoutTryAgain = ({ ...props }: Partial<FallbackProps>) => (
  <ErrorFallbackComponent {...props} withTryAgainButton={false} />
);
