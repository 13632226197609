import React from "react";
import { CurrencyT, MetricDataT, MetricPredictionKindT } from "../../graphql/generated/graphql";
import { formatByDataType } from "../../i18n/formatNumbers";

type LimitsCalculationT = {
  currentValue?: number | null;
  // kpiSetting.progressThresholdOk
  estimatedPeriodValue?: number | null;
  //kpiSetting.metric.predictionKind
  positiveTrend?: boolean | null;
  predictionKind?: MetricPredictionKindT | null;
  progressThresholdLow?: number | null;
  // kpiSetting.progressThresholdLow
  progressThresholdOk?: number | null;
  //kpiSetting.metric.positiveTrend
  targetValue?: number | null;
};

type PropsT = LimitsCalculationT & {
  currency?: CurrencyT | null;
  dataType: MetricDataT;
  kpiExceedable?: false;
};

const calculateLimitTargets = ({
  currentValue,
  estimatedPeriodValue,
  positiveTrend,
  predictionKind,
  progressThresholdLow,
  progressThresholdOk,
  targetValue,
}: LimitsCalculationT) => {
  if (
    typeof targetValue === "number" &&
    typeof progressThresholdLow === "number" &&
    typeof progressThresholdOk === "number" &&
    typeof estimatedPeriodValue !== "number" &&
    typeof currentValue !== "number"
  ) {
    if (predictionKind === MetricPredictionKindT.ConstantT && !positiveTrend) {
      return [
        targetValue + (targetValue * (100 - progressThresholdLow)) / 100,
        targetValue + (targetValue * (100 - progressThresholdOk)) / 100,
        -1,
        -1,
        0,
      ];
    } else {
      return [(targetValue * progressThresholdLow) / 100, (targetValue * progressThresholdOk) / 100, -1, -1, 0];
    }
  }

  if (
    typeof targetValue !== "number" ||
    typeof progressThresholdLow !== "number" ||
    typeof progressThresholdOk !== "number" ||
    typeof estimatedPeriodValue !== "number" ||
    typeof currentValue !== "number"
  ) {
    return [
      typeof progressThresholdLow !== "number" ? -1 : progressThresholdLow,
      typeof progressThresholdOk !== "number" ? -1 : progressThresholdOk,
      -1,
      -1,
      0,
    ];
  }

  if (predictionKind === MetricPredictionKindT.ConstantT && !positiveTrend) {
    return [
      targetValue + (targetValue * (100 - progressThresholdLow)) / 100,
      targetValue + (targetValue * (100 - progressThresholdOk)) / 100,
      estimatedPeriodValue / targetValue,
      1 - (currentValue / targetValue - 1),
      1,
    ];
  }

  return [
    (targetValue * progressThresholdLow) / 100,
    (targetValue * progressThresholdOk) / 100,
    estimatedPeriodValue / targetValue,
    currentValue / targetValue,
    1,
  ];
};

export const KpiProgressChart = ({
  currency,
  currentValue,
  dataType,
  estimatedPeriodValue,
  kpiExceedable,
  positiveTrend,
  predictionKind,
  progressThresholdLow,
  progressThresholdOk,
  targetValue,
}: PropsT) => {
  // SVG range 0 is 0  ..  395 is 100%
  const boxWidth = 395;
  const redBoxWidth = Math.round(boxWidth * ((progressThresholdLow || 0) / 100));
  const redChevron = redBoxWidth - 4.3301;

  const yellowBoxWidth = Math.round(boxWidth * ((progressThresholdOk || 0) / 100));
  const yellowChevron = yellowBoxWidth - 4.3301;

  const [lowTarget, mediumTarget, predictedRisingPosition, currentProgressPosition, validValues] =
    calculateLimitTargets({
      progressThresholdLow,
      progressThresholdOk,
      predictionKind,
      estimatedPeriodValue,
      positiveTrend,
      targetValue,
      currentValue,
    });

  if (validValues === 0) {
    return (
      <svg fill="none" height="54" viewBox="0 0 417 54" width="417" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 13C0 10.7909 1.79086 8 4 8H403C405.209 8 407 10.7909 407 13V38C407 40.2091 405.209 42 403 42H4.00001C1.79087 42 0 40.2091 0 38V13Z"
          fill="#00162A"
          fillOpacity="0.05"
          id="box-grey"
        />
        <path d="M394 7L389.67 0.5L398.33 0.500001L394 7Z" fill="#397031" id="chevron-top-green" />
        <path
          fill="#F1C011"
          id="chevron-top-yellow"
          d={`M${yellowChevron + 4.3301} 7L${yellowChevron} 0.5L${yellowChevron + 8.6601} 0.500001L${
            yellowChevron + 4.3301
          } 7Z`}
        />
        <path
          d={`M${redChevron + 4.3301} 7L${redChevron} 0.5L${redChevron + 8.6601} 0.500001L${redChevron + 4.3301} 7Z`}
          fill="#9D2226"
          id="chevron-top-red"
        />

        <text fill="#A1A1A1" fontSize="9" style={{ textAnchor: "middle" }} x={redBoxWidth + 2.165} y="54">
          {formatByDataType(dataType, currency, lowTarget)}
        </text>
        <text fill="#A1A1A1" fontSize="9" style={{ textAnchor: "middle" }} x={yellowChevron + 4.165} y="54">
          {formatByDataType(dataType, currency, mediumTarget)}
        </text>
        <text fill="#A1A1A1" fontSize="9" style={{ textAnchor: "end" }} x={408} y="54">
          {" "}
          {formatByDataType(dataType, currency, targetValue)}
        </text>
      </svg>
    );
  }

  const predictedValueProgress = Math.max(Math.min(Math.round(boxWidth * predictedRisingPosition), boxWidth + 10), 2);
  const currentProgress = Math.min(boxWidth * currentProgressPosition, boxWidth + 10);
  const currentProgressConstant = Math.max(currentProgress, 8);

  const isOver =
    !kpiExceedable &&
    predictionKind === MetricPredictionKindT.RisingT &&
    positiveTrend &&
    predictedValueProgress > boxWidth;

  return (
    <svg
      fill="none"
      height="54"
      style={{ maxWidth: "100%" }}
      viewBox="0 0 417 54"
      width="417"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 13C0 10.7909 1.79086 8 4 8H403C405.209 8 407 10.7909 407 13V38C407 40.2091 405.209 42 403 42H4.00001C1.79087 42 0 40.2091 0 38V13Z"
        fill="#00162A"
        fillOpacity="0.05"
        id="box-grey"
      />

      <path d="M0 8H395V42.5H10V8Z" fill="#E8F9E7" id="box-green" />
      <path d={`M0 8H${yellowBoxWidth}V42.5H10V8Z`} fill="#FFF7D9" id="box-yellow" />
      <path
        d={`M0 13C0 10.7909 1.79086 8 4 8H${redBoxWidth}V42H4C1.79086 42 0 40.2091 0 38V13Z`}
        fill="#FAE4E3"
        id="box-red"
      />

      <path d="M394 7L389.67 0.5L398.33 0.500001L394 7Z" fill="#397031" id="chevron-top-green" />
      <path
        fill="#F1C011"
        id="chevron-top-yellow"
        d={`M${yellowChevron + 4.3301} 7L${yellowChevron} 0.5L${yellowChevron + 8.6601} 0.500001L${
          yellowChevron + 4.3301
        } 7Z`}
      />
      <path
        d={`M${redChevron + 4.3301} 7L${redChevron} 0.5L${redChevron + 8.6601} 0.500001L${redChevron + 4.3301} 7Z`}
        fill="#9D2226"
        id="chevron-top-red"
      />

      <rect fill="#F1C011" height="34" id="box-split-yellow" width="4" x={yellowChevron + 2.165} y="8" />
      <rect fill="#9D2226" height="34" id="box-split-red" width="4" x={redChevron + 2.165} y="8" />
      <rect fill="#397031" height="34" id="box-split-green" width="4" x={395 - 2.665} y="8" />

      {predictionKind === MetricPredictionKindT.RisingT && !isOver && (
        <>
          <rect
            fill="white"
            fillOpacity="0.7"
            height="7"
            id="bar-end-prediction-white-box"
            width={predictedValueProgress}
            y="21.5"
          />
          <rect
            height="6"
            id="bar-end-prediction-blue-border"
            stroke="#406FDB"
            strokeOpacity="0.4"
            width={predictedValueProgress}
            x="0.5"
            y="22"
          />
          <rect fill="#406FDB" height="7" id="bar-current-value" width={currentProgress} y="21.5" />
        </>
      )}
      {predictionKind === MetricPredictionKindT.RisingT && isOver && (
        <>
          <rect fill="#9D2226" fillOpacity="0.4" height="7" id="bar-end-prediction-white-box" width="408" y="21.5" />
          <rect
            height="6"
            id="bar-end-prediction-blue-border"
            stroke="#9D2226"
            strokeOpacity="0.4"
            width="407"
            x="0.5"
            y="22"
          />
          <rect fill="#9D2226" height="7" id="bar-current-value" width={currentProgress} y="21.5" />
        </>
      )}
      {predictionKind === MetricPredictionKindT.ConstantT && (
        <circle cx={currentProgressConstant - 1} cy="24" fill="#406FDB" r="8" />
      )}

      <text fill="#A1A1A1" fontSize="9" style={{ textAnchor: "middle" }} x={redBoxWidth + 2.165} y="54">
        {formatByDataType(dataType, currency, lowTarget)}
      </text>
      <text fill="#A1A1A1" fontSize="9" style={{ textAnchor: "middle" }} x={yellowChevron + 4.165} y="54">
        {formatByDataType(dataType, currency, mediumTarget)}
      </text>
      <text fill="#A1A1A1" fontSize="9" style={{ textAnchor: "end" }} x={408} y="54">
        {" "}
        {formatByDataType(dataType, currency, targetValue)}
      </text>
    </svg>
  );
};
