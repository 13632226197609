/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from "react";
import { Controller, FieldPath, FieldPathValue, FieldValues, UseFormSetValue } from "react-hook-form";
import { ShowDimensionMetricPill } from "../../components/showDimensionMetricPill/ShowDimensionMetricPill";
import { ValueCategoryT } from "../../graphql/generated/graphql";
import { useRecentMetDims } from "../../hooks/useRecentMetDims";
import { DropdownFloatingBox } from "../Dropdown/DropdownFloatingBox";
import { useDropdown } from "../Dropdown/useDropdown";
import { Tile } from "../Tile/Tile";
import { FakeInput } from "./FakeInput";
import { InputControllerPropsT } from "./InputController";
import { MetricDimensionSelect } from "./MetricDimensionSelect";
import { SelectableDimensionT, SelectableMetricT } from "./MetricDimensionSelectTabs";

export type MetricDimensionControllerPropsT<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<
  InputControllerPropsT<TFieldValues, TName>,
  "defaultValue" | "collection" | "radioOptions" | "select" | "textarea" | "smartSelect" | "autoCompleteOptions"
> & {
  className?: string;
  defaultValue?: MetricDimensionSelectedItemT;
  dimensions?: SelectableDimensionT[];
  disableClear?: boolean;
  disabledTab?: MetricOrDimensionT | null;
  metrics?: SelectableMetricT[];
  name: TName;
  onSetValue: UseFormSetValue<TFieldValues>;
  placeholder?: string;
};

export type MetricOrDimensionT = "metric" | "dimension" | "";
export type MetricDimensionSelectedItemT = { label?: string; type?: MetricOrDimensionT; value?: string };
export type MetricDimensionSelectedCategoryT = { name: ValueCategoryT; type: MetricOrDimensionT };

export const MetricDimensionController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  dimensions,
  disabledTab,
  id,
  metrics,
  name,
  onSetValue,
  placeholder,
}: MetricDimensionControllerPropsT<TFieldValues, TName>) => {
  const [selectedCategory, setSelectedCategory] = useState<MetricDimensionSelectedCategoryT | null>(null);
  const { boxProps, reference, triggerElementProps } = useDropdown({ hasParentWidth: false, shouldOpenOnFocus: true });
  const tileRef = useRef<HTMLDivElement>(null);

  const { addUsedDimension, addUsedMetric, recentDimensions, recentMetrics } = useRecentMetDims();

  return (
    <div className="w-100" data-test-id="metricDimensionSelect">
      <span {...triggerElementProps} ref={reference}>
        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            let item = undefined;
            if ((field.value as MetricDimensionSelectedItemT)?.type && field.value.type === "metric" && metrics) {
              item = metrics.find((metric) => metric.id === field.value.value);
            }
            if ((field.value as MetricDimensionSelectedItemT)?.type === "dimension" && dimensions) {
              item = dimensions.find((dimension) => dimension.id === field.value.value);
            }

            return (
              <FakeInput
                childrenOffset="3px"
                id={id || ""}
                placeholder={placeholder}
                testId={id}
                onClick={() => {
                  boxProps.onToggle((prev) => !prev);
                }}
              >
                {item && <ShowDimensionMetricPill item={item} />}
              </FakeInput>
            );
          }}
        />
      </span>
      <DropdownFloatingBox {...boxProps} className="d-flex flex-column noWrap" lockScroll={false}>
        <Tile
          ref={tileRef}
          className="w-100 overflow-auto"
          contentStyle={{ paddingTop: 0, paddingBottom: 0, minWidth: "320px" }}
        >
          <MetricDimensionSelect
            dimensions={dimensions}
            disabledTab={disabledTab}
            metrics={metrics}
            recentDimensions={recentDimensions}
            recentMetrics={recentMetrics}
            selectedCategory={selectedCategory}
            onSetSelectedCategory={setSelectedCategory}
            onSetSelectedItem={(selectedValue) => {
              if (selectedValue.value && selectedValue.type === "metric") {
                addUsedMetric(selectedValue.value);
              }
              if (selectedValue.value && selectedValue.type === "dimension") {
                addUsedDimension(selectedValue.value);
              }
              onSetValue(name, selectedValue as FieldPathValue<TFieldValues, TName>);
              boxProps.onToggle(false);
            }}
          />
        </Tile>
      </DropdownFloatingBox>
    </div>
  );
};
