import React, { ChangeEvent, useCallback, useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FieldError, useFieldArray, useForm, useWatch } from "react-hook-form";
import { calculateFieldCounts, createHashMap } from "../../functions/calculateFieldCounts";
import { CsvResultT, exportToCsvDimensionValues, importCSV } from "../../functions/csvHelpers";
import {
  DimensionT,
  MappingMatchT,
  MappingNonmatchHandlingT,
  MappingRuleInputT,
  useDimensionValueCountsQuery,
  useOrganizationDimensionsQuery,
} from "../../graphql/generated/graphql";
import { useHandleServerErrors } from "../../hooks/useHandleServerErrors";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { ButtonPrimary, ButtonSecondary, ButtonSecondaryRed, ButtonTertiary } from "../../ui/Button/Button";
import FormGroup from "../../ui/forms/FormGroup";
import { InputController } from "../../ui/forms/InputController";
import { Col, Row } from "../../ui/grid/Grid";
import { HeadingSmall } from "../../ui/Heading/Heading";
import { Icon } from "../../ui/Icon/Icon";
import { Loader } from "../../ui/Loader/Loader";
import { ModalBody, ModalFooter } from "../../ui/Modal";
import ModalCloseButton from "../../ui/Modal/ModalCloseButton";
import { Pagination, usePagination } from "../../ui/Pagination/Pagination";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { UsedByGraph } from "../../ui/usedByGraph/UsedByGraph";
import { DownloadButton } from "../DownloadButton/DownloadButton";
import { DimensionSelect } from "../formEditWidget/inputs/InputWidgetDimensionsItem";
import { formDimensionSchema } from "./validations";

export type ArrayMappingT = {
  to?: string;
  what?: string;
};

export type FormDimensionInputsT = {
  arrayMapping: ArrayMappingT[];
  description: string;
  mappingRule: MappingRuleInputT;
  name: string;
  referencedDimensionId: string;
};

export type FormDimensionPropsT = {
  defaultValues?: Pick<DimensionT, "name" | "description" | "referencedDimensionId" | "mappingRule"> | null;
  isMutationLoading?: boolean;
  onSubmit: (values: FormDimensionInputsT) => void;
  serverErrors?: string[];
};

const removeEmptyLine = <T extends { to?: string | undefined; what?: string | undefined }>(arrayMapping: T[]) =>
  arrayMapping.filter(({ to, what }) => to !== "" && what !== "");

export const FormDimension = ({ defaultValues, isMutationLoading, onSubmit, serverErrors }: FormDimensionPropsT) => {
  const {
    organization: { externalId },
  } = useAppSettings();
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setError,
    setValue,
    trigger,
  } = useForm<FormDimensionInputsT>({
    resolver: yupResolver(formDimensionSchema),
    defaultValues: {
      name: defaultValues?.name || "",
      description: defaultValues?.description || "",
      referencedDimensionId: defaultValues?.referencedDimensionId || "",
      mappingRule: {
        matchType: defaultValues?.mappingRule?.matchType || MappingMatchT.EqualsT,
        nonmatchHandling: defaultValues?.mappingRule?.nonmatchHandling || MappingNonmatchHandlingT.OriginalValueT,
        nonmatchValue: defaultValues?.mappingRule?.nonmatchValue || "",
      },
      arrayMapping: defaultValues?.mappingRule?.mapping
        ? ((defaultValues?.mappingRule?.mapping || []) as ArrayMappingT[])
        : [{ what: "", to: "" }],
    },
  });

  useHandleServerErrors({ errors: serverErrors, setError, shouldNotify: true });

  const matchType = useWatch({ name: "mappingRule.matchType", control });
  const arrayMapping = useWatch({ name: "arrayMapping", control });
  const referencedDimensionId = useWatch({ name: "referencedDimensionId", control });

  const handleImport = useCallback(
    (csvData: CsvResultT) => {
      const filtredData = csvData.filter(({ to, what }) => (what || "").length > 0 || (to || "").length > 0);
      const clearedArrayMapping = removeEmptyLine(arrayMapping);
      const mappingHash = createHashMap(clearedArrayMapping, "what");

      const newFieldData = filtredData.map((item) => {
        const duplicatedItem = { ...(mappingHash[item.what || ""] || {}) };
        delete mappingHash[item.what || ""];

        return { what: item?.what || duplicatedItem?.what, to: item?.to || duplicatedItem?.to };
      });

      setValue("arrayMapping", [...Object.values(mappingHash), ...newFieldData]);
    },
    [arrayMapping, setValue]
  );

  const handleClearArrayMapping = () => setValue("arrayMapping", [{ what: "", to: "" }]);

  const { append, fields, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "arrayMapping", // unique name for your Field Array
  });

  const [paginationProps, paginatedFields] = usePagination<typeof fields>(fields);

  const { data, loading: dimensionsLoading } = useOrganizationDimensionsQuery({
    variables: {
      organizationExternalId: externalId,
      limit: 100000,
    },
  });

  const { data: valueCountsData, loading: valueCountsLoading } = useDimensionValueCountsQuery({
    variables: {
      organizationExternalId: externalId,
      ids: [referencedDimensionId],
    },
    skip: !referencedDimensionId,
  });

  const foundDimension = valueCountsData?.organization?.dimensions?.nodes.find(
    (dimension) => referencedDimensionId === dimension.id
  );

  const hasValueCountsToShow =
    !valueCountsLoading && foundDimension?.valueCounts && foundDimension?.valueCounts.length > 0;

  const parsedArrayMapping = useMemo(
    () => arrayMapping.map((arrMap, index) => ({ ...arrMap, id: fields[index]?.id })),
    [arrayMapping, fields]
  );

  const fieldCount = useMemo(() => {
    return calculateFieldCounts({
      valuesCount: foundDimension?.valueCounts || [],
      arrayMapping: parsedArrayMapping,
      matchType,
    });
  }, [foundDimension?.valueCounts, matchType, parsedArrayMapping]);

  const handlePrefill = () => {
    if (foundDimension?.valueCounts) {
      const filteredParsedArrayMapping = removeEmptyLine(parsedArrayMapping);
      const activeMappingHash = createHashMap(filteredParsedArrayMapping, "what");

      const filtredData = foundDimension?.valueCounts
        .filter((item) => !activeMappingHash[item.value])
        .map((item) => ({ ...item, what: item.value, to: "" }));

      setValue("arrayMapping", [...filteredParsedArrayMapping, ...filtredData]);
    }
  };

  useEffect(() => {
    trigger("arrayMapping");
  }, [trigger, arrayMapping]);

  return (
    <form id="new-dimension" onSubmit={handleSubmit(onSubmit)}>
      <ModalBody>
        <Tile>
          <div className="w-50 mb-8">
            <InputController
              control={control}
              defaultValue={defaultValues?.name || ""}
              errors={errors}
              id="name"
              input={{ type: "text", placeholder: "Insert name", autoFocus: true }}
              label="Dimension name"
              name="name"
            />
          </div>

          <InputController
            className="mb-8"
            control={control}
            errors={errors}
            id="description"
            label="Description"
            name="description"
            textarea={{ type: "text", placeholder: "Insert Description (Optional)" }}
          />

          <div className="delimiter mv-16" />
          <HeadingSmall margin={16}>Source dimension</HeadingSmall>

          {dimensionsLoading ? (
            <Loader size="medium" />
          ) : (
            <Row alignItems="bottom" flexwrap>
              <Col width="50%">
                <Controller
                  control={control}
                  name="referencedDimensionId"
                  render={({ field, fieldState }) => (
                    <FormGroup error={fieldState.error} label="Source Dimension">
                      <DimensionSelect
                        dimensions={data?.organization?.dimensions?.nodes || []}
                        id="referencedDimensionId"
                        placeholder="Select Source Dimension"
                        value={field.value}
                        hasArrow
                        onChange={(dimId) => setValue("referencedDimensionId", dimId || "", { shouldValidate: true })}
                      />
                    </FormGroup>
                  )}
                />
              </Col>
              <Col>
                {Object.keys(errors).length === 0 && (
                  <Row className="mb-8">
                    <Col>
                      <Icon kind="info" size="14px" />
                    </Col>
                    <Col>
                      <Text color="gray">Changing the source dimension resets remapping values.</Text>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          )}
        </Tile>

        {getValues().referencedDimensionId !== "" && (
          <>
            <Tile className="mt-16">
              <Row className="flex-gap-8 mb-16" justify="between" flexwrap>
                <Col>
                  <HeadingSmall margin={0}>Remapping Settings</HeadingSmall>{" "}
                </Col>
                <Col>
                  <Row className="flex-gap-8">
                    <ButtonSecondary
                      data-test-id="export-to-csv-button"
                      disabled={dimensionsLoading}
                      icon="feed-export"
                      size="small"
                      onClick={() => exportToCsvDimensionValues(arrayMapping)}
                    >
                      Export (CSV)
                    </ButtonSecondary>
                    <DownloadButton
                      disabled={dimensionsLoading}
                      size="small"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => importCSV(event, handleImport)}
                    >
                      Import (CSV)
                    </DownloadButton>
                    <ButtonSecondaryRed
                      data-test-id="clear-all-button"
                      disabled={dimensionsLoading}
                      icon="trash"
                      size="small"
                      onClick={handleClearArrayMapping}
                    >
                      Clear all
                    </ButtonSecondaryRed>
                  </Row>
                </Col>
              </Row>

              <Row alignItems="center" className="mb-16" flexwrap>
                <Col>
                  <Text>Remap content which</Text>
                </Col>
                <Col>
                  <InputController
                    control={control}
                    id="mappingRule_matchType"
                    input={{ type: "radio", kind: "segmented" }}
                    name="mappingRule.matchType"
                    radioOptions={[
                      { label: "Equals", value: MappingMatchT.EqualsT },
                      { label: "Contains", value: MappingMatchT.IncludesT },
                      { label: "Regexp", value: MappingMatchT.RegexpT },
                    ]}
                  />
                </Col>
                <Col>
                  <Text>source values.</Text>
                </Col>
              </Row>

              <>
                {paginatedFields.map((item, paginatedIndex) => {
                  const index = paginationProps.currentPageIndex * paginationProps.pageSize + paginatedIndex;
                  return (
                    <Tile key={item.id} className="mb-8" contentStyle={{ padding: "8px 16px" }}>
                      <Row alignItems="top">
                        <Col className="pt-4" data-test-id="graph">
                          {valueCountsLoading && referencedDimensionId ? (
                            <Loader size="small" />
                          ) : (
                            <UsedByGraph
                              products={fieldCount.resultFields[item?.id] || 0}
                              productsTotal={fieldCount?.totalCount}
                              size={26}
                            />
                          )}
                        </Col>
                        <Col type="grow">
                          <InputController
                            control={control}
                            customError={index === 0 ? ({ ...errors.arrayMapping } as FieldError) : undefined}
                            errors={errors}
                            id={`arrayMapping_${index}_what`}
                            name={`arrayMapping.${index}.what`}
                            placeholder="Enter value"
                            autoCompleteOptions={{
                              variableName: foundDimension?.name,
                              collection: foundDimension?.valueCounts,
                            }}
                          />
                        </Col>
                        <Col className="mt-8" type="shrink">
                          <Icon color="gray" kind="arrow-right" />
                        </Col>
                        <Col type="grow">
                          <InputController
                            control={control}
                            id={`arrayMapping_${index}_to`}
                            input={{ type: "text", placeholder: "New value" }}
                            name={`arrayMapping.${index}.to`}
                          />
                        </Col>
                        <Col type="shrink">
                          <ButtonTertiary icon="trash" onlyIcon onClick={() => remove(index)} />
                        </Col>
                      </Row>
                    </Tile>
                  );
                })}

                <Pagination className="mt-16" testId={`xxx-pag`} isCompact {...paginationProps} />

                <div className="mt-16">
                  <ButtonSecondary
                    icon="plus"
                    size="small"
                    onClick={async () => {
                      const currentPage = paginationProps.currentPageIndex;
                      await append({ what: "", to: "" });
                      paginationProps.onSetPageIndex(currentPage);
                    }}
                  >
                    Add new line
                  </ButtonSecondary>
                  <ButtonSecondary
                    data-test-id="autofill-button"
                    disabled={isMutationLoading || !hasValueCountsToShow}
                    size="small"
                    onClick={handlePrefill}
                  >
                    Prefill all variable values
                  </ButtonSecondary>
                </div>
              </>
            </Tile>

            <Tile className="mt-16">
              <HeadingSmall margin={16}>How to Handle No Match Values</HeadingSmall>
              <InputController
                className="mb-8"
                control={control}
                id="mappingRule_nonmatchHandling"
                label="No Match Values Behaviour"
                name="mappingRule.nonmatchHandling"
                collection={[
                  { label: "Use original value of dimension", value: MappingNonmatchHandlingT.OriginalValueT },
                  {
                    label: "All non matched dimension values will be overwriten",
                    value: MappingNonmatchHandlingT.CustomValueT,
                  },
                ]}
              />

              <Controller
                control={control}
                name="mappingRule.nonmatchHandling"
                render={({ field }) =>
                  field.value === MappingNonmatchHandlingT.CustomValueT ? (
                    <Tile className="mt-16" contentStyle={{ padding: "8px 16px" }}>
                      <Row alignItems="center">
                        <Col>
                          <UsedByGraph
                            products={fieldCount.unused || 0}
                            productsTotal={fieldCount?.totalCount}
                            size={26}
                          />
                        </Col>
                        <Col type="grow">Remap rest of values to</Col>
                        <Col type="grow">
                          <InputController
                            control={control}
                            errors={errors}
                            id="mappingRule_nonmatchValue"
                            name="mappingRule.nonmatchValue"
                          />
                        </Col>
                      </Row>
                    </Tile>
                  ) : (
                    <div />
                  )
                }
              />
            </Tile>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonPrimary
          disabled={isMutationLoading || Object.keys(errors).length > 0}
          loading={isMutationLoading}
          type="submit"
        >
          Save
        </ButtonPrimary>
        <ModalCloseButton />
      </ModalFooter>
    </form>
  );
};
