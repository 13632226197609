import React, { ChangeEvent, FormEvent } from "react";
import Input, { InputT } from "../../ui/forms/Input";

export type SearchInputPropsT = {
  className?: string;
  clearSearch: () => void;
  hasMagnifierIcon?: boolean;
  value: string;
} & (
  | {
      onChange: (event: FormEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => void;
      onChangeDebounced?: never;
    }
  | {
      onChange?: never;
      onChangeDebounced: InputT["onChangeDebounced"];
    }
);

export const SearchInput = ({
  className,
  clearSearch,
  hasMagnifierIcon,
  onChange,
  onChangeDebounced,
  value,
}: SearchInputPropsT) => {
  return (
    <Input
      className={className}
      inputProps={{
        onChange,
        onChangeDebounced,
        value,
        placeholder: "Search",
        isClearable: true,
        isWithSearch: hasMagnifierIcon,
      }}
      onClear={clearSearch}
    />
  );
};
