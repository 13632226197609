import React, { PropsWithChildren } from "react";
import { ButtonPrimary, ButtonSecondary } from "../../ui/Button/Button";
import { Modal, ModalBody, ModalFooter } from "../../ui/Modal";
import { ModalPropsT } from "../../ui/Modal/Modal";
import { UseModalReturnT } from "../../ui/Modal/useModal";
import { FormSection, FormSectionPropsT } from "../formSection/FormSection";

type ModalOptionsT = UseModalReturnT & ModalPropsT;

type SectionModalPropsT = { isCreating?: boolean; modalOptions: ModalOptionsT } & Omit<FormSectionPropsT, "formId">;

export const SectionModal = ({
  defaultValues,
  isCreating,
  modalOptions,
  onSubmit,
}: PropsWithChildren<SectionModalPropsT>) => {
  const formId = "form-add-new-section";
  return (
    <Modal {...modalOptions}>
      <ModalBody>
        <FormSection defaultValues={defaultValues} formId={formId} onSubmit={onSubmit} />
      </ModalBody>
      <ModalFooter>
        <ButtonPrimary form={formId} type="submit">
          {isCreating ? "Create section" : "Save changes"}
        </ButtonPrimary>
        <ButtonSecondary onClick={modalOptions.close}>Cancel</ButtonSecondary>
      </ModalFooter>
    </Modal>
  );
};
