import React from "react";
import { ConditionT, DateRangeT, DimensionOperatorT, MetricOperatorT } from "../../graphql/generated/graphql";
import { ConditionsT } from "../../types/widgets";
import { Col, Row } from "../../ui/grid/Grid";
import { HeadingSection } from "../../ui/Heading/Heading";
import { ConditionsFormT, FormConditions } from "../formConditions/FormConditions";
import { useReportBoardContext } from "./context/reportBoardContext";

type PropsT = {
  conditions: ConditionT[];
  dateRange?: DateRangeT | null;
  onChange?: (formValues: ConditionsT) => void;
  onClose?: () => void;
  onSubmit: (formValues: ConditionsT) => void;
};

export const parseFormConditions = <T extends ConditionsFormT>(formValues: T) =>
  formValues.conditions.map((condition) => ({
    id: condition.id || "",
    groupId: condition.groupId,
    values: [...condition.value],
    ...(condition.unit.type === "metric" && {
      metric: { id: condition.unit.value || "", name: condition.unit.label || "" },
    }),
    ...(condition.unit.type === "metric" && { metricOperator: (condition.operator as MetricOperatorT) || "" }),
    ...(condition.unit.type === "dimension" && {
      dimension: { id: condition.unit.value || "", name: condition.unit.label || "" },
    }),
    ...(condition.unit.type === "dimension" && {
      dimensionOperator: (condition.operator as DimensionOperatorT) || "",
    }),
  }));

export const ReportFilter = ({ conditions, dateRange, onChange, onClose, onSubmit }: PropsT) => {
  const { widgetSettingsData } = useReportBoardContext();

  const handleSubmit = (formValues: ConditionsFormT) => {
    onSubmit(parseFormConditions(formValues));
    if (onClose) {
      onClose();
    }
  };

  const handleChange = (formValues: ConditionsFormT) => {
    if (onChange) {
      onChange(parseFormConditions(formValues));
    }
  };

  return (
    <Col padding="n" style={{ height: "100%" }}>
      <Row alignItems="center" className="pb-16 ph-16 delimiter negative-mr-16 negative-ml-16">
        <HeadingSection className="ml-8" margin={0}>
          Create new filter
        </HeadingSection>

        {/* <ButtonSecondary className="ml-a" form="form-conditions" size="small">
          TODO: Save Filter As
        </ButtonSecondary> */}
      </Row>
      <FormConditions
        conditions={conditions}
        dateRange={dateRange}
        widgetSettingsData={widgetSettingsData}
        onChange={handleChange}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </Col>
  );
};
