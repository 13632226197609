import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { withApiStateHandler } from "../components/ErrorLoadingWrapper/withApiStateHandler";
import { FormCustomMetric } from "../components/formCustomMetric/FormCustomMetric";
import { DUPLICATED_PREFIX } from "../constants/report";
import { ROUTES } from "../constants/routes";
import { notifyError, notifySucces } from "../functions/toast";
import {
  CustomMetricQueryT,
  CustomMetricsDocumentT,
  useCreateCustomMetricMutation,
  useCustomMetricQuery,
} from "../graphql/generated/graphql";
import { useAppSettings } from "../providers/appSettingsProvider";
import { RouteParamsT } from "../types/router";
import { Modal } from "../ui/Modal";

type PropsT = {
  data?: CustomMetricQueryT;
  onClose: () => void;
  organizationExternalId: string;
};

const MetricDuplicatePageComponent = withApiStateHandler(({ data, onClose, organizationExternalId }: PropsT) => {
  const [serverErrors, setServerErrors] = useState<string[] | undefined>(undefined);

  const [mutate, { loading }] = useCreateCustomMetricMutation({
    refetchQueries: [CustomMetricsDocumentT],
    onCompleted: (res) => {
      const errors = res.organization?.createCustomMetric?.errors;

      if (errors?.length) {
        return setServerErrors(errors);
      }
      notifySucces(<>{`Custom metric duplicated!`}</>);
      onClose();
    },
    onError: () => notifyError(<>{`Server error when creating custom metric!`}</>),
  });

  const customMetric = data?.organization?.customMetric;

  return (
    <FormCustomMetric
      defaultValues={customMetric ? { ...customMetric, name: `${DUPLICATED_PREFIX} ${customMetric?.name}` } : undefined}
      isMutationLoading={loading}
      serverErrors={serverErrors}
      onSubmit={(values) =>
        mutate({
          variables: {
            ...values,
            organizationExternalId,
          },
        })
      }
    />
  );
});

type ModalPropsT = { metricId?: string } & Pick<PropsT, "onClose">;

export const MetricDuplicateModal = ({ metricId, onClose }: ModalPropsT) => {
  const { organization } = useAppSettings();

  const response = useCustomMetricQuery({
    fetchPolicy: "network-only",
    variables: {
      organizationExternalId: organization.externalId,
      customMetricId: metricId || "",
    },
    skip: !metricId,
  });
  return (
    <Modal close={onClose} heading="Duplicate custom metric" testId="duplicate-custom-metric-modal" isOpen>
      <MetricDuplicatePageComponent
        {...response}
        organizationExternalId={organization.externalId}
        wrapWithLayout
        onClose={onClose}
      />
    </Modal>
  );
};
export const MetricDuplicatePage = () => {
  const { metricId } = useParams<RouteParamsT["metric"]>();
  const navigate = useNavigate();

  return <MetricDuplicateModal metricId={metricId} onClose={() => navigate(ROUTES.metricsAndDimensions())} />;
};
