import React, { PropsWithChildren } from "react";
import cs from "classnames";
import { useModalContext } from "./modalContext";

type PropsT = { hasBorder?: boolean };

function ModalFooter({ children, hasBorder }: PropsWithChildren<PropsT>) {
  const {} = useModalContext();

  return (
    <div
      className={cs("Modal-footer", { "Modal-footer--withBorder": hasBorder })}
      data-test-id="confirmation-modal-footer"
    >
      {children}
    </div>
  );
}

export default ModalFooter;
