import React from "react";
import { notifyError, notifySucces } from "../../functions/toast";
import { useFetchCurrentCampaignsDataMutation } from "../../graphql/generated/graphql";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { Button, ButtonPropsT } from "../../ui/Button/Button";
import { useReportBoardContext } from "../reportBoard/context/reportBoardContext";

type PropsT = {
  buttonProps?: ButtonPropsT;
};

const ReloadReportCampaignData = ({ buttonProps }: PropsT) => {
  const { organization } = useAppSettings();
  const { report } = useReportBoardContext();

  const [mutate, { loading }] = useFetchCurrentCampaignsDataMutation({
    onCompleted: (data) => {
      const errors = data.organization?.fetchCurrentCampaignsData?.errors;

      if (errors?.length) {
        return notifyError(<>{errors.join("\n")}</>);
      }

      notifySucces(<>All campaign data updated</>);
    },
    onError: () => {
      notifyError(<>"Update failed"</>);
    },
  });

  const handleSend = () => {
    mutate({
      variables: {
        organizationExternalId: organization.externalId,
        reportId: report.id,
      },
    });
  };

  return (
    <Button disabled={loading} loading={loading} variant="secondary" onClick={handleSend} {...buttonProps}>
      Reload campaign data
    </Button>
  );
};

export default ReloadReportCampaignData;
