import React, { useCallback } from "react";
import { ConditionsT } from "../../types/widgets";
import { Col, Row } from "../../ui/grid/Grid";
import { Modal, ModalBody, useModal } from "../../ui/Modal";
import { Tile } from "../../ui/Tile/Tile";
import { useReportBoardContext } from "./context/reportBoardContext";
import { PageContextT } from "./ReportBoardPage";
import { ReportFilter } from "./ReportFilter";

type PropsT = {
  pageContext: PageContextT;
};

export const ReportBoardEditFilters = ({ pageContext }: PropsT) => {
  const { isReportFilterOpen, reportFilter, reportFilterClose, reportFilterUpdate } = useReportBoardContext();
  const { editedFilterSectionId, editedSection, sectionFilterClose, sectionUpdate } = pageContext;
  const modalOptions = useModal({ isInitialOpen: true });

  const handleClose = useCallback(() => {
    sectionFilterClose();
    reportFilterClose();
  }, [sectionFilterClose, reportFilterClose]);

  const handleUpdateSectionConditions = useCallback(
    (conditions: ConditionsT) => {
      if (editedSection) {
        sectionUpdate({ sectionId: editedSection.id, sectionInputData: (prev) => ({ ...prev, conditions }) });
        handleClose();
      }
    },
    [editedSection, sectionUpdate, handleClose]
  );

  const handleUpdateReportConditions = useCallback(
    (conditions: ConditionsT) => {
      reportFilterUpdate({ conditions });
      handleClose();
    },
    [reportFilterUpdate, handleClose]
  );

  return (
    <Modal
      heading={editedFilterSectionId ? "Section filter" : "Report filter"}
      size="rightSide"
      testId="edit-widget-modal"
      {...{ ...modalOptions, close: handleClose }}
    >
      <ModalBody>
        <Row height="100%">
          <Col>
            <Tile
              border="onlyLeftBorder"
              style={{
                width: "488px",
                overflowY: "auto",
                paddingBottom: "72px",
              }}
              isFullHeight
              isSquare
            >
              {editedFilterSectionId && editedSection && (
                <ReportFilter
                  conditions={editedSection.conditions}
                  dateRange={pageContext.editedSection?.dateRange}
                  onClose={handleClose}
                  onSubmit={handleUpdateSectionConditions}
                />
              )}
              {isReportFilterOpen && (
                <ReportFilter
                  conditions={reportFilter.conditions}
                  onClose={handleClose}
                  onSubmit={handleUpdateReportConditions}
                />
              )}
            </Tile>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
