import React, { useMemo } from "react";
import { WidgetDataFragmentT } from "../../graphql/generated/graphql";
import { useGetWidgetComponentData } from "../../hooks/useGetWidgetComponentData";
import { ReportFilterT } from "../../types/report";
import { WidgetT } from "../../types/widgets";
import { Loader } from "../../ui/Loader/Loader";
import { withApiStateHandler } from "../ErrorLoadingWrapper/withApiStateHandler";
import { WidgetTableComponentContent } from "./TableComponentContent";

type PropsT = {
  filterValues: ReportFilterT;
  loading: boolean;
  originalWidgetData?: WidgetDataFragmentT;
  widget: WidgetT;
  widgetData?: WidgetDataFragmentT;
};

export const WidgetRawDataTableComponent = withApiStateHandler(({ loading, widget, widgetData, ...rest }: PropsT) => {
  if (loading) {
    return <Loader size="big" />;
  }

  return <WidgetTableComponentContent widget={widget} widgetData={widgetData} isRawDataPreview {...rest} />;
});

export const WidgetRawDataTable = ({
  filterValues,
  originalWidgetData,
  widget: originalWidget,
  ...rest
}: Omit<PropsT, "loading">) => {
  const hasMaxRows = !!originalWidget.maxRows;

  const widgetRaw = useMemo(
    () => ({
      ...originalWidget,
      maxRows: null,
      campaignDataColumns: [],
    }),
    [originalWidget]
  );

  const result = useGetWidgetComponentData({
    filterValues,
    widget: widgetRaw,
    onWidgetDataChange: null,
    skip: !hasMaxRows,
  });

  return (
    <WidgetRawDataTableComponent
      {...rest}
      {...result}
      filterValues={filterValues}
      widget={widgetRaw}
      widgetData={hasMaxRows ? result.widgetData : originalWidgetData}
    />
  );
};
