import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { notifyError, notifySucces } from "../functions/toast";
import {
  CustomValueMetricsDocumentT,
  useCustomValueMetricQuery,
  useUpdateCustomValueMetricMutation,
} from "../graphql/generated/graphql";
import { useAppSettings } from "../providers/appSettingsProvider";
import { RouteParamsT } from "../types/router";
import { Modal } from "../ui/Modal";
import ValueMetricEditModalContent from "./ValueMetricEditModalContent";

export const ValueMetricEditModal = ({ onClose, valueMetricId }: { onClose: () => void; valueMetricId?: string }) => {
  const [serverErrors, setServerErrors] = useState<string[] | undefined>(undefined);

  const {
    organization: { externalId },
  } = useAppSettings();

  const { data, loading } = useCustomValueMetricQuery({
    fetchPolicy: "network-only",
    variables: {
      organizationExternalId: externalId,
      customValueMetricId: valueMetricId || "",
    },
    skip: !valueMetricId,
  });

  const [mutate, { loading: mutationLoading }] = useUpdateCustomValueMetricMutation({
    refetchQueries: [CustomValueMetricsDocumentT],
    onCompleted: (res) => {
      const errors = res.organization?.updateCustomValueMetric?.errors;

      if (errors?.length) {
        return setServerErrors(errors);
      }
      notifySucces(<>{`Custom value metric updated!`}</>);
      onClose();
    },
    onError: () => notifyError(<>{`Server error when updating custom value metric!`}</>),
  });

  return (
    <Modal close={onClose} heading="Edit custom value metric" testId="edit-custom-value-metric-modal" isOpen>
      <ValueMetricEditModalContent
        data={data}
        isModalLoading={loading}
        isMutationLoading={mutationLoading}
        serverErrors={serverErrors}
        onSubmit={(values) => {
          return mutate({
            variables: {
              ...values,
              id: valueMetricId || "",
              organizationExternalId: externalId,
            },
          });
        }}
      />
    </Modal>
  );
};
export const ValueMetricEditPage = () => {
  const { valueMetricId } = useParams<RouteParamsT["valueMetric"]>();
  const navigate = useNavigate();

  return <ValueMetricEditModal valueMetricId={valueMetricId} onClose={() => navigate(ROUTES.metricsAndDimensions())} />;
};
