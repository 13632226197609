/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import cs from "classnames";
import { Row } from "../grid/Grid";
import { Link } from "../Link/Link";
import { Text } from "../Text/Text";

type PropsT = {
  children: ReactNode;
  className?: string;
  dataTestId?: string;
  dependencies?: any[];
  lines?: number;
};

export const ClampedText = ({ children, className, dataTestId, dependencies, lines = 2 }: PropsT) => {
  const descriptionRef = useRef<HTMLDivElement | null>(null);
  const [showMore, setShowMore] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const customDependencies = useMemo(() => dependencies || [], [dependencies]);

  useEffect(() => {
    setShowMore(false);
    if (descriptionRef.current) {
      setShowLink(descriptionRef.current.clientHeight < descriptionRef.current.scrollHeight);
    }
  }, [descriptionRef, customDependencies]);

  return (
    <Row className={cs("mt-4 mb-0 pos-relative", className, !showMore && `line-clamp-${lines}`)} flexwrap>
      <Text ref={descriptionRef} className="clamped-text" color="gray" data-test-id={dataTestId}>
        {children}
      </Text>
      {showLink && (
        <Text
          className={cs("background-white", { "pos-absolute": !showMore })}
          color="gray"
          style={{ bottom: 0, right: 0 }}
        >
          {!showMore && <span className="pr-4">...</span>}
          <Link onClick={() => setShowMore(!showMore)}>{showMore ? "Show less" : "Show more"}</Link>
        </Text>
      )}
    </Row>
  );
};
