import { useCallback, useState } from "react";
import { AliasNameT, Maybe } from "../../graphql/generated/graphql";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { Col, Row } from "../../ui/grid/Grid";
import { ShowDimensionMetricPillItemT } from "../showDimensionMetricPill/ShowDimensionMetricPill";
import { AliasButtonCreate, AliasButtonDelete, AliasButtonUpdate, RowItemT } from "./AliasMutateButtons";
import { AliasTablePropsT } from "./AliasTable";

export const AliasTableCell = ({
  refetchQuery,
  rowItem,
  type,
}: {
  refetchQuery: AliasTablePropsT["refetch"];
  rowItem: ShowDimensionMetricPillItemT & { aliasName?: Maybe<AliasNameT> };
  type: AliasTablePropsT["type"];
}) => {
  const {
    organization: { externalId },
  } = useAppSettings();

  const [isAnyMutationInProgress, setIsAnyMutationInProgress] = useState(false);

  const handleSetIsAnyMutationInProgress = useCallback((isInProgress: boolean) => {
    setIsAnyMutationInProgress(isInProgress);
  }, []);

  if (!rowItem.aliasName?.value) {
    return <AliasButtonCreate externalId={externalId} refetchQuery={refetchQuery} rowItem={rowItem} type={type} />;
  }

  return (
    <Row alignItems="center">
      <Col>{rowItem.aliasName.value}</Col>
      <Col className="ShowOnHoverElement">
        <AliasButtonUpdate
          externalId={externalId}
          isForceLoading={isAnyMutationInProgress}
          rowItem={rowItem as RowItemT}
          onMutationInProgressChange={handleSetIsAnyMutationInProgress}
        />
      </Col>
      <Col className="ShowOnHoverElement ml-s">
        <AliasButtonDelete
          externalId={externalId}
          isForceLoading={isAnyMutationInProgress}
          refetchQuery={refetchQuery}
          rowItem={rowItem as RowItemT}
          onMutationInProgressChange={handleSetIsAnyMutationInProgress}
        />
      </Col>
    </Row>
  );
};
