import React, { ReactElement, useMemo, useState } from "react";
import { FieldError, RefCallBack } from "react-hook-form";
import { createHashMap } from "../../../functions/calculateFieldCounts";
import { MetricT, WidgetMetricT, useReportMetricsLazyQueryT } from "../../../graphql/generated/graphql";
import { useRecentMetDims } from "../../../hooks/useRecentMetDims";
import { CustomMetricNewModal } from "../../../pages/MetricNew";
import { useAppSettings } from "../../../providers/appSettingsProvider";
import { WidgetT } from "../../../types/widgets";
import { ButtonSecondary } from "../../../ui/Button/Button";
import { DropdownFloatingBox } from "../../../ui/Dropdown/DropdownFloatingBox";
import { useDropdown } from "../../../ui/Dropdown/useDropdown";
import { FakeInput } from "../../../ui/forms/FakeInput";
import { MetricDimensionSelectedCategoryT } from "../../../ui/forms/MetricDimensionController";
import { MetricDimensionSelect } from "../../../ui/forms/MetricDimensionSelect";
import { Tile } from "../../../ui/Tile/Tile";
import { useReportBoardContext } from "../../reportBoard/context/reportBoardContext";
import { ReportMetricT } from "../../reportBoard/context/types";
import { ShowDimensionMetricPill } from "../../showDimensionMetricPill/ShowDimensionMetricPill";

type InputValueT = WidgetT["widgetMetrics"][number];

type PropsT = {
  formKey: string;
  index: number;
  isDefaultOpen: boolean;
  isDisabled: boolean;
  label?: string;
  onChange: ({ index, newMetric }: { index: number; newMetric: InputValueT }) => void;
  pathString: string;
  value: WidgetMetricT;
};

type MetricSelectPropsT = {
  children?: ReactElement;
  error?: FieldError;
  hasArrow?: boolean;
  id: string;
  inputProps?: {
    ref: RefCallBack;
  };
  isDefaultOpen?: boolean;
  isDisabled?: boolean;
  isDisabledNotConnectedWarning?: boolean;
  label?: string;
  metrics: ReportMetricT[];
  onChange: (dimensionId: MetricT["id"] | null) => void;
  onCreate?: () => Promise<void>;
  placeholder?: string;
  value: MetricT["id"];
};

export const MetricSelect = ({
  children,
  error,
  hasArrow,
  id,
  inputProps,
  isDefaultOpen,
  isDisabled,
  isDisabledNotConnectedWarning,
  label,
  metrics,
  onChange,
  onCreate,
  placeholder,
  value,
}: MetricSelectPropsT) => {
  const [selectedCategory, setSelectedCategory] = useState<MetricDimensionSelectedCategoryT | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { addUsedMetric, recentMetrics } = useRecentMetDims();

  const metricsById = useMemo(() => createHashMap(metrics, "id"), [metrics]);

  const { boxProps, reference, triggerElementProps } = useDropdown({
    hasParentWidth: true,
    isDefaultOpen: isDefaultOpen,
  });

  const handleChange = (newValue: MetricT["id"] | null) => {
    boxProps.onToggle(false);
    onChange(newValue || null);
    addUsedMetric(newValue);
  };

  const handleCreate = async (newMetricId: string) => {
    if (onCreate) {
      await onCreate();
      handleChange(newMetricId);
      setIsModalOpen(false);
    }
  };

  return (
    <div className="position-relative w-100" data-test-id="metricDimensionSelect">
      <div>
        <div {...triggerElementProps} ref={reference}>
          <FakeInput
            childrenOffset="3px"
            error={error}
            hasArrow={hasArrow}
            id={id}
            inputProps={inputProps}
            isDisabled={isDisabled}
            label={label}
            placeholder={placeholder}
            testId={id}
            onClick={() => {
              boxProps.onToggle((prev) => !prev);
            }}
          >
            {metricsById[value] && <ShowDimensionMetricPill item={metricsById[value]} />}
          </FakeInput>
        </div>
        <DropdownFloatingBox {...boxProps} className="d-flex flex-column noWrap" lockScroll={false}>
          <Tile
            className="w-100 overflow-auto"
            contentStyle={{ paddingTop: 0, paddingBottom: 0 }}
            style={{ minWidth: "300px" }}
          >
            <MetricDimensionSelect
              disabledTab="dimension"
              isDisabledNotConnectedWarning={isDisabledNotConnectedWarning}
              metrics={metrics}
              recentMetrics={recentMetrics}
              selectedCategory={selectedCategory}
              onSetSelectedCategory={setSelectedCategory}
              onSetSelectedItem={(selectedMetric) => handleChange(selectedMetric.value || null)}
            />
            {onCreate && (
              <ButtonSecondary
                className="mb-8"
                icon="plus"
                block
                onClick={() => {
                  setIsModalOpen(true);
                  boxProps.onToggle(false);
                }}
              >
                Create New Custom Metric
              </ButtonSecondary>
            )}
          </Tile>
        </DropdownFloatingBox>

        {isModalOpen && onCreate && (
          <CustomMetricNewModal
            onCreate={handleCreate}
            onClose={() => {
              setIsModalOpen(false);
              boxProps.onToggle(true);
            }}
          />
        )}
      </div>
      {children}
    </div>
  );
};

export const InputWidgetMetricsItem = ({
  formKey,
  index,
  isDefaultOpen,
  isDisabled,
  onChange,
  pathString,
  value,
}: PropsT) => {
  const {
    dimensionsMetricsHydrateFromApi,
    report,
    widgetSettingsData: { metrics },
  } = useReportBoardContext();

  const { organization } = useAppSettings();

  const [getMetrics] = useReportMetricsLazyQueryT({
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      dimensionsMetricsHydrateFromApi({ metrics: response.organization?.report?.metrics.nodes });
    },
    variables: { reportId: report.id, organizationExternalId: organization.externalId },
  });

  const handleCreate = async () => {
    await getMetrics();
  };

  return (
    <MetricSelect
      id={`${formKey}-${pathString}`}
      isDefaultOpen={isDefaultOpen}
      isDisabled={isDisabled}
      metrics={metrics}
      value={value?.metricId}
      onChange={(id) => onChange({ index, newMetric: { ...value, metricId: id || "" } })}
      onCreate={handleCreate}
    />
  );
};
