import { MutableRefObject, RefObject, useEffect, useRef, useState } from "react";

export const useElementDimensions = (elRef: RefObject<HTMLElement>) => {
  const [dimensions, setDimensions] = useState({
    width: elRef.current?.clientWidth,
    height: elRef.current?.clientHeight,
  });

  const observer = useRef(
    new ResizeObserver((entries) => {
      const contentBoxSize = Array.isArray(entries[0].contentBoxSize)
        ? entries[0].contentBoxSize[0]
        : entries[0].contentBoxSize;
      setDimensions({ width: contentBoxSize.inlineSize, height: contentBoxSize.blockSize });
    })
  );

  useEffect(() => {
    let observerRefValue: RefObject<HTMLElement>["current"] = null;
    let observerValue: MutableRefObject<ResizeObserver>["current"] | null = null;
    if (elRef.current) {
      observer.current.observe(elRef.current);
      observerRefValue = elRef.current;
      observerValue = observer.current;
    }

    return () => {
      if (observerRefValue && observerValue) {
        observerValue.unobserve(observerRefValue);
      }
    };
  }, [elRef, observer]);

  return dimensions;
};
