import React, { PropsWithChildren } from "react";
import { SystemNameT } from "../../graphql/generated/graphql";
import ModalHeader from "./ModalHeader";

type PropsT = {
  hasHeaderBorder?: boolean;
  heading?: string | React.ReactNode;
  icon?: string;
  systemIcon?: SystemNameT | null;
};

function ModalContent({ children, hasHeaderBorder, heading, icon, systemIcon }: PropsWithChildren<PropsT>) {
  return (
    <div className="Modal-content">
      <ModalHeader hasBorder={hasHeaderBorder} heading={heading} icon={icon} systemIcon={systemIcon} />
      {children}
    </div>
  );
}

export default ModalContent;
