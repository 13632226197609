import React from "react";
import image from "../../assets/chain_segment.png";
import { ButtonPrimary } from "../../ui/Button/Button";
import { EmptyState } from "../../ui/Table/EmptyState";

type PropsT = {
  onShowForm: () => void;
};

export const ReportSharingEmpty = ({ onShowForm }: PropsT) => {
  return (
    <EmptyState
      description="Start with create new shareable link. With links you can specify pages you want to share, see engagements, enable or disable sharing and add your custom name for link."
      heading="You don't have any link yet"
      image={image}
      style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "35em" }}
    >
      <div className="mt-16">
        <ButtonPrimary data-test-id="button-create-new-link" icon="plus" onClick={onShowForm}>
          Create new link
        </ButtonPrimary>
      </div>
    </EmptyState>
  );
};
