import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import { useToggleClassOnMount } from "../../hooks/useToggleClassOnMount";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { SidebarMenuComponent, SidebarMenuComponentPropsT } from "./SidebarMenuComponent";

const event = new CustomEvent("onSidebarToggle");

export const SidebarMenu = ({ collection }: SidebarMenuComponentPropsT) => {
  const {
    layoutSelectors: { sidebarContainer, sidebarMainNavigation },
  } = useAppSettings();

  const callback = useCallback(() => {
    window.requestAnimationFrame(() => window.dispatchEvent(event));
  }, []);

  const container = document.querySelector(sidebarContainer);
  useToggleClassOnMount(sidebarMainNavigation, "d-none", { callback });

  if (!container) {
    return null;
  }

  return ReactDOM.createPortal(<SidebarMenuComponent collection={collection} />, container);
};
