import React from "react";
import { DataConsistencyT } from "../../graphql/generated/graphql";
import { Badge, BadgeKindT } from "../../ui/Badge/Badge";

const VARIANTS = {
  [DataConsistencyT.ErrorsT]: {
    kind: "red" as BadgeKindT,
    tooltipContent: "Error while downloading data from systems.",
    children: "Error",
    testId: "data-consistency--error",
  },
  [DataConsistencyT.IncompleteT]: {
    tooltipContent: "Unable to load all data from systems.",
    children: "Incomplete",
    testId: "data-consistency--incomplete",
  },
  [DataConsistencyT.InProgressT]: {
    tooltipContent: "We find out that some data are missing and we are fetching them from systems.",
    children: "Updating",
    testId: "data-consistency--updating",
  },
  [DataConsistencyT.FakeDataT]: {
    children: "Sample Data",
    testId: "data-consistency--sampleData",
  },
  [DataConsistencyT.UndefinedT]: {
    kind: "red" as BadgeKindT,
    tooltipContent:
      "Unexpected error while downloading data from systems. Please try to refresh page If the error persists, please contact us and we will solve the problem ASAP.",
    children: "Unexpected Error",
    testId: "data-consistency--unexpectedError",
  },
  [DataConsistencyT.NoDataYetT]: {
    kind: "orange" as BadgeKindT,
    tooltipContent: "Data for today will be available tomorrow",
    children: "No data yet, come back tommorow",
    testId: "data-consistency--no-data-yet",
  },
};

export const DataConsistencyBadge = ({ dataConsistency }: { dataConsistency?: DataConsistencyT }) =>
  !dataConsistency || dataConsistency === DataConsistencyT.OkT ? null : (
    <Badge className="ml-8" size="extraLarge" {...VARIANTS[dataConsistency]} />
  );
