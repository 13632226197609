import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DUPLICATED_PREFIX } from "../../constants/report";
import { ROUTES } from "../../constants/routes";
import { PageT } from "../../graphql/generated/graphql";
import { t } from "../../i18n/translation";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { ButtonPrimary, ButtonSecondary } from "../../ui/Button/Button";
import { ButtonGroup } from "../../ui/ButtonGroup/ButtonGroup";
import { DropdownMenu } from "../../ui/Dropdown/DropdownMenu";
import { Row } from "../../ui/grid/Grid";
import { Icon } from "../../ui/Icon/Icon";
import { Tooltip } from "../../ui/Tooltip/Tooltip";
import { useConfirmationContext } from "../confirmationDialog/confirmationDialogContext";
import { shouldUseCopyPrefix } from "./context/functions";
import { PageAddT, PageDeleteT, PageDuplicateT, PageUpdateT, ReportBoardPageT, ReportResetT } from "./context/types";

const ItemIcon = ({ page }: { page: Pick<PageT, "shared"> }) => {
  if (!page.shared) {
    return null;
  }

  return (
    <Tooltip enterDelay={1500} tooltipContent="Shared">
      <Icon kind="share" />
    </Tooltip>
  );
};

type PropsT = {
  activePageId?: string | null;
  canEdit: boolean;
  hasReportUnsavedChanges: boolean;
  onPageAdd: PageAddT;
  onPageDelete: PageDeleteT;
  onPageDuplicate: PageDuplicateT;
  onPageUpdate: PageUpdateT;
  onReportReset: ReportResetT;
  pages: Pick<PageT, "id" | "name" | "uuid" | "shared">[];
  reportId: string;
};

export const ReportPagesMenu = ({
  activePageId,
  canEdit,
  hasReportUnsavedChanges,
  onPageAdd,
  onPageDelete,
  onPageDuplicate,
  onPageUpdate,
  onReportReset,
  pages,
  reportId,
}: PropsT) => {
  const { appPathname } = useAppSettings();
  const { callWithConfirmation } = useConfirmationContext();
  const navigate = useNavigate();

  const redirectToNewPage = useCallback(
    (newPage: { id: string; uuid: string }) => {
      navigate(ROUTES.reportPage(reportId, newPage.uuid));
    },
    [reportId, navigate]
  );

  const handleAddPage = useCallback(() => {
    callWithConfirmation(
      (name) => {
        if (name) {
          onPageAdd(name, { callback: redirectToNewPage });
        }
      },
      {
        inputProps: {
          isRequired: true,
          label: "Page name",
        },
        title: "Create new page",
        description: "Add a proper name of the new page. You can rename it later.",
        confirmButtonProps: { text: "Create" },
      }
    );
  }, [onPageAdd, callWithConfirmation, redirectToNewPage]);

  const handleDuplicatePage = useCallback(
    (page: Pick<ReportBoardPageT, "id" | "name" | "uuid">) => {
      callWithConfirmation(
        (name) => {
          if (name) {
            onPageDuplicate({ pageId: page.id, newName: name }, { callback: redirectToNewPage });
          }
        },
        {
          inputProps: {
            isRequired: true,
            defaultValue: `${shouldUseCopyPrefix(page.name || "") ? `${DUPLICATED_PREFIX} ` : ""}${page.name || ""}`,
            label: "Page name",
            canBeValueNotChanged: true,
          },
          title: "Duplicate Page",
          description:
            "Duplicating this page  will create an identical copy of it, including all associated data. Please make sure to give the new item a unique and descriptive name below to avoid confusion.",
          confirmButtonProps: { text: "Duplicate" },
        }
      );
    },
    [onPageDuplicate, callWithConfirmation, redirectToNewPage]
  );

  const handleRenamePage = useCallback(
    (page: Pick<ReportBoardPageT, "id" | "name">) => {
      callWithConfirmation(
        (name) => {
          if (name) {
            onPageUpdate({ pageId: page.id, pageInputData: { name } });
          }
        },
        {
          inputProps: {
            isRequired: true,
            defaultValue: page.name || "",
            label: "Page name",
          },
          title: "Rename page",
          description: "Please make sure to give the page a unique and descriptive name below to avoid confusion.",
          confirmButtonProps: { text: "Rename" },
        }
      );
    },
    [onPageUpdate, callWithConfirmation]
  );

  const handleDeletePage = useCallback(
    (pageId: string) => {
      callWithConfirmation(
        () => {
          onPageDelete(pageId);
        },
        {
          title: "Delete Page",
          description:
            "Deleting this page will permanently remove it from the report. Are you sure to proceed the deletion.",
          confirmButtonProps: { text: "Delete", variant: "red" },
        }
      );
    },
    [onPageDelete, callWithConfirmation]
  );

  const goToNewPage = (pageId: string) => {
    const newLocation = `${window.location.pathname.replace(appPathname, "/")}?page=${pageId || ""}`;
    navigate(newLocation);
  };

  const handleChangePage = (pageId: string) => {
    if (hasReportUnsavedChanges) {
      callWithConfirmation(
        () => {
          onReportReset();
          goToNewPage(pageId);
        },
        {
          title: t("confirmationModal.unsavedChanges.heading"),
          description: t("confirmationModal.unsavedChanges.description"),
          confirmButtonProps: {
            text: t("confirmationModal.unsavedChanges.confirmButtonText"),
            variant: "primary",
          },
        }
      );
    } else {
      goToNewPage(pageId);
    }
  };

  const isOnlyOne = pages.length === 1;
  const getPageMenu = useCallback(
    (page: Pick<ReportBoardPageT, "id" | "name">) => [
      {
        value: "Rename",
        icon: "edit",
        onClick: () => handleRenamePage(page),
      },
      {
        value: "Duplicate",
        icon: "duplicate",
        onClick: () => handleDuplicatePage(page),
      },
      {
        value: "Delete",
        color: "danger" as const,
        icon: "trash",
        isDisabled: isOnlyOne,
        onClick: () => handleDeletePage(page.id),
      },
    ],
    [handleDuplicatePage, handleRenamePage, handleDeletePage, isOnlyOne]
  );

  return (
    <div>
      <Row alignItems="top" className="mb-16" gap={8} flexwrap>
        {pages.map((page) => {
          const isActive = activePageId === page.uuid;
          return (
            <ButtonGroup key={page.id}>
              {isActive ? (
                <ButtonPrimary data-test-id="page-button">
                  {page.name} <ItemIcon page={page} />
                </ButtonPrimary>
              ) : (
                <ButtonSecondary data-test-id="page-button" onClick={() => handleChangePage(page?.uuid || "")}>
                  {page.name} <ItemIcon page={page} />
                </ButtonSecondary>
              )}
              {canEdit && (
                <DropdownMenu
                  buttonProps={{ variant: isActive ? "primary" : "secondary" }}
                  collection={getPageMenu(page)}
                  testId={`page-menu-btn-${page.name}`}
                />
              )}
            </ButtonGroup>
          );
        })}
        {canEdit && <ButtonSecondary data-test-id="add-page-button" icon="plus" onlyIcon onClick={handleAddPage} />}
      </Row>
    </div>
  );
};
