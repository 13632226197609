import React, { PropsWithChildren } from "react";
import { ReportSharingConnected } from "../../componentsConnected/reportSharing/ReportSharing";
import { ReportPageQueryResultT } from "../../graphql/generated/graphql";
import { Col, Row } from "../../ui/grid/Grid";
import { Modal, ModalBody } from "../../ui/Modal";
import { ModalPropsT } from "../../ui/Modal/Modal";
import { UseModalReturnT } from "../../ui/Modal/useModal";
import { Tile } from "../../ui/Tile/Tile";
import { ReportSharingPropsT } from "../reportSharing/ReportSharing";

type ModalOptionsT = UseModalReturnT & ModalPropsT;

type PropsT = Pick<ReportSharingPropsT, "pages" | "reportName"> & {
  modalOptions: ModalOptionsT;
  reportId: string;
  reportRefetch: ReportPageQueryResultT["refetch"];
};

export const SharingModal = ({ modalOptions, ...rest }: PropsWithChildren<PropsT>) => {
  return (
    <Modal {...modalOptions} heading="Share Report Management" size="rightSide">
      <ModalBody>
        <Row height="100%">
          <Col overflow>
            <Tile border="withoutBorder" style={{ width: "600px", overflowY: "auto", minHeight: "100%" }} isSquare>
              <ReportSharingConnected {...rest} />
            </Tile>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
