import React, { HTMLProps, PropsWithChildren } from "react";
import cs from "classnames";
import { useTabsContext } from "./TabsContext";

type PropsT = PropsWithChildren<{ tabId: string }> & HTMLProps<HTMLDivElement>;

export const TabPanel = ({ children, className, tabId }: PropsT) => {
  const { selectedTab, testId } = useTabsContext();

  const isSelected = selectedTab === tabId;

  return (
    <div
      aria-hidden={!isSelected}
      aria-labelledby={`tab-${tabId}`}
      className={cs(className, { "d-none": !isSelected })}
      data-test-id={`${testId}-TabPanel-${tabId}`}
      id={`panel-${tabId}`}
      role="tabpanel"
      tabIndex={isSelected ? 0 : undefined}
    >
      {children}
    </div>
  );
};
