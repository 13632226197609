import { array, object, string } from "yup";
import { VALIDATIONS } from "../../constants/validations";

export const formDimensionSchema = object({
  name: string().required(VALIDATIONS.required),
  referencedDimensionId: string().required(VALIDATIONS.required),
  arrayMapping: array().test("required", "At least one enter variable is required.", (rows = []) => {
    const hasAtLeastOne = rows.reduce((acc, row) => {
      return acc || !!row.what;
    }, false);

    return hasAtLeastOne;
  }),
});
