import React from "react";
import {
  FormCustomValueMetric,
  FormCustomValueMetricPropsT,
} from "../components/formCustomValueMetric/FormCustomValueMetric";
import { CustomValueMetricQueryT } from "../graphql/generated/graphql";
import ErrorBox from "../ui/ErrorBox/ErrorBox";
import { Loader } from "../ui/Loader/Loader";
import { ModalBody } from "../ui/Modal";
import ModalCloseButton from "../ui/Modal/ModalCloseButton";

export default function ValueMetricEditModalContent({
  data,
  isModalLoading,
  ...rest
}: FormCustomValueMetricPropsT & { data?: CustomValueMetricQueryT; isModalLoading: boolean }) {
  if (isModalLoading) {
    return <Loader />;
  }

  if (data?.organization?.customValueMetric?.discarded) {
    return (
      <>
        <ModalBody>
          <ErrorBox withIcon>Custom value metric was deleted</ErrorBox>
        </ModalBody>
        <ModalCloseButton />
      </>
    );
  }

  return <FormCustomValueMetric defaultValues={data?.organization?.customValueMetric} {...rest} />;
}
