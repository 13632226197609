import React, { ChangeEvent, useCallback, useState } from "react";
import { normalizeDateRange } from "../../../functions/normalize";
import { WidgetCompareT, WidgetMetricT } from "../../../graphql/generated/graphql";
import { WidgetT } from "../../../types/widgets";
import { ButtonTertiary } from "../../../ui/Button/Button";
import { DateRange } from "../../../ui/forms/DateRange";
import Select from "../../../ui/forms/Select";
import { Col, Row } from "../../../ui/grid/Grid";
import { Text } from "../../../ui/Text/Text";
import { COMPARE_OPTIONS } from "./InputCompare";
import { InputWidgetMetricsItem } from "./InputWidgetMetricsItem";
import { MetricDimensionDraggableRow } from "./MetricDimensionDraggableRow";

const METRIC_COMPARE_OPTIONS = COMPARE_OPTIONS;

type PropsT = {
  canShowAdditionalInfo: boolean;
  formKey: string;
  handleRemoveRow: (rowIndex: number) => void;
  index: number;
  isDefaultOpen: boolean;
  isRemoveButtonDisabled?: boolean;
  isRowDisabled: boolean;
  onChange: OnChangeT;
  onRowHeightChange: (rowId: string, height: number) => void;
  value: WidgetMetricT;
};

type OnChangeT = ({ index, newMetric }: { index: number; newMetric: WidgetMetricT }) => void;

type CompareDateRangeComponentPropsT = Pick<PropsT, "formKey" | "value"> & {
  handleChange: (widgetData: Partial<WidgetMetricT>) => void;
  handleCompareRemove: () => void;
  handleDateRangeRemove: () => void;
  isCompareVisible: boolean;
  isDateRangeVisible: boolean;
  isRowDisabled: boolean;
  pathString: string;
};

const CompareDateRangeComponent = ({
  formKey,
  handleChange,
  handleCompareRemove,
  handleDateRangeRemove,
  isCompareVisible,
  isDateRangeVisible,
  isRowDisabled,
  pathString,
  value,
}: CompareDateRangeComponentPropsT) => (
  <Col type="grow">
    {isCompareVisible && (
      <Row alignItems="center">
        <Col width="88px">
          <Text>Compare with</Text>
        </Col>
        <Col type="grow">
          <Select
            collection={METRIC_COMPARE_OPTIONS}
            selectProps={{
              id: `${formKey}.${pathString}.compare`,
              onChange: (event: ChangeEvent<HTMLSelectElement>) =>
                handleChange({ compare: (event.currentTarget.value as WidgetCompareT) || null }),
              disabled: isRowDisabled,
              value: value.compare || "",
              name: `${pathString}.compare`,
            }}
          />
        </Col>
        <Col>
          <ButtonTertiary disabled={isRowDisabled} icon="remove" size="medium" onlyIcon onClick={handleCompareRemove} />
        </Col>
      </Row>
    )}
    {isDateRangeVisible && (
      <Row alignItems="center">
        <Col width="88px">
          <Text>Date Range</Text>
        </Col>
        <Col type="grow">
          <DateRange
            id={`${formKey}.${pathString}.dateRange`}
            isDisabled={isRowDisabled}
            value={value.dateRange}
            isClearDisable
            onChange={(dateRange: WidgetT["dateRange"]) =>
              handleChange({ dateRange: normalizeDateRange(dateRange || {}) })
            }
          />
        </Col>
        <Col>
          <ButtonTertiary
            disabled={isRowDisabled}
            icon="remove"
            size="medium"
            onlyIcon
            onClick={handleDateRangeRemove}
          />
        </Col>
      </Row>
    )}
  </Col>
);

export const InputWidgetMetricsItemRow = ({
  canShowAdditionalInfo,
  formKey,
  handleRemoveRow,
  index,
  isDefaultOpen,
  isRemoveButtonDisabled,
  isRowDisabled,
  onChange,
  onRowHeightChange,
  value,
}: PropsT) => {
  const [isCompareVisible, setIsCompareVisible] = useState(!!value.compare);
  const [isDateRangeVisible, setIsDateRangeVisible] = useState(!!value.dateRange?.range);

  const pathString = ["widgetMetrics", index].join("-");

  const handleChange = useCallback(
    (newValue: Partial<WidgetMetricT>) => {
      onChange({ index, newMetric: { ...value, ...newValue } });
    },
    [index, onChange, value]
  );

  const handleCompareRemove = useCallback(() => {
    handleChange({ compare: WidgetCompareT.NoneT });
    setIsCompareVisible(false);
  }, [handleChange]);

  const handleDateRangeRemove = useCallback(() => {
    handleChange({ dateRange: { range: null, to: null, toDays: null, from: null, fromDays: null } });
    setIsDateRangeVisible(false);
  }, [handleChange]);

  return (
    <MetricDimensionDraggableRow
      draggableHandle="draggable-handle-metric"
      id={value.id}
      index={index}
      isRemoveButtonDisabled={isRemoveButtonDisabled}
      additionalContent={
        (isCompareVisible || isDateRangeVisible) && canShowAdditionalInfo ? (
          <CompareDateRangeComponent
            formKey={formKey}
            handleChange={handleChange}
            handleCompareRemove={handleCompareRemove}
            handleDateRangeRemove={handleDateRangeRemove}
            isCompareVisible={isCompareVisible}
            isDateRangeVisible={isDateRangeVisible}
            isRowDisabled={isRowDisabled}
            pathString={pathString}
            value={value}
          />
        ) : null
      }
      collection={
        canShowAdditionalInfo
          ? [
              { value: "Add compare", onClick: () => setIsCompareVisible(true), isDisabled: isCompareVisible },
              { value: "Add Date Range", onClick: () => setIsDateRangeVisible(true), isDisabled: isDateRangeVisible },
            ]
          : undefined
      }
      onRemove={handleRemoveRow}
      onRowHeightChange={onRowHeightChange}
    >
      <InputWidgetMetricsItem
        formKey={formKey}
        index={index}
        isDefaultOpen={isDefaultOpen}
        isDisabled={isRowDisabled}
        pathString={pathString}
        value={value}
        onChange={onChange}
      />
    </MetricDimensionDraggableRow>
  );
};
