import { useMemo } from "react";
import { addDays, startOfDay } from "date-fns";
import { formatDate } from "../../functions/dateHelpers";
import { useUserCampaignActionsQuery } from "../../graphql/generated/graphql";
import { useAppSettings } from "../../providers/appSettingsProvider";

type ParamsT = {
  campaignsData?: { campaign: { id: string } }[];
  daysAgo?: number;
  skip?: boolean;
};

export const useUserCampaignActionsHistory = ({ campaignsData, daysAgo = 7, skip }: ParamsT) => {
  const { organization } = useAppSettings();
  const campaignIds = useMemo(
    () => Array.from(new Set(campaignsData?.map((campaignData) => campaignData.campaign.id))),
    [campaignsData]
  );

  const { data, refetch } = useUserCampaignActionsQuery({
    variables: {
      limit: 1000,
      offset: 0,
      organizationExternalId: organization.externalId,
      campaignIds: campaignIds,
      from: formatDate(startOfDay(addDays(new Date(), -1 * daysAgo)), "dateApi"),
    },
    skip: !campaignIds || campaignIds.length === 0 || skip,
  });

  return useMemo(
    () => ({
      dataUserCampaignActionsHistory: data,
      refetch,
    }),
    [data, refetch]
  );
};
