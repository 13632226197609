import React, { useCallback, useMemo, useState } from "react";
import {
  Control,
  DeepRequired,
  FieldErrorsImpl,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
  useFieldArray,
} from "react-hook-form";
import { useDimensionValueCountsLazyQueryT } from "../../graphql/generated/graphql";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { ButtonPrimary, ButtonSecondaryRed } from "../../ui/Button/Button";
import { SelectableDimensionT } from "../../ui/forms/MetricDimensionSelectTabs";
import { Row } from "../../ui/grid/Grid";
import { HeadingSection } from "../../ui/Heading/Heading";
import { InfoBox } from "../../ui/InfoBox/InfoBox";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { useConfirmationContext } from "../confirmationDialog/confirmationDialogContext";
import { ConditionsT, DimensionsValueCountsT } from "../formConditions/FormConditions";
import { FormConditionsList } from "../formConditions/FormConditionsList";
import { FormKpiInputsT } from "./FormKpi";

type PropsT = {
  conditions: FormKpiInputsT["conditions"];
  control: Control<FormKpiInputsT>;
  dimensions?: SelectableDimensionT[];
  errors: FieldErrorsImpl<DeepRequired<FormKpiInputsT>>;
  setValue: UseFormSetValue<FormKpiInputsT>;
  trigger: UseFormTrigger<FormKpiInputsT>;
  watch: UseFormWatch<FormKpiInputsT>;
};

const getAreConditionsEmpty = (condition?: ConditionsT[0]) =>
  !condition?.unit?.value && condition?.operator === "" && !condition?.value?.length;

export const KpiConditions = ({ conditions, control, dimensions, errors, setValue, trigger, watch }: PropsT) => {
  const fields = useFieldArray({
    control,
    name: "conditions",
  });
  const { organization } = useAppSettings();
  const [getDimensionValueCounts] = useDimensionValueCountsLazyQueryT({});
  const dimensionsValueCounts: DimensionsValueCountsT = useMemo(() => ({}), []);
  const { callWithConfirmation } = useConfirmationContext();

  const handleGetValueCounts = useCallback(
    (dimensionId: string) => {
      dimensionsValueCounts[dimensionId] = { isLoading: !dimensionsValueCounts[dimensionId] };
      {
        getDimensionValueCounts({
          fetchPolicy: "cache-and-network",
          onCompleted(result) {
            dimensionsValueCounts[dimensionId] = {
              isLoading: false,
              valuesCount: result.organization?.dimensions.nodes[0].valueCounts,
            };
          },
          variables: {
            organizationExternalId: organization.externalId,
            ids: [dimensionId],
          },
        });
      }
    },
    [dimensionsValueCounts, getDimensionValueCounts, organization.externalId]
  );

  const [areConditionsEmpty, setAreConditionsEmpty] = useState(getAreConditionsEmpty(conditions?.[0]));

  return (
    <Tile className="mb-16">
      <Row alignItems="center" justify="between">
        <HeadingSection margin={0}>Filters</HeadingSection>

        <ButtonSecondaryRed
          disabled={getAreConditionsEmpty(conditions?.[0])}
          size="medium"
          onClick={() => {
            callWithConfirmation(
              () => {
                setValue("conditions", []);
                setAreConditionsEmpty(true);
              },
              {
                confirmButtonProps: { text: "Delete", variant: "red" },
                description: "Are you sure you really want to remove filter?",
                title: "Reset Changes",
                testId: "reset-filters",
              }
            );
          }}
        >
          Remove Filters
        </ButtonSecondaryRed>
      </Row>

      <Text className="mb-16 d-block" color="gray">
        Choose the metric you want to monitor and connect accounts.
      </Text>

      <div className="mt-16">
        {areConditionsEmpty ? (
          <div className="text-center">
            <InfoBox isCompact>You don't have any filter yet</InfoBox>

            <div className="mt-8">
              <ButtonPrimary onClick={() => setAreConditionsEmpty(false)}>Create new filter</ButtonPrimary>
            </div>
          </div>
        ) : (
          <FormConditionsList
            {...fields}
            conditions={conditions}
            control={control}
            dimensions={dimensions}
            dimensionsValueCounts={dimensionsValueCounts}
            errors={errors}
            setValue={setValue}
            trigger={trigger}
            watch={watch}
            onGetValueCounts={handleGetValueCounts}
          />
        )}
      </div>
    </Tile>
  );
};
