import React from "react";
import { ApolloError } from "@apollo/client";
import { ReportPageQueryT, useReportPageQuery } from "../../graphql/generated/graphql";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { ButtonPrimary } from "../../ui/Button/Button";
import { Col, GridOverflow } from "../../ui/grid/Grid";
import { Modal } from "../../ui/Modal";
import { ModalPropsT } from "../../ui/Modal/Modal";
import ModalBody from "../../ui/Modal/ModalBody";
import ModalFooter from "../../ui/Modal/ModalFooter";
import { withApiStateHandler } from "../ErrorLoadingWrapper/withApiStateHandler";
import { ReporBoardProvider } from "../reportBoard/context/reportBoardContext";
import { ReportBoard } from "../reportBoard/ReportBoard";

type QueryProps = { data?: ReportPageQueryT; error?: ApolloError; loading: boolean };
type PropsT = {
  modalOptions: ModalPropsT;
  name: string;
  onUseTemplate: (id: string, name: string) => void;
  reportId: string;
  useTemplateLoading: boolean;
};

const PreviewModalContent = withApiStateHandler(({ data }: Pick<QueryProps, "data">) =>
  data?.organization?.report ? (
    <ReporBoardProvider report={data.organization.report} reportOptions={{ canEdit: false }}>
      <ReportBoard isTemplate />
    </ReporBoardProvider>
  ) : (
    <div>no data</div>
  )
);

const TemplatePreviewModal = ({
  data,
  modalOptions,
  name,
  onUseTemplate,
  reportId,
  useTemplateLoading,
  ...rest
}: PropsT & QueryProps) => {
  return (
    <Modal {...modalOptions} heading={`Template preview: ${name}`} size="full" hasHeaderBorder>
      <ModalBody>
        <GridOverflow>
          <Col className="pa-16" type="grow">
            <PreviewModalContent {...rest} data={data} />
          </Col>
        </GridOverflow>
      </ModalBody>
      <ModalFooter hasBorder>
        <ButtonPrimary loading={useTemplateLoading} onClick={() => onUseTemplate(reportId, name)}>
          Use template
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};

export const TemplatePreviewModalConnected = ({ modalOptions, reportId, ...rest }: PropsT) => {
  const { organization } = useAppSettings();

  const { data, error, loading } = useReportPageQuery({
    variables: { organizationExternalId: organization.externalId, reportId },
    skip: !reportId || !modalOptions.isOpen,
  });

  return (
    <TemplatePreviewModal
      {...rest}
      data={data}
      error={error}
      loading={loading}
      modalOptions={modalOptions}
      reportId={reportId}
    />
  );
};
