import React, { PropsWithChildren } from "react";
import { SystemNameT } from "../../graphql/generated/graphql";
import { Col, Row } from "../grid/Grid";
import ModalHeader from "./ModalHeader";

type PropsT = {
  hasHeaderBorder?: boolean;
  heading?: string | React.ReactNode;
  icon?: string;
  systemIcon?: SystemNameT | null;
};

function ModalContentFullWithGrid({ children, hasHeaderBorder, heading, icon, systemIcon }: PropsWithChildren<PropsT>) {
  return (
    <div className="Modal-content">
      <Col height="100%">
        <Row className="mb-0">
          <Col type="grow">
            <ModalHeader hasBorder={hasHeaderBorder} heading={heading} icon={icon} systemIcon={systemIcon} />
          </Col>
        </Row>
        {children}
      </Col>
    </div>
  );
}

export default ModalContentFullWithGrid;
