import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import { AppSettingsProvider } from "./providers/appSettingsProvider";

const rootElement = document.getElementById("dee-reporting-root") as HTMLElement;

const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <AppSettingsProvider>
        <App />
      </AppSettingsProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
