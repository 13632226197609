import React, { memo, useCallback, useMemo } from "react";
import { isEqual } from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import { v4 as uuid } from "uuid";
import { DEFAULT_WIDGET_DIMENSION } from "../../../constants/form";
import { WidgetDimensionT } from "../../../graphql/generated/graphql";
import { usePrevious } from "../../../hooks/usePrevious";
import { WidgetRuleT, WidgetT } from "../../../types/widgets";
import { Text } from "../../../ui/Text/Text";
import { OnFormEditWidgetChangeT } from "../FormEditWidget";
import { InputWidgetDimensionsItem } from "./InputWidgetDimensionsItem";
import { LimitState } from "./LimitState";
import { MetricDimensionDraggableRow } from "./MetricDimensionDraggableRow";
import { useMetricDimensionDraggableList } from "./useMetricDimensionDraggableList";

const ResponsiveGridLayout = WidthProvider(Responsive);

type PropsT = {
  addButtonTitle: string;
  formKey: string;
  hasDefaultData?: boolean;
  label: React.ReactNode;
  onChange: OnFormEditWidgetChangeT;
  rules?: WidgetRuleT;
  value: WidgetT["widgetDimensions"];
};

const InputWidgetDimensionsMemo = ({
  addButtonTitle,
  formKey,
  hasDefaultData,
  label,
  onChange,
  rules,
  value,
}: PropsT) => {
  const limits = rules?.dimensions;
  const filledLength = value.filter((dim) => dim.dimensionId !== "").length;
  const prevValue = usePrevious(value);
  const hasNewItem = !!prevValue && value.length > prevValue.length;

  const handleChange = useCallback(
    ({ index, newDimension }: { index: number; newDimension: WidgetDimensionT }) => {
      onChange((prev) => ({
        ...prev,
        widgetDimensions: [...value.slice(0, index), newDimension, ...value.slice(index + 1)],
      }));
    },
    [value, onChange]
  );

  const handleAddRow = useCallback(() => {
    onChange((prev) => ({ ...prev, widgetDimensions: [...value, { ...DEFAULT_WIDGET_DIMENSION, id: uuid() }] }));
  }, [onChange, value]);

  const handleRemoveRow = useCallback(
    (index: number) => {
      onChange((prev) => ({
        ...prev,
        widgetDimensions: [...value.slice(0, index), ...value.slice(index + 1)],
      }));
    },
    [value, onChange]
  );

  const handleLayoutChange = <T extends WidgetDimensionT>(sortedValues: T[]) => {
    onChange((prev) => ({ ...prev, widgetDimensions: sortedValues }));
  };

  const { defaultGridProps, onRowHeightChange } = useMetricDimensionDraggableList({
    value,
    onChange: handleLayoutChange,
  });

  const selectedIds = useMemo(() => value.map((dimension) => dimension.dimensionId), [value]);

  return (
    <div className="mb-16">
      {<h3 className="Heading Heading--lg mb-0">{label}</h3> || (
        <Text className="mb-0" bold>
          Dimensions
        </Text>
      )}

      <ResponsiveGridLayout
        {...defaultGridProps}
        className="mt-16"
        draggableHandle=".draggable-handle-dimension"
        margin={[0, 0]}
      >
        {value.map((widgetDimension, index) => {
          const isRowDisabled = !!(limits && limits.max && index >= limits.max);

          return (
            <div key={widgetDimension.id} data-test-id={`dimension-row-${index}`}>
              <MetricDimensionDraggableRow
                draggableHandle="draggable-handle-dimension"
                id={widgetDimension.id}
                index={index}
                onRemove={handleRemoveRow}
                onRowHeightChange={onRowHeightChange}
              >
                <InputWidgetDimensionsItem
                  formKey={formKey}
                  index={index}
                  isDefaultOpen={hasNewItem && !hasDefaultData}
                  isDisabled={isRowDisabled}
                  selectedIds={selectedIds}
                  value={widgetDimension}
                  onChange={handleChange}
                />
              </MetricDimensionDraggableRow>
            </div>
          );
        })}
      </ResponsiveGridLayout>

      <LimitState
        addButtonTitle={addButtonTitle}
        filledLength={filledLength}
        handleAddRow={handleAddRow}
        limits={limits}
        rawLength={value.length}
        testId="widgetDimensions-add-button"
      />
    </div>
  );
};

export const InputWidgetDimensions = memo(InputWidgetDimensionsMemo, isEqual);
