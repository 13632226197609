import React from "react";
import { KpiSemaphoreT, MetricT, ResultT } from "../../graphql/generated/graphql";
import { formatPercents } from "../../i18n/formatNumbers";
import { Col, Row } from "../../ui/grid/Grid";
import { Heading } from "../../ui/Heading/Heading";
import { CustomIconNameT, IconCustom } from "../../ui/IconCustom/IconCustom";
import { Tooltip } from "../../ui/Tooltip/Tooltip";
import { KpiSemaphoreBadge } from "./KpiSemaphoreBadge";
import { KpiSemaphoreTooltipContent } from "./KpiSemaphoreTooltipContent";

export type KpiSemaphoreResultT = Pick<
  ResultT,
  | "semaphore"
  | "currentValue"
  | "estimatedProgress"
  | "estimatedPeriodValue"
  | "targetValue"
  | "currency"
  | "startOn"
  | "endOn"
>;

export type KpiSemaphorePropsT = {
  className?: string;
  hideBadge?: boolean;
  metric: Pick<MetricT, "id" | "dataType">;
  name: string;
  onlyIcon?: boolean;
  result: KpiSemaphoreResultT | null | undefined;
  size?: "sm" | "md" | "lg" | "inherit";
};

export const ORDERED_KPI_SEMAPHORE_VARIANTS = [
  KpiSemaphoreT.GreenT,
  KpiSemaphoreT.YellowT,
  KpiSemaphoreT.RedT,
  KpiSemaphoreT.ExceededT,
  KpiSemaphoreT.LoadingT,
  KpiSemaphoreT.ErrorT,
];

export const KPI_SEMAPHORE_VARIANTS = {
  [KpiSemaphoreT.LoadingT]: {
    color: "#D9D9D9",
    icon: CustomIconNameT.HourglassHexT,
    label: "Loading",
    error: "KPIs do not contain any data yet, as soon as they are available they will be displayed",
  },
  [KpiSemaphoreT.ErrorT]: {
    color: "#D9D9D9",
    icon: CustomIconNameT.ErrorHexT,
    label: "Error",
    error: "Something went wrong and we can't retrieve the data. Please check your KPI settings",
  },
  [KpiSemaphoreT.GreenT]: {
    color: "#63BC26",
    icon: CustomIconNameT.CheckCircleT,
    label: "On Track",
    error: undefined,
  },
  [KpiSemaphoreT.YellowT]: {
    color: "#F59E0B",
    icon: CustomIconNameT.WarningT,
    label: "Behind",
    error: undefined,
  },
  [KpiSemaphoreT.RedT]: {
    color: "#FF264A",
    icon: CustomIconNameT.FlameT,
    label: "At Risk",
    error: undefined,
  },
  [KpiSemaphoreT.ExceededT]: {
    color: "#EF292A",
    icon: CustomIconNameT.HandCircleT,
    label: "Over",
    error: undefined,
  },
};

const ICON_SIZE = {
  sm: 16,
  md: 16,
  lg: 40,
  inherit: 16,
};

export const KpiSemaphore = ({ className, hideBadge, onlyIcon, result, size, ...rest }: KpiSemaphorePropsT) => {
  const semaphoreVariant = result?.semaphore ? KPI_SEMAPHORE_VARIANTS[result?.semaphore] : undefined;
  const isMedium = size === "md";
  const isSmall = size === "sm";
  const isLarge = size === "lg";

  if (!semaphoreVariant) {
    return null;
  }

  return (
    <Row
      alignItems="center"
      className={className}
      flexwrap={isLarge}
      justify="center"
      padding="m"
      style={{ ...(isMedium && { fontSize: "13px" }) }}
    >
      <Col alignItems="center" type="shrink" width={isLarge ? "100%" : undefined}>
        <Tooltip tooltipContent={<KpiSemaphoreTooltipContent {...rest} result={result} />}>
          <IconCustom name={semaphoreVariant.icon} size={size ? ICON_SIZE[size] : 24} />
        </Tooltip>
      </Col>

      {!isSmall && !semaphoreVariant.error && !onlyIcon && (
        <>
          <Col type="shrink">
            <Heading
              level={isMedium ? "h4" : "h1"}
              margin={0}
              style={{
                color: semaphoreVariant?.color,
                marginRight: "0.3em",
                ...(isLarge && { fontSize: "36px", lineHeight: "44px" }),
              }}
            >
              {typeof result?.estimatedProgress === "number" &&
                (result.estimatedProgress > 9.99 ? "999+ %" : formatPercents(result.estimatedProgress, 0))}
            </Heading>
          </Col>
          {!isMedium && !hideBadge && (
            <Col type="shrink">
              <KpiSemaphoreBadge semaphore={result?.semaphore} size={isLarge ? size : undefined} />
            </Col>
          )}
        </>
      )}
    </Row>
  );
};
