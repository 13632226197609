import React, { HTMLAttributes, MouseEvent, forwardRef, useCallback, useState } from "react";
import cs from "classnames";
import { Tooltip } from "../Tooltip/Tooltip";

export const MiddleEllipsis = forwardRef<
  HTMLDivElement,
  {
    children: string | undefined | null;
    isInline?: boolean;
    tag?: "span" | "p" | "div";
  } & HTMLAttributes<HTMLElement>
>(({ children, className, tag = "span", ...rest }, ref) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      const targetElem = event.currentTarget.querySelector(".js-middle-text");
      if (targetElem && (targetElem as HTMLElement).offsetWidth !== targetElem.scrollWidth && !showTooltip) {
        setShowTooltip(true);
      } else if (targetElem && (targetElem as HTMLElement).offsetWidth === targetElem.scrollWidth && showTooltip) {
        setShowTooltip(false);
      }
    },
    [showTooltip, setShowTooltip]
  );

  const Tag = tag;
  const firstPart = children?.slice(0, 8);
  const middlePart = children?.slice(8, children?.length > 26 ? 8 + children?.length - 26 : 26);
  const endPart =
    children && (children?.length || 0) > 26 ? children?.slice(children?.length - 18, children?.length) : null;

  return (
    <Tooltip tooltipContent={showTooltip ? children : null}>
      <Tag {...rest} ref={ref} className={cs(className)} onMouseEnter={handleMouseEnter}>
        <div className="d-flex">
          <span className="flex-shrink-0">{firstPart}</span>
          {middlePart && <span className="js-middle-text text-overflow">{middlePart}</span>}
          {endPart && <span className="flex-shrink-0">{endPart}</span>}
        </div>
      </Tag>
    </Tooltip>
  );
});
