import {
  CurrencyT,
  DimensionOperatorT,
  MetricOperatorT,
  WidgetCompareT,
  WidgetDimensionT,
  WidgetMetricT,
} from "../graphql/generated/graphql";
import I18n from "../i18n/en.json";

export const DEFAULT_DIMENSION = {
  dimensionId: {
    value: "",
    label: "",
    type: "dimension",
  },
} as const;

export const DEFAULT_METRIC = {
  metricId: {
    label: "",
    value: "",
    type: "metric",
  },
  compare: WidgetCompareT.NoneT,
  dateRange: {
    range: null,
    from: null,
    to: null,
  },
} as const;

export const DEFAULT_WIDGET_DIMENSION: WidgetDimensionT = {
  id: "",
  dimensionId: "",
  __typename: "WidgetDimension",
};

export const DEFAULT_WIDGET_METRIC: WidgetMetricT = {
  compare: null,
  dateRange: {
    __typename: "DateRange",
    from: null,
    to: null,
    range: null,
  },
  id: "",
  metricId: "",
  __typename: "WidgetMetric",
};

export const SELECT_OPTIONS_METRIC_OPERATOR = (Object.keys(MetricOperatorT) as Array<keyof typeof MetricOperatorT>).map(
  (key) => ({
    label: I18n.form.metric.condtiton.operator[key],
    value: MetricOperatorT[key],
  })
);

export const SELECT_OPTIONS_DIMENSION_OPERATOR = (
  Object.keys(DimensionOperatorT) as Array<keyof typeof DimensionOperatorT>
).map((key) => ({
  label: I18n.form.dimension.condtiton.operator[key],
  value: DimensionOperatorT[key],
}));

export const SELECT_OPTIONS_DIMENSION_CAMPAIGN_STATUS_OPERATOR = [
  {
    label: I18n.form.dimension.condtiton.operator.EqT,
    value: DimensionOperatorT.EqT,
  },
  {
    label: I18n.form.dimension.condtiton.operator.NotEqT,
    value: DimensionOperatorT.NotEqT,
  },
];

export const SELECT_OPTIONS_COMPARE = (Object.keys(WidgetCompareT) as (keyof typeof WidgetCompareT)[]).map(
  (compare) => ({
    label: WidgetCompareT[compare],
    value: WidgetCompareT[compare],
  })
);

export const SELECT_METRIC_OPTIONS_COMPARE = [
  {
    label: "From widget",
    value: "",
  },
].concat(SELECT_OPTIONS_COMPARE);

export const SELECT_OPTIONS_CURRENCY = (Object.keys(CurrencyT) as (keyof typeof CurrencyT)[]).map((compare) => ({
  label: CurrencyT[compare],
  value: CurrencyT[compare],
}));
