export const usePaginationFloatingWindow = (
  pageCount: number,
  currentPageIndex: number
): { endMore: boolean; pages: unknown[]; startMore: boolean; startingCounter: number } => {
  let pages = Array(...new Array(pageCount));
  let startMore = false;
  let endMore = false;
  let startingCounter = 0;

  if (pageCount > 6) {
    pages = Array(...new Array(4));
    startMore = false;
    endMore = true;

    if (currentPageIndex >= 3) {
      startMore = true;
      pages = Array(...new Array(3));
      startingCounter = currentPageIndex - 1;

      if (currentPageIndex + 3 >= pageCount) {
        endMore = false;
        pages = Array(...new Array(4));
        startingCounter = pageCount - 4;
      }
    }
  }

  return { pages, startingCounter, startMore, endMore };
};
