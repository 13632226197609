import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { AggregationFunctionT, CurrencyT, MetricDataT, MetricT } from "../../graphql/generated/graphql";
import { useHandleServerErrors } from "../../hooks/useHandleServerErrors";
import { ButtonPrimary } from "../../ui/Button/Button";
import { InputController } from "../../ui/forms/InputController";
import { Col, Row } from "../../ui/grid/Grid";
import { HeadingSmall } from "../../ui/Heading/Heading";
import { ModalBody, ModalFooter } from "../../ui/Modal";
import ModalCloseButton from "../../ui/Modal/ModalCloseButton";
import { Tile } from "../../ui/Tile/Tile";
import { CURRENCY_OPTIONS } from "../formEditWidget/inputs/InputCurrency";
import { formCustomValueMetricSchema } from "./validations";

export type FormCustomValueMetricInputsT = {
  aggregationFunction: AggregationFunctionT;
  dataType: MetricDataT;
  defaultCurrency: CurrencyT;
  description: string;
  name: string;
};

export type FormCustomValueMetricPropsT = {
  defaultValues?: Pick<MetricT, "name" | "description" | "defaultCurrency" | "aggregationFunction" | "dataType"> | null;
  isMutationLoading?: boolean;
  onSubmit: (values: FormCustomValueMetricInputsT) => void;
  serverErrors?: string[];
};

export const FormCustomValueMetric = ({
  defaultValues,
  isMutationLoading,
  onSubmit,
  serverErrors,
}: FormCustomValueMetricPropsT) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm<FormCustomValueMetricInputsT>({
    resolver: yupResolver(formCustomValueMetricSchema),
    defaultValues: {
      name: defaultValues?.name || "",
      description: defaultValues?.description || "",
      aggregationFunction: defaultValues?.aggregationFunction || AggregationFunctionT.SumT,
      dataType: defaultValues?.dataType || MetricDataT.FloatT,
      defaultCurrency: defaultValues?.defaultCurrency || CurrencyT.UsdT,
    },
  });

  useHandleServerErrors({ errors: serverErrors, setError, shouldNotify: true });

  return (
    <form
      id="new-custom-value-metric"
      onSubmit={(event) => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
    >
      <ModalBody>
        <Tile className="mt-8">
          <div className="w-50 mb-8">
            <InputController
              control={control}
              defaultValue={defaultValues?.name || ""}
              errors={errors}
              id="name"
              input={{ type: "text", placeholder: "Insert name", autoFocus: true }}
              label="Metric name"
              name="name"
            />
          </div>

          <InputController
            control={control}
            errors={errors}
            id="description"
            label="Description"
            name="description"
            textarea={{ type: "text", placeholder: "Insert Description (Optional)" }}
          />

          <div className="delimiter mv-16" />
          <HeadingSmall margin={16}>Values settings</HeadingSmall>
          <Row padding="n">
            <Col type="grow">
              <InputController
                control={control}
                id="aggregationFunction"
                label="Aggregation"
                name="aggregationFunction"
                collection={[
                  { label: "Sum", value: AggregationFunctionT.SumT },
                  { label: "Avg", value: AggregationFunctionT.AvgT },
                  { label: "Max", value: AggregationFunctionT.MaxT },
                  { label: "Min", value: AggregationFunctionT.MinT },
                  { label: "LastValue", value: AggregationFunctionT.LastValueT },
                  { label: "Count", value: AggregationFunctionT.CountT },
                ]}
              />
            </Col>
            <Col className="ml-8" type="grow">
              <InputController
                control={control}
                id="dataType"
                label="Data Type"
                name="dataType"
                collection={[
                  { label: "Float (Example: 10,00)", value: MetricDataT.FloatT },
                  { label: "Integer (Example: 10)", value: MetricDataT.IntegerT },
                  { label: "Money (Example: 1.000.000,00)", value: MetricDataT.MoneyT },
                  { label: "Percent (Example: 10,00%)", value: MetricDataT.PercentT },
                ]}
              />
            </Col>
            <Col>
              <Controller
                control={control}
                name="dataType"
                render={({ field }) =>
                  field.value === MetricDataT.MoneyT ? (
                    <InputController
                      className="pl-8"
                      control={control}
                      id="defaultCurrency"
                      label="Currency"
                      name="defaultCurrency"
                      collection={CURRENCY_OPTIONS.filter(
                        (item) => item.value !== CurrencyT.OriginalT && item.value !== ""
                      )}
                    />
                  ) : (
                    <div />
                  )
                }
              />
            </Col>
          </Row>
        </Tile>
      </ModalBody>
      <ModalFooter>
        <ButtonPrimary
          disabled={isMutationLoading}
          form="new-custom-value-metric"
          loading={isMutationLoading}
          type="submit"
        >
          Save
        </ButtonPrimary>
        <ModalCloseButton />
      </ModalFooter>
    </form>
  );
};
