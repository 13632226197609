import { omit } from "lodash";
import { SearchInput } from "../../components/search/Search";
import { useSearch } from "../../hooks/useSearch";
import { FormSelectOptionsT } from "../../types/common";
import { ButtonPrimary, ButtonPropsT, ButtonSecondary } from "../Button/Button";
import Select from "../forms/Select";
import { Col, Row } from "../grid/Grid";
import { Heading } from "../Heading/Heading";

export const TableHeader = ({
  createButtonProps,
  heading,
  headingLevel = "h3",
  searchInputProps,
  selectFilterProps,
}: {
  createButtonProps?: Pick<ButtonPropsT, "onClick" | "to" | "isRealHref" | "link">;
  heading?: string;
  headingLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  searchInputProps?: ReturnType<typeof useSearch>["searchInputProps"];
  selectFilterProps?: ReturnType<typeof useSearch>["searchInputProps"] & {
    collection: FormSelectOptionsT;
  };
}) => {
  if (!heading && !searchInputProps && !createButtonProps && !selectFilterProps) {
    return null;
  }

  const handleClearFilters = () => {
    searchInputProps?.clearSearch();
    selectFilterProps?.clearSearch();
  };

  return (
    <>
      {heading && (
        <Row>
          <Heading level={headingLevel}>{heading}</Heading>
        </Row>
      )}
      {(searchInputProps || createButtonProps || selectFilterProps) && (
        <Row className="mb-16">
          {createButtonProps && (
            <Col>
              <ButtonPrimary {...createButtonProps} icon="plus" onlyIcon />
            </Col>
          )}

          {searchInputProps && (
            <Col width="200px">
              <SearchInput {...searchInputProps} />
            </Col>
          )}

          {selectFilterProps && (
            <Col>
              <Select collection={selectFilterProps?.collection} selectProps={omit(selectFilterProps, "clearSearch")} />
            </Col>
          )}
          {((selectFilterProps && selectFilterProps.value !== "") ||
            (searchInputProps && searchInputProps.value !== "")) && (
            <Col>
              <ButtonSecondary icon="remove" onClick={handleClearFilters}>
                Clear filters
              </ButtonSecondary>
            </Col>
          )}
        </Row>
      )}
    </>
  );
};
