import { mergeStyles } from "react-select";

export const smartSelectStyles: ReturnType<typeof mergeStyles> = {
  container: (provided, state) => ({
    ...provided,
    pointerEvents: state.isDisabled ? "all" : undefined,
    fontSize: "12px",
    "&:hover": state.isDisabled && { cursor: "not-allowed" },
    "fieldset:disabled &:hover": { cursor: "not-allowed" },
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow:
      (state.menuIsOpen && "0px 0px 0px 2px #E0DAFA") ||
      (state.isDisabled && "unset") ||
      "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
    transition: "none",
    borderColor: (state.menuIsOpen && "#855BE2") || (state.isDisabled && "#ECEFF2") || "#D5DDE2",
    borderWidth: "1px",
    cursor: "text",
    minHeight: "32px",
    color: state.isDisabled ? "#B0BFC9" : "#333E47",
    pointerEvents: state.isDisabled ? "none" : undefined,
    backgroundColor: (state.menuIsOpen && "#FFFFFF") || (state.isDisabled && "#ECEFF2") || "#F9FAFB",
    "&:hover": !state.menuIsOpen && {
      borderColor: "#B0BFC9",
      backgroundColor: "#FFFFFF",
    },
    "fieldset:disabled &": {
      pointerEvents: "none",
      color: "#B0BFC9",
      backgroundColor: "#ECEFF2",
      borderColor: "#ECEFF2",
      boxShadow: "unset",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    alignSelf: "stretch",
    padding: "1px 8px",
    gap: "0 4px",
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: "0",
    color: "#859BAB",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: (state.selectProps.menuIsOpen && "#668091") || (state.selectProps.isDisabled && "#B0BFC9") || "#859BAB",
    transform: (state.selectProps.menuIsOpen && "rotateX(180deg)") || "rotateX(0)",
    transition: "transform ease 0.15s",
    padding: "0 8px 0 0",
    lineHeight: "20px",
    cursor: "pointer",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: 0,
    color: "#859BAB",
    "&:hover, &:focus": {
      cursor: "pointer",
      color: "#668091",
    },
    "&:active": { color: "#859BAB" },
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: "0",
    color: "inherit",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.isDisabled ? "#ECEFF2" : "#EEEBFC",
    color: state.selectProps.isDisabled ? "#B0BFC9" : "#6031A6",
    border: "1px solid #E0DAFA",
    borderRadius: "6px",
    padding: "2px 6px",
    display: "inline-flex",
    alignItems: "center",
    gap: "4px",
    margin: 0,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontFamily: "Inter Medium",
    fontSize: "11px",
    lineHeight: "14px",
    color: "inherit",
    padding: 0,
    paddingLeft: 0,
    "& .Icon": {
      color: "#855BE2",
    },
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "inherit",
    padding: 0,
    "&:hover, &:focus": {
      opacity: 0.7,
      cursor: "pointer",
      backgroundColor: "inherit",
      color: "inherit",
    },
    "&:active": { backgroundColor: "inherit", color: "inherit" },
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: "110",
    boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.08)",
    marginTop: "6px",
    border: "1px solid #E4E4E7",
    borderRadius: "8px",
    display: state.selectProps.isDisabled ? "none" : undefined,
    "fieldset:disabled &": {
      display: "none",
    },
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "6px",
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: "Inter Medium",
    fontSize: "13px",
    lineHeight: "120%",
    borderRadius: "4px",
    color: "#425462",
    marginBottom: "4px",
    backgroundColor: (state.isSelected ? "#F6F4FE" : undefined) || (state.isFocused ? "#F4F4F5" : undefined),
    "&:hover, &:focus": {
      backgroundColor: state.isSelected ? "#EEEBFC" : "#F4F4F5",
      cursor: "pointer",
    },
    "&:active": {
      backgroundColor: state.isSelected ? "#E0DAFA" : "#E4E4E7",
      cursor: "pointer",
    },
    "&:last-of-type": {
      marginBottom: 0,
    },
  }),
};
