import React from "react";
import { CreateSharedReportInputT } from "../../graphql/generated/graphql";
import { FormSharingLink, FormSharingLinkPropsT } from "../formSharingLink/FormSharingLink";
import { ReportSharingPropsT } from "./ReportSharing";
import { ReportSharingLink, ReportSharingLinkPropsT } from "./ReportSharingLink";

type PropsT = ReportSharingLinkPropsT &
  Pick<FormSharingLinkPropsT, "pages" | "isEditation"> &
  Pick<ReportSharingPropsT, "isLoadingData" | "isLoadingUpdate">;

export const ReportSharingDetail = (props: PropsT) => {
  const {
    isEditation,
    isLoadingData,
    isLoadingUpdate,
    onRefetchData,
    onSetEditedSharedReport,
    onUpdateSharedReport,
    pages,
    sharedReport,
  } = props;

  const handleSubmit = async (values: Omit<CreateSharedReportInputT, "reportId">) => {
    onUpdateSharedReport({
      variables: { ...sharedReport, ...values },
      onCompleted: async () => {
        await onRefetchData();
        onSetEditedSharedReport(null);
      },
    });
  };

  return (
    <div>
      <ReportSharingLink {...props} hiddenMenuItems={["detail"]} />

      <div className="mt-16">
        <FormSharingLink
          defaultValues={sharedReport}
          isEditation={isEditation}
          isSubmiting={isLoadingUpdate || isLoadingData}
          pages={pages}
          reportName={sharedReport.name || ""}
          onCancel={() => onSetEditedSharedReport(null)}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};
