import { array, object, string } from "yup";

export const formConditionsSchema = object({
  conditions: array().of(
    object({
      value: array().of(string()),
      operator: string(),
      unit: object(),
    }).test("required-condition-unit", "All items are required", (values, { parent }) => {
      const isConditionValid =
        values?.operator !== "" &&
        !!values?.value?.length &&
        !!Object.keys(values).length &&
        values?.unit?.label !== "" &&
        values?.unit?.value !== "" &&
        values?.unit?.type !== "";

      const isEmptyCondition =
        values?.operator === "" && !values?.value?.length && Object.keys(values?.unit).length === 0;

      const isEmptyConditions = parent.length < 2 && isEmptyCondition;

      return isEmptyConditions || isConditionValid;
    })
  ),
});
