import React, { CSSProperties, ForwardedRef, Fragment, HTMLProps, ReactNode, forwardRef } from "react";
import cs from "classnames";
import { Icon } from "../Icon/Icon";
import { Tooltip } from "../Tooltip/Tooltip";
import { TileContentHorizontal } from "./TileContentHorizontal";
import { TileContentVertical } from "./TileContentVertical";

export type TilePropsT = {
  alphaBadge?: string;
  badge?: string;
  border?: "withoutBorder" | "onlyLeftBorder";
  children: ReactNode;
  contentStyle?: CSSProperties;
  ctaLink?: string;
  dataTestId?: string;
  hasShadow?: boolean;
  iconTile?: boolean;
  inDashboardTile?: boolean;
  isActive?: boolean;
  isCentered?: boolean;
  isDisabled?: boolean;
  isFullHeight?: boolean;
  isHorizontal?: boolean;
  isHoverable?: boolean;
  isSmallSpaced?: boolean;
  isSquare?: boolean;
  isWithCheck?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
  systemIconHover?: boolean;
  text?: string;
  tooltip?: string;
} & HTMLProps<HTMLDivElement>;

export const Tile = forwardRef(
  (
    {
      alphaBadge,
      badge,
      border,
      children,
      className,
      contentStyle,
      ctaLink,
      dataTestId,
      hasShadow,
      iconTile,
      inDashboardTile,
      isActive,
      isCentered,
      isDisabled,
      isFullHeight,
      isHorizontal,
      isHoverable,
      isSmallSpaced,
      isSquare,
      isWithCheck,
      onClick,
      systemIconHover,
      text,
      tooltip,
      ...rest
    }: TilePropsT,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const isLink = ctaLink && ctaLink.length > 0 && !onClick;
    const Element = isLink ? "a" : Fragment;
    const elementProps = isLink ? { "data-test-id": dataTestId, href: ctaLink } : {};
    const hoverable = isHoverable || onClick || (ctaLink && ctaLink.length > 0);

    return (
      <div
        ref={ref}
        data-test-id={dataTestId ? `Tile[${dataTestId}]` : undefined}
        className={cs("Tile", className, {
          "Tile--shadow": hasShadow,
          "Tile--disabled": isDisabled,
          "Tile--hover": hoverable,
          "Tile--active": isActive,
          "text-center": isCentered,
          "Tile--fullHeight": isFullHeight,
          "Tile--icon": iconTile,
          "Tile--horizontal": isHorizontal,
          "Tile--systemHover": systemIconHover && isHoverable,
          "Tile--square": isSquare,
          [`Tile--${border}`]: border,
        })}
        onClick={isLink ? undefined : onClick}
        {...rest}
      >
        {tooltip && tooltip.length > 0 && (
          <div className="Tile-tooltip">
            <Tooltip tooltipContent="Lorem ipsum...">
              <Icon kind="info" size="16px" isInheritColor />
            </Tooltip>
          </div>
        )}

        <Element {...elementProps}>
          <div
            style={contentStyle}
            className={cs("Tile-content", {
              "Tile-content--smallSpaced": isSmallSpaced,
              "Tile-content--inDashboardTile": inDashboardTile,
              "pr-32": tooltip && tooltip.length > 0 && isHorizontal,
            })}
          >
            {isHorizontal && (
              <TileContentHorizontal alphaBadge={alphaBadge} badge={badge} isWithCheck={isWithCheck} text={text}>
                {children}
              </TileContentHorizontal>
            )}
            {!isHorizontal && (
              <TileContentVertical
                alphaBadge={alphaBadge}
                badge={badge}
                isWithCheck={isWithCheck}
                text={text}
                tooltip={tooltip}
              >
                {children}
              </TileContentVertical>
            )}
          </div>
        </Element>
      </div>
    );
  }
);
