/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, HTMLProps, MouseEvent, ReactElement } from "react";
import { Controller, ControllerProps, FieldError, FieldPath, FieldValues, UseFormReturn } from "react-hook-form";
import { Props } from "react-select";
import { formatDate } from "../../functions/dateHelpers";
import { formatDateRangeSelection } from "../../i18n/formatDate";
import { FormRadioOptionsT, FormSelectOptionsT, HTMLInputTypeAttributeT } from "../../types/common";
import { SmartSelect, SmartSelectPropsT } from "../SmartSelect/SmartSelect";
import { AutoComplete, AutoCompleteCollectionT } from "./AutoCompleteInput";
import Checkbox from "./Checkbox";
import FormGroup from "./FormGroup";
import Input, { InputT } from "./Input";
import Radio from "./Radio";
import Select from "./Select";
import Switch from "./Switch";
import Textarea from "./Textarea";

export type InternalPropsT =
  | {
      radioLabel: string | number;
      radioValue: string | number;
    }
  | {
      radioLabel?: never;
      radioValue?: never;
    };

export type InputControllerPropsT<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> =
  | Omit<ControllerProps<TFieldValues, TName>, "render"> & {
      className?: string;
      customError?: FieldError;
      errors?: UseFormReturn<TFieldValues, TName>["formState"]["errors"];
      id?: string;
      isDate?: boolean;
      isFullWidth?: boolean;
      isLoading?: boolean;
      label?: string | ReactElement;
      onClear?: (event: MouseEvent<HTMLElement>) => void;
      onValueChange?: (value: string) => void;
      placeholder?: string;
      testId?: string;
    } & (
        | {
            autoCompleteOptions?: never;
            collection?: never;
            hasCopyBtn?: boolean;
            input?: Omit<HTMLProps<HTMLInputElement>, "type"> &
              InputT & {
                type?: Exclude<HTMLInputTypeAttributeT, "radio">;
              };
            radioOptions?: never;
            select?: never;
            smartSelect?: never;
            textarea?: never;
          }
        | {
            autoCompleteOptions?: never;
            collection?: never;
            hasCopyBtn?: never;
            input: Omit<HTMLProps<HTMLInputElement>, "type"> & {
              isClearable?: never;
              kind?: "segmented" | "tile";
              type?: "radio";
            };
            radioOptions: FormRadioOptionsT;
            select?: never;
            smartSelect?: never;
            textarea?: never;
          }
        | {
            autoCompleteOptions?: never;
            collection?: FormSelectOptionsT;
            hasCopyBtn?: never;
            input?: never;
            radioOptions?: never;
            select?: HTMLProps<HTMLSelectElement>;
            smartSelect?: never;
            textarea?: never;
          }
        | {
            autoCompleteOptions?: never;
            collection?: never;
            hasCopyBtn?: never;
            input?: never;
            radioOptions?: never;
            select?: never;
            smartSelect?: never;
            textarea: HTMLProps<HTMLTextAreaElement>;
          }
        | {
            autoCompleteOptions?: never;
            collection?: never;
            hasCopyBtn?: never;
            input?: never;
            radioOptions?: never;
            select?: never;
            smartSelect: SmartSelectPropsT & Props;
            textarea?: never;
          }
        | {
            autoCompleteOptions: {
              collection?: AutoCompleteCollectionT;
              variableName?: string;
            };
            collection?: never;
            hasCopyBtn?: never;
            input?: never;
            radioOptions?: never;
            select?: never;
            smartSelect?: never;
            textarea?: never;
          }
      );

export const InputController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  autoCompleteOptions,
  className,
  collection,
  control,
  customError,
  defaultValue,
  hasCopyBtn,
  id,
  input,
  isDate,
  isFullWidth,
  isLoading,
  label,
  name,
  onClear,
  onValueChange,
  placeholder,
  radioOptions,
  select,
  smartSelect,
  testId,
  textarea,
}: InputControllerPropsT<TFieldValues, TName> & InternalPropsT) => {
  const isCheckbox = input?.type === "checkbox";
  const isSwitch = input?.type === "switch";
  const isRadio = input?.type === "radio";
  const isDateRange = <T extends { value?: any }>(field: T) =>
    field.value &&
    field.value.hasOwnProperty("from") &&
    field.value.hasOwnProperty("to") &&
    field.value.hasOwnProperty("range");

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState }) => {
        const generalProps = {
          className,
          error: fieldState.error || customError,
        };

        if (onValueChange) {
          onValueChange(field.value);
        }

        if (collection && !smartSelect) {
          return (
            <Select
              {...generalProps}
              collection={collection}
              isLoading={isLoading}
              label={label}
              testId={testId}
              selectProps={{
                id,
                ...select,
                ...field,
                onChange: select?.multiple
                  ? (event: ChangeEvent<HTMLSelectElement>) => {
                      const options = (event?.target as HTMLSelectElement)?.options;
                      const val = [];
                      for (let index = 0, length = options.length; index < length; index += 1) {
                        if (options[index].selected) {
                          val.push(options[index].value);
                        }
                      }
                      field.onChange(val);
                    }
                  : field.onChange,
              }}
            />
          );
        }

        if (smartSelect) {
          return (
            <FormGroup {...generalProps} id={id} label={label}>
              <SmartSelect {...smartSelect} {...field} id={id} />
            </FormGroup>
          );
        }

        if (textarea) {
          return (
            <Textarea
              {...generalProps}
              label={label}
              testId={testId}
              textarea={{
                id,
                ...textarea,
                ...field,
              }}
            />
          );
        }

        if (isCheckbox) {
          return (
            <Checkbox
              {...generalProps}
              isFullWidth={isFullWidth}
              label={label}
              testId={testId}
              input={{
                id: id as string,
                ...input,
                ...field,
                onBlur: (event) => {
                  field.onBlur();
                  if (input?.onBlur) {
                    input.onBlur(event);
                  }
                },
                checked: field?.value,
              }}
            />
          );
        }

        if (isSwitch) {
          return (
            <Switch
              {...generalProps}
              isFullWidth={isFullWidth}
              label={label}
              testId={testId}
              input={{
                id: id as string,
                ...input,
                ...field,
                onBlur: (event) => {
                  field.onBlur();
                  if (input?.onBlur) {
                    input.onBlur(event);
                  }
                },
                checked: field?.value,
              }}
            />
          );
        }

        if (isRadio && radioOptions) {
          return (
            <Radio
              {...generalProps}
              label={label}
              radioOptions={radioOptions}
              testId={testId}
              input={{
                id,
                ...input,
                ...field,
              }}
            />
          );
        }

        if (isDateRange(field)) {
          const value = formatDateRangeSelection(field.value);
          return (
            <Input
              {...generalProps}
              isLoading={isLoading}
              label={label}
              inputProps={{
                id,
                ...input,
                ...field,
                onBlur: (event) => {
                  field.onBlur();
                  if (input?.onBlur) {
                    input.onBlur(event);
                  }
                },
                value: value || "",
              }}
              onClear={onClear}
            />
          );
        }

        if (autoCompleteOptions) {
          return (
            <AutoComplete
              {...generalProps}
              collection={autoCompleteOptions.collection}
              isLoading={isLoading}
              label={label}
              testId={testId}
              variableName={autoCompleteOptions.variableName}
              inputProps={{
                id,
                ...field,
                value: field.value || "",
                onChange: (value) => field.onChange(value),
                placeholder,
              }}
              onClear={(event) => {
                if (onClear) {
                  onClear(event);
                } else {
                  field.onChange("");
                }
              }}
            />
          );
        }

        return (
          <Input
            {...generalProps}
            hasCopyBtn={hasCopyBtn}
            isLoading={isLoading}
            label={label}
            testId={testId}
            inputProps={{
              id,
              ...input,
              ...field,
              onBlur: (event) => {
                if (input?.onBlur) {
                  input.onBlur(event);
                }
                field.onBlur();
              },
              value: input?.type === "file" ? undefined : isDate ? formatDate(field.value) : field.value,
              onChange:
                input?.type === "file"
                  ? (event) => field.onChange((event.target as HTMLInputElement).files)
                  : field.onChange,
            }}
            onClear={(event) => {
              if (onClear) {
                onClear(event);
              } else {
                field.onChange("");
              }
            }}
          />
        );
      }}
    />
  );
};
