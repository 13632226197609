import React from "react";
import { Dictionary } from "lodash";
import { SubAccountT, SystemNameT } from "../../graphql/generated/graphql";
import { SourceSystemsT } from "../../types/report";
import { Modal } from "../../ui/Modal";
import { FormChooseAccountPropsT } from "../formChooseAccount/FormChooseAccount";
import { GetAccountLinkT } from "./AccountSelect";
import { AccountsModalContent } from "./AccountsModalContent";
import { SystemsModalContent } from "./SystemsModalContent";

export type AccountSelectModalPropsT = Pick<FormChooseAccountPropsT, "onSubmit"> & {
  editedSystem?: SystemNameT | null;
  getAccountLink: GetAccountLinkT;
  groupedSubAccounts: Dictionary<SubAccountT[]>;
  onClose: () => void;
  onShowSelectSystemModal: (systemName: SystemNameT) => void;
  refetchSubAccounts: () => void;
  requiredSystems: SystemNameT[];
  selectedAccounts?: SourceSystemsT;
};

export const AccountSelectModal = ({
  editedSystem,
  groupedSubAccounts,
  onClose,
  onSubmit,
  requiredSystems = [],
  selectedAccounts,
  ...rest
}: AccountSelectModalPropsT) => {
  return (
    <Modal
      close={onClose}
      heading="Select data source to show in report"
      systemIcon={editedSystem && editedSystem}
      testId="selected_system_modal"
      isOpen
    >
      {editedSystem && (
        <AccountsModalContent
          {...rest}
          accounts={groupedSubAccounts[editedSystem]}
          editedSystem={editedSystem}
          selectedAccounts={selectedAccounts}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      )}
      {!editedSystem && (
        <SystemsModalContent
          {...rest}
          requiredSystems={requiredSystems}
          subAccountsKeys={Object.keys(groupedSubAccounts)}
        />
      )}
    </Modal>
  );
};
