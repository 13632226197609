import React from "react";
import { SystemNameT } from "../../graphql/generated/graphql";
import { HeadingSmall } from "../../ui/Heading/Heading";
import { ConnectSystemItem, ConnectSystemItemPropsT } from "./ConnectSystemItem";

type PropsT = Omit<ConnectSystemItemPropsT, "systemName" | "isConnected"> & {
  heading?: string;
  isConnectedCallback: (system: SystemNameT) => boolean;
  systems: SystemNameT[];
};

export const ConnectSystemList = ({ heading, isConnectedCallback, systems, ...rest }: PropsT) => (
  <>
    {heading && heading.length > 0 && <HeadingSmall className="mt-8">{heading}</HeadingSmall>}
    <div className="d-flex flex-wrap flex-gap-16">
      {systems.map((system) => (
        <ConnectSystemItem {...rest} key={system} isConnected={isConnectedCallback(system)} systemName={system} />
      ))}
    </div>
  </>
);
