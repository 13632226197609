import React from "react";
import { notifyError, notifySucces } from "../functions/toast";
import {
  CustomValueMetricsQueryResultT,
  useCustomValueMetricsQuery,
  useDeleteCustomValueMetricMutation,
} from "../graphql/generated/graphql";
import { useAppSettings } from "../providers/appSettingsProvider";
import { SortableAdditionalPropsT, SortableWrapper } from "../ui/DataTable/Sortable";
import { TBody, THead, Table, Th, TrHeader } from "../ui/Table/Table";
import { GeneralMetricDimensionTable } from "./GeneralMetricDimensionTable";
import { CustomValueMetricT, ValueMetricTableRow } from "./ValueMetricTableRow";

type ValueMetricTableRowsT = CustomValueMetricT[];

export const ValueMetricsTable = ({
  refetch,
  sortableProps,
  tableRows,
}: Pick<CustomValueMetricsQueryResultT, "refetch"> & {
  sortableProps: SortableAdditionalPropsT;
  tableRows: ValueMetricTableRowsT;
}) => {
  const { organization } = useAppSettings();

  const [deleteCustomValueMetric] = useDeleteCustomValueMetricMutation({
    onCompleted: (res) => {
      const errors = res.organization?.deleteCustomValueMetric?.errors;
      if (errors?.length) {
        return notifyError(<>{errors.join("\n")}</>);
      }
      notifySucces(
        <>
          Custom value metric{" "}
          <span className="Text Text--white Text--bold">
            {res.organization?.deleteCustomValueMetric?.customValueMetric?.name}
          </span>{" "}
          was deleted successfuly.
        </>
      );
    },
    onError: () => notifyError(<>Server error when deleting metric!</>),
    update() {
      refetch();
    },
  });

  return (
    <Table>
      <THead>
        <TrHeader>
          <Th style={{ width: "300px", minWidth: "300px" }}>
            <SortableWrapper {...sortableProps} sortByValue="name">
              Name
            </SortableWrapper>
          </Th>
          <Th>Description</Th>
          <Th>Aggregation</Th>
          <Th>Data Type</Th>
          <Th>Currency</Th>
          <Th>
            <SortableWrapper {...sortableProps} sortByValue="referencesCount">
              Used
            </SortableWrapper>
          </Th>
          <Th>Created by</Th>
        </TrHeader>
      </THead>
      <TBody>
        {tableRows.map((customValueMetric) => (
          <ValueMetricTableRow
            key={customValueMetric.id}
            customValueMetric={customValueMetric}
            onDelete={() =>
              deleteCustomValueMetric({
                variables: {
                  customValueMetricId: customValueMetric.id,
                  organizationExternalId: organization.externalId,
                },
              })
            }
          />
        ))}
      </TBody>
    </Table>
  );
};

export const ValueMetricsPageConnected = () => {
  const { organization } = useAppSettings();
  const response = useCustomValueMetricsQuery({
    variables: {
      organizationExternalId: organization.externalId,
      limit: 1000,
      offset: 0,
    },
  });

  return <GeneralMetricDimensionTable {...response} type="customValue" />;
};
