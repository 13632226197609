/* eslint-disable id-length */
import { get } from "lodash";
import i18n from "./en.json";

export const t = (key: string, scope?: string): string => {
  const path = scope ? [scope, key].join(".") : key;

  const translation = get(i18n, path);

  if (typeof translation === "string") {
    return translation;
  }

  return path;
};
