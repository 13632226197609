import React, { ForwardedRef } from "react";
import { CusotmIconBasicPropsT } from "./IconCustom";

export const WarningSmIcon = React.forwardRef(
  ({ className, size, ...rest }: CusotmIconBasicPropsT, ref: ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.8779 2.27622C8.49888 1.58136 7.50112 1.58136 7.1221 2.27622L0.806647 13.8546C0.44317 14.5209 0.925483 15.3334 1.68454 15.3334H14.3155C15.0745 15.3334 15.5568 14.5209 15.1934 13.8546L8.8779 2.27622Z"
        fill="#F59E0B"
      />
      <path d="M8.6665 5.64819H7.33317V10.3149H8.6665V5.64819Z" fill="#241C00" />
      <path
        d="M7.1665 12.3149C7.1665 12.7749 7.53984 13.1482 7.99984 13.1482C8.45984 13.1482 8.83317 12.7749 8.83317 12.3149C8.83317 11.8549 8.45984 11.4815 7.99984 11.4815C7.53984 11.4815 7.1665 11.8549 7.1665 12.3149Z"
        fill="#241C00"
      />
    </svg>
  )
);
