import React, { useState } from "react";
import { ROUTES } from "../../constants/routes";
import { useUpdateReport } from "../../hooks/useUpdateReport";
import { Subheader } from "../../layout/wrappers/Subheader";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { ButtonPrimary } from "../../ui/Button/Button";
import { ConditionalWrapper } from "../../ui/ConditionalWrapper/ConditionalWrapper";
import { DropdownMenu } from "../../ui/Dropdown/DropdownMenu";
import { InlineEditOnChangeT } from "../../ui/forms/InlineEdit";
import { Row } from "../../ui/grid/Grid";
import { Tooltip } from "../../ui/Tooltip/Tooltip";
import { DuplicatedToOrganizationModal } from "../reportList/DuplicatedToOrganizationModal";
import { useReportBoardContext } from "./context/reportBoardContext";

export type ReportBoardHeaderPropsT = {
  isOnDeleteReportLoading?: boolean;
  isOnDuplicationReportLoading?: boolean;
  isTemplate?: boolean;
  onDeleteReport?: (reportId: string) => void;
  onDuplicateReport?: ({ name, reportId }: { name: string; reportId: string }) => void;
  onSharingModalOpen?: () => void;
};

export const ReportBoardHeader = ({
  isOnDeleteReportLoading,
  isOnDuplicationReportLoading,
  isTemplate,
  onDeleteReport,
  onDuplicateReport,
  onSharingModalOpen,
}: ReportBoardHeaderPropsT) => {
  const { hasReportUnsavedChanges, report, reportOptions } = useReportBoardContext();
  const {
    reportToken,
    user: { organizations },
  } = useAppSettings();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { updateReport } = useUpdateReport({});

  const handleUpdateReportName: InlineEditOnChangeT = (value) => {
    return updateReport({ name: value });
  };

  return (
    <ConditionalWrapper
      condition={!isTemplate}
      wrapper={(child) => (
        <Subheader
          backTo={reportToken ? undefined : ROUTES.reports()}
          title={report.name}
          onTitleChange={handleUpdateReportName}
        >
          {child}
        </Subheader>
      )}
    >
      <Row>
        {/* TODO - waiting for public share page */}
        {onSharingModalOpen && reportOptions.canEdit && (
          <Tooltip tooltipContent={hasReportUnsavedChanges ? "You can set sharing only for saved report" : undefined}>
            <div>
              <ButtonPrimary
                className="mr-8"
                data-test-id="button-share-report"
                disabled={hasReportUnsavedChanges}
                icon="share"
                onClick={onSharingModalOpen}
              >
                Share
              </ButtonPrimary>
            </div>
          </Tooltip>
        )}

        {onDuplicateReport && onDeleteReport && reportOptions.canEdit && (
          <div>
            <DropdownMenu
              buttonProps={{ variant: "secondary" }}
              isLoading={isOnDeleteReportLoading || isOnDuplicationReportLoading}
              testId="Dropdown--report-actions"
              collection={[
                {
                  value: "Edit report",
                  icon: "edit",
                  to: ROUTES.reportEdit(report.id),
                },
                {
                  value: "Duplicate report",
                  isDisabled: hasReportUnsavedChanges,
                  icon: "duplicate",
                  onClick: () => onDuplicateReport({ name: report.name, reportId: report.id }),
                },
                ...(!organizations.available.length
                  ? []
                  : [
                      {
                        value: "Duplicate to Organization",
                        icon: "duplicate",
                        onClick: () => setIsModalOpen(true),
                      },
                    ]),

                {
                  value: "Delete report",
                  icon: "trash",
                  color: "danger",
                  onClick: () => onDeleteReport(report.id),
                },
              ]}
            />
          </div>
        )}
        {isModalOpen && (
          <DuplicatedToOrganizationModal
            reportId={report.id}
            reportName={report.name}
            onModalClose={() => setIsModalOpen(false)}
          />
        )}
      </Row>
    </ConditionalWrapper>
  );
};
