import { ReportMetricT } from "../components/reportBoard/context/types";
import { MetricDataT } from "../graphql/generated/graphql";

export const getDecimalsCount = (num: number | string | null) => {
  if (!num) {
    return 0;
  }
  const numStr = typeof num === "number" ? String(num) : num;

  if (numStr.includes(".") || numStr.includes(",")) {
    return numStr.split(/[,.]/)[1].length;
  }

  return 0;
};

const getDecimals = (decimals?: number, defaultDecimals?: number) => {
  if (typeof decimals === "undefined") {
    return defaultDecimals;
  }

  return decimals;
};

export const numberWithThousandDelimiter = (value: number | string): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatCurrency = (value?: number | null, currency?: string | null, decimals?: number) => {
  if ((value || value === 0) && currency) {
    return `${currency} ${numberWithThousandDelimiter(value.toFixed(getDecimals(decimals, 2)))}`;
  } else if (value || value === 0) {
    return numberWithThousandDelimiter(value.toFixed(getDecimals(decimals, 2)));
  }
  return "-";
};

export const formatPercents = (value?: number | null, decimals?: number) => {
  if (value || value === 0) {
    const shouldShowDecimals = Math.abs(value) >= 1;
    return `${numberWithThousandDelimiter((value * 100).toFixed(shouldShowDecimals ? 0 : getDecimals(decimals, 2)))} %`;
  }
  return "-";
};

export const formatByDataType = (
  dataType: MetricDataT,
  currency?: string | null,
  value?: number | null,
  decimals?: number
): string => {
  if (value || value === 0) {
    switch (dataType) {
      case MetricDataT.FloatT:
        return numberWithThousandDelimiter(value.toFixed(getDecimals(decimals, 2)));
      case MetricDataT.IntegerT:
        return numberWithThousandDelimiter(Math.round(value));
      case MetricDataT.MoneyT:
        return formatCurrency(value, currency, decimals);
      case MetricDataT.PercentT:
        return formatPercents(value, decimals);
    }
  }

  return "-";
};

export const formatMetricValue = ({
  currency,
  decimals,
  metric,
  value,
}: {
  currency?: string | null;
  decimals?: number;
  metric: Pick<ReportMetricT, "dataType">;
  value?: number | null;
}): string => {
  return formatByDataType(metric.dataType, currency, value, decimals);
};
