import { ChangeEvent } from "react";
import { pick } from "lodash";
import { ParseResult, parse, unparse } from "papaparse";
import { formatDate } from "./dateHelpers";

type CsvItemT = { to?: string; what?: string };
export type CsvResultT = CsvItemT[];

const downloadTextFile = ({ data, fileName, fileType }: { data: string; fileName: string; fileType: string }) => {
  const blob = new Blob([data], { type: `text/${fileType}` });
  const url = URL.createObjectURL(blob);

  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = `${fileName}.${fileType}`;
  downloadLink.click();
};

export const exportToCsvDimensionValues = (data: CsvResultT) => {
  const clearedData = data.map((item) => pick(item, ["what", "to"]));
  const csvData = unparse(clearedData.filter((row) => row.to || row.what));
  downloadTextFile({ data: csvData, fileName: "dimension-value-export", fileType: "csv" });
};

export const exportToCsvWidgetData = ({ data, widgetName }: { data: string[][]; widgetName: string }) => {
  const csvData = unparse(data);
  downloadTextFile({
    data: csvData,
    fileName: `${widgetName.replace(", ", "_").replace(" ", "_")}_${formatDate(new Date(), "dateApi")}`,
    fileType: "csv",
  });
};

export const importCSV = (event: ChangeEvent<HTMLInputElement>, complete: (data: CsvResultT) => void) => {
  if (event.target.files) {
    parse(event.target.files[0], {
      complete: (csv: ParseResult<string[]>) => {
        if (csv?.data[0][0] === "what" && csv.data[0][1] === "to") {
          csv.data.shift();
        }
        complete(csv?.data.map((row) => ({ what: row[0], to: (row[1] || "").replace(/[\n\r]+/g, " ") })));
      },
      header: false,
    });
  }

  event.target.value = "";
};
