import { DateRangeEnumT, DateRangeT, Maybe, WidgetDimensionT, WidgetMetricT } from "../graphql/generated/graphql";

export const fetchDimensionId = (widgetDimension: WidgetDimensionT): string =>
  `DimensionId:${widgetDimension.dimensionId}`;

const fetchDateRange = (dateRange?: Maybe<DateRangeT>) => {
  if (!dateRange?.range) {
    return;
  }

  if (dateRange.range == DateRangeEnumT.RangeCustomT) {
    return ["custom", dateRange.from, dateRange.to].join("-");
  }

  return dateRange.range.replace("RANGE_", "").toLowerCase();
};
export const fetchMetricId = (widgetMetric: WidgetMetricT): string =>
  ["MetricId", widgetMetric.metricId, widgetMetric.compare?.toLowerCase(), fetchDateRange(widgetMetric.dateRange)]
    .filter((text) => !!text)
    .join(":");
