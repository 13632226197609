import React, { useState } from "react";
import { FormCustomMetric } from "../components/formCustomMetric/FormCustomMetric";
import { notifyError, notifySucces } from "../functions/toast";
import { CustomMetricsDocumentT, useCreateCustomMetricMutation } from "../graphql/generated/graphql";
import { useAppSettings } from "../providers/appSettingsProvider";

export const MetricNewModal = ({ onCreate }: { onCreate?: (id: string) => void }) => {
  const [serverErrors, setServerErrors] = useState<string[] | undefined>(undefined);

  const {
    organization: { externalId },
  } = useAppSettings();

  const [mutate, { loading }] = useCreateCustomMetricMutation({
    onCompleted: (res) => {
      const metricResponse = res.organization?.createCustomMetric;
      const errors = metricResponse?.errors;

      if (errors?.length) {
        return setServerErrors(errors);
      }
      notifySucces(<>{`Custom metric created!`}</>);
      if (onCreate && metricResponse?.customMetric?.id) {
        onCreate(metricResponse.customMetric.id);
      }
    },
    refetchQueries: [CustomMetricsDocumentT],
    onError: () => notifyError(<>{`Server error when creating custom metric!`}</>),
  });
  return (
    <FormCustomMetric
      isMutationLoading={loading}
      serverErrors={serverErrors}
      onSubmit={(values) =>
        mutate({
          variables: {
            ...values,
            organizationExternalId: externalId,
          },
        })
      }
    />
  );
};
