import { ChangeEvent, createContext, useContext } from "react";

type SegmentedButtonContextT = {
  id: string;
  name: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  selected: string | number;
  testId: string;
};

const SegmentedButtonContext = createContext<null | SegmentedButtonContextT>(null);

const useSegmentedButtonContext = () => {
  const context = useContext(SegmentedButtonContext);
  if (!context) {
    throw new Error(`SegmentedButton compound components cannot be rendered outside the SegmentedButton component`);
  }
  return context;
};

export { useSegmentedButtonContext, SegmentedButtonContext };
