import React, { ForwardedRef } from "react";
import { CheckCircleIcon } from "./CheckCircleIcon";
import { CheckCircleSmIcon } from "./CheckCircleIconSm";
import { ErrorHexIcon } from "./ErrorHexIcon";
import { ErrorHexSmIcon } from "./ErrorHexSmIcon";
import { FlameIcon } from "./FlameIcon";
import { FlameSmIcon } from "./FlameSmIcon";
import { HandCircleIcon } from "./HandCircleIcon";
import { HandCircleSmIcon } from "./HandCircleSmIcon";
import { HourglassHexIcon } from "./HourglassHexIcon";
import { HourglassHexSmIcon } from "./HourglassHexSmIcon";
import { WarningIcon } from "./WarningIcon";
import { WarningSmIcon } from "./WarningSmIcon";

export enum CustomIconNameT {
  CheckCircleT = "checkCircle",
  ErrorHexT = "errorHex",
  FlameT = "flame",
  HandCircleT = "HandCircle",
  HourglassHexT = "HourglassHex",
  WarningT = "warning",
}

export type CusotmIconBasicPropsT = {
  className?: string;
  size: number;
};

type PropsT = CusotmIconBasicPropsT & {
  className?: string;
  name: CustomIconNameT;
};

export const IconCustom = React.forwardRef(
  ({ className, name, size, ...rest }: PropsT, ref: ForwardedRef<SVGSVGElement>) => {
    const isSmall = size < 20;
    const props = { size, ref, className, ...rest };

    if (name === CustomIconNameT.FlameT) {
      return !isSmall ? <FlameIcon {...props} /> : <FlameSmIcon {...props} />;
    }
    if (name === CustomIconNameT.WarningT) {
      return !isSmall ? <WarningIcon {...props} /> : <WarningSmIcon {...props} />;
    }
    if (name === CustomIconNameT.HandCircleT) {
      return !isSmall ? <HandCircleIcon {...props} /> : <HandCircleSmIcon {...props} />;
    }
    if (name === CustomIconNameT.CheckCircleT) {
      return !isSmall ? <CheckCircleIcon {...props} /> : <CheckCircleSmIcon {...props} />;
    }
    if (name === CustomIconNameT.HourglassHexT) {
      return !isSmall ? <HourglassHexIcon {...props} /> : <HourglassHexSmIcon {...props} />;
    }
    if (name === CustomIconNameT.ErrorHexT) {
      return !isSmall ? <ErrorHexIcon {...props} /> : <ErrorHexSmIcon {...props} />;
    }
    return null;
  }
);
