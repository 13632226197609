import React, { ReactElement, ReactNode } from "react";
import classnames from "classnames";
import { FieldError } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";
import Label from "./Label";

type PropsT = {
  children: ReactNode;
  className?: string;
  error?: FieldError;
  htmlFor?: string;
  id?: string;
  isCheckbox?: boolean;
  isSegmentedButton?: boolean;
  isSwitch?: boolean;
  label?: string | number | ReactElement;
  name?: string;
  testId?: string;
};

const FormGroup = ({
  children,
  className,
  error,
  htmlFor,
  id,
  isCheckbox,
  isSegmentedButton,
  isSwitch,
  label,
  name,
  testId,
}: PropsT) => {
  return (
    <div
      data-test-id={testId}
      className={classnames(className, {
        Input: !isCheckbox && !isSwitch && !isSegmentedButton,
        "has-error": error?.message,
      })}
    >
      {label && (
        <Label className="d-block" htmlFor={htmlFor || id || name}>
          {label}
        </Label>
      )}

      {children}

      <ErrorMessage error={error} />
    </div>
  );
};

export default FormGroup;
