import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { withApiStateHandler } from "../components/ErrorLoadingWrapper/withApiStateHandler";
import { FormKpi } from "../components/formKpi/FormKpi";
import { ROUTES } from "../constants/routes";
import { notifyError, notifySucces } from "../functions/toast";
import { removeTypenameOnArrayFields } from "../functions/widgetDataHelpers";
import {
  KpiSettingUpdateMutationVariablesT,
  KpisEditPageQueryT,
  TargetInputT,
  useKpiSettingUpdateMutation,
  useKpisEditPageQuery,
} from "../graphql/generated/graphql";
import { Layout } from "../layout/wrappers/Layout";
import { useAppSettings } from "../providers/appSettingsProvider";
import { RouteParamsT } from "../types/router";

type PropsT = {
  data?: KpisEditPageQueryT;
  kpiSettingId: string;
  loading: boolean;
};

export const KpisEditPageComponent = withApiStateHandler(({ data, kpiSettingId, loading: isDataLoading }: PropsT) => {
  const { organization } = useAppSettings();
  const navigate = useNavigate();

  const [mutation, mutateState] = useKpiSettingUpdateMutation({
    onCompleted: (response) => {
      const errors = response?.organization?.updateKpiSetting?.errors;

      if (!!errors?.length) {
        return notifyError(<>Error when creating KPI</>);
      }
      if (response?.organization?.updateKpiSetting?.kpiSetting?.id) {
        notifySucces(<>KPI was updated successfully</>);
        navigate(ROUTES.kpis());
      }
    },
    onError: () => notifyError(<>Server error when updating KPI!</>),
  });

  const handleSubmit = (
    newKpiSetting: Omit<KpiSettingUpdateMutationVariablesT, "id" | "targets"> & { targets: Array<TargetInputT> }
  ) => {
    mutation({
      variables: {
        organizationExternalId: organization.externalId,
        id: kpiSettingId,
        ...newKpiSetting,
        targets: removeTypenameOnArrayFields(newKpiSetting.targets),
        sourceSystems: Array.isArray(newKpiSetting.sourceSystems)
          ? removeTypenameOnArrayFields(newKpiSetting.sourceSystems).map((sourceSystem) => ({
              externalId: sourceSystem.externalId,
              name: sourceSystem.name,
              externalName: sourceSystem.externalName,
            }))
          : null,
      },
      fetchPolicy: "network-only",
    });
  };

  return (
    <Layout backTo={ROUTES.kpis()} kind="formLayout" title="KPI editation" hideSidebar>
      <FormKpi
        defaultValues={data?.organization?.kpiSetting}
        dimensions={data?.organization?.dimensions.nodes}
        isDataLoading={isDataLoading}
        isSubmitLoading={mutateState.loading}
        metrics={data?.organization?.metrics.nodes}
        submitLabel="Update KPI"
        isEditing
        onSubmit={handleSubmit}
      />
    </Layout>
  );
});

export const KpisEditPage = () => {
  const { organization } = useAppSettings();
  const { kpiSettingId } = useParams<RouteParamsT["kpisDetail"]>();

  const { data, error, loading } = useKpisEditPageQuery({
    variables: { organizationExternalId: organization.externalId as string, kpiSettingId: kpiSettingId as string },
    fetchPolicy: "network-only",
  });

  return <KpisEditPageComponent data={data} error={error} kpiSettingId={kpiSettingId as string} loading={loading} />;
};
