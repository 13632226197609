import React from "react";
import { WidgetComponentPropsT } from "../../types/widgets";
import widgetComponentContainer from "./widgetComponentContainer";

export type TitleComponentPropsT = WidgetComponentPropsT;

const WidgetTitleComponent = ({ className, widget }: TitleComponentPropsT) => {
  const Tag = widget.properties?.titleType || "h1";
  return (
    <div className="overflow-auto h-100">
      <Tag className={className}>{widget.properties?.title || "Title Placeholder"}</Tag>
    </div>
  );
};

export default widgetComponentContainer(WidgetTitleComponent);
