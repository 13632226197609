export const getCookie = (name: string) => {
  const cookieArr = document.cookie.split(";");

  for (let index = 0; index < cookieArr.length; index++) {
    const cookiePair = cookieArr[index].split("=");

    if (name == cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }

  return null;
};
