import { HTMLProps, useState } from "react";
import {
  Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFocus,
  useInteractions,
  useRole,
} from "@floating-ui/react";

export const useDropdown = (props?: {
  hasParentWidth?: boolean;
  isDefaultOpen?: boolean;
  offset?: number;
  onClose?: () => void;
  onOpen?: () => void;
  placement?: Placement;
  shouldOpenOnFocus?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(!!props?.isDefaultOpen);
  const nodeId = useFloatingNodeId();

  const onToggle = (value: boolean | ((value: boolean) => boolean)) => {
    setIsOpen((prev) => {
      const result = typeof value === "function" ? value(prev) : value;

      if (result && props?.onOpen) {
        props.onOpen();
      }
      if (!result && props?.onClose) {
        props.onClose();
      }

      return result;
    });
  };

  const { context, floating, reference, strategy, x, y } = useFloating({
    nodeId,
    placement: props?.placement || "bottom-end",
    open: isOpen,
    onOpenChange: onToggle,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(props?.offset || 4),
      shift(),
      flip(),
      size({
        apply({ availableHeight, elements, rects }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight - 20}px`,
            height: "auto",
            width: props?.hasParentWidth && `${rects.reference.width}px`,
          });
        },
      }),
    ],
  });

  const { getFloatingProps, getReferenceProps } = useInteractions([
    useClick(context, { toggle: false }),
    useFocus(context, { enabled: !!props?.shouldOpenOnFocus }),
    useRole(context, { role: "listbox" }),
    useDismiss(context, { referencePress: false, bubbles: false }),
  ]);

  return {
    boxProps: { ...getFloatingProps(), context, floating, strategy, x, y, isOpen, onToggle },
    reference,
    isOpen,
    nodeId,
    triggerElementProps: {
      ...getReferenceProps(),
      "aria-label": "Toggle dropdown",
    } as Pick<
      HTMLProps<HTMLElement>,
      | "aria-controls"
      | "aria-expanded"
      | "aria-haspopup"
      | "aria-label"
      | "onClick"
      | "onKeyDown"
      | "onKeyUp"
      | "onMouseDown"
      | "onPointerDown"
    >,
  };
};
