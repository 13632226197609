import React from "react";
import cs from "classnames";
import { CurrencyT } from "../../graphql/generated/graphql";
import { ConditionsT } from "../../types/widgets";
import { Row } from "../../ui/grid/Grid";
import { FilterButtonWithPreview } from "../filterButtonWithPreview/FilterButtonWithPreview";
import {
  FormReportCompactFilter,
  FormReportCompactFilterInputsT,
} from "../formReportCompactFilter/FormReportCompactFilter";
import { MetricsByIdT } from "./context/types";

type PropsT = {
  canEdit?: boolean;
  canEditConditions?: boolean;
  conditions: ConditionsT;
  defaultValues?: Partial<FormReportCompactFilterInputsT>;
  disableClear?: boolean;
  hasBottomSpacing?: boolean;
  hasCurrency?: boolean;
  hasCurrencyPreview?: CurrencyT | null;
  id: string;
  isInputMinified?: boolean;
  isVissibleOnHover?: boolean;
  metricsById: MetricsByIdT;
  onChange: (filterValues: Partial<FormReportCompactFilterInputsT>) => void;
  onFilterOpen: () => void;
};

export const ReportFilterCompact = ({
  canEdit,
  canEditConditions = true,
  conditions,
  defaultValues,
  disableClear,
  hasBottomSpacing = false,
  hasCurrency,
  hasCurrencyPreview,
  id,
  isInputMinified,
  isVissibleOnHover,
  metricsById,
  onChange,
  onFilterOpen,
}: PropsT) => {
  return (
    <Row alignItems="center" className={cs("flex-gap-8", { "mb-16": hasBottomSpacing })} flexwrap>
      <FormReportCompactFilter
        canEdit={canEdit}
        defaultValues={defaultValues}
        disableClear={disableClear}
        hasCurrency={hasCurrency}
        hasCurrencyPreview={hasCurrencyPreview}
        id={id}
        isInputMinified={isInputMinified}
        isVissibleOnHover={isVissibleOnHover}
        onChange={onChange}
      />

      <FilterButtonWithPreview
        canEdit={canEdit && canEditConditions}
        className={cs({ "show-on-hover-element": isVissibleOnHover })}
        conditions={conditions}
        metricsById={metricsById}
        onFilterOpen={onFilterOpen}
      />
    </Row>
  );
};
