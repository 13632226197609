import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { ReportListT } from "../../types/report";
import { ReportT } from "../../types/widgets";
import { Col, Row } from "../../ui/grid/Grid";
import { Pagination, PaginationPropsT } from "../../ui/Pagination/Pagination";
import { withApiStateHandler } from "../ErrorLoadingWrapper/withApiStateHandler";
import { ReportItem } from "./ReportItem";

type PropsT = {
  canEdit: boolean;
  onDeleteReport: (id: ReportT["id"]) => void;
  pagination: PaginationPropsT;
  reports: ReportListT | undefined;
};

export const ReportList = withApiStateHandler(({ canEdit, onDeleteReport, pagination, reports }: PropsT) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!reports || reports.length === 0) {
      navigate(ROUTES.firstTemplates());
    }
  }, [navigate, reports]);

  return (
    <Col>
      <Row className="mb-24" flexwrap>
        {reports?.map((report) => (
          <ReportItem key={report.id} canEdit={canEdit} report={report} onDeleteReport={onDeleteReport} />
        ))}
      </Row>
      <Pagination className="mb-40" {...pagination} />
    </Col>
  );
});
