import React, { CSSProperties, ForwardedRef, HTMLAttributes, PropsWithChildren, forwardRef } from "react";
import cs from "classnames";

type PropsT = {
  className?: string;
  color?: string;
  isColored?: boolean;
  isInheritColor?: boolean;
  isRotate?: boolean;
  kind: string;
  size?: string;
  style?: CSSProperties;
} & HTMLAttributes<HTMLSpanElement>;

export const Icon = forwardRef(
  (
    {
      children,
      className,
      color,
      isColored,
      isInheritColor,
      isRotate,
      kind,
      size,
      style,
      ...rest
    }: PropsWithChildren<PropsT>,
    ref: ForwardedRef<HTMLElement>
  ) => (
    <span
      {...rest}
      ref={ref}
      role="img"
      style={{ ...style, ...(isInheritColor ? {} : { color }), fontSize: size, animationDuration: "3s" }}
      className={cs(className, `fc-${kind}`, "Icon", {
        "Icon--colored": isColored,
        "rotating-forever": isRotate,
        "d-inline-block": isRotate,
      })}
    >
      {children}
    </span>
  )
);
