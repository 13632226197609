import React, { KeyboardEvent, useState } from "react";
import Input from "./Input";
import {
  MetricDimensionSelectedCategoryT,
  MetricDimensionSelectedItemT,
  MetricOrDimensionT,
} from "./MetricDimensionController";
import { MetricDimensionItemSelect } from "./MetricDimensionItemSelect";
import { MetricDimensionSelectTabs, SelectableDimensionT, SelectableMetricT } from "./MetricDimensionSelectTabs";

export type MetricDimensionSelectPropsT = {
  dimensions?: SelectableDimensionT[];
  disabledIds?: string[];
  disabledTab?: MetricOrDimensionT | null;
  isDisabledNotConnectedWarning?: boolean;
  metrics?: SelectableMetricT[];
  onSetSelectedCategory: (category: MetricDimensionSelectedCategoryT | null) => void;
  onSetSelectedItem: (selectedItem: MetricDimensionSelectedItemT) => void;
  recentDimensions?: string[];
  recentMetrics?: string[];
  searchValue?: string;
  selectedCategory: MetricDimensionSelectedCategoryT | null;
};

const MetricDimensionSelectContent = ({
  dimensions,
  disabledIds,
  disabledTab,
  isDisabledNotConnectedWarning,
  metrics,
  onSetSelectedCategory,
  onSetSelectedItem,
  recentDimensions,
  recentMetrics,
  searchValue,
  selectedCategory,
}: MetricDimensionSelectPropsT) => {
  const generalProps = {
    onSetSelectedItem,
    searchValue,
    selectedCategory,
    onSetSelectedCategory,
    recentDimensions,
    recentMetrics,
    disabledIds,
    isDisabledNotConnectedWarning,
  };

  if (metrics && dimensions) {
    return (
      <MetricDimensionSelectTabs
        {...generalProps}
        dimensions={dimensions}
        disabledIds={disabledIds}
        disabledTab={disabledTab}
        metrics={metrics}
      />
    );
  }

  if (dimensions) {
    return <MetricDimensionItemSelect {...generalProps} type="dimension" values={dimensions} />;
  }

  if (metrics) {
    return <MetricDimensionItemSelect {...generalProps} type="metric" values={metrics} />;
  }

  return <div>No metrics and dimensions provided</div>;
};

export const MetricDimensionSelect = (props: Omit<MetricDimensionSelectPropsT, "searchValue">) => {
  const [searchValue, setSearchValue] = useState("");
  return (
    <>
      <Input
        className="mt-16 mb-2"
        inputProps={{
          id: "MetricDimensionSelect--[search]",
          type: "text",
          isClearable: true,
          autoComplete: "off",
          value: searchValue,
          isWithSearch: true,
          onChange: (event: KeyboardEvent<HTMLInputElement>) => {
            setSearchValue(event.target.value || "");
          },
        }}
        onClear={() => setSearchValue("")}
      />
      <MetricDimensionSelectContent
        {...props}
        searchValue={searchValue}
        onSetSelectedItem={(selectedItem) => {
          props.onSetSelectedItem(selectedItem);
          setSearchValue("");
        }}
      />
    </>
  );
};
