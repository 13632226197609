import React, { useMemo } from "react";
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { CHART_LINE_COLOR_OTHER } from "../../constants/report";
import { getDateGrouppedDateRange } from "../../functions/dateHelpers";
import { fetchDimensionValueFromRowAsText } from "../../functions/widgetDataHelpers";
import { WidgetMetricT, WidgetRowT } from "../../graphql/generated/graphql";
import { formatDateRange } from "../../i18n/formatDate";
import { InfoBox } from "../../ui/InfoBox/InfoBox";
import { MetricInformationCard } from "./MetricInformationCard";
import { SerieT } from "./useTimebasedChart";

const ROWS_LIMIT = 12;

const isMetricOrDimension = (row: WidgetRowT, serie: { dimensionsValue: string; metric: { id: string } }) => {
  return (
    row.widgetMetrics.some((widgetMetric) => widgetMetric.metricId === serie.metric.id) &&
    (row.widgetDimensions.length === 0 ||
      fetchDimensionValueFromRowAsText(row.widgetDimensions.map((wd) => ({ id: `${wd.dimensionId}` })))(row) ===
        serie.dimensionsValue)
  );
};

export const WidgetChartTooltipTimebased = ({
  active,
  payload,
  series,
  widgetMetrics,
}: TooltipProps<ValueType, NameType> & {
  series: SerieT[];
  widgetMetrics: WidgetMetricT[];
}) => {
  const data = payload?.[0];

  const rowsData = useMemo(
    () =>
      series
        .filter((item) => !item.isCompare)
        .reduce(
          (
            acc:
              | []
              | Array<{ foundRow: WidgetRowT; originalData: SerieT; rowMetric: WidgetRowT["widgetMetrics"][0] } | null>,
            serie
          ) => {
            if (acc.length >= ROWS_LIMIT) {
              return [...acc, null];
            }
            const foundRow: WidgetRowT = data?.payload.rowsData.find((row: WidgetRowT) =>
              isMetricOrDimension(row, serie)
            );
            if (!foundRow) {
              return acc;
            }
            const rowMetric = foundRow.widgetMetrics.find((widgetMetric) => widgetMetric.metricId === serie.metric.id);
            if (!rowMetric) {
              return acc;
            }
            return [...acc, { foundRow, rowMetric, originalData: serie }];
          },
          []
        ),
    [data?.payload.rowsData, series]
  );

  if (!data || !active) {
    return null;
  }

  const { dateFrom, dateTo } = getDateGrouppedDateRange({ dateFrom: data.payload.date, ...data.payload });
  const isOverLimit = rowsData.length > ROWS_LIMIT;

  return (
    <div className="Popover custom-tooltip" style={{ borderColor: `1px solid ${CHART_LINE_COLOR_OTHER}` }}>
      <div className="mb-8">{formatDateRange(dateFrom, dateTo)}</div>
      {isOverLimit && (
        <InfoBox className="mb-16" variant="warning" withIcon>
          Showing {ROWS_LIMIT} rows out of {rowsData?.length}. To view all data, please open "Raw data modal" in the
          widget menu.
        </InfoBox>
      )}
      {rowsData.map((row) => {
        if (!row) {
          return null;
        }
        return (
          <div key={row.originalData.key} className="mb-16">
            <MetricInformationCard
              color={row.originalData.color}
              currency={row.foundRow?.currency}
              hidePill={widgetMetrics.length === 1}
              metric={row.originalData.metric}
              previousValue={row.rowMetric.previousValue}
              size="small-inline"
              trend={row.rowMetric.trend}
              value={row.rowMetric.value}
              compareDate={
                row.rowMetric?.previousDate && row.rowMetric?.previousDateTo
                  ? { previousDateTo: row.rowMetric?.previousDateTo, previousDate: row.rowMetric.previousDate }
                  : undefined
              }
            >
              {row.originalData.dimensionsValue}
            </MetricInformationCard>
          </div>
        );
      })}
    </div>
  );
};
