import React from "react";
import { WidgetComponentPropsT } from "../../types/widgets";
import { Icon } from "../../ui/Icon/Icon";
import widgetComponentContainer from "./widgetComponentContainer";

export type ImageComponentPropsT = WidgetComponentPropsT;

const WidgetImageComponent = ({ className, widget }: ImageComponentPropsT) => (
  <div className={className}>
    {widget.properties?.url ? (
      <img alt="" src={widget.properties?.url} />
    ) : (
      <Icon className="pos-absolute align-center" color="#e5e7e9" kind="empty-image" size="128px" />
    )}
  </div>
);

export default widgetComponentContainer(WidgetImageComponent);
