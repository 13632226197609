import React, { HTMLProps, MouseEvent, ReactElement, useCallback, useState } from "react";
import cs from "classnames";
import { debounce } from "lodash";
import { FieldError } from "react-hook-form";
import { notifySucces } from "../../functions/toast";
import { ButtonSecondary } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { Loader } from "../Loader/Loader";
import FormGroup from "./FormGroup";

export type InputT = HTMLProps<HTMLInputElement> & {
  beforeUnit?: string;
  hasArrow?: boolean;
  icon?: string;
  isClearable?: boolean;
  isInputContentOpen?: boolean;
  isWithSearch?: boolean;
  minified?: boolean;
  onChangeDebounced?: (value: string) => void;
  unit?: string;
};

export type InputPropsT = {
  className?: string;
  error?: FieldError;
  hasCopyBtn?: boolean;
  inputProps: InputT;
  isLoading?: boolean;
  label?: string | ReactElement;
  onClear?: (event: MouseEvent<HTMLElement>) => void;
  testId?: string;
};

const Input = ({ className, error, hasCopyBtn, inputProps, isLoading, label, onClear, testId }: InputPropsT) => {
  const [value, setValue] = useState(inputProps.value);
  const {
    beforeUnit,
    hasArrow,
    icon,
    isClearable,
    isInputContentOpen,
    isWithSearch,
    minified,
    onChange,
    onChangeDebounced,
    unit,
    ...inputPropsRest
  } = inputProps;

  const isInputVisible = !minified || inputProps.value || value;
  const isClearBtnVissible =
    !inputProps?.disabled &&
    inputProps?.isClearable &&
    isInputVisible &&
    typeof onClear === "function" &&
    (value || inputProps.value);

  const htmlFor = inputProps.id || inputPropsRest.name;

  const handleChangeDebounced = useCallback(
    (newValue: string) => {
      if (onChangeDebounced) {
        onChangeDebounced(newValue);
      }
    },
    [onChangeDebounced]
  );

  const debouncedOnChange = React.useRef(debounce(handleChangeDebounced, 300)).current;

  return (
    <FormGroup
      error={error}
      id={htmlFor}
      label={label}
      testId={testId}
      className={cs(className, "text-left", {
        "pointer-events-none no-user-select": icon && isLoading,
        "Input--noBorder": icon || unit || beforeUnit,
        "Input--search": isWithSearch,
        open: isInputContentOpen,
      })}
    >
      {icon && !isInputVisible && (
        <label htmlFor={htmlFor}>
          <ButtonSecondary data-test-id="input-icon" icon={icon} onlyIcon onClick={() => {}} />
        </label>
      )}
      <div
        data-active-element={inputProps.value && inputProps.value !== ""}
        style={{ ...inputProps.style, width: isInputVisible ? inputProps.style?.width : "unset" }}
        className={cs("pos-relative", {
          "d-none": icon && !isInputVisible,
          "Input-unitWrapper": beforeUnit || unit,
          "Input-iconWrapper": icon,
          active: isInputContentOpen,
        })}
      >
        {isLoading && <Loader size="small" style={{ borderRadius: "4px" }} absolute />}

        {icon && isInputVisible && (
          <label className="before label" htmlFor={htmlFor}>
            <Icon data-test-id="input-icon" kind={icon} />
          </label>
        )}

        {beforeUnit && isInputVisible && (
          <label className="before label" htmlFor={htmlFor}>
            {beforeUnit}
          </label>
        )}

        <input
          {...inputPropsRest}
          className={cs(inputPropsRest.className, { text_with_unit: inputProps?.unit })}
          id={htmlFor}
          type={inputProps?.type || "text"}
          value={onChangeDebounced ? value : inputProps.value}
          style={{
            ...inputProps.style,
            ...((isClearBtnVissible || hasArrow) && { paddingRight: "28px" }),
            ...(isClearBtnVissible && hasArrow && { paddingRight: "48px" }),
            ...(hasCopyBtn && { paddingRight: "60px" }),
          }}
          onChange={
            inputProps.onChangeDebounced
              ? (event) => {
                  setValue(event.currentTarget.value);
                  debouncedOnChange(event.currentTarget.value);
                }
              : inputProps.onChange
          }
        />

        {hasCopyBtn && (
          <ButtonSecondary
            size="small"
            style={{
              position: "absolute",
              right: "4px",
              top: "4px",
            }}
            onClick={() => {
              navigator.clipboard.writeText(`${value || ""}`);
              notifySucces("Password has been copied to clipboard");
            }}
          >
            Copy
          </ButtonSecondary>
        )}

        {unit && (
          <label className="after label" htmlFor={htmlFor}>
            {unit}
          </label>
        )}

        {isClearBtnVissible && (
          <div
            className="Input-reset fc-close"
            data-test-id="clear-input"
            style={{ right: hasArrow ? "30px" : "8px" }}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.currentTarget.blur();
              setValue("");
              if (typeof onClear === "function") {
                onClear(event);
              }
            }}
          />
        )}

        {hasArrow && <div className="Input-chevron fc-chevron-down" />}
      </div>
      {isWithSearch && <i className="Input-magnifier fc-input-search" style={{ cursor: "auto" }} />}
    </FormGroup>
  );
};

export default Input;
