import React, { useCallback } from "react";
import emptyStateImage from "../../assets/suborganization.png";
import { ORDERED_KPI_PRIORITIES } from "../../constants/kpi";
import { useSearch } from "../../hooks/useSearch";
import { SortableWrapper, useSortable } from "../../ui/DataTable/Sortable";
import { Col, Row } from "../../ui/grid/Grid";
import { EmptyState } from "../../ui/Table/EmptyState";
import { TBody, THead, Table, Th, TrHeader } from "../../ui/Table/Table";
import { Text } from "../../ui/Text/Text";
import { SearchInput } from "../search/Search";
import { OrganizationKpiListItem, OrganizationKpiListItemPropsT } from "./OrganizationKpiListItem";

type PropsT = {
  onDeleteKpiSettings: (kpiSettingIds: string[]) => void;
  organizations: OrganizationKpiListItemPropsT["organization"][];
};

export const OrganizationsKpiList = ({ onDeleteKpiSettings, organizations }: PropsT) => {
  const filterSearchRows = useCallback((organization: PropsT["organizations"][0]) => organization.name || "", []);
  const { filterData: filterSearchData, searchInputProps } = useSearch<PropsT["organizations"]>(filterSearchRows);
  const isListEmpty = !organizations.length;
  const filteredData = filterSearchData(organizations);

  const [sortableProps, sortedTableRows] = useSortable<typeof organizations>(
    filteredData,
    useCallback(
      (sortBy) => (row) => {
        if (sortBy === "kpiProgressSummary") {
          return row.kpiProgressSummary?.estimatedProgress || null;
        }
        return row?.[sortBy as "name"] || null;
      },
      []
    ),
    { sortBy: "name" }
  );

  return (
    <div>
      <Row className="mv-16">
        <Col width="23em">
          <SearchInput {...searchInputProps} />
        </Col>
      </Row>

      {isListEmpty && (
        // TODO: Change image
        <EmptyState heading="No suborganizations here." image={emptyStateImage} />
      )}

      {!isListEmpty && (sortedTableRows.length || 0) === 0 && (
        <Text className="text-center">
          None of the items match your search query. Try to modify what you're looking for.
        </Text>
      )}

      {!isListEmpty && (sortedTableRows.length || 0) > 0 && (
        <Table doNotStickFirstColumn isBigRowHeight>
          <THead>
            <TrHeader>
              <Th className="text-center">
                <SortableWrapper {...sortableProps} sortByValue="kpiProgressSummary">
                  Overall Score
                </SortableWrapper>
              </Th>
              <Th>
                <SortableWrapper {...sortableProps} sortByValue="name">
                  Suborganization
                </SortableWrapper>
              </Th>
              {ORDERED_KPI_PRIORITIES.map((priority) => (
                <Th key={priority} className="first-capital">
                  {priority}
                </Th>
              ))}
              <Th width="32" />
              <Th width="32" />
            </TrHeader>
          </THead>
          <TBody>
            {sortedTableRows.map((organization) => (
              <OrganizationKpiListItem
                key={organization.id}
                organization={organization}
                onDeleteKpiSettings={onDeleteKpiSettings}
              />
            ))}
          </TBody>
        </Table>
      )}
    </div>
  );
};
