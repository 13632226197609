import React, { HTMLProps, MouseEvent, PropsWithChildren } from "react";
import cs from "classnames";
import { useTabsContext } from "./TabsContext";

type PropsT = PropsWithChildren<{ id: string }> &
  HTMLProps<HTMLLIElement> & { onClick?: (event: MouseEvent<HTMLAnchorElement>) => void };

export const Tab = ({ children, className, disabled, id, onClick, ...rest }: PropsT) => {
  const { selectTab, selectedTab, testId } = useTabsContext();

  const isSelected = selectedTab === id;

  return (
    <li
      {...rest}
      aria-controls={`panel-${id}`}
      aria-disabled={disabled}
      aria-selected={isSelected}
      className={cs("TabNavigation-item flex-1 text-center", className, { active: isSelected, disabled })}
      data-test-id={`${testId}-Tab-${id}`}
      id={`tab-${id}`}
      role="tab"
    >
      <a
        className="ph-16 pv-12"
        data-test-id={`${testId}-Tab-link-${id}`}
        onClick={
          !disabled
            ? (event) => {
                if (onClick) {
                  onClick(event);
                }
                selectTab(id);
              }
            : undefined
        }
      >
        {children}
      </a>
    </li>
  );
};
