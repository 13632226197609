import { useCallback, useEffect } from "react";

type OptionsT = {
  callback?: () => void;
  shouldRemoveOnMount?: boolean;
};

export const useToggleClassOnMount = (elementQuery: string, className: string, options?: OptionsT) => {
  const element = document.querySelector(elementQuery);
  const callback = options?.callback;
  const shouldRemoveOnMount = options?.shouldRemoveOnMount;

  const handleCallback = useCallback(() => {
    if (callback) {
      callback();
    }
  }, [callback]);

  useEffect(() => {
    if (element) {
      handleCallback();
      if (shouldRemoveOnMount) {
        element.classList.remove(className);
      } else {
        element.classList.add(className);
      }
    }
    return () => {
      if (element) {
        handleCallback();
        if (shouldRemoveOnMount) {
          element.classList.add(className);
        } else {
          element.classList.remove(className);
        }
      }
    };
  }, [className, element, handleCallback, shouldRemoveOnMount]);
};
