import { omit } from "lodash";
import { DateRangeEnumT, DateRangeT } from "../graphql/generated/graphql";

export const normalizeDateRange = (dateRange: DateRangeT) => {
  if (!dateRange?.range) {
    return { from: null, to: null, range: null };
  }
  let omitedRange = omit(dateRange, "__typename");

  if (dateRange?.range === DateRangeEnumT.RangeCustomDaysT) {
    omitedRange = { ...omitedRange, from: null, to: null };
  } else if (dateRange?.range === DateRangeEnumT.RangeCustomT) {
    omitedRange = { ...omitedRange, toDays: null, fromDays: null };
  } else {
    omitedRange = { ...omitedRange, from: null, to: null, toDays: null, fromDays: null };
  }

  return omitedRange;
};

export const normalizeSearchText = (text: string) => text.trim().toLowerCase();
