import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  setYear,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
} from "date-fns";
import { createStaticRanges } from "react-date-range";
import { getDateIsValid } from "../../functions/dateHelpers";
import { DateRangeEnumT, DateRangeT, Maybe } from "../../graphql/generated/graphql";
import { RangeT } from "./DateRange";

const defineds = {
  startOfToday: startOfDay(new Date()),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfLastYear: startOfYear(addYears(new Date(), -1)),
  endOfLastYear: endOfYear(addYears(new Date(), -1)),
  startOfThisYear: startOfYear(new Date()),
};

export const dateRangesOptions = [
  {
    label: "Yesterday",
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -1)),
      endDate: startOfDay(addDays(new Date(), -1)),
      kind: DateRangeEnumT.RangeLastDayT,
    }),
    kind: DateRangeEnumT.RangeLastDayT,
  },
  {
    label: "Last 7 days",
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -7)),
      endDate: defineds.endOfYesterday,
      kind: DateRangeEnumT.RangeLast_7DaysT,
    }),
    kind: DateRangeEnumT.RangeLast_7DaysT,
  },
  {
    label: "Last 30 days",
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -30)),
      endDate: defineds.endOfYesterday,
      kind: DateRangeEnumT.RangeLast_30DaysT,
    }),
    kind: DateRangeEnumT.RangeLast_30DaysT,
  },
  {
    label: "Last 90 days",
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -90)),
      endDate: defineds.endOfYesterday,
      kind: DateRangeEnumT.RangeLast_90DaysT,
    }),
    kind: DateRangeEnumT.RangeLast_90DaysT,
  },
  {
    label: "Last 365 days",
    range: () => ({
      startDate: startOfDay(setYear(new Date(), defineds.startOfToday.getFullYear() - 1)),
      endDate: defineds.endOfYesterday,
      kind: DateRangeEnumT.Range_1YearAgoT,
    }),
    kind: DateRangeEnumT.Range_1YearAgoT,
  },
  {
    label: "This Month",
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate:
        defineds.endOfYesterday.getMonth() !== defineds.startOfMonth.getMonth()
          ? defineds.startOfMonth
          : defineds.endOfYesterday,
      kind: DateRangeEnumT.RangeThisMonthT,
    }),
    kind: DateRangeEnumT.RangeThisMonthT,
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
      kind: DateRangeEnumT.RangeLastMonthT,
    }),
    kind: DateRangeEnumT.RangeLastMonthT,
  },
  {
    label: "Last year",
    range: () => ({
      startDate: defineds.startOfLastYear,
      endDate: defineds.endOfLastYear,
      kind: DateRangeEnumT.RangeLastYearT,
    }),
    kind: DateRangeEnumT.RangeLastYearT,
  },
  {
    label: "This year",
    range: () => ({
      startDate: defineds.startOfThisYear,
      endDate:
        defineds.startOfThisYear.getFullYear() !== defineds.endOfYesterday.getFullYear()
          ? defineds.startOfThisYear
          : defineds.endOfYesterday,
      kind: DateRangeEnumT.RangeThisYearT,
    }),
    kind: DateRangeEnumT.RangeThisYearT,
  },
];

export const dateRangesOptionsByKind = dateRangesOptions.reduce(
  (acc, option) => ({ ...acc, [option.kind]: option }),
  {}
) as { [key in DateRangeEnumT]: { label: string } };

export const dateRanges = createStaticRanges(
  dateRangesOptions.map((range) => ({
    label: range.label,
    range: range.range,
    isSelected: (selectedRange) => {
      return (selectedRange as Pick<RangeT, "kind">).kind === range.kind;
    },
  }))
);

export const getRangeKind = ({
  endDate,
  startDate,
  useCustomDays,
}: {
  endDate?: Date | null;
  startDate?: Date | null;
  useCustomDays?: boolean;
}) => {
  if (!endDate || !startDate) {
    return null;
  }
  if (useCustomDays) {
    return DateRangeEnumT.RangeCustomDaysT;
  }

  const kind = dateRangesOptions.find(({ range }) => {
    return range().startDate?.getTime() == startDate?.getTime() && range().endDate?.getTime() === endDate?.getTime();
  }) || {
    kind: DateRangeEnumT.RangeCustomT,
  };

  return kind ? kind.kind : "";
};

export const getStartDay = (defaultValue?: Maybe<DateRangeT>) => {
  if (defaultValue?.range === DateRangeEnumT.RangeCustomDaysT) {
    return subDays(new Date(), defaultValue.fromDays || 0);
  }

  if (defaultValue?.range !== DateRangeEnumT.RangeCustomT) {
    return dateRangesOptions.find(({ kind }) => defaultValue?.range === kind)?.range().startDate;
  }
  return defaultValue?.from && getDateIsValid(defaultValue?.from) ? startOfDay(new Date(defaultValue.from)) : null;
};

export const getEndDay = (defaultValue?: Maybe<DateRangeT>) => {
  if (!defaultValue?.range) {
    return new Date("");
  }

  if (defaultValue?.range === DateRangeEnumT.RangeCustomDaysT) {
    return subDays(new Date(), defaultValue.toDays || 0);
  }

  if (defaultValue?.range !== DateRangeEnumT.RangeCustomT) {
    return dateRangesOptions.find(({ kind }) => defaultValue?.range === kind)?.range().endDate;
  }
  return defaultValue?.to && getDateIsValid(defaultValue?.to)
    ? endOfDay(new Date(defaultValue.to))
    : endOfDay(new Date("")); // react-date-range hack for empty selection;
};
