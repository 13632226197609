import React, { HTMLProps, ReactElement } from "react";
import classNames from "classnames";
import { FieldError } from "react-hook-form";
import { FormRadioOptionsT } from "../../types/common";
import { Col, Row } from "../grid/Grid";
import { Heading } from "../Heading/Heading";
import { Icon } from "../Icon/Icon";
import { Tile } from "../Tile/Tile";
import ErrorMessage from "./ErrorMessage";
import FormGroup from "./FormGroup";
import Label from "./Label";

type InputT = Omit<HTMLProps<HTMLInputElement>, "type"> & {
  kind?: "segmented" | "tile";
  type?: "radio";
};

type PropsT = {
  className?: string;
  error?: FieldError;
  input: InputT;
  isInline?: boolean;
  label?: string | number | ReactElement;
  radioOptions: FormRadioOptionsT;
  testId?: string;
};

const RadioSegmented = ({ className, error, input, isInline, label, radioOptions, testId }: PropsT) => {
  const { kind, ...inputRest } = input;

  return (
    <div className={classNames(className, "radio_buttons", "Segmented")}>
      {label && <Label className="Segmented-label">{label}</Label>}

      <div className={classNames("Segmented-wrapper", { "d-flex flex-gap-16": isInline })}>
        <div className="radio" />
        {radioOptions?.map((radio) => {
          const id = radio.value ? `${input.id}-${radio.value}` : input.id;

          return (
            <FormGroup key={`radio-${radio.value}`} className="radio" id={input.id} testId={testId} isSegmentedButton>
              <input
                {...inputRest}
                checked={radio.value === input.value}
                className={classNames("radio_buttons", input.className)}
                id={id}
                type="radio"
                value={radio.value}
              />
              {radio?.label && (
                <Label className="collection_radio_buttons" htmlFor={id}>
                  {radio.label}
                </Label>
              )}
            </FormGroup>
          );
        })}
      </div>

      {error && (
        <div>
          <ErrorMessage error={error} />
        </div>
      )}
    </div>
  );
};

const RadioTile = ({ className, error, input, isInline, label, radioOptions, testId }: PropsT) => {
  const { kind, ...inputRest } = input;

  return (
    <div className={classNames(className, "radio_buttons", "RadioTiles", { "RadioTiles--vertical": !isInline })}>
      {label && <Label className="Segmented-label">{label}</Label>}

      <div className="RadioTiles-wrapper">
        {radioOptions?.map((radio) => {
          const id = radio.value ? `${input.id}-${radio.value}` : input.id;

          return (
            <FormGroup key={`radio-${radio.value}`} className="radio mt-0" id={input.id} testId={testId}>
              <input
                {...inputRest}
                checked={radio.value === input.value}
                className={classNames("radio_buttons RadioTiles-input", input.className)}
                id={id}
                type="radio"
                value={radio.value}
              />
              {radio?.label && (
                <Label className="collection_radio_buttons text-left" htmlFor={id}>
                  <Tile isHorizontal isHoverable isWithCheck>
                    <Row alignItems="center" padding="l">
                      {radio.icon && (
                        <Col type="shrink">
                          <div className="Tile-icon">
                            <Icon color={radio.iconColor} kind={radio.icon} />
                          </div>
                        </Col>
                      )}

                      <Col padding="n" type="grow">
                        <Row>
                          <Heading level="h4" margin={0}>
                            {radio.label}
                          </Heading>
                        </Row>

                        {radio.description && (
                          <Row>
                            <div className="Tile-description">{radio.description}</div>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Tile>
                </Label>
              )}
            </FormGroup>
          );
        })}
      </div>

      {error && (
        <div>
          <ErrorMessage error={error} />
        </div>
      )}
    </div>
  );
};

const RadioDefault = ({ className, error, input, isInline, label, radioOptions, testId }: PropsT) => {
  const { kind, ...inputRest } = input;

  return (
    <div className={classNames(className, "radio_buttons", "Radios")}>
      {label && <Label>{label}</Label>}

      <div className={classNames({ "d-flex flex-gap-16": isInline })}>
        {radioOptions?.map((radio) => {
          const id = radio.value ? `${input.id}-${radio.value}` : input.id;

          return (
            <FormGroup key={`radio-${radio.value}`} className="radio Radios-item" id={input.id} testId={testId}>
              <input
                {...inputRest}
                checked={radio.value === input.value}
                className={classNames("radio_buttons", "Radios-input", input.className)}
                id={id}
                type="radio"
                value={radio.value}
              />
              {radio?.label && (
                <Label className="collection_radio_buttons" htmlFor={id}>
                  {radio.label}
                </Label>
              )}
            </FormGroup>
          );
        })}
      </div>

      {error && (
        <div>
          <ErrorMessage error={error} />
        </div>
      )}
    </div>
  );
};

const Radio = (props: PropsT) => {
  if (props.input.kind === "segmented") {
    return <RadioSegmented {...props} />;
  }

  if (props.input.kind === "tile") {
    return <RadioTile {...props} />;
  }

  return <RadioDefault {...props} />;
};

export default Radio;
