import { ReportDimensionT, ReportMetricT } from "../components/reportBoard/context/types";
import { MATCHING_DIMENSIONS, SYSTEMS_AD, SYSTEMS_ALL_KEY } from "../constants/report";
import { SystemNameT } from "../graphql/generated/graphql";
import { SourceSystemsT } from "../types/report";

export const getRequiredSystems = ({
  connectedSystems,
  dimensions,
  metrics,
}: {
  connectedSystems: SourceSystemsT;
  dimensions: ReportDimensionT[];
  metrics: ReportMetricT[];
}) => {
  const getAllRequiredSystems = () => {
    const dimensionsWithoutMatching = dimensions.filter(
      (dimension) => !MATCHING_DIMENSIONS.includes(dimension.originalName)
    );
    const dimensionsWithMatching = dimensions.filter((dimension) =>
      MATCHING_DIMENSIONS.includes(dimension.originalName)
    );

    const requiredSystems = {
      adSystems: new Set([] as string[]),
      analyticsSystems: new Set([] as string[]),
    };

    dimensionsWithoutMatching.forEach((dimension) => {
      dimension.adSystems?.forEach((system) => requiredSystems.adSystems.add(system.replace("all", SystemNameT.AnyT)));
      dimension.analyticsSystems?.forEach((system) => {
        requiredSystems.analyticsSystems.add(system.replace("all", SystemNameT.AnyT));
      });
    });
    metrics.forEach((metric) => {
      metric.requiredAdSystems?.forEach((system) => requiredSystems.adSystems.add(system));
      metric.requiredAnalyticsSystems?.forEach((system) => requiredSystems.analyticsSystems.add(system));
    });

    if (requiredSystems.adSystems.size > 1) {
      requiredSystems.adSystems.delete("any");
    }

    if (requiredSystems.analyticsSystems.size > 1) {
      requiredSystems.analyticsSystems.delete("any");
    }

    return {
      adSystems: Array.from(requiredSystems.adSystems),
      analyticsSystems: Array.from(requiredSystems.analyticsSystems),
      requireAtLeastAny: !!dimensionsWithMatching.length,
    };
  };

  const checkIsAdSystemConnected = (system: string) => {
    if (system === SYSTEMS_ALL_KEY) {
      return connectedSystems.some((connectedSystem) => SYSTEMS_AD.includes(connectedSystem.name));
    }
    return connectedSystems.some((connectedSystem) => connectedSystem.name === system);
  };

  const checkIsAnalyticsSystemConnected = (system: string) => {
    return connectedSystems.some((connectedSystem) => connectedSystem.name === system);
  };

  const allRequiredSystems = getAllRequiredSystems();
  const hasRequiredAnyAdSystems = !!allRequiredSystems.adSystems.length;
  const hasRequiredAnylaticsSystems = !!allRequiredSystems.analyticsSystems.length;
  const hasConnectedAnySystem = !!connectedSystems.length;

  return {
    adSystems: allRequiredSystems.adSystems.filter((system) => !checkIsAdSystemConnected(system)),
    analyticsSystems: allRequiredSystems.analyticsSystems.filter((system) => !checkIsAnalyticsSystemConnected(system)),
    requireAtLeastAny:
      !hasConnectedAnySystem &&
      !hasRequiredAnyAdSystems &&
      !hasRequiredAnylaticsSystems &&
      allRequiredSystems.requireAtLeastAny,
  };
};

export enum FakeDataStateT {
  ALL_COMPLETE = "ALL_COMPLETE",
  ALL_SAMPLE = "ALL_SAMPLE",
  INCOMPLETE = "INCOMPLETE",
}

export const getIsRequiredAnySystem = ({
  connectedSystems,
  dimensions,
  metrics,
}: {
  connectedSystems: SourceSystemsT;
  dimensions: ReportDimensionT[];
  metrics: ReportMetricT[];
}) => {
  if (connectedSystems.length === 0) {
    return FakeDataStateT.ALL_SAMPLE;
  }

  const requiredSystems = getRequiredSystems({ connectedSystems, dimensions, metrics });

  if (!!(requiredSystems.adSystems.length || requiredSystems.analyticsSystems.length)) {
    return FakeDataStateT.INCOMPLETE;
  }

  return FakeDataStateT.ALL_COMPLETE;
};

export const getAccountSelectLabel = ({
  adSystems,
  analyticsSystems,
}: {
  adSystems: string[];
  analyticsSystems: string[];
}) => {
  let label = "Required or connected accounts: ";
  const isAllAnalyticsSystems = analyticsSystems.length === 1 && analyticsSystems[0] === "any";
  const isAllAddSystems = adSystems.length === 1 && adSystems[0] === "any";
  if (isAllAddSystems) {
    label = "Required or connected accounts(select at least one adsystem):";
  }
  if (isAllAnalyticsSystems) {
    label = "Required or connected accounts(select at least one analytics system):";
  }
  if (isAllAnalyticsSystems && isAllAddSystems) {
    label = "Required accounts(select at least one adsystem and one analytics system):";
  }

  if (!analyticsSystems.length && !adSystems.length) {
    label = "To show your data please add the data sources to your report.";
  }

  return label;
};
