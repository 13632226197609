import React, { CSSProperties, HTMLProps, PropsWithChildren, ReactNode } from "react";
import classNames from "classnames";

type TablePropsT = {
  children: ReactNode;
  className?: string;
  doNotStickFirstColumn?: boolean;
  isBigRowHeight?: boolean;
  isExpanded?: boolean;
  isSmallRowHeight?: boolean;
  isWithFocus?: boolean;
  isWithLastRowDelimiter?: boolean;
  isWithSmallHeader?: boolean;
  isWithoutBorder?: boolean;
  isWithoutRowDelimiter?: boolean;
  style?: CSSProperties;
  testId?: string;
};

type TableCellPropsT = HTMLProps<HTMLTableCellElement> & {
  children?: ReactNode;
  className?: string;
  colSpan?: number;
  justifyRight?: boolean;
};

export const Table = ({
  children,
  className,
  doNotStickFirstColumn,
  isBigRowHeight,
  isExpanded,
  isSmallRowHeight,
  isWithFocus,
  isWithLastRowDelimiter,
  isWithSmallHeader,
  isWithoutBorder,
  isWithoutRowDelimiter,
  testId,
  ...rest
}: TablePropsT) => {
  return (
    <div
      {...rest}
      className={classNames("Sticky-Wrapper", "ppcbee-scrollbar", className, {
        "Sticky-Wrapper--withoutBorder": isWithoutBorder,
        "Sticky-Wrapper--isExpanded": isExpanded,
      })}
    >
      <table
        {...rest}
        data-test-id={`${testId}-table`}
        className={classNames("Sticky-Table", {
          "Sticky-Table--delimiter": !isWithoutRowDelimiter,
          "Sticky-Table--narrow": isSmallRowHeight,
          "Sticky-Table--spacious": isBigRowHeight,
          "Sticky-Table--doNotStickFirstColumn": doNotStickFirstColumn,
          "Sticky-Table--withLastDelimiter": isWithLastRowDelimiter,
          "Sticky-Table--withSmallHeader": isWithSmallHeader,
          "Sticky-Table--withFocus": isWithFocus,
        })}
      >
        {children}
      </table>
    </div>
  );
};

type TableElementT = PropsWithChildren & {
  className?: string;
};

type TableRowPropsT = PropsWithChildren & {
  className?: string;
  hasHover?: boolean;
};

export const THead = ({ children, ...rest }: TableElementT) => {
  return <thead {...rest}>{children}</thead>;
};

export const TBody = ({ children, className, ...rest }: TableElementT) => {
  return (
    <tbody {...rest} className={classNames("Sticky-Body", className)}>
      {children}
    </tbody>
  );
};

export const TFoot = ({ children, className, ...rest }: TableElementT) => {
  return (
    <tfoot {...rest} className={classNames("Sticky-Footer--TFoot", className)}>
      {children}
    </tfoot>
  );
};

export const TrHeader = ({ children, className, ...rest }: TableElementT) => (
  <tr {...rest} className={classNames("Sticky-Header", className)}>
    {children}
  </tr>
);

export const TrFooter = ({ children, className, ...rest }: TableElementT) => (
  <tr {...rest} className={classNames("Sticky-Footer", className)}>
    {children}
  </tr>
);

export const Th = ({ children, className, justifyRight, ...rest }: TableCellPropsT) => (
  <th {...rest} className={classNames("Sticky-Cell", className, { "text-right": justifyRight })}>
    {children}
  </th>
);

export const TrBody = ({ children, className, hasHover = true, ...rest }: TableRowPropsT) => (
  <tr {...rest} className={classNames("Sticky-Row", className, { noHover: !hasHover })}>
    {children}
  </tr>
);

export const Td = ({ children, className, justifyRight, ...rest }: TableCellPropsT) => (
  <td {...rest} className={classNames("Sticky-Cell", className, { "text-right": justifyRight })}>
    {children}
  </td>
);
