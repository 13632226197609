import React from "react";
import { HeadingSmall } from "../../ui/Heading/Heading";
import { Icon } from "../../ui/Icon/Icon";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";

type PropsT = {
  heading: string;
  icon: string;
  isTypeMenuOpen: boolean;
  name: string;
  setIsTypeMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const WidgetTypeButton = ({ heading, icon, isTypeMenuOpen, name, setIsTypeMenuOpen }: PropsT) => {
  return (
    <div>
      <HeadingSmall>{heading}</HeadingSmall>
      <Tile
        className="mb-16"
        ctaLink="...."
        data-test-id="button-widget-type-toggle"
        isActive={isTypeMenuOpen}
        isHorizontal
        isHoverable
        onClick={() => setIsTypeMenuOpen((prev) => !prev)}
      >
        <div className="d-flex align-items-center ">
          <Icon className="mr-8" color="#596774" kind={icon} size="18px" />
          <Text bold>{name}</Text>
          <Icon className="ml-a" color="#596774" kind="chevron-right" size="16px" />
        </div>
      </Tile>
    </div>
  );
};
