import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { withApiStateHandler } from "../components/ErrorLoadingWrapper/withApiStateHandler";
import { FormCustomValueMetric } from "../components/formCustomValueMetric/FormCustomValueMetric";
import { DUPLICATED_PREFIX } from "../constants/report";
import { ROUTES } from "../constants/routes";
import { notifyError, notifySucces } from "../functions/toast";
import {
  CustomValueMetricQueryT,
  CustomValueMetricsDocumentT,
  useCreateCustomValueMetricMutation,
  useCustomValueMetricQuery,
} from "../graphql/generated/graphql";
import { useAppSettings } from "../providers/appSettingsProvider";
import { RouteParamsT } from "../types/router";
import { Modal } from "../ui/Modal";

type PropsT = {
  data?: CustomValueMetricQueryT;
  onClose: () => void;
  organizationExternalId: string;
};

const ValueMetricDuplicatePageComponent = withApiStateHandler(({ data, onClose, organizationExternalId }: PropsT) => {
  const [serverErrors, setServerErrors] = useState<string[] | undefined>(undefined);

  const [mutate, { loading }] = useCreateCustomValueMetricMutation({
    refetchQueries: [CustomValueMetricsDocumentT],
    onCompleted: (res) => {
      const errors = res.organization?.createCustomValueMetric?.errors;

      if (errors?.length) {
        return setServerErrors(errors);
      }
      notifySucces(<>{`Custom value metric duplicated!`}</>);
      onClose();
    },
    onError: () => notifyError(<>{`Server error when creating custom value metric!`}</>),
  });

  const customValueMetric = data?.organization?.customValueMetric;

  return (
    <FormCustomValueMetric
      isMutationLoading={loading}
      serverErrors={serverErrors}
      defaultValues={
        customValueMetric
          ? { ...customValueMetric, name: `${DUPLICATED_PREFIX} ${customValueMetric?.name}` }
          : undefined
      }
      onSubmit={(values) =>
        mutate({
          variables: {
            ...values,
            organizationExternalId,
          },
        })
      }
    />
  );
});

export const ValueMetricDuplicateModal = ({
  onClose,
  valueMetricId,
}: {
  onClose: () => void;
  valueMetricId?: string;
}) => {
  const { organization } = useAppSettings();

  const response = useCustomValueMetricQuery({
    fetchPolicy: "network-only",
    variables: {
      organizationExternalId: organization.externalId,
      customValueMetricId: valueMetricId || "",
    },
    skip: !valueMetricId,
  });
  return (
    <Modal close={onClose} heading="Duplicate custom value metric" testId="duplicate-custom-value-metric-modal" isOpen>
      <ValueMetricDuplicatePageComponent
        {...response}
        organizationExternalId={organization.externalId}
        wrapWithLayout
        onClose={onClose}
      />
    </Modal>
  );
};
export const ValueMetricDuplicatePage = () => {
  const { valueMetricId } = useParams<RouteParamsT["valueMetric"]>();
  const navigate = useNavigate();

  return (
    <ValueMetricDuplicateModal valueMetricId={valueMetricId} onClose={() => navigate(ROUTES.metricsAndDimensions())} />
  );
};
