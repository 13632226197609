import React, { useState } from "react";
import { FormCustomValueMetric } from "../components/formCustomValueMetric/FormCustomValueMetric";
import { notifyError, notifySucces } from "../functions/toast";
import { CustomValueMetricsDocumentT, useCreateCustomValueMetricMutation } from "../graphql/generated/graphql";
import { useAppSettings } from "../providers/appSettingsProvider";

export const ValueMetricNewModal = ({ onCreate }: { onCreate?: (id: string) => void }) => {
  const [serverErrors, setServerErrors] = useState<string[] | undefined>(undefined);
  const {
    organization: { externalId },
  } = useAppSettings();

  const [mutate, { loading }] = useCreateCustomValueMetricMutation({
    refetchQueries: [CustomValueMetricsDocumentT],
    onCompleted: (res) => {
      const metricResponse = res.organization?.createCustomValueMetric;
      const errors = metricResponse?.errors;

      if (errors?.length) {
        return setServerErrors(errors);
      }
      notifySucces(<>{`Custom value metric created!`}</>);
      if (onCreate && metricResponse?.customValueMetric?.id) {
        onCreate(metricResponse.customValueMetric.id);
      }
    },
    onError: () => notifyError(<>{`Server error when creating custom value metric!`}</>),
  });

  return (
    <FormCustomValueMetric
      isMutationLoading={loading}
      serverErrors={serverErrors}
      onSubmit={(values) =>
        mutate({
          variables: {
            ...values,
            organizationExternalId: externalId,
          },
        })
      }
    />
  );
};
