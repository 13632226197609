import React, { PropsWithChildren } from "react";
import cs from "classnames";
import { isBoolean } from "lodash";
import { CompareDateT, CompareInterval } from "../../components/widgetComponents/MetricInformationCard";
import { Maybe, WidgetCompareT } from "../../graphql/generated/graphql";
import { formatPercents } from "../../i18n/formatNumbers";
import { Badge } from "../Badge/Badge";
import { Row } from "../grid/Grid";
import { Icon } from "../Icon/Icon";
import { Tooltip } from "../Tooltip/Tooltip";

const TREND_TRESHOLD = 0.02;

const Trend: {
  [positiveTrend: string]: {
    [sign: string]: {
      background: "lightRed" | "lightGray" | "lightGreen";
      color: "red" | "gray" | "green";
      icon: string;
    };
  };
} = {
  true: {
    "-1": { background: "lightRed", color: "red", icon: "chevron-down" },
    "0": { background: "lightGray", color: "gray", icon: "line" },
    "1": { background: "lightGreen", color: "green", icon: "chevron-up" },
  },
  false: {
    "-1": { background: "lightGreen", color: "green", icon: "chevron-down" },
    "0": { background: "lightGray", color: "gray", icon: "line" },
    "1": { background: "lightRed", color: "red", icon: "chevron-up" },
  },
};

type TrendBadgePropsT = {
  className?: string;
  compareDate?: CompareDateT;
  currentValue?: string;
  positiveTrend?: boolean;
  previousValue?: string;
  trend?: number | null;
};

export type TrendWrapperPropsT = {
  compare?: Maybe<WidgetCompareT>;
} & TrendBadgePropsT;

export const TrendBadge = ({
  className,
  compareDate,
  currentValue,
  positiveTrend,
  previousValue,
  trend,
}: TrendBadgePropsT) => {
  if (!trend) {
    return null;
  }
  const positiveTrendKey = isBoolean(positiveTrend) ? positiveTrend : true;
  const isOverTreshold = Math.abs(trend) > TREND_TRESHOLD;
  const { background, icon } = isOverTreshold ? Trend[`${positiveTrendKey}`][Math.sign(trend)] : Trend.false[0];

  return (
    <Tooltip
      tooltipContent={
        previousValue || compareDate || currentValue ? (
          <>
            {previousValue && (
              <Row>
                <span>Previous value: {previousValue}</span>
              </Row>
            )}
            {currentValue && (
              <Row>
                <span>Current value: {currentValue}</span>
              </Row>
            )}
            {compareDate && (
              <Row>
                <CompareInterval isLight {...compareDate} />
              </Row>
            )}
          </>
        ) : undefined
      }
    >
      <Badge className={cs("ml-8", className)} kind={background}>
        <Icon className="mr-4" kind={icon} isInheritColor />
        {formatPercents(trend, 0)}
      </Badge>
    </Tooltip>
  );
};

export const TrendWrapper = ({ children, compare, ...rest }: PropsWithChildren<TrendWrapperPropsT>) => {
  if (!compare || compare == WidgetCompareT.NoneT) {
    return <>{children}</>;
  }

  return (
    <span style={{ textAlign: "center" }}>
      {children}
      <TrendBadge {...rest} />
    </span>
  );
};
