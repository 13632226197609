import React from "react";
import { groupBy } from "lodash";
import { LegendProps } from "recharts";
import {
  fetchDimensionValueFromRowAsText,
  fetchMetricCompareFromRow,
  fetchMetricPreviousValueFromRow,
  fetchMetricTrendFromRow,
  fetchMetricValueFromRow,
} from "../../functions/widgetDataHelpers";
import { WidgetMetricT, WidgetRowT } from "../../graphql/generated/graphql";
import { Ellipsis } from "../../ui/Ellipsis/Ellipsis";
import { Col, Row } from "../../ui/grid/Grid";
import { Tile } from "../../ui/Tile/Tile";
import { MetricsByIdT, ReportDimensionT } from "../reportBoard/context/types";
import { MetricInformationCard } from "./MetricInformationCard";
import { UseHideSeriesGetIsHiddenT, UseHideSeriesOnLegendClickT } from "./useHideSeries";

type PropsT = Omit<LegendProps, "payload"> & {
  getIsHidden: UseHideSeriesGetIsHiddenT;
  metricsById: MetricsByIdT;
  onClick: UseHideSeriesOnLegendClickT;
  payload?: Array<{
    color: string;
    payload: {
      "data-dimension-value"?: string;
      "data-metric-compare"?: string;
      "data-metric-id": string;
      "data-serie-id": string;
    };
  }>;
  selectedDimensions: ReportDimensionT[];
  summaryRowsByDimensions: Pick<WidgetRowT, "widgetMetrics">[];
  widgetMetrics: WidgetMetricT[];
};

export const WidgetChartTimebasedLegend = ({
  getIsHidden,
  metricsById,
  onClick,
  payload,
  selectedDimensions,
  summaryRowsByDimensions,
  widgetMetrics,
}: PropsT) => {
  const summaryByDimensionValues = groupBy(
    summaryRowsByDimensions,
    fetchDimensionValueFromRowAsText(selectedDimensions)
  );

  return (
    <Row justify="center" style={{ maxHeight: "90px", marginBottom: "-8px" }} flexwrap overflow>
      {payload &&
        payload?.length > 0 &&
        payload
          .filter((item) => !item.payload["data-metric-compare"])
          .map((entry, index) => {
            const value = entry?.payload?.["data-dimension-value"];
            const summRow = (summaryByDimensionValues[value || ""] || [])[0] as WidgetRowT;
            const metric = metricsById[entry.payload["data-metric-id"]];
            const isCompare = entry.payload?.["data-metric-compare"];
            const compareDate = isCompare
              ? fetchMetricCompareFromRow({ id: "XXX", metricId: metric.id })(summRow)
              : undefined;

            return (
              <Col
                key={`item-${index}`}
                type="equalSize"
                style={{
                  cursor: "pointer",
                  opacity: getIsHidden(entry.payload["data-serie-id"]) ? 0.3 : 1,
                  maxWidth: "max(160px, calc(8% - 8px))",
                }}
                onClick={() => onClick(entry)}
              >
                <Tile className="h-100 recharts-legend-item-text" isSmallSpaced>
                  <div
                    className="w-100 mb-8"
                    style={{ backgroundColor: entry?.color, height: "4px", borderRadius: "2px" }}
                  />
                  <MetricInformationCard
                    compareDate={compareDate}
                    currency={summRow?.currency}
                    hidePill={widgetMetrics.length === 1 && value !== "-"}
                    metric={metric}
                    size="small"
                    trend={
                      !isCompare ? fetchMetricTrendFromRow({ id: "XXX", metricId: metric.id })(summRow) : undefined
                    }
                    value={
                      ((isCompare
                        ? fetchMetricPreviousValueFromRow({ id: "XXX", metricId: metric.id })(summRow)
                        : fetchMetricValueFromRow({ id: "XXX", metricId: metric.id })(summRow)) as number) || undefined
                    }
                  >
                    {value !== "-" && <Ellipsis twoLine>{value}</Ellipsis>}
                  </MetricInformationCard>
                </Tile>
              </Col>
            );
          })}
    </Row>
  );
};
