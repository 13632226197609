import React, { ReactNode } from "react";
import classNames from "classnames";
import { Row } from "../grid/Grid";
import { Tile } from "../Tile/Tile";

export type ActionBarPropsT = {
  children: ReactNode;
  hasAbsolutePosition?: boolean;
  hasMoreBottomPadding?: boolean;
};

export const ActionBar = ({ children, hasAbsolutePosition, hasMoreBottomPadding }: ActionBarPropsT) => (
  <Row
    style={{ zIndex: 100, paddingBottom: hasMoreBottomPadding ? "72px" : "32px" }}
    type="shrink"
    className={classNames("mt-16 NewLayout-actionBar js-newLayout-actionBar", {
      "NewLayout-actionBar--absolutePosition": hasAbsolutePosition,
    })}
  >
    <Tile>
      <Row>{children}</Row>
    </Tile>
  </Row>
);
