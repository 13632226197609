import { format } from "date-fns";
import { uniq } from "lodash";
import { MetricsByIdT } from "../components/reportBoard/context/types";
import { DATE_FORMATS } from "../constants/date";
import { CHART_LINE_COLORS } from "../constants/report";
import { CurrencyT, MetricDataT, WidgetMetricT, WidgetRowT } from "../graphql/generated/graphql";
import { ReportSettingsDataT } from "../types/widgets";
import { normalizeMetricDataType } from "./widgetHelpers";

const getUnifiedDataProps = (item: WidgetRowT, widgetSettingsData: ReportSettingsDataT) => {
  const uniqueKeys = new Set();

  const subKey = item.widgetDimensions.reduce(
    (acc, dimension) => `${acc}${acc ? "." : ""}${dimension.dimensionId}.${dimension.value}`,
    ""
  );

  const containsOther = item.widgetDimensions.some((dimension) => dimension.value === "other");
  const subName = item.widgetDimensions.reduce((acc, dimension) => {
    const dimName = widgetSettingsData.dimensions?.find(
      (dimensionData) => dimensionData.id === dimension.dimensionId
    )?.name;
    return `${acc}${acc ? " " : ""}${dimName} ${dimension.value}`;
  }, "");

  return { uniqueKeys, subKey, subName, containsOther };
};

export const getTransformedTimeSeriesData = ({
  data,
  widgetSettingsData,
}: {
  data: WidgetRowT[];
  widgetSettingsData: ReportSettingsDataT;
}) => {
  const getUnifiedData = (item: WidgetRowT) => {
    const { subKey, subName, uniqueKeys } = getUnifiedDataProps(item, widgetSettingsData);

    return item.widgetMetrics.reduce((acc, metric) => {
      const metName = widgetSettingsData.metrics?.find((metricData) => metricData.id === metric.metricId)?.name;

      const name = `${metName}${subName && " - "}${subName}`;
      const key = `${subKey}.${metric.metricId}--${name}`;
      uniqueKeys.add(key);
      return {
        ...acc,
        [key]: metric.value,
      };
    }, {});
  };

  const groupByDate = data.reduce((acc, item) => {
    const { date } = item;
    acc[date] = acc[date] ?? [];
    acc[date].push(item);
    return acc;
  }, {} as { [key: string]: [WidgetRowT] });

  const chartData = (Object.keys(groupByDate) as (keyof typeof groupByDate)[]).map((key) => ({
    date: format(new Date(key), DATE_FORMATS.date),
    ...groupByDate[key].reduce((acc, item) => ({ ...acc, ...getUnifiedData(item) }), {}),
  })) as { [key: string]: string | number }[];

  const chartProps = () => {
    if (chartData?.[0]) {
      return Object.keys(chartData[0])
        .map((key, index) => ({
          dataKey: key,
          fill: CHART_LINE_COLORS[index] || CHART_LINE_COLORS[0],
          stroke: CHART_LINE_COLORS[index] || CHART_LINE_COLORS[0],
          name: key.split("--")[1] || "Unknown",
        }))
        .filter((prop) => prop.dataKey !== "date");
    }

    return [];
  };

  return {
    data: chartData,
    props: chartProps(),
  };
};

export const normalizeMetricMoneyAxis = (currency?: CurrencyT | null | string) =>
  [MetricDataT.MoneyT, currency].join("-");

export const getYAxisDataTypes = ({
  metricsById,
  rows,
  widgetMetrics,
}: {
  metricsById: MetricsByIdT;
  rows?: WidgetRowT[];
  widgetMetrics: WidgetMetricT[];
}) => {
  const dataTypes = uniq(widgetMetrics.map((metric) => metricsById[metric.metricId]).map(normalizeMetricDataType));
  const hasMoneyDataType = dataTypes.includes(MetricDataT.MoneyT);

  const currencies = uniq(rows?.map((row) => row.currency)).map((currency) => ({
    dataType: MetricDataT.MoneyT,
    currency,
    orientation: "right",
    yAxisId: [MetricDataT.MoneyT, currency].join("-"),
  }));

  const axis = dataTypes.flatMap((dataType, index) =>
    dataType === MetricDataT.MoneyT
      ? currencies
      : { dataType, currency: "", yAxisId: dataType, orientation: !hasMoneyDataType && index === 1 ? "right" : "left" }
  );
  if (axis.length === 1) {
    return [{ ...axis[0], orientation: "left" }];
  }

  return axis;
};
