import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrayMappingT, FormDimension, FormDimensionInputsT } from "../components/formDimension/FormDimension";
import { ROUTES } from "../constants/routes";
import { notifyError, notifySucces } from "../functions/toast";
import { CustomDimensionsDocumentT, useCreateCustomDimensionMutation } from "../graphql/generated/graphql";
import { useAppSettings } from "../providers/appSettingsProvider";
import { Modal } from "../ui/Modal";
import { DIMENSIONS } from "./MetricsAndDimensions";

export const normalizeApiFormDimensionValues = ({ arrayMapping, ...values }: FormDimensionInputsT) => ({
  ...values,
  mappingRule: {
    ...values.mappingRule,
    mapping: arrayMapping.reduce(
      (acc, item) =>
        item && item.what && (item.what.length > 0 || (item.to && item.to.length > 0))
          ? [...acc, { what: item.what, to: item.to || "" }]
          : acc,
      [] as ArrayMappingT[]
    ),
  },
});

export const DimensionNewModal = ({ onClose, onCreate }: { onClose: () => void; onCreate?: (id: string) => void }) => {
  const [serverErrors, setServerErrors] = useState<string[] | undefined>(undefined);
  const {
    organization: { externalId },
  } = useAppSettings();

  const [mutate] = useCreateCustomDimensionMutation({
    refetchQueries: [CustomDimensionsDocumentT],
    onCompleted: async (res) => {
      const dimensionResponse = res.organization?.createCustomDimension;
      const errors = dimensionResponse?.errors;

      if (errors?.length) {
        return setServerErrors(errors);
      }
      notifySucces(<>{`Custom dimension created!`}</>);

      if (onCreate && dimensionResponse?.customDimension?.id) {
        onCreate(dimensionResponse.customDimension.id);
      }
    },
    onError: () => notifyError(<>{`Server error when creating custom dimension!`}</>),
  });

  return (
    <Modal close={onClose} heading="New custom dimension" testId="new-custom-dimension-modal" zIndex="high" isOpen>
      <FormDimension
        serverErrors={serverErrors}
        onSubmit={(values) =>
          mutate({
            variables: {
              ...normalizeApiFormDimensionValues(values),
              organizationExternalId: externalId,
            },
          })
        }
      />
    </Modal>
  );
};
export const DimensionNewPage = () => {
  const navigate = useNavigate();
  const handleReturnToMainPage = () => navigate(ROUTES.metricsAndDimensionsTabs(DIMENSIONS));

  return <DimensionNewModal onClose={handleReturnToMainPage} onCreate={handleReturnToMainPage} />;
};
