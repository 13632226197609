import React, { FC, useMemo } from "react";
import { getWidgetNamePlaceholder } from "../../functions/widgetHelpers";
import { WidgetDataFragmentT } from "../../graphql/generated/graphql";
import { ReportFilterT } from "../../types/report";
import { WidgetT } from "../../types/widgets";
import { InfoBox } from "../../ui/InfoBox/InfoBox";
import { Modal, ModalBody, useModal } from "../../ui/Modal";
import { useReportBoardContext } from "../reportBoard/context/reportBoardContext";
import { WidgetRawDataTable } from "./WidgetRawDataTable";

type PropsT = {
  filterValues?: ReportFilterT;
  isShowRawData?: boolean;
  onCloseRawData?: () => void;
  widget: WidgetT;
  widgetData?: WidgetDataFragmentT | undefined;
};

type ContainerT = <T extends PropsT>(component: FC<T>) => FC<T>;

export const widgetDataComponentContainer: ContainerT = (WrappedComponent) => (props) => {
  const {
    widgetSettingsData: { dimensionsById, metricsById },
  } = useReportBoardContext();

  const modalOptions = useModal();

  const namePlaceholder = useMemo(
    () =>
      getWidgetNamePlaceholder({
        widgetMetrics: props.widget.widgetMetrics,
        widgetDimensions: props.widget.widgetDimensions,
        metricsById,
        dimensionsById,
      }),
    [props.widget.widgetMetrics, props.widget.widgetDimensions, metricsById, dimensionsById]
  );

  return (
    <>
      <WrappedComponent {...props} />
      {!!props.isShowRawData && props.onCloseRawData && (
        <Modal
          {...modalOptions}
          close={props.onCloseRawData}
          heading={`${props.widget.name || namePlaceholder}: Raw data`}
          testId="widget-raw-data-modal"
          zIndex="high"
          isOpen
        >
          <ModalBody>
            {props.filterValues ? (
              <WidgetRawDataTable
                {...props}
                filterValues={props.filterValues}
                isShowRawData={false}
                originalWidgetData={props.widgetData}
                widget={props.widget}
              />
            ) : (
              <InfoBox variant="warning">No raw data</InfoBox>
            )}
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
