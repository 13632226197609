import { ChangeEvent, useRef } from "react";
import { ButtonPropsT, ButtonSecondary } from "../../ui/Button/Button";

type DownloadButtonT = { onChange: (event: ChangeEvent<HTMLInputElement>) => void };
export const DownloadButton = ({
  onChange,
  ...rest
}: DownloadButtonT & Omit<ButtonPropsT, "systemIcon" | "onChange">) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  return (
    <span>
      <input ref={hiddenFileInput} accept=".csv" className="hidden" id={"csv_file"} type="file" onChange={onChange} />
      <ButtonSecondary {...rest} icon="download" onClick={() => hiddenFileInput?.current?.click()} />
    </span>
  );
};
