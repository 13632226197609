import { object, string } from "yup";
import { VALIDATIONS } from "../../constants/validations";
import { KpiPeriodT } from "../../graphql/generated/graphql";
import { formConditionsSchema } from "../formConditions/validations";

export const formKpiSchema = formConditionsSchema.concat(
  object({
    name: string().required(VALIDATIONS.required),
    metricId: string().required(VALIDATIONS.required),
    startOn: string().required(VALIDATIONS.required),
    endOn: string().when(["period"], {
      is: (period: KpiPeriodT | null) => period === KpiPeriodT.NoneT,
      then: string().required(VALIDATIONS.required),
    }),
  })
);
