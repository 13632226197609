import React from "react";
import { Col, Row } from "../grid/Grid";
import { TilePropsT } from "./Tile";
import { TileBadge } from "./TileBadge";
import { TileCheck } from "./TileCheck";

type PropsT = Pick<TilePropsT, "alphaBadge" | "badge" | "children" | "text" | "isWithCheck">;

export const TileContentHorizontal = ({ alphaBadge, badge, children, isWithCheck, text }: PropsT) => (
  <Row alignItems="center" padding="l">
    {isWithCheck && (
      <Col type="shrink">
        <TileCheck />
      </Col>
    )}
    <Col type="grow">
      {children}
      {text}
    </Col>

    {(badge || alphaBadge) && (
      <Col type="shrink">
        <TileBadge alphaBadge={alphaBadge} badge={badge} leftSpacing />
      </Col>
    )}
  </Row>
);
