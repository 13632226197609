/* eslint-disable react/no-array-index-key */
import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { WIDGET_SCHEMA } from "../../constants/report";
import { getWidgetDataErrors } from "../../functions/widgetHelpers";
import { CurrencyT, DataConsistencyT, Maybe } from "../../graphql/generated/graphql";
import { SectionT, WidgetT } from "../../types/widgets";
import { ClampedText } from "../../ui/ClampedText/ClampedText";
import { DropdownMenu } from "../../ui/Dropdown/DropdownMenu";
import { Ellipsis } from "../../ui/Ellipsis/Ellipsis";
import { Col, Row } from "../../ui/grid/Grid";
import { HeadingSmall } from "../../ui/Heading/Heading";
import { Icon } from "../../ui/Icon/Icon";
import { useModal } from "../../ui/Modal";
import { Tooltip } from "../../ui/Tooltip/Tooltip";
import { useConfirmationContext } from "../confirmationDialog/confirmationDialogContext";
import { FormReportCompactFilterInputsT } from "../formReportCompactFilter/FormReportCompactFilter";
import { useReportBoardContext } from "../reportBoard/context/reportBoardContext";
import { WidgetContextT } from "../reportBoard/ReportBoardSection";
import { ReportFilterCompact } from "../reportBoard/ReportFilterCompact";
import { SearchInput, SearchInputPropsT } from "../search/Search";
import { DataConsistencyBadge } from "./DataConsistencyBadge";
import { MoveToOtherSectionModal } from "./MoveToOtherSectionModal";

export type PropsT = {
  currencyData?: { isNotResolvable: boolean; resolvedCurrency?: Maybe<CurrencyT> };
  dataConsistency?: DataConsistencyT;
  hasRawData: boolean;
  isPreview?: boolean;
  namePlaceholder: string;
  onShowRawData: () => void;
  searchInputProps: SearchInputPropsT;
  sectionId?: SectionT["id"];
  widget: WidgetT;
  widgetContext: WidgetContextT;
};

export const WidgetHeader = ({
  currencyData,
  dataConsistency,
  hasRawData,
  isPreview,
  namePlaceholder,
  onShowRawData,
  searchInputProps,
  sectionId,
  widget,
  widgetContext,
}: PropsT) => {
  const hasFilter = WIDGET_SCHEMA[widget.kind].hasFilter;
  const hasSearch = WIDGET_SCHEMA[widget.kind].hasQuickSearch;

  const {
    reportOptions,
    widgetSettingsData: { metricsById },
  } = useReportBoardContext();
  const {
    sectionsList,
    widgetDelete,
    widgetDuplicate,
    widgetEditationOpen,
    widgetEditationOpenConditions,
    widgetMoveToDifferentSection,
    widgetUpdate,
  } = widgetContext;
  const { callWithConfirmation } = useConfirmationContext();

  const moveToOtherSectionsModal = useModal();

  const handleOpenEditation = useCallback(() => {
    widgetEditationOpen(widget.id);
  }, [widgetEditationOpen, widget.id]);

  const handleWidgetDuplication = () => {
    widgetDuplicate(widget.id);
  };

  const handleMoveToDifferentSection = useCallback(
    (wantedSectionId: string) => widgetMoveToDifferentSection({ wantedSectionId, widget }),
    [widget, widgetMoveToDifferentSection]
  );

  const handleWidgetFilterValues = (newState: Partial<FormReportCompactFilterInputsT>) => {
    widgetUpdate({
      widgetId: widget.id,
      setWidget: (prev) => ({ ...prev, dateRange: newState.dateRange ? newState.dateRange : null }),
    });
  };

  const clampedTextDependencies = useMemo(
    () => [widget.properties?.options?.width, widget.description],
    [widget.properties?.options?.width, widget.description]
  );

  // Part of InlineEdit
  // const handleWidgetUpdate = (value: string, propertyPath: string, prevValue?: string | null) => {
  //   if (value === prevValue) {
  //     return;
  //   }

  //   return widgetUpdate({
  //     value: value === "" ? null : value,
  //     propertyPath,
  //     overrideSectionId: sectionId,
  //     overrideWidgetId: widget.id,
  //   });
  // };

  // const { buttonProps, inputProps, isInputVisible } = useInlineEditWithToggleButton({
  //   text: widget?.description,
  //   onChange: (value) => handleWidgetUpdate(value, "description", widget.description),
  // });

  return (
    <Col>
      <Row alignItems="center" className="mb-0" gap={8} justify="between" padding="n" flexwrap>
        <Col
          className={classNames("mw-100", "mv-4", { "negative-ml-16": !isPreview })}
          style={{ flexBasis: "280px" }}
          type="grow"
        >
          <Row alignItems="center" className="pos-relative" type="shrink">
            {!isPreview && (
              <Col className="show-on-hover-element" width="8px">
                {reportOptions.canEdit && (
                  <span
                    className="DragHandle draggable-handle-widget show-on-hover-element d-flex"
                    style={{ width: "14px", paddingBottom: "2px" }}
                  />
                )}
              </Col>
            )}
            <Col className="mb-0" type="grow">
              <Row alignItems="center">
                {currencyData?.isNotResolvable && (
                  <Col>
                    <Tooltip
                      tooltipContent={
                        <>
                          We cannot evaluate currency: <span className="Text--bold mr-4">{widget.currency}</span>.
                          {currencyData?.resolvedCurrency && (
                            <>
                              Therefore the currency used for this widget is:
                              <span className="Text--bold ml-4">{currencyData.resolvedCurrency}</span>
                            </>
                          )}
                        </>
                      }
                    >
                      <Icon
                        className="mr-8"
                        color="red"
                        data-test-id="widget-not-resolvable-warning"
                        kind="warning"
                        size="16px"
                      />
                    </Tooltip>
                  </Col>
                )}
                <Col className="mb-0" type="grow">
                  <HeadingSmall
                    className={classNames("widget-header", { "cursor-pointer": !isPreview && reportOptions.canEdit })}
                    margin={0}
                    onClick={!isPreview && reportOptions.canEdit ? handleOpenEditation : undefined}
                  >
                    <Ellipsis data-test-id="widget-name">
                      {widget.name || namePlaceholder || "Widget name placeholder"}
                    </Ellipsis>
                  </HeadingSmall>
                  {/* preparation InlineEdit implementation */}
                  {/* <div className="cursor-pointer">
                    <InlineEdit
                      kind="h3"
                      placeholder={namePlaceholder || "Widget name placeholder"}
                      testId="widget-name-inline-edit"
                      value={widget.name}
                      onChange={(value) => handleWidgetUpdate(value, "name", widget.name)}
                    />
                  </div> */}
                </Col>
              </Row>
            </Col>
          </Row>

          {/* preparation InlineEdit implementation */}
          {/* <Row className="widget-description">
            {isInputVisible ? (
              <InlineEdit
                {...inputProps}
                kind="text"
                testId="widget-description-inline-edit"
                value={widget.description ? widget.description : undefined}
                isMultiline
              />
            ) : (
              <ButtonTertiary
                {...buttonProps}
                className="show-on-hover-element"
                icon="edit"
                style={{ backgroundColor: "transparent" }}
              >
                Add description
              </ButtonTertiary>
            )}
          </Row> */}
        </Col>

        {!isPreview && reportOptions?.canEdit && (
          <Col>
            <DataConsistencyBadge dataConsistency={dataConsistency} />
          </Col>
        )}

        {!isPreview && reportOptions?.canEdit && (
          <Col className="show-on-hover-element" type="shrink">
            {hasSearch && <SearchInput {...searchInputProps} />}
          </Col>
        )}

        <Col className="mw-100">
          {hasFilter && (
            <ReportFilterCompact
              canEdit={reportOptions?.canEdit}
              conditions={widget.conditions}
              defaultValues={{ dateRange: widget.dateRange || {} }}
              hasCurrencyPreview={widget.currency}
              id={`widget-filter-${widget.id}`}
              metricsById={metricsById}
              isInputMinified
              isVissibleOnHover
              onChange={handleWidgetFilterValues}
              onFilterOpen={() => widgetEditationOpenConditions(widget.id)}
            />
          )}
        </Col>

        {reportOptions?.canEdit && !isPreview && (
          <Col>
            <DropdownMenu
              buttonProps={{ variant: "secondary" }}
              className={classNames({ "show-on-hover-element": !isPreview })}
              testId={`Dropdown--widget-${widget.name || namePlaceholder}`}
              collection={[
                ...(!isPreview
                  ? [
                      { value: "Edit widget", icon: "edit", onClick: handleOpenEditation },
                      { value: "Duplicate widget", icon: "duplicate", onClick: handleWidgetDuplication },
                      {
                        value: "Move to Section",
                        icon: "sign-out",
                        isDisabled: sectionsList.length <= 1,
                        onClick: moveToOtherSectionsModal.open,
                      },
                    ]
                  : []),
                ...(getWidgetDataErrors(widget).length === 0 && hasRawData
                  ? [{ value: "Show raw data", icon: "research", onClick: onShowRawData }]
                  : []),
                ...(!isPreview && sectionId
                  ? [
                      {
                        value: "Delete widget",
                        color: "danger" as const,
                        icon: "trash",
                        onClick: () => {
                          callWithConfirmation(() => widgetDelete(widget.id), {
                            description: "Are you sure you want to delete this widget ?",
                            title: "Delete widget",
                            testId: `delete-widget-${widget.id}`,
                            confirmButtonProps: {
                              variant: "red",
                              text: "Delete",
                            },
                          });
                        },
                      },
                    ]
                  : []),
              ]}
            />

            {sectionId && (
              <MoveToOtherSectionModal
                {...moveToOtherSectionsModal}
                sectionId={sectionId}
                sections={sectionsList}
                onMove={handleMoveToDifferentSection}
              />
            )}
          </Col>
        )}
      </Row>
      {widget.description && widget.description.length > 0 && (
        <ClampedText
          className="widget-description"
          dataTestId="widget-description"
          dependencies={clampedTextDependencies}
        >
          {widget.description}
        </ClampedText>
      )}
    </Col>
  );
};
