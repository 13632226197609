import { useCallback, useMemo } from "react";
import { QueryResult } from "@apollo/client";
import emptyMetricsImage from "../../assets/empty_metrics.svg";
import { AliasDimensionsQueryT, AliasMetricsQueryT, ValueCategoryT } from "../../graphql/generated/graphql";
import { useSearch } from "../../hooks/useSearch";
import { Unpacked } from "../../types/common";
import { ButtonPrimary } from "../../ui/Button/Button";
import { SortableWrapper, useSortable } from "../../ui/DataTable/Sortable";
import { CATEGORY_PROPS } from "../../ui/forms/MetricDimensionItemSelect";
import { Col, Row } from "../../ui/grid/Grid";
import { Icon } from "../../ui/Icon/Icon";
import { Pagination, usePagination } from "../../ui/Pagination/Pagination";
import { EmptyState } from "../../ui/Table/EmptyState";
import { TBody, THead, Table, Td, Th, TrBody, TrHeader } from "../../ui/Table/Table";
import { TableHeader } from "../../ui/Table/TableHeader";
import { Tile } from "../../ui/Tile/Tile";
import { Tooltip } from "../../ui/Tooltip/Tooltip";
import { withApiStateHandler } from "../ErrorLoadingWrapper/withApiStateHandler";
import { ShowDimensionMetricPill } from "../showDimensionMetricPill/ShowDimensionMetricPill";
import { AliasTableCell } from "./AliasTableCell";

export type OnChangeAliasT = (params: { id: string; newValue: string }) => Promise<boolean>;
export type OnDeleteAliasT = (aliasId: string) => void;

export type AliasTablePropsT = {
  heading: string;
  headingLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
} & (
  | (Pick<QueryResult<AliasDimensionsQueryT>, "data" | "refetch"> & { type: "dimensions" })
  | (Pick<QueryResult<AliasMetricsQueryT>, "data" | "refetch"> & { type: "metrics" })
);

export const AliasTable = withApiStateHandler(({ data, heading, headingLevel, refetch, type }: AliasTablePropsT) => {
  const rows = useMemo(
    () => (type == "dimensions" ? data?.organization?.dimensions.nodes : data?.organization?.metrics.nodes) || [],
    [data, type]
  );

  const unsupportedCategories = useMemo(
    () => Object.values(ValueCategoryT).filter((value) => rows.some((row) => row.category === value)),
    [rows]
  );

  const availableCategories = useMemo(
    () =>
      Object.values(ValueCategoryT)
        .filter((value) => unsupportedCategories.includes(value))
        .map((value) => ({
          value,
          label: CATEGORY_PROPS?.[value].label,
        })),
    [unsupportedCategories]
  );

  const filterRowsByCategories = useCallback((item: Unpacked<typeof rows>) => item.category || "", []);
  const { filterData: filtreByCategories, searchInputProps: selectProps } = useSearch(filterRowsByCategories);
  const filtredDataByCategories = useMemo(() => filtreByCategories(rows), [filtreByCategories, rows]);

  const filterRowsBySearch = useCallback((item: Unpacked<typeof rows>) => [item.originalName, item.name].join(" "), []);
  const { filterData, searchInputProps } = useSearch(filterRowsBySearch);
  const filtredData: typeof filtredDataByCategories = useMemo(
    () => filterData(filtredDataByCategories),
    [filterData, filtredDataByCategories]
  );

  const [sortableProps, sortedTableRows] = useSortable(
    filtredData,
    useCallback(
      (sortBy) => (row) => {
        const rowValue = row?.[sortBy as "originalName" | "referencesCount" | "aliasName"];
        if (typeof rowValue === "object") {
          return rowValue?.value || null;
        }
        return rowValue ?? null;
      },
      []
    ),
    { sortBy: "originalName" }
  );

  const [paginationProps, paginatedTableRows] = usePagination<typeof sortedTableRows>(sortedTableRows);

  const handleResetFilters = useCallback(() => {
    selectProps?.clearSearch();
    searchInputProps?.clearSearch();
  }, [searchInputProps, selectProps]);

  return (
    <>
      <TableHeader
        heading={heading}
        headingLevel={headingLevel}
        searchInputProps={searchInputProps}
        selectFilterProps={{
          ...selectProps,
          collection: [{ value: "", label: "All Ad systems" }, ...availableCategories],
        }}
      />

      {paginatedTableRows.length === 0 ? (
        <Tile>
          <EmptyState
            description={`Out of ${rows.length} records, none of them match your search query. Try to modify what you're looking for.`}
            heading={heading}
            image={emptyMetricsImage}
          >
            <div className="mt-16">
              <ButtonPrimary icon="remove" onClick={handleResetFilters}>
                Reset filters
              </ButtonPrimary>
            </div>
          </EmptyState>
        </Tile>
      ) : (
        <Table>
          <THead>
            <TrHeader>
              <Th style={{ width: "380px", minWidth: "380px" }}>
                <SortableWrapper {...sortableProps} sortByValue="originalName">
                  Name
                </SortableWrapper>
              </Th>
              <Th>
                <SortableWrapper {...sortableProps} sortByValue="aliasName">
                  <Row alignItems="center" className="mr-16">
                    <Col>Alias</Col>
                    <Tooltip tooltipContent="The alias is used to replace the original name, if you need to call a metric or dimension something else, create a new alias below.">
                      <Icon kind="info" size="16px" isInheritColor />
                    </Tooltip>
                  </Row>
                </SortableWrapper>
              </Th>
              <Th>
                <SortableWrapper {...sortableProps} sortByValue="referencesCount">
                  Used
                </SortableWrapper>
              </Th>
            </TrHeader>
          </THead>
          <TBody>
            {paginatedTableRows.map((rowItem) => (
              <TrBody key={rowItem.id}>
                <Td>
                  <ShowDimensionMetricPill item={{ ...rowItem, name: rowItem.originalName }} />
                </Td>
                <Td className="ShowOnHoverWrapper">
                  <AliasTableCell refetchQuery={refetch} rowItem={rowItem} type={type} />
                </Td>
                <Td>{rowItem.referencesCount}</Td>
              </TrBody>
            ))}
          </TBody>
        </Table>
      )}

      <Pagination className="mt-16" testId="alias-dimension-pagination" isCompact {...paginationProps} />
    </>
  );
});
