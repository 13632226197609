import { useEffect, useState } from "react";
import { SystemNameT } from "../../graphql/generated/graphql";
import { useAppSettings } from "../../providers/appSettingsProvider";
import { GetAccountLinkT } from "./AccountSelect";

type PropsT = {
  getAccountLink: GetAccountLinkT;
  returnTo?: string;
  systemName: SystemNameT;
};

type StateT = { [key: string]: undefined | null | string };
export const useGetAccountLink = ({ getAccountLink, systemName }: PropsT) => {
  const [accountAccessLinks, setAccountAccessLinks] = useState<StateT>({});
  const { organization } = useAppSettings();

  useEffect(() => {
    getAccountLink({
      variables: {
        organizationExternalId: organization.externalId,
        returnTo: "",
      },
    }).then((response) => {
      setAccountAccessLinks({
        adwords: response.data?.organization?.accountLink_adwords,
        bing: response.data?.organization?.accountLink_bing,
        facebook: response.data?.organization?.accountLink_facebook,
        google_analytics3: response.data?.organization?.accountLink_google_analytics3,
        google_analytics4: response.data?.organization?.accountLink_google_analytics4,
        sklik: response.data?.organization?.accountLink_sklik,
      });
    });
  }, [getAccountLink, organization.externalId]);

  return accountAccessLinks[systemName] || undefined;
};
