import React from "react";
import { KPI_PRIORITY_PROPS } from "../../constants/kpi";
import { KpiPriorityT } from "../../graphql/generated/graphql";
import { Icon } from "../../ui/Icon/Icon";

type PropsT = {
  priority: KpiPriorityT;
};

export const KpiPriority = ({ priority }: PropsT) => (
  <>
    <Icon className="mr-4" color={KPI_PRIORITY_PROPS[priority].color} kind="flag" />
    {priority} Priority
  </>
);
