import React, { PropsWithChildren, ReactNode, useEffect, useRef } from "react";
import cs from "classnames";
import { useElementDimensions } from "../../../hooks/useElementDimensions";
import { ButtonTertiary } from "../../../ui/Button/Button";
import { DropdownMenu, DropdownMenuPropsT } from "../../../ui/Dropdown/DropdownMenu";
import { Col, Row } from "../../../ui/grid/Grid";
import { Icon } from "../../../ui/Icon/Icon";
import { Tile } from "../../../ui/Tile/Tile";

export type MetricDimensionDraggableRowPropsT = {
  additionalContent?: ReactNode;
  collection?: DropdownMenuPropsT["collection"];
  draggableHandle: string;
  id: string;
  index: number;
  isRemoveButtonDisabled?: boolean;
  onRemove: (index: number) => void;
  onRowHeightChange: (id: string, height: number) => void;
};

export const MetricDimensionDraggableRow = ({
  additionalContent,
  children,
  collection,
  draggableHandle,
  id,
  index,
  isRemoveButtonDisabled,
  onRemove,
  onRowHeightChange,
}: PropsWithChildren<MetricDimensionDraggableRowPropsT>) => {
  const itemRef = useRef<HTMLDivElement | null>(null);

  const itemDimensions = useElementDimensions(itemRef);

  const handleHeightChange = () => {
    if (itemDimensions.height || itemDimensions.height === 0) {
      onRowHeightChange(id, itemDimensions.height);
    }
  };

  useEffect(() => {
    handleHeightChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemDimensions]);

  return (
    <Tile ref={itemRef} style={{ backgroundColor: "rgba(0, 22, 42, 0.02)" }} isSmallSpaced>
      <Row alignItems="center" className={cs({ "delimiter pb-8": additionalContent })} justify="center">
        <Col type="shrink">
          <Icon
            className={cs(draggableHandle)}
            color="#CCD0D4"
            kind="dragable"
            size="14px"
            style={{ cursor: "grab" }}
          />
        </Col>
        <Col data-test-id={`dimension-row-${index}`} type="grow">
          {children}
        </Col>

        {collection && (
          <Col>
            <DropdownMenu collection={collection} testId="show-additional-metricData" />
          </Col>
        )}

        <Col>
          <ButtonTertiary
            disabled={isRemoveButtonDisabled}
            icon="trash"
            size="medium"
            onlyIcon
            onClick={() => onRemove(index)}
          />
        </Col>
      </Row>
      {additionalContent && <Row>{additionalContent}</Row>}
    </Tile>
  );
};
