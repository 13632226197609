import React from "react";
import { getBestWorstKpis } from "../../functions/kpisHelper";
import { Col, Row } from "../../ui/grid/Grid";
import { HeadingSection } from "../../ui/Heading/Heading";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { KpiListItemPropsT } from "../kpiList/KpiListItem";
import { KpiSemaphore } from "../kpiSemaphore/KpiSemaphore";

const KpiTileOverallBestWorstItem = ({ kpiSetting }: { kpiSetting: KpiListItemPropsT["kpiSetting"] }) => (
  <Tile className="pa-4 h-100">
    <Col alignItems="center">
      <Text className="text-center" bold>
        {kpiSetting.name}
      </Text>
      <KpiSemaphore className="mt-8" {...kpiSetting} result={kpiSetting.lastResult} hideBadge />
    </Col>
  </Tile>
);

export const KpiTileOverallBestWorst = ({ kpiSettings }: { kpiSettings: KpiListItemPropsT["kpiSetting"][] }) => {
  const { best, worst } = getBestWorstKpis(kpiSettings);

  if (!best || !worst || best.id === worst.id) {
    return null;
  }

  return (
    <Tile isFullHeight>
      <HeadingSection margin={0}>The Best and the Worst</HeadingSection>

      <Row className="mt-8">
        <Col type="grow">
          <KpiTileOverallBestWorstItem kpiSetting={best} />
        </Col>
        <Col type="grow">
          <KpiTileOverallBestWorstItem kpiSetting={worst} />
        </Col>
      </Row>
    </Tile>
  );
};
