import React from "react";
import { CategoryTranslations, WIDGET_SCHEMA } from "../../constants/report";
import { WidgetKindT } from "../../graphql/generated/graphql";
import { WidgetCategoryT } from "../../types/widgets";
import { Heading, HeadingSmall } from "../../ui/Heading/Heading";
import { Icon } from "../../ui/Icon/Icon";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";

type PropsT = {
  onSelect: (widgetType: WidgetKindT) => void;
  selectedWidgetKind?: WidgetKindT;
};

export const WidgetTypeSelection = ({ onSelect, selectedWidgetKind }: PropsT) => {
  return (
    <div data-test-id="widget-type-selection">
      <HeadingSmall>View type</HeadingSmall>

      {(Object.keys(WidgetCategoryT) as (keyof typeof WidgetCategoryT)[]).map((categoryKey) => {
        const categoryName = WidgetCategoryT[categoryKey];
        const filteredWidgetKinds = (Object.keys(WIDGET_SCHEMA) as WidgetKindT[]).filter(
          (widgetKind) => WIDGET_SCHEMA[widgetKind].category === categoryName
        );

        if (categoryName === WidgetCategoryT.NoneT || !filteredWidgetKinds.length) {
          return null;
        }

        return (
          <div key={categoryName}>
            <Heading level="h4">{CategoryTranslations[categoryName]}</Heading>
            <ul className="pa-0">
              {filteredWidgetKinds.map((widgetKind) => {
                const isActive = widgetKind === selectedWidgetKind;
                const widget = WIDGET_SCHEMA[widgetKind];

                return (
                  <li key={`${widgetKind}-button`} className="mb-8" style={{ listStyle: "none" }}>
                    <Tile
                      dataTestId={`widget-type-selection--${widgetKind}`}
                      isActive={isActive}
                      isHorizontal
                      isHoverable
                      onClick={!isActive ? () => onSelect(widgetKind) : undefined}
                    >
                      <div className="d-flex align-items-center">
                        <Icon className="mr-8" color="#596774" kind={WIDGET_SCHEMA[widgetKind].icon} size="16px" />
                        <Text>{widget.name}</Text>
                      </div>
                    </Tile>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};
