import React, { useCallback } from "react";
import { WidgetDataFragmentT } from "../../graphql/generated/graphql";
import { ButtonPrimary } from "../../ui/Button/Button";
import { SortableWrapper, useSortable } from "../../ui/DataTable/Sortable";
import { MiddleEllipsis } from "../../ui/Ellipsis/MiddleEllipsis";
import { Modal, ModalBody, ModalFooter } from "../../ui/Modal";
import { TBody, THead, Table, Td, Th, TrBody, TrHeader } from "../../ui/Table/Table";
import { CampaignStatus } from "../widgetComponents/TableOptimatizationCell/TableOptimatizationCell";

type AffectedCampaignModalPropsT = {
  campaignsData: NonNullable<WidgetDataFragmentT["includedData"]>["campaignsData"];
  onClose: () => void;
};

export const AffectedCampaignsModal = ({ campaignsData, onClose }: AffectedCampaignModalPropsT) => {
  const [sortableProps, sortedTableRows] = useSortable(
    campaignsData,
    useCallback((sortBy) => (row) => row?.campaign[sortBy as "name" | "status"], []),
    { sortBy: "name" }
  );

  return (
    <Modal
      close={onClose}
      heading="Affected Campaigns"
      portalId="dee-modal-js"
      size="small"
      testId="affected-campaign-modal"
      zIndex="extra"
      isOpen
    >
      <ModalBody>
        <Table isWithSmallHeader>
          <THead>
            <TrHeader>
              <Th>
                <SortableWrapper {...sortableProps} sortByValue="name">
                  Campaign name
                </SortableWrapper>
              </Th>
              <Th>
                <SortableWrapper {...sortableProps} sortByValue="status">
                  Status
                </SortableWrapper>
              </Th>
            </TrHeader>
          </THead>
          <TBody>
            {sortedTableRows.map(({ campaign }) => (
              <TrBody key={campaign.id}>
                <Td>
                  <MiddleEllipsis>{campaign.name}</MiddleEllipsis>
                </Td>
                <Td>
                  <CampaignStatus {...campaign} />
                </Td>
              </TrBody>
            ))}
          </TBody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <ButtonPrimary onClick={onClose}>Close</ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};
