import React from "react";
import { withApiStateHandler } from "../components/ErrorLoadingWrapper/withApiStateHandler";
import { TemplatePreviewList } from "../components/template/TemplatePreviewList";
import { PAGE_SIZE } from "../constants/common";
import { ROUTES } from "../constants/routes";
import { ReportListQueryT, useReportListQuery } from "../graphql/generated/graphql";
import { useCreateReport } from "../hooks/useCreateReport";
import { Layout } from "../layout/wrappers/Layout";
import { useAppSettings } from "../providers/appSettingsProvider";
import { Button, ButtonSecondary } from "../ui/Button/Button";
import { Col, Row } from "../ui/grid/Grid";
import { HeadingPage, HeadingSmall } from "../ui/Heading/Heading";
import { InfoBox } from "../ui/InfoBox/InfoBox";
import { Text } from "../ui/Text/Text";

type PropsT = { data?: ReportListQueryT; doNotShowSubHeader?: boolean; isEmptyReports?: boolean };

const ReportTemplatesComponent = withApiStateHandler(({ data, doNotShowSubHeader, isEmptyReports }: PropsT) => {
  const templates = data?.organization?.reports?.nodes;
  const backTo = !doNotShowSubHeader ? ROUTES.reports() : undefined;

  const { createReport, loading } = useCreateReport({
    successMessage: "New empty report was created.",
  });

  return (
    <Layout backTo={backTo}>
      <Row alignItems="center" className="mb-16">
        <Col type="grow">
          <HeadingPage margin={0}>Template selection</HeadingPage>
        </Col>
        <Col>
          <Row alignItems="center">
            <Text>Missing Any Template?</Text>
            <ButtonSecondary
              className="ml-8"
              icon="reply"
              size="small"
              to="https://t.maze.co/188586443?guerilla=true"
              isExternalLink
              isRealHref
            >
              Let Us Know
            </ButtonSecondary>
          </Row>
        </Col>
      </Row>
      {isEmptyReports && (
        <InfoBox className="mb-16" isComplex>
          <HeadingSmall margin={0}>No reports</HeadingSmall>
          <Text className="d-block">
            You don't have any reports so choose from our predefined templates below, or create blank report.
          </Text>
          <Button
            className="mt-16"
            data-test-id="button-create-new-blank-report"
            loading={loading}
            variant="secondary"
            onClick={() => createReport({ name: "New report", description: "Description for the new report." })}
          >
            Create Blank Report
          </Button>
        </InfoBox>
      )}

      {templates?.length ? <TemplatePreviewList templates={templates} /> : <span>no data</span>}
    </Layout>
  );
});

export const ReportTemplatesPage = ({ doNotShowSubHeader, isEmptyReports }: Omit<PropsT, "data">) => {
  const { organization } = useAppSettings();
  const result = useReportListQuery({
    variables: { organizationExternalId: organization.externalId, limit: PAGE_SIZE, offset: 0, template: true },
    fetchPolicy: "network-only",
  });

  return (
    <ReportTemplatesComponent {...result} doNotShowSubHeader={doNotShowSubHeader} isEmptyReports={isEmptyReports} />
  );
};
