import React, { useCallback, useEffect, useState } from "react";
import { DimensionT, MetricT } from "../../graphql/generated/graphql";
import { Badge } from "../Badge/Badge";
import { Tab } from "../Tabs/Tab";
import { TabList } from "../Tabs/TabList";
import { TabPanel } from "../Tabs/TabPanel";
import { Tabs } from "../Tabs/Tabs";
import { MetricOrDimensionT } from "./MetricDimensionController";
import { MetricDimensionItemSelect } from "./MetricDimensionItemSelect";
import { MetricDimensionSelectPropsT } from "./MetricDimensionSelect";

export type SelectableDimensionT = Pick<
  DimensionT,
  "id" | "name" | "adSystems" | "analyticsSystems" | "isConnected" | "category" | "favorite"
>;
export type SelectableMetricT = Pick<
  MetricT,
  "id" | "name" | "adSystems" | "analyticsSystems" | "isConnected" | "category" | "dataType" | "favorite"
>;

type MetricDimensionSelectTabsPropsT = Omit<MetricDimensionSelectPropsT, "dimensions" | "metrics"> & {
  dimensions: SelectableDimensionT[];
  disabledIds?: string[];
  metrics: SelectableMetricT[];
};

type MetricDimensionOnlyT = Exclude<MetricOrDimensionT, "">;

export const MetricDimensionSelectTabs = ({
  dimensions,
  disabledIds,
  disabledTab,
  metrics,
  onSetSelectedCategory,
  onSetSelectedItem,
  recentDimensions,
  recentMetrics,
  searchValue,
  selectedCategory,
}: MetricDimensionSelectTabsPropsT) => {
  const isDisabledTabMetric = !!(disabledTab && disabledTab === "metric");
  const isDisabledTabDimension = !!(disabledTab && disabledTab === "dimension");
  const [selectedTab, setSelectedTab] = useState<MetricDimensionOnlyT>(isDisabledTabMetric ? "dimension" : "metric");
  const [valuesCount, setValuesCount] = useState<{ [K in MetricDimensionOnlyT]: number }>({
    metric: metrics?.length,
    dimension: dimensions?.length,
  });

  const handleValuesCount = useCallback(({ count, type }: { count: number; type: string }) => {
    setValuesCount((prev) => ({ ...prev, [type]: count }));
  }, []);

  const handleMetricCount = useCallback(
    (count: number) => {
      handleValuesCount({ type: "metric", count });
    },
    [handleValuesCount]
  );

  const handleDimensionCount = useCallback(
    (count: number) => {
      handleValuesCount({ type: "dimension", count });
    },
    [handleValuesCount]
  );

  useEffect(() => {
    const wantedTab = (Object.keys(valuesCount) as (keyof typeof valuesCount)[]).find((key) => valuesCount[key] > 0);
    const isSelectedTabEmpty =
      (wantedTab === "dimension" && !valuesCount.metric) || (wantedTab === "metric" && !valuesCount.dimension);

    if (wantedTab && isSelectedTabEmpty && wantedTab !== disabledTab) {
      setSelectedTab(wantedTab);
    }
  }, [valuesCount, disabledTab]);

  return (
    <Tabs selectedTab={selectedTab} testId="reports" onSelected={(tab) => setSelectedTab(tab as MetricDimensionOnlyT)}>
      <TabList
        className="negative-mh-16 pv-16 d-flex justify-content-between background-white border-top-left-radius-8 border-top-right-radius-8 z-index-1"
        style={{ position: "sticky", top: 0 }}
      >
        <Tab className="flex-1 text-center" disabled={isDisabledTabMetric} id="metric">
          Metrics {searchValue && <Badge className="pa-4">{valuesCount.metric}</Badge>}
        </Tab>
        <Tab className="flex-1 text-center" disabled={isDisabledTabDimension} id="dimension">
          Dimensions {searchValue && <Badge className="pa-4">{valuesCount.dimension}</Badge>}
        </Tab>
      </TabList>

      <TabPanel tabId="metric">
        <MetricDimensionItemSelect
          disabledIds={disabledIds}
          recentDimensions={recentDimensions}
          recentMetrics={recentMetrics}
          searchValue={searchValue}
          selectedCategory={selectedCategory}
          type="metric"
          values={metrics as SelectableMetricT[]}
          withTabs
          onSetSelectedCategory={onSetSelectedCategory}
          onSetSelectedItem={onSetSelectedItem}
          onSetValuesCount={handleMetricCount}
        />
      </TabPanel>
      <TabPanel tabId="dimension">
        <MetricDimensionItemSelect
          disabledIds={disabledIds}
          recentDimensions={recentDimensions}
          recentMetrics={recentMetrics}
          searchValue={searchValue}
          selectedCategory={selectedCategory}
          type="dimension"
          values={dimensions as SelectableDimensionT[]}
          withTabs
          onSetSelectedCategory={onSetSelectedCategory}
          onSetSelectedItem={onSetSelectedItem}
          onSetValuesCount={handleDimensionCount}
        />
      </TabPanel>
    </Tabs>
  );
};
