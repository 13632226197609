import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import classNames from "classnames";
import { formatDate } from "../../functions/dateHelpers";
import { notifySucces } from "../../functions/toast";
import { ButtonSecondary } from "../../ui/Button/Button";
import { DropdownMenu } from "../../ui/Dropdown/DropdownMenu";
import { Ellipsis } from "../../ui/Ellipsis/Ellipsis";
import { Col, Row } from "../../ui/grid/Grid";
import { HeadingSection } from "../../ui/Heading/Heading";
import { Icon } from "../../ui/Icon/Icon";
import { Link } from "../../ui/Link/Link";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { useConfirmationContext } from "../confirmationDialog/confirmationDialogContext";
import { ReportBoardSharedReportT } from "../reportBoard/context/types";
import { RegenerateLinkConfirmationModal } from "./RegenerateLinkConfirmation";
import { ReportSharingPropsT } from "./ReportSharing";

export type ReportSharingLinkPropsT = Pick<
  ReportSharingPropsT,
  "onDeleteSharedReport" | "onRefetchData" | "onUpdateSharedReport" | "pages"
> & {
  className?: string;
  hiddenMenuItems?: Array<"detail">;
  onSetEditedSharedReport: Dispatch<SetStateAction<string | null>>;
  sharedReport: ReportBoardSharedReportT;
};

export const ReportSharingLink = ({
  className,
  hiddenMenuItems,
  onDeleteSharedReport,
  onRefetchData,
  onSetEditedSharedReport,
  onUpdateSharedReport,
  pages,
  sharedReport,
}: ReportSharingLinkPropsT) => {
  const link = `${window.location.origin}/shared_report/${sharedReport.token}`;
  const { callWithConfirmationManualClose } = useConfirmationContext();
  const [isRegenerateConfirmVisible, setIsRegenerateConfirmVisible] = useState(false);

  const engagementsCount = pages
    .filter((page) => sharedReport.pages.nodes.some((sharedPage) => sharedPage.id === page.id))
    .reduce((acc, page) => acc + page.sections.reduce((accS, section) => section.widgets.nodes.length, 0), 0);

  const handleDelete = useCallback(() => {
    callWithConfirmationManualClose(
      async (onClose) => {
        await onDeleteSharedReport(sharedReport.id);
        await onRefetchData();
        onClose();
      },
      {
        description:
          "Deleting link will permanently remove access for the users with link. Please double-check your decision before proceeding with the deletion.",
        confirmButtonProps: { text: "Delete", variant: "red" },
      }
    );
  }, [onDeleteSharedReport, callWithConfirmationManualClose, sharedReport, onRefetchData]);

  const handleRename = useCallback(() => {
    callWithConfirmationManualClose(
      async (onClose, newName) => {
        await onUpdateSharedReport({ variables: { ...sharedReport, name: newName } });
        await onRefetchData();
        onClose();
      },
      {
        title: "Rename Link",
        description: "Please make sure to give the link a unique and descriptive name below to avoid confusion.",
        confirmButtonProps: { text: "Save" },
        inputProps: {
          canBeValueNotChanged: false,
          defaultValue: sharedReport.name || "",
        },
      }
    );
  }, [onUpdateSharedReport, callWithConfirmationManualClose, sharedReport, onRefetchData]);

  const handleRegenerateLink = useCallback(() => {
    callWithConfirmationManualClose(
      async (onClose) => {
        await onUpdateSharedReport({ variables: { id: sharedReport.id, generateToken: true } });
        await onRefetchData();
        onClose();
        setIsRegenerateConfirmVisible(true);
      },
      {
        title: "Regenerate Link",
        description:
          "Regenerating link will create a new unique link. Old link will not be reachable so far. Please double-check your decision before proceeding with the regenerate.",
        confirmButtonProps: { text: "Regenerate" },
      }
    );
  }, [onUpdateSharedReport, callWithConfirmationManualClose, sharedReport, onRefetchData]);

  return (
    <>
      <Tile className={classNames(className, "overflow-hidden")} isSmallSpaced>
        <Row>
          <Col justify="center">
            <div className="pos-relative">
              <Icon color="#7140df" kind="anchor" size="2em" />
              {sharedReport.hasPassword && (
                <Icon
                  className="pos-absolute background-white"
                  kind="locked"
                  size="0.9em"
                  style={{
                    padding: "0px 1px",
                    borderRadius: "50%",
                    bottom: "0",
                    right: "0",
                    transform: "translate(3px, 3px)",
                  }}
                />
              )}
            </div>
          </Col>
          <Col>
            <HeadingSection data-test-id="shared-link-name" margin={0} style={{ maxWidth: "350px" }}>
              <Ellipsis>{sharedReport.name}</Ellipsis>
            </HeadingSection>
            <Link
              className="ellipsis"
              data-test-id="shared-link"
              href={link}
              style={{ maxWidth: "350px" }}
              target="_blank"
            >
              <Text color="gray">{link}</Text>
            </Link>
          </Col>
          <Col alignItems="center" direction="row" justify="end" type="grow">
            <ButtonSecondary
              className="mr-8"
              data-test-id="shared-link-duplication"
              icon="duplicate"
              onClick={() => {
                navigator.clipboard.writeText(link);
                notifySucces("Link has been copied to clipboard");
              }}
            >
              Copy
            </ButtonSecondary>
            <DropdownMenu
              testId={`shared-link-menu-${sharedReport.name}`}
              collection={[
                ...(!hiddenMenuItems?.includes("detail")
                  ? [
                      {
                        value: "Edit",
                        icon: "search",
                        onClick: () => onSetEditedSharedReport(sharedReport.id),
                      },
                    ]
                  : []),
                {
                  value: "Rename",
                  icon: "edit",
                  onClick: handleRename,
                },

                {
                  value: "Regenerate link",
                  icon: "repeat",
                  color: "danger",
                  onClick: handleRegenerateLink,
                },
                {
                  value: "Delete link",
                  icon: "trash",
                  color: "danger",
                  onClick: handleDelete,
                },
              ]}
            />
          </Col>
        </Row>
        <Row className="background-body negative-mh-8 ph-8 negative-mb-8 pv-8" gap={24}>
          <Col className="d-block" data-test-id="shared-link-pages" style={{ minWidth: "45px" }}>
            <Text color="gray" size="sm" bold>
              PAGES
            </Text>
            <br />
            <Icon color="gray" kind="template" />{" "}
            {sharedReport.allPages ? "All" : `${sharedReport.pages.nodes.length} of ${pages.length}`}
          </Col>
          <Col className="d-block" data-test-id="shared-link-date">
            <Text color="gray" size="sm" bold>
              DATE
            </Text>
            <br />
            <Icon color="gray" kind="calendar" /> {formatDate(sharedReport.createdAt)}
          </Col>
          <Col className="d-block" data-test-id="shared-link-engagements">
            <Text color="gray" size="sm" bold>
              ENGAGEMENTS
            </Text>
            <br />
            <Icon color="gray" kind="template" /> {engagementsCount}
          </Col>
        </Row>
      </Tile>

      {isRegenerateConfirmVisible && (
        <RegenerateLinkConfirmationModal
          link={link}
          sharedReport={sharedReport}
          onClose={() => setIsRegenerateConfirmVisible(false)}
        />
      )}
    </>
  );
};
