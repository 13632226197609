/* eslint-disable id-length */
/* eslint-disable import/prefer-default-export */
import {
  Layout as RglLayoutT,
  Layouts as RglLayoutsT,
  ResponsiveProps as RglResponsivePropsT,
} from "react-grid-layout";

type GetGridLayoutT = (params: {
  breakpoints: NonNullable<RglResponsivePropsT["breakpoints"]>;
  columns: NonNullable<RglResponsivePropsT["cols"]>;
  items: { height?: number | null | undefined; id: string; width: number | null | undefined }[] | undefined;
}) => RglLayoutsT;

export const getGridLayout: GetGridLayoutT = ({ breakpoints, columns, items }) => {
  const getBreakpointLayout = (breakpointKey: keyof typeof breakpoints) => {
    const colCount = columns[breakpointKey];

    const test = items?.reduce((acc, item, index) => {
      const w = Math.min(item?.width || 1, colCount);
      const h = item?.height || 1;
      const x = (acc[index - 1]?.x || 0) + (acc[index - 1]?.w || 0);
      const isOverflowRow = x + w > colCount;
      const y = acc[index - 1]?.y || 0;

      return [
        ...acc,
        {
          i: item.id,
          x: isOverflowRow ? 0 : x,
          y: isOverflowRow ? y + 1 : y,
          w,
          h,
        },
      ];
    }, [] as RglLayoutT[]);

    return test;
  };

  return (Object.keys(breakpoints) as (keyof typeof breakpoints)[]).reduce(
    (acc, key) => ({
      ...acc,
      [key]: getBreakpointLayout(key),
    }),
    {}
  );
};

export const getGridLayoutPropertiesOfItems = (layout: RglLayoutT[]) => {
  const sorted = [...layout].sort((a, b) => (a.y === b.y ? a.x - b.x : a.y - b.y));
  return sorted.map((item, index) => ({
    itemId: item.i,
    order: index,
    width: item.w,
    height: item.h,
  }));
};
