import React, { ForwardedRef, forwardRef } from "react";
import cs from "classnames";
import { SystemNameT } from "../../graphql/generated/graphql";
import { Tooltip } from "../Tooltip/Tooltip";
import { AdWordsIcon } from "./AdwordsIcon";
import { AnalyticsIcon } from "./AnalyticsIcon";
import { BingIcon } from "./BingIcon";
import { FacebookIcon } from "./FacebookIcon";
import { SklikIcon } from "./SklikIcon";
import { SystemIconPropsT } from "./SystemIconWithText";

const SystemIconComponent = forwardRef(
  (
    { className, color, hover, system, ...rest }: Omit<SystemIconPropsT, "tooltipContent">,
    ref: ForwardedRef<HTMLElement>
  ) => (
    <span
      ref={ref}
      className={cs("System-icon", "d-inline-block", className, {
        "System-icon--hover": hover,
        [`System-icon--${color}`]: color,
      })}
    >
      {system === SystemNameT.AdwordsT && <AdWordsIcon {...rest} />}
      {system === SystemNameT.SklikT && <SklikIcon {...rest} />}
      {system === SystemNameT.BingT && <BingIcon {...rest} />}
      {system === SystemNameT.FacebookT && <FacebookIcon {...rest} />}
      {system === SystemNameT.GoogleAnalytics3T && <AnalyticsIcon {...rest} />}
      {system === SystemNameT.GoogleAnalytics4T && <AnalyticsIcon {...rest} />}
    </span>
  )
);

export const SystemIcon = ({ tooltipContent, ...rest }: SystemIconPropsT) => (
  <Tooltip tooltipContent={tooltipContent}>
    <SystemIconComponent {...rest} />
  </Tooltip>
);
