import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { debounce } from "lodash";
import { WIDGET_SCHEMA } from "../../constants/report";
import { getValidatedWidgetData, getWidgetDataFilter } from "../../functions/widgetHelpers";
import { WidgetKindT } from "../../graphql/generated/graphql";
import { ReportFilterT } from "../../types/report";
import { ConditionT, WidgetT } from "../../types/widgets";
import { ButtonSecondary } from "../../ui/Button/Button";
import { Col, GridOverflow, Row } from "../../ui/grid/Grid";
import { HeadingSmall } from "../../ui/Heading/Heading";
import { Tab } from "../../ui/Tabs/Tab";
import { TabList } from "../../ui/Tabs/TabList";
import { TabPanel } from "../../ui/Tabs/TabPanel";
import { Tabs } from "../../ui/Tabs/Tabs";
import { Tile } from "../../ui/Tile/Tile";
import { Tooltip } from "../../ui/Tooltip/Tooltip";
import { ConditionsPreview } from "../conditionsPreview/ConditionsPreview";
import { useConfirmationContext } from "../confirmationDialog/confirmationDialogContext";
import { FormEditWidget } from "../formEditWidget/FormEditWidget";
import { WidgetTypeSelection } from "../widgetTypeSelection/WidgetTypeSelection";
import { ReportBoardReducerStateT, WidgetSettingsDataT } from "./context/types";
import { OnSetEditedWidgetT } from "./ReportBoardEditWidget";
import { ReportFilter } from "./ReportFilter";
import { WidgetTypeButton } from "./WidgetTypeButton";

const WIDTH_SIDEBAR = 488;
const WIDTH_SELECT_MENU = 241;

type PropsT = {
  editedWidget: WidgetT;
  isCreating?: boolean;
  isFilterOpen: boolean;
  onSetEditedWidget: OnSetEditedWidgetT;
  onSetIsFilterOpen: Dispatch<SetStateAction<boolean>>;
  reportFilter: ReportBoardReducerStateT["reportFilter"];
  sectionFilter: ReportFilterT;
  widgetSettingsData: WidgetSettingsDataT;
};

export const ReportBoardEditWidgetSidebar = ({
  editedWidget,
  isCreating,
  isFilterOpen,
  onSetEditedWidget,
  onSetIsFilterOpen,
  reportFilter,
  sectionFilter,
  widgetSettingsData,
}: PropsT) => {
  const [isTypeMenuOpen, setIsTypeMenuOpen] = useState(false);

  const isTypeMenuForceOpen = isTypeMenuOpen || isCreating;
  const { callWithConfirmation } = useConfirmationContext();

  const handleUpdateConditions = useCallback(
    (conditions: ConditionT[]) => {
      onSetEditedWidget((prev) => ({ ...prev, conditions: conditions }));
    },
    [onSetEditedWidget]
  );

  const handleClearConditions = () => {
    onSetEditedWidget((prev) => ({ ...prev, conditions: [] }));
  };

  const handleUpdateConditionsDebounced = debounce(
    useCallback((conditions: ConditionT[]) => handleUpdateConditions(conditions), [handleUpdateConditions]),
    500
  );

  const handleWidgetPresetChange = (newWidgetKind: WidgetKindT) => {
    const newData = getValidatedWidgetData({
      widget: { ...editedWidget, kind: newWidgetKind },
      metrics: widgetSettingsData.metrics,
      dimensions: widgetSettingsData.dimensions,
      connectedSystems: widgetSettingsData.connectedSystems,
    });

    const cleanWidgetDimensions = newData.widgetDimensions.filter((obj) => obj.dimensionId !== "");
    const cleanWidgetMetrics = newData.widgetMetrics.filter((obj) => obj.metricId !== "");

    const cleanNewData = {
      ...newData,
      widgetDimensions: cleanWidgetDimensions,
      widgetMetrics: cleanWidgetMetrics,
    };

    onSetEditedWidget((prev) => ({ ...prev, ...cleanNewData }));
  };

  const dateRange = useMemo(
    () => getWidgetDataFilter({ widget: editedWidget, sectionFilter, reportFilter }).dateRange,
    [editedWidget, sectionFilter, reportFilter]
  );

  return (
    <>
      <Tile
        border="onlyLeftBorder"
        style={{
          width: `${WIDTH_SIDEBAR}px`,
          marginLeft: isTypeMenuForceOpen ? `${WIDTH_SELECT_MENU}px` : 0,
          overflowY: "auto",
          paddingBottom: "72px",
        }}
        isFullHeight
        isSquare
      >
        {!isFilterOpen && (
          <Col style={{ height: "100%" }}>
            <Tabs initialSelectedTab="setting" testId="widget-tabs">
              <TabList className="d-flex justify-content-between negative-ml-16 negative-mr-16">
                <Tab className="flex-1 text-center" id="setting">
                  Settings
                </Tab>
                <Tab className="flex-1 text-center" id="information">
                  Informations
                </Tab>
              </TabList>

              <Row className="pos-relative negative-ml-16 negative-mr-16" type="grow">
                <GridOverflow>
                  <div className="w-100 ph-8 mt-16">
                    <TabPanel tabId="setting">
                      <WidgetTypeButton
                        heading="View type"
                        icon={WIDGET_SCHEMA[editedWidget.kind].icon}
                        isTypeMenuOpen={isTypeMenuOpen}
                        name={WIDGET_SCHEMA[editedWidget.kind].name}
                        setIsTypeMenuOpen={setIsTypeMenuOpen}
                      />

                      <div className="delimiter mv-16" />
                      <FormEditWidget
                        editedWidget={editedWidget}
                        tabPanelType="setting"
                        widgetSettingsData={widgetSettingsData}
                        onSetEditedWidget={onSetEditedWidget}
                      />

                      <div className="delimiter mv-16 negative-ml-16 negative-mr-16" />
                      <div className="d-flex align-items-center">
                        <HeadingSmall margin={0}>Filter</HeadingSmall>
                        {!editedWidget.conditions.length && (
                          <ButtonSecondary
                            className="ml-a"
                            icon="plus"
                            size="small"
                            onlyIcon
                            onClick={() => onSetIsFilterOpen(true)}
                          />
                        )}

                        {!!editedWidget.conditions.length && (
                          <Tooltip tooltipContent="Delete all conditions">
                            <ButtonSecondary
                              className="ml-a"
                              icon="line"
                              size="small"
                              onlyIcon
                              onClick={() =>
                                callWithConfirmation(handleClearConditions, {
                                  description: "Are you sure you want to delete all set filters?",
                                  title: "Confirm this action",
                                  testId: "delete-all-conditions",
                                })
                              }
                            />
                          </Tooltip>
                        )}
                      </div>

                      {!!editedWidget.conditions.length && (
                        <Col
                          className="mt-16 pa-16 cursor-pointer border border-color-soft-gray border-radius-8"
                          onClick={() => onSetIsFilterOpen(true)}
                        >
                          <ConditionsPreview
                            conditions={editedWidget.conditions}
                            metricsById={widgetSettingsData.metricsById}
                          />
                          <ButtonSecondary className="ml-a mt-16" icon="edit">
                            Edit
                          </ButtonSecondary>
                        </Col>
                      )}
                    </TabPanel>

                    <TabPanel tabId="information">
                      <FormEditWidget
                        editedWidget={editedWidget}
                        tabPanelType="information"
                        widgetSettingsData={widgetSettingsData}
                        onSetEditedWidget={onSetEditedWidget}
                      />
                    </TabPanel>
                  </div>
                </GridOverflow>
              </Row>
            </Tabs>
          </Col>
        )}

        {isFilterOpen && (
          <ReportFilter
            conditions={editedWidget.conditions}
            dateRange={dateRange}
            onChange={handleUpdateConditionsDebounced}
            onClose={() => onSetIsFilterOpen(false)}
            onSubmit={handleUpdateConditions}
          />
        )}
      </Tile>

      {!isFilterOpen && isTypeMenuForceOpen && (
        <div
          className="background-white border-end pa-16 pos-absolute h-100"
          style={{
            right: `${WIDTH_SIDEBAR}px`,
            width: `${WIDTH_SELECT_MENU}px`,
            overflowY: "auto",
          }}
        >
          <WidgetTypeSelection selectedWidgetKind={editedWidget?.kind} onSelect={handleWidgetPresetChange} />
        </div>
      )}
    </>
  );
};
