import React, { ForwardedRef } from "react";
import { CusotmIconBasicPropsT } from "./IconCustom";

export const FlameSmIcon = React.forwardRef(
  ({ className, size, ...rest }: CusotmIconBasicPropsT, ref: ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        clipRule="evenodd"
        d="M5.75 0C6.15667 4.79267 2 6.44267 2 10.664C2 13.5313 4.046 15.9787 8 16C11.954 16.0213 14 13.0573 14 10.0293C14 7.26867 12.6253 4.66533 10.032 3.04667C10.648 4.78467 9.828 6.372 9.03133 6.91867C9.078 4.694 8.28133 1.39267 5.75 0Z"
        fill="#FF264A"
        fillRule="evenodd"
      />
    </svg>
  )
);
