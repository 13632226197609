import React, { PropsWithChildren } from "react";
import cs from "classnames";
import { Col, Row } from "../grid/Grid";
import { Icon } from "../Icon/Icon";

export const BOX_VARIANTS = ["info", "warning"] as const;

export type BoxVariantsT = typeof BOX_VARIANTS[number];

export type InfoBoxPropsT = {
  className?: string;
  isCompact?: boolean;
  isComplex?: boolean;
  maxWidth?: string;
  variant?: BoxVariantsT;
  withIcon?: boolean;
} & React.HTMLAttributes<HTMLOrSVGElement>;

export const InfoBox = ({
  children,
  className,
  isCompact,
  isComplex,
  maxWidth,
  variant = "info",
  withIcon = true,
}: PropsWithChildren<InfoBoxPropsT>) => {
  return !isCompact ? (
    <div
      className={cs("InfoBox", `InfoBox--${variant}`, className, { "InfoBox--complex": isComplex })}
      data-test-id="info-box-info"
      style={{ maxWidth }}
    >
      <div className={cs("InfoBox-content", { "InfoBox-content--withIcon": withIcon })}>{children}</div>
    </div>
  ) : (
    <Row className="d-inline-flex">
      <Col>
        <Icon kind="info" size={"1.4em"} />
      </Col>
      <Col type="grow">{children}</Col>
    </Row>
  );
};
