import React, { HTMLAttributes, LegacyRef, ReactNode, forwardRef } from "react";
import cs from "classnames";

export type TypographyTextPropsT = {
  bold?: boolean;
  children: ReactNode;
  className?: string;
  color?:
    | "accented"
    | "black-light"
    | "blue"
    | "darkGreen"
    | "default"
    | "error"
    | "grape"
    | "green"
    | "gray"
    | "lightGray"
    | "softGray"
    | "white";
  isCaption?: boolean;
  medium?: boolean;
  nowrap?: boolean;
  semibold?: boolean;
  size?: "xs" | "sm" | "default";
  spaced?: boolean;
  tag?: "span" | "p";
  underline?: boolean;
  uppercase?: boolean;
  withLine?: boolean;
} & HTMLAttributes<HTMLElement>;

export const Text = forwardRef(
  (
    {
      bold,
      children,
      className,
      color,
      isCaption,
      medium,
      nowrap,
      semibold,
      size,
      spaced,
      tag = "span",
      underline,
      uppercase,
      withLine,
      ...rest
    }: TypographyTextPropsT,
    ref: LegacyRef<HTMLParagraphElement>
  ) => {
    const Tag = tag;
    return (
      <Tag
        ref={ref}
        className={cs("Text", className, {
          [`Text--${color}`]: color,
          [`Text--${size}`]: size,
          "Text--withLine": withLine,
          "Text--bold": bold,
          "Text--semibold": semibold,
          "Text--medium": medium,
          "Text--spaced": spaced,
          "Text--caption": isCaption,
          "Text--underline": underline,
          "Text--uppercase": uppercase,
          "text-nowrap": nowrap,
        })}
        {...rest}
      >
        {children}
      </Tag>
    );
  }
);
