import React from "react";
import cs from "classnames";
import { Ellipsis } from "../Ellipsis/Ellipsis";
import { Col, Row } from "../grid/Grid";
import { Icon } from "../Icon/Icon";

type ItemT = {
  id: string;
  name: string;
};

type PropsT = {
  className?: string;
  hasBorder?: boolean;
  items: ItemT[];
  onSelect: (item: ItemT) => void;
  selectedItemId?: string;
  testId?: string;
};

export const SelectableList = ({ className, hasBorder, items, onSelect, selectedItemId, testId }: PropsT) => {
  return (
    <div className={cs("SelectableList", className, { "SelectableList--withBorder": hasBorder })} data-test-id={testId}>
      {items.map((item) => (
        <div
          key={item.id}
          className={cs("SelectableList-item", { active: item.id === selectedItemId })}
          onClick={() => onSelect(item)}
        >
          <Row className="mw-100">
            <Col>
              <Icon kind="directory" size="14px" />
            </Col>
            <Col type="grow">
              <Ellipsis>{item.name}</Ellipsis>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
};
