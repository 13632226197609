import { useEffect } from "react";
import {
  AliasNameT,
  useAliasCreateNameMutation,
  useAliasDeleteNameMutation,
  useAliasUpdateNameMutation,
} from "../../graphql/generated/graphql";
import { ButtonSecondary, ButtonTertiary } from "../../ui/Button/Button";
import { useConfirmationContext } from "../confirmationDialog/confirmationDialogContext";
import { ShowDimensionMetricPillItemT } from "../showDimensionMetricPill/ShowDimensionMetricPill";
import { AliasTablePropsT } from "./AliasTable";
import { getAliasConfirmationProps, handleNotification, hasAnyError } from "./utils";

export type RowItemT = ShowDimensionMetricPillItemT & { aliasName: AliasNameT };
type AliasButtonPropsT = {
  externalId: string;
  isForceLoading?: boolean;
  onMutationInProgressChange: (isLoading: boolean) => void;
  refetchQuery: AliasTablePropsT["refetch"];
  rowItem: RowItemT;
};

export const AliasButtonCreate = ({
  externalId,
  refetchQuery,
  rowItem,
  type,
}: {
  externalId: string;
  refetchQuery?: AliasTablePropsT["refetch"];
  rowItem: Omit<RowItemT, "aliasName">;
  type: AliasTablePropsT["type"];
}) => {
  const { callWithConfirmationManualClose } = useConfirmationContext();
  const [createAlias, { loading }] = useAliasCreateNameMutation();

  const handleCreate = () => {
    callWithConfirmationManualClose(async (onClose, name) => {
      if (!name) {
        return;
      }
      const keyType = type === "dimensions" ? "dimensionId" : "metricId";
      const res = await createAlias({ variables: { externalId, value: name, [keyType]: rowItem.id } });
      const errors = res.data?.organization?.createAliasName?.errors;
      handleNotification({ refetchQuery, successMessage: "Succesfully created alias.", errors });
      if (!hasAnyError(errors)) {
        onClose();
      }
    }, getAliasConfirmationProps({ item: rowItem }));
  };

  return (
    <ButtonSecondary loading={loading} size="small" onClick={handleCreate}>
      Add Alias
    </ButtonSecondary>
  );
};

export const AliasButtonUpdate = ({
  externalId,
  isForceLoading,
  onMutationInProgressChange,
  rowItem,
}: Omit<AliasButtonPropsT, "refetchQuery">) => {
  const { callWithConfirmationManualClose } = useConfirmationContext();
  const [updateAlias, { loading }] = useAliasUpdateNameMutation();

  useEffect(() => {
    onMutationInProgressChange(loading);
  }, [loading, onMutationInProgressChange]);

  const handleUpdate = () => {
    callWithConfirmationManualClose(async (onClose, name) => {
      if (!name) {
        return;
      }
      const res = await updateAlias({ variables: { externalId, aliasId: rowItem.aliasName.id, value: name } });
      const errors = res.data?.organization?.updateAliasName?.errors;
      handleNotification({ successMessage: "Succesfully updated alias.", errors });
      if (!hasAnyError(errors)) {
        onClose();
      }
    }, getAliasConfirmationProps({ item: rowItem, defaultValue: rowItem.aliasName.value || undefined, heading: "Edit Alias for" }));
  };

  return (
    <ButtonTertiary icon="edit" loading={isForceLoading || loading} size="small" onlyIcon onClick={handleUpdate} />
  );
};

export const AliasButtonDelete = ({
  externalId,
  isForceLoading,
  onMutationInProgressChange,
  refetchQuery,
  rowItem,
}: AliasButtonPropsT) => {
  const { callWithConfirmation } = useConfirmationContext();
  const [deleteAlias, { loading }] = useAliasDeleteNameMutation();

  useEffect(() => {
    onMutationInProgressChange(loading);
  }, [loading, onMutationInProgressChange]);

  const handleDeleteAlias = () => {
    return callWithConfirmation(
      async () => {
        const res = await deleteAlias({ variables: { externalId, id: rowItem.aliasName.id } });
        handleNotification({
          errors: res.data?.organization?.deleteAliasName?.errors,
          refetchQuery,
          successMessage: "Succesfully deleted alias.",
        });
      },
      {
        confirmButtonProps: {
          variant: "red",
          text: "Delete",
        },
        description: `Are you sure you want to delete this alias?`,
        title: `Delete alias: "${rowItem.aliasName.value}"`,
      }
    );
  };

  return (
    <ButtonTertiary
      icon="remove"
      loading={isForceLoading || loading}
      size="small"
      onlyIcon
      onClick={handleDeleteAlias}
    />
  );
};
