import React, { PropsWithChildren } from "react";
import cs from "classnames";

export type ErrorBoxPropsT = {
  className?: string;
  isComplex?: boolean;
  maxWidth?: string;
  withIcon?: boolean;
} & React.HTMLAttributes<HTMLOrSVGElement>;

const ErrorBox = ({ children, className, isComplex, maxWidth, withIcon }: PropsWithChildren<ErrorBoxPropsT>) => (
  <div
    className={cs("InfoBox", "InfoBox--error", className, { "InfoBox--complex": isComplex })}
    data-test-id="error-box-error"
    style={{ maxWidth }}
  >
    <div className={cs("InfoBox-content", { "InfoBox-content--withIcon": withIcon })}>{children}</div>
  </div>
);

export default ErrorBox;
