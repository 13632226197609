import React, { CSSProperties, HTMLAttributes, PropsWithChildren } from "react";
import cs from "classnames";

export type PropsT = {
  absolute?: boolean;
  className?: string;
  size?: "extraSmall" | "small" | "medium" | "semiBig" | "big";
  style?: CSSProperties;
} & HTMLAttributes<HTMLDivElement>;

export const Loader = ({ size, absolute, children, style = {}, className, ...rest }: PropsWithChildren<PropsT>) => {
  const cls = cs("Loader", "vab", { [`Loader--${size}`]: size }, className);

  if (absolute) {
    return (
      <div className="Loader--absolute" style={style} {...rest}>
        <div className={cls} data-test-id="loader" />
        {children}
      </div>
    );
  }

  return <div className={cls} data-test-id="loader" {...rest} />;
};
