import React, { useCallback, useMemo, useState } from "react";
import { cloneDeep } from "lodash";
import { v4 as uuid } from "uuid";
import dataSourceImage from "../../assets/data_source.svg";
import { DUPLICATED_PREFIX } from "../../constants/report";
import { buildNewSection } from "../../functions/sectionHelper";
import { ReportBoardSectionT, SectionT } from "../../types/widgets";
import { ButtonPrimary } from "../../ui/Button/Button";
import { HeadingSection } from "../../ui/Heading/Heading";
import { useModal } from "../../ui/Modal";
import { Text } from "../../ui/Text/Text";
import { cleanWidget, clearItemId, compact, shouldUseCopyPrefix, sortByPosition } from "./context/functions";
import { useReportBoardContext } from "./context/reportBoardContext";
import { ReportBoardPageT } from "./context/types";
import { ReportBoardEditFilters } from "./ReportBoardEditFilters";
import { SectionModal } from "./ReportBoardSectionModal";
import ReportBoardSections from "./ReportBoardSections";

export type PageContextT = {
  editedFilterSectionId: string | null;
  editedSection: ReportBoardSectionT | null;
  openCreateSectionModal: (position: number) => void;
  sectionDelete: (sectionId: SectionT["id"]) => void;
  sectionDuplicate: (section: SectionT) => void;
  sectionFilterClose: () => void;
  sectionFilterOpen: (sectionId: string) => void;
  sectionUpdate: (params: {
    sectionId: SectionT["id"];
    sectionInputData: SectionT | ((section: SectionT) => SectionT);
  }) => void;
  sectionsList: Pick<ReportBoardSectionT, "id" | "name">[];
  setEditedFilterSectionId: React.Dispatch<React.SetStateAction<string | null>>;
};

type PropsT = {
  activePageId?: string | null;
};

export const ReportBoardPage = ({ activePageId }: PropsT) => {
  const [newSectionPosition, setNewSectionPosition] = useState(0);
  const [editedFilterSectionId, setEditedFilterSectionId] = useState<string | null>(null);
  const {
    isReportFilterOpen,
    pageUpdate: pageUpdateContext,
    pagesData,
    reportOptions: { canEdit },
  } = useReportBoardContext();
  const sectionModalOptions = useModal();
  const page = pagesData.find((pageData) => pageData.uuid === activePageId);
  const isEmptyBoard = !page?.sections.length;

  const pageUpdate = useCallback(
    (pageInputData: Partial<ReportBoardPageT> | ((section: ReportBoardPageT) => ReportBoardPageT)) => {
      if (page?.id) {
        pageUpdateContext({ pageId: page.id, pageInputData });
      }
    },
    [page?.id, pageUpdateContext]
  );

  const openCreateSectionModal = useCallback(
    (newPosition: number) => {
      setNewSectionPosition(newPosition);
      sectionModalOptions.open();
    },
    [sectionModalOptions]
  );

  const sectionAdd = useCallback(
    (sectionInputData: Partial<ReportBoardSectionT>, params?: { newPosition?: number }) => {
      const newSection = buildNewSection({
        ...sectionInputData,
        id: uuid(),
        pageUuid: page?.uuid as string,
        position: params?.newPosition || newSectionPosition,
      });
      pageUpdate((prev) => ({
        ...prev,
        sections: [...prev?.sections, newSection].sort(sortByPosition),
      }));
    },
    [newSectionPosition, page?.uuid, pageUpdate]
  );

  const sectionDuplicate = useCallback(
    (originalSection: SectionT) => {
      const name = shouldUseCopyPrefix(originalSection.name)
        ? `${DUPLICATED_PREFIX} ${originalSection.name}`
        : originalSection.name || "";

      sectionAdd(
        {
          ...cloneDeep(compact(originalSection)),
          id: uuid(),
          name,
          isNew: true,
          createdAt: null,
          pageUuid: page?.uuid || "",
          dateRange: originalSection.dateRange || undefined,
          conditions: originalSection.conditions?.map(clearItemId) || [],
          widgets: {
            nodes: originalSection.widgets.nodes.map((widget) => ({
              ...cloneDeep(cleanWidget(widget)),
              isNew: true,
            })),
          },
        },
        { newPosition: originalSection.position }
      );
    },
    [sectionAdd, page?.uuid]
  );

  const sectionDelete = useCallback(
    (sectionId: SectionT["id"]) => {
      const index = page?.sections.findIndex(({ id }) => id === sectionId);
      const sections = [...(page?.sections || [])];

      if (index !== undefined) {
        sections.splice(index, 1);
      }

      pageUpdate({ sections });
    },
    [page?.sections, pageUpdate]
  );

  const sectionUpdate = useCallback(
    ({
      sectionId,
      sectionInputData,
    }: {
      sectionId: ReportBoardSectionT["id"];
      sectionInputData: ReportBoardSectionT | ((section: ReportBoardSectionT) => ReportBoardSectionT);
    }) => {
      pageUpdate((prev) => {
        const updatingSection = prev.sections.find((section) => sectionId === section.id);
        const otherSections = prev.sections.filter((section) => sectionId !== section.id);

        const newSection = (
          typeof sectionInputData === "function" && updatingSection
            ? sectionInputData(updatingSection)
            : sectionInputData
        ) as ReportBoardSectionT;

        return { ...prev, sections: [...otherSections, { ...newSection }] };
      });
    },
    [pageUpdate]
  );

  const sectionFilterOpen = useCallback((sectionId: string) => {
    setEditedFilterSectionId(sectionId);
  }, []);

  const sectionFilterClose = useCallback(() => {
    setEditedFilterSectionId(null);
  }, []);

  const editedSection = useMemo(
    () => cloneDeep(page?.sections.find((section) => section.id === editedFilterSectionId)) || null,
    [page?.sections, editedFilterSectionId]
  );

  const sectionsList = useMemo(() => {
    return page?.sections.map((section) => ({ id: section.id, name: section.name })) || [];
  }, [page?.sections]);

  const pageContext = useMemo(
    () => ({
      editedFilterSectionId,
      editedSection,
      openCreateSectionModal,
      sectionDelete,
      sectionDuplicate,
      sectionFilterClose,
      sectionFilterOpen,
      sectionUpdate,
      setEditedFilterSectionId,
      sectionsList,
    }),
    [
      editedFilterSectionId,
      editedSection,
      openCreateSectionModal,
      sectionDelete,
      sectionDuplicate,
      sectionFilterClose,
      sectionFilterOpen,
      sectionUpdate,
      setEditedFilterSectionId,
      sectionsList,
    ]
  );

  const handleAddSection = useCallback(
    (section: Partial<SectionT>) => {
      sectionAdd(section);
      sectionModalOptions.close();
    },
    [sectionAdd, sectionModalOptions]
  );

  if (!page) {
    return <div>Empty report</div>;
  }

  return (
    <>
      {canEdit && isEmptyBoard && (
        <div className="mt-16 mb-40 text-center">
          <div className="pv-24">
            <img alt="Data source" src={dataSourceImage} />
            <HeadingSection className="mt-16">Create first section</HeadingSection>
            <div className="mb-16">
              <Text color="gray">Create first section of the report.</Text>
            </div>

            <ButtonPrimary data-test-id="add-new-section" icon="plus" onClick={() => openCreateSectionModal(0)}>
              Add new section
            </ButtonPrimary>
          </div>
        </div>
      )}

      <ReportBoardSections pageContext={pageContext} sections={page.sections} />

      <SectionModal
        modalOptions={{ ...sectionModalOptions, testId: "section-new-modal", heading: "Create new section" }}
        isCreating
        onSubmit={handleAddSection}
      />

      {(editedFilterSectionId || isReportFilterOpen) && <ReportBoardEditFilters pageContext={pageContext} />}
    </>
  );
};
