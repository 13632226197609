/* eslint-disable @typescript-eslint/naming-convention */
import { isEqual, omit } from "lodash";
import { ConditionT, ConditionsT } from "../types/widgets";

export const getConditionsApiInputFormat = (conditions: ConditionsT) =>
  conditions.map((condition) => ({
    id: condition.id,
    groupId: condition.groupId,
    values: condition.values,
    ...(condition.metric &&
      condition.metricOperator && { metric: { id: condition.metric.id, operator: condition.metricOperator } }),
    ...(condition.dimension &&
      condition.dimensionOperator && {
        dimension: { id: condition.dimension.id, operator: condition.dimensionOperator },
      }),
  }));

type GeneratedConditionT = ConditionT & {
  __typename?: string;
};

export const getIsConditionsEqual = (first: GeneratedConditionT[], second: GeneratedConditionT[]): boolean => {
  const getClearedConditions = (conditions: GeneratedConditionT[]) => {
    return conditions.map((condition) => {
      return {
        ...omit(condition, ["__typename", "metricId", "dimensionId"]),
        ...(condition.metric && { metric: omit(condition.metric, "__typename", "name") }),
        ...(condition.dimension && { dimension: omit(condition.dimension, "__typename", "name") }),
      };
    });
  };

  return isEqual(getClearedConditions(first), getClearedConditions(second));
};
