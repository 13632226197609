import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { Row } from "../ui/grid/Grid";
import { Modal, ModalBody } from "../ui/Modal";
import { SegmentedButton } from "../ui/SegmentedButton/SegmentedButton";
import { SegmentedItem } from "../ui/SegmentedButton/SegmentedItem";
import { Text } from "../ui/Text/Text";
import { MetricNewModal } from "./MetricNewModal";
import { ValueMetricNewModal } from "./ValueMetricNewModal";

const METRIC = "metric";
const VALUE_METRIC = "value-metric";

export const CustomMetricNewModal = ({
  onClose,
  onCreate,
}: {
  onClose: () => void;
  onCreate?: (id: string) => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type");

  useEffect(() => {
    if (!type || ![METRIC, VALUE_METRIC].includes(type)) {
      searchParams.set("type", METRIC);
      setSearchParams(searchParams);
    }
  }, [setSearchParams, type, searchParams]);

  return (
    <Modal close={onClose} heading="Create new metric" testId="new-custom-metric-modal" zIndex="high" isOpen>
      <ModalBody>
        <SegmentedButton
          id="metric-type-selection"
          name="metric-type-selection"
          testId="metric-type-selection"
          value={type || METRIC}
          onChange={(event) => {
            searchParams.set("type", event.target.value);
            setSearchParams(searchParams);
          }}
        >
          <Row alignItems="center">
            <Text className="mr-8" color="gray">
              Choose which type of metric you want to create:
            </Text>
            <SegmentedItem value={METRIC}>Calculated Metric</SegmentedItem>
            <SegmentedItem value={VALUE_METRIC}>Custom Value Metric</SegmentedItem>
          </Row>
        </SegmentedButton>
      </ModalBody>
      {type === METRIC && <MetricNewModal onCreate={onCreate} />}
      {type === VALUE_METRIC && <ValueMetricNewModal onCreate={onCreate} />}
    </Modal>
  );
};

export const MetricNewPage = () => {
  const navigate = useNavigate();
  const handleReturnToMainPage = () => navigate(ROUTES.metricsAndDimensions());

  return <CustomMetricNewModal onClose={handleReturnToMainPage} onCreate={handleReturnToMainPage} />;
};
