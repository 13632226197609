import React, { Fragment, useMemo } from "react";
import { WIDGET_SCHEMA } from "../../constants/report";
import { DataConsistencyT, WidgetDataFragmentT } from "../../graphql/generated/graphql";
import { UseSearchT } from "../../hooks/useSearch";
import { ReportFilterT } from "../../types/report";
import { WidgetT } from "../../types/widgets";
import { WidgetContextT } from "../reportBoard/ReportBoardSection";
import AreaChartComponent from "../widgetComponents/AreaChartComponent";
import BarChartComponent from "../widgetComponents/BarChartComponent";
import ImageComponent from "../widgetComponents/ImageComponent";
import LineChartComponent from "../widgetComponents/LineChartComponent";
import NoteComponent from "../widgetComponents/NoteComponent";
import PieChartComponent from "../widgetComponents/PieChartComponent";
import ScatterChartComponent from "../widgetComponents/ScatterChartComponent";
import ScorecardComponent from "../widgetComponents/ScorecardComponent";
import TableComponent from "../widgetComponents/TableComponent";
import TableOptimizationComponent from "../widgetComponents/TableOptimizationComponent";
import TimeBarChartComponent from "../widgetComponents/TimeBarChartComponent";
import TimeStackedBarChartComponent from "../widgetComponents/TimeStackedBarChartComponent";
import TitleComponent from "../widgetComponents/TitleComponent";
import widgetContainer, { ChangeWidgetDataT } from "./widgetContainer";

export type WidgetPropsT = {
  dataConsistency?: DataConsistencyT;
  filterData: UseSearchT<WidgetDataFragmentT["rows"]["nodes"]>["filterData"];
  filterValues: ReportFilterT;
  isPreview?: boolean;
  isShowRawData: boolean;
  onCloseRawData: () => void;
  onWidgetDataChange: ChangeWidgetDataT;
  widget: WidgetT;
  widgetContext: WidgetContextT;
};

const Widget = ({
  filterData,
  filterValues,
  isPreview,
  isShowRawData,
  onCloseRawData,
  onWidgetDataChange,
  widget,
  widgetContext,
}: WidgetPropsT) => {
  const components = useMemo(
    () => ({
      titleComponent: TitleComponent,
      tableComponent: TableComponent,
      tableOptimizationComponent: TableOptimizationComponent,
      imageComponent: ImageComponent,
      noteComponent: NoteComponent,
      scorecardComponent: ScorecardComponent,
      lineChartComponent: LineChartComponent,
      areaChartComponent: AreaChartComponent,
      pieChartComponent: PieChartComponent,
      barChartComponent: BarChartComponent,
      timeBarChartComponent: TimeBarChartComponent,
      timeStackedBarChartComponent: TimeStackedBarChartComponent,
      scatterChartComponent: ScatterChartComponent,
    }),
    []
  );

  return (
    <Fragment>
      {WIDGET_SCHEMA[widget.kind].components.map((key) => {
        const Component = components[key];

        return (
          <Component
            key={key}
            componentType={key}
            filterData={filterData}
            filterValues={filterValues}
            isPreview={isPreview}
            isShowRawData={isShowRawData}
            widget={widget}
            widgetContext={widgetContext}
            onCloseRawData={onCloseRawData}
            onWidgetDataChange={onWidgetDataChange}
          />
        );
      })}
    </Fragment>
  );
};

export default widgetContainer(Widget);
