import { notifyError, notifySucces } from "../../functions/toast";
import { Row } from "../../ui/grid/Grid";
import { ConfirmationDialogOptionT } from "../confirmationDialog/confirmationDialogContext";
import {
  ShowDimensionMetricPill,
  ShowDimensionMetricPillItemT,
} from "../showDimensionMetricPill/ShowDimensionMetricPill";

type GetAliasConfirmationPropsT = (params: {
  defaultValue?: string;
  heading?: string;
  item: ShowDimensionMetricPillItemT;
}) => ConfirmationDialogOptionT;

export const getAliasConfirmationProps: GetAliasConfirmationPropsT = ({
  defaultValue,
  heading = "Add Alias for",
  item,
}) => ({
  inputProps: {
    defaultValue,
    isRequired: true,
    label: "New Alias",
  },
  title: (
    <Row alignItems="center" justify="center" type="grow">
      {heading}
      <ShowDimensionMetricPill className="ml-8" cursor="auto" item={item} />
    </Row>
  ),
  description:
    "The alias is used to replace the original name, if you need to call a metric or dimension something else, create a new alias below.",
  confirmButtonText: "Save",
});

type HandleNotificationAndGetStatusT = (params: {
  errors?: string[] | null;
  refetchQuery?: () => void;
  successMessage: string;
}) => void;

export const hasAnyError = (errors?: string[] | null) => !!errors?.length;

export const handleNotification: HandleNotificationAndGetStatusT = ({ errors, refetchQuery, successMessage }) => {
  if (errors?.length) {
    notifyError(<>{errors.join("\n")}</>);
    return;
  }

  if (refetchQuery) {
    refetchQuery();
  }
  notifySucces(<>{successMessage}</>);
};
