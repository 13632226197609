import React, { ReactNode } from "react";
import cs from "classnames";
import { DateRangeT, Maybe } from "../../graphql/generated/graphql";
import { formatDateRange, formatDateRangeSelection } from "../../i18n/formatDate";
import { formatMetricValue } from "../../i18n/formatNumbers";
import { TrendBadge, TrendWrapper, TrendWrapperPropsT } from "../../ui/DataTable/TrendWrapper";
import { Col, Row } from "../../ui/grid/Grid";
import { Heading } from "../../ui/Heading/Heading";
import { Text } from "../../ui/Text/Text";
import { ReportMetricT } from "../reportBoard/context/types";
import { ShowDimensionMetricPill } from "../showDimensionMetricPill/ShowDimensionMetricPill";

type LabelPropsT = {
  dimensionValue?: ReactNode;
  metric: Pick<ReportMetricT, "dataType" | "name" | "adSystems" | "analyticsSystems" | "positiveTrend" | "id">;
};
export type CompareDateT = { previousDate: string; previousDateTo: string };

type PropsT = {
  children?: React.ReactNode;
  color?: string;
  compareDate?: CompareDateT;
  currency?: string | null;
  dateRange?: Maybe<DateRangeT>;
  hidePill?: boolean;
  previousValue?: number | null;
  size?: "small" | "small-inline" | "large";
  value?: number | null;
} & Pick<TrendWrapperPropsT, "trend" | "compare"> &
  LabelPropsT;

const MetricDimensionLabel = ({ dimensionValue, metric }: LabelPropsT) => (
  <>
    <Col style={{ maxWidth: "100%" }}>
      <ShowDimensionMetricPill className="w-100" item={metric} />
    </Col>
    {dimensionValue && dimensionValue !== "-" && (
      <Col>
        <Text color="softGray" tag="p">
          {dimensionValue}
        </Text>
      </Col>
    )}
  </>
);

export const CompareInterval = ({
  className,
  isLight,
  previousDate,
  previousDateTo,
}: CompareDateT & { className?: string; isLight?: boolean }) => {
  const dateFrom = new Date(previousDate);
  const dateTo = new Date(previousDateTo);
  return (
    <Text className={className} color={isLight ? "white" : "softGray"} size="xs">
      {formatDateRange(dateFrom, dateTo)}
    </Text>
  );
};

export const MetricInformationCard = ({
  children,
  color,
  compare,
  compareDate,
  currency,
  dateRange,
  dimensionValue,
  hidePill,
  metric,
  previousValue,
  size = "large",
  trend,
  value,
}: PropsT) => {
  if (!metric) {
    return null;
  }

  if (size === "small" || size === "small-inline") {
    return (
      <Row alignItems="center" className={cs("w-100", { "pos-relative pl-32": color })} padding="m" flexwrap>
        {color && (
          <span
            style={{
              background: color,
              display: "block",
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              position: "absolute",
              top: "0.6em",
              left: "6px",
            }}
          />
        )}
        {!hidePill && (
          <Col className={cs({ "w-100": size === "small" })}>
            <Row alignItems="center" className="w-100" justify="start" padding="s">
              <MetricDimensionLabel
                dimensionValue={dimensionValue ? `${dimensionValue}: ` : undefined}
                metric={metric}
              />
            </Row>
          </Col>
        )}
        {children && children.toString().trim() !== "-" && <Col style={{ maxWidth: "100%" }}>{children}</Col>}
        <Col>
          <Heading
            className="ma-0"
            level="h3"
            dangerouslySetInnerHTML={{
              __html: formatMetricValue({ metric, currency, value }).replace(/\s/g, "&nbsp;"),
            }}
          />
        </Col>
        <Col>
          <Row alignItems="center">
            {typeof trend === "number" && <TrendBadge positiveTrend={!!metric.positiveTrend} trend={trend} />}
            {typeof previousValue === "number" && (
              <span className="ml-4">previous: {formatMetricValue({ metric, currency, value: previousValue })}</span>
            )}
            {compareDate && <CompareInterval className="ml-4" {...compareDate} />}
          </Row>
        </Col>
      </Row>
    );
  }

  return (
    <Col alignItems="center" justify="center">
      {!hidePill && (
        <Row>
          <MetricDimensionLabel dimensionValue={dimensionValue} metric={metric} />
        </Row>
      )}

      {dateRange?.range ? <Row alignItems="center">{formatDateRangeSelection(dateRange)}</Row> : null}
      <Row alignItems="center" justify="center">
        <TrendWrapper
          className="ml-0 mb-4"
          compare={compare}
          compareDate={compareDate}
          currentValue={formatMetricValue({ metric, currency, value })}
          positiveTrend={!!metric.positiveTrend}
          previousValue={formatMetricValue({ metric, currency, value: previousValue })}
          trend={trend}
        >
          <Heading
            className="mt-8 pa-0 Heading--intro"
            level="h1"
            dangerouslySetInnerHTML={{
              __html: formatMetricValue({ metric, currency, value }).replace(/\s/g, "&nbsp;"),
            }}
          />
        </TrendWrapper>
      </Row>
    </Col>
  );
};
