import React from "react";
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { CHART_LINE_COLOR_OTHER } from "../../constants/report";
import { fetchDimensionValueFromRowAsText, fetchMetricCompareFromRow } from "../../functions/widgetDataHelpers";
import { WidgetRowMetricT } from "../../graphql/generated/graphql";
import { ReportDimensionT, ReportMetricT } from "../reportBoard/context/types";
import { MetricInformationCard } from "./MetricInformationCard";

export const WidgetChartTooltipSummaryPie = ({
  active,
  metric,
  payload,
  selectedDimensions,
}: TooltipProps<ValueType, NameType> & {
  metric?: ReportMetricT;
  selectedDimensions: ReportDimensionT[];
}) => {
  const widgetRowMetric = payload?.[0]?.payload?.widgetMetrics?.find(
    (widgetMetric: WidgetRowMetricT) => widgetMetric.metricId === metric?.id
  );

  if (!widgetRowMetric || !active || !metric) {
    return null;
  }

  return (
    <div className="Popover custom-tooltip" style={{ borderColor: `${CHART_LINE_COLOR_OTHER}` }}>
      <div
        className="w-100 mb-8"
        style={{ backgroundColor: payload?.[0]?.payload?.fill, height: "4px", borderRadius: "2px" }}
      />

      <MetricInformationCard
        key={`${widgetRowMetric.metricId}`}
        compareDate={fetchMetricCompareFromRow({ id: "XXX", metricId: widgetRowMetric.metricId })(payload?.[0].payload)}
        currency={payload?.[0].payload?.currency}
        metric={metric}
        previousValue={widgetRowMetric.previousValue}
        size="small"
        trend={widgetRowMetric?.trend}
        value={widgetRowMetric?.value as number}
        hidePill
      >
        {fetchDimensionValueFromRowAsText(selectedDimensions)(payload?.[0].payload)}
      </MetricInformationCard>
    </div>
  );
};
