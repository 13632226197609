import { cleanWidget, clearItemId, compact } from "../components/reportBoard/context/functions";
import { ReportBoardSectionT } from "../types/widgets";

export const buildNewSection = (
  initData: Partial<Omit<ReportBoardSectionT, "pageUuid">> & Pick<ReportBoardSectionT, "pageUuid">
): ReportBoardSectionT => ({
  ...compact(initData),
  name: initData.name || "",
  description: initData.description || "",
  id: initData.id || "",
  pageUuid: initData.pageUuid,
  isNew: true,
  widgets: {
    nodes: initData.widgets?.nodes.map((widget) => ({ ...cleanWidget(widget), isNew: true })) || [],
  },
  createdAt: null,
  dateRange: initData.dateRange || undefined,
  conditions: initData.conditions?.map(clearItemId) || [],
  position: initData.position || 0,
});

export const addNewItemToSections = <T extends { position: number }, K>(items: T[], item: K, position: number) => {
  const newItem = { ...item, position: position + 1 };
  const editedItems = items.map((it) => (it.position <= position ? it : { ...it, position: it.position + 1 }));
  return [...editedItems, newItem];
};
