import React, { useMemo, useState } from "react";
import { LazyQueryExecFunction } from "@apollo/client";
import { groupBy } from "lodash";
import { SYSTEMS_AD, SYSTEMS_ANALYTICS } from "../../constants/report";
import { getAccountSelectLabel } from "../../functions/sourceSystemsHelper";
import { Exact, NewAccountAccessLinkQueryT, SubAccountT, SystemNameT } from "../../graphql/generated/graphql";
import { SourceSystemsT } from "../../types/report";
import { ButtonPrimary } from "../../ui/Button/Button";
import { HeadingSection } from "../../ui/Heading/Heading";
import { Icon } from "../../ui/Icon/Icon";
import { Loader } from "../../ui/Loader/Loader";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { withApiStateHandler } from "../ErrorLoadingWrapper/withApiStateHandler";
import { FormChooseAccountPropsT } from "../formChooseAccount/FormChooseAccount";
import { AccountSelectButton } from "./AccountSelectButton";
import { AccountSelectModal } from "./AccountSelectModal";

export type GetAccountLinkT = LazyQueryExecFunction<
  NewAccountAccessLinkQueryT,
  Exact<{
    organizationExternalId: string;
    returnTo: string;
  }>
>;

type PropsT = {
  children?: JSX.Element;
  fakeDataStatus?: boolean;
  getAccountLink: GetAccountLinkT;
  isCompactView?: boolean;
  onClearSubaccounts: (systemName: SystemNameT) => void;
  refetchSubAccounts: () => void;
  requiredSystems: {
    adSystems: string[];
    analyticsSystems: string[];
    requireAtLeastAny: boolean;
  };
  sourceSystems: SourceSystemsT;
  subAccounts?: SubAccountT[];
  submitLoading?: boolean;
} & Pick<FormChooseAccountPropsT, "onSubmit">;

export const AccountSelect = withApiStateHandler(
  ({
    children,
    fakeDataStatus,
    getAccountLink,
    isCompactView,
    onClearSubaccounts,
    onSubmit,
    refetchSubAccounts,
    requiredSystems,
    sourceSystems,
    subAccounts,
    submitLoading,
  }: PropsT) => {
    const [isOpen, setIsOpen] = useState(false);
    const [editedSystem, setEditedSystem] = useState<SystemNameT | null>(null);
    const WrapperComponent = isCompactView ? "div" : Tile;

    const handleShowSelectSystemModal = (systemName?: SystemNameT | null) => {
      setIsOpen(true);
      if (systemName) {
        setEditedSystem(systemName);
      }
    };

    const handleClose = () => {
      setEditedSystem(null);
      setIsOpen(false);
    };

    const handleSubmit: FormChooseAccountPropsT["onSubmit"] = (props) => {
      onSubmit(props);
      handleClose();
    };

    const groupedSubAccounts = useMemo(
      () => (subAccounts?.length ? groupBy(subAccounts, "system") : {}),
      [subAccounts]
    );

    const groupedSourceSystems = useMemo(
      () => (sourceSystems?.length ? groupBy(sourceSystems, "name") : {}),
      [sourceSystems]
    );

    const isAllAnalyticsSystems =
      requiredSystems.analyticsSystems.length === 1 && requiredSystems.analyticsSystems[0] === SystemNameT.AnyT;
    const isAllAddSystems = requiredSystems.adSystems.length === 1 && requiredSystems.adSystems[0] === SystemNameT.AnyT;
    const isAtLeastAny = requiredSystems.requireAtLeastAny;

    const analyticsSystems =
      isAllAnalyticsSystems || isAtLeastAny ? SYSTEMS_ANALYTICS : requiredSystems.analyticsSystems;
    const addSystems = isAllAddSystems || isAtLeastAny ? SYSTEMS_AD : requiredSystems.adSystems;

    const showedSystems = new Set([...analyticsSystems, ...addSystems]);
    sourceSystems.forEach((system) => showedSystems.add(system.name));
    const label = getAccountSelectLabel({
      adSystems: requiredSystems.adSystems,
      analyticsSystems: requiredSystems.analyticsSystems,
    });

    return (
      <>
        <WrapperComponent className="mb-16">
          {submitLoading && <Loader size="semiBig" absolute />}

          {!isCompactView && (
            <div className="d-flex align-items-center mb-8">
              <Icon className="mr-8" kind="data-sources" size="16px" />
              <HeadingSection margin={0}>Data Sources</HeadingSection>
            </div>
          )}

          <div className="d-flex justify-content-between align-items-start">
            <div className="d-flex align-items-start flex-gap-8">
              {label && fakeDataStatus && <Text className="pt-8 nowrap">{label}</Text>}
              <div className="d-flex flex-wrap flex-gap-8">
                {!!showedSystems.size &&
                  Array.from(showedSystems)
                    .sort()
                    .map((system) => {
                      const systemSubAccounts = groupedSubAccounts[system] || [];
                      const systemSourceSystems = groupedSourceSystems[system] || [];
                      const connectedSystems = systemSubAccounts.filter((account) =>
                        systemSourceSystems.map(({ externalId }) => externalId).includes(account.externalId)
                      );
                      return (
                        <AccountSelectButton
                          key={system}
                          className="mr-8"
                          connectedSystems={connectedSystems}
                          getAccountLink={getAccountLink}
                          isAnyAccountConnected={!!systemSubAccounts?.length}
                          refetchSubAccounts={refetchSubAccounts}
                          systemName={system as SystemNameT}
                          onClearSubaccounts={onClearSubaccounts}
                          onEditedSystem={handleShowSelectSystemModal}
                        />
                      );
                    })}
              </div>
            </div>
            <ButtonPrimary icon="plus" onClick={() => handleShowSelectSystemModal()}>
              Add Account
            </ButtonPrimary>
          </div>
          {children}
        </WrapperComponent>

        {isOpen && (
          <AccountSelectModal
            editedSystem={editedSystem}
            getAccountLink={getAccountLink}
            groupedSubAccounts={groupedSubAccounts}
            refetchSubAccounts={refetchSubAccounts}
            requiredSystems={[...addSystems, ...analyticsSystems] as SystemNameT[]}
            selectedAccounts={groupedSourceSystems[editedSystem || ""]}
            onClose={handleClose}
            onShowSelectSystemModal={handleShowSelectSystemModal}
            onSubmit={handleSubmit}
          />
        )}
      </>
    );
  }
);
