import React, { HTMLAttributes } from "react";
import cs from "classnames";
import { ButtonPropsT } from "../Button/Button";
import { Col, Row } from "../grid/Grid";
import { Link, LinkPropsT } from "../Link/Link";
import { Loader } from "../Loader/Loader";
import { Dropdown } from "./Dropdown";
import { DropdownMenuButton } from "./DropdownMenuButton";

type DropdownMenuItemT = LinkPropsT & {
  isLoading?: boolean;
  value: string;
};

export type DropdownMenuCollectionT = DropdownMenuItemT[] | [];

type TypeT = "horizontal" | "error" | "withList" | "small";

export type DropdownMenuPropsT = {
  buttonProps?: Pick<ButtonPropsT, "variant" | "disabled" | "size" | "icon">;
  className?: string;
  collection: DropdownMenuCollectionT;
  icon?: string;
  isHorizontal?: boolean;
  isLoading?: boolean;
  label?: string;
  onMenuOpen?: () => void;
  showItemValueInDropdown?: boolean;
  testId: string;
  types?: TypeT[];
} & HTMLAttributes<HTMLDivElement>;

const Content = ({
  collection,
  dataTestId,
  isLoading,
  onToggle,
}: Pick<DropdownMenuPropsT, "collection" | "isLoading"> & {
  dataTestId?: string;
  onToggle?: (open: boolean) => void;
}) => {
  if (!onToggle || !dataTestId || !collection.length) {
    return null;
  }

  return (
    <>
      {collection.map((item, index) => {
        const onClick = item?.onClick;
        if (onClick && typeof onClick === "function") {
          item.onClick = (event) => {
            onClick(event);
            onToggle(false);
          };
        }

        return (
          <div key={`${item.value}${index}`} className={index > 0 ? "mt-4" : "mt-0"}>
            <Row>
              <Col type="grow" noWrap>
                <Link
                  {...item}
                  data-test-id={`${dataTestId}--${item.value}`}
                  isDisabled={isLoading || item?.isDisabled || item?.isLoading}
                >
                  {item.value}
                  {item?.isLoading && <Loader className="ml-8" size="extraSmall" />}
                </Link>
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

export const DropdownMenu = ({
  buttonProps,
  children,
  className,
  collection,
  icon = "more",
  isLoading,
  label,
  onMenuOpen,
  style,
  testId,
  types,
  ...rest
}: DropdownMenuPropsT) => (
  <div
    style={{ width: "auto", ...style }}
    className={cs("DropdownMenu", className, {
      "DropdownMenu--small": types?.includes("small"),
      "DropdownMenu--withLabel": label,
    })}
    {...rest}
  >
    <Dropdown
      placement="bottom-start"
      testId={testId}
      className={cs("DropdownMenu-dropdown open ", {
        "DropdownMenu-dropdown--horizontal": types?.includes("horizontal"),
        "DropdownMenu-dropdown--error": types?.includes("error"),
        "DropdownMenu-dropdown--withList": types?.includes("withList"),
      })}
      triggerItem={(props) => {
        return <DropdownMenuButton {...props} icon={icon} isLoading={isLoading} label={label} {...buttonProps} />;
      }}
      onOpen={onMenuOpen}
    >
      {children || <Content collection={collection} dataTestId={testId} isLoading={isLoading} />}
    </Dropdown>
  </div>
);
