import React, { FormEvent } from "react";
import { WidgetRuleT, WidgetT } from "../../../types/widgets";
import Checkbox from "../../../ui/forms/Checkbox";
import Select from "../../../ui/forms/Select";
import { Col, Row } from "../../../ui/grid/Grid";
import { OnFormEditWidgetChangeT } from "../FormEditWidget";

type PropsT = {
  formKey: string;
  onChange: OnFormEditWidgetChangeT;
  rules?: WidgetRuleT;
  value: Pick<WidgetT, "maxRows" | "showOther">;
};

const MAX_ROWS_OPTIONS = [1, 2, 3, 4, 5, 10, 20, 30, 50].map((value) => ({
  label: `${value}`,
  value: value,
  disabled: false,
}));

const MAX_ROWS_NOT_SELECTED_OPTION = {
  label: "Not selected",
  value: "",
  disabled: false,
};

export const InputMaxRows = ({ formKey, onChange, rules, value }: PropsT) => {
  const pathMaxRows = ["maxRows"];
  const pathStringMaxRows = pathMaxRows.join(".");
  const pathShowOther = ["showOther"];
  const pathStringShowOther = pathShowOther.join(".");

  const handleChangeMaxRows = (event: FormEvent<HTMLSelectElement>) => {
    const inputValue = Number(event.currentTarget.value);
    onChange((prev) => ({ ...prev, maxRows: inputValue || null }));
  };

  let selectOptions = [MAX_ROWS_NOT_SELECTED_OPTION, ...MAX_ROWS_OPTIONS];
  if (rules?.maxRows) {
    const { max, min } = rules.maxRows;

    selectOptions = [
      {
        ...MAX_ROWS_NOT_SELECTED_OPTION,
        disabled: !!(min && min > 0),
      },
      ...MAX_ROWS_OPTIONS.map((option) => ({
        ...option,
        disabled: !!((min && min > option.value) || (max && max < option.value)),
      })),
    ];
  }

  const handleChangeShowOther = () => {
    onChange((prev) => ({ ...prev, showOther: !prev.showOther }));
  };

  return (
    <Row alignItems="top">
      <Col type="grow">
        <Select
          className="mb-16"
          collection={selectOptions}
          label="Max rows for data drilldown"
          selectProps={{
            id: `${formKey}.${pathStringMaxRows}`,
            onChange: handleChangeMaxRows,
            value: value.maxRows || "",
            name: pathStringMaxRows,
          }}
        />
      </Col>

      {!!value.maxRows && (
        <Col className="pt-16">
          <Checkbox
            className="mb-16"
            label="Display grouped 'Other' data"
            input={{
              type: "checkbox",
              id: `${formKey}.${pathStringShowOther}`,
              onChange: handleChangeShowOther,
              checked: value.showOther,
              name: pathStringShowOther,
            }}
          />
        </Col>
      )}
    </Row>
  );
};
