import React from "react";
import { OptimizationCampaignDataColumnT } from "../../../constants/report";
import { WidgetRuleT, WidgetT } from "../../../types/widgets";
import Checkbox from "../../../ui/forms/Checkbox";
import FormGroup from "../../../ui/forms/FormGroup";
import { OnFormEditWidgetChangeT } from "../FormEditWidget";

type PropsT = {
  formKey: string;
  onChange: OnFormEditWidgetChangeT;
  rules?: WidgetRuleT;
  value: WidgetT["campaignDataColumns"];
};

const CAMPAIGN_DATA_COLUMNS_OPTIONS = [
  {
    label: "Campaign status",
    value: OptimizationCampaignDataColumnT.StatusT,
  },
  {
    label: "Campaign budget",
    value: OptimizationCampaignDataColumnT.BudgetT,
  },
  {
    label: "Campaign bidding",
    value: OptimizationCampaignDataColumnT.BiddingT,
  },
];

export const InputCampaignDataColumns = ({ formKey, onChange, value }: PropsT) => {
  const path = ["campaignDataColumns"];

  const handleChange = (checkboxValue: string) => {
    const newValue = value.includes(checkboxValue)
      ? [...value].filter((item) => item !== checkboxValue)
      : [...value, checkboxValue];
    onChange((prev) => ({ ...prev, campaignDataColumns: newValue }));
  };

  return (
    <FormGroup label="Campaign current data">
      {CAMPAIGN_DATA_COLUMNS_OPTIONS.map((option) => (
        <Checkbox
          key={option.value}
          label={option.label}
          input={{
            id: `${formKey}.${path}.${option.value}`,
            name: option.value,
            checked: value.includes(option.value),
            onChange: () => handleChange(option.value),
          }}
        />
      ))}
    </FormGroup>
  );
};
