import React, { SVGProps, useCallback, useState } from "react";
import color from "color";
import { Cell, Label, LabelProps, Pie, PieChart, PieLabel, ResponsiveContainer, Tooltip } from "recharts";
import { CHART_LINE_COLORS } from "../../constants/report";
import { fetchMetricId } from "../../functions/fetchIds";
import {
  fetchColorForRow,
  fetchDimensionValueFromRowAsText,
  fetchMetricPreviousValueFromRow,
  fetchMetricValueFromRow,
  fetchSummaryMetricFromRow,
} from "../../functions/widgetDataHelpers";
import { WidgetCompareT, WidgetDataFragmentT, WidgetRowT } from "../../graphql/generated/graphql";
import { useGetWidgetComponentData } from "../../hooks/useGetWidgetComponentData";
import { formatMetricValue, formatPercents } from "../../i18n/formatNumbers";
import { ReportFilterT } from "../../types/report";
import { WidgetComponentPropsT, WidgetT } from "../../types/widgets";
import { TrendBadge } from "../../ui/DataTable/TrendWrapper";
import { Col, Row } from "../../ui/grid/Grid";
import { withApiStateHandler } from "../ErrorLoadingWrapper/withApiStateHandler";
import { useReportBoardContext } from "../reportBoard/context/reportBoardContext";
import { ReportDimensionT } from "../reportBoard/context/types";
import { WidgetDataEmpty } from "../widgetDataAlerts/WidgetDataEmpty";
import { WidgetDataError } from "../widgetDataAlerts/WidgetDataError";
import { WidgetChartTooltipSummaryPie } from "./WidgetChartTooltipSummaryPie";
import widgetComponentContainer from "./widgetComponentContainer";
import { widgetDataComponentContainer } from "./widgetDataComponentContainer";

export type PieChartComponentPropsT = WidgetComponentPropsT;
type PropsT = { filterValues: ReportFilterT; widget: WidgetT; widgetData?: WidgetDataFragmentT };

type CustomLabelPropsT = Omit<SVGProps<SVGTextElement>, "viewBox"> &
  Omit<LabelProps, "viewBox"> & {
    hasCompare?: boolean;
    positiveTrend?: boolean | null;
    title: string;
    trend?: number | null;
    value: string | number;
    viewBox?: {
      clockWise?: boolean;
      cx?: number;
      cy?: number;
      endAngle?: number;
      innerRadius?: number;
      outerRadius?: number;
      startAngle?: number;
    };
  };

const CustomLabel = ({ hasCompare, positiveTrend, title, trend, value, viewBox }: CustomLabelPropsT) => {
  return (
    <foreignObject
      height={100}
      style={{ transform: `translate(-${viewBox?.innerRadius}px, -50px)` }}
      width={2 * (viewBox?.innerRadius || 1) - (hasCompare ? 36 : 0)}
      x={(viewBox?.cx || 0) + (hasCompare ? 18 : 0)}
      y={viewBox?.cy}
    >
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
        <div style={{ textAlign: "center", lineHeight: "1.4", fontSize: `${(viewBox?.innerRadius || 1) / 78}em` }}>
          {title} <br />
          <strong style={{ fontSize: "1.2em" }}>{value}</strong>
          {trend && (
            <div>
              <TrendBadge className="ma-0" positiveTrend={!!positiveTrend} trend={trend} />
            </div>
          )}
        </div>
      </div>
    </foreignObject>
  );
};

const renderActiveShape: (dimensions: ReportDimensionT[], showLabels: boolean) => PieLabel =
  (dimensions, showLabels) => (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, fill, innerRadius, midAngle, outerRadius, percent } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius - 5) * cos;
    const sy = cy + (outerRadius - 5) * sin;
    const mx = cx + (outerRadius + 5) * cos;
    const my = cy + (outerRadius + 5) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 10;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    const value = fetchDimensionValueFromRowAsText(dimensions)(props.payload);
    const rate = formatPercents(percent, 0);
    const boxWidth = 125;

    const rateX = cx + (innerRadius + (outerRadius - innerRadius) / 2) * Math.cos(-midAngle * RADIAN);
    const rateY = cy + (innerRadius + (outerRadius - innerRadius) / 2) * Math.sin(-midAngle * RADIAN);
    const showInnerPercents = outerRadius - innerRadius > 27;
    const textX = ex + (cos >= 0 ? 1 : -1) * 12 + (textAnchor === "end" ? -1 * boxWidth : 0);

    if (!percent || percent < 0.04) {
      return null;
    }

    if (!showLabels) {
      return showInnerPercents ? (
        <text
          dominantBaseline="middle"
          fill="white"
          fontSize={`${(innerRadius || 1) / 120}em`}
          style={{ pointerEvents: "none" }}
          textAnchor="middle"
          x={rateX}
          y={rateY}
        >
          {rate}
        </text>
      ) : null;
    }

    return (
      <g>
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} fill="none" stroke={fill} />
        {showInnerPercents && (
          <text
            dominantBaseline="middle"
            fill="white"
            fontSize={`${(innerRadius || 1) / 100}em`}
            style={{ pointerEvents: "none" }}
            textAnchor="middle"
            x={rateX}
            y={rateY}
          >
            {rate}
          </text>
        )}
        <circle cx={ex} cy={ey} fill={fill} r={2} stroke="none" />
        <foreignObject
          height="50"
          textAnchor={textAnchor}
          width={textX < 0 ? boxWidth + textX : boxWidth}
          x={Math.max(0, textX)}
          y={ey - 25}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: textAnchor,
              height: "100%",
              width: "100%",
            }}
          >
            <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{value}</div>
          </div>
        </foreignObject>
      </g>
    );
  };

const PieChartComponentContent = withApiStateHandler(
  widgetDataComponentContainer(({ widget, widgetData }: PropsT) => {
    const {
      widgetSettingsData: { dimensionsById, metricsById },
    } = useReportBoardContext();
    const { widgetMetrics } = widget;
    const rows = widgetData?.rows.nodes as WidgetRowT[] | undefined;
    const summary = widgetData?.summary.nodes;
    const selectedDimensions = widget.widgetDimensions.map((dimension) => dimensionsById[dimension.dimensionId]);
    const hasCompare = widget.compare && widget.compare !== WidgetCompareT.NoneT;

    const [activeSlice, setActiveSlice] = useState<string>("");
    const onMouseEnter = useCallback((key: string) => () => setActiveSlice(key), [setActiveSlice]);
    const onMouseLeave = useCallback(() => setActiveSlice(""), [setActiveSlice]);

    const generalChartProps = {
      data: rows,
      endAngle: 90,
      startAngle: 450,
      isAnimationActive: false,
    };

    if (!rows || !widgetMetrics || widgetMetrics.length === 0 || !selectedDimensions) {
      return <WidgetDataError />;
    }

    if (rows.length === 0) {
      return <WidgetDataEmpty />;
    }

    return (
      <Row height="100%">
        {widgetMetrics.map((selectedMetric) => {
          const metricId = fetchMetricId(selectedMetric);
          const metric = metricsById[selectedMetric.metricId];
          const summaryMetric = fetchSummaryMetricFromRow(selectedMetric)(summary?.[0]);
          const showLabels = rows.length < 12;

          return (
            <Col key={metricId} type="grow">
              <ResponsiveContainer>
                <PieChart>
                  <Tooltip
                    content={<WidgetChartTooltipSummaryPie metric={metric} selectedDimensions={selectedDimensions} />}
                    cursor={{ fill: "rgba(0,22,42,.05)" }}
                  />

                  <Pie
                    {...generalChartProps}
                    dataKey={fetchMetricValueFromRow(selectedMetric)}
                    innerRadius={showLabels ? "57%" : "75%"}
                    label={renderActiveShape(selectedDimensions, showLabels)}
                    labelLine={false}
                    outerRadius={showLabels ? "80%" : "100%"}
                  >
                    <Label
                      position="center"
                      content={
                        <CustomLabel
                          hasCompare={hasCompare}
                          positiveTrend={metricsById[selectedMetric.metricId].positiveTrend}
                          title={metricsById[selectedMetric.metricId]?.name}
                          trend={summaryMetric?.trend}
                          value={
                            typeof summaryMetric?.value === "number"
                              ? formatMetricValue({
                                  metric: metricsById[selectedMetric.metricId],
                                  currency: summary?.[0].currency,
                                  value: summaryMetric.value,
                                })
                              : summaryMetric?.value || ""
                          }
                        />
                      }
                    />

                    {rows?.map((row, index) => {
                      const colorFill = fetchColorForRow({ row, color: CHART_LINE_COLORS[index] });
                      const cellId = `${selectedMetric.id}-${index}`;

                      return (
                        <Cell
                          key={row.id}
                          fill={activeSlice === cellId ? color(colorFill).darken(0.2).toString() : colorFill}
                          onMouseEnter={onMouseEnter(cellId)}
                          onMouseLeave={onMouseLeave}
                        />
                      );
                    })}
                  </Pie>

                  {hasCompare && (
                    <Pie
                      {...generalChartProps}
                      dataKey={fetchMetricPreviousValueFromRow(selectedMetric)}
                      innerRadius={showLabels ? "50%" : "67%"}
                      outerRadius={showLabels ? "56%" : "74%"}
                    >
                      {rows?.map((row, index) => (
                        <Cell
                          key={row.id}
                          fill={fetchColorForRow({ row, color: CHART_LINE_COLORS[index], isCompare: true })}
                        />
                      ))}
                    </Pie>
                  )}
                </PieChart>
              </ResponsiveContainer>
            </Col>
          );
        })}
      </Row>
    );
  })
);

const PieChartComponent = ({ filterValues, onWidgetDataChange, widget, ...rest }: PieChartComponentPropsT) => {
  const result = useGetWidgetComponentData({
    filterValues,
    widget,
    onWidgetDataChange,
  });

  return <PieChartComponentContent {...rest} {...result} filterValues={filterValues} widget={widget} />;
};

export default widgetComponentContainer(PieChartComponent);
