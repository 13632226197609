import React, { ForwardedRef } from "react";
import { CusotmIconBasicPropsT } from "./IconCustom";

export const FlameIcon = React.forwardRef(
  ({ className, size, ...rest }: CusotmIconBasicPropsT, ref: ForwardedRef<SVGSVGElement>) => (
    <svg
      ref={ref}
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.4771 5.15184C18.9306 7.54963 20.75 11.1936 20.75 15.044C20.75 17.2607 20.0009 19.4459 18.5384 21.07C17.0797 22.6897 14.899 23.7656 12.0013 23.75C9.08826 23.7343 6.90408 22.8261 5.44969 21.4192C3.99483 20.0118 3.25 18.0861 3.25 15.996C3.25 13.104 4.58148 11.0419 5.97935 8.87691C6.06994 8.73661 6.16081 8.59587 6.25162 8.45445C7.67369 6.23969 9.05947 3.87822 8.90521 0.456623C10.5166 1.48341 11.6096 3.07363 12.3039 4.81365C13.0545 6.69461 13.3315 8.73293 13.2971 10.3728L13.2868 10.8597L13.6884 10.5841C14.8684 9.77445 16.0371 7.60341 15.4771 5.15184Z"
        fill="#FF264A"
        stroke="url(#paint0_linear_5390_127889)"
        strokeWidth="0.5"
      />
      <path
        d="M11.7691 22C14.7851 22 17.0911 16.989 13.3361 13C11.7371 16.988 9.01908 16.99 9.00008 19.423C8.99008 20.735 9.93408 22 11.7691 22Z"
        fill="#FDA522"
        stroke="url(#paint1_linear_5390_127889)"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_5390_127889"
          x1="12"
          x2="12"
          y1="0"
          y2="24.0002"
        >
          <stop stopColor="#FF8970" />
          <stop offset="1" stopColor="#E23B16" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_5390_127889"
          x1="12.143"
          x2="12.143"
          y1="13"
          y2="22"
        >
          <stop stopColor="#FFB951" />
          <stop offset="1" stopColor="#EE9A1C" />
        </linearGradient>
      </defs>
    </svg>
  )
);
