import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import cs from "classnames";
import { Layout as LayoutT, Responsive, WidthProvider } from "react-grid-layout";
import { v4 as uuid } from "uuid";
import {
  DUPLICATED_PREFIX,
  REPORT_SECTION_LAYOUT_BREAKPOINTS,
  REPORT_SECTION_LAYOUT_COLUMNS,
} from "../../constants/report";
import { getGridLayout, getGridLayoutPropertiesOfItems } from "../../functions/gridLayoutHelpers";
import { addNewItemToSections } from "../../functions/sectionHelper";
import { notifySucces } from "../../functions/toast";
import { getWidgetDefaultData } from "../../functions/widgetHelpers";
import { useElementDimensions } from "../../hooks/useElementDimensions";
import { SectionT, WidgetT } from "../../types/widgets";
import { ButtonSecondary, ButtonTertiary } from "../../ui/Button/Button";
import { Text } from "../../ui/Text/Text";
import { Tile } from "../../ui/Tile/Tile";
import { FormReportCompactFilterInputsT } from "../formReportCompactFilter/FormReportCompactFilter";
import Widget from "../widget/Widget";
import { cleanWidget, shouldUseCopyPrefix } from "./context/functions";
import { useReportBoardContext } from "./context/reportBoardContext";
import { ReportBoardEditWidget } from "./ReportBoardEditWidget";
import { PageContextT } from "./ReportBoardPage";
import { ReportBoardSectionHeader } from "./ReportBoardSectionHeader";

export type WidgetContextT = {
  sectionsList: PageContextT["sectionsList"];
  widgetDelete: (widgetId: string) => void;
  widgetDuplicate: (widgetId: string) => void;
  widgetEditationClose: () => void;
  widgetEditationOpen: (widgetId: string) => void;
  widgetEditationOpenConditions: (widgetId: string) => void;
  widgetMoveToDifferentSection: (params: { wantedSectionId: string; widget: WidgetT }) => void;
  widgetUpdate: (params: { setWidget: WidgetT | ((widget: WidgetT) => WidgetT); widgetId: string }) => void;
};

type ReportBoardSectionPropsT = {
  isLast: boolean;
  onHeightChange: (sectionId: SectionT["id"], height: number) => void;
  pageContext: PageContextT;
  section: SectionT;
};

const ResponsiveGridLayout = WidthProvider(Responsive);

export const ReportBoardSection = ({ isLast, onHeightChange, pageContext, section }: ReportBoardSectionPropsT) => {
  const { id: sectionId, widgets } = section;
  const [isSectionHidden, setSectionHidden] = useState(false);
  const [editedWidgetId, setEditedWidgetId] = useState<{
    id: string | null;
    options?: { defaultView: "filter" };
  } | null>(null);
  const { openCreateSectionModal, sectionUpdate, sectionsList } = pageContext;
  const { reportFilter, reportOptions, widgetSettingsData } = useReportBoardContext();

  const handleSectionFilterChange = (values: Partial<FormReportCompactFilterInputsT>) => {
    sectionUpdate({
      sectionId,
      sectionInputData: (prev) => ({ ...prev, dateRange: values.dateRange }),
    });
  };

  // start widget context
  const widgetUpdate = useCallback(
    ({ setWidget, widgetId }: { setWidget: WidgetT | ((widget: WidgetT) => WidgetT); widgetId: string }) => {
      sectionUpdate({
        sectionId,
        sectionInputData: (prev) => {
          const updatingWidget = prev.widgets.nodes.find((widget) => widgetId === widget.id);
          const otherWidgets = prev.widgets.nodes.filter((widget) => widgetId !== widget.id);

          const newWidget = (
            typeof setWidget === "function" && updatingWidget ? setWidget(updatingWidget) : setWidget
          ) as WidgetT;

          return {
            ...prev,
            widgets: {
              ...prev.widgets,
              nodes: [...otherWidgets, { ...newWidget }],
            },
          };
        },
      });
    },
    [sectionUpdate, sectionId]
  );

  const widgetAddOpen = useCallback(() => setEditedWidgetId({ id: null }), []);

  const widgetEditationOpen = useCallback((widgetId: string) => setEditedWidgetId({ id: widgetId }), []);

  const widgetEditationOpenConditions = useCallback(
    (widgetId: string) => setEditedWidgetId({ id: widgetId, options: { defaultView: "filter" } }),
    []
  );

  const widgetEditationClose = useCallback(() => {
    setEditedWidgetId(null);
  }, []);

  const widgetDuplicate = useCallback(
    (widgetId: string) => {
      const targetWidget = section.widgets.nodes.find((widget) => widget.id === widgetId);
      const duplicatedWidget = { ...targetWidget } as WidgetT;

      const newWidgets = addNewItemToSections(
        section.widgets.nodes,
        {
          ...cleanWidget(duplicatedWidget),
          isNew: true,
          name: `${shouldUseCopyPrefix(duplicatedWidget.name) ? `${DUPLICATED_PREFIX} ` : ""}${duplicatedWidget.name}`,
        },
        duplicatedWidget.position ? duplicatedWidget.position + 1 : 0
      );

      sectionUpdate({
        sectionId,
        sectionInputData: (prev) => ({ ...prev, widgets: { nodes: newWidgets as WidgetT[] } }),
      });
      notifySucces(<>Widget has been duplicated.</>);
    },
    [sectionUpdate, section.widgets.nodes, sectionId]
  );

  const widgetLayoutChange = useCallback(
    (currentLayout: LayoutT[]) => {
      const items = getGridLayoutPropertiesOfItems(currentLayout);

      items.forEach((item) => {
        const curWidget = section.widgets.nodes.find((widget) => widget.id === item.itemId);

        widgetUpdate({
          widgetId: item.itemId,
          setWidget: (prev) => ({
            ...prev,
            position: item.order,
            properties: {
              ...curWidget?.properties,
              options: { ...curWidget?.properties?.options, width: item.width, height: item.height },
            },
          }),
        });
      });
    },
    [section.widgets, widgetUpdate]
  );

  const widgetDelete = useCallback(
    (widgetId: string, options?: { hasNoNotification?: boolean }) => {
      sectionUpdate({
        sectionId,
        sectionInputData: (prev) => ({
          ...prev,
          widgets: { nodes: section.widgets.nodes.filter((widget) => widget.id !== widgetId) },
        }),
      });
      if (!options?.hasNoNotification) {
        notifySucces(<>Widget has been deleted.</>);
      }
    },
    [sectionUpdate, section.widgets, sectionId]
  );

  const widgetAdd = useCallback(
    (newWidget: WidgetT) => {
      sectionUpdate({
        sectionId,
        sectionInputData: (prev) => ({ ...prev, widgets: { nodes: [...section.widgets.nodes, newWidget] } }),
      });
    },
    [section.widgets, sectionUpdate, sectionId]
  );

  const widgetMoveToDifferentSection = useCallback(
    ({ wantedSectionId, widget }: { wantedSectionId: string; widget: WidgetT }) => {
      sectionUpdate({
        sectionId: wantedSectionId,
        sectionInputData: (prev) => ({
          ...prev,
          widgets: { nodes: [...prev.widgets.nodes, { ...cleanWidget(widget), isNew: true }] },
        }),
      });
      widgetDelete(widget.id, { hasNoNotification: true });
    },
    [sectionUpdate, widgetDelete]
  );

  const editedWidget = useMemo(() => {
    if (editedWidgetId && !editedWidgetId.id) {
      return getWidgetDefaultData({
        id: uuid(),
        position: section.widgets.nodes.length,
        metrics: widgetSettingsData.metrics,
        dimensions: widgetSettingsData.dimensions,
        connectedSystems: widgetSettingsData.connectedSystems,
      });
    }
    if (editedWidgetId?.id) {
      return section.widgets.nodes.find((widget) => widget.id === editedWidgetId.id);
    }
  }, [editedWidgetId, section, widgetSettingsData]);

  const widgetContext = useMemo(
    () => ({
      sectionsList,
      widgetEditationClose,
      widgetEditationOpen,
      widgetUpdate,
      widgetDuplicate,
      widgetDelete,
      widgetMoveToDifferentSection,
      widgetEditationOpenConditions,
    }),
    [
      sectionsList,
      widgetEditationClose,
      widgetUpdate,
      widgetEditationOpen,
      widgetDuplicate,
      widgetDelete,
      widgetMoveToDifferentSection,
      widgetEditationOpenConditions,
    ]
  );
  // end widget context

  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperDimensions = useElementDimensions(wrapperRef);

  const gridLayout = useMemo(
    () =>
      getGridLayout({
        items: widgets?.nodes
          .slice()
          .sort((a, b) => a.position - b.position)
          .map((widget) => ({
            id: widget.id,
            width: widget.properties?.options?.width,
            height: widget.properties?.options?.height,
          })),
        columns: REPORT_SECTION_LAYOUT_COLUMNS,
        breakpoints: REPORT_SECTION_LAYOUT_BREAKPOINTS,
      }),
    [widgets?.nodes]
  );

  const handleHeightChange = () => {
    if (wrapperDimensions.height || wrapperDimensions.height === 0) {
      onHeightChange(section.id, wrapperDimensions.height);
    }
  };

  useEffect(() => {
    handleHeightChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperDimensions]);

  const handleUpdateWidget = useCallback(
    (widget: WidgetT) => {
      if (editedWidgetId?.id) {
        widgetUpdate({ widgetId: widget.id, setWidget: widget });
      } else {
        widgetAdd(widget);
      }
    },
    [widgetUpdate, widgetAdd, editedWidgetId]
  );

  return (
    <div ref={wrapperRef} data-test-id={`${section.name}`}>
      <Tile className="show-on-hover-wrapper" contentStyle={{ paddingLeft: "24px" }}>
        <ReportBoardSectionHeader
          isSectionHidden={isSectionHidden}
          pageContext={pageContext}
          section={section}
          setSectionHidden={setSectionHidden}
          onSectionFilterChange={handleSectionFilterChange}
        />

        {!isSectionHidden && (
          <>
            <ResponsiveGridLayout
              breakpoints={REPORT_SECTION_LAYOUT_BREAKPOINTS}
              className="layout mt-4"
              cols={REPORT_SECTION_LAYOUT_COLUMNS}
              compactType={null}
              draggableHandle=".draggable-handle-widget"
              isDraggable={reportOptions.canEdit}
              isResizable={reportOptions.canEdit}
              layouts={gridLayout}
              resizeHandle={!reportOptions.canEdit && <></>}
              rowHeight={420}
              style={{ marginLeft: "-10px", marginRight: "-10px" }}
              onDrag={handleHeightChange}
              onDragStop={handleHeightChange}
              onLayoutChange={widgetLayoutChange}
            >
              {widgets?.nodes.map((widget, index) => (
                <div key={widget.id} className="show-on-hover-wrapper ReportWidget" data-grid={gridLayout.sm[index]}>
                  <Widget
                    sectionFilter={{ dateRange: section.dateRange || {}, conditions: section.conditions }}
                    sectionId={sectionId}
                    widget={widget}
                    widgetContext={widgetContext}
                  />
                </div>
              ))}
            </ResponsiveGridLayout>

            {reportOptions?.canEdit && (
              <div className="text-center mt-16">
                <ButtonSecondary className="mr-4" data-test-id="button-add-widget" icon="plus" onClick={widgetAddOpen}>
                  Add widget
                </ButtonSecondary>
              </div>
            )}
          </>
        )}
      </Tile>

      <div className="pv-16 ShowOnHoverWrapper">
        {reportOptions?.canEdit && (
          <Text className={cs({ ShowOnHoverElement: !isLast })} withLine>
            <ButtonTertiary
              data-test-id="add-new-section"
              icon="plus"
              onClick={() => openCreateSectionModal(section.position)}
            >
              Add new section
            </ButtonTertiary>
          </Text>
        )}
      </div>

      {editedWidget && (
        <ReportBoardEditWidget
          editedWidget={editedWidget}
          isCreating={!!editedWidgetId && !editedWidgetId.id}
          isFilterOpenDefault={editedWidgetId?.options?.defaultView === "filter"}
          reportFilter={reportFilter}
          widgetContext={widgetContext}
          widgetSettingsData={widgetSettingsData}
          sectionFilter={{
            dateRange: section.dateRange || {},
            conditions: section.conditions || [],
          }}
          onClose={widgetEditationClose}
          onSaveWidget={handleUpdateWidget}
        />
      )}
    </div>
  );
};
