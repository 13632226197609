import React from "react";
import { SystemIconBasicPropsT } from "./types";

export const AnalyticsIcon = ({ active, size = "16px" }: SystemIconBasicPropsT) => (
  <svg
    className={`system-icon analytics-icon ${active && "active"}`}
    fill="none"
    height={size}
    viewBox="0 0 23 25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8523 24.9948C21.5994 24.9857 23.0092 23.5883 22.9999 21.8717V3.12319C23.0011 2.99654 22.9937 2.87112 22.9791 2.74569C22.7758 1.04159 21.2034 -0.178201 19.4682 0.0213856C17.8458 0.254845 16.6548 1.64004 16.6933 3.25076V21.7438C16.6535 23.3524 17.8411 24.7376 19.4626 24.9732C19.5916 24.9887 19.7224 24.9958 19.8523 24.9948Z"
      fill="#F9AB00"
    />
    <path
      d="M6.30753 21.9034C6.30753 20.1932 4.89553 18.8068 3.15376 18.8068C1.412 18.8068 0 20.1929 0 21.9034C0 23.6139 1.412 25 3.15376 25C4.89553 25 6.30753 23.6136 6.30753 21.9034Z"
      fill="#E37400"
    />
    <path
      d="M11.4514 9.4223C9.70123 9.51691 8.34362 10.9576 8.38465 12.6782V20.9952C8.38465 23.2526 9.39633 24.6225 10.8786 24.9146C12.5889 25.2551 14.2555 24.1699 14.602 22.4905C14.6449 22.2849 14.6658 22.0761 14.6648 21.8665V12.5345C14.6679 10.8179 13.2541 9.42444 11.5058 9.42139L11.4706 9.42163L11.4514 9.4223Z"
      fill="#E37400"
    />
  </svg>
);
