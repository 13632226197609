import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  FloatingFocusManager,
  FloatingPortal,
  autoUpdate,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useMergeRefs,
  useRole,
} from "@floating-ui/react";
import cs from "classnames";
import { normalizeSearchText } from "../../functions/normalize";
import Input, { InputPropsT } from "./Input";

export type AutoCompleteCollectionT = Array<{ count: number; value: string }>;

type PropsT = Omit<InputPropsT, "inputProps"> & {
  collection?: AutoCompleteCollectionT;
  inputProps: Omit<InputPropsT["inputProps"], "onChange"> & { onChange: (value: string) => void };
  variableName?: string;
};

export function AutoComplete({ collection, inputProps, variableName = "variable", ...rest }: PropsT) {
  const inputValue = `${inputProps.value || ""}`;
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const listRef = useRef<Array<HTMLElement | null>>([]);
  const inputRef = useRef(null);

  const { context, floating, reference, strategy, x, y } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    middleware: [
      size({
        apply({ availableHeight, elements, rects }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight <= 200 ? availableHeight : 200}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const ref = useMergeRefs([reference, inputRef]);

  const { getFloatingProps, getItemProps, getReferenceProps } = useInteractions([
    useRole(context, { role: "listbox" }),
    useDismiss(context),
    useListNavigation(context, {
      listRef,
      activeIndex,
      onNavigate: setActiveIndex,
      virtual: true,
      loop: true,
    }),
  ]);

  const items = (collection || []).filter((item) =>
    normalizeSearchText(item.value).includes(normalizeSearchText(inputValue))
  );

  const firstAndOnlyItem = items.length === 1 && items[0].value;

  useEffect(() => {
    if (inputValue && inputRef?.current === document.activeElement) {
      setOpen(true);
      setActiveIndex(0);
    }

    if (!inputValue || items.length === 0 || firstAndOnlyItem === inputValue) {
      setOpen(false);
    }
  }, [inputValue, items.length, firstAndOnlyItem]);

  return (
    <div>
      <Input
        {...rest}
        inputProps={{
          ...getReferenceProps({
            ...inputProps,
            onChange: (event: ChangeEvent<HTMLInputElement>) => inputProps.onChange(event.currentTarget.value),
            "aria-autocomplete": "list",
            autoComplete: "off",
            placeholder: inputProps.placeholder || `Enter ${variableName}`,
            value: inputValue,
            ref,
            onKeyDown(event) {
              if (event.key === "Enter" && activeIndex != null && items[activeIndex]) {
                event.preventDefault();
                if (inputProps.onChange) {
                  inputProps.onChange(items[activeIndex].value);
                }
              }
            },
          }),
        }}
      />
      <FloatingPortal id="dee-dropdown-js">
        {open && collection && items.length > 0 && (
          <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
            <div
              {...getFloatingProps({
                ref: floating,
                style: {
                  position: strategy,
                  left: x ?? 0,
                  top: y ?? 0,
                },
              })}
              className="w-100 h-100 Placeholders-dropdown zIndex--10000"
            >
              <div className="Placeholders-list h-100" data-test-id="autosuggest-list">
                {items.map((item, index) => (
                  <div
                    {...getItemProps({
                      ref(node) {
                        listRef.current[index] = node;
                      },
                      onClick() {
                        if (inputProps.onChange) {
                          inputProps.onChange(item.value);
                        }
                      },
                    })}
                    key={item.value}
                    aria-selected={activeIndex === index}
                    className={cs("Placeholders-dropdownItem pa-8 ma-0", { active: activeIndex === index })}
                    role="option"
                  >
                    {item.value}
                  </div>
                ))}
              </div>
              <div className="Placeholders-dropdownHint">Use arrows to navigate</div>
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </div>
  );
}
