import { useMemo, useState } from "react";
import { getGridLayout, getGridLayoutPropertiesOfItems } from "../../../functions/gridLayoutHelpers";
import { WidgetDimensionT, WidgetMetricT } from "../../../graphql/generated/graphql";

const GRID_COLUMNS = { sm: 1 };
const GRID_BREAKPOINTS = { sm: 0 };

export const useMetricDimensionDraggableList = <T extends WidgetMetricT | WidgetDimensionT>({
  onChange,
  value,
}: {
  onChange: (sortedValues: T[]) => void;
  value: T[];
}) => {
  const [heights, setHeights] = useState<{ [key: string]: number }>({});

  const handleHeightChange = (rowId: string, height: number) => {
    setHeights((prev) => ({ ...prev, [rowId]: height + 8 }));
  };

  const gridLayout = useMemo(
    () =>
      getGridLayout({
        items: value?.map((val) => ({
          id: val.id,
          width: 1,
          height: heights[val.id] || 1,
        })),
        columns: GRID_COLUMNS,
        breakpoints: GRID_BREAKPOINTS,
      }),
    [heights, value]
  );

  const handleLayoutChange = (currentLayout: ReactGridLayout.Layout[]) => {
    const sorted = getGridLayoutPropertiesOfItems(currentLayout);

    const sortedValues = sorted.map((item) => value.find((val) => val.id === item.itemId)).filter((item) => item);
    if (sortedValues && sortedValues.length > 1 && onChange) {
      onChange(sortedValues as T[]);
    }
  };

  return {
    gridLayout,
    handleLayoutChange,
    onRowHeightChange: handleHeightChange,
    defaultGridProps: {
      breakpoints: GRID_BREAKPOINTS,
      className: "layout",
      cols: GRID_COLUMNS,
      isResizable: false,
      layouts: gridLayout,
      rowHeight: 1,
      onLayoutChange: handleLayoutChange,
    },
  };
};
